import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './UnitGlobalSettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import UnitGlobalSetting from './UnitGlobalSettings';
import moment from 'moment-timezone';

const UnitGlobalSettingListTable = ({
  setEditHandler,
  ModelVersionId,
  selectedUnitGlobalSettingId,
  saveUnitGlobalSetting,
  unitGlobalSettings,
  describeUnitGlobalSetting,
  changeUnitGlobalSetting,
  unitGlobalSettingsData,
  UpdateAllowed,
  DeleteAllowed,
  ReadOnly,
  disableMode,
  disableModeHandler,
  addNewRef,
  removeUnitGlobalSetting,
  currentTimezone
}) => {
  const fetchDataHandler = unitGlobalSettingId => {
    setEditHandler(unitGlobalSettingId);
    disableModeHandler(true);
    describeUnitGlobalSetting(unitGlobalSettingId);
  };

  const deleteDataHandler = unitGlobalSettingId => {
    removeUnitGlobalSetting(unitGlobalSettingId);
  };

  const cancelHandler = () => {
    setEditHandler(-1);
    disableModeHandler(false);
  };

  const editModelVersionContent = (
    <UnitGlobalSetting
      UnitGlobalSettingId={selectedUnitGlobalSettingId}
      ModelVersionId={ModelVersionId}
      saveUnitGlobalSetting={saveUnitGlobalSetting}
      describeUnitGlobalSetting={describeUnitGlobalSetting}
      unitGlobalSetting={unitGlobalSettings}
      changeUnitGlobalSetting={changeUnitGlobalSetting}
      cancelHandler={cancelHandler}
      currentTimezone={currentTimezone}
      setEditHandler={setEditHandler}
    />
  );

  let editorMain = (
    <tr>
      <td colSpan="10">{editModelVersionContent}</td>
    </tr>
  );

  let newRowClass = unitGlobalSettings.showAddEditScreen && selectedUnitGlobalSettingId === 0 ? s.newRow : '';

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="unitGlobalSettings.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="unitGlobalSettings.code" defaultMessage="Code" />
            </th>
            <th>
              <FormattedMessage id="unitGlobalSettings.defaultValue" defaultMessage="Default Value" />
            </th>
            <th>
              <FormattedMessage id="unitGlobalSetting.uOM" defaultMessage="Unit of Measure" />
            </th>

            {!ReadOnly && (UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {unitGlobalSettingsData.map(n => {
            return (
              <Fragment key={n.UnitGlobalSettingId}>
                <tr key={n.UnitGlobalSettingId} data-unittest="unitGlobalSettingData">
                  <td>{n.Name}</td>
                  <td>{n.Code}</td>
                  <td>{n.DefaultValue}</td>
                  <td>{n.UnitOfMeasure}</td>
                  {!ReadOnly && (UpdateAllowed || DeleteAllowed) && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={fetchDataHandler.bind(this, n.UnitGlobalSettingId)}
                          disabled={disableMode}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="unitGlobalSettings.change" defaultMessage="Change" />
                        </Button>
                      )}
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={deleteDataHandler.bind(this, n.UnitGlobalSettingId)}
                          disabled={disableMode}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="unitGlobalSettings.delete" defaultMessage="Delete" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
                {unitGlobalSettings.showAddEditScreen && selectedUnitGlobalSettingId === n.UnitGlobalSettingId && editorMain}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className={newRowClass} ref={addNewRef}>
        {unitGlobalSettings.showAddEditScreen && selectedUnitGlobalSettingId === 0 && editModelVersionContent}
      </div>
    </div>
  );
};

UnitGlobalSettingListTable.defaultProps = {
  unitGlobalSettingsData: []
};

UnitGlobalSettingListTable.propTypes = {
  setEditHandler: PropTypes.func.isRequired,
  selectedUnitGlobalSettingId: PropTypes.number,
  saveUnitGlobalSetting: PropTypes.func.isRequired,
  unitGlobalSettings: PropTypes.object.isRequired,
  describeUnitGlobalSetting: PropTypes.func.isRequired,
  changeUnitGlobalSetting: PropTypes.func.isRequired,
  unitGlobalSettingsData: PropTypes.array,
  UpdateAllowed: PropTypes.bool,
  disableMode: PropTypes.bool,
  disableModeHandler: PropTypes.func.isRequired
};

export default injectIntl(UnitGlobalSettingListTable);
