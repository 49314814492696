import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  CHECKLIST_ERROR,
  GET_ALL_CHECKLIST_DETAILS,
  CHECKLIST_IS_LOADING,
  SET_CHECKLIST_CURRENT_PAGE,
  SET_CHECKLIST_PAGE_FILTER,
  SET_CHECKLIST_CHANGE,
  SET_CHECKLIST,
  UPDATE_CHECKLIST_SAVE_STATUS,
  SET_CHECKLIST_FILTERED_RESULTS,
  GET_ALL_CHECKLIST_FILTERS
} from '../constants/index';

import { CHECKLIST_MANAGEMENT, CREATE_CHECKLIST, UPDATE_CHECKLIST } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setChecklistErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setChecklistError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setChecklistError(messageCode, false));
  }, 2500);
};

export const setChecklistError = (messageCode, status) => {
  return {
    type: CHECKLIST_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: CHECKLIST_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getChecklistThunk = (filterObj, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getChecklist(filterObj, widgetCode, orgId));
};

export const getChecklist = (filterObj, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/checklists`,
    data: {
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter,
      FilterStatus: filterObj.filterStatus,
      FilterIsServiceChecklist: filterObj.filterIsServiceChecklist,
      FilterUnitId: filterObj.filterUnitId,
      UserId: Cookies.get(`userid-${envName}`),
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllChecklistListThunk(retData),
    onFailure: err => setChecklistErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllChecklistListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let checklists = (retData && retData.retData.checklist) || [];
  dispatch(setAllChecklistList(messageCode, checklists));
};

export const setAllChecklistList = (messageCode, checklists) => ({
  type: GET_ALL_CHECKLIST_DETAILS,
  payload: {
    data: {
      Checklists: checklists,
      filteredResults: checklists,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_CHECKLIST_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filterBy => ({
  type: SET_CHECKLIST_PAGE_FILTER,
  payload: { filterBy }
});

export const describeChecklistThunk = (ChecklistId, widgetcode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeChecklist(ChecklistId, widgetcode, orgId));
};

export const describeChecklist = (ChecklistId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/checklists`,
    data: {
      ChecklistId: ChecklistId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setChecklistThunk(retData),
    onFailure: err => setChecklistErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setChecklistThunk = ({ retData }) => dispatch => {
  let data = retData.checklist;
  dispatch(setChecklist(data));
};

export const setChecklist = data => ({
  type: SET_CHECKLIST,
  payload: data
});

export const saveChecklistThunk = (checklist, widgetCode) => dispatch => {
  let url = '/management/checklists/save';
  let actionName = checklist.ChecklistId === 0 ? CREATE_CHECKLIST : UPDATE_CHECKLIST;
  let log = logEntry(CHECKLIST_MANAGEMENT, actionName, checklist);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  checklist.OrganisationId = orgId;
  dispatch(saveChecklist(checklist, url, log, widgetCode));
};

export const saveChecklist = (checklist, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setChecklistStatusThunk(retData, checklist),
    onFailure: err => setChecklistErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      checklist: checklist,
      log: log
    },
    widgetcode: widgetCode
  });

export const setChecklistStatusThunk = (message, checklist) => dispatch => {
  let markAsComplete = checklist.MarkAsComplete ? true : false;
  let isServiceChecklist = checklist.IsServiceChecklist ? true : false;
  let warning = false;
  if (message && message.message && message.warning) {
    warning = true;
    message = message.message;
  }

  dispatch(setChecklistStatus(message, true, markAsComplete, isServiceChecklist, warning));
  setTimeout(() => {
    //Hide the banner
    dispatch(setChecklistStatus(message, false, markAsComplete, isServiceChecklist));
  }, 2500);
};

export const setChecklistStatus = (messageCode, status, markAsComplete, isServiceChecklist, warning) => ({
  type: UPDATE_CHECKLIST_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      warning: warning,
      displayMessageCode: messageCode || '',
      MarkAsComplete: markAsComplete,
      IsServiceChecklist: isServiceChecklist
    }
  }
});

export const setChecklistChangeThunk = retData => dispatch => {
  dispatch(setChecklistChange(retData));
};

export const setChecklistChange = retData => ({
  type: SET_CHECKLIST_CHANGE,
  payload: retData
});

export const setChecklistFilteredDataThunk = retData => dispatch => {
  dispatch(setChecklistFilteredData(retData));
};

export const setChecklistFilteredData = retData => ({
  type: SET_CHECKLIST_FILTERED_RESULTS,
  payload: retData
});

export const getChecklistsFilterThunk = widgetCode => dispatch => {
  dispatch(getChecklistFilter(widgetCode));
};

export const getChecklistFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      filterFor: 'checklists'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllChecklistFilterThunk(retData),
    onFailure: err => setChecklistErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllChecklistFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;

  dispatch(setAllChecklistFilter(messageCode, filterData, itemCount));
};

export const setAllChecklistFilter = (messageCode, filterData, itemCount) => ({
  type: GET_ALL_CHECKLIST_FILTERS,
  payload: {
    data: {
      filterData: filterData,
      checklistCount: itemCount,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});
