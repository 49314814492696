import { initialTimezoneState } from './initialState';
import * as timezoneActionTypes from '../constants/index';

export default (state = initialTimezoneState, action = {}) => {
  switch (action.type) {
    case timezoneActionTypes.SET_TIMEZONE:
      return { ...state, timezone: action.timezone };

    default:
      return state;
  }
};
