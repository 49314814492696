import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import s from './Layout.module.scss';
import logoWhite from '../../assets/logo-white.svg';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const Layout = ({ children }) => (
  <Container fluid className={s.height100}>
    <Row className={s.height100}>
      <Col md={6} className={classNames(s.imageSectionNoAuth, s.hideMobile, s.hideTablet)}>
        <div className={s.logoContainer}>
          <img src={logoWhite} alt="icon" />
          <p>
            <FormattedMessage
              id="layout.tagline"
              defaultMessage="At Water Source Australia we believe clean drinking water should be available for all."
            />
          </p>
        </div>
        <div className={s.textBottomImage}>
          <p>
            <a href="https://www.watersourceaustralia.com.au" target="_blank">
              www.watersourceaustralia.com.au
            </a>
          </p>
        </div>
      </Col>

      <Col md={6} xs={12} className={s.contentWrapper + ' text-left'}>
        {children}
      </Col>
    </Row>
  </Container>
);

export default Layout;
