import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionGlobalSettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelVersionState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import Cookies from 'js-cookie';

const ModelVersionGlobalSetting = ({
  ModelVersionGlobalSettingId,
  ModelVersionId,
  saveModelVersionGlobalSetting,
  modelVersionGlobalSetting,
  changeModelVersionGlobalSetting,
  unitGlobalSettings,
  cancelHandler,
  intl
}) => {
  let IsEdit = false;
  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  if (ModelVersionGlobalSettingId) {
    IsEdit = true;
  }

  // Update redux store
  const setLocalModelVersionGlobalSetting = currentState => {
    changeModelVersionGlobalSetting(currentState);
  };

  let widgetCode = IsEdit ? 'MODELVERSIONGLOBALSETTINGSEDIT' : 'MODELVERSIONGLOBALSETTINGSADD';

  const globalSettingOptions = () => {
    let globalSettingData = [];
    unitGlobalSettings.forEach(element => {
      if (
        modelVersionGlobalSetting &&
        modelVersionGlobalSetting.GlobalSettingsList.filter(x => x.GeneralGlobalSettingId === element.GeneralGlobalSettingId).length === 1
      ) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }
      globalSettingData.push({
        ...element,
        label: element.Name,
        value: element.GeneralGlobalSettingId
      });
    });
    return globalSettingData.sort(compareValues('label'));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = modelVersionGlobalSetting.SelectedGlobalSetting;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.ModelVersionGlobalSettingId = 0;
      saveData.ModelVersionId = ModelVersionId;
      saveData.GuidIdentifier = uuidv4();
    }

    saveModelVersionGlobalSetting(saveData, widgetCode);
  };

  let selGlobalSettingObj = null;
  if (modelVersionGlobalSetting.SelectedGlobalSetting.GeneralGlobalSettingId) {
    selGlobalSettingObj = unitGlobalSettings.find(
      x => x.GeneralGlobalSettingId === modelVersionGlobalSetting.SelectedGlobalSetting.GeneralGlobalSettingId
    );
  }

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !modelVersionGlobalSetting ||
      !modelVersionGlobalSetting.SelectedGlobalSetting ||
      !modelVersionGlobalSetting.SelectedGlobalSetting.GeneralGlobalSettingId ||
      isRequired(modelVersionGlobalSetting.SelectedGlobalSetting.GeneralGlobalSettingId, 1)
    ) {
      formErrors.GeneralGlobalSettingId = intl.formatMessage({
        id: 'modelVersionManagement.globalSettingRequired',
        defaultMessage: 'Setting is a mandatory field'
      });
      isValid = false;
    }

    if (
      !modelVersionGlobalSetting ||
      !modelVersionGlobalSetting.SelectedGlobalSetting ||
      !modelVersionGlobalSetting.SelectedGlobalSetting.GeneralGlobalSettingId ||
      isRequired(modelVersionGlobalSetting.SelectedGlobalSetting.Value, 1)
    ) {
      formErrors.Value = intl.formatMessage({
        id: 'modelVersionManagement.ValueRequired',
        defaultMessage: 'Value is a mandatory field'
      });
      isValid = false;
    } else {
      if (
        selGlobalSettingObj &&
        selGlobalSettingObj.MinimumValue !== undefined &&
        selGlobalSettingObj.MinimumValue !== '' &&
        selGlobalSettingObj.MinimumValue > parseFloat(modelVersionGlobalSetting.SelectedGlobalSetting.Value)
      ) {
        formErrors.Value = intl.formatMessage({
          id: 'modelVersionManagement.ValueMustBeWithinRange',
          defaultMessage: 'Value must be within Min and Max range'
        });
        isValid = false;
      } else if (
        selGlobalSettingObj &&
        selGlobalSettingObj.MaximumValue !== undefined &&
        selGlobalSettingObj.MaximumValue !== '' &&
        selGlobalSettingObj.MaximumValue < parseFloat(modelVersionGlobalSetting.SelectedGlobalSetting.Value)
      ) {
        formErrors.Value = intl.formatMessage({
          id: 'modelVersionManagement.ValueMustBeWithinRange',
          defaultMessage: 'Value must be within Min and Max range'
        });
        isValid = false;
      }
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    setLocalModelVersionGlobalSetting({
      ...modelVersionGlobalSetting,
      SelectedGlobalSetting: { ...modelVersionGlobalSetting.SelectedGlobalSetting, [e.target.name]: e.target.value }
    });
  };

  const getDefaultValue = id => {
    let generalGlobalSettingId = id ? id : modelVersionGlobalSetting.SelectedGlobalSetting.GeneralGlobalSettingId;
    let unitGlobalSetting = unitGlobalSettings.filter(x => x.GeneralGlobalSettingId === generalGlobalSettingId);

    return unitGlobalSetting.length > 0 ? unitGlobalSetting[0].DefaultValue : '';
  };

  const onDropdownChange = e => {
    let defaultValue = getDefaultValue(e.value);

    setLocalModelVersionGlobalSetting({
      SelectedGlobalSetting: { ...modelVersionGlobalSetting.SelectedGlobalSetting, GeneralGlobalSettingId: e.value, Value: defaultValue }
    });
  };

  const defaultHandler = () => {
    setLocalModelVersionGlobalSetting({
      SelectedGlobalSetting: { ...modelVersionGlobalSetting.SelectedGlobalSetting, Value: getDefaultValue() }
    });
  };

  let ddlClass = localformErrors && localformErrors.GeneralGlobalSettingId ? s.ddlError : '';

  return (
    <div className={s.modelversion}>
      <div className={s.modelversionContent}>
        <Form>
          <div className={s.topRow}>
            <Row>
              <Col lg={3}>
                <Form.Group controlId="formGlobalSettingName">
                  <Form.Label>
                    <FormattedMessage id="modelVersionManagement.selectSetting" defaultMessage="Select setting" />
                  </Form.Label>
                  <Dropdown
                    id="formGlobalSettingName"
                    dataArray={globalSettingOptions()}
                    controlData={{ placeholderText: 'Select Setting', customClassName: ddlClass }}
                    onDropdownChange={onDropdownChange}
                    selectedOption={globalSettingOptions().filter(
                      option => option.value === modelVersionGlobalSetting.SelectedGlobalSetting.GeneralGlobalSettingId
                    )}
                    disabled={IsEdit}
                    data-unittest="formGlobalSetting"
                  />
                  {localformErrors && localformErrors.GeneralGlobalSettingId && (
                    <p role="alert" className={s.error}>
                      {localformErrors.GeneralGlobalSettingId}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col xs={6} lg={2}>
                <Form.Group controlId="formGlobalSettingValue">
                  <Form.Label>
                    <FormattedMessage id="modelVersionManagement.settingValue" defaultMessage="Select value" />
                  </Form.Label>
                  <InputNumber
                    name="Value"
                    step=".1"
                    onInputChange={onChange}
                    value={modelVersionGlobalSetting.SelectedGlobalSetting.Value}
                    id="formGlobalSettingValue"
                    customClassName={`${localformErrors && localformErrors.Value ? s.formControlError : ''}`}
                  />
                  {localformErrors && localformErrors.Value && (
                    <p role="alert" className={s.error}>
                      {localformErrors.Value}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col xs={6}>
                <p>&nbsp;</p>
                <p className={s.smallItalic}>
                  {(selGlobalSettingObj && selGlobalSettingObj.MinimumValue !== undefined && selGlobalSettingObj.MinimumValue !== '') ||
                  (selGlobalSettingObj && selGlobalSettingObj.MaximumValue !== undefined && selGlobalSettingObj.MaximumValue !== '') ? (
                    <Fragment>
                      (
                      {selGlobalSettingObj && selGlobalSettingObj.MinimumValue !== undefined && selGlobalSettingObj.MinimumValue !== '' ? (
                        <Fragment>
                          <FormattedMessage id="modelVersionManagement.min" defaultMessage="Min" />
                          {` ${selGlobalSettingObj.MinimumValue}`}
                        </Fragment>
                      ) : (
                        ''
                      )}
                      {selGlobalSettingObj && selGlobalSettingObj.MaximumValue !== undefined && selGlobalSettingObj.MaximumValue !== '' ? (
                        <Fragment>
                          {selGlobalSettingObj && selGlobalSettingObj.MinimumValue !== undefined && selGlobalSettingObj.MinimumValue !== ''
                            ? `, `
                            : ''}
                          <FormattedMessage id="modelVersionManagement.max" defaultMessage="Max" />
                          {` ${selGlobalSettingObj.MaximumValue}`}
                        </Fragment>
                      ) : (
                        ''
                      )}
                      )
                    </Fragment>
                  ) : (
                    ''
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={ModelVersionGlobalSettingId ? 5 : 4} xs={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="modelVersionManagement.saveModelVersionThreshold" defaultMessage="SAVE" />
                </Button>
                {IsEdit && (
                  <Button
                    variant="outline-secondary"
                    className={classNames(s.btnCancel)}
                    onClick={defaultHandler}
                    noValidate
                    data-unittest="saveDefault"
                  >
                    <FormattedMessage id="modelVersionManagement.defaultValue" defaultMessage="DEFAULT" />
                  </Button>
                )}
                <Button
                  variant="outline-secondary"
                  className={classNames(s.btnCancel)}
                  onClick={cancelHandler}
                  noValidate
                  data-unittest="saveCancel"
                >
                  <FormattedMessage id="modelVersionManagement.cancelModelVersionThreshold" defaultMessage="CANCEL" />
                </Button>
              </Col>
              <Col lg={ModelVersionGlobalSettingId ? 7 : 5}></Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

ModelVersionGlobalSetting.defaultProps = {
  modelVersionGlobalSetting: {
    ...initialModelVersionState.modelVersionManager.selectedModelVersion.GlobalSettings
  }
};

ModelVersionGlobalSetting.propTypes = {
  saveModelVersionGlobalSetting: PropTypes.func.isRequired,
  changeModelVersionGlobalSetting: PropTypes.func.isRequired,
  modelVersionGlobalSetting: PropTypes.object.isRequired,
  unitGlobalSettings: PropTypes.array,
  cancelHandler: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionGlobalSetting);
