import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  GET_GLOBAL_SETTINGS,
  GLOBAL_SETTINGS_IS_LOADING,
  GLOBAL_SETTINGS_MANAGEMENT_ERROR,
  GLOBAL_SETTINGS_SET_CURRENT_PAGE,
  GLOBAL_SETTINGS_SET_PAGE_FILTER,
  GLOBAL_SETTINGS_SET,
  GLOBAL_SETTINGS_UPDATE_SAVE_STATUS,
  GLOBAL_SETTINGS_SET_CHANGE,
  GLOBAL_SETTINGS_SET_FILTERED_RESULTS
} from '../constants/index';

import {
  GENERALGLOBALSETTINGS_MANAGEMENT,
  CREATE_GENERALGLOBALSETTINGS,
  UPDATE_GENERALGLOBALSETTINGS,
  DELETE_GENERALGLOBALSETTINGS
} from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const getGlobalSettingsThunk = (offset, limit, filter, widgetCode) => dispatch => {
  dispatch(getGlobalSettings(widgetCode));
};

export const getGlobalSettings = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/globalsettings`,
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllGlobalSettingsListThunk(retData),
    onFailure: err => setGlobalSettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllGlobalSettingsListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let globalSettings = (retData && retData.globalSettings) || [];
  dispatch(setAllGlobalSettingsList(messageCode, globalSettings));
};

export const setAllGlobalSettingsList = (messageCode, globalSettings) => ({
  type: GET_GLOBAL_SETTINGS,
  payload: {
    data: {
      globalSettings: globalSettings,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setLoadingStatus = isLoading => ({
  type: GLOBAL_SETTINGS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const setGlobalSettingsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setGlobalSettingsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setGlobalSettingsError(messageCode, false));
  }, 2500);
};

export const setGlobalSettingsError = (messageCode, status) => {
  return {
    type: GLOBAL_SETTINGS_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setCurrentPage = currentPage => ({
  type: GLOBAL_SETTINGS_SET_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: GLOBAL_SETTINGS_SET_PAGE_FILTER,
  payload: { filter }
});

export const describeGlobalSettingsThunk = (GlobalSettingId, widgetCode) => dispatch => {
  dispatch(describeGlobalSettings(GlobalSettingId, widgetCode));
};

export const describeGlobalSettings = (GlobalSettingId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/globalsettings`,
    data: {
      GeneralGlobalSettingId: GlobalSettingId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setGlobalSettingsThunk(retData),
    onFailure: err => setGlobalSettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setGlobalSettingsThunk = retData => dispatch => {
  let data = retData.globalSettings;
  dispatch(setGlobalSettings(data));
};

export const setGlobalSettings = data => ({
  type: GLOBAL_SETTINGS_SET,
  payload: data
});

export const saveGlobalSettingsThunk = (globalSettings, widgetCode) => dispatch => {
  let url = '/management/globalsettings/save';
  let actionName = globalSettings.MarkAsDeleted
    ? DELETE_GENERALGLOBALSETTINGS
    : globalSettings.GlobalSettingId === 0
    ? CREATE_GENERALGLOBALSETTINGS
    : UPDATE_GENERALGLOBALSETTINGS;
  let log = logEntry(GENERALGLOBALSETTINGS_MANAGEMENT, actionName, globalSettings);
  dispatch(saveGlobalSettings(globalSettings, url, log, widgetCode));
};

export const saveGlobalSettings = (globalSettings, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setGlobalSettingsStatusThunk(retData, globalSettings),
    onFailure: err => setGlobalSettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      globalSettings: globalSettings,
      log: log
    },
    widgetcode: widgetCode
  });

export const setGlobalSettingsStatusThunk = (message, globalSettings) => dispatch => {
  dispatch(setGlobalSettingsStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    dispatch(setGlobalSettingsStatus(message, false, !globalSettings.MarkAsDeleted));
  }, 2500);
};

export const setGlobalSettingsStatus = (messageCode, status, redirect) => ({
  type: GLOBAL_SETTINGS_UPDATE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      redirect: redirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setGlobalSettingsChangeThunk = retData => dispatch => {
  dispatch(setGlobalSettingsChange(retData));
};

export const setGlobalSettingsChange = retData => ({
  type: GLOBAL_SETTINGS_SET_CHANGE,
  payload: retData
});

export const setGlobalSettingsFilteredDataThunk = retData => dispatch => {
  dispatch(setGlobalSettingsFilteredData(retData));
};

export const setGlobalSettingsFilteredData = retData => ({
  type: GLOBAL_SETTINGS_SET_FILTERED_RESULTS,
  payload: retData
});
