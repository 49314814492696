import React, { useEffect } from 'react';
import svgpanzoom from 'svg-pan-zoom';
import './PLCNextBasicDeviceStyle.css';
let schematicDiagram = null;
const PLCNextBasicSvg = sensorData => {
  //Ball valves
  //===========
  let { ShowSensors, unitComponentParts } = sensorData;

  //let clsshow_sensor = ShowSensors ? 'show-sensor' : 'hide-sensor';
  let clsshow_sensor = 'show-sensor';
  const svgElement = document.querySelector('#dv-schematics');
  var options = {
    zoomEnabled: true,
    mouseWheelZoomEnabled: sensorData.enableMouseZoom,
    controlIconsEnabled: true,
    fit: true,
    center: true,
    contain: true
  };

  if (svgElement) {
    schematicDiagram = svgpanzoom(svgElement, options);

    if (sensorData.enableMouseZoom) {
      schematicDiagram.enableMouseWheelZoom();
    } else {
      schematicDiagram.disableMouseWheelZoom();
    }
  }

  const onResize = () => {
    if (schematicDiagram) {
      // schematicDiagram.reset();
      schematicDiagram.resize();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
  }, [onResize]); // Only initialize listener on initial render

  // switch state data
  let swt101 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.swt101_state;
  let swt102 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.swt102_state;
  let swt103 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.swt103_state;
  let swt104 = sensorData && sensorData.SensorData && sensorData.SensorData.state && sensorData.SensorData.state.swt104_state;

  // potential meter sensor data

  let pot101 = sensorData && sensorData.SensorData && sensorData.SensorData.pot101_voltage;
  let pot101_value = typeof pot101 == 'number' ? pot101.toFixed(1) : '-';

  // colour the sensors
  let cls_swt101 = swt101 === undefined ? 'sen-amber' : swt101 === 'OFF' ? 'sen-red' : swt101 === 'ON' ? 'sen-green' : 'sen-amber';
  let cls_swt101_off = swt101 === undefined ? 'show-sensor' : swt101 === 'OFF' ? 'show-sensor' : 'hide-sensor';
  let cls_swt101_on = swt101 === undefined ? 'hide-sensor' : swt101 === 'ON' ? 'show-sensor' : 'hide-sensor';

  let cls_swt102 = swt102 === undefined ? 'sen-amber' : swt102 === 'OFF' ? 'sen-red' : swt102 === 'ON' ? 'sen-green' : 'sen-amber';
  let cls_swt102_off = swt102 === undefined ? 'show-sensor' : swt102 === 'OFF' ? 'show-sensor' : 'hide-sensor';
  let cls_swt102_on = swt102 === undefined ? 'hide-sensor' : swt102 === 'ON' ? 'show-sensor' : 'hide-sensor';

  let cls_swt103 = swt103 === undefined ? 'sen-amber' : swt103 === 'OFF' ? 'sen-red' : swt103 === 'ON' ? 'sen-green' : 'sen-amber';
  let cls_swt103_off = swt103 === undefined ? 'show-sensor' : swt103 === 'OFF' ? 'show-sensor' : 'hide-sensor';
  let cls_swt103_on = swt103 === undefined ? 'hide-sensor' : swt103 === 'ON' ? 'show-sensor' : 'hide-sensor';

  let cls_swt104 = swt104 === undefined ? 'sen-amber' : swt104 === 'OFF' ? 'sen-red' : swt104 === 'ON' ? 'sen-green' : 'sen-amber';
  let cls_swt104_off = swt104 === undefined ? 'show-sensor' : swt104 === 'OFF' ? 'show-sensor' : 'hide-sensor';
  let cls_swt104_on = swt104 === undefined ? 'hide-sensor' : swt104 === 'ON' ? 'show-sensor' : 'hide-sensor';

  let clspot101_text = 'voltage-font';

  /*
   ********************************************* SVG TO SVGR CONVERSION***************************************
    Following online SVG to SVGR Converter Tool is used 
      https://www.fetoolkit.io/widget/svg-converter  

    Step  1: Copy SVG to INPUT section
    Step  2: Disable Clean Up Ids and Remove Unknowns and Defaults from Settings
    Step  3: Select Option JSX
    Step  4: Copy the converted SVG and paste it in notepad++
    Step  5: Remove JSX code, xmlns tag and ...props attribute
    Step  6: Replace {"{# with {
    Step  7: Replace #}"} with }
    Step  8: Replace "{# with {
    Step  9: Replace #}" with }
    Step  10: Set SVG tag properties as given below
              width="258.43408mm"
              height="168.71985mm"
              viewBox="0 0 258.43408 168.71985"
              id="dv-schematics"
    Step  11: Copy the SVG from notepad+ and replace SVG section below

  */

  return (
    <svg width="258.43408mm" height="168.71985mm" viewBox="0 0 258.43408 168.71985" id="dv-schematics">
      <defs id="defs1">
        <path id="rect3" d="M25.409 67.152h211.437v103.45H25.409z" />
        <path id="rect3-1" d="M25.409 67.152h211.437v103.45H25.409z" />
      </defs>
      <g id="MainLayer" transform="matrix(.85316 0 0 .9033 7.773 -3.624)">
        <g id="g_swt101" transform="matrix(1.16997 0 0 1.01151 11.585 -1.388)">
          <path
            style={{
              fill: '#c00'
            }}
            d="M55.322 17.111h52.016a2.993 2.993 0 0 1 2.992 2.992v24.016a2.993 2.993 0 0 1-2.992 2.992H55.322a2.993 2.993 0 0 1-2.992-2.992V20.103a2.993 2.993 0 0 1 2.992-2.992z"
            id="g_swt101_path"
            className={cls_swt101}
          />
          <g id="g_swt101_block_right" className={cls_swt101_on} transform="translate(52.437 2.628)">
            <path
              style={{
                fill: '#fff',
                stroke: '#e7eced',
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              d="M30.992 14.5h24.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H30.992A2.993 2.993 0 0 1 28 41.508V17.492a2.993 2.993 0 0 1 2.992-2.992Z"
              id="path2"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_3"
              d="M46 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_2"
              d="M43 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_1"
              d="M40 32.5v-6"
            />
          </g>
          <g
            id="g_swt101_block_left"
            style={{
              display: 'block'
            }}
            transform="translate(25.26 2.869)"
            className={cls_swt101_off}
          >
            <path
              style={{
                fill: '#fff',
                stroke: '#e7eced',
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              d="M30.992 14.5h24.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H30.992A2.993 2.993 0 0 1 28 41.508V17.492a2.993 2.993 0 0 1 2.992-2.992Z"
              id="path2-5"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_3-2"
              d="M46 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_2-8"
              d="M43 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_1-3"
              d="M40 32.5v-6"
            />
          </g>
          <text
            transform="scale(.92982 1.07548)"
            id="swt101_text"
            fontSize={12.161}
            fontFamily="Helvetica"
            xmlSpace="preserve"
            className={clsshow_sensor}
            x={-0.938}
            y={34.603}
            style={{
              strokeWidth: 0.243215
            }}
          >
            <tspan
              id="tspan3"
              style={{
                strokeWidth: 0.243215
              }}
            >
              {'SWT101'}
            </tspan>
          </text>
        </g>
        <g id="g_swt102" transform="matrix(1.16997 0 0 1.01151 74.22 45.887)">
          <path
            style={{
              fill: '#c00'
            }}
            d="M2.992 14.5h52.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H2.992A2.993 2.993 0 0 1 0 41.508V17.492A2.993 2.993 0 0 1 2.992 14.5Z"
            id="g_swt102_path"
            className={cls_swt102}
          />
          <g id="g_swt102_block_right" className={cls_swt102_on}>
            <path
              style={{
                fill: '#fff',
                stroke: '#e7eced',
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              d="M30.992 14.5h24.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H30.992A2.993 2.993 0 0 1 28 41.508V17.492a2.993 2.993 0 0 1 2.992-2.992Z"
              id="path2-0"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_3-3"
              d="M46 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_2-86"
              d="M43 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_1-1"
              d="M40 32.5v-6"
            />
          </g>
          <g
            id="g_swt102_block_left"
            style={{
              display: 'block'
            }}
            transform="translate(-27.07 .258)"
            className={cls_swt102_off}
          >
            <path
              style={{
                fill: '#fff',
                stroke: '#e7eced',
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              d="M30.992 14.5h24.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H30.992A2.993 2.993 0 0 1 28 41.508V17.492a2.993 2.993 0 0 1 2.992-2.992Z"
              id="path2-5-9"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_3-2-5"
              d="M46 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_2-8-9"
              d="M43 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_1-3-3"
              d="M40 32.5v-6"
            />
          </g>
          <text
            id="swt102_text"
            fontSize={12.161}
            fontFamily="Helvetica"
            xmlSpace="preserve"
            className={clsshow_sensor}
            x={-58.428}
            y={30.855}
            style={{
              display: 'block',
              strokeWidth: 0.243215
            }}
            transform="scale(.92982 1.07548)"
          >
            <tspan
              id="tspan3-6"
              style={{
                strokeWidth: 0.243215
              }}
              x={-58.428}
              y={30.855}
            >
              {'SWT102'}
            </tspan>
          </text>
        </g>
        <g id="g_swt103" transform="matrix(1.16997 0 0 1.01151 75.382 90.44)">
          <path
            style={{
              fill: '#c00'
            }}
            d="M2.992 14.5h52.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H2.992A2.993 2.993 0 0 1 0 41.508V17.492A2.993 2.993 0 0 1 2.992 14.5Z"
            id="g_swt103_path"
            className={cls_swt103}
          />
          <g id="g_swt103_block_right" className={cls_swt103_on}>
            <path
              style={{
                fill: '#fff',
                stroke: '#e7eced',
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              d="M30.992 14.5h24.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H30.992A2.993 2.993 0 0 1 28 41.508V17.492a2.993 2.993 0 0 1 2.992-2.992Z"
              id="path2-0-2"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_3-3-2"
              d="M46 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_2-86-0"
              d="M43 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_1-1-9"
              d="M40 32.5v-6"
            />
          </g>
          <g
            id="g_swt103_block_left"
            style={{
              display: 'block'
            }}
            transform="translate(-27.07 .258)"
            className={cls_swt103_off}
          >
            <path
              style={{
                fill: '#fff',
                stroke: '#e7eced',
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              d="M30.992 14.5h24.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H30.992A2.993 2.993 0 0 1 28 41.508V17.492a2.993 2.993 0 0 1 2.992-2.992Z"
              id="path2-5-9-8"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_3-2-5-5"
              d="M46 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_2-8-9-8"
              d="M43 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_1-3-3-1"
              d="M40 32.5v-6"
            />
          </g>
          <text
            id="swt103_text"
            fontSize={12.161}
            fontFamily="Helvetica"
            xmlSpace="preserve"
            className={clsshow_sensor}
            x={-59.967}
            y={32.032}
            style={{
              display: 'block',
              strokeWidth: 0.243215
            }}
            transform="scale(.92982 1.07548)"
          >
            <tspan
              id="tspan3-6-0"
              style={{
                strokeWidth: 0.243215
              }}
              x={-59.967}
              y={32.032}
            >
              {'SWT103'}
            </tspan>
          </text>
        </g>
        <g id="g_swt104" transform="matrix(1.16997 0 0 1.01151 77.058 137.604)">
          <path
            style={{
              fill: '#c00'
            }}
            d="M2.992 14.5h52.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H2.992A2.993 2.993 0 0 1 0 41.508V17.492A2.993 2.993 0 0 1 2.992 14.5Z"
            id="g_swt104_path"
            className={cls_swt104}
          />
          <g id="g_swt104_block_right" className={cls_swt104_on}>
            <path
              style={{
                fill: '#fff',
                stroke: '#e7eced',
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              d="M30.992 14.5h24.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H30.992A2.993 2.993 0 0 1 28 41.508V17.492a2.993 2.993 0 0 1 2.992-2.992Z"
              id="path2-0-2-0"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_3-3-2-6"
              d="M46 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_2-86-0-4"
              d="M43 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_1-1-9-6"
              d="M40 32.5v-6"
            />
          </g>
          <g
            id="g_swt104_block_left"
            style={{
              display: 'block'
            }}
            transform="translate(-27.07 .258)"
            className={cls_swt104_off}
          >
            <path
              style={{
                fill: '#fff',
                stroke: '#e7eced',
                strokeWidth: 2,
                strokeMiterlimit: 10
              }}
              d="M30.992 14.5h24.016A2.993 2.993 0 0 1 58 17.492v24.016a2.993 2.993 0 0 1-2.992 2.992H30.992A2.993 2.993 0 0 1 28 41.508V17.492a2.993 2.993 0 0 1 2.992-2.992Z"
              id="path2-5-9-8-5"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_3-2-5-5-0"
              d="M46 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_2-8-9-8-9"
              d="M43 32.5v-6"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#afb6bb',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeMiterlimit: 10
              }}
              id="on_line_1-3-3-1-2"
              d="M40 32.5v-6"
            />
          </g>
          <text
            id="swt104_text"
            fontSize={12.161}
            fontFamily="Helvetica"
            xmlSpace="preserve"
            className={clsshow_sensor}
            x={-61.56}
            y={30.201}
            style={{
              display: 'block',
              strokeWidth: 0.243215
            }}
            transform="scale(.92982 1.07548)"
          >
            <tspan
              id="tspan3-6-0-8"
              style={{
                strokeWidth: 0.243215
              }}
              x={-61.56}
              y={30.201}
            >
              {'SWT104'}
            </tspan>
          </text>
        </g>
        <g id="g_pot101" transform="matrix(.26458 0 0 .26458 176.856 47.804)">
          <text
            id="POT101_text"
            fontSize={50}
            fontFamily="Helvetica"
            xmlSpace="preserve"
            className={clsshow_sensor}
            x={57.379}
            y={-18.892}
            style={{
              strokeWidth: 1
            }}
          >
            <tspan
              id="tspan3-0"
              style={{
                strokeWidth: 1
              }}
              x={57.379}
              y={-18.892}
            >
              {'POT101'}
            </tspan>
          </text>
          <g id="g1" transform="matrix(.92217 0 0 .89318 -16.24 10.685)">
            <g id="g4" transform="translate(-22.879)">
              <path
                style={{
                  fill: '#cbd4d8'
                }}
                d="M425.22 512H182.237c-24.298 0-43.39-19.092-43.39-43.39v-43.39c0-5.207 3.471-8.678 8.678-8.678 5.207 0 8.678 3.471 8.678 8.678v43.39c0 14.753 11.281 26.034 26.034 26.034H425.22c14.753 0 26.034-11.281 26.034-26.034V251.661c0-5.207 3.471-8.678 8.678-8.678 5.207 0 8.678 3.471 8.678 8.678V468.61c0 24.298-19.091 43.39-43.39 43.39"
                id="path1"
              />
              <path
                style={{
                  fill: '#cbd4d8'
                }}
                d="M373.153 477.288H251.661c-14.753 0-26.034-11.281-26.034-26.034V425.22c0-5.207 3.471-8.678 8.678-8.678 5.207 0 8.678 3.471 8.678 8.678v26.034c0 5.207 3.471 8.678 8.678 8.678h121.492c5.207 0 8.678-3.471 8.678-8.678V251.661c0-5.207 3.471-8.678 8.678-8.678 5.207 0 8.678 3.471 8.678 8.678v199.593c-.001 14.753-11.282 26.034-26.034 26.034"
                id="path2-3"
              />
              <path
                style={{
                  fill: '#cbd4d8'
                }}
                d="M390.508 182.237c-5.207 0-8.678-3.471-8.678-8.678v-26.034c0-5.207 3.471-8.678 8.678-8.678 5.207 0 8.678 3.471 8.678 8.678v26.034c0 5.207-3.471 8.678-8.678 8.678"
                id="path3"
              />
              <path
                style={{
                  fill: '#cbd4d8'
                }}
                d="M459.932 182.237c-5.207 0-8.678-3.471-8.678-8.678v-26.034c0-5.207 3.471-8.678 8.678-8.678 5.207 0 8.678 3.471 8.678 8.678v26.034c0 5.207-3.471 8.678-8.678 8.678"
                id="path4"
              />
            </g>
            <path
              style={{
                fill: '#f4ba48'
              }}
              d="M232.253 425.22H103.82c-51.2 0-91.986-39.051-91.986-86.78C33.528 227.362 35.264 192.65 17.04 85.911l-5.207-25.166C11.833 26.902 40.47 0 76.05 0h183.973c35.58 0 64.217 26.902 64.217 60.746l-5.207 25.166c-18.224 111.078-16.488 145.79 5.207 252.529 0 47.728-40.787 86.779-91.987 86.779"
              id="path5"
            />
            <path
              style={{
                fill: '#75b93f'
              }}
              d="M263.494 173.559H72.58c-9.546 0-17.356-7.81-17.356-17.356V60.746c0-9.546 7.81-17.356 17.356-17.356h190.915c9.546 0 17.356 7.81 17.356 17.356v95.458c0 9.545-7.81 17.355-17.356 17.355"
              id="path6"
            />
            <path
              style={{
                fill: '#2c3c42'
              }}
              d="M168.036 381.831c-47.729 0-86.78-39.051-86.78-86.78s39.051-86.78 86.78-86.78c47.73 0 86.78 39.051 86.78 86.78s-39.05 86.78-86.78 86.78"
              id="path7"
            />
            <g id="g9" transform="translate(-22.879)">
              <path
                style={{
                  fill: '#e15649'
                }}
                d="M407.864 251.661h-34.712v-60.746c0-9.546 7.81-17.356 17.356-17.356 9.546 0 17.356 7.81 17.356 17.356z"
                id="path8"
              />
              <path
                style={{
                  fill: '#e15649'
                }}
                d="M477.288 251.661h-34.712v-60.746c0-9.546 7.81-17.356 17.356-17.356 9.546 0 17.356 7.81 17.356 17.356z"
                id="path9"
              />
            </g>
            <g id="g12" transform="translate(-22.879)">
              <text
                x={194.333}
                y={140.369}
                fill="#b3e68a"
                id="pot101_value"
                textAnchor="middle"
                transform="scale(.98416 1.0161)"
                style={{
                  strokeWidth: 1.10186
                }}
                fontSize={20}
                className={clspot101_text}
              >
                {pot101_value}
              </text>
            </g>
            <path
              style={{
                fill: '#51656b'
              }}
              d="m101.216 240.38 122.36 122.359c4.338-3.471 8.677-7.81 12.148-12.149L113.365 228.23c-4.339 3.472-8.678 7.811-12.149 12.15"
              id="path13"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PLCNextBasicSvg;
