import { initialTasksState } from './initialState';

import {
  TASKS_ERROR,
  GET_ALL_TASKS_DETAILS,
  TASKS_IS_LOADING,
  SET_TASKS_CURRENT_PAGE,
  SET_TASKS_PAGE_FILTER,
  SET_TASKS_CHANGE,
  SET_TASK,
  UPDATE_TASKS_SAVE_STATUS,
  SET_TASKS_FILTERED_RESULTS,
  SET_TASKS_COMMENT_CHANGE,
  RESET_TASKS,
  GET_ALL_TASKS_FILTERS
} from '../constants/index';

const tasksReducer = (state = initialTasksState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_TASKS_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...data
        }
      };
    }

    case SET_TASKS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          currentPage
        }
      };
    }

    case SET_TASKS_PAGE_FILTER: {
      const { filterBy } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          filterBy: {
            ...filterBy
          }
        }
      };
    }

    case GET_ALL_TASKS_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...data
        }
      };
    }

    case UPDATE_TASKS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...data
        }
      };
    }

    case TASKS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isLoading: isLoading
        }
      };
    }

    case TASKS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...data
        }
      };
    }

    case SET_TASK: {
      let { selectedTask, isOpSuccessful, showBanner } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          selectedTask: {
            ...selectedTask
          },
          isOpSuccessful: isOpSuccessful,
          showBanner: showBanner
        }
      };
    }

    case SET_TASKS_CHANGE: {
      let { selectedTask, isOpSuccessful } = payload;

      return {
        ...state,
        tasks: {
          ...state.tasks,
          selectedTask: {
            ...state.tasks.selectedTask,
            ...selectedTask
          },
          isOpSuccessful: isOpSuccessful || false
        }
      };
    }

    case SET_TASKS_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...filteredResults
        }
      };
    }

    case SET_TASKS_COMMENT_CHANGE: {
      let { Comments } = payload;

      return {
        ...state,
        tasks: {
          ...state.tasks,
          selectedTask: {
            ...state.tasks.selectedTask,
            Comments: Comments
          }
        }
      };
    }

    case RESET_TASKS: {
      let { tasks } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...tasks
        }
      };
    }

    default:
      return state;
  }
};

export default tasksReducer;
