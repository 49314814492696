import React from 'react';
import { FormattedMessage } from 'react-intl';

export const EVENT_TYPES = {
  // 0: { name: 'SystemError' },
  1: { name: 'Warnings' },
  2: { name: 'Alarms' },
  3: { name: 'Sequence' },
  4: { name: 'Events' }
};

export const ALARMS = {
  1: { name: 'WARNING - High pressure exceeded at PT01' },
  4: { name: 'WARNING - High pressure exceeded at PT02' },
  9: { name: 'OCP WARNING - Low flow exceeded at FT01' },
  12: { name: 'OCP WARNING - High turbidity exceeded at TB01' },
  14: { name: 'OCP WARNING - High turbidity exceeded at TB02' },
  16: { name: 'CCP WARNING - High turbidity exceeded at TB03' },
  18: { name: 'CCP WARNING - High conductivity exceeded at CT01' },
  22: { name: 'CCP WARNING - Low UV transmission exceeded in' },
  25: { name: 'OCP STOP - Min level exceeded in TK02' },
  28: { name: 'OCP WARNING - Low differential pressure over pre-filters' },
  29: { name: 'OCP WARNING - High differential pressure over pre-filters' },
  32: { name: 'CCP WARNING - Low differential pressure over membrane UF01' },
  33: { name: 'OCP WARNING - High differential pressure over membrane UF01' },
  38: { name: 'WARNING - High pressure exceeded at PT01' },
  41: { name: 'WARNING - High pressure exceeded at PT02' },
  44: { name: 'WARNING - Low flow exceeded at FT01' },
  51: { name: 'WARNING - High pressure exceeded at PT01' },
  54: { name: 'WARNING - High pressure exceeded at PT03' },
  57: { name: 'WARNING - Low flow exceeded at FT01' },
  65: { name: 'CCP WARNING - Low differential pressure over membrane UF01' },
  66: { name: 'OCP WARNING - High differential pressure over membrane UF01' },
  69: { name: 'WARNING - High pressure exceeded at PT02' },
  72: { name: 'WARNING - High pressure exceeded at PT02' },
  75: { name: 'WARNING - Low flow exceeded at FT02' },
  79: { name: 'OCP WARNING - High pressure exceeded at PT03' },
  82: { name: 'WARNING - Measurement fail at membrane UF02' },
  84: { name: 'OCP WARNING - High pressure exceeded at PT01' }
};

export const SEQUENCES = {
  0: {
    name: 'Initialise',
    showCommand: false,
    shadowKey: 'initialise',
    description: <FormattedMessage id="command.initialise" defaultMessage="Initialise" />
  },

  1: {
    name: 'Forward flush',
    showCommand: true,
    shadowKey: 'membraneforwardflush',
    description: <FormattedMessage id="command.membraneforwardflush" defaultMessage="Forward flush" />
  },

  2: {
    name: 'Normal processing',
    showCommand: true,
    shadowKey: 'normalprocessing',
    description: <FormattedMessage id="command.normalprocessing" defaultMessage="Normal processing" />
  },

  3: {
    name: 'Back flush',
    showCommand: false,
    shadowKey: 'membranebackflush',
    description: <FormattedMessage id="command.membranebackflush" defaultMessage="Back flush" />
  },

  4: { name: 'Flush', showCommand: true, shadowKey: 'flush', description: <FormattedMessage id="command.flush" defaultMessage="Flush" /> },

  5: {
    name: 'T1 drain',
    showCommand: true,
    shadowKey: 'tank1drain',
    description: <FormattedMessage id="command.tank1drain" defaultMessage="T1 drain" />
  },

  6: {
    name: 'Pre-filter back flush',
    showCommand: true,
    shadowKey: 'prefilterbackflush',
    description: <FormattedMessage id="command.prefilterbackflush" defaultMessage="Pre-filter back flush" />
  },

  7: {
    name: 'Direct integrity test',
    showCommand: true,
    shadowKey: 'dit',
    description: <FormattedMessage id="command.dit" defaultMessage="Direct integrity test" />
  },

  20: { name: 'Run', showCommand: false, shadowKey: 'run', description: <FormattedMessage id="command.run" defaultMessage="Run" /> },

  21: { name: 'Wait', showCommand: false, shadowKey: 'wait', description: <FormattedMessage id="command.wait" defaultMessage="Wait" /> },

  22: {
    name: 'Emergency stop',
    showCommand: false,
    shadowKey: 'emergencystop',
    description: <FormattedMessage id="command.emergencystop" defaultMessage="Emergency stop" />
  },

  23: {
    name: 'Transition',
    showCommand: false,
    shadowKey: 'transition',
    description: <FormattedMessage id="command.transition" defaultMessage="Transition" />
  },

  24: { name: 'Stop', showCommand: true, shadowKey: 'stop', description: <FormattedMessage id="command.stop" defaultMessage="Stop" /> },

  25: { name: 'Start', showCommand: true, shadowKey: 'start', description: <FormattedMessage id="command.start" defaultMessage="Start" /> },

  26: {
    name: 'Wait for AWS',
    showCommand: false,
    shadowKey: 'waitforaws',
    description: <FormattedMessage id="command.waitforaws" defaultMessage="Wait for AWS" />
  },

  27: {
    name: 'Hardware fault',
    showCommand: false,
    shadowKey: 'hardwarefault',
    description: <FormattedMessage id="command.hardwarefault" defaultMessage="Hardware fault" />
  },

  30: {
    name: 'Alarm',
    showCommand: false,
    shadowKey: 'alarm',
    description: <FormattedMessage id="command.alarm" defaultMessage="Alarm" />
  },

  50: { name: 'None', showCommand: false, shadowKey: 'none', description: <FormattedMessage id="command.none" defaultMessage="None" /> }
};

export const ERRORS = {
  0: { name: 'STOP - Min pressure exceeded at PT01' },
  2: { name: 'STOP - Max pressure exceeded at PT01' },
  3: { name: 'STOP - Min pressure exceeded at PT02' },
  5: { name: 'STOP - Max pressure exceeded at PT02' },
  6: { name: 'STOP - Min pressure exceeded at PT03' },
  7: { name: 'STOP - Max pressure exceeded at PT03' },
  8: { name: 'OCP STOP - Min flow exceeded at FT01' },
  10: { name: 'CCP STOP - Max flow exceeded at FT01' },
  11: { name: 'CCP STOP - Max flow exceeded at FT02' },
  13: { name: 'OCP STOP - Max turbidity exceeded at TB01' },
  15: { name: 'OCP STOP - Max turbidity exceeded at TB02' },
  17: { name: 'CCP STOP - Max turbidity exceeded at TB03' },
  19: { name: 'CCP STOP - Max conductivity exceeded at CT01' },
  20: { name: 'CCP STOP - Min UV intensity exceeded in' },
  21: { name: 'CCP STOP - Min UV transmission exceeded in' },
  23: { name: 'OCP STOP - Min level exceeded in TK01' },
  24: { name: 'STOP - Max level exceeded in TK01' },
  26: { name: 'STOP - Max level exceeded in TK02' },
  27: { name: 'OCP STOP - Min differential pressure over pre-filters' },
  30: { name: 'OCP STOP - Max differential pressure over pre-filters' },
  31: { name: 'CCP STOP - Min differential pressure over membrane UF0' },
  34: { name: 'OCP STOP - Max differential pressure over membrane UF01' },
  35: { name: 'OCP STOP - Min between flow meters' },
  36: { name: 'OCP STOP - Max between flow meters' },
  37: { name: 'STOP - Min pressure exceeded at PT01' },
  39: { name: 'STOP - Max pressure exceeded at PT01' },
  40: { name: 'STOP - Min pressure exceeded at PT02' },
  42: { name: 'STOP - Max pressure exceeded at PT02' },
  43: { name: 'STOP - Min flow exceeded at FT01' },
  45: { name: 'STOP - Max flow exceeded at FT01' },
  46: { name: 'STOP - Min level exceeded in TK01' },
  47: { name: 'STOP - Max level exceeded in TK01' },
  48: { name: 'STOP - Min level exceeded in TK02' },
  49: { name: 'STOP - Max level exceeded in TK02' },
  50: { name: 'STOP - Min pressure exceeded at PT01' },
  52: { name: 'STOP - Max pressure exceeded at PT01' },
  53: { name: 'STOP - Min pressure exceeded at PT03' },
  55: { name: 'STOP - Max pressure exceeded at PT03' },
  56: { name: 'STOP - Min flow exceeded at FT01' },
  58: { name: 'STOP - Max flow exceeded at FT01' },
  59: { name: 'STOP - Max flow exceeded at FT02' },
  60: { name: 'STOP - Min level exceeded in TK01' },
  61: { name: 'STOP - Max level exceeded in TK01' },
  62: { name: 'STOP - Min level exceeded in TK02' },
  63: { name: 'STOP - Max level exceeded in TK02' },
  64: { name: 'CCP STOP - Min differential pressure over membrane UF01' },
  67: { name: 'OCP STOP - Max differential pressure over membrane UF01' },
  68: { name: 'STOP - Min pressure exceeded at PT02' },
  70: { name: 'STOP - Max pressure exceeded at PT02' },
  71: { name: 'STOP - Min pressure exceeded at PT01' },
  73: { name: 'STOP - Max pressure exceeded at PT03' },
  74: { name: 'STOP - Min flow exceeded at FT01' },
  76: { name: 'STOP - Max flow exceeded at FT03' },
  77: { name: 'STOP - Min level exceeded in TK02' },
  78: { name: 'STOP - Max level exceeded in TK02' },
  80: { name: 'OCP STOP - Max pressure exceeded at PT03' },
  81: { name: 'CCP STOP - Pressurisation timeout fail at membrane UF01' },
  83: { name: 'CCP STOP - Test fail at membrane UF03' },
  85: { name: 'OCP STOP - Max pressure exceeded at PT01' },
  200: { name: 'AWS connection loss' },
  201: { name: 'I2C error' }
};

export const SENSORS = {
  ct01: {
    unit: 'Siemens',
    description: <FormattedMessage id="sensorTable.ct01" defaultMessage="Conductivity transmitter (+ temperature)" />
  },
  ft01: { unit: 'l/min', description: <FormattedMessage id="sensorTable.ft01" defaultMessage="Post-pump flowrate" /> },
  ft02: { unit: 'l/min', description: <FormattedMessage id="sensorTable.ft02" defaultMessage="UV Flowrate" /> },
  pt01: { unit: 'Bar', description: <FormattedMessage id="sensorTable.pt01" defaultMessage="Post-pump pressure transmitter" /> },
  pt01pt02: { unit: 'Bar', description: <FormattedMessage id="sensorTable.pt01pt02" defaultMessage="Pressure diff across pre-filters" /> },
  pt02: { unit: 'Bar', description: <FormattedMessage id="sensorTable.pt02" defaultMessage="Pre-membrane pressure transmitter" /> },
  pt02pt03: { unit: 'Bar', description: <FormattedMessage id="sensorTable.pt02pt03" defaultMessage="Pressure diff across membrane" /> },
  pt03: { unit: 'Bar', description: <FormattedMessage id="sensorTable.pt03" defaultMessage="Post-membrane pressure transmitter" /> },
  tb01: { unit: 'TBD', description: <FormattedMessage id="sensorTable.tb01" defaultMessage="Incoming Turbidity transmitter" /> },
  tb02: { unit: 'TBD', description: <FormattedMessage id="sensorTable.tb02" defaultMessage="Pre-membrane Turbidity transmitter" /> },
  tb03: { unit: 'TBD', description: <FormattedMessage id="sensorTable.tb03" defaultMessage="Post-membrane turbidity transmitter" /> },
  uvi01: { unit: 'UVI', description: <FormattedMessage id="sensorTable.uvi01" defaultMessage="UV Intensity" /> },
  uvt01: { unit: 'UVI', description: <FormattedMessage id="sensorTable.uvt01" defaultMessage="UV Transmissivity" /> },
  tk01: { unit: 'litres', description: <FormattedMessage id="sensorTable.tk01" defaultMessage="Tank 1 analogue level transmitter" /> },
  tk02: { unit: 'litres', description: <FormattedMessage id="sensorTable.tk02" defaultMessage="Tank 2 analogue level transmitter" /> },
  // lsl01: { unit: 'litres', description: <FormattedMessage id="sensorTable.lsl01" defaultMessage="Tank 1, low level switch" /> },
  cs01: { unit: 'Amps', description: <FormattedMessage id="sensorTable.cs01" defaultMessage="SBC Current Sensor" /> },
  cs02: { unit: 'Amps', description: <FormattedMessage id="sensorTable.cs02" defaultMessage="System Current Sensor" /> }
};

export const THRESHOLD_TABLE_STRUCTURE = {
  processFlow: {
    sensor: { title: 'Sensor', description: <FormattedMessage id="thresholdTable.sensor" defaultMessage="Sensor" /> },
    units: { title: 'Units', description: <FormattedMessage id="thresholdTable.units" defaultMessage="Units" /> },
    high: { title: 'High', description: <FormattedMessage id="thresholdTable.high" defaultMessage="High" /> },
    low: { title: 'Low', description: <FormattedMessage id="thresholdTable.low" defaultMessage="Low" /> }
  },
  sequence: {
    sensor: { title: 'Sensor', description: <FormattedMessage id="thresholdTable.sensor" defaultMessage="Sensor" /> },
    units: { title: 'Units', description: <FormattedMessage id="thresholdTable.units" defaultMessage="Units" /> },
    min: { title: 'Min', description: <FormattedMessage id="thresholdTable.min" defaultMessage="Min" /> },
    low: { title: 'Low', description: <FormattedMessage id="thresholdTable.low" defaultMessage="Low" /> },
    clow: { title: 'Critical Low', description: <FormattedMessage id="thresholdTable.criticallow" defaultMessage="Critical Low" /> },
    chigh: { title: 'Critical High', description: <FormattedMessage id="thresholdTable.criticalhigh" defaultMessage="Critical High" /> },
    high: { title: 'High', description: <FormattedMessage id="thresholdTable.high" defaultMessage="High" /> },
    max: { title: 'Max', description: <FormattedMessage id="thresholdTable.max" defaultMessage="Max" /> }
  },
  global: {
    sensor: { title: 'Sensor', description: <FormattedMessage id="thresholdTable.sensor" defaultMessage="Sensor" /> },
    units: { title: 'Units', description: <FormattedMessage id="thresholdTable.units" defaultMessage="Units" /> },
    value: { title: 'Value', description: <FormattedMessage id="thresholdTable.value" defaultMessage="Value" /> }
  },
  sensorcalibration: {
    sensor: { title: 'Sensor', description: <FormattedMessage id="thresholdTable.sensor" defaultMessage="Sensor" /> },
    value: { title: 'Value', description: <FormattedMessage id="thresholdTable.value" defaultMessage="Value" /> }
  }
};

export const GLOBAL_THRESHOLDS = {
  closevalvedelaytimer: { unit: 'seconds', description: 'Close valve delay timer' },
  flushpumpdelaytimer: { unit: 'seconds', description: 'Flush pump delay timer' },
  numberofsamples: { unit: 'seconds', description: 'Number of samples' }
};

export const CALIBRATION_THRESHOLDS = {
  t1mvalue: { description: 'T1 m value' },
  t1cvalue: { description: 'T1 c value' },
  t2mvalue: { description: 'T2 m value' },
  t2cvalue: { description: 'T2 c value' }
};

export const EVENT_CATEGORY = {
  0: { name: 'CCP', value: 'CCP' },
  1: { name: 'OCP', value: 'OCP' },
  2: { name: 'SP', value: 'SP' },
  3: { name: 'N/A', value: 'N/A' }
};
