import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';
import uuidv4 from 'uuid/v4';

import {
  CALIBRATION_ERROR,
  GET_ALL_CALIBRATION_DETAILS,
  CALIBRATION_IS_LOADING,
  SET_CALIBRATION_CURRENT_PAGE,
  SET_CALIBRATION_PAGE_FILTER,
  SET_CALIBRATION_CHANGE,
  SET_CALIBRATION,
  UPDATE_CALIBRATION_SAVE_STATUS,
  SET_CALIBRATION_RESULT_CHANGE,
  SET_CALIBRATION_CONSTANT_CHANGE,
  SET_CALIBRATION_COMMENT_CHANGE,
  CALIBRATION_VIEW_STATUS,
  SET_CALIBRATION_CONST
} from '../constants/index';

import {
  CALIBRATION_MANAGEMENT,
  CREATE_CALIBRATION,
  UPDATE_CALIBRATION,
  DELETE_CALIBRATION,
  CREATE_CALIBRATION_COMMENT,
  UPDATE_UNIT_SHADOW
} from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setCalibrationErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setCalibrationError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setCalibrationError(messageCode, false));
  }, 2500);
};

export const setCalibrationError = (messageCode, status) => {
  return {
    type: CALIBRATION_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: CALIBRATION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getCalibrationThunk = (unitSerialNumber, filterObj, widgetCode) => dispatch => {
  dispatch(getCalibration(unitSerialNumber, filterObj, widgetCode));
};

export const getCalibration = (unitSerialNumber, filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${unitSerialNumber}/calibrations`,
    data: {
      OrgId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      unitSerialNumber: unitSerialNumber,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllCalibrationListThunk(retData),
    onFailure: err => setCalibrationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllCalibrationListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let calibration = (retData && retData.calibrations) || [];
  dispatch(setAllCalibrationList(messageCode, calibration));
};

export const setAllCalibrationList = (messageCode, calibration) => ({
  type: GET_ALL_CALIBRATION_DETAILS,
  payload: {
    data: {
      CalibrationList: calibration,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_CALIBRATION_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_CALIBRATION_PAGE_FILTER,
  payload: { filter }
});

export const describeCalibrationThunk = (CalibrationId, unitSerialNumber, widgetCode) => dispatch => {
  dispatch(describeCalibration(CalibrationId, unitSerialNumber, widgetCode));
};

export const describeCalibration = (CalibrationId, unitSerialNumber, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${unitSerialNumber}/calibrations`,
    data: {
      CalibrationId: CalibrationId || undefined,
      OrgId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      unitSerialNumber: unitSerialNumber
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCalibrationThunk(retData),
    onFailure: err => setCalibrationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setCalibrationThunk = ({ retData }) => dispatch => {
  let data = (retData.calibration && retData.calibration[0]) || null;
  let describeCalibration = {};
  if (data) {
    describeCalibration = data;
  }

  dispatch(setCalibration(describeCalibration));
};

export const setCalibration = data => ({
  type: SET_CALIBRATION,
  payload: {
    SelectedCalibration: data,
    isOpSuccessful: false,
    showBanner: false
  }
});

export const saveCalibrationThunk = (Calibration, unitSerialNumber, isOpSuccessful = false, widgetCode) => dispatch => {
  let url = `/device/${unitSerialNumber}/calibrations/save`;
  let actionName = Calibration.CalibrationId === 0 ? CREATE_CALIBRATION : UPDATE_CALIBRATION;
  let log = logEntry(CALIBRATION_MANAGEMENT, actionName, Calibration);
  dispatch(saveCalibration(Calibration, isOpSuccessful, url, log, widgetCode));
};

export const saveCalibration = (Calibration, isOpSuccessful, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCalibrationStatusThunk(retData, isOpSuccessful),
    onFailure: err => setCalibrationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      Calibration: Calibration,
      log: log
    },
    widgetcode: widgetCode
  });

export const setCalibrationStatusThunk = (retData, isOpSuccessful) => dispatch => {
  let messageCode = retData;
  dispatch(setCalibrationStatus(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setCalibrationStatus('', false, isOpSuccessful));
  }, 2500);
};

export const setCalibrationStatus = (messageCode, status, isOpSuccessful = true) => ({
  type: UPDATE_CALIBRATION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: isOpSuccessful,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setCalibrationChangeThunk = retData => dispatch => {
  dispatch(setCalibrationChange(retData));
};

export const setCalibrationChange = retData => ({
  type: SET_CALIBRATION_CHANGE,
  payload: retData
});

export const setCalibrationResultChangeThunk = retData => dispatch => {
  dispatch(setCalibrationResultChange(retData));
};

export const setCalibrationResultChange = retData => ({
  type: SET_CALIBRATION_RESULT_CHANGE,
  payload: retData
});

export const setCalibrationConstantThunk = retData => dispatch => {
  dispatch(setCalibrationConstantChange(retData));
};

export const setCalibrationConstantChange = retData => ({
  type: SET_CALIBRATION_CONSTANT_CHANGE,
  payload: retData
});

// export const setCalibrationConstantListThunk = retData => dispatch => {
//   dispatch(setCalibrationConstantList(retData));
// };

// export const setCalibrationConstantList = retData => ({
//   type: SET_CALIBRATION_CONSTANT_LIST_CHANGE,
//   payload: retData
// });

export const setViewStatusThunk = status => dispatch => {
  dispatch(setViewStatus(status));
};

export const setViewStatus = status => ({
  type: CALIBRATION_VIEW_STATUS,
  payload: { status: status }
});

export const describeCalibrationEquationThunk = (CalibrationEquationId, CalibrationId, widgetCode) => dispatch => {
  dispatch(describeCalibrationEquation(CalibrationEquationId, CalibrationId, widgetCode));
};

export const describeCalibrationEquation = (CalibrationEquationId, CalibrationId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/calibrationequations`,
    data: {
      CalibrationEquationId: CalibrationEquationId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCalibrationEquationThunk(retData, CalibrationId),
    onFailure: err => setCalibrationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setCalibrationEquationThunk = ({ retData }, CalibrationId) => dispatch => {
  let data = (retData.calibrationEquation && retData.calibrationEquation[0]) || null;
  let SelectedCalibration = {};
  if (data) {
    let CalibrationConstants = [];
    if (data.CalibrationConstants && data.CalibrationConstants.length > 0) {
      data.CalibrationConstants.map(eqConst => {
        CalibrationConstants.push({
          CalibrationComponentConstantId: 0,
          CalibrationId: CalibrationId,
          CalibrationEquationConstantId: eqConst.CalibrationEquationConstantId,
          Name: eqConst.Name,
          GuidIdentifier: uuidv4(),
          Description: eqConst.Description,
          isChanged: false,
          ConstantValue: null
        });
      });
    }
    SelectedCalibration = {
      CalibrationEquationId: data.CalibrationEquationId,
      CalibrationEquationName: data.Name,
      CalibrationEquationFormula: data.Formula,
      CalibrationRequired: false,
      CalibrationComponentConstants: CalibrationConstants
    };
  }

  dispatch(setCalibrationEquation(SelectedCalibration));
};

export const setCalibrationEquation = data => ({
  type: SET_CALIBRATION_CONST,
  payload: data
});

// Calibration Comments

export const saveCalibrationCommentThunk = (Calibration, unitSerialNumber, widgetCode) => dispatch => {
  let url = `/device/${unitSerialNumber}/calibrations/comments/save`;
  let actionName = CREATE_CALIBRATION_COMMENT;
  let log = logEntry(CALIBRATION_MANAGEMENT, actionName, Calibration);
  dispatch(saveCalibrationComments(Calibration, url, log, widgetCode));
};

export const saveCalibrationComments = (Calibration, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCalibrationCommentsStatusThunk(retData),
    onFailure: err => setCalibrationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      Calibration: Calibration,
      log: log
    },
    widgetcode: widgetCode
  });

export const setCalibrationCommentsStatusThunk = ({ message, CalibrationComments }) => dispatch => {
  dispatch(setCalibrationCommentsStatus(CalibrationComments));
  setTimeout(() => {
    //Hide the banner
    dispatch(setCalibrationStatus('', false, false));
  }, 2500);
};

export const setCalibrationCommentsStatus = retData => ({
  type: SET_CALIBRATION_COMMENT_CHANGE,
  payload: { CalibrationComments: retData }
});

export const updateUnitShadowThunk = (Calibration, unitSerialNumber, shadowData, isOpSuccessful = false, widgetCode) => dispatch => {
  let url = `/management/modelversion/updateunitshadow`;
  let actionName = UPDATE_UNIT_SHADOW;
  let log = logEntry(CALIBRATION_MANAGEMENT, actionName, shadowData);
  dispatch(updateUnitShadow(Calibration, unitSerialNumber, shadowData, isOpSuccessful, url, log, widgetCode));
};

export const updateUnitShadow = (Calibration, unitSerialNumber, shadowData, isOpSuccessful, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setShadowSuccessStatusThunk(retData, isOpSuccessful, Calibration, unitSerialNumber),
    onFailure: err => setCalibrationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      ...shadowData,
      log: log
    },
    widgetcode: widgetCode
  });

export const setShadowSuccessStatusThunk = (retData, isOpSuccessful, Calibration, unitSerialNumber) => dispatch => {
  let messageCode = retData.message;
  dispatch(setCalibrationStatus(messageCode, true, isOpSuccessful));
  setTimeout(() => {
    dispatch(saveCalibrationThunk(Calibration, unitSerialNumber, false, 'CALIBRATION_EDIT'));
  }, 2500);
};

export const deleteCalibrationThunk = (CalibrationId, unitSerialNumber, isOpSuccessful = false, widgetCode) => dispatch => {
  let url = `/device/${unitSerialNumber}/calibrations/delete`;
  let actionName = DELETE_CALIBRATION;
  let log = logEntry(CALIBRATION_MANAGEMENT, actionName, CalibrationId);
  dispatch(deleteCalibration(CalibrationId, isOpSuccessful, url, log, widgetCode));
};

export const deleteCalibration = (CalibrationId, isOpSuccessful, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCalibrationStatusThunk(retData, isOpSuccessful),
    onFailure: err => setCalibrationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      Calibration: {
        CalibrationId: CalibrationId
      },
      log: log
    },
    widgetcode: widgetCode
  });
