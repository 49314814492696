import { initialCommandSchedulerState } from './initialState';

import {
  COMMAND_SCHEDULER_ERROR,
  GET_ALL_COMMAND_SCHEDULER_DETAILS,
  COMMAND_SCHEDULER_IS_LOADING,
  SET_COMMAND_SCHEDULER_CURRENT_PAGE,
  SET_COMMAND_SCHEDULER_PAGE_FILTER,
  SET_COMMAND_SCHEDULER_CHANGE,
  SET_COMMAND_SCHEDULER,
  UPDATE_COMMAND_SCHEDULER_SAVE_STATUS,
  UPDATE_COMMAND_SCHEDULER_SAVE_STATUS_DATA
} from '../constants/index';

const commandSchedulerReducer = (state = initialCommandSchedulerState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_COMMAND_SCHEDULER_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        commandScheduler: {
          ...state.commandScheduler,
          ...data
        }
      };
    }

    case SET_COMMAND_SCHEDULER_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        commandScheduler: {
          ...state.commandScheduler,
          currentPage
        }
      };
    }

    case SET_COMMAND_SCHEDULER_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        commandScheduler: {
          ...state.commandScheduler,
          filter
        }
      };
    }

    case UPDATE_COMMAND_SCHEDULER_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        commandScheduler: {
          ...state.commandScheduler,
          ...data
        }
      };
    }

    case UPDATE_COMMAND_SCHEDULER_SAVE_STATUS_DATA: {
      const { data } = payload;
      return {
        ...state,
        commandScheduler: {
          ...state.commandScheduler,
          ...data
        }
      };
    }

    case COMMAND_SCHEDULER_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        commandScheduler: {
          ...state.commandScheduler,
          isLoading: isLoading
        }
      };
    }

    case COMMAND_SCHEDULER_ERROR: {
      const { data } = payload;
      return {
        ...state,
        commandScheduler: {
          ...state.commandScheduler,
          ...data
        }
      };
    }

    case SET_COMMAND_SCHEDULER: {
      let { selectedCommandSchedule, isOpSuccessful, showBanner } = payload;

      return {
        ...state,
        commandScheduler: {
          ...state.commandScheduler,
          selectedCommandSchedule: {
            ...state.commandScheduler.selectedCommandSchedule,
            ...selectedCommandSchedule
          },
          isOpSuccessful: isOpSuccessful,
          showBanner: showBanner
        }
      };
    }

    case SET_COMMAND_SCHEDULER_CHANGE: {
      let { selectedCommandSchedule } = payload;

      return {
        ...state,
        commandScheduler: {
          ...state.commandScheduler,
          selectedCommandSchedule: {
            ...state.commandScheduler.selectedCommandSchedule,
            ...selectedCommandSchedule
          }
        }
      };
    }

    default:
      return state;
  }
};

export default commandSchedulerReducer;
