import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './ForgotPassword.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getIsLoading, getForgotPassword } from '../../selectors/index';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import logoBlack from '../../assets/logo-black.svg';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import alert from '../../assets/alert.svg';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { isValidEmail, isValidPassword } from '../../utils/';
import { submitEmailVerificationThunk, submitConfirmationThunk, resetVerificationCodeThunk } from '../../actions/forgotPassword';
import VerificationCodeInput from '../WSAControls/VerificationCodeInput/VerificationCodeInput';

const ForgotPassword = ({ intl }) => {
  const [email, setEmail] = useState('');
  const isLoading = useSelector(state => getIsLoading(state));
  const forgotPassword = useSelector(state => getForgotPassword(state));

  const emailHandler = value => {
    setEmail(value);
  };
  const apiMessage = forgotPassword.displayMessageCode.length > 0 ? forgotPassword.displayMessageCode : 'null';
  const messageText = intl.formatMessage({ id: apiMessage, defaultMessage: forgotPassword.displayMessageCode });
  let content = (
    <EmailConfirmation
      email={email}
      emailHandler={emailHandler}
      forgotPassword={forgotPassword}
      isLoading={isLoading}
      messageText={messageText}
    />
  );

  if (forgotPassword.verificationSent) {
    content = (
      <CodeVerification
        email={email}
        emailHandler={emailHandler}
        forgotPassword={forgotPassword}
        isLoading={isLoading}
        messageText={messageText}
      />
    );
  }

  return (
    <div className={s.wrapper}>
      <img src={logoBlack} alt="icon" className={classNames(s.hideDesktop, s.logo)} />
      <div className={s.contactSection}>
        <span>
          <FormattedMessage id="general.needHelp" defaultMessage="Need help?" />
        </span>
        <Button variant="outline-secondary">
          <FormattedMessage id="general.contactUsNow" defaultMessage="CONTACT US NOW" />
        </Button>
      </div>

      <div className={s.forgotPassword}>
        <h1>
          {' '}
          <FormattedMessage id="forgotpassword.forgotyourpassword" defaultMessage="Forgot your password?" />
        </h1>
        {isLoading && <LoadingSpinner />}
        {content}
      </div>
    </div>
  );
};

const EmailConfirmation = ({ email, emailHandler, forgotPassword, isLoading, messageText }) => {
  const [formErrors, setFormErrors] = useState({ email: false });
  const dispatch = useDispatch();

  const handleInput = e => {
    const { value } = e.target;
    emailHandler(value);
    setFormErrors({ email: '' });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const emailError = !isValidEmail(email);

    setFormErrors({ email: emailError });

    if (!emailError) {
      dispatch(submitEmailVerificationThunk({ email: email }));
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {forgotPassword.showMessage && forgotPassword.displayMessageCode && (
          <Alert variant="light" className={s.errorContainer}>
            <img src={alert} alt="icon" /> <div>{messageText}</div>
          </Alert>
        )}
        <Row>
          <Col lg={10} sm={12}>
            <Form.Label>
              <FormattedMessage
                id="forgotpassword.pleaseEnterVerificationEmail"
                defaultMessage="Please enter your verification email address"
              />
            </Form.Label>
            <Form.Control
              type="input"
              name="email"
              value={email}
              onChange={handleInput}
              className={formErrors.email ? s.formControlError : ''}
            />

            {formErrors.email && (
              <p role="alert" className={s.error}>
                <FormattedMessage id="forgotpassword.invalidEmail" defaultMessage="Please enter a valid email address" />
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col lg={5} sm={12}>
            <Button disabled={isLoading} type="submit" className={s.forgotPasswordButton}>
              {' '}
              <FormattedMessage id="forgotpassword.resetMyPassord" defaultMessage="RESET MY PASSWORD" />{' '}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const CodeVerification = ({ email, forgotPassword, isLoading, messageText, emailHandler }) => {
  const [form, setForm] = useState({ newPassword: '', confirmPassword: '', confirmationCode: '', email: email });
  const [formErrors, setFormErrors] = useState({ newPassword: false, confirmPassword: false, confirmationCode: false, email: false });
  const dispatch = useDispatch();

  const handleInput = e => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const passwordError = !isValidPassword(form.newPassword);
    const confirmPasswordError = form.newPassword !== form.confirmPassword;
    const confirmCodeError = form.confirmationCode.toString().length !== 6;

    setFormErrors({
      newPassword: passwordError,
      confirmPassword: confirmPasswordError,
      confirmationCode: confirmCodeError
    });

    if (!confirmCodeError && !passwordError && !confirmPasswordError) {
      dispatch(submitConfirmationThunk({ email: email, password: form.newPassword, confirmationCode: form.confirmationCode }));
    }
  };

  const handleCodeInput = value => {
    setForm({
      ...form,
      confirmationCode: value
    });
  };

  const resendCodeHandler = () => {
    setForm({ newPassword: '', confirmPassword: '', confirmationCode: '', email: '' });
    setFormErrors({ newPassword: false, confirmPassword: false, confirmationCode: false, email: false });
    emailHandler('');
    dispatch(resetVerificationCodeThunk());
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={10}>
            <div className={s.verificationInfo}>
              <FormattedMessage
                id="forgotpassword.verificatiionEmailSentEnterCode"
                defaultMessage="A verification code has been sent to your inbox. Please enter the verification code below."
              />
            </div>
          </Col>
        </Row>
        {forgotPassword.showMessage && forgotPassword.displayMessageCode && (
          <Alert variant="light" className={s.errorContainer}>
            <img src={alert} alt="icon" /> <div>{messageText}</div>
          </Alert>
        )}
        <Row>
          <Col lg={10} sm={12}>
            <div className={s.verificationContainer}>
              <div className={s.verificationCode}>
                <FormattedMessage id="forgotpassword.enterCode" defaultMessage="Enter Code" />
              </div>
              <VerificationCodeInput type="number" fields={6} value={form.confirmationCode} onChange={handleCodeInput} />

              {formErrors.confirmationCode && (
                <p role="alert" className={s.error}>
                  <FormattedMessage id="forgotpassword.codeRequirement" defaultMessage="Invalid verification code" />
                </p>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={10} sm={12}>
            <Form.Label>
              {' '}
              <FormattedMessage id="general.enterpassword" defaultMessage="Enter password" />
            </Form.Label>
            <Form.Control
              type="password"
              name="newPassword"
              value={form.newPassword}
              onChange={handleInput}
              className={formErrors.newPassword ? s.formControlError : ''}
            />

            {formErrors.newPassword && (
              <p role="alert" className={s.error}>
                <FormattedMessage
                  id="general.passwordRequirement"
                  defaultMessage="Password must contain at least 1 uppercase, 1 lowercase and 10 characters"
                />
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={10} sm={12}>
            <Form.Label>
              {' '}
              <FormattedMessage id="general.ConfirmPassword" defaultMessage="Confirm password" />
            </Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              value={form.confirmPassword}
              onChange={handleInput}
              className={formErrors.confirmPassword ? s.formControlError : ''}
            />

            {formErrors.confirmPassword && (
              <p role="alert" className={s.error}>
                <FormattedMessage id="general.passwordDoNotMatch" defaultMessage="Password does not match" />
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col lg={5} sm={12}>
            <Button disabled={isLoading} type="submit" className={s.forgotPasswordButton}>
              {' '}
              <FormattedMessage id="forgotpassword.next" defaultMessage="RESET MY PASSWORD" />{' '}
            </Button>
          </Col>
          <Col lg={5} md={12}>
            <a onClick={resendCodeHandler} className={s.resendLink}>
              <span>
                <FormattedMessage id="forgotpassword.resendCode" defaultMessage="RESEND CODE" />
              </span>
            </a>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default injectIntl(ForgotPassword);
