import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './PartDetails.module.scss';
import PartFirmwareHistoryListTable from './PartFirmwareHistoryListTable';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import { injectIntl } from 'react-intl';

const PartFirmwareHistoryList = ({ intl, firmwareHistoryData }) => {
  let initialLimit = 6;
  let initialRecords = firmwareHistoryData && firmwareHistoryData.slice(0, initialLimit);

  const [showAll, setShowAll] = useState(false);

  const firmwareDataLength = firmwareHistoryData && firmwareHistoryData.length;

  const showAllHandler = () => {
    setShowAll(!showAll);
  };

  const getFirmwareData = () => {
    let firmwareData = [];
    if (showAll) {
      firmwareData = firmwareHistoryData;
    } else {
      firmwareData = initialRecords;
    }
    firmwareData = firmwareData.sort(compareValues('ModelFirmwareVersionId', 'desc'));

    return firmwareData;
  };

  let showAllText = intl.formatMessage({ id: 'componentParts.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'componentParts.showLess', defaultMessage: 'SHOW LESS' });

  return (
    <div className={s.historyData}>
      <div className={s.contentWrapper}>
        <div>
          <PartFirmwareHistoryListTable firmwareHistoryData={getFirmwareData()} />
        </div>
        {firmwareDataLength > initialLimit && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${firmwareDataLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

PartFirmwareHistoryList.defaultProps = {
  firmwareHistoryData: []
};

PartFirmwareHistoryList.propTypes = {
  firmwareHistoryData: PropTypes.array.isRequired
};

export default injectIntl(PartFirmwareHistoryList);
