import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './OrganisationList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialOrganisationManagerState } from '../../reducers/initialState';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import OrganisationListTable from '../OrganisationList/OrganisationListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';

const OrganisationList = ({ getOrganisations, setCurrentPage, setPageFilter, organisationManager, intl, userProfileWidgets }) => {
  let filter = organisationManager.filter || '';
  let offset = organisationManager.offset || 0;
  let limit = organisationManager.limit || 1000;

  useEffect(() => {
    getOrganisations(offset, limit, filter);
  }, [getOrganisations, offset, limit, filter]);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    filter = value;
    setCurrentPage(1);
    setPageFilter(filter);
  };

  const organisationsObj = Object.values(organisationManager.organisations);

  let messageId = (organisationManager && organisationManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'ORGANISATIONUPDATE');
  const isConfigureAllowed = IsWidgetAccessible(userProfileWidgets, 'ORGANISATION_CONFIGURE');
  const isMoveUnitsAllowed = IsWidgetAccessible(userProfileWidgets, 'ORGANISATIONMOVEUNITS');

  return (
    <div className={s.organisations}>
      {organisationManager.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={organisationManager.showBanner}
        status={organisationManager.isOpSuccessfull}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageOrganisations" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.organisationsHeader}>
            <div className={s.subHeading}>
              <FormattedMessage id="organisation.organisation" defaultMessage="Organisations" />
              <span className={s.circle}>{organisationManager.organisations.length}</span>
            </div>
          </div>

          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={filter}
                placeHolderTextId="organisation.organisationName"
              />
            </Col>
            <Col sm={6} lg={8}>
              <Link to={'/admin-controls/organisation'}>
                <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `ORGANISATIONINSERT`)}>
                  + <FormattedMessage id="organisation.addNewOrganisation" defaultMessage="ADD NEW Organisation" />
                </Button>
              </Link>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={organisationsObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={organisationManager.currentPage}
            >
              <OrganisationListTable
                UpdateAllowed={isUpdateAllowed}
                configureAllowed={isConfigureAllowed}
                isMoveUnitsAllowed={isMoveUnitsAllowed}
              />
            </Pagination>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

OrganisationList.defaultProps = {
  organisationManager: {
    ...initialOrganisationManagerState.organisationManager
  }
};

OrganisationList.propTypes = {
  getOrganisations: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired
};

export default injectIntl(OrganisationList);
