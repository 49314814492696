import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ModelList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelState } from '../../reducers/initialState';

import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import ModelListTable from '../ModelList/ModelListTable';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { WidgetVisibility } from '../../utils/widgetManager';
import Cookies from 'js-cookie';

const ModelList = ({
  getModels,
  setCurrentPage,
  setPageFilter,
  setFilterData,
  modelManager,
  intl,
  userProfileWidgets,
  currentTimezone
}) => {
  let filter = modelManager.filter || '';
  let offset = modelManager.offset || 0;
  let limit = modelManager.limit || 1000;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  useEffect(() => {
    getModels(offset, limit, filter, organisationId);
  }, [getModels, offset, limit, filter, organisationId]);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setCurrentPage(1);
    filteredResults(value);
  };

  const filteredResults = value => {
    let filteredList = modelManager.models;

    if (value !== '') {
      filteredList = filteredList.filter(function(item) {
        return item.Name.toLowerCase().includes(value.toLowerCase());
      });
    }

    setFilterData({ filteredResults: { filteredResults: filteredList } });
  };

  const modelObj = Object.values(modelManager.filteredResults);

  let messageId = (modelManager && modelManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODEL_UPDATE');
  const isDuplicateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODEL_DUPLICATE');
  const isConfigurationAllowed = IsWidgetAccessible(userProfileWidgets, 'MODEL_CONFIGURE');

  return (
    <div className={s.model}>
      {modelManager.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={modelManager.showBanner}
        status={modelManager.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageModels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.modelHeader}>
            <div className={s.subHeading}>
              <FormattedMessage id="model.model" defaultMessage="Model" />
              <span data-unittest="modelDataCount" className={s.circle}>
                {modelManager.models.length}
              </span>
            </div>
          </div>

          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={filter}
                placeHolderTextId="model.modelName"
              />
            </Col>
            <Col sm={6} lg={8}>
              <Link to={`/admin-controls/model`}>
                <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `MODEL_ADD`)}>
                  + <FormattedMessage id="model.addNew" defaultMessage="ADD NEW" />
                </Button>
              </Link>
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={modelObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={modelManager.currentPage}
            >
              <ModelListTable
                UpdateAllowed={isUpdateAllowed}
                DuplicateAllowed={isDuplicateAllowed}
                ConfigurationAllowed={isConfigurationAllowed}
                currentTimezone={currentTimezone}
              />
            </Pagination>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ModelList.defaultProps = {
  ...initialModelState
};

ModelList.propTypes = {
  getModels: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(ModelList);
