import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './Settings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LanguageSelector from '../LanguageSelector/LanguageSelectorContainer';
import Timezone from '../Timezone/TimezoneContainer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AccountSettingsContentTemplate from '../AccountSettings/AccountSettingsContentTemplate';
import UserOrganisations from '../UserOrganisations/UserOrganisations';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Button from 'react-bootstrap/Button';
import { WidgetVisibility } from '../../utils/widgetManager';
import { DOMAIN_NAME, IS_LOCALHOST } from '../../constants/index';
import Cookies from 'js-cookie';

const Settings = ({ intl }) => {
  const [active, setActive] = useState(false);
  const [activeClearCookie, setActiveClearCookie] = useState(false);
  const [commandId, setCommandId] = useState(0);
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  const handleConfirm = () => {
    // TO DO
    localStorage.removeItem('state');

    window.location.reload();

    handleClose();
  };

  const handleClearCookieConfirm = () => {
    Object.keys(Cookies.get()).forEach(cookieName => {
      const envName = process.env.REACT_APP_ENV_NAME_SHORT;

      if (cookieName.split('-').length > 1 && cookieName.split('-')[1] === envName) {
        Cookies.remove(cookieName, { domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
      }
    });

    window.location.reload();

    handleClearCookieClose();
  };

  const handleClose = () => setActive(false);

  const handleClearCookieClose = () => setActiveClearCookie(false);

  const handleCommand = commandId => {
    setActive(true);
    setCommandId(commandId);
  };

  const handleClearCookies = commandId => {
    setActiveClearCookie(true);
    // setCommandId(commandId);
  };

  const dialogTitle = intl.formatMessage({
    id: 'accountSettings.resetCacheConfirmation',
    defaultMessage: 'Are you sure you want to reset the cache?'
  });

  const dialogTitleClearCookies = intl.formatMessage({
    id: 'accountSettings.clearCookiesConfirmation',
    defaultMessage: 'Are you sure you want to clear the cookies?'
  });

  return (
    <div className={s.settings}>
      {/* <Banner
        failureText={messageText}
        showBanner={userManager.showBanner}
        status={userManager.isOpSuccessfull}
        successText={messageText}
      /> */}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <ConfirmDialog
        title={dialogTitleClearCookies}
        onConfirm={handleClearCookieConfirm}
        onClose={handleClearCookieClose}
        showDialog={activeClearCookie}
      />
      <AccountSettingsContentTemplate selectedPage="settings">
        <div className={s.contentWrapper}>
          <div className={s.header}>
            <div className={s.subHeading}>
              <FormattedMessage id="accountSettings.generalSettings" defaultMessage="General Account Settings" />
            </div>
          </div>
          <Row>
            <Col md={4} xs={12}>
              <LanguageSelector />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4} xs={12}>
              <Timezone />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4} xs={12}>
              <UserOrganisations />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12} xs={12}>
              <span className={s.note}>
                <FormattedMessage
                  id="accountSettings.resetCacheNote"
                  defaultMessage="Please note resetting the cache would also reset all your trend analysis charts on this device"
                />
              </span>
              <Button
                className={s.cachebutton}
                variant="outline-danger"
                onClick={handleCommand}
                noValidate
                data-unittest="resetCache"
                style={WidgetVisibility(userProfileWidgets, `CACHERESET`)}
              >
                <FormattedMessage id="accountSettings.resetCache" defaultMessage="RESET CACHE" />
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12} xs={12}>
              <span className={s.note}>
                <FormattedMessage
                  id="accountSettings.clearCookiesNote"
                  defaultMessage="Please note clearing the cookies would log you out of the application"
                />
              </span>
              <Button
                className={s.cachebutton}
                variant="outline-danger"
                onClick={handleClearCookies}
                noValidate
                data-unittest="resetCache"
                style={WidgetVisibility(userProfileWidgets, `CACHERESET`)}
              >
                <FormattedMessage id="accountSettings.clearCookies" defaultMessage="CLEAR COOKIES" />
              </Button>
            </Col>
          </Row>
        </div>
      </AccountSettingsContentTemplate>
    </div>
  );
};

Settings.defaultProps = {
  organisations: []
};

Settings.propTypes = {};

export default injectIntl(Settings);
