import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelConfiguration from './ModelConfiguration';
import { getModelManager, getUserProfileWidget } from '../../selectors/index';
import { describeModelThunk } from '../../actions/model';

const mapStateToProps = state => {
  return {
    modelManager: getModelManager(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeModel: (modelId, isDuplicate) => describeModelThunk(modelId, isDuplicate, 'MODEL_CONFIGURE')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelConfiguration);
