import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ApprovedComponent from './ApprovedComponent';
import { getUserProfileWidget, getApprovedComponent } from '../../selectors/index';

import {
  describeApprovedComponentThunk,
  saveApprovedComponentThunk,
  setApprovedComponentChangeThunk,
  setApprovedSubComponentChangeThunk,
  resetModelComponentThunk,
  setApprovedSubComponentListThunk,
  showAddEditSreenThunk
} from '../../actions/approvedComponents';

const mapStateToProps = state => {
  return {
    approvedComponent: getApprovedComponent(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveApprovedComponent: (approvedComponent, widgetCode) => saveApprovedComponentThunk(approvedComponent, widgetCode),
      describeApprovedComponent: (approvedComponentId, widgetCode) => describeApprovedComponentThunk(approvedComponentId, widgetCode),
      changeApprovedComponent: selectedApprovedComponent => setApprovedComponentChangeThunk(selectedApprovedComponent),
      changeApprovedSubComponent: selectedApprovedSubComponent => setApprovedSubComponentChangeThunk(selectedApprovedSubComponent),
      resetModelComponent: approvedComponent => resetModelComponentThunk(approvedComponent),
      setApprovedSubComponentList: approvedSubComponentList => setApprovedSubComponentListThunk(approvedSubComponentList),
      showAddEditScreen: status => showAddEditSreenThunk(status)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedComponent);
