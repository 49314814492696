import React, { useEffect, useState } from 'react';

import s from './TreeView.module.scss';
import logoBlack from '../../../assets/logo-black.svg';
import Button from 'react-bootstrap/Button';

const TreeView = ({ treeData, onNodeSelect }) => {
  const [selectedNodeKey, setSelectedNodeKey] = useState('');
  return (
    <div className={s.treeView}>
      <ul>
        {treeData.map(node => (
          <TreeNode
            onNodeSelect={onNodeSelect}
            key={`treenode-${node.key}`}
            node={node}
            keyVal={node.key}
            setSelectedNodeKey={setSelectedNodeKey}
            selectedNodeKey={selectedNodeKey}
          />
        ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node, keyVal, onNodeSelect, setSelectedNodeKey, selectedNodeKey }) => {
  const { children, label, compLogo } = node;

  const [showChildren, setShowChildren] = useState(false);

  const handleClick = () => {
    setSelectedNodeKey(keyVal);
    if (children) setShowChildren(!showChildren);
  };
  const buttonClick = () => {
    onNodeSelect(node);
  };

  let companyLogo = logoBlack;
  if (compLogo && !compLogo.includes('data:image')) {
    companyLogo = compLogo;
  }

  return (
    <>
      <div
        onClick={() => {
          handleClick();
        }}
        className={s.treeNode}
        key={keyVal}
      >
        {showChildren ? <DownArrow /> : <RightArrow />}
        {selectedNodeKey == keyVal ? <span className={s.circleBlue} /> : <span className={s.circle} />}
        <span>
          {' '}
          <img src={companyLogo} alt="icon" className={s.logo}></img>
          {label}
        </span>
        {selectedNodeKey == keyVal ? (
          <Button onClick={buttonClick} className={s.buttonClass}>
            select
          </Button>
        ) : (
          <></>
        )}
      </div>
      <ul>
        {showChildren && (
          <Tree treeData={children} onNodeSelect={onNodeSelect} setSelectedNodeKey={setSelectedNodeKey} selectedNodeKey={selectedNodeKey} />
        )}
      </ul>
    </>
  );
};

const Tree = ({ treeData, setSelectedNodeKey, onNodeSelect, selectedNodeKey }) => {
  return (
    <ul>
      {treeData.map(node => (
        <TreeNode
          key={`treenode-${node.key}`}
          onNodeSelect={onNodeSelect}
          node={node}
          keyVal={node.key}
          setSelectedNodeKey={setSelectedNodeKey}
          selectedNodeKey={selectedNodeKey}
        />
      ))}
    </ul>
  );
};

const RightArrow = () => {
  return (
    <svg
      width="1em"
      height="1em"
      fill="#2F92D0"
      stroke="#2F92D0"
      style={{
        opacity: 1
      }}
      viewBox="0 0 256 512"
    >
      <path d="m224.3 273-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
    </svg>
  );
};

const DownArrow = () => {
  return (
    <svg width="1em" height="1em" fill="#2F92D0" stroke="#2F92D0" viewBox="0 0 320 512">
      <path d="M143 352.3 7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
    </svg>
  );
};

export default TreeView;
