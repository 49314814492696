import get from 'lodash.get';
import { UPDATE_SENSOR_DATA, SENSOR_LOADING_DATA } from '../constants/index';

const sensorsReducer = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case UPDATE_SENSOR_DATA: {
      const { results } = payload;
      if (!get(results, [0])) return state;
      const { unitserialnumber, ...data } = results[0];
      return {
        ...state,
        [unitserialnumber]: {
          ...data
        },
        isLoading: false
      };
    }
    case SENSOR_LOADING_DATA: {
      const { status } = payload;
      return {
        ...state,
        isLoading: status
      };
    }
    default:
      return state;
  }
};

export default sensorsReducer;
