import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';

import s from './EventActionRHS.module.scss';
import Button from 'react-bootstrap/Button';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { ALARM_STATUS_RESOLVED, TASK_STATUS_ACTIVE } from '../../constants';
import LinkAlarm from '../EventAction/LinkAlarm';

const EventActionRHS = ({
  currentTimezone,
  eventAction,
  users,
  onResolvedBtnClick,
  onUserDdlChange,
  isUpdateAllowed,
  tasks,
  onAlarmUnLink,
  onAlarmLink
}) => {
  let dataArray =
    (users &&
      users.length &&
      users.map(user => {
        return { label: user.FullName, value: user.UserId };
      })) ||
    [];

  const [IsError, SetError] = useState(false);
  let isResolved = eventAction.EventStatus === ALARM_STATUS_RESOLVED;

  const onMarkAsResolvedBtnClick = () => {
    if (tasks.some(x => x.Status === TASK_STATUS_ACTIVE)) {
      SetError(true);
    } else {
      SetError(false);
      onResolvedBtnClick();
    }
  };

  return (
    <div className={s.eventActionRHS}>
      <div className={s.eventActionRHSContainer}>
        <div>
          <div className={s.eventActionRHSHeading}>
            <FormattedMessage id="eventAction.status" defaultMessage="Status" />
          </div>
          <div className={s.eventActionRHSContents}>{eventAction.EventStatus}</div>
        </div>
        {!isResolved && isUpdateAllowed && eventAction.LinkStatus !== 2 && (
          <div>
            <Button id="btnMarkResolved" variant="primary" className={s.eventActionRHSButton} onClick={() => onMarkAsResolvedBtnClick()}>
              <FormattedMessage id="eventAction.markAsResolved" defaultMessage="MARK AS RESOLVED" />
            </Button>
            {IsError && (
              <p role="alert" className={s.error}>
                {<FormattedMessage id="eventAction.activeTasks" defaultMessage="Few tasks are still active, can not mark as resolved." />}
              </p>
            )}
          </div>
        )}

        <div className={s.eventActionRHSHeading}>
          <FormattedMessage id="eventAction.assignee" defaultMessage="Assignee" />
        </div>
        <div>
          <Dropdown
            id="drpAssignedUser"
            dataArray={dataArray}
            controlData={{
              placeholderText: '-',
              customClassName: ''
            }}
            onDropdownChange={e => onUserDdlChange(e)}
            selectedOption={dataArray.filter(option => option.value === (eventAction.EventActionAssignedToUserId || 0))}
            disabled={isResolved || !isUpdateAllowed || eventAction.LinkStatus === 2}
          />
        </div>
        <LinkAlarm
          eventAction={eventAction}
          isResolved={isResolved}
          isUpdateAllowed={isUpdateAllowed}
          onAlarmLink={onAlarmLink}
          onAlarmUnLink={onAlarmUnLink}
        />

        <div className={s.eventActionRHSHeading}>
          <FormattedMessage id="eventAction.dates" defaultMessage="Dates" />
        </div>

        <div className={s.eventActionRHSContents}>
          <div>
            <p className={s.eventActionRHSSubHeading}>
              <FormattedMessage id="eventAction.recieved" defaultMessage="Recieved" />
            </p>
            <p className={s.eventActionRHSSubHeadingContent}>
              {eventAction.EventDateTime
                ? moment(parseInt(eventAction.EventDateTime))
                    .tz(currentTimezone)
                    .format('DD-MMM-YYYY HH:mm z')
                : '-'}
            </p>
          </div>
          <div>
            <p className={s.eventActionRHSSubHeading}>
              <FormattedMessage id="eventAction.resolved" defaultMessage="Resolved" />
            </p>
            <p className={s.eventActionRHSSubHeadingContent}>
              {eventAction.ResolvedDateTime
                ? moment
                    .unix(parseInt(eventAction.ResolvedDateTime))
                    .tz(currentTimezone)
                    .format('DD-MMM-YYYY HH:mm z')
                : '-'}
            </p>
          </div>
        </div>

        <div className={s.eventActionRHSHeading}>
          <FormattedMessage id="eventAction.unit" defaultMessage="Unit" />
        </div>
        <div className={s.eventActionRHSContents}>
          <div>
            <p className={s.eventActionRHSSubHeading}>
              <FormattedMessage id="eventAction.name" defaultMessage="Name" />
            </p>
            <p className={s.eventActionRHSSubHeadingContent}>{eventAction.Name}</p>
          </div>
          <div>
            <p className={s.eventActionRHSSubHeading}>
              <FormattedMessage id="eventAction.id" defaultMessage="I.D." />
            </p>
            <p className={s.eventActionRHSSubHeadingContent}>{eventAction.UnitSerialNumber}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

EventActionRHS.defaultProps = {
  currentTimezone: moment.tz.guess(),
  eventAction: { EventStatus: ALARM_STATUS_RESOLVED },
  isUpdateAllowed: false,
  tasks: []
};

EventActionRHS.propTypes = {
  currentTimezone: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  tasks: PropTypes.array
};

export default EventActionRHS;
