import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ApprovedComponentList from './ApprovedComponentList';
import { getUserProfileWidget, getApprovedComponent } from '../../selectors/index';

import { getApprovedComponentThunk, saveApprovedComponentThunk, setApprovedComponentChangeThunk } from '../../actions/approvedComponents';

const mapStateToProps = state => {
  return {
    approvedComponent: getApprovedComponent(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getApprovedComponent: (offset, limit, filter) => getApprovedComponentThunk({ offset, limit, filter }, 'APPROVEDCOMPONENT_LIST'),
      saveApprovedComponent: (approvedComponent, widgetCode) => saveApprovedComponentThunk(approvedComponent, widgetCode),
      setApprovedComponentChange: selectedApprovedComponent => setApprovedComponentChangeThunk(selectedApprovedComponent)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedComponentList);
