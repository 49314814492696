import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import EventLog from './EventLog';
import {
  getIsEventLoading,
  getCurrentTimezone,
  getEventActionStatus,
  getFlowSequenceGlobalSettings,
  getUserProfileWidget,
  getAlarmEvents,
  getWarningEvents,
  getInfoEvents
} from '../../selectors/index';
import { fetchEventsThunk } from '../../actions/events';
import { fetchEventActionExistanceThunk } from '../../actions/eventActionStatus';

const mapStateToProps = state => {
  return {
    isLoading: getIsEventLoading(state),
    currentTimezone: getCurrentTimezone(state),
    eventActionStatus: getEventActionStatus(state),
    flowSequences: getFlowSequenceGlobalSettings(state),
    userProfileWidgets: getUserProfileWidget(state),
    alarms: getAlarmEvents(state),
    warnings: getWarningEvents(state),
    infoEvents: getInfoEvents(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEvents: (limit, view, from, to, code, type) => fetchEventsThunk(limit, view, from, to, code, type, 'EVENTLOGSUMMARY'),
      fetchEventActionExistance: data => fetchEventActionExistanceThunk(data, 'EVENTLOGSUMMARY')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventLog);
