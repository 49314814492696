import React from 'react';
import s from './DateTimePicker.module.scss';
import DateTime from 'react-datetime';

const DateTimePicker = props => {
  const inputProps = { placeholder: props.placeHolderText, disabled: props.disabled ? props.disabled : false };
  return (
    <div className={s.dateWrapper}>
      <DateTime
        className={`${s.wsaInput} ${props.className}`}
        onChange={props.onChange}
        onBlur={props.onBlur}
        timeFormat={props.timeFormat}
        dateFormat={props.dateFormat}
        value={props.value}
        defaultValue={props.defaultValue}
        closeOnSelect={props.closeOnSelect}
        showClear={props.showClear}
        inputProps={inputProps}
        isValidDate={props.isValidDate}
        timeConstraints={props.timeConstraints}
      />
      {props.showClear && (
        <span className={s.clearText} onClick={props.onClear}>
          {' '}
          x{' '}
        </span>
      )}
    </div>
  );
};

DateTimePicker.defaultProps = {
  value: null,
  className: '',
  showClear: false
};

export default DateTimePicker;
