import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import s from './TaskDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialTasksState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import Banner from '../Banner/Banner';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import Form from 'react-bootstrap/Form';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useHistory, Link, useParams } from 'react-router-dom';
import { TASK_STATUS_ACTIVE, TASK_STATUS_COMPLETE } from '../../constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import alert from '../../assets/alert.svg';
import uuid from 'uuid';
import attachmentIcon from '../../assets/attachment.svg';
import moment from 'moment-timezone';
import TasksAndChecklistsContentTemplate from '../TasksAndChecklists/TasksAndChecklistsContentTemplate';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import TaskComments from './TaskComments';
import { getQuerystring, getOverdueStatus } from '../../utils';
import GoBack from '../WSAControls/GoBack/GoBack';

const TaskDetail = ({ userProfileWidgets, intl, taskManager, describeTask, saveTask, changeTask, currentTimezone, saveTaskComment }) => {
  let { TaskId } = useParams();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let userId = Cookies.get(`userid-${envName}`) || 0;
  let history = useHistory();

  const [active, setActive] = useState(false);
  const [commandId, setCommandId] = useState(0);
  const [editActualEffort, setEditActualEffort] = useState(false);
  const [localformErrors, setFormErrors] = useState({});
  const [isDelete, setIsDelete] = useState(false);

  let checklistId = getQuerystring('checklistId') || 0;
  checklistId = parseInt(checklistId);
  let isServiceChecklist = getQuerystring('isServiceChecklist') === 'true' ? true : false;
  let unitId = getQuerystring('unitId') ? parseInt(getQuerystring('unitId')) : 0;

  let eventActionId = getQuerystring('eventActionId') || 0;
  eventActionId = parseInt(eventActionId);

  let dashboardTasks = getQuerystring('dashboardTasks') || 0;
  dashboardTasks = parseInt(dashboardTasks);

  let fromView = getQuerystring('view') || 0;
  fromView = parseInt(fromView);

  useEffect(() => {
    if (TaskId) {
      describeTask(TaskId);
    }
  }, [describeTask, TaskId, taskManager.selectedTask.MarkAsComplete]);

  // useEffect(() => {
  //   return () => {
  //     setLocalTask({
  //       ...taskManager,
  //       isOpSuccessful: false
  //     });
  //   };
  // }, []);

  const handleClose = () => setActive(false);

  const handleCommand = commandId => {
    setActive(true);
    setCommandId(commandId);
  };

  const handleConfirm = () => {
    deleteTaskClick(commandId);
    handleClose();
  };

  const borderClass =
    taskManager.selectedTask.Status === TASK_STATUS_ACTIVE
      ? s.borderRed
      : taskManager.selectedTask.Status === TASK_STATUS_COMPLETE
      ? s.borderGreen
      : '';
  const dotClass =
    taskManager.selectedTask.Status === TASK_STATUS_ACTIVE
      ? s.dotActive
      : taskManager.selectedTask.Status === TASK_STATUS_COMPLETE
      ? s.dotComplete
      : '';

  let widgetCode = 'TASKEDIT';

  const deleteTaskClick = () => {
    setIsDelete(true);
    let saveData = taskManager.selectedTask;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    saveData.MarkAsDeleted = true;

    saveTask(saveData, 'TASKDELETE');
  };

  const markAsCompleteClick = () => {
    let saveData = taskManager.selectedTask;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    saveData.MarkAsComplete = true;
    saveTask(saveData, widgetCode);
  };

  const updateActualEfforts = () => {
    if (!validateInput()) {
      return;
    }
    let saveData = taskManager.selectedTask;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    saveData.UpdateActualEffort = true;
    saveTask(saveData, widgetCode);
    setEditActualEffort(false);
  };

  const cancelActualEffortClick = () => {
    setEditActualEffort(false);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !taskManager ||
      !taskManager.selectedTask ||
      !taskManager.selectedTask.ActualEffort ||
      isRequired(taskManager.selectedTask.ActualEffort, 1)
    ) {
      formErrors.ActualEffort = intl.formatMessage({
        id: 'tasksManagement.actualEffortMandatory',
        defaultMessage: 'Actual effort is mandatory'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const setLocalTask = currentState => {
    changeTask(currentState);
  };

  const onChange = e => {
    setLocalTask({
      ...taskManager,
      selectedTask: { ...taskManager.selectedTask, [e.target.name]: e.target.value }
    });
  };

  const dialogTitle = intl.formatMessage({
    id: 'tasksManagement.confirmDeleteTask',
    defaultMessage: 'Are you sure you want to delete the task?'
  });

  let messageId = (taskManager && taskManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let backToMessage = intl.formatMessage({ id: 'tasksManagement.backtoTasks', defaultMessage: 'BACK TO TASKS' });
  if (checklistId) {
    backToMessage = intl.formatMessage({ id: 'checklistManagement.backtoChecklist', defaultMessage: 'BACK TO CHECKLIST' });
  }
  if (eventActionId) {
    backToMessage = intl.formatMessage({ id: 'tasksManagement.backtoAlarm', defaultMessage: 'BACK TO ALARM' });
  }
  if (dashboardTasks) {
    backToMessage = intl.formatMessage({ id: 'tasksManagement.backtoDashboard', defaultMessage: 'BACK TO DASHBOARD' });
  }

  return (
    <div className={s.task}>
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      {taskManager.isLoading && <LoadingSpinner />}

      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={taskManager.showBanner}
        status={taskManager.isOpSuccessful}
        successText={messageText}
      />
      {taskManager.isOpSuccessful && !taskManager.showBanner && isDelete ? history.goBack() : ''}
      <TasksAndChecklistsContentTemplate selectedPage="myTasks" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.taskHeader}>
            <GoBack>
              &lt; &nbsp;
              {backToMessage}
            </GoBack>
          </div>
          <div className={`${s.taskContent} ${borderClass}`}>
            {taskManager.selectedTask && (
              <Row>
                <Col md={8} className={`${s.borderRight} p-5`}>
                  <p>
                    <span className={`${s.dot} ${dotClass}`}></span>
                    {taskManager.selectedTask.Status === TASK_STATUS_ACTIVE ? (
                      <FormattedMessage id="taskManagement.active" defaultMessage="Active" />
                    ) : (
                      <FormattedMessage id="taskManagement.complete" defaultMessage="Complete" />
                    )}
                    <FormattedMessage id="taskManagement.task" defaultMessage="Task" />
                  </p>
                  <h3>{taskManager.selectedTask.Name}</h3>
                  <p>{taskManager.selectedTask.Description}</p>
                  {taskManager.selectedTask.Activities && (
                    <div>
                      <h3>
                        <FormattedMessage id="taskManagement.activities" defaultMessage="Activities" />
                      </h3>
                      <p>{taskManager.selectedTask.Activities}</p>
                    </div>
                  )}
                  {taskManager.selectedTask.Alarms.length > 0 && (
                    <div>
                      <div className="mb-2">
                        <img src={attachmentIcon} alt="icon" className="mr-2"></img>
                        <span>
                          <FormattedMessage id="taskManagement.linkedAlarms" defaultMessage="This task is linked to the following alarms" />
                        </span>
                      </div>

                      {taskManager.selectedTask.Alarms.map(item => {
                        return (
                          <div key={item.EventActionId}>
                            <Link
                              to={`/events/action/${taskManager.selectedTask.UnitNumber}/${item.EventActionId}?from=taskDetail`}
                              className={s.alarmLink}
                            >
                              <Alert variant="light" className={`d-flex flex-row align-items-center ${s.alarmAlert}`}>
                                <img src={alert} alt="icon" />
                                <span>
                                  <span>{item.EventTitle}</span>
                                </span>
                              </Alert>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <TaskComments
                    UpdateAllowed={true}
                    taskManager={taskManager}
                    intl={intl}
                    currentTimezone={currentTimezone}
                    saveTaskComment={saveTaskComment}
                  />
                </Col>
                <Col md={4} className="p-5">
                  <h6>
                    <FormattedMessage id="taskManagement.status" defaultMessage="Status" />
                  </h6>
                  <p>
                    <span className={`${s.dot} ${dotClass}`}></span>
                    {taskManager.selectedTask.Status === TASK_STATUS_ACTIVE ? (
                      <FormattedMessage id="taskManagement.active" defaultMessage="Active" />
                    ) : (
                      <FormattedMessage id="taskManagement.complete" defaultMessage="Complete" />
                    )}
                  </p>
                  {taskManager.selectedTask.Status === TASK_STATUS_ACTIVE && (
                    <Button
                      variant="primary"
                      className={s.btnMarkComplete}
                      onClick={() => markAsCompleteClick()}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="taskTemplateManagement.markAsComplete" defaultMessage="Mark As Complete" />
                    </Button>
                  )}
                  <h6>
                    <FormattedMessage id="taskManagement.serviceTask" defaultMessage="Service task" />
                  </h6>
                  <p>{taskManager.selectedTask.IsServiceTask ? 'Yes' : 'No' || 'N/A'}</p>
                  <h6>
                    <FormattedMessage id="taskManagement.people" defaultMessage="People" />
                  </h6>
                  <span className={s.subHeading}>
                    <FormattedMessage id="taskManagement.role" defaultMessage="Role" />
                  </span>
                  <p>{taskManager.selectedTask.ProfileName ? taskManager.selectedTask.ProfileName : 'N/A'}</p>

                  <span className={s.subHeading}>
                    <FormattedMessage id="taskManagement.assignedTo" defaultMessage="Assigned to" />
                  </span>
                  <p>{taskManager.selectedTask.AssignedUsername ? taskManager.selectedTask.AssignedUsername : 'N/A'}</p>

                  <span className={s.subHeading}>
                    <FormattedMessage id="taskManagement.assignedBy" defaultMessage="Assigned by" />
                  </span>
                  <p>{taskManager.selectedTask.AssignedByUsername ? taskManager.selectedTask.AssignedByUsername : 'N/A'}</p>

                  <h6 className="pt-2">
                    <FormattedMessage id="taskManagement.dates" defaultMessage="Dates" />
                  </h6>
                  <span className={s.subHeading}>
                    <FormattedMessage id="taskManagement.dueDate" defaultMessage="Due date" />
                  </span>
                  <p>
                    {taskManager.selectedTask.DueDateTime
                      ? moment
                          .unix(taskManager.selectedTask.DueDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm:ss z')
                      : 'N/A'}
                    <span className={s.overDue}>
                      {taskManager.selectedTask.Status === TASK_STATUS_ACTIVE && getOverdueStatus(taskManager.selectedTask.DueDateTime)}
                    </span>
                  </p>
                  {taskManager.selectedTask.Status === TASK_STATUS_COMPLETE && (
                    <div>
                      <span className={s.subHeading}>
                        <FormattedMessage id="taskManagement.completedOn" defaultMessage="Completed on" />
                      </span>
                      <p>
                        {moment
                          .unix(taskManager.selectedTask.CompletedOnDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm:ss z')}
                      </p>
                    </div>
                  )}

                  <h6 className="pt-2">
                    <FormattedMessage id="taskManagement.effort" defaultMessage="Effort" />
                  </h6>
                  <span className={s.subHeading}>
                    <FormattedMessage id="taskManagement.estimatedEffort" defaultMessage="Estimated effort" />
                  </span>
                  <p>{taskManager.selectedTask.EstimatedEffort ? taskManager.selectedTask.EstimatedEffort + ' hours' : 'N/A'}</p>

                  <span className={s.subHeading}>
                    <FormattedMessage id="taskManagement.actualEffort" defaultMessage="Actual effort" />
                  </span>
                  {editActualEffort ? (
                    <Form>
                      <Row>
                        <Col xs={12} lg={3} className={s.divMaxWidth}>
                          <InputNumber
                            name="ActualEffort"
                            step="1"
                            onInputChange={onChange}
                            value={taskManager.selectedTask.ActualEffort}
                            id="formActualEffort"
                            placeholder="Enter"
                            customClassName={`${s.formControl} ${s.controlWidth} ${
                              localformErrors && localformErrors.ActualEffort ? s.formControlError : ''
                            }`}
                          />
                        </Col>
                        <Col xs={12} lg={3} className={s.divMaxWidth}>
                          <Button variant="outline-secondary" className={`${s.controlWidth} mr-2`} onClick={updateActualEfforts}>
                            <FormattedMessage id="taskManagement.save" defaultMessage="Save" />
                          </Button>
                        </Col>
                        <Col xs={12} lg={3} className={s.divMaxWidth}>
                          <Button variant="outline-secondary" className={`${s.controlWidth} mr-2`} onClick={cancelActualEffortClick}>
                            <FormattedMessage id="taskTemplateManagement.cancel" defaultMessage="Cancel" />
                          </Button>
                        </Col>
                        <Col xs={12} lg={3}></Col>
                      </Row>
                      <Row>
                        <Col>
                          {localformErrors && localformErrors.ActualEffort && (
                            <p role="alert" className={s.error}>
                              {localformErrors.ActualEffort}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  ) : (
                    <p>
                      {taskManager.selectedTask.ActualEffort ? `${taskManager.selectedTask.ActualEffort} hours  ` : ' '}
                      <a className={s.actualEffort} onClick={() => setEditActualEffort(true)}>
                        {taskManager.selectedTask.ActualEffort ? 'Edit' : 'Add'}
                      </a>
                    </p>
                  )}

                  <h6 className="pt-2">
                    <FormattedMessage id="taskManagement.unit" defaultMessage="Unit" />
                  </h6>
                  <span className={s.subHeading}>
                    <FormattedMessage id="taskManagement.unitName" defaultMessage="Name" />
                  </span>
                  <p>{taskManager.selectedTask.UnitName ? taskManager.selectedTask.UnitName : 'N/A'}</p>
                </Col>
              </Row>
            )}
          </div>
          <div className={s.changeButtonDiv} style={WidgetVisibility(userProfileWidgets, `TASKEDIT`)}>
            <Link
              to={`/tasks-and-checklists/task/${TaskId}?prevPath=${history.location.pathname}${
                checklistId ? '&checklistId=' + checklistId : ''
              }${fromView ? '&view=1' : ''}&isServiceChecklist=${isServiceChecklist}&unitId=${unitId}`}
            >
              <Button variant="outline-secondary" className={`${s.btnEdit} mr-2`}>
                <FormattedMessage id="taskManagement.edit" defaultMessage="Edit" />
              </Button>
            </Link>
            {parseInt(userId) === taskManager.selectedTask.CreatedByUserId && IsWidgetAccessible(userProfileWidgets, `TASKDELETE`) && (
              <a onClick={() => handleCommand()}>
                <Button variant="outline-secondary" className={s.btnEdit}>
                  <FormattedMessage id="taskManagement.delete" defaultMessage="Delete" />
                </Button>
              </a>
            )}
          </div>
        </div>
      </TasksAndChecklistsContentTemplate>
    </div>
  );
};

TaskDetail.defaultProps = {
  taskManager: {
    ...initialTasksState.tasks
  }
};

TaskDetail.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired,
  taskManager: PropTypes.object.isRequired,
  describeTask: PropTypes.func.isRequired,
  saveTask: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  changeTask: PropTypes.func.isRequired,
  saveTaskComment: PropTypes.func.isRequired
};

export default injectIntl(TaskDetail);
