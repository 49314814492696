import { initialOrganisationRegionState } from './initialState';

import {
  ORGANISATION_REGION_ERROR,
  GET_ALL_ORGANISATION_REGION_DETAILS,
  ORGANISATION_REGION_IS_LOADING,
  SET_ORGANISATION_REGION_CURRENT_PAGE,
  SET_ORGANISATION_REGION_PAGE_FILTER,
  SET_ORGANISATION_REGION_CHANGE,
  SET_ORGANISATION_REGION_CLEANUP,
  SET_ORGANISATION_REGION,
  UPDATE_ORGANISATION_REGION_SAVE_STATUS
} from '../constants/index';

const organisationRegionReducer = (state = initialOrganisationRegionState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_ORGANISATION_REGION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        organisationRegion: {
          ...state.organisationRegion,
          ...data
        }
      };
    }

    case SET_ORGANISATION_REGION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        organisationRegion: {
          ...state.organisationRegion,
          currentPage
        }
      };
    }

    case SET_ORGANISATION_REGION_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        organisationRegion: {
          ...state.organisationRegion,
          filter
        }
      };
    }

    case UPDATE_ORGANISATION_REGION_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        organisationRegion: {
          ...state.organisationRegion,
          ...data
        }
      };
    }

    case ORGANISATION_REGION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        organisationRegion: {
          ...state.organisationRegion,
          isLoading: isLoading
        }
      };
    }

    case ORGANISATION_REGION_ERROR: {
      const { data } = payload;
      return {
        ...state,
        organisationRegion: {
          ...state.organisationRegion,
          ...data
        }
      };
    }

    case SET_ORGANISATION_REGION: {
      let data = payload;

      return {
        ...state,
        organisationRegion: {
          ...state.organisationRegion,
          isOpSuccessful: false,
          showBanner: false,
          selectedOrganisationRegion: {
            ...state.organisationRegion.selectedOrganisationRegion,
            ...data
          }
        }
      };
    }

    case SET_ORGANISATION_REGION_CHANGE: {
      let { selectedOrganisationRegion } = payload;

      return {
        ...state,
        organisationRegion: {
          ...state.organisationRegion,
          isOpSuccessful: false,
          showBanner: false,
          selectedOrganisationRegion: {
            ...state.organisationRegion.selectedOrganisationRegion,
            ...selectedOrganisationRegion
          }
        }
      };
    }
    case SET_ORGANISATION_REGION_CLEANUP: {
      let selectedOrganisationRegion = payload;
      return {
        ...state,
        organisationRegion: {
          ...state.organisationRegion,
          organisationRegions: [],
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          selectedOrganisationRegion: selectedOrganisationRegion
        }
      };
    }

    default:
      return state;
  }
};

export default organisationRegionReducer;
