import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import s from './ReleaseNoteList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialReleaseNotesState } from '../../reducers/initialState';
import ReleaseNoteListTable from './ReleaseNoteListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import { getReleaseNote, getCurrentTimezone, getUserProfileWidget } from '../../selectors/index';
import {
  getReleaseNoteListThunk,
  setReleaseNoteFilteredDataThunk,
  setCurrentPage,
  setPageFilter,
  setDeleteReleaseNoteListThunk,
  saveReleaseNoteThunk
} from '../../actions/releaseNotes';
import { push } from 'connected-react-router';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import Button from 'react-bootstrap/Button';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';

const SEARCH_FILTER = 'SEARCH_FILTER';

const ReleaseNoteList = ({ intl }) => {
  const dispatch = useDispatch();
  const releaseNote = useSelector(state => getReleaseNote(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  const [searchFilter, setSearchFilter] = useState('');
  const [asendingOrder, setAsendingOrder] = useState(false);

  const [active, setActive] = useState(false);
  const [releaseNoteId, setReleaseNoteId] = useState(0);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let filter = releaseNote.filterBy.filter || '';
  let offset = releaseNote.offset || 0;
  let limit = releaseNote.limit || 1000;

  useEffect(() => {
    let filterObj = {
      filter: filter,
      offset: offset,
      limit: limit
    };
    dispatch(getReleaseNoteListThunk(filterObj, 'RELEASENOTELIST'));
  }, [getReleaseNoteListThunk, offset, limit, filter]);

  useEffect(() => {
    //To Reload after delete
    if (releaseNote.isRedirect && !releaseNote.showBanner && releaseNoteId) {
      let data = {};
      data.releaseNoteData = releaseNote.releaseNotes.filter(item => item.ReleaseNoteId != releaseNoteId);
      data.filteredResultData = releaseNote.filteredResults.filter(item => item.ReleaseNoteId != releaseNoteId);

      dispatch(setDeleteReleaseNoteListThunk(data));
    }
  }, [getReleaseNoteListThunk, releaseNote.isRedirect]);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  let releaseNotesObj = Object.values(releaseNote.filteredResults);
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'RELEASENOTEMANAGE');

  if (asendingOrder) {
    releaseNotesObj = releaseNotesObj.sort(compareValues('Name'));
  } else {
    releaseNotesObj = releaseNotesObj.sort(compareValues('Naame', 'desc'));
  }

  const filteredResults = (filterType, value) => {
    let filteredList = releaseNote.releaseNotes;

    let apifilterObj = {
      filter: null
    };

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (releaseNote.releaseNoteListCount > releaseNote.releaseNotes.length) {
      setPageFilter(apifilterObj);
    } else {
      dispatch(setReleaseNoteFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  const onReleaseNoteSelection = (id, guid) => {
    if (isUpdateAllowed) {
      dispatch(push(`/admin-controls/release-notes/${id}`));
    }
  };

  const performDelete = e => {
    let saveData = releaseNote.selectedReleaseNote;
    saveData.ReleaseNoteId = releaseNoteId;
    saveData.PerformDelete = true;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveReleaseNoteThunk(saveData, 'RELEASENOTEMANAGE'));
  };

  const onRemoveClick = releaseNoteId => {
    setActive(true);
    setReleaseNoteId(releaseNoteId);
  };

  const handleClose = (clearId = true) => {
    setActive(false);
    if (clearId) setReleaseNoteId(0);
  };

  const handleConfirm = () => {
    performDelete();
    handleClose(false);
  };

  const dialogTitle = intl.formatMessage({
    id: 'releaseNotes.confirmDelete',
    defaultMessage: 'Are you sure you want to delete release note?'
  });

  let messageId = (releaseNote && releaseNote.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <AdminControlsContentTemplate selectedPage="manageReleaseNotes" userProfileWidgets={userProfileWidgets}>
      <div>
        <div className={s.fullWidth}>
          <div className={s.fullWidthWrapper}>
            <h1 className={s.tabHeader}>{<FormattedMessage id="releaseNotes.releaseNotes" defaultMessage="Release Notes" />}</h1>
          </div>
        </div>

        <div className={s.pageContent}>
          <div className={s.releaseNotes}>
            {releaseNote.isLoading && <LoadingSpinner />}
            <Banner
              failureText={messageText}
              showBanner={releaseNote.showBanner}
              status={releaseNote.isOpSuccessful}
              successText={messageText}
            />
            <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
            <div className={s.contentWrapper}>
              <Row>
                <Col sm={12}>
                  <span className={s.textHeading}>
                    <FormattedMessage id="releaseNotes.search" defaultMessage="Search" />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={6} lg={4}>
                  <SearchBar
                    searchHandler={searchHandler}
                    clearSearchInVisible={false}
                    initialText={searchFilter}
                    placeHolderTextId="releaseNotes.releaseNoteSearchName"
                  />
                </Col>
                <Col xs={6} lg={8} className={s.colAddButton}>
                  <Link to={`/admin-controls/release-notes/0`}>
                    <Button variant="primary" data-unittest="addnew" className={s.addButton}>
                      +<FormattedMessage id="releaseNotes.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Link>
                </Col>
              </Row>

              <div className="">
                <Pagination
                  data={releaseNotesObj}
                  pageSize={DEFAULT_PAGE_SIZE}
                  onPageChange={onPageChangeHandler}
                  startingPage={releaseNote.currentPage}
                >
                  <ReleaseNoteListTable
                    redirect={push}
                    onRemoveClick={onRemoveClick}
                    UpdateAllowed={isUpdateAllowed}
                    currentPage={releaseNote.currentPage}
                    currentTimezone={currentTimezone}
                    onSortOrder={onSortOrder}
                    onReleaseNoteSelection={onReleaseNoteSelection}
                    asendingOrder={asendingOrder}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminControlsContentTemplate>
  );
};

ReleaseNoteList.defaultProps = {
  ...initialReleaseNotesState
};

ReleaseNoteList.propTypes = {};

export default injectIntl(ReleaseNoteList);
