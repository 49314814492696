import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './Calibration.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialCalibrationState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Redirect, Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import CalibrationResults from './CalibrationResults';
import CalibrationConstants from './CalibrationConstants';
import CalibrationComments from './CalibrationComments';
import { CALIBRATION_DRAFT } from '../../constants/index';
import GoBack from '../WSAControls/GoBack/GoBack';

const Calibration = ({
  calibrationManager,
  userProfileWidgets,
  currentTimezone,
  unitComponentParts,
  unitSubComponentDetails,
  setCalibrationChange,
  setCalibrationResultChange,
  setCalibrationConstant,
  describeCalibration,
  describeCalibrationEquation,
  getUnitComponentParts,
  saveCalibration,
  saveCalibrationComment,
  intl
}) => {
  let IsEdit = false;
  let IsRedirect = false;
  let { unitSerialNumber, unitId, calibrationId } = useParams();
  const [localformErrors, setFormErrors] = useState({});

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'CALIBRATION_EDIT');
  const isCommentAllowed = IsWidgetAccessible(userProfileWidgets, 'CALIBRATION_COMMENT_ADD');

  let userName = Cookies.get(`name-${envName}`) || '';
  var d = new Date();
  const currentTimestamp = calibrationManager.SelectedCalibration.CalibrationDateTime
    ? calibrationManager.SelectedCalibration.CalibrationDateTime
    : moment(d).unix();

  let widgetCode = '';

  if (calibrationId && parseInt(calibrationId) > 0) {
    IsEdit = true;
    userName = calibrationManager.SelectedCalibration.FullName;
    widgetCode = 'CALIBRATION_EDIT';
  }

  useEffect(() => {
    if ((!calibrationId || calibrationId === 0) && unitId > 0) {
      getUnitComponentParts(unitSerialNumber, unitId);
    }
  }, [getUnitComponentParts, calibrationId, unitSerialNumber, unitId]);

  useEffect(() => {
    if (unitSerialNumber.length > 0 && calibrationId > 0) {
      describeCalibration(calibrationId, unitSerialNumber);
    } else {
      setCalibrationChange({
        SelectedCalibration: { ...calibrationManager.SelectedCalibration, ...initialCalibrationState.calibration.SelectedCalibration }
      });
    }
  }, [describeCalibration, calibrationId, unitSerialNumber]);

  const calibrationRequiredToggleHandler = () => {
    setCalibrationChange({
      SelectedCalibration: {
        ...calibrationManager.SelectedCalibration,
        CalibrationRequired: !calibrationManager.SelectedCalibration.CalibrationRequired
      }
    });
  };

  const onSensorDropdownChange = e => {
    let unitComponentType = getUnitComponentTypeInfoById(0);
    setCalibrationChange({
      SelectedCalibration: {
        ...calibrationManager.SelectedCalibration,
        ...unitComponentType,
        CalibrationDateTime: currentTimestamp,
        ComponentPartId: e.value
      }
    });
  };

  const onDropdownChange = e => {
    let unitComponentType = getUnitComponentTypeInfoById(e.value);
    describeCalibrationEquation(unitComponentType.CalibrationEquationId, calibrationId);
    setCalibrationChange({
      SelectedCalibration: { ...calibrationManager.SelectedCalibration, ...unitComponentType, CalibrationDateTime: currentTimestamp }
    });
  };
  const onDateChange = value => {
    // let defaultValue = getDefaultValue(e.value);
    setCalibrationChange({
      SelectedCalibration: { ...calibrationManager.SelectedCalibration, CalibrationDateTime: moment(value).unix() }
    });
  };

  const getUnitComponentTypeInfoById = id => {
    let sensorInfo = {
      CalibrationAcceptedErrorTolerance: '',
      OutputRangeLow: '',
      OutputRangeHigh: '',
      UnitOfMeasure: '',
      CalibrationApprovedSubComponentId: 0,
      CalibrationEquationId: 0
    };
    if (unitSubComponentDetails.length > 0 && id && id > 0) {
      const unitComponentType = unitSubComponentDetails.filter(comp => comp.ApprovedSubComponentId === parseInt(id))[0];
      sensorInfo.CalibrationApprovedSubComponentId = parseInt(id);
      if (unitComponentType) {
        sensorInfo.CalibrationAcceptedErrorTolerance = unitComponentType.CalibrationAcceptedErrorTolerance;
        sensorInfo.OutputRangeLow = unitComponentType.OutputRangeLow;
        sensorInfo.OutputRangeHigh = unitComponentType.OutputRangeHigh;
        sensorInfo.UnitOfMeasure = unitComponentType.UnitOfMeasure;
        sensorInfo.CalibrationEquationId = unitComponentType.CalibrationEquationId;
      }
    }

    return sensorInfo;
  };

  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }
    let saveData = calibrationManager.SelectedCalibration;
    saveData.UnitId = parseInt(unitId);
    saveData.UnitSerialNumber = parseInt(unitSerialNumber);
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    // These needs to be reset in order to be finalized and updated
    saveData.IsFinalized = false;
    saveData.IsUploaded = false;
    saveData.Status = CALIBRATION_DRAFT;

    if (!IsEdit) {
      saveData.CalibrationId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'CALIBRATION_ADD';
    }
    saveCalibration(saveData, unitSerialNumber, true, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !calibrationManager ||
      !calibrationManager.SelectedCalibration ||
      !calibrationManager.SelectedCalibration.ComponentPartId ||
      isRequired(calibrationManager.SelectedCalibration.ComponentPartId, 1)
    ) {
      formErrors.ComponentPartId = intl.formatMessage({
        id: 'calibration.sensorMandatory',
        defaultMessage: 'Sensor is mandatory'
      });
      isValid = false;
    }

    if (
      !calibrationManager ||
      !calibrationManager.SelectedCalibration ||
      !calibrationManager.SelectedCalibration.CalibrationApprovedSubComponentId ||
      isRequired(calibrationManager.SelectedCalibration.CalibrationApprovedSubComponentId, 1)
    ) {
      formErrors.CalibrationApprovedSubComponentId = intl.formatMessage({
        id: 'calibration.componentMandatory',
        defaultMessage: 'Calibration component is mandatory'
      });
      isValid = false;
    }

    if (
      !calibrationManager ||
      !calibrationManager.SelectedCalibration ||
      !calibrationManager.SelectedCalibration.CalibrationDateTime ||
      isRequired(calibrationManager.SelectedCalibration.CalibrationDateTime, 1)
    ) {
      formErrors.CalibrationDateTime = intl.formatMessage({
        id: 'calibration.calibrationDateMandatory',
        defaultMessage: 'Calibration Date is mandatory'
      });
      isValid = false;
    }
    setFormErrors(formErrors);

    return isValid;
  };

  const unitSensorOption = () => {
    let unitComponentData = [];

    const sensorComponents = unitComponentParts;

    sensorComponents.forEach(element => {
      unitComponentData.push({
        ...element,
        label: element.ModelComponent,
        value: element.ComponentPartId
      });
    });
    return unitComponentData.sort(compareValues('ModelComponent'));
  };

  const unitSubComponentOption = id => {
    let unitSubComponentData = [];

    const subComponents = unitSubComponentDetails.filter(x => x.ComponentPartId == id);

    subComponents.forEach(element => {
      unitSubComponentData.push({
        ...element,
        label: element.ModelComponent,
        value: element.ApprovedSubComponentId
      });
    });
    return unitSubComponentData.sort(compareValues('ModelComponent'));
  };

  const disablePastDates = current =>
    current.isAfter(
      moment()
        .tz(currentTimezone)
        .subtract(1, 'day')
    );

  let ddlClass = localformErrors && localformErrors.ComponentPartId ? s.ddlError : '';
  const returnUrl = IsEdit
    ? `/unit/calibrations/${unitSerialNumber}/view/${unitId}/${calibrationId}`
    : '/unit/calibrations/' + unitSerialNumber;
  let messageId = (calibrationManager && calibrationManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <div className={s.calibration}>
      {calibrationManager.isLoading && <LoadingSpinner />}
      {calibrationManager.isOpSuccessful && !calibrationManager.showBanner ? <Redirect to={returnUrl} /> : ''}
      {IsRedirect ? <Redirect to={returnUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={calibrationManager.showBanner || false}
        status={calibrationManager.isOpSuccessful}
        successText={messageText}
      />
      <ContentTemplate selectedPage="calibration" userProfileWidgets={userProfileWidgets} widgetCode={widgetCode} tagoutRequired={false}>
        <div className={s.contentWrapper}>
          <div className={s.calibrationHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="calibration.backToCalibrations" defaultMessage="BACK" />
            </GoBack>
            <h3 data-unittest="headingLabel">
              <FormattedMessage id="calibration.calibration" defaultMessage="Calibration" />
            </h3>
          </div>
          <div className={s.calibrationContent}>
            <Row className={s.breakrow}>
              <Col sm={12}>
                <h4>
                  <FormattedMessage id="calibration.details" defaultMessage="Details" />
                </h4>
              </Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.unit" defaultMessage="Unit" />
                </Form.Label>
              </Col>
              <Col sm={10}>{unitSerialNumber}</Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.reporter" defaultMessage="Reporter" />
                </Form.Label>
              </Col>
              <Col sm={10}>{userName}</Col>
            </Row>

            <hr className={s.breakhr} />

            <Row className={s.breakrow}>
              <Col sm={3}>
                <Form.Group controlId="frmSensorId">
                  <Form.Label>
                    <FormattedMessage id="calibration.sensor" defaultMessage="Sensor" />
                  </Form.Label>
                  {!IsEdit ? (
                    <Dropdown
                      id="drpSensorId"
                      dataArray={unitSensorOption()}
                      controlData={{
                        placeholderText: <FormattedMessage id="calibration.selectSensor" defaultMessage="Select Sensor" />,
                        customClassName: ddlClass
                      }}
                      onDropdownChange={onSensorDropdownChange}
                      selectedOption={unitSensorOption().filter(
                        option => option.value === calibrationManager.SelectedCalibration.ComponentPartId
                      )}
                      disabled={IsEdit}
                      data-unittest="formGlobalSetting"
                    />
                  ) : (
                    <div className={s.paddingTop}>
                      <Form.Label>{calibrationManager.SelectedCalibration.ComponentPartName}</Form.Label>
                    </div>
                  )}
                  {localformErrors && localformErrors.ComponentPartId && (
                    <p role="alert" className={s.error}>
                      {localformErrors.ComponentPartId}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group controlId="frmSensorId">
                  <Form.Label>
                    <FormattedMessage id="calibration.calibrationComponent" defaultMessage="Calibration Component" />
                  </Form.Label>
                  {!IsEdit ? (
                    <Dropdown
                      id="drpSubComponentId"
                      dataArray={unitSubComponentOption(calibrationManager.SelectedCalibration.ComponentPartId)}
                      controlData={{
                        placeholderText: (
                          <FormattedMessage id="calibration.calibrationComponent" defaultMessage="Select Calibration Component" />
                        ),
                        customClassName: ddlClass
                      }}
                      onDropdownChange={onDropdownChange}
                      selectedOption={unitSubComponentOption(calibrationManager.SelectedCalibration.ComponentPartId).filter(
                        option => option.value === calibrationManager.SelectedCalibration.CalibrationApprovedSubComponentId
                      )}
                      disabled={IsEdit}
                    />
                  ) : (
                    <div className={s.paddingTop}>
                      <Form.Label>
                        {calibrationManager.SelectedCalibration.ComponentPartName +
                          '_' +
                          calibrationManager.SelectedCalibration.CalibrationComponentName}
                      </Form.Label>
                    </div>
                  )}
                  {localformErrors && localformErrors.CalibrationApprovedSubComponentId && (
                    <p role="alert" className={s.error}>
                      {localformErrors.CalibrationApprovedSubComponentId}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group controlId="frmCalibrationDatetime">
                  <Form.Label>
                    <FormattedMessage id="calibration.calibrationDatetime" defaultMessage="Calibration Date Time" />
                  </Form.Label>
                  <DateTime
                    isValidDate={disablePastDates}
                    onChange={m => onDateChange(m.valueOf())}
                    timeFormat="HH:mm"
                    dateFormat="DD-MMM-YYYY"
                    value={moment.unix(currentTimestamp).tz(currentTimezone)}
                    defaultValue={moment(currentTimestamp).tz(currentTimezone)}
                    closeOnSelect
                  />
                  {localformErrors && localformErrors.CalibrationDateTime && (
                    <p role="alert" className={s.error}>
                      {localformErrors.CalibrationDateTime}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}></Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.tolerance" defaultMessage="Tolerance" />
                </Form.Label>
              </Col>
              <Col sm={10}>{calibrationManager.SelectedCalibration.CalibrationAcceptedErrorTolerance}</Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.sensorRange" defaultMessage="Sensor Range" />
                </Form.Label>
              </Col>
              <Col sm={10}>
                <Row>
                  <Col md={2}>
                    <Form.Label>
                      <FormattedMessage id="calibration.low" defaultMessage="Low" />
                    </Form.Label>{' '}
                    : {calibrationManager.SelectedCalibration.OutputRangeLow}
                  </Col>
                  <Col md={2}>
                    <Form.Label>
                      <FormattedMessage id="calibration.high" defaultMessage="High" />
                    </Form.Label>{' '}
                    : {calibrationManager.SelectedCalibration.OutputRangeHigh}
                  </Col>
                  <Col md={2}>
                    <Form.Label>
                      {' '}
                      <FormattedMessage id="calibration.unitOfMeasure" defaultMessage="Unit Of Measure" />
                    </Form.Label>{' '}
                    : {calibrationManager.SelectedCalibration.UnitOfMeasure}
                  </Col>
                  <Col md={6}></Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <CalibrationResults
          UpdateAllowed={isUpdateAllowed}
          calibrationManager={calibrationManager}
          setCalibrationResults={setCalibrationResultChange}
          intl={intl}
        />
        <CalibrationConstants
          UpdateAllowed={isUpdateAllowed}
          calibrationManager={calibrationManager}
          setCalibrationRequired={calibrationRequiredToggleHandler}
          setCalibrationConstant={setCalibrationConstant}
          intl={intl}
        />
        {IsEdit && isCommentAllowed && (
          <CalibrationComments
            UpdateAllowed={isUpdateAllowed}
            calibrationManager={calibrationManager}
            intl={intl}
            currentTimezone={currentTimezone}
            saveCalibrationComment={saveCalibrationComment}
            unitSerialNumber={unitSerialNumber}
          />
        )}
        <Row className={s.breakRow}>
          <Col sm={12}>
            <Button
              variant="outline-secondary"
              className={classNames(s.btnSaveChanges)}
              onClick={submitForm}
              noValidate
              data-unittest="saveData"
            >
              <FormattedMessage id="calibration.save" defaultMessage="SAVE CHANGES" />
            </Button>
            <Link to={returnUrl}>
              <Button variant="outline-secondary" className={s.btnCancel}>
                <FormattedMessage id="calibration.cancel" defaultMessage="CANCEL" />
              </Button>
            </Link>
          </Col>
        </Row>
      </ContentTemplate>
    </div>
  );
};

Calibration.defaultProps = {
  calibrationManager: {
    ...initialCalibrationState.Calibration
  },
  unitComponentParts: [],
  unitSubComponentDetails: []
};

Calibration.propTypes = {
  describeCalibration: PropTypes.func.isRequired,
  describeCalibrationEquation: PropTypes.func.isRequired,
  getUnitComponentParts: PropTypes.func.isRequired
};

export default injectIntl(Calibration);
