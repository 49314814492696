import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './FAQ.module.scss';
import { injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import { initialFAQCategoryState } from '../../../reducers/initialState';
import HelpControlsContentTemplate from '../HelpControls/HelpControlsContentTemplate';
import { getFAQCategoryThunk, setFAQCategoryCleanUpThunk } from '../../../actions/faqCategories';
import { getFAQCategory } from '../../../selectors/index';
import { groupBy, sortObjectKeys } from '../../../utils/';
import FAQItem from './FAQItem';

const FAQ = ({ intl }) => {
  const dispatch = useDispatch();

  const faqCategory = useSelector(state => getFAQCategory(state));

  useEffect(() => {
    dispatch(getFAQCategoryThunk('GENERALACCESS', true));
  }, [getFAQCategoryThunk]);

  useEffect(() => {
    return () => {
      dispatch(
        setFAQCategoryCleanUpThunk({
          ...initialFAQCategoryState.faqCategory.selectedFAQCategory
        })
      );
    };
  }, []);

  const faqCategoryObj = Object.values(faqCategory.faqCategories);
  const groupByCategoryName = groupBy('Name');
  const groupedData = sortObjectKeys(groupByCategoryName(faqCategoryObj));

  let messageId = (faqCategory && faqCategory.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <div className={s.faq}>
      {faqCategory.isLoading && <LoadingSpinner />}
      <Banner failureText={messageText} showBanner={faqCategory.showBanner} status={faqCategory.isOpSuccessful} successText={messageText} />
      <HelpControlsContentTemplate selectedPage="faq">
        <div className={s.contentWrapper}>
          {Object.keys(groupedData).map(element => {
            if (groupedData[element][0].FAQuestionId) {
              return (
                <div className={s.faqCategory} key={element}>
                  <h3>{element}</h3>
                  <div className={s.questionsContent}>
                    {groupedData[element].map(item => {
                      return <FAQItem key={item.FAQuestionId} faqItem={item} />;
                    })}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </HelpControlsContentTemplate>
    </div>
  );
};

FAQ.defaultProps = {
  ...initialFAQCategoryState
};

export default injectIntl(FAQ);
