import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import ComponentPartList from './ComponentPartList';
import { getComponentParts, getCurrentTimezone, getUserProfileWidget, getUserProfileUnit } from '../../selectors/index';
import {
  getComponentPartsThunk,
  getComponentPartsFilterThunk,
  setComponentPartFilteredDataThunk,
  setCurrentPage,
  setPageFilter
} from '../../actions/componentParts';

const mapStateToProps = state => {
  return {
    componentParts: getComponentParts(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state),
    userProfileUnits: getUserProfileUnit(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirect: location => push(location),
      getComponentParts: filterObj => getComponentPartsThunk(filterObj, 'PARTS_TAB'),
      getComponentPartFilters: () => getComponentPartsFilterThunk('PARTS_TAB'),
      setFilterData: currentPage => setComponentPartFilteredDataThunk(currentPage),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ComponentPartList);
