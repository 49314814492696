import {
  GET_ALL_SEQUENCEVERSION_DETAILS,
  SEQUENCEVERSION_IS_LOADING,
  SEQUENCEVERSION_MANAGEMENT_ERROR,
  SET_SEQUENCEVERSION_CURRENT_PAGE,
  SET_SEQUENCEVERSION_DETAILS,
  SET_SEQUENCEVERSION_CHANGE,
  SET_SEQUENCEVERSION_FILTERED_RESULTS,
  SEQUENCEVERSION_CLEANUP,
  UPDATE_SEQUENCEVERSION_SAVE_STATUS,
  SET_SEQUENCEVERSIONFILE_CHANGE,
  SET_SEQUENCEVERSIONFILE_DETAILS,
  UPDATE_SEQUENCEVERSIONFILE_SAVE_STATUS
} from '../constants/index';
import { initialSequenceVersionState } from './initialState';

const sequenceVersionReducer = (state = initialSequenceVersionState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_SEQUENCEVERSION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          ...data
        }
      };
    }

    case SET_SEQUENCEVERSION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          currentPage
        }
      };
    }

    case SEQUENCEVERSION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          isLoading: isLoading
        }
      };
    }

    case SEQUENCEVERSION_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          ...data
        }
      };
    }

    case UPDATE_SEQUENCEVERSION_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          ...data
        }
      };
    }

    case UPDATE_SEQUENCEVERSIONFILE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          selectedSequenceVersion: {
            ...state.sequenceVersionManager.selectedSequenceVersion,
            ...data
          }
        }
      };
    }

    case SET_SEQUENCEVERSION_DETAILS: {
      let { SequenceVersion } = payload;

      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          isOpSuccessful: false,
          performDelete: false,
          duplicateSequence: false,
          selectedSequenceVersion: {
            ...state.sequenceVersionManager.selectedSequenceVersion,
            ...SequenceVersion
          }
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: false
      };
    }

    case SET_SEQUENCEVERSION_CHANGE: {
      let { selectedSequenceVersion } = payload;

      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          selectedSequenceVersion: selectedSequenceVersion
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: false
      };
    }

    case SET_SEQUENCEVERSION_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          ...filteredResults
        }
      };
    }

    case SEQUENCEVERSION_CLEANUP: {
      const { data } = payload;
      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          ...data
        }
      };
    }

    case SET_SEQUENCEVERSIONFILE_DETAILS: {
      let { SequenceVersionFile } = payload;

      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          selectedSequenceVersion: {
            ...state.sequenceVersionManager.selectedSequenceVersion,
            selectedSequenceVersionFile: {
              ...SequenceVersionFile,
              JsonText: (SequenceVersionFile.JsonText && SequenceVersionFile.JsonText) || ''
            }
          }
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: false
      };
    }

    case SET_SEQUENCEVERSIONFILE_CHANGE: {
      let { selectedSequenceVersionFile } = payload;

      return {
        ...state,
        sequenceVersionManager: {
          ...state.sequenceVersionManager,
          selectedSequenceVersion: {
            ...state.sequenceVersionManager.selectedSequenceVersion,
            selectedSequenceVersionFile: {
              ...state.sequenceVersionManager.selectedSequenceVersion.selectedSequenceVersionFile,
              ...selectedSequenceVersionFile
            }
          }
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: false
      };
    }

    default:
      return state;
  }
};

export default sequenceVersionReducer;
