import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './TaskList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { initialTasksState } from '../../reducers/initialState';
import TaskListTable from './TaskListTable';
import { Link, useHistory } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import {
  DEFAULT_PAGE_SIZE,
  TASK_STATUS_ACTIVE,
  TASK_STATUS_COMPLETE,
  TASK_ASSIGNED_TO_ME,
  TASK_CREATED_BY_ME,
  TASK_MARK_AS_COMPLETE
} from '../../constants';
import { IsWidgetAccessible, WidgetVisibility } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { compareValues, isRequired } from '../../utils';
import Cookies from 'js-cookie';
import TasksAndChecklistsContentTemplate from '../TasksAndChecklists/TasksAndChecklistsContentTemplate';
import uuidv4 from 'uuid/v4';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';

const STATUS = 'STATUS';
const USER_FILTER = 'USER_FILTER';
const SERVICE_TASK_FILTER = 'SERVICE_TASK_FILTER';
const SEARCH_FILTER = 'SEARCH_FILTER';
const UNIT_FILTER = 'UNIT_FILTER';

const TaskList = ({
  getTasks,
  setFilterData,
  setCurrentPage,
  setPageFilter,
  tasksManager,
  redirect,
  intl,
  userProfileWidgets,
  currentTimezone,
  saveTask,
  changeTask,
  userUnits,
  getTasksFilters
}) => {
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let userId = Cookies.get(`userid-${envName}`) || 0;
  let filter = tasksManager.filterBy.filter || '';
  let offset = tasksManager.offset || 0;
  let limit = tasksManager.limit || 1000;
  const history = useHistory();
  let OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  let filterByStatus = tasksManager.filterBy.filterStatus || null;
  let filterAssignedByUser = tasksManager.filterBy.filterAssignedByUser || null;
  let filterCreatedByUser = tasksManager.filterBy.filterCreatedByUser || null;
  let filterIsServiceTask = tasksManager.filterBy.filterIsServiceTask;
  let filterByUnitId = tasksManager.filterBy.filterUnitId || null;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterStatus, setFilterStatus] = useState('0');
  const [filterUser, setFilterUser] = useState('0');
  const [filterServiceTask, setFilterServiceTask] = useState('0');
  const [filterUnitId, setFilterUnitId] = useState(0);

  const [bulkAction, setBulkAction] = useState('');
  const [isBulkComplete, setBulkComplete] = useState(false);
  const [localformErrors, setFormErrors] = useState({});
  const [asendingOrder, setAsendingOrder] = useState(false);

  const unitsObj =
    userUnits && userUnits.userProfileUnits && userUnits.userProfileUnits.filter(x => x.OrganisationId === parseInt(OrganisationId));

  useEffect(() => {
    getTasksFilters();
  }, [getTasksFilters]);

  useEffect(() => {
    let filterObj = {
      filterStatus: filterByStatus,
      filterAssignedUser: filterAssignedByUser,
      filterCreatedUser: filterCreatedByUser,
      filterIsServiceTask: filterIsServiceTask,
      filterUnitId: filterByUnitId,
      filter: filter,
      offset: offset,
      limit: limit,
      dashboardTasks: false
    };
    getTasks(filterObj);
  }, [getTasks, offset, limit, filter, filterByStatus, filterAssignedByUser, filterCreatedByUser, filterIsServiceTask, filterByUnitId]);

  const statusOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="tasksManagement.showAll" defaultMessage="Show: All" />,
      value: '0'
    });
    statusData.push({
      label: TASK_STATUS_ACTIVE,
      value: TASK_STATUS_ACTIVE
    });
    statusData.push({
      label: TASK_STATUS_COMPLETE,
      value: TASK_STATUS_COMPLETE
    });

    return statusData;
  };

  const userOptions = () => {
    let userData = [];

    userData.push({
      label: <FormattedMessage id="tasksManagement.showAll" defaultMessage="Show: All" />,
      value: '0'
    });
    userData.push({
      label: TASK_ASSIGNED_TO_ME,
      value: '1'
    });
    userData.push({
      label: TASK_CREATED_BY_ME,
      value: '2'
    });

    return userData;
  };

  const serviceTaskOptions = () => {
    let serviceData = [];

    serviceData.push({
      label: <FormattedMessage id="tasksManagement.showAll" defaultMessage="Show: All" />,
      value: '0'
    });
    serviceData.push({
      label: <FormattedMessage id="tasksManagement.serviceTask" defaultMessage="Service task" />,
      value: true
    });
    serviceData.push({
      label: <FormattedMessage id="tasksManagement.nonServiceTask" defaultMessage="Non-service task" />,
      value: false
    });

    return serviceData;
  };

  const bulkActionOptions = () => {
    let optData = [];

    optData.push({
      label: <FormattedMessage id="tasksManagement.bulkActions" defaultMessage="Bulk Actions" />,
      value: '0'
    });
    optData.push({
      label: TASK_MARK_AS_COMPLETE,
      value: TASK_MARK_AS_COMPLETE
    });

    return optData;
  };

  const onStatusChange = e => {
    setFilterStatus(e.value);
    filteredResults(STATUS, e.value);
    setCurrentPage(1);
  };

  const onUserFilterChange = e => {
    setFilterUser(e.value);
    filteredResults(USER_FILTER, e.value);
    setCurrentPage(1);
  };

  const onServiceTaskFilterChange = e => {
    setFilterServiceTask(e.value);
    filteredResults(SERVICE_TASK_FILTER, e.value);
    setCurrentPage(1);
  };

  const onBulkActionsChange = e => {
    setBulkAction(e.value);
    bulkActionsSetup(e.value);
    setCurrentPage(1);
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
    // setPageFilter(filter);
  };

  const onUnitIdChange = e => {
    setFilterUnitId(e.value);
    filteredResults(UNIT_FILTER, e.value);
    setCurrentPage(1);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const viewBtnClick = url => {
    history.push(`${url}`);
  };

  const addBtnClick = () => {
    history.push(`/tasks-and-checklists/task?prevPath=${history.location.pathname}`);
  };

  let tasksObj = Object.values(tasksManager.filteredResults);

  if (asendingOrder) {
    tasksObj = tasksObj.sort(compareValues('DueDateTime'));
  } else {
    tasksObj = tasksObj.sort(compareValues('DueDateTime', 'desc'));
  }

  const bulkActionsSetup = actionType => {
    if (actionType === TASK_MARK_AS_COMPLETE) {
      let formErrors = {};
      if (!tasksManager || !tasksManager.filteredResults || tasksManager.filteredResults.filter(x => x.IsSelected).length === 0) {
        formErrors.TaskId = intl.formatMessage({
          id: 'tasksManagement.selectTask',
          defaultMessage: 'Select at least one task'
        });

        setFormErrors(formErrors);
        setBulkAction('0');
        return false;
      }
      setFormErrors({});
      setBulkComplete(true);
    } else {
      setBulkComplete(false);
    }
  };

  const bulkCompleteTasks = () => {
    if (!validateInput()) {
      return;
    }
    let saveData = tasksManager.selectedTask;
    saveData.BulkComplete = true;
    saveData.UpdatedBy = userId;
    saveData.TaskIds = tasksManager.filteredResults.filter(x => x.IsSelected).map(y => y.TaskId);
    let comments = [];

    saveData.TaskIds.forEach(taskId => {
      comments.push({
        GuidIdentifier: uuidv4(),
        Description: saveData.Comment,
        IsActive: true,
        UpdatedBy: Cookies.get(`userid-${envName}`) || null,
        TaskId: taskId
      });
    });

    saveData.Comments = comments;

    saveTask(saveData, 'TASKEDIT');
    setBulkComplete(false);
  };

  const cancelClick = () => {
    setBulkComplete(false);
    setBulkAction('0');
  };

  const unitOptions = () => {
    let unitData = [];
    unitsObj &&
      unitsObj.forEach(element => {
        unitData.push({
          ...element,
          label: element.Name,
          value: element.UnitId
        });
      });
    unitData.sort(compareValues('label'));
    return [
      {
        label: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
        value: '0'
      },
      ...unitData
    ];
  };

  const filteredResults = (filterType, value) => {
    let filteredList = tasksManager.taskList;

    let apifilterObj = {
      filter: null,
      filterStatus: null,
      filterAssignedUser: null,
      filterCreatedUser: null,
      filterIsServiceTask: null,
      filterUnitId: null
    };

    if (filterType === STATUS) {
      if (value !== '0') {
        apifilterObj.filterStatus = value;
        filteredList = filteredList.filter(function(item) {
          return item.Status === value;
        });
      }
    } else {
      if (filterStatus !== '0') {
        apifilterObj.filterStatus = filterStatus;
        filteredList = filteredList.filter(function(item) {
          return item.Status === filterStatus;
        });
      }
    }

    let myUserId = parseInt(userId);
    if (filterType === USER_FILTER) {
      if (value !== '0') {
        if (value === '1') {
          apifilterObj.filterAssignedUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.AssignedToUserId === myUserId;
          });
        } else if (value === '2') {
          apifilterObj.filterCreatedUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.CreatedByUserId === myUserId;
          });
        }
      }
    } else {
      if (filterUser !== '0') {
        if (value === '1') {
          apifilterObj.filterAssignedUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.AssignedToUserId === myUserId;
          });
        } else if (value === '2') {
          apifilterObj.filterCreatedUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.CreatedByUserId === myUserId;
          });
        }
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (filterType === SERVICE_TASK_FILTER) {
      if (value !== '0') {
        apifilterObj.filterIsServiceTask = value;
        filteredList = filteredList.filter(function(item) {
          return item.IsServiceTask === value;
        });
      }
    } else {
      if (filterServiceTask !== '0') {
        apifilterObj.filterIsServiceTask = filterServiceTask;
        filteredList = filteredList.filter(function(item) {
          return item.IsServiceTask === filterServiceTask;
        });
      }
    }

    if (filterType === UNIT_FILTER) {
      if (value !== '' && value !== '0') {
        apifilterObj.filterUnitId = value;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === value;
        });
      }
    } else {
      if (filterUnitId && filterUnitId !== '' && filterUnitId !== '0') {
        apifilterObj.filterUnitId = filterUnitId;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === filterUnitId;
        });
      }
    }

    if (tasksManager.taskListCount > tasksManager.taskList.length) {
      setPageFilter(apifilterObj);
    } else {
      setFilterData({ filteredResults: { filteredResults: filteredList } });
    }
  };

  const setLocalTask = currentState => {
    changeTask(currentState);
  };

  const onTaskSelectionChange = (taskId, isChecked) => {
    let filteredList = tasksManager.filteredResults;
    filteredList = filteredList.map(el => (el.TaskId == taskId ? { ...el, IsSelected: isChecked } : el));
    setFilterData({ filteredResults: { filteredResults: filteredList } });
  };

  const onChange = e => {
    setLocalTask({
      ...tasksManager,
      selectedTask: { ...tasksManager.selectedTask, [e.target.name]: e.target.value }
    });
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !tasksManager ||
      !tasksManager.selectedTask ||
      !tasksManager.selectedTask.Comment ||
      isRequired(tasksManager.selectedTask.Comment, 1)
    ) {
      formErrors.Comment = intl.formatMessage({
        id: 'eventAction.commentsRequired',
        defaultMessage: 'Required comment'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'TASKVIEW');

  return (
    <TasksAndChecklistsContentTemplate selectedPage="myTasks" userProfileWidgets={userProfileWidgets}>
      <div style={WidgetVisibility(userProfileWidgets, `TASKLIST`)}>
        <div className={s.pageContent}>
          <div className={s.tasks}>
            {tasksManager.isLoading && <LoadingSpinner />}
            <div className={s.contentWrapper}>
              <Row>
                <Col sm={6} lg={3}>
                  <SearchBar
                    searchHandler={searchHandler}
                    clearSearchInVisible={false}
                    initialText={searchFilter}
                    placeHolderTextId="tasksManagement.search"
                  />
                </Col>
                <Col sm={12} lg={9}>
                  <Row className={s.colActionButton}>
                    <Button
                      className={classNames(s.margin5, s.addbutton)}
                      onClick={addBtnClick}
                      style={WidgetVisibility(userProfileWidgets, `TASKADDNEW`)}
                    >
                      + <FormattedMessage id="tasksManagement.addNew" defaultMessage="ADD NEW" />
                    </Button>
                    <div style={WidgetVisibility(userProfileWidgets, `BULK_COMPLETE_TASKS`)} className={s.addbutton}>
                      <Dropdown
                        id="drpBulkActions"
                        dataArray={bulkActionOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="tasksManagement.bulkActions" defaultMessage="Bulk Actions" />,
                          customClassName: s.wsaDropdownBulkActions
                        }}
                        onDropdownChange={onBulkActionsChange}
                        selectedOption={bulkActionOptions().filter(option => option.value === bulkAction)}
                        disabled={false}
                      />
                      {localformErrors && localformErrors.TaskId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.TaskId}
                        </p>
                      )}
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <span className={s.textHeading}>
                    {' '}
                    <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpStatusId"
                    dataArray={statusOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="tasksManagement.showAll" defaultMessage="Show: All" />,
                      customClassName: ''
                    }}
                    onDropdownChange={onStatusChange}
                    selectedOption={statusOptions().filter(option => option.value === filterStatus)}
                    disabled={false}
                  />
                </Col>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpUserId"
                    dataArray={userOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="tasksManagement.showAll" defaultMessage="Show: All" />,
                      customClassName: ''
                    }}
                    onDropdownChange={onUserFilterChange}
                    selectedOption={userOptions().filter(option => option.value === filterUser)}
                    disabled={false}
                  />
                </Col>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpServiceTask"
                    dataArray={serviceTaskOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="tasksManagement.showAll" defaultMessage="Show: All" />,
                      customClassName: ''
                    }}
                    onDropdownChange={onServiceTaskFilterChange}
                    selectedOption={serviceTaskOptions().filter(option => option.value === filterServiceTask)}
                    disabled={false}
                  />
                </Col>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpUnitId"
                    dataArray={unitOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
                      customClassName: ''
                    }}
                    onDropdownChange={onUnitIdChange}
                    selectedOption={unitOptions().filter(option => option.value === filterUnitId)}
                    disabled={false}
                  />
                </Col>
              </Row>

              {isBulkComplete && (
                <Form>
                  <Row>
                    <Col xs={6} lg={3}>
                      <Form.Control
                        type="text"
                        name="Comment"
                        onChange={onChange}
                        value={tasksManager.selectedTask.Comment || ''}
                        placeholder={intl.formatMessage({
                          id: 'eventAction.addAComment',
                          defaultMessage: 'Add a Comment'
                        })}
                        className={`${s.formControl} ${localformErrors && localformErrors.Comment ? s.formControlError : ''}`}
                      />
                      {localformErrors && localformErrors.Comment && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Comment}
                        </p>
                      )}
                    </Col>
                    <Col xs={6} lg={3}>
                      <Row>
                        <Button variant="outline-secondary" className={`${s.controlWidth} mr-2`} onClick={bulkCompleteTasks}>
                          <FormattedMessage id="taskManagement.save" defaultMessage="Save" />
                        </Button>
                        <Button variant="outline-secondary" className={`${s.controlWidth}`} onClick={cancelClick}>
                          <FormattedMessage id="taskTemplateManagement.cancel" defaultMessage="Cancel" />
                        </Button>
                      </Row>
                    </Col>
                    <Col xs={12} lg={6}></Col>
                  </Row>
                </Form>
              )}
              <div className="">
                <Pagination
                  data={tasksObj}
                  pageSize={DEFAULT_PAGE_SIZE}
                  onPageChange={onPageChangeHandler}
                  startingPage={tasksManager.currentPage}
                >
                  <TaskListTable
                    ViewAllowed={isViewAllowed}
                    currentPage={tasksManager.currentPage}
                    currentTimezone={currentTimezone}
                    onSortOrder={onSortOrder}
                    asendingOrder={asendingOrder}
                    onViewBtnClick={viewBtnClick}
                    onTaskSelectionChange={onTaskSelectionChange}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TasksAndChecklistsContentTemplate>
  );
};

TaskList.defaultProps = {
  tasksManager: initialTasksState.tasks
};

TaskList.propTypes = {
  setFilterData: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  tasksManager: PropTypes.object.isRequired,
  saveTask: PropTypes.func.isRequired,
  changeTask: PropTypes.func.isRequired
};

export default injectIntl(TaskList);
