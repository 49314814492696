import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProcessFlow from './DeviceSchematic';
import { setSelectedUserProfileDetails } from '../../actions/UserProfileWidgets';
import { setActiveDeviceSequence } from '../../actions/devices';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateWidget: profileWidgets => setSelectedUserProfileDetails(profileWidgets),
      setCurrentDeviceSequence: (deviceSerialNumber, sequence) => setActiveDeviceSequence(deviceSerialNumber, sequence)
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ProcessFlow);
