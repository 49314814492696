import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './CalibrationEquation.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import EquationConstant from './EquationConstant';
import { WidgetVisibility } from '../../utils/widgetManager';

const EquationConstantTable = ({
  SelectedCalibrationEquationConstant,
  setAddHandler,
  setEditHandler,
  disableMode,
  disableModeHandler,
  selectedCalibrationEquationConstantId,
  selectedEquationConstantIdentifier,
  setCalibrationEquationConstantList,
  changeCalibrationEquationConstant,
  setCalibrationEquationCustomError,
  userProfileWidgets,
  UpdateAllowed,
  intl
}) => {
  let isEdit = false;

  if (selectedCalibrationEquationConstantId > 0 || selectedEquationConstantIdentifier.length > 0) {
    isEdit = true;
  }

  const addNewCosntantHandler = () => {
    setAddHandler();
    disableModeHandler(true);
  };

  const fetchDataHandler = (calibrationEquationConstantId, identifier) => {
    setEditHandler(calibrationEquationConstantId, identifier);
    disableModeHandler(true);
  };

  const deleteDataHandler = (equationConstantId, identifer) => {
    if (equationConstantId > -1) {
      const constantList = SelectedCalibrationEquationConstant.EquationConstantList || [];
      let constantEquation = constantList.filter(item => {
        if (item.GuidIdentifier === identifer) return item;
      });

      if (constantEquation.length > 0 && constantEquation[0].IsDeletable === 0) {
        const messageText = intl.formatMessage({
          id: 'calibrationEquation.constant.calibrationexists',
          defaultMessage: 'Unable to delete, this constant is still is use in calibrations'
        });
        setCalibrationEquationCustomError(messageText, true);
      } else {
        let saveData = constantEquation[0];
        saveData.isDeleted = true;
        saveEquationConstantHandler(saveData);
      }
    }
  };

  const saveEquationConstantHandler = equationConstant => {
    let listData = SelectedCalibrationEquationConstant.EquationConstantList;

    if (equationConstant.CalibrationEquationConstantId > 0) {
      const updatedData = listData.map(x => (x.GuidIdentifier === equationConstant.GuidIdentifier ? { ...x, ...equationConstant } : x));
      setCalibrationEquationConstantList({
        EquationConstantList: updatedData
      });
    } else {
      let hasItem = listData.filter(item => {
        if (item.GuidIdentifier === equationConstant.GuidIdentifier) return item;
      });

      if (hasItem.length > 0) {
        const updatedData = listData.map(x => (x.GuidIdentifier === equationConstant.GuidIdentifier ? { ...x, ...equationConstant } : x));
        setCalibrationEquationConstantList({
          EquationConstantList: updatedData
        });
      } else {
        listData.push(equationConstant);
        setCalibrationEquationConstantList({
          EquationConstantList: listData
        });
      }
    }
  };

  const getConstantListData = () => {
    const constantList = SelectedCalibrationEquationConstant.EquationConstantList || [];
    let constantEquation = constantList.filter(item => {
      if (item.isDeleted !== true) return item;
    });
    return constantEquation;
  };

  const cancelEquationConstantHandler = () => {
    setEditHandler(-1, '');
    disableModeHandler(false);
  };

  const ConstantContent = (
    <EquationConstant
      isEdit={isEdit}
      selectedEquationConstant={SelectedCalibrationEquationConstant.SelectedEquationConstant}
      changeCalibrationEquationConstant={changeCalibrationEquationConstant}
      saveEquationConstant={saveEquationConstantHandler}
      cancelEquationConstantHandler={cancelEquationConstantHandler}
    />
  );

  let editorMain = (
    <tr>
      <td colSpan="10">{ConstantContent}</td>
    </tr>
  );

  let newRowClass = SelectedCalibrationEquationConstant.showAddEditScreen && selectedCalibrationEquationConstantId === 0 ? s.newRow : '';

  return (
    <div className="">
      <Row className={s.breakRow}>
        <Col sm={6} lg={8}>
          <h4 className={s.subheading}>
            <FormattedMessage id="calibrationEquation.constant" defaultMessage="Calibration Constants" />
          </h4>
        </Col>
        <Col sm={6} lg={4}>
          <Button
            className={s.addbutton}
            style={WidgetVisibility(userProfileWidgets, 'CALIBRATIONEQUATIONCONSTADD')}
            onClick={addNewCosntantHandler}
            disabled={disableMode}
            data-unittest="addGlobalSettingButton"
          >
            + <FormattedMessage id="modelVersion.addNew" defaultMessage="ADD NEW" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Table variant className={s.innerTable}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="calibrationEquation.constant.name" defaultMessage="Name" />
                </th>
                <th>
                  <FormattedMessage id="calibrationEquation.constant.description" defaultMessage="Description" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {getConstantListData().map(n => {
                return (
                  <Fragment key={n.GuidIdentifier}>
                    <tr key={n.CalibrationEquationConstantId} data-unittest="EquationConstantList">
                      <td>{n.Name}</td>
                      <td>{n.Description}</td>
                      <td>
                        {UpdateAllowed && (
                          <div className={s.buttonContainer}>
                            <Button
                              variant="outline-secondary"
                              className={s.changeButton}
                              href="#"
                              onClick={fetchDataHandler.bind(this, n.CalibrationEquationConstantId, n.GuidIdentifier)}
                              disabled={disableMode}
                              data-unittest="changeData"
                              style={WidgetVisibility(userProfileWidgets, `CALIBRATIONEQUATIONCONSTUPDATE`)}
                            >
                              <FormattedMessage id="calibrationEquation.constant.change" defaultMessage="Change" />
                            </Button>
                            <Button
                              variant="outline-secondary"
                              className={s.removeButton}
                              href="#"
                              onClick={deleteDataHandler.bind(this, n.CalibrationEquationConstantId, n.GuidIdentifier)}
                              disabled={disableMode}
                              data-unittest="deleteData"
                              style={WidgetVisibility(userProfileWidgets, `CALIBRATIONEQUATIONCONSTDELETE`)}
                            >
                              <FormattedMessage id="calibrationEquation.constant.remove" defaultMessage="Remove" />
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                    {SelectedCalibrationEquationConstant.showAddEditScreen &&
                      selectedEquationConstantIdentifier === n.GuidIdentifier &&
                      editorMain}
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
          <div className={newRowClass}>
            {SelectedCalibrationEquationConstant.showAddEditScreen && selectedEquationConstantIdentifier === '' && ConstantContent}
          </div>
        </Col>
      </Row>
    </div>
  );
};

EquationConstantTable.defaultProps = {
  //CalibrationConstants: []
};

EquationConstantTable.propTypes = {
  setEditHandler: PropTypes.func.isRequired,
  selectedCalibrationEquationConstantId: PropTypes.number,
  SelectedCalibrationEquationConstant: PropTypes.object.isRequired,
  UpdateAllowed: PropTypes.bool,
  disableMode: PropTypes.bool,
  disableModeHandler: PropTypes.func.isRequired
};

export default injectIntl(EquationConstantTable);
