import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UpdatePassword from './UpdatePassword';
import { submitUpdatePassword } from '../../actions/user';
import { getIsLoading, getUserLoginError, getUsername, getUserSessionId } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    isLoading: getIsLoading(state),
    loginError: getUserLoginError(state),
    sessionId: getUserSessionId(state),
    username: getUsername(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirect: () => push('/'),
      submitPassword: newPassword => submitUpdatePassword(newPassword)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePassword);
