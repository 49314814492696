import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

import DeviceList from './DeviceList';
import {
  getSelectedView,
  getDevicesStatus,
  getDeviceIsLoading,
  getDevicesAlarms,
  getFlowSequenceGlobalSettings,
  getUserProfileWidget
} from '../../selectors/index';
import { fetchDevicesStatus, setSelectedView, getDeviceAlarmsThunk } from '../../actions/devices';
import { setSelectedUnit, setActiveDeviceTimezone } from '../../actions/devices';
import { describeUnitThunk } from '../../actions/unitManager';
import { changeTimezone } from '../../actions/timezone';

const mapStateToProps = state => {
  return {
    devicesStatus: getDevicesStatus(state),
    selectedView: getSelectedView(state),
    deviceAlarms: getDevicesAlarms(state),
    flowSequences: getFlowSequenceGlobalSettings(state),
    userProfileWidgets: getUserProfileWidget(state),
    isDeviceListLoading: getDeviceIsLoading(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirect: location => push(location),
      setSelectedUnit: unitSerialNumber => setSelectedUnit(unitSerialNumber),
      changeTimezone: timezone => changeTimezone(timezone),
      describeUnit: (unitSerialNumber, widgetCode) => describeUnitThunk(unitSerialNumber, widgetCode, true),
      resetDevicetimezone: () => setActiveDeviceTimezone(''),
      getDevicesStatus: (unitSerialNumbers, from, to) => fetchDevicesStatus(unitSerialNumbers, from, to, 'MYUNITSINDASHBOARD'),
      setSelectedUnitView: tabName => setSelectedView(tabName),
      getDeviceAlarms: (unitSerialNumbers, status) => getDeviceAlarmsThunk(unitSerialNumbers, status, 'MYUNITSINDASHBOARD')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeviceList);
