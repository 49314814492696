import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DashboardTasks from './DashboardTasks';
import { getTaskManager, getCurrentTimezone } from '../../selectors/index';
import { getTasksThunk, setEmptyTasksThunk } from '../../actions/tasks';

const mapStateToProps = state => {
  return {
    tasksManager: getTaskManager(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTasks: filterObj => getTasksThunk(filterObj, 'MYTASKSINDASHBOARD'),
      setEmptyTasks: () => setEmptyTasksThunk()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTasks);
