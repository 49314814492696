import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './Model.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Redirect, Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import Table from 'react-bootstrap/Table';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { compareValues } from '../../utils/';

const Model = ({
  saveModel,
  describeModel,
  getModelVersions,
  setDuplicateChange,
  modelManager,
  changeModel,
  modelCleanUp,
  userProfileWidgets,
  intl
}) => {
  let IsEdit = false;
  let IsDuplicate = false;
  let IsRedirect = false;
  let heading = '';
  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const isDuplicateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODEL_DUPLICATE');
  const organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;

  let { ModelId, Duplicate } = useParams();

  let widgetCode = 'MODEL_ADD';
  if (ModelId) {
    IsEdit = true;
    if (Duplicate) {
      widgetCode = 'MODEL_DUPLICATE';
      IsDuplicate = true;
      heading = intl.formatMessage({ id: 'model.duplicateModel', defaultMessage: 'Duplicate' });
    } else {
      widgetCode = 'MODEL_UPDATE';
      heading = intl.formatMessage({ id: 'model.editModel', defaultMessage: 'Edit' });
    }
  } else {
    IsEdit = false;
  }

  useEffect(() => {
    if (ModelId) {
      if (Duplicate) {
        describeModel(ModelId, Duplicate, widgetCode);
      } else {
        describeModel(ModelId, null, widgetCode);
      }
    }
  }, [describeModel, ModelId, Duplicate, widgetCode]);

  useEffect(() => {
    if (ModelId && isDuplicateAllowed && IsDuplicate) {
      getModelVersions(ModelId, 0, 1000, null, organisationId, true);
    } else if (!IsDuplicate) {
      modelCleanUp({
        ...initialModelState.modelManager
      });
    }
  }, [getModelVersions, ModelId, isDuplicateAllowed, IsDuplicate]);

  //clean up
  useEffect(() => {
    return () => {
      modelCleanUp({
        ...initialModelState.modelManager
      });
    };
  }, []);

  // Update redux store
  const setLocalModel = currentState => {
    changeModel(currentState);
  };
  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = modelManager.selectedModel;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

    if (!IsEdit) {
      saveData.ModelId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    if (IsDuplicate) {
      saveData.RefModelId = ModelId;
      saveData.IsDuplicate = IsDuplicate;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model
    saveModel(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !modelManager ||
      !modelManager.selectedModel ||
      !modelManager.selectedModel.Name ||
      isRequired(modelManager.selectedModel.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'model.nameMandatory',
        defaultMessage: 'Model name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onDuplicateChange = e => {
    setDuplicateChange({
      ...modelManager.selectedModel.DuplicateModel,
      [e.target.name]: e.target.checked
    });
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalModel({
        ...modelManager,
        selectedModel: { ...modelManager.selectedModel, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalModel({
        ...modelManager,
        selectedModel: { ...modelManager.selectedModel, [e.target.name]: e.target.value }
      });
    }
  };

  const onDropdownChange = e => {
    setLocalModel({
      ...modelManager,
      selectedModel: { ...modelManager.selectedModel, SchematicDiagramName: e.value }
    });
  };

  const schematicDiagramOptions = () => {
    let schematicDiagramOptions = [
      {
        label: 'Schematic diagram Ver 1',
        value: 'DeviceSchematicSvg'
      },
      {
        label: 'Schematic diagram Ver 2',
        value: 'DeviceSchematicSvg1'
      },
      {
        label: 'Alpha 2 Schematic diagram Ver 3',
        value: 'Alpha2DeviceSchematicSvg3'
      },
      {
        label: 'Alpha 2',
        value: 'alpha2Svg'
      },
      {
        label: 'PLCNext BASIC',
        value: 'PLCNextSvg'
      },
      {
        label: 'PLCNext DEMO',
        value: 'PLCNextDemoSvg'
      }
    ];

    return schematicDiagramOptions.sort(compareValues('label'));
  };

  let messageId = (modelManager && modelManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const duplicateModelName =
    (isDuplicateAllowed && IsDuplicate && modelManager.selectedModel ? modelManager.selectedModel.ModelName : '') || '';

  return (
    <div className={s.model}>
      {modelManager.isLoading && <LoadingSpinner />}
      {modelManager.isOpSuccessful && !modelManager.showBanner ? <Redirect to="/admin-controls/model-list" /> : ''}
      {IsRedirect ? <Redirect to="/admin-controls/model-list" /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={modelManager.showBanner}
        status={modelManager.isOpSuccessful}
        successText={messageText}
      />

      <AdminControlsContentTemplate selectedPage="manageModels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.modelHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="model.backToModels" defaultMessage="BACK TO MODELS" />
            </GoBack>
            {isDuplicateAllowed && IsDuplicate && (
              <h4>
                {' '}
                <FormattedMessage id="model.duplicate" defaultMessage="Duplicate" /> {' - '} {duplicateModelName}
              </h4>
            )}
          </div>
          <div className={s.modelContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formModelName">
                    <Form.Label>
                      <FormattedMessage id="model.modelName" defaultMessage="Model Name" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={modelManager.selectedModel.Name}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formModelName">
                    <Form.Label>
                      <FormattedMessage id="model.schematicDiagram" defaultMessage="Schematic Diagram" />
                    </Form.Label>

                    <Dropdown
                      id="ddlComponentType"
                      dataArray={schematicDiagramOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                      }}
                      onDropdownChange={onDropdownChange}
                      selectedOption={schematicDiagramOptions().filter(
                        option => option.value === modelManager.selectedModel.SchematicDiagramName
                      )}
                      data-unittest="ddlComponentType"
                      disabled={IsEdit && !IsDuplicate}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formModelDescription">
                    <Form.Label>
                      <FormattedMessage id="model.description" defaultMessage="Model Description" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'model.enterDescription',
                        defaultMessage: 'Enter description'
                      })}
                      value={modelManager.selectedModel.Description}
                      className={`${s.textArea} ${localformErrors && localformErrors.Description ? s.formControlError : ''}`}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formActiveModel">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsActive',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'model.active',
                            defaultMessage: 'Model is active'
                          }),
                          isChecked: modelManager.selectedModel.IsActive
                        }
                      ]}
                      onSelectionChange={onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>

          {isDuplicateAllowed && IsDuplicate && (
            <div className={s.modelContent}>
              <Row>
                <Col className={s.subHeading}>
                  <h4>
                    <FormattedMessage id="model.duplicateOptions" defaultMessage="Duplicate options" />
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formComponentPositionLinks">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'ComponentPositionLinks',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'model.duplicateComponentPositionLinks',
                            defaultMessage: 'Component Position Links'
                          }),
                          isChecked: modelManager.selectedModel.DuplicateModel.ComponentPositionLinks || false
                        }
                      ]}
                      onSelectionChange={onDuplicateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formTelemetryLinks">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'TelemetryLinks',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'model.duplicateTelemetryLinks',
                            defaultMessage: 'Telemetry Links'
                          }),
                          isChecked: modelManager.selectedModel.DuplicateModel.TelemetryLinks || false
                        }
                      ]}
                      onSelectionChange={onDuplicateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className={s.modelVersionInfo}>
                  <p>
                    <FormattedMessage
                      id="model.duplicateModelVersionInfo"
                      defaultMessage="Please select the model version(s) that require to be duplicated"
                    />
                  </p>
                </Col>
              </Row>
              <ModelVersionListTable modelManager={modelManager} setDuplicateChange={setDuplicateChange} intl={intl} />
            </div>
          )}
          <Row>
            <Col>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChanges)}
                onClick={submitForm}
                noValidate
                data-unittest="saveData"
              >
                <FormattedMessage id="model.saveModel" defaultMessage="SAVE CHANGES" />
              </Button>
              <Link to="/admin-controls/model-list">
                <Button variant="outline-secondary" className={s.btnCancel}>
                  <FormattedMessage id="model.cancelModel" defaultMessage="CANCEL" />
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

Model.defaultProps = {
  modelManager: {
    ...initialModelState.modelManager
  }
};

Model.propTypes = {
  saveModel: PropTypes.func.isRequired,
  modelManager: PropTypes.object.isRequired,
  describeModel: PropTypes.func.isRequired,
  changeModel: PropTypes.func.isRequired,
  userProfileWidgets: PropTypes.object.isRequired
};

export default injectIntl(Model);

const ModelVersionListTable = ({ modelManager, setDuplicateChange }) => {
  let versionData = modelManager.selectedModel.DuplicateModel.ModelVersions || [];
  const onDuplicateChange = updatedObject => {
    versionData.forEach((element, index) => {
      if (element.ModelVersionId === updatedObject.ModelVersionId) {
        versionData[index] = updatedObject;
      }
    });

    setDuplicateChange({
      ...modelManager.selectedModel.DuplicateModel,
      ModelVersions: versionData
    });
  };

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="model.versionSelected" defaultMessage="Select" />
            </th>
            <th>
              <FormattedMessage id="modelVersion.version" defaultMessage="Version" />
            </th>
            <th>
              <FormattedMessage id="modelVersion.description" defaultMessage="Description" />
            </th>
            <th>
              <FormattedMessage id="model.duplicateGobalSettings" defaultMessage="Duplicate Gobal Settings" />
            </th>
            <th>
              <FormattedMessage id="model.duplicateThresholds" defaultMessage="Duplicate Tresholds" />
            </th>
            <th>
              <FormattedMessage id="model.duplicateUnitGlobalSettings" defaultMessage="Duplicate Unit Global Settings" />
            </th>
            <th>
              <FormattedMessage id="model.duplicateSeqeunceVersion" defaultMessage="Duplicate Sequence Versions" />
            </th>
          </tr>
        </thead>
        <tbody>
          {versionData.map(n => {
            return <TableRow key={n.ModelVersionId} n={n} onDuplicateModelVersionChange={onDuplicateChange} />;
          })}
        </tbody>
      </Table>
    </div>
  );
};

const TableRow = ({ n, onDuplicateModelVersionChange }) => {
  const onLocalChange = (e, type) => {
    let selectedModelVersion = {};

    if (type == 'SequenceVersions') {
      selectedModelVersion = { ...n, [type]: e.value };
    } else {
      selectedModelVersion = { ...n, [e.target.name]: e.target.checked };
      if (e.target.name === 'IsSelected' && !e.target.checked) {
        selectedModelVersion.GlobalSettings = false;
        selectedModelVersion.Thresholds = false;
        selectedModelVersion.UnitGlobalSettings = false;
        selectedModelVersion.SequenceVersions = 0;
      }
    }

    onDuplicateModelVersionChange(selectedModelVersion);
  };

  const sequenceOptions = () => {
    let dataOption = [];

    dataOption.push({
      label: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
      value: 0
    });

    n.SequenceFiles.forEach(element => {
      dataOption.push({
        label: element.Name,
        value: element.SequenceVersionId
      });
    });

    return dataOption.sort(compareValues('label'));
  };

  //onDuplicateModelVersionChange

  return (
    <tr>
      <td>
        <Checkbox
          key={uuidv4()}
          dataArray={[
            {
              SKEY: 'IsSelected',
              target: { type: 'checkbox' },
              label: '',
              isChecked: n.IsSelected
            }
          ]}
          onSelectionChange={onLocalChange}
        />
      </td>
      <td>{n.Name || ''}</td>
      <td>{n.Description || ''}</td>
      <td>
        <Checkbox
          key={uuidv4()}
          containerClass={s.alignMiddle}
          dataArray={[
            {
              SKEY: 'GlobalSettings',
              target: { type: 'checkbox' },
              label: '',
              isChecked: n.GlobalSettings
            }
          ]}
          disable={!n.IsSelected}
          onSelectionChange={onLocalChange}
        />
      </td>
      <td>
        <Checkbox
          key={uuidv4()}
          containerClass={s.alignMiddle}
          dataArray={[
            {
              SKEY: 'Thresholds',
              target: { type: 'checkbox' },
              label: '',
              isChecked: n.Thresholds
            }
          ]}
          disable={!n.IsSelected}
          onSelectionChange={onLocalChange}
        />
      </td>
      <td>
        <Checkbox
          key={uuidv4()}
          containerClass={s.alignMiddle}
          dataArray={[
            {
              SKEY: 'UnitGlobalSettings',
              target: { type: 'checkbox' },
              label: '',
              isChecked: n.UnitGlobalSettings
            }
          ]}
          disable={!n.IsSelected}
          onSelectionChange={onLocalChange}
        />
      </td>
      <td>
        <Dropdown
          id="ddlSequenceVersions"
          dataArray={sequenceOptions()}
          controlData={{
            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
          }}
          onDropdownChange={e => onLocalChange(e, 'SequenceVersions')}
          selectedOption={sequenceOptions().filter(option => option.value === n.SequenceVersions)}
          disabled={!n.IsSelected}
        />
      </td>
    </tr>
  );
};
