import React from 'react';
import PropTypes from 'prop-types';
import s from './ReleaseNoteList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import Button from 'react-bootstrap/Button';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';

const ReleaseNoteListTable = ({
  paginatedData,
  onRemoveClick,
  onReleaseNoteSelection,
  UpdateAllowed,
  onSortOrder,
  asendingOrder,
  currentTimezone
}) => {
  const deleteDataHandler = (id, guid) => {
    onRemoveClick(id);
  };

  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="releaseNotes.name" defaultMessage="Name" /> <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="releaseNotes.version" defaultMessage="Version" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="releaseNotes.published" defaultMessage="Published" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="releaseNotes.dateCreated" defaultMessage="Date created" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr className={n.IsPublished ? s.resolved : s.new} key={n.ReleaseNoteId} data-unittest="releaseNoteData">
                <td>{n.Name || ''}</td>
                <td className="d-none d-md-table-cell">{n.VersionNo || ''}</td>
                <td className="d-none d-md-table-cell">
                  {n.IsPublished ? (
                    <FormattedMessage id="common.yes" defaultMessage="Yes" />
                  ) : (
                    <FormattedMessage id="common.no" defaultMessage="No" />
                  )}
                </td>
                <td className="d-none d-md-table-cell">
                  {n.CreatedDateTime
                    ? moment(parseInt(n.CreatedDateTime * 1000))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY z')
                    : '-'}
                </td>

                <td className="d-none d-md-table-cell">
                  {UpdateAllowed && (
                    <div className={s.buttonContainer}>
                      <Button
                        variant="outline-secondary"
                        className={s.removeButton}
                        href="#"
                        onClick={deleteDataHandler.bind(this, n.ReleaseNoteId, n.GuidIdentifier)}
                        data-unittest="deleteData"
                      >
                        <FormattedMessage id="releaseNotes.remove" defaultMessage="Remove" />
                      </Button>
                      <Button
                        variant="outline-secondary"
                        className={s.changeButton}
                        href="#"
                        onClick={onReleaseNoteSelection.bind(this, n.ReleaseNoteId, n.GuidIdentifier)}
                        data-unittest="changeData"
                      >
                        <FormattedMessage id="releaseNotes.change" defaultMessage="Change" />
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ReleaseNoteListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

ReleaseNoteListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(ReleaseNoteListTable);
