import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './SchedulerList.module.scss';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialCommandSchedulerState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import SchedulerListTable from '../SchedulerList/SchedulerListTable';
import { WidgetVisibility } from '../../utils/widgetManager';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import { logEntry, compareValues } from '../../utils';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { getCommandScheduler, getUserProfileWidget, getCurrentTimezone, getUnitManager } from '../../selectors/index';
import { getUnitCommandScheduler, setCurrentPage, saveCommandScheduler } from '../../actions/commandScheduler';
import { SCHEDULE_MANAGEMENT, END_SCHEDULE } from '../../constants/logs';

const SchedulerList = ({ intl }) => {
  const dispatch = useDispatch();
  const schedulerManager = useSelector(state => getCommandScheduler(state));
  const unitManager = useSelector(state => getUnitManager(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  let filter = schedulerManager.filter || '';
  let offset = schedulerManager.offset || 0;
  let limit = schedulerManager.limit || 1000;

  const [active, setActive] = useState(false);
  const [commandId, setCommandId] = useState(0);
  const [asendingOrder, setAsendingOrder] = useState(false);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let { unitSerialNumber } = useParams();
  const unitId = (unitManager.selectedUnit && unitManager.selectedUnit.UnitId) || 0;
  const isUnitTaggedOut = (unitManager.selectedUnit && unitManager.selectedUnit.IsTaggedOut) || 0;

  useEffect(() => {
    let filterObj = {
      filter: filter,
      offset: offset,
      limit: limit
    };
    dispatch(getUnitCommandScheduler(unitSerialNumber, unitId, filterObj, 'SCHEDULE_LIST'));
  }, [getUnitCommandScheduler, unitId, offset, limit, filter]);

  const onPageChangeHandler = currentPage => {
    dispatch(setCurrentPage(currentPage));
  };

  const handleClose = () => setActive(false);

  const handleCommand = commandId => {
    setActive(true);
    setCommandId(commandId);
  };

  const handleConfirm = () => {
    onEndSchedule(commandId);
    handleClose();
  };

  const onEndSchedule = commandSchedulerId => {
    let saveData = schedulerManager.selectedCommandSchedule;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.CommandSchedulerId = commandSchedulerId;
    saveData.unitId = unitId;
    saveData.Offset = offset;
    saveData.Limit = limit;

    var d = new Date();
    saveData.EndDateTime = moment(d).unix();

    let actionName = END_SCHEDULE;
    let log = logEntry(SCHEDULE_MANAGEMENT, actionName, saveData);
    dispatch(saveCommandScheduler(saveData, log, unitSerialNumber, 'SCHEDULE_END'));
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  let schedulesList = Object.values(schedulerManager.commandSchedules);
  if (asendingOrder) {
    schedulesList = schedulesList.sort(compareValues('StartDateTime'));
  } else {
    schedulesList = schedulesList.sort(compareValues('StartDateTime', 'desc'));
  }

  const dialogTitle = intl.formatMessage({
    id: 'schedule.confirmEndSchedule',
    defaultMessage: 'Are you sure you want to end the schedule?'
  });

  let messageId = (schedulerManager && schedulerManager.displayMessageCode) || '';
  let messageText = '';
  messageText = messageId !== '' ? intl.formatMessage({ id: messageId, defaultMessage: messageId }) : '';

  return (
    <div className={s.scheduler}>
      {schedulerManager.isLoading && <LoadingSpinner />}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <Banner
        failureText={messageText}
        showBanner={schedulerManager.showBanner}
        status={schedulerManager.isOpSuccessful}
        successText={messageText}
      />
      <ContentTemplate
        selectedPage="unitSchedule"
        userProfileWidgets={userProfileWidgets}
        widgetCode={'SCHEDULE_LIST'}
        tagoutRequired={true}
      >
        <div className={s.contentWrapper}>
          <Row>
            <Col sm={6} lg={8}>
              <div className={s.schedulerHeader}>
                <div className={s.subHeading}>
                  <FormattedMessage id="schedule.schedules" defaultMessage="Schedules" />
                  <span data-unittest="schedulesDataCount" className={s.circle}>
                    {schedulerManager.commandSchedules.length}
                  </span>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              {isUnitTaggedOut === 0 ? (
                <Link to={`/unit/schedule/${unitSerialNumber}/add/${unitId}/`}>
                  <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `SCHEDULE_ADD`)}>
                    + <FormattedMessage id="schedule.addNew" defaultMessage="+ ADD NEW Schedule" />
                  </Button>
                </Link>
              ) : null}
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={schedulesList}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={schedulerManager.currentPage}
            >
              <SchedulerListTable
                userProfileWidgets={userProfileWidgets}
                currentTimezone={currentTimezone}
                isSummary={false}
                endSchedulehandler={handleCommand}
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
              />
            </Pagination>
          </div>
        </div>
      </ContentTemplate>
    </div>
  );
};

SchedulerList.defaultProps = {
  ...initialCommandSchedulerState
};

export default injectIntl(SchedulerList);
