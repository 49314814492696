import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './NotificationTypeList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const NotificationTypeListTable = ({ NotificationTypesData, ChangeNotificationType, UpdateAllowed, intl }) => {
  return (
    <div>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="common.description" defaultMessage="Description" />
            </th>
            {UpdateAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {NotificationTypesData.map(n => {
            return (
              <Fragment key={n.NotificationTypeId}>
                <tr data-unittest="notificationTypeData">
                  <td>{n.Name}</td>
                  <td>{n.Description}</td>
                  {UpdateAllowed && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={() => {
                            ChangeNotificationType(n.NotificationTypeId);
                          }}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="common.manage" defaultMessage="MANAGE" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

NotificationTypeListTable.defaultProps = {
  NotificationTypesData: []
};

NotificationTypeListTable.propTypes = {
  NotificationTypesData: PropTypes.array.isRequired,
  ChangeNotificationType: PropTypes.func.isRequired
};

export default injectIntl(NotificationTypeListTable);
