import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TasksAndChecklistsTabs from './TasksAndChecklistsTabs';

import s from './TasksAndChecklistsContentTemplate.module.scss';

const TasksAndChecklistsContentTemplate = ({ children, selectedPage, userProfileWidgets }) => (
  <Fragment>
    <div className={s.fullWidth}>
      <div className={s.fullWidthWrapper}>
        <h1 className={s.tabHeader}>
          {<FormattedMessage id="tasksAndChecklistsManagement.tasksChecklists" defaultMessage="Tasks & Checklistss" />}
        </h1>
        {<TasksAndChecklistsTabs selectedPage={selectedPage} userProfileWidgets={userProfileWidgets} />}
      </div>
    </div>

    <div className={s.pageContent}>{children}</div>
  </Fragment>
);

TasksAndChecklistsContentTemplate.defaultProps = {
  selectedPage: ''
};

TasksAndChecklistsContentTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  selectedPage: PropTypes.string
};

export default TasksAndChecklistsContentTemplate;
