import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TaskDetail from './TaskDetail';
import { getTaskManager, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import { describeTasksThunk, saveTasksThunk, setTasksChangeThunk, saveTasksCommentThunk } from '../../actions/tasks';

const mapStateToProps = state => {
  return {
    taskManager: getTaskManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeTask: taskId => describeTasksThunk(taskId, 'TASKVIEW'),
      saveTask: (task, widgetCode) => saveTasksThunk(task, widgetCode),
      changeTask: task => setTasksChangeThunk(task),
      saveTaskComment: task => saveTasksCommentThunk(task, 'TASKEDIT')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetail);
