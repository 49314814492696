import React from 'react';
import PropTypes from 'prop-types';
import s from './CalibrationEquationList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const CalibrationEquationListTable = ({ paginatedData, currentPage, UpdateAllowed, currentTimezone }) => {
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="calibrationEquation.name" defaultMessage="Name" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="calibrationEquation.formula" defaultMessage="Formula" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="calibrationEquation.description" defaultMessage="Description" />
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.CalibrationEquationId} data-unittest="calibrationEquationData">
                <td>{n.Name || ''}</td>
                <td>{n.Formula || ''}</td>
                <td className="d-none d-md-table-cell">{n.Description || ''}</td>
                <td>
                  {UpdateAllowed ? (
                    <Link to={`/admin-controls/calibration-equation/${n.CalibrationEquationId}`}>
                      <Button variant="outline-secondary" className={s.changeButton}>
                        <FormattedMessage id="calibrationEquation.change" defaultMessage="CHANGE" />
                      </Button>
                    </Link>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

CalibrationEquationListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

CalibrationEquationListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(CalibrationEquationListTable);
