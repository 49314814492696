import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './UnitGlobalSettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelVersionState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';

const UnitGlobalSettings = ({
  UnitGlobalSettingId,
  ModelVersionId,
  saveUnitGlobalSetting,
  unitGlobalSetting,
  changeUnitGlobalSetting,
  cancelHandler,
  setEditHandler,
  intl
}) => {
  const regexDefaultValue = /^[a-zA-Z0-9  .]+$/;
  const regexCode = /^[a-zA-Z0-9_]+$/;
  let IsEdit = false;
  const [localformErrors, setFormErrors] = useState({});

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  if (UnitGlobalSettingId) {
    IsEdit = true;
  }

  // Update redux store
  const setLocalUnitGlobalSetting = currentState => {
    changeUnitGlobalSetting(currentState);
  };

  let widgetCode = IsEdit ? 'UNITGLOBALSETTINGSEDIT' : 'UNITGLOBALSETTINGSADD';

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = unitGlobalSetting.SelectedUnitGlobalSetting;
    saveData.MarkAsDeleted = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.UnitGlobalSettingId = 0;
      saveData.ModelVersionId = ModelVersionId;
      saveData.GuidIdentifier = uuidv4();
    }

    saveUnitGlobalSetting(saveData, widgetCode, false);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;
    if (
      !unitGlobalSetting ||
      !unitGlobalSetting.SelectedUnitGlobalSetting ||
      isRequired(unitGlobalSetting.SelectedUnitGlobalSetting.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'unitGlobalSettings.nameRequired',
        defaultMessage: 'Name is required'
      });
      isValid = false;
    }

    if (
      !unitGlobalSetting ||
      !unitGlobalSetting.SelectedUnitGlobalSetting ||
      isRequired(unitGlobalSetting.SelectedUnitGlobalSetting.Code, 1)
    ) {
      formErrors.Code = intl.formatMessage({
        id: 'unitGlobalSettings.codeMandatory',
        defaultMessage: 'Code is a mandatory field'
      });
      isValid = false;
    }

    if (
      !unitGlobalSetting ||
      !unitGlobalSetting.SelectedUnitGlobalSetting ||
      isRequired(unitGlobalSetting.SelectedUnitGlobalSetting.TranslationCode, 1)
    ) {
      formErrors.TranslationCode = intl.formatMessage({
        id: 'unitGlobalSettings.translationCodeMandatory',
        defaultMessage: 'Translation code is a mandatory field'
      });
      isValid = false;
    }

    if (
      !unitGlobalSetting ||
      !unitGlobalSetting.SelectedUnitGlobalSetting ||
      isRequired(unitGlobalSetting.SelectedUnitGlobalSetting.DefaultValue, 1)
    ) {
      formErrors.DefaultValue = intl.formatMessage({
        id: 'unitGlobalSettings.defaultValueMandatory',
        defaultMessage: 'Default value is a mandatory field'
      });
      isValid = false;
    }

    if (unitGlobalSetting.SelectedUnitGlobalSetting) {
      let defValue = unitGlobalSetting.SelectedUnitGlobalSetting.DefaultValue;

      if (defValue !== '' && !isNaN(defValue)) {
        let minValue = unitGlobalSetting.SelectedUnitGlobalSetting.MinimumValue;
        let maxValue = unitGlobalSetting.SelectedUnitGlobalSetting.MaximumValue;
        if (minValue !== '' && maxValue !== '') {
          if (parseFloat(maxValue) < parseFloat(minValue)) {
            formErrors.DefaultValue = intl.formatMessage({
              id: 'unitGlobalSettings.maxValuemustbeGrater',
              defaultMessage: 'Max value must be grater than Min value'
            });
            isValid = false;
          }
        }

        if (
          (minValue !== '' && parseFloat(defValue) < parseFloat(minValue)) ||
          (maxValue !== '' && parseFloat(defValue) > parseFloat(maxValue))
        ) {
          formErrors.DefaultValue = intl.formatMessage({
            id: 'unitGlobalSettings.defaultValueWithinRange',
            defaultMessage: 'Default value must be within Min and Max range'
          });
          isValid = false;
        }
      }
    }

    setFormErrors(formErrors);
    return isValid;
  };

  //clean up
  useEffect(() => {
    return () => {
      setEditHandler(-1);
    };
  }, []);

  //on control value change
  const onChange = e => {
    let minMaxControldisabled = false;
    if (e.target.name == 'DefaultValue' && e.target.value != '') {
      if (!regexDefaultValue.test(e.target.value)) return;
    }
    let MinimumValue = unitGlobalSetting.SelectedUnitGlobalSetting.MinimumValue;
    let MaximumValue = unitGlobalSetting.SelectedUnitGlobalSetting.MaximumValue;
    if (e.target.name == 'DefaultValue') {
      if (isNaN(e.target.value)) {
        minMaxControldisabled = true;
        MinimumValue = '';
        MaximumValue = '';
      } else {
        minMaxControldisabled = false;
      }
    }
    if (e.target.name == 'Code' && e.target.value != '') {
      if (!regexCode.test(e.target.value)) return;
    }
    setLocalUnitGlobalSetting({
      ...unitGlobalSetting,
      SelectedUnitGlobalSetting: {
        ...unitGlobalSetting.SelectedUnitGlobalSetting,
        MinimumValue: MinimumValue,
        MaximumValue: MaximumValue,
        IsMinMaxDisabled: minMaxControldisabled,
        [e.target.name]: e.target.value
      }
    });
  };

  return (
    <div className={s.unitGlobalSettings}>
      <div className={s.unitGlobalSettingsContent}>
        <Form>
          <div className={s.topRow}>
            <Row>
              <Col lg={3}>
                <Form.Group controlId="formUnitGlobalSettingName">
                  <Form.Label>
                    <FormattedMessage id="unitGlobalSettings.name" defaultMessage="Name" />
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="Name"
                    onChange={onChange}
                    placeholder={intl.formatMessage({
                      id: 'unitGlobalSettings.name',
                      defaultMessage: 'Name'
                    })}
                    value={unitGlobalSetting.SelectedUnitGlobalSetting.Name}
                    className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                  />
                  {localformErrors && localformErrors.Name && (
                    <p role="alert" className={s.error}>
                      {localformErrors.Name}
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group controlId="formGlobalSettingsCode">
                  <Form.Label>
                    <FormattedMessage id="globalSettingsManagement.code" defaultMessage="Code" />
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="Code"
                    onChange={onChange}
                    value={unitGlobalSetting.SelectedUnitGlobalSetting.Code}
                    className={`${s.formControl} ${localformErrors && localformErrors.Code ? s.formControlError : ''}`}
                    placeholder={intl.formatMessage({
                      id: 'unitGlobalSettings.code',
                      defaultMessage: 'Code'
                    })}
                  />
                  {localformErrors && localformErrors.Code && (
                    <p role="alert" className={s.error}>
                      {localformErrors.Code}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="formUnitGlobalSettingTranslationcode">
                  <Form.Label>
                    <FormattedMessage id="unitGlobalSettings.translationCode" defaultMessage="Translation Code" />
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="TranslationCode"
                    onChange={onChange}
                    value={unitGlobalSetting.SelectedUnitGlobalSetting.TranslationCode}
                    className={`${s.formControl} ${localformErrors && localformErrors.TranslationCode ? s.formControlError : ''}`}
                    placeholder={intl.formatMessage({
                      id: 'unitGlobalSettings.translationCode',
                      defaultMessage: 'Translation Code'
                    })}
                  />
                  {localformErrors && localformErrors.TranslationCode && (
                    <p role="alert" className={s.error}>
                      {localformErrors.TranslationCode}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="formUnitGlobalSettingsUoM">
                  <Form.Label>
                    <FormattedMessage id="unitGlobalSettings.uOM" defaultMessage="Unit of Measure" />
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="UnitOfMeasure"
                    onChange={onChange}
                    value={unitGlobalSetting.SelectedUnitGlobalSetting.UnitOfMeasure}
                    className={`${s.formControl}`}
                    placeholder={intl.formatMessage({
                      id: 'unitGlobalSettings.uOM',
                      defaultMessage: 'Unit of Measure'
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Group controlId="formUnitGlobalSettingsDescription">
                  <Form.Label>
                    <FormattedMessage id="unitGlobalSettings.description" defaultMessage="Description" />
                  </Form.Label>

                  <Form.Control
                    className={s.txtArea}
                    as="textarea"
                    rows="3"
                    name="Description"
                    onChange={onChange}
                    placeholder={intl.formatMessage({
                      id: 'unitGlobalSettings.description',
                      defaultMessage: 'Description'
                    })}
                    value={unitGlobalSetting.SelectedUnitGlobalSetting.Description}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Form.Group controlId="formGlobalSettingsDefaultValue">
                  <Form.Label>
                    <FormattedMessage id="unitGlobalSettings.defaultValue" defaultMessage="Default value" />
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="DefaultValue"
                    onChange={onChange}
                    value={unitGlobalSetting.SelectedUnitGlobalSetting.DefaultValue}
                    className={`${s.formControl} ${localformErrors && localformErrors.DefaultValue ? s.formControlError : ''}`}
                    placeholder={intl.formatMessage({
                      id: 'unitGlobalSettings.defaultValue',
                      defaultMessage: 'Default value'
                    })}
                  />

                  {localformErrors && localformErrors.DefaultValue && (
                    <p role="alert" className={`${s.error}`}>
                      {localformErrors.DefaultValue}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="formUnitGlobalSettingsMinValue">
                  <Form.Label>
                    <FormattedMessage id="unitGlobalSettings.minValue" defaultMessage="Min value" />
                  </Form.Label>
                  <InputNumber
                    name="MinimumValue"
                    step=".1"
                    onInputChange={onChange}
                    value={unitGlobalSetting.SelectedUnitGlobalSetting.MinimumValue}
                    id="formGlobalSettingMinimumValue"
                    disabled={unitGlobalSetting.SelectedUnitGlobalSetting.IsMinMaxDisabled || false}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="formUnitGlobalSettingsMaxValue">
                  <Form.Label>
                    <FormattedMessage id="unitGlobalSettings.maxValue" defaultMessage="Max value" />
                  </Form.Label>
                  <InputNumber
                    name="MaximumValue"
                    step=".1"
                    onInputChange={onChange}
                    value={unitGlobalSetting.SelectedUnitGlobalSetting.MaximumValue}
                    id="formUnitGlobalSettingMaximumValue"
                    customClassName={`${localformErrors && localformErrors.MaximumValue ? s.formControlError : ''}`}
                    disabled={unitGlobalSetting.SelectedUnitGlobalSetting.IsMinMaxDisabled || false}
                  />
                  {localformErrors && localformErrors.MaximumValue && (
                    <p role="alert" className={`${s.error} d-block d-lg-none `}>
                      {localformErrors.MaximumValue}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={UnitGlobalSettingId ? 8 : 4} xs={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="unitGlobalSettings.save" defaultMessage="SAVE" />
                </Button>
                <Button
                  variant="outline-secondary"
                  className={classNames(s.btnCancel)}
                  onClick={cancelHandler}
                  noValidate
                  data-unittest="saveCancel"
                >
                  <FormattedMessage id="unitGlobalSettings.cancel" defaultMessage="CANCEL" />
                </Button>
              </Col>
              <Col lg={UnitGlobalSettingId ? 7 : 5}></Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

// UnitGlobalSettings.defaultProps = {
//   unitGlobalSetting: {
//     ...initialModelVersionState.modelVersionManager.selectedModelVersion.UnitGlobalSettings
//   }
// };

UnitGlobalSettings.propTypes = {
  saveUnitGlobalSetting: PropTypes.func.isRequired,
  changeUnitGlobalSetting: PropTypes.func.isRequired,
  unitGlobalSetting: PropTypes.object.isRequired,
  cancelHandler: PropTypes.func.isRequired
};

export default injectIntl(UnitGlobalSettings);
