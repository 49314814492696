import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DataTrend from './DataTrend';
import {
  getSelectedDeviceDefault,
  getDeviceCharts,
  getSelectedUnitComponentPositions,
  getUnitManager,
  getUserProfileWidget
} from '../../selectors/index';
import { addChart } from '../../actions/charts';

const mapStateToProps = state => {
  return {
    selectedDevice: getSelectedDeviceDefault(state),
    charts: getDeviceCharts(state),
    unitComponentParts: getSelectedUnitComponentPositions(state),
    userProfileWidgets: getUserProfileWidget(state),
    unitManager: getUnitManager(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addChart: unitSerialNumber => addChart(unitSerialNumber)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DataTrend);
