import React from 'react';
import PropTypes from 'prop-types';
import s from './Alarms.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import { ALARM_STATUS_PENDING, ALARM_STATUS_RESOLVED } from '../../constants/index';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import chain from '../../assets/chain.svg';
import key from '../../assets/key.svg';

const AlarmsListTable = ({
  paginatedData,
  redirect,
  currentPage,
  UpdateAllowed,
  onSortOrder,
  asendingOrder,
  currentTimezone,
  setSelectedUnit
}) => {
  const onAlarmSelection = obj => {
    if (UpdateAllowed) {
      setSelectedUnit(obj.UnitSerialNumber);
      redirect(`/events/action/${obj.UnitSerialNumber}/${obj.EventActionId}`);
    }
  };

  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="alarms.dateReceived" defaultMessage="Date Received" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="alarms.eventCode" defaultMessage="Event Code" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="alarms.alarmName" defaultMessage="Name" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="alarms.linkedAlarm" defaultMessage="Linked Alarm" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="alarms.status" defaultMessage="Status" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="alarms.unit" defaultMessage="Unit" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr
                key={n.EventActionId}
                data-unittest="alarmData"
                onClick={onAlarmSelection.bind(this, n)}
                className={
                  n.EventStatus === ALARM_STATUS_RESOLVED ? s.resolved : n.EventStatus === ALARM_STATUS_PENDING ? s.pending : s.new
                }
              >
                <td className="d-none d-md-table-cell">
                  {n.EventDateTime
                    ? moment(parseInt(n.EventDateTime))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                </td>
                <td>{n.EventCode || ''}</td>
                <td>{n.EventTitle || ''}</td>
                <td>
                  {n.LinkStatus === 2 ? (
                    <img src={chain} alt="Linked Alarm" />
                  ) : n.LinkStatus === 1 ? (
                    <img src={key} alt="Parent Alarm" />
                  ) : (
                    ''
                  )}
                </td>
                <td className={'d-none d-md-table-cell ' + s.status}>
                  <span
                    className={`${s.dot} ${
                      n.EventStatus === ALARM_STATUS_RESOLVED
                        ? s.dotResovled
                        : n.EventStatus === ALARM_STATUS_PENDING
                        ? s.dotPending
                        : s.dotNew
                    }`}
                  ></span>{' '}
                  {n.EventStatus || ''}
                </td>
                <td className="d-none d-md-table-cell">{n.Name}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

AlarmsListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

AlarmsListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(AlarmsListTable);
