import React from 'react';
import s from './EventCodeList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { EVENT_TYPES } from '../../constants/device';

const getEventType = typeId => {
  const type = EVENT_TYPES[typeId].name;
  if (type) {
    return type;
  }
  return '';
};

const getEventTypeStyle = typeId => {
  if (typeId === 1) {
    return s.dotWarnings;
  } else if (typeId === 2) {
    return s.dotAlarms;
  } else if (typeId === 3) {
    return s.dotVersions;
  } else {
    return s.dotEvents;
  }
};

const EventCodeListTable = ({ paginatedData, currentPage, userProfileWidgets }) => {
  const isEditAllowed = IsWidgetAccessible(userProfileWidgets, 'EVENTCODEUPDATE');

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="eventCodes.code'" defaultMessage="Event Code" />
            </th>
            <th>
              <FormattedMessage id="eventCodes.sensor" defaultMessage="Sensor" />
            </th>
            <th>
              <FormattedMessage id="eventCodes.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="eventCodes.eventCodeType" defaultMessage="Event Code Type" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="eventCodes.flowSequence" defaultMessage="Flow Sequence" />
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.EventCodeId} data-unittest="eventCodeData">
                <td>{n.Code || ''}</td>
                <td className="d-none d-md-table-cell">{n.ModelComponentName || ''}</td>
                <td>{n.Name || ''}</td>
                <td>
                  {' '}
                  <span className={`${s.dot} ${getEventTypeStyle(n.Severity)}`}></span> {getEventType(n.Severity)}
                </td>
                <td className="d-none d-md-table-cell">{n.GlobalSettingName || ''}</td>
                <td>
                  {isEditAllowed ? (
                    <Link to={`/admin-controls/event-codes/${n.EventCodeId}`}>
                      <Button variant="outline-secondary" className={s.changeButton}>
                        <FormattedMessage id="eventCodes.edit" defaultMessage="EDIT" />
                      </Button>
                    </Link>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

EventCodeListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

EventCodeListTable.propTypes = {};

export default injectIntl(EventCodeListTable);
