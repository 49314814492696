import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './ModelComponentPosition.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';

const ModelSubComponentPositionListTable = ({
  ModelComponentPositionData,
  ChangeModelComponentPosition,
  UpdateAllowed,
  intl,
  onAddNewBtnClick
}) => {
  const labelNo = <FormattedMessage id="common.no" defaultMessage="No" />;
  const labelYes = <FormattedMessage id="common.yes" defaultMessage="Yes" />;
  let history = useHistory();

  return (
    <div className={s.contentWrapper}>
      <Row>
        <Col lg={9}>
          <div className={s.subHeading}>
            <h4>
              <FormattedMessage id="modelComponentPositions.subcomponents" defaultMessage="Subcomponents" />
            </h4>
            <span className={s.circle}>{ModelComponentPositionData.length}</span>
          </div>
        </Col>
        <Col lg={3} className={s.colAddButton}>
          <Button variant="primary" data-unittest="addnew" className={s.addButton} onClick={() => onAddNewBtnClick()}>
            + <FormattedMessage id="checklistTasks.addNew" defaultMessage="ADD NEW" />
          </Button>
        </Col>
      </Row>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="modelComponentPositions.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="modelComponentPositions.componentType" defaultMessage="Component type" />
            </th>
            <th>
              <FormattedMessage id="modelComponentPositions.state" defaultMessage="State" />
            </th>
            <th>
              <FormattedMessage id="modelComponentPositions.feedback" defaultMessage="Feedback" />
            </th>
            <th>
              <FormattedMessage id="modelComponentPositions.ccp" defaultMessage="CCP" />
            </th>
            <th>
              <FormattedMessage id="modelComponentPositions.ocp" defaultMessage="OCP" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ModelComponentPositionData.length == 0 && (
            <Fragment>
              <tr data-unittest="modelComponentPositionData">
                <td colSpan={7} className={s.emptyRow}>
                  <FormattedMessage id="modelComponentPositions.noData" defaultMessage="No Data" />
                </td>
              </tr>
            </Fragment>
          )}
          {ModelComponentPositionData.length > 0 &&
            ModelComponentPositionData.map(n => {
              return (
                <Fragment key={n.ModelComponentPositionId}>
                  <tr data-unittest="modelComponentPositionData">
                    <td>{n.Name}</td>
                    {/* <td className="d-none d-lg-table-cell">{n.Description}</td> */}
                    <td>{n.ModelComponentTypeName}</td>
                    <td>{n.NeedState ? labelYes : labelNo}</td>
                    <td>{n.NeedFeedback ? labelYes : labelNo}</td>
                    <td>{n.IsCCP ? labelYes : labelNo}</td>
                    <td>{n.IsOCP ? labelYes : labelNo}</td>
                    {UpdateAllowed && (
                      <td className={s.btnCol}>
                        {UpdateAllowed && !n.IsRaw && (
                          <Button
                            variant="outline-secondary"
                            className={s.changeButton}
                            href="#"
                            onClick={() => {
                              ChangeModelComponentPosition(n.ModelComponentPositionId);
                            }}
                            data-unittest="changeData"
                          >
                            <FormattedMessage id="modelComponentPositions.change" defaultMessage="CHANGE" />
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                </Fragment>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

ModelSubComponentPositionListTable.defaultProps = {
  ModelComponentPositionData: []
};

ModelSubComponentPositionListTable.propTypes = {
  ModelComponentPositionData: PropTypes.array.isRequired,
  ChangeModelComponentPosition: PropTypes.func.isRequired,
  UpdateAllowed: PropTypes.bool
};

export default injectIntl(ModelSubComponentPositionListTable);
