import React, { Fragment, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ComponentPositionModel.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import help from '../../assets/help-grey.svg';
import ComponentPositionModel from './ComponentPositionModel';

const ComponentPositionModelListTable = ({
  ComponentPositionsManager,
  modelComponentPositions,
  ComponentPositionsData,
  disableMode,
  ModelId,
  saveComponentPosition,
  setComponentPositionChange,
  DeleteAllowed,
  deleteComponentPosition,
  cancelHandler,
  intl
}) => {
  const editModelVersionContent = (
    <ComponentPositionModel
      ModelId={ModelId}
      saveComponentPosition={saveComponentPosition}
      ComponentPositionsManager={ComponentPositionsManager}
      modelComponentPositions={modelComponentPositions}
      setComponentPositionChange={setComponentPositionChange}
      cancelHandler={cancelHandler}
    />
  );

  useLayoutEffect(() => {
    if (disableMode) {
      const scrollPos = window.scrollY + window.innerHeight;
      window.scrollTo(0, scrollPos);
    }
  }, [disableMode]);

  const getStatusText = status => {
    if (status) {
      return 'True';
    } else {
      return 'False';
    }
  };

  let newRowClass = ComponentPositionsManager.showAddEditScreen ? s.newRow : '';

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="model.componentpositions.positionName" defaultMessage="Position" />
            </th>
            <th>
              <FormattedMessage id="model.componentpositions.positionDescription" defaultMessage="Description" />
            </th>
            <th>
              <FormattedMessage id="model.componentpositions.componentType" defaultMessage="Component Type" />
            </th>
            <th>
              <FormattedMessage id="model.componentpositions.state" defaultMessage="State" />
            </th>
            <th>
              <FormattedMessage id="model.componentpositions.feedback" defaultMessage="Feedback" />
            </th>
            <th>
              <FormattedMessage id="model.componentpositions.unitOfMeasure" defaultMessage="Unit of Measure" />
            </th>
            <th>
              <FormattedMessage id="model.componentpositions.active" defaultMessage="Active" />
            </th>
            {DeleteAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {ComponentPositionsData.map(n => {
            return (
              <Fragment key={n.ModelComponentPositionLinkId}>
                <tr key={n.ModelComponentPositionLinkId} data-unittest="ComponentPositionsData">
                  <td>{n.Name}</td>
                  <td>{n.Description}</td>
                  <td>{n.ComponentType}</td>
                  <td>{getStatusText(n.NeedState)}</td>
                  <td>{getStatusText(n.NeedFeedback)}</td>
                  <td>{n.NeedUnitOfMeasure || '-'}</td>
                  <td>{getStatusText(n.IsActive)}</td>
                  {DeleteAllowed && (
                    <td className={s.btnCol}>
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.removeButton}
                          href="#"
                          onClick={() =>
                            deleteComponentPosition({
                              ModelComponentPositionId: n.ModelComponentPositionId,
                              ModelComponentPositionLinkId: n.ModelComponentPositionLinkId
                            })
                          }
                          disabled={disableMode}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="modelVersion.remove" defaultMessage="Remove" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className={newRowClass}>{ComponentPositionsManager.showAddEditScreen && editModelVersionContent}</div>
      {/* <ReactTooltip /> */}
    </div>
  );
};

ComponentPositionModelListTable.defaultProps = {
  ComponentPositionsData: []
};

ComponentPositionModelListTable.propTypes = {
  selectedModelComponentPositionLinkId: PropTypes.number,
  saveComponentPosition: PropTypes.func.isRequired,
  ComponentPositionsManager: PropTypes.object.isRequired,
  setComponentPositionChange: PropTypes.func.isRequired,
  ComponentPositionsData: PropTypes.array,
  DeleteAllowed: PropTypes.bool,
  disableMode: PropTypes.bool
};

export default injectIntl(ComponentPositionModelListTable);
