import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import Alarms from './Alarms';
import { getAlarms, getCurrentTimezone, getAlarmStatus, getUserProfileWidget } from '../../selectors/index';
import { getAlarmsThunk, getAlarmsFilterThunk, setAlarmFilteredDataThunk, setCurrentPage, setPageFilter } from '../../actions/alarms';
import { setSelectedUnit } from '../../actions/devices';

const mapStateToProps = state => {
  return {
    alarms: getAlarms(state),
    alarmsStatus: getAlarmStatus(),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirect: location => push(location),
      getAlarm: filterObj => getAlarmsThunk(filterObj, 'NAV_ALARMS'),
      getAlarmFilters: () => getAlarmsFilterThunk('NAV_ALARMS'),
      setFilterData: currentPage => setAlarmFilteredDataThunk(currentPage),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter),
      setSelectedUnit: unitSerialNumber => setSelectedUnit(unitSerialNumber)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);
