import React from 'react';
import s from './Dropdown.module.scss';
import Select from 'react-select';

//Common Dropdown for WS project
//Properties
//    Events => onDropdownChange(selectedObject)
//              componentDidMount() //is invoked immediately after a component is mounted (inserted into the tree).
//              componentDidUpdate() //is invoked immediately after updating occurs. This method is not called for the initial render
//              componentWillUnmount() // is invoked immediately before a component is unmounted and destroyed
//              componentDidCatch(error, info) //This lifecycle is invoked after an error has been thrown by a descendant component

const Dropdown = props => {
  return (
    <Select
      id={props.id || undefined}
      placeholder={props.controlData.placeholderText}
      className={`${s.wsaDropdown} ${props.controlData.customClassName}`}
      options={props.dataArray}
      onChange={props.onDropdownChange || null}
      value={props.selectedOption}
      isDisabled={props.disabled}
      //defaultValue={props.defaultValue || null}
    />
  );
};

Dropdown.defaultProps = {
  dataArray: [],
  controlData: {
    placeholderText: '',
    customClassName: ''
  },
  value: {}
};

export default Dropdown;
