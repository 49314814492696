import React from 'react';
import s from './User.module.scss';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { initialUnitManagerState } from '../../reducers/initialState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SearchBar from '../SearchBar/SearchBar';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const UserProfileUnits = ({ unitManager, highlightedProfile, onUnitSelectionChange, onUnitSearch }) => {
  let activeUnits = [];
  let formattedSelectedUnits = [];

  // const onPageChangeHandler = currentPage => {
  //   setCurrentPage(currentPage);
  // };

  const searchHandler = value => {
    onUnitSearch(value);
  };

  if (highlightedProfile && highlightedProfile.selectedUnits) {
    formattedSelectedUnits = highlightedProfile.selectedUnits.map(sl => {
      return {
        ...sl,
        SKEY: sl.UnitId,
        label: sl.Name,
        value: sl.UnitId,
        isChecked: true
      };
    });
  }
  if (unitManager.units) {
    activeUnits = unitManager.units
      .filter(p => p.IsActive && !p.IsDeleted)
      .map(unit => {
        return {
          ...unit,
          SKEY: unit.UnitId,
          label: unit.Name,
          value: unit.UnitId,
          isChecked: formattedSelectedUnits.filter(formattedUnit => formattedUnit.UnitId === unit.UnitId).length > 0
        };
      });
  }

  const onCheckboxSelectionChange = (e, data) => {
    if (!highlightedProfile.selectedUnits) {
      highlightedProfile.selectedUnits = [];
    }

    if (data.isChecked) {
      highlightedProfile.selectedUnits.push(data);
    } else {
      highlightedProfile.selectedUnits = highlightedProfile.selectedUnits.filter(selUnit => selUnit.UnitId !== data.UnitId);
    }
    onUnitSelectionChange(highlightedProfile);
  };

  return (
    <div data-unittest="unit" className={s.userProfiles}>
      {unitManager.isLoading && <LoadingSpinner />}
      {/* <Banner
          failureText={unitManager.messageText}
          showBanner={unitManager.showBanner}
          status={unitManager.isOpSuccessfull}
          successText={unitManager.messageText}
        /> */}
      <Row className={s.row}>
        <Col sm={6}>
          <SearchBar searchHandler={searchHandler} clearSearchInVisible={true} placeHolderTextId="usermanagement.unitname" />
        </Col>
        <Col sm={6}>
          <Dropdown
            dataArray={[
              {
                label: 'Name',
                value: 'Name',
                isDisabled: false
              }
            ]}
            controlData={{ placeholderText: 'Show:all', customClassName: '' }}
            onDropdownChange={null}
          />
        </Col>
      </Row>

      <Row className={s.selectedProfilesNUnits}>
        <Col sm={12} className={s.selectedCols}>
          <Checkbox dataArray={activeUnits} onSelectionChange={onCheckboxSelectionChange} />
        </Col>
      </Row>

      <div></div>
    </div>
  );
};

UserProfileUnits.defaultProps = {
  units: [],
  highlightedProfile: {},
  unitManager: {
    ...initialUnitManagerState.unitManager
  }
};

UserProfileUnits.propTypes = {
  onUnitSelectionChange: PropTypes.func.isRequired
};

export default injectIntl(UserProfileUnits);
