import { GET_ALL_USER_PROFILE_UNITS, SET_UNIT_TAGOUT } from '../constants/index';

const userProfileUnits = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_USER_PROFILE_UNITS: {
      const { profile } = payload;
      return {
        ...state,
        userProfileUnits: profile.profile.units
      };
    }

    case SET_UNIT_TAGOUT: {
      const { tagout, unitSerialNumber } = payload;

      let units = state.userProfileUnits;
      let unitIndex = units.findIndex(x => x.UnitSerialNumber === unitSerialNumber);
      units[unitIndex].IsTaggedOut = tagout.IsTaggedOut;

      return {
        ...state,
        userProfileUnits: units
      };
    }

    default:
      return state;
  }
};

export default userProfileUnits;
