import {
  UNIT_ADD_ERROR,
  SET_UNIT_ADD_DROPDOWN_LIST,
  UNIT_ADD_LOADING,
  SET_UNIT_ADD_INFO,
  UNIT_ADD_CHANGE,
  SET_UNIT_ADD_CLEANUP
} from '../constants/index';

import { initialUnitState } from './initialState';

const unit = (state = initialUnitState, action) => {
  const { payload, type } = action;
  switch (type) {
    case UNIT_ADD_LOADING: {
      const { status } = payload;
      return {
        ...state,
        unit: {
          ...state.unit,
          isLoading: status
        }
      };
    }

    case UNIT_ADD_ERROR: {
      const { unit } = payload;
      return {
        ...state,
        unit: {
          ...state.unit,
          ...unit
        }
      };
    }

    case SET_UNIT_ADD_DROPDOWN_LIST: {
      const { data, result } = payload;
      return {
        ...state,
        unit: {
          ...state.unit,
          unitDropdownList: {
            ...state.unit.unitDropdownList,
            ...data
          },
          ...result
        }
      };
    }

    case SET_UNIT_ADD_INFO: {
      const { data } = payload;
      return {
        ...state,
        unit: {
          ...state.unit,
          ...data
        }
      };
    }

    case UNIT_ADD_CHANGE: {
      const selectedUnit = payload;
      return {
        ...state,
        unit: {
          ...state.unit,
          selectedUnit: {
            ...state.unit.selectedUnit,
            ...selectedUnit
          }
        }
      };
    }

    case SET_UNIT_ADD_CLEANUP: {
      let { data } = payload;
      return {
        ...state,
        unit: {
          ...state.unit,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default unit;
