import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './AdminControlsTabs.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const Tab = ({ selectedPage, pageComponent, url, title }) => (
  <Fragment>
    <Link className={classNames({ [s.active]: selectedPage === pageComponent })} to={url}>
      {title}
      {selectedPage === pageComponent && <span />}
    </Link>
  </Fragment>
);

const AdminControlsTabs = ({ selectedPage, userProfileWidgets }) => (
  <ul className={s.tabNav}>
    {IsWidgetAccessible(userProfileWidgets, 'ORGANISATION_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/organisation-list`}
          title={<FormattedMessage id="AdminControlsTabs.manageOrganisations" defaultMessage="Organisations" />}
          selectedPage={selectedPage}
          pageComponent="manageOrganisations"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'USERS_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/user-list`}
          title={<FormattedMessage id="AdminControlsTabs.manageUsers" defaultMessage="Users" />}
          selectedPage={selectedPage}
          pageComponent="manageUsers"
        />
      </li>
    )}

    {IsWidgetAccessible(userProfileWidgets, 'PROFILES_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/profile-list`}
          title={<FormattedMessage id="AdminControlsTabs.manageProfiles" defaultMessage="Profiles" />}
          selectedPage={selectedPage}
          pageComponent="manageProfiles"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'MODELS_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/model-list`}
          title={<FormattedMessage id="AdminControlsTabs.manageModel" defaultMessage="Models" />}
          selectedPage={selectedPage}
          pageComponent="manageModels"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'CALIBRATIONS_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/calibration-equation-list`}
          title={<FormattedMessage id="AdminControlsTabs.calibrations" defaultMessage="Calibrations" />}
          selectedPage={selectedPage}
          pageComponent="manageCalibrations"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'TASKSCHECKLISTS_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/checklist-task-templates`}
          title={<FormattedMessage id="AdminControlsTabs.checklistsTasks" defaultMessage="Checklists & Tasks" />}
          selectedPage={selectedPage}
          pageComponent="checklistTaskTemplate"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'GLOBALSETTINGS_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/general-global-settings`}
          title={<FormattedMessage id="AdminControlsTabs.globalSettings" defaultMessage="Global Settings" />}
          selectedPage={selectedPage}
          pageComponent="globalSettings"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'EVENTCODES_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/event-code-list`}
          title={<FormattedMessage id="eventCodes.eventCodes" defaultMessage="Event Codes" />}
          selectedPage={selectedPage}
          pageComponent="manageEventCodes"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'MODELCOMPONET_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/model-component`}
          title={<FormattedMessage id="modelComponentType.Components" defaultMessage="Components" />}
          selectedPage={selectedPage}
          pageComponent="manageComponents"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'NOTIFICATIONS_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/notifications`}
          title={<FormattedMessage id="AdminControlsTabs.notifications" defaultMessage="Notifications" />}
          selectedPage={selectedPage}
          pageComponent="manageNotifications"
        />
      </li>
    )}

    {IsWidgetAccessible(userProfileWidgets, 'FAQ_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/faqs`}
          title={<FormattedMessage id="AdminControlsTabs.faq" defaultMessage="FAQ" />}
          selectedPage={selectedPage}
          pageComponent="manageFAQ"
        />
      </li>
    )}

    {IsWidgetAccessible(userProfileWidgets, 'RELEASENOTELIST') && (
      <li>
        <Tab
          url={`/admin-controls/release-notes`}
          title={<FormattedMessage id="AdminControlsTabs.releaseNotes" defaultMessage="Release Notes" />}
          selectedPage={selectedPage}
          pageComponent="manageReleaseNotes"
        />
      </li>
    )}
  </ul>
);

AdminControlsTabs.propTypes = {
  selectedPage: PropTypes.string.isRequired
};

export default AdminControlsTabs;
