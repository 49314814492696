import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfileList from './ProfileList';
import { getProfile, getUserProfileWidget } from '../../selectors/index';
import { getProfileThunk } from '../../actions/profiles';
import { fetchAppWidgetsThunk } from '../../actions/appwidgets';

const mapStateToProps = state => {
  return {
    profile: getProfile(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProfileInfo: () => getProfileThunk('PROFILES_TAB'),
      getAppWidgets: () => fetchAppWidgetsThunk('PROFILES_TAB')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileList);
