import React, { useEffect, useState } from 'react';
import s from './ReleaseNoteView.module.scss';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getReleaseNoteViewThunk } from '../../actions/releaseNotes';
import { getReleaseNote } from '../../selectors/index';

const ReleaseNoteView = ({ intl }) => {
  const dispatch = useDispatch();
  const widgetCode = 'GENERALACCESS';
  const releaseNote = useSelector(state => getReleaseNote(state));
  const releaseNoteViewData = releaseNote.viewReleaseNotes || [];
  useEffect(() => {
    dispatch(getReleaseNoteViewThunk(widgetCode));
  }, [getReleaseNoteViewThunk, dispatch, widgetCode]);

  return (
    <div className={s.releaseNotes}>
      {releaseNote.isLoading && <LoadingSpinner />}
      <div className={s.fullWidth}>
        <div className={s.fullWidthWrapper}>
          <h1 className={s.tabHeader}>{<FormattedMessage id="releaseNote.releaseNotes" defaultMessage="Release Notes" />}</h1>
        </div>
      </div>
      <div className={s.pageContent}>
        {releaseNoteViewData.length > 0 &&
          releaseNoteViewData.map(item => {
            return <ReleaseNoteItem key={item.ReleaseNoteId} item={item} />;
          })}
      </div>
    </div>
  );
};

ReleaseNoteView.defaultProps = {};

ReleaseNoteView.propTypes = {};

export default ReleaseNoteView;

const ReleaseNoteItem = ({ item }) => {
  return (
    <div className={s.contentWrapper}>
      <Row>
        <Col>
          <h2>{item.Name}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Version No : {item.VersionNo}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={s.notes} dangerouslySetInnerHTML={{ __html: item.Notes }} />
        </Col>
      </Row>
    </div>
  );
};
