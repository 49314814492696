import { connect } from 'react-redux';
import Sidebar from './Sidebar';
import { bindActionCreators } from 'redux';
import {
  getCurrentSidebarState,
  getUserProfileWidget,
  getGlobalSettingsDataPresent,
  getUserProfileWidgetsPresent
} from '../../selectors/index';
import { toggleSidebar } from '../../actions/sidebar';
import { getSelectedUserProfileWidgetsThunk } from '../../actions/UserProfileWidgets';
import { getGlobalSettingsThunk } from '../../actions/globalSettings';

const mapStateToProps = state => {
  return {
    currentSidebarOpen: getCurrentSidebarState(state),
    userProfileWidgets: getUserProfileWidget(state),
    globalSettingsDataPresent: getGlobalSettingsDataPresent(state),
    userProfileWidgetsPresent: getUserProfileWidgetsPresent(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleViewSidebar: sidebarOpen => toggleSidebar(sidebarOpen),
      getSelectedUserProfileWidgets: (profileId, userName) => getSelectedUserProfileWidgetsThunk(profileId, userName, 'GENERALACCESS'),
      getGlobalSettings: () => getGlobalSettingsThunk(null, null, null, 'GENERALACCESS')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
