import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import s from './PartDetails.module.scss';
import { FormattedMessage } from 'react-intl';
import Table from 'react-bootstrap/Table';
import { getCurrentTimezone } from '../../selectors/index';
import moment from 'moment-timezone';

const PartFirmwareHistoryListTable = ({ firmwareHistoryData }) => {
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="modelFirmwareVersion.name" defaultMessage="Version Name" />
            </th>
            <th>
              <FormattedMessage id="modelFirmwareVersion.version" defaultMessage="Version" />
            </th>
            <th className={s.sortHeading}>
              <FormattedMessage id="modelFirmwareVersion.publishedDate" defaultMessage="Published Date" />{' '}
            </th>

            <th>
              <FormattedMessage id="modelFirmwareVersion.publishedBy" defaultMessage="Published By" />
            </th>
            <th>
              <FormattedMessage id="modelFirmwareVersion.updatedDateOn" defaultMessage="Updated On" />
            </th>
            <th>
              <FormattedMessage id="modelFirmwareVersion.activeVersion" defaultMessage="Active Version" />
            </th>
          </tr>
        </thead>
        <tbody>
          {firmwareHistoryData.map((n, index) => {
            return (
              <Fragment key={index}>
                <tr key={index} data-unittest="firmwareHistoryData">
                  <td>{n.VersionName}</td>
                  <td>{n.Version}</td>
                  <td>
                    {n.PublishedDateTime
                      ? moment
                          .unix(n.PublishedDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm:ss z')
                      : '-'}
                  </td>
                  <td>{n.PublishedBy}</td>

                  <td>
                    {n.UpdatedDateTime
                      ? moment
                          .unix(n.UpdatedDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm:ss z')
                      : '-'}
                  </td>
                  <td>
                    {n.IsCurrentVersion ? (
                      <FormattedMessage id="common.yes" defaultMessage="yes" />
                    ) : (
                      <FormattedMessage id="common.no" defaultMessage="no" />
                    )}
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

PartFirmwareHistoryListTable.defaultProps = {
  firmwareHistoryData: []
};

PartFirmwareHistoryListTable.propTypes = {
  firmwareHistoryData: PropTypes.array.isRequired
};

export default PartFirmwareHistoryListTable;
