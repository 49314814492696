import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EventCodeList from './EventCodeList';
import {
  getEventCode,
  getUserProfileWidget,
  getCurrentTimezone,
  getFlowSequenceGlobalSettings,
  getAllModelComponentPositions
} from '../../selectors/index';
import { getEventCodeThunk, setCurrentPage, setPageFilter, setFilteredDataThunk } from '../../actions/eventCode';

const mapStateToProps = state => {
  return {
    eventCodeManager: getEventCode(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state),
    flowSequences: getFlowSequenceGlobalSettings(state),
    modelComponentPositions: getAllModelComponentPositions(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getEventCodes: () => getEventCodeThunk('EVENTCODES_TAB'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter),
      setFilterData: filteredData => setFilteredDataThunk(filteredData)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventCodeList);
