import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import s from './OrganisationRegion.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganisationRegion } from '../../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import { initialOrganisationRegionState } from '../../../reducers/initialState';
import { getOrganisationRegionThunk, saveOrganisationRegionThunk } from '../../../actions/organisationRegions';
import OrganisationRegionListTable from './OrganisationRegionListTable';
import collapseDown from '../../../assets/collapse-down.svg';
import collapseUp from '../../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import SearchBar from '../../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';

const OrganisationRegionList = ({ userProfileWidgets, intl }) => {
  const history = useHistory();
  const { organisationId } = useParams();
  let initialLimit = 6;

  const dispatch = useDispatch();
  const organisationRegion = useSelector(state => getOrganisationRegion(state));

  let initialRecords = organisationRegion.organisationRegions.slice(0, initialLimit);
  const [organisationRegionOpen, setOrganisationRegionOpen] = useState(false);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    dispatch(getOrganisationRegionThunk('ORGANISATION_REGION_LIST', organisationId));
  }, [getOrganisationRegionThunk, dispatch, organisationId, organisationRegion.isOpSuccessful]);

  const [localOrganisationRegions, setLocalOrganisationRegions] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [organisationRegionId, setOrganisationRegionId] = useState(0);

  const [searchText, setSearchText] = useState('');

  const localOrganisationRegionLength = localOrganisationRegions.length;
  const globalSettingLength = organisationRegion.organisationRegions.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalOrganisationRegions(organisationRegion.organisationRegions);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = organisationRegion.organisationRegions.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalOrganisationRegions(filteredList);
  };

  const filterData = () => {
    let filteredList = organisationRegion.organisationRegions;
    if (isFilter) {
      filteredList = organisationRegion.organisationRegions.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getOrganisationRegionData = () => {
    let organisationRegion = [];
    if (showAll) {
      organisationRegion = filterData();
    } else {
      if (isFilter) {
        organisationRegion = localOrganisationRegionLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        organisationRegion = initialRecords;
      }
    }
    return organisationRegion.sort(compareValues('Name'));
  };

  const onRemoveOrganisationRegionClick = organisationRegionId => {
    setActive(true);
    setOrganisationRegionId(organisationRegionId);
  };

  const DeleteOrganisationRegion = () => {
    let saveData = organisationRegion.selectedOrganisationRegion;
    saveData.PerformDelete = true;
    saveData.OrganisationRegionId = organisationRegionId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveOrganisationRegionThunk(saveData, 'ORGANISATION_REGION_DELETE', organisationId));
  };

  const handleClose = () => {
    setActive(false);
    setOrganisationRegionId(0);
  };

  const handleConfirm = () => {
    DeleteOrganisationRegion();
    handleClose();
  };

  const onAddNewClick = () => {
    history.push(`/admin-controls/organisation-list/${organisationId}/configure/region`);
  };

  const onChangeOrganisationRegionClick = organisationRegionId => {
    history.push(`/admin-controls/organisation-list/${organisationId}/configure/region/${organisationRegionId}`);
  };

  let messageId = (organisationRegion && organisationRegion.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'ORGANISATION_REGION_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'ORGANISATION_REGION_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'organisationRegions.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this /site?'
  });

  return (
    <div className={s.organisationRegionList}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'ORGANISATION_REGION_LIST')}>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={organisationRegionOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setOrganisationRegionOpen(!organisationRegionOpen)}
                aria-controls="model-component-type-section"
                aria-expanded={organisationRegionOpen}
              ></img>
              <h4>
                <FormattedMessage id="organisationRegions.organisationRegions" defaultMessage="Organisation Regions" />
              </h4>
              <span className={s.circle}>{organisationRegion.organisationRegions.length}</span>
            </Row>
          </Col>
        </Row>
        <Collapse in={organisationRegionOpen}>
          <div className={s.innerComponent} id="model-component-type-section">
            <div className={s.component}>
              {organisationRegion.isLoading && <LoadingSpinner />}
              {organisationRegion.showBanner && (
                <Banner
                  failureText={messageText}
                  showBanner={organisationRegion.showBanner}
                  status={organisationRegion.isOpSuccessful}
                  successText={messageText}
                />
              )}
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
              <div className={s.contentWrapper}>
                <Row className={s.topRow}>
                  <Col sm={6} lg={4}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={false}
                      disabled={organisationRegion.showAddEditScreen}
                      // initialText={filter}
                      placeHolderTextId="common.search"
                      data-unittest="searchOrganisationRegions"
                    />
                  </Col>
                  <Col sm={6} lg={8}>
                    <Button
                      className={s.addbutton}
                      style={WidgetVisibility(userProfileWidgets, 'ORGANISATION_REGION_ADD')}
                      onClick={onAddNewClick}
                      disabled={organisationRegion.showAddEditScreen}
                      data-unittest="addOrganisationRegionButton"
                    >
                      + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Col>
                </Row>

                <div>
                  <OrganisationRegionListTable
                    RemoveOrganisationRegion={onRemoveOrganisationRegionClick}
                    OrganisationRegionData={getOrganisationRegionData()}
                    ChangeOrganisationRegion={onChangeOrganisationRegionClick}
                    UpdateAllowed={isUpdateAllowed}
                    DeleteAllowed={isDeleteAllowed}
                  />
                </div>
                {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localOrganisationRegionLength > initialLimit)) && (
                  <Row className={s.topRow}>
                    <Col>
                      <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                        {!showAll
                          ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localOrganisationRegionLength})`
                          : showLessText}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

OrganisationRegionList.defaultProps = {
  ...initialOrganisationRegionState
};

export default injectIntl(OrganisationRegionList);
