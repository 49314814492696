import Cookies from 'js-cookie';
import { UPDATE_SENSOR_DATA, SENSOR_LOADING_DATA } from '../constants/index';
import { apiAction } from '../utils/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const fetchSensorReadings = unitSerialNumber =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${unitSerialNumber}/telemetry/summary`,
    method: 'GET',
    onSuccess: processSensorMetrics,
    onLoad: status => updateSensorLoading(status),
    accessToken: Cookies.get(`access-${envName}`),
    data: {}
  });

export const processSensorMetrics = results => dispatch => {
  dispatch(updateSensorMetrics(results));
};

export const updateSensorMetrics = results => ({
  type: UPDATE_SENSOR_DATA,
  payload: { results }
});

export const updateSensorLoading = status => ({
  type: SENSOR_LOADING_DATA,
  payload: { status }
});
