import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionThresholds.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelVersionState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import { compareValues } from '../../utils/';

const ModelVersionThreshold = ({
  ModelVersionThresholdId,
  saveModelVersionThreshold,
  modelVersionThreshold,
  changeModelVersionThreshold,
  flowSequences,
  modelComponentPositions,
  cancelHandler,
  intl
}) => {
  let IsEdit = false;
  let IsRedirect = false;
  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  if (ModelVersionThresholdId) {
    IsEdit = true;
  } else {
    IsRedirect = true;
  }

  let widgetCode = IsEdit ? 'MODELVERSIONTHRESHOLDSEDIT' : 'MODELVERSIONTHRESHOLDSADD';

  //clean up
  useEffect(() => {
    return () => {
      cancelHandler();
    };
  }, []);

  // Update redux store
  const setLocalModelVersionThreshold = currentState => {
    changeModelVersionThreshold(currentState);
  };

  const flowSequenceOptions = () => {
    let flowSequenceData = [];

    flowSequences.forEach(element => {
      flowSequenceData.push({
        label: element.Name,
        value: element.GeneralGlobalSettingId
      });
    });
    return flowSequenceData.sort(compareValues('label'));
  };

  const modelComponentsOptions = () => {
    let modelComponentPositionsData = [];

    const sensorComponents = modelComponentPositions.filter(item => item.NeedFeedback === true);

    sensorComponents.forEach(element => {
      modelComponentPositionsData.push({
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return modelComponentPositionsData.sort(compareValues('label'));
  };

  let ddlClassFlowSequence = localformErrors && localformErrors.GeneralGlobalSettingId ? s.ddlError : '';
  let ddlClassSensor = localformErrors && localformErrors.ModelComponentPositionId ? s.ddlError : '';

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = modelVersionThreshold.SelectedThreshold;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.ModelVersionThresholdId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    //cancelHandler();
    saveModelVersionThreshold(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !modelVersionThreshold ||
      !modelVersionThreshold.SelectedThreshold ||
      !modelVersionThreshold.SelectedThreshold.GeneralGlobalSettingId ||
      isRequired(modelVersionThreshold.SelectedThreshold.GeneralGlobalSettingId, 1)
    ) {
      formErrors.GeneralGlobalSettingId = intl.formatMessage({
        id: 'modelVersionThreshold.FlowSequenceMandotory',
        defaultMessage: 'Flow Sequence is a mandatory field'
      });
      isValid = false;
    }

    if (
      !modelVersionThreshold ||
      !modelVersionThreshold.SelectedThreshold ||
      !modelVersionThreshold.SelectedThreshold.ModelComponentPositionId ||
      isRequired(modelVersionThreshold.SelectedThreshold.ModelComponentPositionId, 1)
    ) {
      formErrors.ModelComponentPositionId = intl.formatMessage({
        id: 'modelVersionThreshold.sensorMandatory',
        defaultMessage: 'Sensor is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalModelVersionThreshold({
        SelectedThreshold: { ...modelVersionThreshold.SelectedThreshold, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalModelVersionThreshold({
        SelectedThreshold: { ...modelVersionThreshold.SelectedThreshold, [e.target.name]: e.target.value }
      });
    }
  };

  const onDropdownFlowSequenceChange = e => {
    setLocalModelVersionThreshold({
      SelectedThreshold: { ...modelVersionThreshold.SelectedThreshold, GeneralGlobalSettingId: e.value }
    });
  };

  const onDropdownSensorChange = e => {
    setLocalModelVersionThreshold({
      SelectedThreshold: { ...modelVersionThreshold.SelectedThreshold, ModelComponentPositionId: e.value }
    });
  };

  return (
    <div className={s.modelversion}>
      <div className={s.contentWrapper}>
        <Form>
          <div className={s.topRow} data-unittest="formAddEditThresholds">
            <Row>
              <Col lg={10}>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formFlowSequence">
                      <Form.Label>
                        <FormattedMessage id="modelVersion.thresholdFlowSequence" defaultMessage="Flow Sequence" />
                      </Form.Label>
                      <Dropdown
                        id="formFlowSequence"
                        dataArray={flowSequenceOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="modelVersion.selectFlowSequence" defaultMessage="Select Flow Sequence" />,
                          customClassName: ddlClassFlowSequence
                        }}
                        onDropdownChange={onDropdownFlowSequenceChange}
                        selectedOption={flowSequenceOptions().filter(
                          option => option.value === modelVersionThreshold.SelectedThreshold.GeneralGlobalSettingId
                        )}
                        disabled={IsEdit}
                        data-unittest="formFlowSequence"
                      />
                      {localformErrors && localformErrors.GeneralGlobalSettingId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.GeneralGlobalSettingId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group controlId="formSensor">
                      <Form.Label>
                        <FormattedMessage id="modelVersion.thresholdSensor" defaultMessage="Sensor" />
                      </Form.Label>
                      <Dropdown
                        id="formSensor"
                        dataArray={modelComponentsOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="modelVersion.selectSensor" defaultMessage="Select Sensor" />,
                          customClassName: ddlClassSensor
                        }}
                        onDropdownChange={e => onDropdownSensorChange(e)}
                        selectedOption={modelComponentsOptions().filter(
                          option => option.value === modelVersionThreshold.SelectedThreshold.ModelComponentPositionId
                        )}
                        disabled={IsEdit}
                        data-unittest="formSensor"
                      />
                      {localformErrors && localformErrors.ModelComponentPositionId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.ModelComponentPositionId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={4}></Col>
                </Row>
              </Col>
              <Col lg={2}></Col>
            </Row>
            <Row>
              <Col lg={10}>
                <Row>
                  <Col lg={2}>
                    <Form.Group controlId="formModelVersionThresholdLowLow">
                      <Form.Label className={s.red}>
                        <FormattedMessage id="modelVersion.thresholdLowLow" defaultMessage="Low Low" />
                      </Form.Label>
                      <InputNumber
                        name="Min"
                        step=".1"
                        customClassName={s.redBorder}
                        onInputChange={onChange}
                        value={modelVersionThreshold.SelectedThreshold.Min?.toString()}
                        id="formThresholdLowLowValue"
                      />
                      {localformErrors && localformErrors.Min && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Min}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group controlId="formModelVersionThresholdLow">
                      <Form.Label className={s.orange}>
                        <FormattedMessage id="modelVersion.thresholdLow" defaultMessage="Low" />
                      </Form.Label>
                      <InputNumber
                        name="CriticalLow"
                        step=".1"
                        onInputChange={onChange}
                        customClassName={s.orangeBorder}
                        value={modelVersionThreshold.SelectedThreshold.CriticalLow?.toString()}
                        id="formThresholdLowValue"
                      />
                      {localformErrors && localformErrors.CriticalLow && (
                        <p role="alert" className={s.error}>
                          {localformErrors.CriticalLow}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group controlId="formModelVersionThresholdOpLow">
                      <Form.Label className={s.green}>
                        <FormattedMessage id="modelVersion.thresholdOpLow" defaultMessage="Op Low" />
                      </Form.Label>
                      <InputNumber
                        name="Low"
                        step=".1"
                        onInputChange={onChange}
                        customClassName={s.greenBorder}
                        value={modelVersionThreshold.SelectedThreshold.Low?.toString()}
                        id="formThresholdOpLowValue"
                      />
                      {localformErrors && localformErrors.Low && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Low}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group controlId="formModelVersionThresholdOpHigh">
                      <Form.Label className={s.green}>
                        <FormattedMessage id="modelVersion.thresholdOpHigh" defaultMessage="Op High" />
                      </Form.Label>
                      <InputNumber
                        name="High"
                        step=".1"
                        onInputChange={onChange}
                        customClassName={s.greenBorder}
                        value={modelVersionThreshold.SelectedThreshold.High?.toString()}
                        id="formThresholdOpHighValue"
                      />
                      {localformErrors && localformErrors.High && (
                        <p role="alert" className={s.error}>
                          {localformErrors.High}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group controlId="formModelVersionThresholdHigh">
                      <Form.Label className={s.orange}>
                        <FormattedMessage id="modelVersion.thresholdHigh" defaultMessage="High" />
                      </Form.Label>
                      <InputNumber
                        name="CriticalHigh"
                        step=".1"
                        onInputChange={onChange}
                        customClassName={s.orangeBorder}
                        value={modelVersionThreshold.SelectedThreshold.CriticalHigh?.toString()}
                        id="formThresholdHighValue"
                      />
                      {localformErrors && localformErrors.CriticalHigh && (
                        <p role="alert" className={s.error}>
                          {localformErrors.CriticalHigh}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group controlId="formModelVersionThresholdHighHigh">
                      <Form.Label className={s.red}>
                        <FormattedMessage id="modelVersion.thresholdHighHigh" defaultMessage="High High" />
                      </Form.Label>
                      <InputNumber
                        name="Max"
                        step=".1"
                        onInputChange={onChange}
                        customClassName={s.redBorder}
                        value={modelVersionThreshold.SelectedThreshold.Max?.toString()}
                        id="formThresholdHighHighValue"
                      />
                      {localformErrors && localformErrors.Max && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Max}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={2}></Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="modelVersionManagement.saveModelVersionThreshold" defaultMessage="SAVE" />
                </Button>
                <Button
                  variant="outline-secondary"
                  className={classNames(s.btnCancel)}
                  onClick={cancelHandler}
                  noValidate
                  data-unittest="saveCancel"
                >
                  <FormattedMessage id="modelVersionManagement.cancelModelVersionThreshold" defaultMessage="CANCEL" />
                </Button>
              </Col>
              <Col lg={8}></Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

ModelVersionThreshold.defaultProps = {
  modelVersionThreshold: {
    ...initialModelVersionState.modelVersionManager.selectedModelVersion.Thresholds
  }
};

ModelVersionThreshold.propTypes = {
  saveModelVersionThreshold: PropTypes.func.isRequired,
  modelVersionThreshold: PropTypes.object.isRequired,

  changeModelVersionThreshold: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionThreshold);
