import * as timezoneActionTypes from '../constants/index';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const changeTimezone = timezone => ({
  type: timezoneActionTypes.SET_TIMEZONE,
  timezone
});

export const switchTimezone = (showUnitTimezone, deviceTimezone) => dispatch => {
  //Local timezone
  let defaultTimezone = Cookies.get(`defaultTimezone-${envName}`);
  if (defaultTimezone === undefined) {
    defaultTimezone = moment.tz.guess();
  }

  if (showUnitTimezone) {
    defaultTimezone = deviceTimezone;
  }
  dispatch(changeTimezone(defaultTimezone));
};
