import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import HelpControlsTabs from './HelpControlsTabs';
import s from './HelpControlsContentTemplate.module.scss';
import { getUserProfileWidget } from '../../../selectors/index';

const HelpControlsContentTemplate = ({ children, selectedPage }) => {
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));

  return (
    <Fragment>
      <div className={s.fullWidth}>
        <div className={s.fullWidthWrapper}>
          <h1 className={s.tabHeader}>{<FormattedMessage id="help.help" defaultMessage="Help" />}</h1>
          {<HelpControlsTabs selectedPage={selectedPage} userProfileWidgets={userProfileWidgets} />}
        </div>
      </div>

      <div className={s.pageContent}>{children}</div>
    </Fragment>
  );
};

HelpControlsContentTemplate.defaultProps = {
  selectedPage: ''
};

HelpControlsContentTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  selectedPage: PropTypes.string
};

export default HelpControlsContentTemplate;
