import { EVENT_ACTION_EXISTANCE_ERROR, EVENT_ACTION_EXISTANCE_STATUS, EVENT_ACTION_EXISTANCE_IS_LOADING } from '../constants/index';
import { initialEventActionStatus } from './initialState';

const eventActionStatusReducer = (state = initialEventActionStatus, action) => {
  const { payload, type } = action;
  switch (type) {
    case EVENT_ACTION_EXISTANCE_ERROR: {
      const { eventActionStatus } = payload;

      return {
        ...state,
        eventActionStatus: {
          ...state.eventActionStatus,
          ...eventActionStatus
        }
      };
    }

    case EVENT_ACTION_EXISTANCE_STATUS: {
      const { eventActionStatus, message, isLoading } = payload;

      return {
        ...state,
        eventActionStatus: {
          ...state.eventActionStatus,
          ...eventActionStatus.data,
          messageCode: message,
          isOpSuccessfull: true,
          isLoading: isLoading,
          showBanner: false
        }
      };
    }

    case EVENT_ACTION_EXISTANCE_IS_LOADING: {
      return {
        ...state,
        ...payload
      };
    }

    default:
      return state;
  }
};

export default eventActionStatusReducer;
