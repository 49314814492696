import React from 'react';
import Select from 'react-select';
import s from './MultiselectDropdown.module.scss';

class MultiselectDropdown extends React.Component {
  //Constructor
  constructor(props) {
    super(props);
    this.state = {
      dataArray: [],
      selectedOptions: []
    };
  }

  //componentDidMount
  componentDidMount() {
    this.setState({ dataArray: this.props.dataArray || [] });
    this.setState({ selectedOptions: this.props.selectedOptions || [] });

    if (this.props.componentDidMount) {
      this.props.componentDidMount();
    }
  }

  //componentDidUpdate(prevProps, prevState, snapshot)
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.componentDidUpdate) {
      this.props.componentDidUpdate(prevProps, prevState, snapshot);
    }
  }

  //Component will unmount
  componentWillUnmount() {
    if (this.props.componentWillUnmount) {
      this.props.componentWillUnmount();
    }
  }

  //componentDidCatch(error, info)
  componentDidCatch(error, info) {
    if (this.props.componentDidCatch) {
      this.props.componentDidCatch(error, info);
    }
  }

  //render method
  render() {
    return (
      <Select
        className={s.multiSelect}
        // classNamePrefix="multi"
        IndicatorsContainer={false}
        value={this.props.selectedOptions}
        isClearable={false}
        isMulti
        onChange={this.props.handleChange}
        options={this.props.sensorOptions}
      />
    );
  }
}

MultiselectDropdown.defaultProps = {
  selectedOptions: [],
  dataArray: []
};

export default MultiselectDropdown;
