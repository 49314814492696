import { initialNotificationsState } from './initialState';

import {
  NOTIFICATIONS_ERROR,
  SET_NOTIFICATION_TYPE_USERS,
  NOTIFICATIONS_IS_LOADING,
  SET_NOTIFICATIONS_TYPE_USERS_CHANGE,
  SET_NOTIFICATION_TYPES,
  UPDATE_NOTIFICATION_TYPE_USERS_SAVE_STATUS,
  NOTIFICATIONS_CLEANUP,
  SET_NOTIFICATION_HISTORY,
  SET_NOTIFICATIONS_FOR_BOARD,
  RESET_NOTIFICATION_ALERT,
  SET_NOTIFICATION_HISTORY_CURRENT_PAGE
} from '../constants/index';

const notificationsReducer = (state = initialNotificationsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_NOTIFICATION_TYPES: {
      const { data } = payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          notificationTypes: data.notificationTypes,
          isLoading: data.isLoading,
          displayMessageCode: data.displayMessageCode,
          showBanner: data.showBanner,
          isOpSuccessful: data.isOpSuccessful
        }
      };
    }

    case SET_NOTIFICATION_TYPE_USERS: {
      const { data } = payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          notificationTypeUsers: data.notificationTypeUsers,
          selectedNotificationType: data.selectedNotificationType,
          isLoading: data.isLoading,
          displayMessageCode: data.displayMessageCode,
          showBanner: data.showBanner,
          isOpSuccessful: data.isOpSuccessful
        }
      };
    }

    case UPDATE_NOTIFICATION_TYPE_USERS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...data
        }
      };
    }

    case NOTIFICATIONS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: isLoading
        }
      };
    }

    case NOTIFICATIONS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...data
        }
      };
    }

    case SET_NOTIFICATIONS_TYPE_USERS_CHANGE: {
      let { notificationTypeUsers } = payload;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          isOpSuccessful: false,
          showBanner: false,
          notificationTypeUsers: notificationTypeUsers
        }
      };
    }

    case NOTIFICATIONS_CLEANUP: {
      const { notifications } = payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...notifications
        }
      };
    }

    case SET_NOTIFICATION_HISTORY: {
      const { data } = payload;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          notificationsList: data.notificationsList,
          isLoading: data.isLoading,
          displayMessageCode: data.displayMessageCode,
          showBanner: data.showBanner,
          isOpSuccessful: data.isOpSuccessful
        }
      };
    }

    case SET_NOTIFICATIONS_FOR_BOARD: {
      const { data, isPolling } = payload;
      const newNotifications = [];

      if (isPolling) {
        const currentNotificationList = state.notifications.notificationsHistory;
        const newNotificationList = data.notificationsHistory;
        newNotificationList.forEach(element => {
          if (currentNotificationList.filter(x => x.NotificationQueueId === element.NotificationQueueId).length === 0) {
            newNotifications.push(element);
          }
        });
      }

      return {
        ...state,
        notifications: {
          ...state.notifications,
          notificationsHistory: data.notificationsHistory,
          isLoading: data.isLoading,
          displayMessageCode: data.displayMessageCode,
          showBanner: data.showBanner,
          isOpSuccessful: data.isOpSuccessful,
          showNotificationAlerts: newNotifications
        }
      };
    }

    case RESET_NOTIFICATION_ALERT: {
      const status = payload;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          showNotificationAlerts: []
        }
      };
    }

    case SET_NOTIFICATION_HISTORY_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        unitTagHistory: {
          ...state.unitTagHistory,
          currentPage
        }
      };
    }

    default:
      return state;
  }
};

export default notificationsReducer;
