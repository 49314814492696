import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './TaskTemplateDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialTaskTemplatesState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import { WidgetVisibility } from '../../utils/widgetManager';
import { getQuerystring } from '../../utils';

const TaskTemplateDetail = ({ userProfileWidgets, intl, taskTemplateManager, describeTaskTemplate }) => {
  let { TaskTemplateId } = useParams();

  let checklistTemplateId = getQuerystring('checklistTemplateId') || 0;
  checklistTemplateId = parseInt(checklistTemplateId);
  let isServiceChecklist = getQuerystring('isServiceChecklist') === 'true' ? true : false;

  let fromView = getQuerystring('view') || 0;
  fromView = parseInt(fromView);

  let backUrl = '/admin-controls/checklist-task-templates';
  if (checklistTemplateId) {
    backUrl = `/admin-controls/checklist-template/${checklistTemplateId}${fromView ? '?view=1' : ''}`;
  }

  useEffect(() => {
    if (TaskTemplateId) {
      describeTaskTemplate(TaskTemplateId, 'TASKTEMPLATEVIEW');
    }
  }, [describeTaskTemplate, TaskTemplateId]);

  return (
    <div className={s.taskTemplate}>
      {taskTemplateManager.isLoading && <LoadingSpinner />}

      <AdminControlsContentTemplate selectedPage="checklistTaskTemplate" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.taskTemplateHeader}>
            <Link className={s.backLink} to={backUrl}>
              &lt; &nbsp;
              <FormattedMessage id="taskTemplateManagement.back" defaultMessage="BACK" />
            </Link>
          </div>
          <div className={s.taskTemplateContent}>
            {taskTemplateManager.SelectedTaskTemplate && (
              <Row>
                <Col md={8} className={`${s.borderRight} p-5`}>
                  <h3>{taskTemplateManager.SelectedTaskTemplate.Name}</h3>
                  <p>{taskTemplateManager.SelectedTaskTemplate.Description}</p>
                  {taskTemplateManager.SelectedTaskTemplate.Activities && (
                    <div>
                      <h3>
                        <FormattedMessage id="taskTemplateManagement.activities" defaultMessage="Activities" />
                      </h3>
                      <p>{taskTemplateManager.SelectedTaskTemplate.Activities}</p>
                    </div>
                  )}
                </Col>
                <Col md={4} className="p-5">
                  <h6>
                    <FormattedMessage id="taskTemplateManagement.role" defaultMessage="Role" />
                  </h6>
                  <p>{taskTemplateManager.SelectedTaskTemplate.ProfileName || 'N/A'}</p>
                  <h6>
                    <FormattedMessage id="taskTemplateManagement.serviceTask" defaultMessage="Service task" />
                  </h6>
                  <p>{taskTemplateManager.SelectedTaskTemplate.IsServiceTask ? 'Yes' : 'No' || 'N/A'}</p>

                  <h6 className="pt-2">
                    <FormattedMessage id="taskTemplateManagement.effort" defaultMessage="Effort" />
                  </h6>
                  <span className={s.effort}>
                    <FormattedMessage id="taskTemplateManagement.estimatedEffort" defaultMessage="Estimated effort" />
                  </span>
                  <p>
                    {(taskTemplateManager.SelectedTaskTemplate.EstimatedEffort &&
                      taskTemplateManager.SelectedTaskTemplate.EstimatedEffort + ' hours') ||
                      'N/A'}
                  </p>
                </Col>
              </Row>
            )}
          </div>
          <div className={s.changeButtonDiv} style={WidgetVisibility(userProfileWidgets, `TASKTEMPLATEEDIT`)}>
            <Link
              to={`/admin-controls/task-template/${TaskTemplateId}${
                checklistTemplateId ? '?checklistTemplateId=' + checklistTemplateId + '&isServiceChecklist=' + isServiceChecklist : ''
              }${fromView ? '&view=1' : ''}`}
            >
              <Button className={s.btnChange}>
                <FormattedMessage id="taskTemplateManagement.change" defaultMessage="Change" />
              </Button>
            </Link>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

TaskTemplateDetail.defaultProps = {
  taskTemplateManager: {
    ...initialTaskTemplatesState.taskTemplate
  }
};

TaskTemplateDetail.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired,
  taskTemplateManager: PropTypes.object.isRequired,
  describeTaskTemplate: PropTypes.func.isRequired
};

export default injectIntl(TaskTemplateDetail);
