import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import s from './DeviceList.module.scss';
import bs from '../../styles/bootstrap-overrides.scss';
import DeviceTile from '../DeviceTile/DeviceTile';
import MapView from '../MapView/MapView';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import tableBlue from '../../assets/table-blue.svg';
import tableGrey from '../../assets/table-grey.svg';
import mapGrey from '../../assets/map-grey.svg';
import mapBlue from '../../assets/map-blue.svg';
import alarm from '../../assets/alarm.svg';
import warning from '../../assets/warning-large.svg';
import pendingAlarm from '../../assets/alarm-outline.svg';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { REFRESH_TIME_OTHER } from '../../constants/index';
import { ALARM_STATUS_NEW, ALARM_STATUS_PENDING, ALARM_STATUS_RESOLVED } from '../../constants';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import languageObject from '../../translations/messages';
import blueDownArrow from '../../assets/blue-down-arrow.svg';

const DeviceList = ({
  devices,
  setSelectedUnit,
  setSelectedUnitView,
  redirect,
  getDevicesStatus,
  devicesStatus,
  allowedUnits,
  selectedView,
  getDeviceAlarms,
  deviceAlarms,
  changeTimezone,
  resetDevicetimezone,
  describeUnit,
  flowSequences,
  userProfileWidgets,
  isDeviceListLoading,
  intl
}) => {
  let devicesObj = devices;
  const UNITTILEVIEW = 'UNITTILEVIEW';
  const UNITMAPVIEW = 'UNITMAPVIEW';
  const UNITSCOUNTFORMAPVIEW = 10;

  const isUploadAllowed = IsWidgetAccessible(userProfileWidgets, 'UPLOAD_UNIT');
  const isAddAllowed = IsWidgetAccessible(userProfileWidgets, 'ADD_UNIT');

  const [time, setTime] = React.useState(0);
  const [interval, setInterval] = React.useState(100);
  const [activeView, setActiveView] = useState(selectedView);
  const [sortOrder, setSortOrder] = useState('status');
  const [sortSelection, setSortSelection] = useState(false);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  let isTileViewAvailable = true;
  let isMapViewAvailable = true;
  let statusFetchInterval = REFRESH_TIME_OTHER;

  let devicesWithAddresProps = [];

  if (devicesObj && Array.isArray(devicesObj) && allowedUnits && allowedUnits.userProfileUnits) {
    devicesWithAddresProps = allowedUnits.userProfileUnits.filter(
      allowedUnit => devicesObj.filter(unit => allowedUnit.OrganisationId == OrganisationId && allowedUnit.UnitId == unit.UnitId).length > 0
    );
  } else {
    devicesObj = [];
  }

  selectedView = selectedView ? selectedView : devicesObj.length >= UNITSCOUNTFORMAPVIEW ? UNITMAPVIEW : UNITTILEVIEW;
  if (selectedView !== activeView) {
    setActiveView(selectedView);
  }

  let unitSerialNumbers = devicesObj.map(device => device.unitSerialNumber);

  const toDate = moment().valueOf();
  const fromDate = moment()
    .subtract(1, 'day')
    .valueOf();

  if (time > 50 && interval !== statusFetchInterval) {
    setInterval(statusFetchInterval);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (unitSerialNumbers.length > 0) {
        getDevicesStatus(unitSerialNumbers, fromDate, toDate);
        getDeviceAlarms(unitSerialNumbers, ALARM_STATUS_RESOLVED);
      }

      if (unitSerialNumbers.length > 0 && interval !== statusFetchInterval) {
        setInterval(statusFetchInterval);
      }

      setTime(time + 1);
    }, interval);

    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  //reset the timezone back to the selected timezone in account settings or local timezone
  let defaultTimezone = Cookies.get(`defaultTimezone-${envName}`);
  if (defaultTimezone === undefined) {
    defaultTimezone = moment.tz.guess();
  }

  let widgetCode = 'EMPTY';

  if (IsWidgetAccessible(userProfileWidgets, 'DEVICESCHEMATICDIAGRAM')) {
    widgetCode = 'DEVICESCHEMATICDIAGRAM';
  } else if (IsWidgetAccessible(userProfileWidgets, 'REALTIMESENSORS')) {
    widgetCode = 'REALTIMESENSORS';
  } else if (IsWidgetAccessible(userProfileWidgets, 'EVENTLOGSUMMARY')) {
    widgetCode = 'EVENTLOGSUMMARY';
  } else if (IsWidgetAccessible(userProfileWidgets, 'UNIT_CONFIGURATION')) {
    widgetCode = 'UNIT_CONFIGURATION';
  } else if (IsWidgetAccessible(userProfileWidgets, 'DATATRENDANALYSIS')) {
    widgetCode = 'DATATRENDANALYSIS';
  } else if (IsWidgetAccessible(userProfileWidgets, 'UNITREPORT')) {
    widgetCode = 'UNITREPORT';
  } else if (IsWidgetAccessible(userProfileWidgets, 'UNIT_SCHEDULE')) {
    widgetCode = 'UNIT_SCHEDULE';
  } else if (IsWidgetAccessible(userProfileWidgets, 'DAILY_SUMMARY_DATA')) {
    widgetCode = 'DAILY_SUMMARY_DATA';
  }

  const handleClick = unitSerialNumber => {
    setSelectedUnit(unitSerialNumber);
    changeTimezone(defaultTimezone);
    resetDevicetimezone();
    describeUnit(unitSerialNumber, widgetCode);
    redirect(`/unit/process-flow/${unitSerialNumber}`);
  };

  const [unitsOpen, setUnitsOpen] = useState(true);

  if (devicesStatus && devicesStatus.events) {
    //sorting events
    devicesStatus.events.forEach((event, index) => {
      event.events.sort((a, b) => {
        return a.timestamp < b.timestamp ? 1 : -1;
      });
    });
  }

  let warnings = 0;

  devicesStatus &&
    devicesStatus.events &&
    devicesStatus.events.forEach(event => {
      warnings += event.events && event.events.filter(x => x.type === 1).length;
    });

  const newAlarms =
    deviceAlarms &&
    deviceAlarms.filter(event => {
      return event.EventStatus === ALARM_STATUS_NEW;
    });

  const pendingAlarms =
    deviceAlarms &&
    deviceAlarms.filter(event => {
      return event.EventStatus === ALARM_STATUS_PENDING;
    });

  const handleTabClick = tab => {
    setSelectedUnitView(tab);
    setActiveView(tab);
  };

  const sortDevices = devices => {
    let retData = [];
    switch (sortOrder) {
      case 'status': {
        let mappedDevices = devices.map(item => {
          return {
            ...item,
            newAlarmCount: deviceAlarms
              .filter(x => x.UnitSerialNumber === item.unitSerialNumber)
              .filter(elem => elem.EventStatus === ALARM_STATUS_NEW).length,
            pendingAlarmCount: deviceAlarms
              .filter(x => x.UnitSerialNumber === item.unitSerialNumber)
              .filter(elem => elem.EventStatus === ALARM_STATUS_PENDING).length,
            warningCount:
              (devicesStatus &&
                devicesStatus.events &&
                devicesStatus.events.find(event => event.UnitSerialNumber === item.unitSerialNumber) &&
                devicesStatus.events.find(event => event.UnitSerialNumber === item.unitSerialNumber).events.filter(elem => elem.type === 1)
                  .length) ||
              0
          };
        });

        retData = mappedDevices.sort((a, b) => {
          if (a.newAlarmCount < b.newAlarmCount) {
            return 1;
          } else if (a.newAlarmCount === b.newAlarmCount && a.pendingAlarmCount < b.pendingAlarmCount) {
            return 1;
          } else if (
            a.newAlarmCount === b.newAlarmCount &&
            a.pendingAlarmCount === b.pendingAlarmCount &&
            a.warningCount < b.warningCount
          ) {
            return 1;
          } else if (
            a.newAlarmCount === b.newAlarmCount &&
            a.pendingAlarmCount === b.pendingAlarmCount &&
            a.warningCount === b.warningCount
          ) {
            return 0;
          } else {
            return -1;
          }
        });

        break;
      }
      case 'name': {
        retData = devices.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        break;
      }
      default: {
      }
    }
    return retData;
  };

  const onSortItemChange = item => {
    setSortOrder(item.Value);
    setSortSelection(false);
  };

  const locale = Cookies.get(`defaultLanguage-${envName}`) || 'en';
  return (
    <IntlProvider locale={locale} messages={languageObject[locale]}>
      <div>
        {isDeviceListLoading && <LoadingSpinner />}

        <Row className={s.titleRows}>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={unitsOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setUnitsOpen(!unitsOpen)}
                aria-controls="units-section"
                aria-expanded={unitsOpen}
              ></img>
              <h3>
                <FormattedMessage id="deviceList.myUnits" defaultMessage="My Units" />
              </h3>
              <span className={s.circle}>{devicesObj.length}</span>
              {activeView === UNITTILEVIEW ? (
                <Row className={s.sortOption}>
                  <h4 className={s.subHeading} onClick={() => setSortSelection(sortSelection ? false : true)}>
                    {sortOrder === 'name' ? (
                      <FormattedMessage id="deviceList.sortByName" defaultMessage="Sort by: Name" />
                    ) : (
                      <FormattedMessage id="deviceList.sortByStatus" defaultMessage="Sort by: Status" />
                    )}
                  </h4>
                  <img src={blueDownArrow} onClick={() => setSortSelection(sortSelection ? false : true)}></img>
                </Row>
              ) : (
                ''
              )}
            </Row>
            {sortSelection && (
              <Row>
                <Col>
                  <SortOptions
                    onSortItemChange={onSortItemChange}
                    intl={intl}
                    setSortSelection={setSortSelection}
                    sortItems={[
                      { Name: 'Name', Value: 'name' },
                      { Name: 'Status', Value: 'status' }
                    ]}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col md={6} className={unitsOpen ? 'd-none d-md-block' : 'd-none'}>
            {isTileViewAvailable && isMapViewAvailable ? (
              <div className="float-right row">
                <div
                  className={`mr-2 ${s.link}`}
                  onClick={() => {
                    handleTabClick(UNITTILEVIEW);
                  }}
                >
                  <img src={activeView === UNITTILEVIEW ? tableBlue : tableGrey} alt="Table view"></img>
                  <span className={activeView === UNITTILEVIEW ? s.active : s.nonActive}>
                    <FormattedMessage id="deviceList.tileView" defaultMessage="Tile view" />
                  </span>
                </div>
                <div className={s.pipeSeparator}></div>
                <div
                  className={`ml-2 ${s.link}`}
                  onClick={() => {
                    handleTabClick(UNITMAPVIEW);
                  }}
                >
                  <img src={activeView === UNITTILEVIEW ? mapGrey : mapBlue} alt="Map view"></img>
                  <span className={activeView === UNITTILEVIEW ? s.nonActive : s.active}>
                    <FormattedMessage id="deviceList.mapView" defaultMessage="Map view" />
                  </span>
                </div>
              </div>
            ) : (
              <div />
            )}
          </Col>
        </Row>

        <Collapse in={unitsOpen}>
          <div id="units-section">
            <Row>
              <Col className={s.horizontalLine}>
                <hr />
              </Col>
            </Row>

            <Row className={s.alertsRow}>
              <Col lg={6} xs={12}>
                <div className="row">
                  <div className="mr-3 col-xs-12">
                    <img src={alarm} alt="New Alarm"></img>
                    <span>
                      <FormattedMessage id="deviceList.newAlarms" defaultMessage="New alarms" /> ({newAlarms && newAlarms.length})
                    </span>
                  </div>
                  <div className="mr-3 col-xs-12">
                    <img src={pendingAlarm} alt="Pending Alarm"></img>
                    <span>
                      <FormattedMessage id="deviceList.pendingAlarms" defaultMessage="Pending alarms" /> (
                      {pendingAlarms && pendingAlarms.length})
                    </span>
                  </div>
                  <div className="col-xs-12">
                    <img src={warning} alt="Warning"></img>
                    <span>
                      <FormattedMessage id="deviceList.warnings" defaultMessage="Warnings in the last 24h" /> ({warnings})
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={6} className={'d-none d-md-block'}>
                <div className={`${s.buttonSpace} float-right  row mr-1`}>
                  {isAddAllowed && (
                    <Link to="/unit/add">
                      <Button variant="primary" className={s.btnSize}>
                        <FormattedMessage id="deviceList.addUnit" defaultMessage="Add New Unit" />
                      </Button>
                    </Link>
                  )}
                </div>
                <div className="float-right  row mr-1">
                  {isUploadAllowed && (
                    <Link to="/unit/unit-upload">
                      <Button variant="primary" className={s.btnSize}>
                        <FormattedMessage id="deviceList.uploadUnit" defaultMessage="Upload New Unit" />
                      </Button>
                    </Link>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col className={s.horizontalLine}>
                <hr />
              </Col>
            </Row>

            {/* MOBILE VIEW START */}
            {isTileViewAvailable && isMapViewAvailable && (
              <div>
                <Row className={s.hideDesktop}>
                  <Col xs={3} className={s.viewMap}>
                    <Button variant="outline-secondary" className={s.mapButton} href="/unit/add">
                      <FormattedMessage id="deviceList.addUnit" defaultMessage="Add New Unit" />
                    </Button>
                  </Col>
                  <Col xs={3} className={s.viewMap}>
                    <Button variant="outline-secondary" className={s.mapButton} href="/unit/unit-upload">
                      <FormattedMessage id="deviceList.uploadUnit" defaultMessage="Upload New Unit" />
                    </Button>
                  </Col>
                  <Col xs={6} className={s.viewMap}>
                    {activeView !== UNITTILEVIEW && (
                      <Button
                        className={s.mapButton}
                        variant="outline-secondary"
                        onClick={() => {
                          handleTabClick(UNITTILEVIEW);
                        }}
                      >
                        <FormattedMessage id="deviceList.viewOnTile" defaultMessage="TILE VIEW" />
                      </Button>
                    )}
                    {activeView === UNITTILEVIEW && (
                      <Button
                        className={s.mapButton}
                        variant="outline-secondary"
                        onClick={() => {
                          handleTabClick(UNITMAPVIEW);
                        }}
                      >
                        <FormattedMessage id="deviceList.viewOnMap" defaultMessage="VIEW ON MAP" />
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className={s.hideDesktop}>
                  <Col className={s.horizontalLine}>
                    <hr />
                  </Col>
                </Row>
              </div>
            )}
            {/* MOBILE VIEW END */}

            {activeView === UNITTILEVIEW ? (
              <ul className={s.deviceWrapper}>
                {devicesObj.length > 0 &&
                  sortDevices(
                    devicesObj
                  ).map(
                    ({
                      location,
                      country,
                      isTaggedOut,
                      tagOutByUser,
                      SchedulesCount,
                      Name,
                      unitSerialNumber,
                      DeviceSerialNumber,
                      model,
                      modelVersion
                    }) => (
                      <DeviceTile
                        key={unitSerialNumber}
                        unitSerialNumber={unitSerialNumber}
                        deviceSerialNumber={DeviceSerialNumber}
                        unitName={Name}
                        location={location}
                        country={country}
                        model={model}
                        modelVersion={modelVersion}
                        isTaggedOut={isTaggedOut || 0}
                        isSchedulesCount={SchedulesCount > 0 ? true : false}
                        tagOutMessage={
                          intl.formatMessage({ id: 'maintenance.tagout.isTaggedoutBy', defaultMessage: 'Tagged out by ' }) +
                          (tagOutByUser || unitSerialNumber)
                        }
                        scheduleMessage={intl.formatMessage({ id: 'deviceTile.schedule', defaultMessage: 'Unit has a schedule' })}
                        handleClick={handleClick}
                        deviceSensorData={
                          devicesStatus &&
                          devicesStatus.deviceSensorReadings &&
                          devicesStatus.deviceSensorReadings.filter(sensor => sensor.unitserialnumber === unitSerialNumber)
                        }
                        deviceEventData={
                          devicesStatus &&
                          devicesStatus.events &&
                          devicesStatus.events.filter(event => event.unitSerialNumber === unitSerialNumber)
                        }
                        deviceAlarmsData={deviceAlarms && deviceAlarms.filter(x => x.UnitSerialNumber === unitSerialNumber)}
                        flowSequences={flowSequences}
                        intl={intl}
                      />
                    )
                  )}
              </ul>
            ) : (
              <span />
            )}

            {activeView !== UNITTILEVIEW ? (
              <div className={s.deviceWrapper}>
                {devicesWithAddresProps.length > 0 && (
                  <MapView
                    devices={devicesWithAddresProps}
                    handleClick={handleClick}
                    sensorData={(devicesStatus && devicesStatus.deviceSensorReadings) || []}
                    eventData={(devicesStatus && devicesStatus.events) || []}
                    alarmsData={deviceAlarms}
                    flowSequences={flowSequences}
                    intl={intl}
                  />
                )}
              </div>
            ) : (
              <div />
            )}
          </div>
        </Collapse>
      </div>
    </IntlProvider>
  );
};

const SortOptions = ({ intl, sortItems, onSortItemChange, setSortSelection }) => {
  const clickRef = useRef();

  const handleClickOutside = e => {
    if (!clickRef.current.contains(e.target)) {
      setSortSelection(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  return (
    <div className={s.options} ref={clickRef}>
      {sortItems.map((elem, index) => {
        return (
          <Row
            onClick={() => {
              onSortItemChange(elem);
            }}
            key={index}
            className={[s.rows, index === 0 ? s.noBorder : '']}
          >
            <Col>{elem.Name}</Col>
          </Row>
        );
      })}
    </div>
  );
};

DeviceList.defaultProps = {
  devices: [],
  allowedUnits: {
    userProfileUnits: []
  },
  deviceAlarms: [],
  flowSequences: []
};

DeviceList.propTypes = {
  devices: PropTypes.array,
  redirect: PropTypes.func.isRequired,
  setSelectedUnit: PropTypes.func.isRequired,
  changeTimezone: PropTypes.func.isRequired,
  describeUnit: PropTypes.func.isRequired,
  devicesStatus: PropTypes.object,
  allowedUnits: PropTypes.object,
  resetDevicetimezone: PropTypes.func,
  getDeviceAlarms: PropTypes.func.isRequired,
  deviceAlarms: PropTypes.array,
  flowSequences: PropTypes.array.isRequired
};

export default injectIntl(DeviceList);
