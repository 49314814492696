import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionUnits.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelVersionState } from '../../reducers/initialState';
import ModelVersionUnitsListTable from './ModelVersionUnitsListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import Cookies from 'js-cookie';

const ModelVersionUnitsList = ({
  modelVersionUnitManager,
  intl,
  userProfileWidgets,
  getModelVersionUnits,
  saveModelVersionUnits,
  setModelVersionUnitsChange,
  setModelVersionUnitsScreenStatus
}) => {
  const [currentEditId, setCurrentEditId] = useState(-1);
  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [searchText, setSearchText] = useState('');

  let { ModelVersionId, ModelId } = useParams();

  let filter = modelVersionUnitManager.filter || '';
  let offset = modelVersionUnitManager.offset || 0;
  let limit = modelVersionUnitManager.limit || 1000;
  let initialLimit = 6;
  let initialRecords = modelVersionUnitManager.ModelVersionUnitsList.slice(0, initialLimit);

  useEffect(() => {
    let item = {
      Offset: offset,
      Limit: limit,
      Filter: filter,
      ModelVersionId: ModelVersionId,
      ModelId: ModelId
    };
    getModelVersionUnits(item);
  }, [getModelVersionUnits, ModelId, ModelVersionId, filter]);

  const localSettingLength = localSettings.length;
  const componentPositionLength = modelVersionUnitManager.ModelVersionUnitsList.length; //

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(modelVersionUnitManager.ModelVersionUnitsList);
    }
    setShowAll(!showAll);
  };

  const disableModeHandler = status => {
    setModelVersionUnitsScreenStatus(status);
  };

  const cancelHandler = status => {
    setModelVersionUnitsScreenStatus(false);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = modelVersionUnitManager.ModelVersionUnitsList.filter(function(item) {
      return item.UnitNumber.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const setAddHandler = () => {
    setModelVersionUnitsScreenStatus(true);
    //     // setCurrentEditId(0);
  };

  const filterData = () => {
    let filteredList = modelVersionUnitManager.ModelVersionUnitsList;
    if (isFilter) {
      filteredList = modelVersionUnitManager.ModelVersionUnitsList.filter(function(item) {
        return item.UnitNumber.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getUnitsData = () => {
    let modelVersionUnitData = [];
    if (showAll) {
      modelVersionUnitData = filterData();
    } else {
      if (isFilter) {
        modelVersionUnitData = localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        modelVersionUnitData = initialRecords;
      }
    }
    return modelVersionUnitData.sort(compareValues('UnitNumber'));
  };

  let messageId = (modelVersionUnitManager && modelVersionUnitManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'modelVersion.units.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelVersion.units.showLess', defaultMessage: 'SHOW LESS' });

  const isAddAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSION_UNIT_ALLOCATE');

  return (
    <div className={s.modelVersionUnit}>
      {modelVersionUnitManager.isLoading && <LoadingSpinner />}
      {modelVersionUnitManager.showBanner && (
        <Banner
          failureText={messageText}
          showBanner={modelVersionUnitManager.showBanner}
          status={modelVersionUnitManager.isOpSuccessfull}
          successText={messageText}
        />
      )}

      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              disabled={modelVersionUnitManager.showAddEditScreen}
              // initialText={filter}
              placeHolderTextId="modelVersion.units.search"
              data-unittest="searchUnits"
            />
          </Col>
          <Col sm={6} lg={8}>
            <Button
              className={s.addbutton}
              style={WidgetVisibility(userProfileWidgets, 'MODELVERSION_UNIT_ALLOCATE')}
              onClick={setAddHandler}
              disabled={!isAddAllowed && modelVersionUnitManager.showAddEditScreen}
              data-unittest="allocateUnits"
            >
              + <FormattedMessage id="modelVersion.units.allocateUnits" defaultMessage="ALLOCATE UNITS" />
            </Button>
          </Col>
        </Row>

        <div>
          <ModelVersionUnitsListTable
            modelVersionUnitManager={modelVersionUnitManager}
            ModelId={ModelId}
            allocatedUnits={getUnitsData()}
            saveModelVersionUnits={saveModelVersionUnits}
            setModelVersionUnitsChange={setModelVersionUnitsChange}
            disableMode={modelVersionUnitManager.showAddEditScreen}
            cancelHandler={cancelHandler}
            userProfileWidgets={userProfileWidgets}
          />
        </div>
        {((!isFilter && componentPositionLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? componentPositionLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

ModelVersionUnitsList.defaultProps = {
  ...initialModelVersionState.modelVersionManager.selectedModelVersion.ModelVersionUnit
};

ModelVersionUnitsList.propTypes = {
  modelVersionUnitManager: PropTypes.object.isRequired,
  userProfileWidgets: PropTypes.object.isRequired,
  getModelVersionUnits: PropTypes.func.isRequired,
  saveModelVersionUnits: PropTypes.func.isRequired,
  setModelVersionUnitsChange: PropTypes.func.isRequired,
  setModelVersionUnitsScreenStatus: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionUnitsList);
