import React, { useState } from 'react';
import s from './Calibration.module.scss';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Activity from '../WSAControls/Activity/Activity';
import uuidv4 from 'uuid/v4';
import Cookies from 'js-cookie';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const CalibrationComments = ({ calibrationManager, unitSerialNumber, saveCalibrationComment, UpdateAllowed, intl, currentTimezone }) => {
  const [calibrationComment, setCalibrationComment] = useState('');
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const commentChangeHandler = e => {
    setCalibrationComment(e.target.value);
  };

  const commentSaveHandler = () => {
    if (calibrationComment.length > 0) {
      const calibrationConstant = [
        {
          GuidIdentifier: uuidv4(),
          Description: calibrationComment,
          IsActive: true,
          UpdatedBy: Cookies.get(`userid-${envName}`) || null
        }
      ];

      const saveData = {
        CalibrationId: calibrationManager.SelectedCalibration.CalibrationId,
        CalibrationComments: calibrationConstant
      };
      saveCalibrationComment(saveData, unitSerialNumber, 'CALIBRATION_COMMENT_ADD');
      setCalibrationComment('');
    }
  };

  const commentCancelHandler = () => {
    setCalibrationComment('');
  };

  const comments = () => {
    let commentstData = calibrationManager.SelectedCalibration.CalibrationComments || [];

    if (commentstData && commentstData.length > 0) {
      commentstData = commentstData.map(comment => ({ ...comment, Id: comment.CalibrationCommentId }));
    }

    return commentstData;
  };

  const headertext = '';

  return (
    <div className={s.calibration}>
      <div className={s.contentWrapper}>
        <div className={s.calibrationComment}>
          {calibrationManager.isLoading && <LoadingSpinner centeredLoading={false} />}
          <Row className={s.breakrow}>
            <Col sm={12}>
              <h4>
                <FormattedMessage id="calibration.comments" defaultMessage="Comments" />
                <span data-unittest="calibrationDataCount" className={s.circle}>
                  {calibrationManager.SelectedCalibration.CalibrationComments.length}
                </span>
              </h4>
            </Col>
          </Row>
          <Row className={s.breakrow}>
            <Col sm={12}>
              <Activity
                description={calibrationComment || ''}
                onActivityModified={commentChangeHandler}
                onSaveClick={commentSaveHandler}
                onCancelClick={commentCancelHandler}
                currentTimezone={currentTimezone}
                activities={comments()}
                isUpdateAllowed={UpdateAllowed}
                isViewAllowed={true}
                headingText={headertext}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CalibrationComments;
