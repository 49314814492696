import { initialTelemetryPartsState } from './initialState';

import {
  TELEMETRYPARTS_ERROR,
  GET_ALL_TELEMETRYPARTS_DETAILS,
  TELEMETRYPARTS_IS_LOADING,
  SET_TELEMETRYPARTS_CURRENT_PAGE,
  SET_TELEMETRYPARTS_PAGE_FILTER,
  SET_TELEMETRYPARTS_FILTERED_RESULTS,
  SET_TELEMETRYPARTS_FILTERS,
  TELEMETRYPARTS_CLEANUP,
  START_TELEMETRYPARTS_EXPORT,
  SET_TELEMETRYPARTS_EXPORT_DATA,
  SET_COMPUTE_TELEMETRYPARTS_EXPORT
} from '../constants/index';

const telemetryPartsReducer = (state = initialTelemetryPartsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_TELEMETRYPARTS_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          ...data
        }
      };
    }

    case SET_TELEMETRYPARTS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          currentPage
        }
      };
    }

    case SET_TELEMETRYPARTS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          filterBy: {
            ...state.telemetryParts.filterBy,
            ...filter
          }
        }
      };
    }

    case TELEMETRYPARTS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          isLoading: isLoading
        }
      };
    }

    case TELEMETRYPARTS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          ...data
        }
      };
    }

    case SET_TELEMETRYPARTS_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          ...filteredResults
        }
      };
    }

    case SET_TELEMETRYPARTS_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          ...data
        }
      };
    }

    case TELEMETRYPARTS_CLEANUP: {
      const { telemetryParts } = payload;
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          ...telemetryParts
        }
      };
    }
    case START_TELEMETRYPARTS_EXPORT: {
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          exportedResults: [],
          exportComplete: false,
          exportCompute: false,
          isLoading: true
        }
      };
    }
    case SET_TELEMETRYPARTS_EXPORT_DATA: {
      const { data } = payload;
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          ...data
        }
      };
    }
    case SET_COMPUTE_TELEMETRYPARTS_EXPORT: {
      const { status } = payload;
      return {
        ...state,
        telemetryParts: {
          ...state.telemetryParts,
          exportCompute: status,
          isLoading: false
        }
      };
    }

    default:
      return state;
  }
};

export default telemetryPartsReducer;
