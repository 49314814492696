import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ComponentPositionModel.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelState } from '../../reducers/initialState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import Cookies from 'js-cookie';
import uuidv4 from 'uuid/v4';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';

const ComponentPositionModel = ({
  ModelId,
  saveComponentPosition,
  ComponentPositionsManager,
  setComponentPositionChange,
  modelComponentPositions,
  cancelHandler,
  intl
}) => {
  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  // Update redux store
  const setLocalComponentPositionChange = currentState => {
    setComponentPositionChange(currentState);
  };

  //clean up
  useEffect(() => {
    return () => {
      setLocalComponentPositionChange({
        selectedcomponentPositionModel: {
          ...ComponentPositionsManager.selectedcomponentPositionModel,
          ModelComponentPositionId: 0,
          ModelId: 0
        }
      });
    };
  }, []);

  const postionOptions = () => {
    let postionData = [];
    modelComponentPositions.forEach(element => {
      if (
        ComponentPositionsManager &&
        ComponentPositionsManager.componentPositionModels.filter(x => x.ModelComponentPositionId === element.ModelComponentPositionId)
          .length === 1
      ) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }
      postionData.push({
        ...element,
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return postionData.sort(compareValues('Name'));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = ComponentPositionsManager.selectedcomponentPositionModel;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    saveComponentPosition(saveData);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !ComponentPositionsManager ||
      !ComponentPositionsManager.selectedcomponentPositionModel ||
      !ComponentPositionsManager.selectedcomponentPositionModel.ModelComponentPositionId ||
      isRequired(ComponentPositionsManager.selectedcomponentPositionModel.ModelComponentPositionId, 1)
    ) {
      formErrors.ModelComponentPositionId = intl.formatMessage({
        id: 'model.componentpositions.modelComponentPositionRequired',
        defaultMessage: 'Model component position is requried'
      });
      isValid = false;
    }

    if (
      ComponentPositionsManager &&
      ComponentPositionsManager.selectedcomponentPositionModel &&
      ComponentPositionsManager.selectedcomponentPositionModel.IsEdgeDevice
    ) {
      let edgeDevices = ComponentPositionsManager.componentPositionModels.filter(x => x.IsEdgeDevice);
      if (edgeDevices && edgeDevices.length) {
        formErrors.IsEdgeDevice = intl.formatMessage({
          id: 'model.componentpositions.multipleEdgeDevicesDetected',
          defaultMessage: 'Multiple edge devices detected'
        });
        isValid = false;
      }
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onDropdownChange = e => {
    setLocalComponentPositionChange({
      selectedcomponentPositionModel: {
        ...ComponentPositionsManager.selectedcomponentPositionModel,
        ModelComponentPositionId: e.value,
        ModelId: parseInt(ModelId)
      }
    });
  };

  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalComponentPositionChange({
        selectedcomponentPositionModel: {
          ...ComponentPositionsManager.selectedcomponentPositionModel,
          [e.target.name]: e.target.checked
        }
      });
    }
  };

  let ddlClass = localformErrors && localformErrors.ModelComponentPositionId ? s.ddlError : '';
  let chkClass = localformErrors && localformErrors.IsEdgeDevice ? s.ddlError : s.margin;

  return (
    <div className={s.componentPosition}>
      <div className={s.contentWrapper}>
        <Form>
          <div className={s.topRow}>
            <Row>
              <Col lg={3}>
                <Form.Group controlId="formComponentPositionName">
                  <Form.Label>
                    <FormattedMessage
                      id="model.componentpositions.selectModelComponentPosition"
                      defaultMessage="Select model component position"
                    />
                  </Form.Label>
                  <Dropdown
                    id="formComponentPositionName"
                    dataArray={postionOptions()}
                    controlData={{ placeholderText: 'Please select one', customClassName: ddlClass }}
                    onDropdownChange={onDropdownChange}
                    selectedOption={postionOptions().filter(
                      option => option.value === ComponentPositionsManager.selectedcomponentPositionModel.ModelComponentPositionId
                    )}
                    data-unittest="formComponentPosition"
                  />
                  {localformErrors && localformErrors.ModelComponentPositionId && (
                    <p role="alert" className={s.error}>
                      {localformErrors.ModelComponentPositionId}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={9}>
                <Form.Group controlId="formComponentPositionIsEdgeDevice" className={s.paddingTop}>
                  <Checkbox
                    key={uuidv4()}
                    dataArray={[
                      {
                        SKEY: 'IsEdgeDevice',
                        target: { type: 'checkbox' },
                        label: intl.formatMessage({
                          id: 'model.componentpositions.selectModelComponentPosition.edgeDevice',
                          defaultMessage: 'Edge Device'
                        }),
                        isChecked: ComponentPositionsManager.selectedcomponentPositionModel.IsEdgeDevice
                      }
                    ]}
                    onSelectionChange={onChange}
                    containerClass={chkClass}
                  />
                  {localformErrors && localformErrors.IsEdgeDevice && (
                    <p role="alert" className={s.error}>
                      {localformErrors.IsEdgeDevice}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="model.componentpositions.save" defaultMessage="SAVE" />
                </Button>
                <Button
                  variant="outline-secondary"
                  className={classNames(s.btnCancel)}
                  onClick={cancelHandler}
                  noValidate
                  data-unittest="saveCancel"
                >
                  <FormattedMessage id="model.componentpositions.cancel" defaultMessage="CANCEL" />
                </Button>
              </Col>
              <Col lg={6}></Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

ComponentPositionModel.defaultProps = {
  ComponentPositionsManager: {
    ...initialModelState.modelManager.selectedModel.ComponentPositionModel
  }
};

ComponentPositionModel.propTypes = {
  saveComponentPosition: PropTypes.func.isRequired,
  setComponentPositionChange: PropTypes.func.isRequired,
  ComponentPositionsManager: PropTypes.object.isRequired,
  modelComponentPositions: PropTypes.array,
  cancelHandler: PropTypes.func.isRequired
};

export default injectIntl(ComponentPositionModel);
