import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import UnitStatistics from '../UnitStatistics/UnitStatistics';

const DashboardStatistics = ({ intl, devices }) => {
  return (
    <div>
      <UnitStatistics fromDashboard={true} devices={devices} />
    </div>
  );
};

export default injectIntl(DashboardStatistics);
