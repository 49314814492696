import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import s from './Layout.module.scss';
import expandBlue from '../../assets/expand-blue.svg';
import notification from '../../assets/notification.svg';
import { TEST_ENV, STAGING_ENV, PRODUCTION_ENV, ELEIOT_ENV, REFRESH_NOTIFICATION } from '../../constants/index';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import Sidebar from '../Sidebar/SidebarContainer';
import classNames from 'classnames';
import { WidgetVisibility } from '../../utils/widgetManager';
import NoticeBoard from '../Notifications/NoticeBoard/NoticeBoard';
import { getNotifications } from '../../selectors/index';
import NotificationAlert from '../Notifications/NotificationAlert/NotificationAlert';

const Layout = ({
  isAuthenticated,
  children,
  logout,
  currentSidebarOpen,
  handleViewSidebar,
  doNotificationPolling,
  resetNotificationAlert,
  location,
  userProfileWidgets,
  intl
}) => {
  const notifications = useSelector(state => getNotifications(state));

  const sidebarClass = currentSidebarOpen ? '' : s.active;
  const [show, setShow] = useState(false);
  const [time, setTime] = React.useState(0);
  const [interval, setInterval] = React.useState(100);

  let statusFetchInterval = REFRESH_NOTIFICATION;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const envName = process.env.REACT_APP_ENV_NAME;
  let showAlert = false;
  let alertMessage = '';
  let alertType = 'info';

  let envClass = s.bgColorDev;
  if (envName === TEST_ENV) {
    envClass = s.bgColorTest;
  } else if (envName === STAGING_ENV) {
    envClass = s.bgColorStage;
  }

  if (notifications.showNotificationAlerts.length > 0) {
    if (notifications.showNotificationAlerts.length > 1) {
      alertMessage = intl.formatMessage({
        id: 'notifications.numberOfAlerts',
        defaultMessage: 'You have number of alerts. Please check your notice board'
      });
    } else {
      alertMessage = notifications.showNotificationAlerts[0].Message;
      alertType = notifications.showNotificationAlerts[0].AlertType;
    }
    showAlert = true;
    //Remove the alert
    setTimeout(resetNotificationAlert, 8000);
  }

  const closeAlertHandler = () => {
    resetNotificationAlert();
  };

  //Notification time
  useEffect(() => {
    const timer = setTimeout(() => {
      doNotificationPolling();

      if (interval !== statusFetchInterval) {
        setInterval(statusFetchInterval);
      }

      setTime(time + 1);
    }, interval);

    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  const imgClass = !show ? s.showImage : s.hideImage;

  return (
    <div>
      {envName !== PRODUCTION_ENV && envName !== ELEIOT_ENV && envName !== STAGING_ENV && (
        <div className={`${s.envBar} ${envClass}`}>You are in {process.env.REACT_APP_ENV_NAME} environment</div>
      )}

      <div className={s.wrapper}>
        <Sidebar logout={logout} location={location} />
        <div className={s.contentWrapper}>
          <nav className={classNames(`${s.hideDesktop} navbar navbar-expand-lg`)}></nav>
          <div className={classNames(sidebarClass, s.overlay)} onClick={() => handleViewSidebar(true)}></div>
          {children}

          <div className="container-fluid">
            <img
              src={expandBlue}
              alt="Collapse"
              className={classNames(s.sidebarCollapse, s.hideDesktop)}
              onClick={() => handleViewSidebar(false)}
            />
          </div>

          <div className={s.noticeContainer}>
            <div className={s.noticeBoard} style={WidgetVisibility(userProfileWidgets, 'NOTICEBOARD')}>
              <img src={notification} alt="Notification" onClick={handleShow} className={imgClass}></img>
            </div>
            <div className={classNames(s.circle, imgClass)}> {notifications.notificationsHistory.length}</div>
          </div>
        </div>
      </div>

      <NoticeBoard show={show} handleClose={handleClose} />
      <NotificationAlert showBanner={showAlert} status={alertType} message={alertMessage} closeHandler={closeAlertHandler} />
    </div>
  );
};

Layout.defaultProps = {
  isAuthenticated: false
};

Layout.propTypes = {
  isAuthenticated: PropTypes.bool,
  logout: PropTypes.func,
  handleViewSidebar: PropTypes.func,
  currentSidebarOpen: PropTypes.bool,
  userProfileWidgets: PropTypes.object
};

export default injectIntl(Layout);
