import React, { Fragment, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import s from './UnitConfigurationParts.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { Link, useParams } from 'react-router-dom';
import UnitConfigurationPart from './UnitConfigurationPart';

const UnitConfigurationPartsList = ({
  unitConfiguration,
  partsData,
  userProfileWidgets,
  disableMode,
  disableModeHandler,
  saveComponentPart,
  changeComponentPart,
  setEditHandler,
  selectedComponentPartId,
  modelComponentPositions,
  UnitId,
  intl
}) => {
  let { unitSerialNumber } = useParams();

  const fetchDataHandler = (componentPartId, item) => {
    setEditHandler(componentPartId, item);
    disableModeHandler(true);
  };

  const cancelHandler = () => {
    setEditHandler(-1);
    disableModeHandler(false);
  };

  useLayoutEffect(() => {
    if (disableMode) {
      const scrollPos = window.scrollY + window.innerHeight;
      window.scrollTo(0, scrollPos);
    }
  }, [disableMode]);

  const editComponentPartContent = (
    <UnitConfigurationPart
      OldComponentPartId={selectedComponentPartId}
      saveComponentPart={saveComponentPart}
      changeComponentPart={changeComponentPart}
      unitConfiguration={unitConfiguration}
      cancelHandler={cancelHandler}
      modelComponentPositions={modelComponentPositions}
      UnitId={UnitId}
      currentEditId={selectedComponentPartId}
    />
  );

  let editorMain = (
    <tr>
      <td colSpan="10">{editComponentPartContent}</td>
    </tr>
  );

  let newRowClass = unitConfiguration.componentParts.showAddEditScreen && selectedComponentPartId === 0 ? s.newRow : '';

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="componentParts.modelComponentPosition" defaultMessage="Model Component Position" />
            </th>
            <th>
              <FormattedMessage id="componentParts.brand" defaultMessage="Brand" />
            </th>
            <th>
              <FormattedMessage id="componentParts.model" defaultMessage="Model" />
            </th>
            <th>
              <FormattedMessage id="componentParts.serialNumber" defaultMessage="Serial Number" />
            </th>
            <th>
              <FormattedMessage id="componentParts.componentType" defaultMessage="Component Type" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {partsData.map(n => {
            return (
              <Fragment key={n.ComponentPartId}>
                <tr key={n.ComponentPartId} data-unittest="partsData">
                  <td>{n.ModelComponentPositionName}</td>
                  <td>{n.Brand}</td>
                  <td>{n.Model}</td>
                  <td>{n.SerialNumber}</td>
                  <td>{n.ComponentTypeName}</td>
                  <td className={s.btnCol}>
                    {IsWidgetAccessible(userProfileWidgets, 'UNIT_PARTS_VIEW') && (
                      <Link to={`/unit/unit-configuration/${unitSerialNumber}/parts/part-details/${n.ComponentPartId}`}>
                        <Button variant="outline-secondary" className={s.changeButton} disabled={disableMode} data-unittest="viewData">
                          <FormattedMessage id="componentParts.view" defaultMessage="View" />
                        </Button>
                      </Link>
                    )}
                    {IsWidgetAccessible(userProfileWidgets, 'UNIT_PARTS_REPLACE') && (
                      <Button
                        variant="outline-secondary"
                        className={s.changeButton}
                        disabled={disableMode}
                        onClick={fetchDataHandler.bind(this, n.ComponentPartId, n)}
                        data-unittest="replaceData"
                      >
                        <FormattedMessage id="componentParts.replace" defaultMessage="Replace" />
                      </Button>
                    )}
                  </td>
                </tr>
                {unitConfiguration.componentParts.showAddEditScreen && selectedComponentPartId === n.ComponentPartId && editorMain}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className={newRowClass}>
        {unitConfiguration.componentParts.showAddEditScreen && selectedComponentPartId === 0 && editComponentPartContent}
      </div>
    </div>
  );
};

UnitConfigurationPartsList.defaultProps = {
  partsData: []
};

UnitConfigurationPartsList.propTypes = {
  saveComponentPart: PropTypes.func.isRequired,
  unitConfiguration: PropTypes.object.isRequired,
  changeComponentPart: PropTypes.func.isRequired,
  modelComponentPositions: PropTypes.array.isRequired,
  partsData: PropTypes.array,
  userProfileWidgets: PropTypes.object.isRequired,
  disableMode: PropTypes.bool,
  disableModeHandler: PropTypes.func.isRequired,
  setEditHandler: PropTypes.func.isRequired,
  UnitId: PropTypes.number.isRequired
};

export default injectIntl(UnitConfigurationPartsList);
