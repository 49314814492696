import React from 'react';
import s from './MoreOption.module.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link } from 'react-router-dom';

class MoreOption extends React.Component {
  //Constructor
  constructor(props) {
    super(props);
    this.state = {
      dataArray: [],
      show: false
    };
  }

  //componentDidMount
  componentDidMount() {
    this.setState({ dataArray: this.props.dataArray || [] });

    if (this.props.componentDidMount) {
      this.props.componentDidMount();
    }
  }

  //componentDidUpdate(prevProps, prevState, snapshot)
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.componentDidUpdate) {
      this.props.componentDidUpdate(prevProps, prevState, snapshot);
    }
  }

  //Component will unmount
  componentWillUnmount() {
    if (this.props.componentWillUnmount) {
      this.props.componentWillUnmount();
    }
    this.setState({ show: false });
  }

  //componentDidCatch(error, info)
  componentDidCatch(error, info) {
    if (this.props.componentDidCatch) {
      this.props.componentDidCatch(error, info);
    }
  }

  onbtnClick = id => {
    this.setState({ show: !this.state.show });
  };

  render() {
    return (
      <div className={s.wsaDropdown}>
        <div className={s.dropdown}>
          <DropdownButton alignRight id="dropdown-item-button" title="...">
            {this.state.dataArray.map((n, index) => {
              if (n.Name) {
                if (n.Url) {
                  return (
                    <Link key={index} to={n.Url}>
                      {' '}
                      <Dropdown.Item as="button">{n.Name}</Dropdown.Item>
                    </Link>
                  );
                } else if (n.Click) {
                  return (
                    <Dropdown.Item key={index} onClick={n.Click} as="button">
                      {n.Name}
                    </Dropdown.Item>
                  );
                }
              }
            })}
          </DropdownButton>
        </div>
      </div>
    );
  }
}

MoreOption.defaultProps = {
  dataArray: [],
  show: false
};

export default MoreOption;
