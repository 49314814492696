import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  GET_ALL_COMPONENT_PARTS_DETAILS,
  COMPONENT_PARTS_IS_LOADING,
  COMPONENT_PARTS_ERROR,
  GET_ALL_COMPONENT_PARTS_FILTERS,
  SET_COMPONENT_PARTS_CURRENT_PAGE,
  SET_COMPONENT_PARTS_FILTERED_RESULTS,
  SET_COMPONENT_PARTS_PAGE_FILTER,
  SET_COMPONENT_PARTS_CHANGE,
  SET_COMPONENT_PARTS,
  UPDATE_COMPONENT_PARTS_SAVE_STATUS
} from '../constants/index';

import { COMPONENT_PARTS_MANAGEMENT, CREATE_COMPONENT_PART, UPDATE_COMPONENT_PART } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setComponentPartErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setComponentPartError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setComponentPartError(messageCode, false));
  }, 2500);
};

export const setComponentPartError = (messageCode, status) => {
  return {
    type: COMPONENT_PARTS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getComponentPartsThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getComponentPart(filterObj, widgetCode));
};

export const setLoadingStatus = isLoading => ({
  type: COMPONENT_PARTS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getComponentPart = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/componentParts`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter,
      FilterBrand: filterObj.filterBrand,
      FilterModel: filterObj.filterModel,
      FilterComponentType: filterObj.filterComponentType,
      FilterUnit: filterObj.filterUnit,
      FilterUnitId: filterObj.filterUnitId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllComponentPartListThunk(retData),
    onFailure: err => setComponentPartErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllComponentPartListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let componentParts = (retData && retData.retData.componentPart) || [];
  dispatch(setAllComponentPartList(messageCode, componentParts));
};

export const setAllComponentPartList = (messageCode, componentParts) => ({
  type: GET_ALL_COMPONENT_PARTS_DETAILS,
  payload: {
    data: {
      componentPartsList: componentParts,
      filteredResults: componentParts,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const getComponentPartsFilterThunk = widgetCode => dispatch => {
  dispatch(getComponentPartFilter(widgetCode));
};

export const getComponentPartFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      fBrands: true,
      fApprovedComponentModels: true,
      fComponentTypes: true,
      filterFor: 'componentparts'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllComponentPartFilterThunk(retData),
    onFailure: err => setComponentPartErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllComponentPartFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;

  dispatch(setAllComponentPartFilter(messageCode, filterData, itemCount));
};

export const setAllComponentPartFilter = (messageCode, componentPartsFilter, componentPartsCount) => ({
  type: GET_ALL_COMPONENT_PARTS_FILTERS,
  payload: {
    data: {
      filterData: componentPartsFilter,
      componentPartsListCount: componentPartsCount,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setComponentPartFilteredDataThunk = retData => dispatch => {
  dispatch(setComponentPartFilteredData(retData));
};

export const setComponentPartFilteredData = retData => ({
  type: SET_COMPONENT_PARTS_FILTERED_RESULTS,
  payload: retData
});

export const setCurrentPage = currentPage => ({
  type: SET_COMPONENT_PARTS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_COMPONENT_PARTS_PAGE_FILTER,
  payload: { filter }
});

export const describeComponentPartThunk = (ComponentPartId, widgetCode) => dispatch => {
  dispatch(describeComponentPart(ComponentPartId, widgetCode));
};

export const describeComponentPart = (ComponentPartId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/componentParts`,
    data: {
      ComponentPartId: ComponentPartId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setComponentPartThunk(retData),
    onFailure: err => setComponentPartErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setComponentPartThunk = retData => dispatch => {
  const describeComponentPart = retData.retData.componentPart || {};
  let componentPart =
    describeComponentPart && describeComponentPart.componentPartDetails.length > 0 ? describeComponentPart.componentPartDetails[0] : [];
  componentPart.TelemetryData = (describeComponentPart && describeComponentPart.telemetryData) || [];
  componentPart.TelemetryHistory = (describeComponentPart && describeComponentPart.telemetryHistory) || [];
  componentPart.FirmwareHistory = (describeComponentPart && describeComponentPart.firmwareHistory) || [];
  dispatch(setComponentPart(componentPart));
};

export const setComponentPart = data => ({
  type: SET_COMPONENT_PARTS,
  payload: {
    selectedComponentPart: data,
    isOpSuccessful: false,
    showBanner: false
  }
});

export const saveComponentPartThunk = (componentPart, widgetCode) => dispatch => {
  let url = `/management/componentParts/save`;
  let actionName = componentPart.ComponentPartId === 0 ? CREATE_COMPONENT_PART : UPDATE_COMPONENT_PART;
  let log = logEntry(COMPONENT_PARTS_MANAGEMENT, actionName, componentPart);
  dispatch(saveComponentPart(componentPart, url, log, widgetCode));
};

export const saveComponentPart = (componentPart, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setComponentPartStatusThunk(retData),
    onFailure: err => setComponentPartErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      componentPart: componentPart,
      log: log
    },
    widgetcode: widgetCode
  });

export const setComponentPartStatusThunk = retData => dispatch => {
  let messageCode = retData;
  dispatch(setComponentPartStatus(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setComponentPartStatus('', false));
  }, 2500);
};

export const setComponentPartStatus = (messageCode, status) => ({
  type: UPDATE_COMPONENT_PARTS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setComponentPartChangeThunk = retData => dispatch => {
  dispatch(setComponentPartChange(retData));
};

export const setComponentPartChange = retData => ({
  type: SET_COMPONENT_PARTS_CHANGE,
  payload: retData
});
