import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CalibrationEquationList from './CalibrationEquationList';
import { getCalibrationEquation, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import { getCalibrationEquationThunk, setCurrentPage, setPageFilter } from '../../actions/calibrationEquation';

const mapStateToProps = state => {
  return {
    calibrationEquationManager: getCalibrationEquation(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCalibrationEquation: (offset, limit, filter) => getCalibrationEquationThunk(offset, 'CALIBRATIONS_TAB'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CalibrationEquationList);
