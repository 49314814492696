import { initialUnitStatisticsState } from './initialState';

import {
  UNITSTATISTICS_ERROR,
  SET_UNITSTATISTICS_SETTINGS,
  UNITSTATISTICS_IS_LOADING,
  SET_UNITSTATISTICS_CHANGE,
  SET_UNITSTATISTICS,
  UPDATE_UNITSTATISTICS_SAVE_STATUS,
  UNITSTATISTICS_CLEANUP
} from '../constants/index';

const unitStatisticsReducer = (state = initialUnitStatisticsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_UNITSTATISTICS_SETTINGS: {
      const { data } = payload;
      return {
        ...state,
        unitStatistics: {
          ...state.unitStatistics,
          ...data.unitStatistics,
          isLoading: data.isLoading,
          displayMessageCode: data.displayMessageCode,
          showBanner: data.showBanner,
          isOpSuccessful: data.isOpSuccessful
        }
      };
    }

    case UPDATE_UNITSTATISTICS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        unitStatistics: {
          ...state.unitStatistics,
          ...data
        }
      };
    }

    case UNITSTATISTICS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        unitStatistics: {
          ...state.unitStatistics,
          isLoading: isLoading
        }
      };
    }

    case UNITSTATISTICS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        unitStatistics: {
          ...state.unitStatistics,
          ...data
        }
      };
    }

    case SET_UNITSTATISTICS_CHANGE: {
      let { selectedStatistics } = payload;

      return {
        ...state,
        unitStatistics: {
          ...state.unitStatistics,
          isOpSuccessful: false,
          showBanner: false,
          selectedStatistics: {
            ...state.unitStatistics.selectedStatistics,
            ...selectedStatistics
          }
        }
      };
    }

    case SET_UNITSTATISTICS: {
      const { data } = payload;
      return {
        ...state,
        unitStatistics: {
          ...state.unitStatistics,
          statistics: data.statistics,
          isLoading: data.isLoading,
          displayMessageCode: data.displayMessageCode,
          showBanner: data.showBanner
        }
      };
    }

    case UNITSTATISTICS_CLEANUP: {
      const { unitStatistics } = payload;
      return {
        ...state,
        unitStatistics: {
          ...state.unitStatistics,
          ...unitStatistics
        }
      };
    }

    default:
      return state;
  }
};

export default unitStatisticsReducer;
