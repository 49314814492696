import React, { useState } from 'react';
import s from './MoveUnits.module.scss';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { initialOrganisationManagerState } from '../../reducers/initialState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchBar from '../SearchBar/SearchBar';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import arrowRight from '../../assets/arrow-right-48.png';
import cross from '../../assets/cross-small.svg';
import { isRequired } from '../../utils';
import Form from 'react-bootstrap/Form';

const OrganisationUnits = ({
  organisationManager,
  onUnitSearch,
  initialText,
  organisationOptions,
  modelOptions,
  onOrganisationChange,
  modelVersionsByOrgId,
  selectedModelVersion,
  setSelectedModelVersion,
  selectedModelId,
  setSelectedModel,
  onUnitsMove,
  onUnitsRemove,
  onSelectedUnitsChange,
  intl
}) => {
  let movedUnits = [];
  let organisationUnits = [];
  const [formErrors, setFormErrors] = useState({});

  let ddlTransferOrganisationError = formErrors && formErrors.TransferOrganisationId ? s.ddlError : '';
  let ddlModelVersionError = formErrors && formErrors.TransferModelVersionId ? s.ddlError : '';

  const searchHandler = value => {
    onUnitSearch(value);
  };

  const selectedUnits = organisationManager.selectedOrganisation.SelectedTransferUnits || [];

  if (organisationManager.toFilterUnits) {
    let filterList = organisationManager.toFilterUnits;

    let movedUnitsList = organisationManager.selectedOrganisation.TransferUnits || [];

    const filterOrgUnits = filterList.filter(el => {
      return !movedUnitsList.find(element => {
        return element.UnitId === el.UnitId;
      });
    });

    organisationUnits = filterOrgUnits
      .filter(f => f.ModelId === selectedModelId)
      .map(sl => {
        return {
          ...sl,
          SKEY: sl.UnitId,
          label: `${sl.UnitName} / ${sl.ModelVersionName}`,
          value: sl.UnitId,
          isChecked: selectedUnits && selectedUnits.filter(unit => unit.UnitId === sl.UnitId).length > 0
        };
      });
  }

  if (organisationManager.selectedOrganisation.TransferUnits) {
    movedUnits = organisationManager.selectedOrganisation.TransferUnits.filter(x => x.ModelVersionId === selectedModelVersion.value).map(
      sl => {
        return {
          ...sl,
          SKEY: sl.UnitId,
          label: `${sl.UnitName} / ${sl.ModelVersionName}`,
          value: sl.UnitId
        };
      }
    );
  }

  const onCheckboxSelectionChange = (e, data) => {
    let units = selectedUnits;
    if (data.isChecked) {
      units.push({
        UnitId: data.UnitId,
        UnitName: data.UnitName,
        ModelVersionId: data.ModelVersionId,
        ModelVersionName: data.ModelVersionName
      });
    } else {
      units = units.filter(selUnit => selUnit.UnitId !== data.UnitId);
    }
    onSelectedUnitsChange(units);
  };

  const onMoveUnits = () => {
    let formErrors = {};

    if (
      !organisationManager ||
      !organisationManager.selectedOrganisation ||
      !organisationManager.selectedOrganisation.TransferOrganisationId ||
      isRequired(organisationManager.selectedOrganisation.TransferOrganisationId, 1)
    ) {
      formErrors.TransferOrganisationId = intl.formatMessage({
        id: 'organisationManagement.transferOrganisationMandatory',
        defaultMessage: 'Please select organisation'
      });
      setFormErrors(formErrors);

      return false;
    }

    const modelVersionUnitsFilter = selectedUnits.filter(item => item.ModelVersionId === selectedModelVersion.value);

    if (modelVersionUnitsFilter.length > 0) {
      formErrors.TransferModelVersionId = intl.formatMessage({
        id: 'organisationManagement.youCannotMoveUnitsToSameModelVersion',
        defaultMessage: 'You cannot move units to the same model version!'
      });

      setFormErrors(formErrors);

      return false;
    }

    let transferUnits = selectedUnits.map(item => {
      return {
        UnitId: item.UnitId,
        UnitName: item.UnitName,
        ModelVersionId: selectedModelVersion.value,
        ModelVersionName: selectedModelVersion.label
      };
    });

    onUnitsMove(transferUnits);
  };

  const onRemoveTransferUnits = unitId => {
    onUnitsRemove(unitId);
  };

  const onModelVersionChange = e => {
    setSelectedModelVersion(e);
  };

  const onOrganisationChangeHandler = e => {
    onOrganisationChange(e);
    setFormErrors({});
  };

  const onModelVersionChangeHandler = e => {
    onModelVersionChange(e);
    setFormErrors({});
  };

  const onModelChangeHandler = e => {
    setSelectedModel(e.value);
    setFormErrors({});
  };

  return (
    <Row>
      <Col lg={5} className={s.borderBox}>
        <div className={s.organisationUnits} id="organisationUnits">
          {organisationManager.toFilterUnits.length === 0 && <LoadingSpinner centeredLoading={false} />}
          <Row className={s.row}>
            <Col sm={6}>
              <Form.Label>
                <FormattedMessage id="organisationManagement.fromModel" defaultMessage="Unit Model" />
              </Form.Label>
              <Dropdown
                id="drpOrganisationId"
                dataArray={modelOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                  customClassName: `${ddlTransferOrganisationError}`
                }}
                onDropdownChange={e => onModelChangeHandler(e)}
                selectedOption={modelOptions().filter(option => option.value === selectedModelId)}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>
                <FormattedMessage id="organisationManagement.searchInfo" defaultMessage="Search units by unit name" />
              </Form.Label>
              <SearchBar
                searchHandler={searchHandler}
                initialText={initialText}
                clearSearchInVisible={true}
                placeHolderTextId="organisationManagement.search"
              />
            </Col>
          </Row>
          <Row className={s.selectedUsersAndUnits}>
            <Col sm={12} className={s.selectedCols}>
              <Checkbox dataArray={organisationUnits} onSelectionChange={onCheckboxSelectionChange} />
            </Col>
          </Row>
        </div>
      </Col>
      <Col lg={1} className={s.centerArrowCol}>
        <img className={s.centerArrow} src={arrowRight} onClick={onMoveUnits}></img>
      </Col>
      <Col lg={5} className={s.movedUnitBorderBox}>
        <div className={s.organisationUnits} id="movedOrganisationUnits">
          {/* {parentOrganisationUnits.length === 0 && selectedUnitIds.length === 0 && <LoadingSpinner centeredLoading={false} />} */}
          <Row className={s.rowMovedUnits}>
            <Col sm={6}>
              <Form.Label>
                <FormattedMessage id="organisationManagement.transferringOrg" defaultMessage="Tranfer Organisation" />
              </Form.Label>
              <Dropdown
                id="drpOrganisationId"
                dataArray={organisationOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                  customClassName: `${ddlTransferOrganisationError}`
                }}
                onDropdownChange={e => onOrganisationChangeHandler(e)}
                // selectedOption={organisationOptions().filter(option => option.value === organisationManager.selectedOrganisation.UnitManagerId)}
                disabled={
                  organisationManager.selectedOrganisation.TransferUnits &&
                  organisationManager.selectedOrganisation.TransferUnits.length > 0
                }
              />
            </Col>
            <Col sm={6}>
              <Form.Label>
                <FormattedMessage id="organisationManagement.tranferModelVersion" defaultMessage="Transfer model version" />
              </Form.Label>
              <Dropdown
                id="drpModelVersionId"
                dataArray={modelVersionsByOrgId}
                onDropdownChange={e => onModelVersionChangeHandler(e)}
                controlData={{
                  placeholderText: '',
                  customClassName: `${ddlModelVersionError}`
                }}
                selectedOption={modelVersionsByOrgId.filter(option => option.value === selectedModelVersion.value)}
                disabled={false}
              />
            </Col>
          </Row>
          <Row className={s.selectedUsersAndUnits}>
            <Col sm={12} className={s.selectedCols}>
              <ul>
                {movedUnits &&
                  movedUnits.map(unit => (
                    <li key={unit.value}>
                      {unit.label}
                      <img className={s.cross} src={cross} onClick={() => onRemoveTransferUnits(unit.value)}></img>
                    </li>
                  ))}
              </ul>
            </Col>
          </Row>
        </div>
        {formErrors && formErrors.TransferOrganisationId && (
          <p role="alert" className={s.error}>
            {formErrors.TransferOrganisationId}
          </p>
        )}
        {formErrors && formErrors.TransferModelVersionId && (
          <p role="alert" className={s.error}>
            {formErrors.TransferModelVersionId}
          </p>
        )}
      </Col>
    </Row>
  );
};

OrganisationUnits.defaultProps = {
  parentOrganisationUnits: [],
  organisationManager: {
    ...initialOrganisationManagerState.organisationManager
  }
};

export default injectIntl(OrganisationUnits);
