import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  APPROVEDCOMPONENT_ERROR,
  GET_ALL_APPROVEDCOMPONENT_DETAILS,
  APPROVEDCOMPONENT_IS_LOADING,
  SET_APPROVEDCOMPONENT_CURRENT_PAGE,
  SET_APPROVEDCOMPONENT_PAGE_FILTER,
  SET_APPROVEDCOMPONENT_CHANGE,
  SET_APPROVEDCOMPONENT,
  UPDATE_APPROVEDCOMPONENT_SAVE_STATUS,
  APPROVEDCOMPONENT_CLEANUP,
  SET_APPROVEDCOMPONENT_FILTERED_RESULTS,
  SHOW_APPROVEDCOMPONENT_SUB_COMPONENT_ADD_EDIT_SCREEN,
  SET_APPROVED_SUB_COMPONENT_CHANGE,
  SET_APPROVED_SUB_COMPONENT_LIST
} from '../constants/index';

import {
  APPROVEDCOMPONENT_MANAGEMENT,
  CREATE_APPROVEDCOMPONENT,
  UPDATE_APPROVEDCOMPONENT,
  DELETE_APPROVEDCOMPONENT
} from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setApprovedComponentErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setApprovedComponentError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setApprovedComponentError(messageCode, false));
  }, 2500);
};

export const setApprovedComponentError = (messageCode, status) => {
  return {
    type: APPROVEDCOMPONENT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: APPROVEDCOMPONENT_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getApprovedComponentThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getApprovedComponent(filterObj, widgetCode));
};

export const getApprovedComponent = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/approvedComponents`,
    data: {
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllApprovedComponentListThunk(retData),
    onFailure: err => setApprovedComponentErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllApprovedComponentListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let approvedComponents = (retData && retData.approvedComponents) || [];
  dispatch(setAllApprovedComponentList(messageCode, approvedComponents));
};

export const setAllApprovedComponentList = (messageCode, approvedComponents) => ({
  type: GET_ALL_APPROVEDCOMPONENT_DETAILS,
  payload: {
    data: {
      approvedComponentList: approvedComponents,
      filteredResults: approvedComponents,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setModelComponentPositionFilteredDataThunk = retData => dispatch => {
  dispatch(setModelComponentPositionFilteredData(retData));
};

export const setModelComponentPositionFilteredData = retData => ({
  type: SET_APPROVEDCOMPONENT_FILTERED_RESULTS,
  payload: retData
});

export const setCurrentPage = currentPage => ({
  type: SET_APPROVEDCOMPONENT_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_APPROVEDCOMPONENT_PAGE_FILTER,
  payload: { filter }
});

export const describeApprovedComponentThunk = (ApprovedComponentId, widgetCode) => dispatch => {
  dispatch(describeApprovedComponent(ApprovedComponentId, widgetCode));
};

export const describeApprovedComponent = (ApprovedComponentId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/approvedComponents`,
    data: {
      ApprovedComponentId: ApprovedComponentId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setApprovedComponentThunk(retData),
    onFailure: err => setApprovedComponentErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setApprovedComponentThunk = ({ retData }) => dispatch => {
  dispatch(setApprovedComponent(retData));
};

export const setApprovedComponent = ({ approvedComponents }) => ({
  type: SET_APPROVEDCOMPONENT,
  payload: {
    selectedApprovedComponent: {
      ...approvedComponents.selectedApprovedComponent
    },
    calibrationEquations: approvedComponents.calibrationEquations,
    modelComponentTypes: approvedComponents.modelComponentTypes,
    modelSubComponentTypes: approvedComponents.modelSubComponentTypes,
    isOpSuccessful: false,
    showBanner: false,
    isRedirect: false
  }
});

export const saveApprovedComponentThunk = (approvedComponent, widgetCode) => dispatch => {
  let url = '/management/approvedComponents/save';
  let actionName =
    approvedComponent.ApprovedComponentId === 0
      ? CREATE_APPROVEDCOMPONENT
      : approvedComponent.PerformDelete
      ? DELETE_APPROVEDCOMPONENT
      : UPDATE_APPROVEDCOMPONENT;

  let log = logEntry(APPROVEDCOMPONENT_MANAGEMENT, actionName, approvedComponent);
  dispatch(saveApprovedComponent(approvedComponent, url, log, widgetCode));
};

export const saveApprovedComponent = (approvedComponent, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setApprovedComponentStatusThunk(retData, approvedComponent),
    onFailure: err => setApprovedComponentErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      ApprovedComponent: approvedComponent,
      log: log
    },
    widgetcode: widgetCode
  });

export const setApprovedComponentStatusThunk = (message, approvedComponent) => dispatch => {
  dispatch(setApprovedComponentStatus(message, true, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setApprovedComponentStatus('', false, approvedComponent.PerformDelete ? true : false));
  }, 2500);
};

export const setApprovedComponentStatus = (messageCode, status, isOpSuccessful) => ({
  type: UPDATE_APPROVEDCOMPONENT_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: isOpSuccessful,
      showBanner: status,
      isLoading: false,
      isRedirect: !status && !isOpSuccessful,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setApprovedComponentChangeThunk = retData => dispatch => {
  dispatch(setApprovedComponentChange(retData));
};

export const setApprovedComponentChange = retData => ({
  type: SET_APPROVEDCOMPONENT_CHANGE,
  payload: retData
});

export const setApprovedSubComponentChangeThunk = retData => dispatch => {
  dispatch(setApprovedSubComponentChange(retData));
};

export const setApprovedSubComponentChange = retData => ({
  type: SET_APPROVED_SUB_COMPONENT_CHANGE,
  payload: retData
});

export const resetModelComponentThunk = retData => dispatch => {
  dispatch(resetModelComponent(retData));
};

export const resetModelComponent = retData => ({
  type: APPROVEDCOMPONENT_CLEANUP,
  payload: retData
});

export const showAddEditSreenThunk = status => dispatch => {
  dispatch(showAddEditSreen(status));
};

export const showAddEditSreen = status => ({
  type: SHOW_APPROVEDCOMPONENT_SUB_COMPONENT_ADD_EDIT_SCREEN,
  payload: { showAddEditScreen: status }
});

export const setApprovedSubComponentListThunk = retData => dispatch => {
  dispatch(setApprovedSubComponentList(retData));
};

export const setApprovedSubComponentList = retData => ({
  type: SET_APPROVED_SUB_COMPONENT_LIST,
  payload: retData
});
