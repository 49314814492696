import React from 'react';
import { Link } from 'react-router-dom';
import s from './Unauthorised.module.scss';
import img from '../../assets/Unauthorised401.svg';
import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';

const Unauthorised = () => (
  <div className={s.unauthorised}>
    <div className={s.container}>
      <img src={img} />
      <h1>
        <FormattedMessage id="unauthorised.error401" defaultMessage="Error 401" />
      </h1>
      <h5 className={s.subHeading}>
        <FormattedMessage id="unauthorised.youAreNotAuthorised" defaultMessage="You are not authorised to view this page" />
      </h5>
      <Link to={`/`}>
        <Button className={s.btnChange}>
          <FormattedMessage id="unauthorised.returnHome" defaultMessage="Return Home" />
        </Button>
      </Link>
    </div>
  </div>
);
export default Unauthorised;
