import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AdminControlsTabs from './AdminControlsTabs';

import s from './AdminControlsContentTemplate.module.scss';

const AdminControlsContentTemplate = ({ backLink, children, selectedPage, userProfileWidgets }) => (
  <Fragment>
    <div className={s.fullWidth}>
      <div className={s.fullWidthWrapper}>
        <h1 className={s.tabHeader}>{<FormattedMessage id="AdminControls.adminControls" defaultMessage="Admin Controls" />}</h1>
        {<AdminControlsTabs selectedPage={selectedPage} userProfileWidgets={userProfileWidgets} />}
      </div>
    </div>

    <div className={s.pageContent}>{children}</div>
  </Fragment>
);

AdminControlsContentTemplate.defaultProps = {
  backLink: '/',
  selectedPage: ''
};

AdminControlsContentTemplate.propTypes = {
  backLink: PropTypes.string,
  children: PropTypes.node.isRequired,
  selectedPage: PropTypes.string,
  userProfileWidgets: PropTypes.object
};

export default AdminControlsContentTemplate;
