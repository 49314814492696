import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChecklistDetail from './ChecklistDetail';
import {
  getChecklistTemplate,
  getChecklist,
  getUserProfileWidget,
  getCurrentTimezone,
  getUserProfileUnit,
  getUserManager,
  getProfile
} from '../../selectors/index';
import {
  describeChecklistTemplateThunk,
  getChecklistTemplateThunk,
  setChecklistTemplateChangeThunk
} from '../../actions/checklistTemplates';
import { saveChecklistThunk, describeChecklistThunk, setChecklistChangeThunk } from '../../actions/checklist';
import { resetTasksThunk } from '../../actions/tasks';
import { getProfileThunk } from '../../actions/profiles';
import { getUsersThunk } from '../../actions/userManager';

const mapStateToProps = state => {
  return {
    checklistTemplate: getChecklistTemplate(state),
    checklist: getChecklist(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state),
    userUnits: getUserProfileUnit(state),
    userManager: getUserManager(state),
    profile: getProfile(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveChecklist: (checklist, widgetCode) => saveChecklistThunk(checklist, widgetCode),
      describeChecklist: (checklistId, widgetCode) => describeChecklistThunk(checklistId, widgetCode),
      getChecklistTemplate: widgetCode => getChecklistTemplateThunk(widgetCode),
      describeChecklistTemplate: (checklistTemplateId, widgetCode) => describeChecklistTemplateThunk(checklistTemplateId, widgetCode),
      changeChecklist: checklist => setChecklistChangeThunk(checklist),
      resetTasks: tasks => resetTasksThunk(tasks),
      changeChecklistTemplate: checklistTemplate => setChecklistTemplateChangeThunk(checklistTemplate),
      getProfileInfo: widgetCode => getProfileThunk(widgetCode),
      getUsers: (orgId, widgetCode) => getUsersThunk(null, null, orgId, widgetCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistDetail);
