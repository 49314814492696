import Cookies from 'js-cookie';
import { apiAction } from '../utils/index';

import {
  GET_ALL_UNIT_TAGOUT_HISTORY_DETAILS,
  UNIT_TAGOUT_HISTORY_IS_LOADING,
  UNIT_TAGOUT_HISTORY_ERROR,
  GET_ALL_UNIT_TAGOUT_HISTORY_FILTERS,
  SET_UNIT_TAGOUT_HISTORY_CURRENT_PAGE,
  SET_UNIT_TAGOUT_HISTORY_FILTERED_RESULTS,
  SET_UNIT_TAGOUT_HISTORY_PAGE_FILTER
} from '../constants/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setUnitTagHistoryErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setUnitTagHistoryError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitTagHistoryError(messageCode, false));
  }, 2500);
};

export const setUnitTagHistoryError = (messageCode, status) => {
  return {
    type: UNIT_TAGOUT_HISTORY_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getUnitTagHistoryThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getUnitTagHistory(filterObj, widgetCode));
};

export const setLoadingStatus = isLoading => ({
  type: UNIT_TAGOUT_HISTORY_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getUnitTagHistory = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/maintenance/tagout/tagoutdetails`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      filterUnitId: filterObj.filterUnitId,
      filterTagOutUser: filterObj.filterTagOutUser,
      filterTagOutFrom: filterObj.filterTagOutFrom?.toString(),
      filterTagInFrom: filterObj.filterTagInFrom?.toString(),
      isTaggedOut: filterObj.filterIsTaggedOut === -1 ? null : filterObj.filterIsTaggedOut
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllUnitTagHistoryListThunk(retData),
    onFailure: err => setUnitTagHistoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllUnitTagHistoryListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let tagoutDetails = (retData && retData.tagoutDetails) || [];
  dispatch(setAllUnitTagHistoryList(messageCode, tagoutDetails));
};

export const setAllUnitTagHistoryList = (messageCode, tagoutDetails) => ({
  type: GET_ALL_UNIT_TAGOUT_HISTORY_DETAILS,
  payload: {
    data: {
      unitTagHistoryList: tagoutDetails,
      filteredResults: tagoutDetails,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const getUnitTagHistoryFilterThunk = widgetCode => dispatch => {
  dispatch(getUnitTagHistoryFilter(widgetCode));
};

export const getUnitTagHistoryFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      fUnits: true,
      fAssignees: true,
      filterFor: 'unittagout'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllUnitTagHistoryFilterThunk(retData),
    onFailure: err => setUnitTagHistoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllUnitTagHistoryFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllUnitTagHistoryFilter(messageCode, filterData, itemCount));
};

export const setAllUnitTagHistoryFilter = (messageCode, unitTagHistoryFilter, unitTagHistoryCount) => ({
  type: GET_ALL_UNIT_TAGOUT_HISTORY_FILTERS,
  payload: {
    data: {
      filterData: {
        Users: unitTagHistoryFilter.Assignees,
        Units: unitTagHistoryFilter.Units
      },
      unitTagHistoryListCount: unitTagHistoryCount,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setUnitTagHistoryFilteredDataThunk = retData => dispatch => {
  dispatch(setUnitTagHistoryFilteredData(retData));
};

export const setUnitTagHistoryFilteredData = retData => ({
  type: SET_UNIT_TAGOUT_HISTORY_FILTERED_RESULTS,
  payload: retData
});

export const setCurrentPage = currentPage => ({
  type: SET_UNIT_TAGOUT_HISTORY_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_UNIT_TAGOUT_HISTORY_PAGE_FILTER,
  payload: { filter }
});
