import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './UnitConfigurationParts.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialUnitConfigurationState } from '../../reducers/initialState';
import { WidgetVisibility } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import UnitConfigurationPartsList from './UnitConfigurationPartsList';
import Cookies from 'js-cookie';

const SEARCH_FILTER_TYPE_SERIAL_NUMBER = 'SEARCH_FILTER_TYPE_SERIAL_NUMBER';
const SEARCH_FILTER_TYPE_POSITION = 'SEARCH_FILTER_TYPE_POSITION';

const UnitConfigurationParts = ({
  unitConfiguration,
  intl,
  UnitId,
  userProfileWidgets,
  getComponentPartsByUnit,
  saveComponentPart,
  changeComponentPart,
  setComponentPartShowScreenStatus,
  modelComponentPositions,
  getSelectedUserProfileWidgets
}) => {
  let initialLimit = 6;
  let initialRecords = unitConfiguration.componentParts.componentPartsList.slice(0, initialLimit);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    getComponentPartsByUnit(UnitId);
  }, [getComponentPartsByUnit, UnitId]);

  const [currentEditId, setCurrentEditId] = useState(-1);
  const [localComponentParts, setLocalComponentParts] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchfilterType, setSearchFilterType] = useState(SEARCH_FILTER_TYPE_SERIAL_NUMBER);
  const [localSaveData, setLocalSaveData] = useState({});

  const localComponentPartsLength = localComponentParts.length;
  const componnetPartsLength = unitConfiguration.componentParts.componentPartsList.length;

  useEffect(() => {
    return () => {
      setComponentPartShowScreenStatus(false);
    };
  }, []);

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalComponentParts(unitConfiguration.componentParts.componentPartsList);
    }
    setShowAll(!showAll);
  };

  const disableModeHandler = status => {
    setComponentPartShowScreenStatus(status);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = unitConfiguration.componentParts.componentPartsList.filter(function(item) {
      return item.SerialNumber.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalComponentParts(filteredList);
  };

  const setAddHandler = () => {
    setComponentPartShowScreenStatus(true);
    setCurrentEditId(0);
    changeComponentPart({
      selectedComponentPart: {
        ...initialUnitConfigurationState.unitConfiguration.componentParts.selectedComponentPart
      }
    });
  };

  const setEditHandler = (componentPartId, item = null) => {
    setCurrentEditId(componentPartId);
    if (componentPartId === -1) {
      changeComponentPart({
        selectedComponentPart: {
          ...initialUnitConfigurationState.unitConfiguration.componentParts.selectedComponentPart,
          OldComponentPartId: 0,
          OldSerialNumber: ''
        }
      });
      setComponentPartShowScreenStatus(false);
    } else {
      changeComponentPart({
        selectedComponentPart: {
          ...unitConfiguration.componentParts.selectedComponentPart,
          ...item,
          OldSerialNumber: item.SerialNumber,
          OldComponentPartId: componentPartId
        }
      });
      setComponentPartShowScreenStatus(true);
    }
  };

  const onTypeChange = e => {
    setSearchFilterType(e.value);
  };

  let typePlaceHolder = intl.formatMessage({ id: 'componentParts.serialNumber', defaultMessage: 'Serial Number' });

  const typeOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="componentParts.serialNumber" defaultMessage="Serial Number" />,
      value: SEARCH_FILTER_TYPE_SERIAL_NUMBER
    });

    statusData.push({
      label: <FormattedMessage id="componentParts.position" defaultMessage="Position" />,
      value: SEARCH_FILTER_TYPE_POSITION
    });

    return statusData;
  };

  const filterData = () => {
    let filteredList = unitConfiguration.componentParts.componentPartsList;

    if (isFilter) {
      if (searchfilterType === SEARCH_FILTER_TYPE_SERIAL_NUMBER) {
        filteredList = unitConfiguration.componentParts.componentPartsList.filter(function(item) {
          return item.SerialNumber.toLowerCase().includes(searchText.toLowerCase());
        });
      } else {
        if (searchfilterType === SEARCH_FILTER_TYPE_POSITION) {
          filteredList = unitConfiguration.componentParts.componentPartsList.filter(function(item) {
            return item.ModelComponentPositionName.toLowerCase().includes(searchText.toLowerCase());
          });
        }
      }
    }

    return filteredList;
  };

  const getParts = () => {
    let parts = [];
    if (showAll) {
      parts = filterData();
    } else {
      if (isFilter) {
        parts = localComponentPartsLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        parts = initialRecords;
      }
    }
    return parts.sort(compareValues('SerialNumber'));
  };

  const saveComponentPartLocal = saveData => {
    setLocalSaveData(saveData);
    saveComponentPart(saveData);
  };

  let messageId = (unitConfiguration.componentParts && unitConfiguration.componentParts.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'componentParts.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'componentParts.showLess', defaultMessage: 'SHOW LESS' });
  if (unitConfiguration.componentParts.isOpSuccessful && !unitConfiguration.componentParts.showBanner) {
    if (localSaveData && localSaveData.ComponentTypeName === 'COMPUTER_BOARD_CONTROL-SYSTEM') {
      //Refreshing all page to reload state, if SBC is updated
      setLocalSaveData({});
      setTimeout(() => {
        let selectedProfileid = Cookies.get(`selectedprofileid-${envName}`) || 0;
        let userName = Cookies.get(`username-${envName}`) || '';
        getSelectedUserProfileWidgets(selectedProfileid, userName);
      }, 1000);
    }
  }

  return (
    <div className={s.componentPart}>
      {unitConfiguration.componentParts.isLoading && <LoadingSpinner />}
      {unitConfiguration.componentParts.showBanner && (
        <Banner
          failureText={messageText}
          showBanner={unitConfiguration.componentParts.showBanner}
          status={unitConfiguration.componentParts.isOpSuccessful}
          successText={messageText}
        />
      )}
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} md={7} lg={6}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              disabled={unitConfiguration.componentParts.showAddEditScreen}
              initialText={searchText}
              placeHolderTextId={
                searchfilterType === SEARCH_FILTER_TYPE_SERIAL_NUMBER ? 'componentParts.serialNumber' : 'componentParts.position'
              }
              id="txtSearchBar"
              showFilterType
              searchfilterType={searchfilterType}
              onFilterTypeChange={onTypeChange}
              filterTypeData={typeOptions()}
              filterPlaceholderText={typePlaceHolder}
              data-unittest="searchComponentParts"
            />
          </Col>
          <Col sm={6} md={5} lg={6}>
            <Button
              className={s.addbutton}
              style={WidgetVisibility(userProfileWidgets, 'UNIT_PARTS_ADD')}
              onClick={setAddHandler}
              disabled={unitConfiguration.componentParts.showAddEditScreen}
              data-unittest="addComponentPartButton"
            >
              + <FormattedMessage id="componentParts.addNewPart" defaultMessage="ADD NEW PART" />
            </Button>
          </Col>
        </Row>

        <div>
          <UnitConfigurationPartsList
            setEditHandler={setEditHandler}
            saveComponentPart={saveComponentPartLocal}
            selectedComponentPartId={currentEditId}
            unitConfiguration={unitConfiguration}
            changeComponentPart={changeComponentPart}
            userProfileWidgets={userProfileWidgets}
            partsData={getParts()}
            disableMode={unitConfiguration.componentParts.showAddEditScreen}
            disableModeHandler={disableModeHandler}
            modelComponentPositions={modelComponentPositions}
            UnitId={UnitId}
          />
        </div>
        {((!isFilter && componnetPartsLength > initialLimit) || (isFilter && localComponentPartsLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? componnetPartsLength : localComponentPartsLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

UnitConfigurationParts.defaultProps = {
  ...initialUnitConfigurationState
};

UnitConfigurationParts.propTypes = {
  unitConfiguration: PropTypes.object.isRequired,
  userProfileWidgets: PropTypes.object.isRequired,
  getComponentPartsByUnit: PropTypes.func.isRequired,
  setComponentPartShowScreenStatus: PropTypes.func.isRequired,
  modelComponentPositions: PropTypes.array.isRequired,
  UnitId: PropTypes.number.isRequired,
  saveComponentPart: PropTypes.func.isRequired,
  changeComponentPart: PropTypes.func.isRequired
};

export default injectIntl(UnitConfigurationParts);
