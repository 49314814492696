import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './EventAction.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchBar from '../SearchBar/SearchBar';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { TASK_STATUS_ACTIVE, TASK_STATUS_COMPLETE } from '../../constants/index';
import TaskListTable from '../TaskList/TaskListTable';
import { eventActionCleanUp } from '../../actions/eventAction';

const EventActionTask = ({
  intl,
  onTaskSearch,
  onTaskStatusSearch,
  selectedTasks,
  isNewTask,
  isEventActionCompleted,
  sortOrder,
  isView,
  addNewBtnClick,
  viewBtnClick,
  onSortOrder,
  currentTimezone,
  eventAction
}) => {
  const onDropdownChange = e => {
    onTaskStatusSearch(e.value);
  };

  const onAddNewBtnClick = () => {
    addNewBtnClick();
  };

  const taskStatusSearchOptions = () => {
    return [
      {
        label: intl.formatMessage({ id: 'tasksManagement.showAll', defaultMessage: 'Show:All' }),
        value: 'ALL'
      },
      {
        label: TASK_STATUS_ACTIVE,
        value: TASK_STATUS_ACTIVE
      },
      {
        label: TASK_STATUS_COMPLETE,
        value: TASK_STATUS_COMPLETE
      }
    ];
  };

  return (
    <div className={s.taskContainer}>
      <div>
        <Row>
          <Col xs={3} lg={3}>
            <SearchBar searchHandler={onTaskSearch} clearSearchInVisible={false} placeHolderTextId="tasksManagement.search" />
          </Col>
          <Col xs={3} lg={3}>
            <Dropdown
              id="formTaskStatusSearch"
              dataArray={taskStatusSearchOptions()}
              controlData={{
                placeholderText: intl.formatMessage({ id: 'tasksManagement.showAll', defaultMessage: 'Show:All' }),
                customClassName: s.ddlTaskStatusSearch
              }}
              onDropdownChange={onDropdownChange}
              data-unittest="formTaskStatusSearch"
            />
          </Col>
          <Col xs={3} lg={3}></Col>
          <Col xs={3} lg={3} className={s.colAddButton}>
            {isNewTask && (
              <Button
                disabled={isEventActionCompleted || eventAction.LinkStatus === 2}
                variant="primary"
                data-unittest="addnew"
                className={s.addButton}
                onClick={() => onAddNewBtnClick()}
              >
                + <FormattedMessage id="tasksManagement.addNew" defaultMessage="ADD NEW" />
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <div>
        <TaskListTable
          ViewAllowed={isView}
          currentTimezone={currentTimezone}
          onSortOrder={onSortOrder}
          asendingOrder={sortOrder}
          paginatedData={selectedTasks}
          onViewBtnClick={viewBtnClick}
        />
      </div>
    </div>
  );
};

EventActionTask.defaultProps = {
  selectedTasks: [],
  tasks: []
};

EventActionTask.propTypes = {
  onTaskSearch: PropTypes.func.isRequired,
  viewBtnClick: PropTypes.func.isRequired,
  addNewBtnClick: PropTypes.func.isRequired
};

export default injectIntl(EventActionTask);
