import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MaintenanceTabs from './MaintenanceTabs';
import { useSelector } from 'react-redux';
import { getUserProfileWidget } from '../../selectors/index';

import s from './Maintenance.module.scss';

const MaintenanceContentTemplate = ({ backLink, children, selectedPage }) => {
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));

  return (
    <Fragment>
      <div className={s.fullWidth}>
        <div className={s.fullWidthWrapper}>
          <h1 className={s.tabHeader}>{<FormattedMessage id="maintenance.maintenance" defaultMessage="Maintenance" />}</h1>
          {<MaintenanceTabs selectedPage={selectedPage} userProfileWidgets={userProfileWidgets} />}
        </div>
      </div>

      <div className={s.pageContent}>{children}</div>
    </Fragment>
  );
};

MaintenanceContentTemplate.defaultProps = {
  backLink: '/',
  selectedPage: ''
};

MaintenanceContentTemplate.propTypes = {
  backLink: PropTypes.string,
  children: PropTypes.node.isRequired,
  selectedPage: PropTypes.string
};

export default MaintenanceContentTemplate;
