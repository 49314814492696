import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  UNITSERVICE_ERROR,
  GET_ALL_UNITSERVICE_DETAILS,
  UNITSERVICE_IS_LOADING,
  SET_UNITSERVICE_CURRENT_PAGE,
  SET_UNITSERVICE_PAGE_FILTER,
  SET_UNITSERVICE_CHANGE,
  SET_UNITSERVICE,
  UPDATE_UNITSERVICE_SAVE_STATUS,
  SET_UNITSERVICE_FILTERED_RESULTS,
  UNIT_SERVICING_COMPONENT_PARTS,
  SET_UNITSERVICE_FILTERS,
  UNITSERVICE_CLEANUP,
  START_UNITSERVICE_EXPORT,
  SET_UNITSERVICE_EXPORT_DATA,
  SET_COMPUTE_UNITSERVICE_EXPORT
} from '../constants/index';

import { UNITSERVICE_MANAGEMENT, CREATE_UNITSERVICE, UPDATE_UNITSERVICE } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setUnitServiceErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setUnitServiceError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitServiceError(messageCode, false));
  }, 2500);
};

export const setUnitServiceError = (messageCode, status) => {
  return {
    type: UNITSERVICE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || '',
        exportedResults: [],
        exportComplete: false,
        exportCompute: false
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: UNITSERVICE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getUnitServiceThunk = (filterObj, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getUnitService(filterObj, widgetCode, orgId));
};

export const getUnitService = (filterObj, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/maintenance/services/unitservices/list`,
    data: {
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      filterUnitId: filterObj.filterUnitId,
      filterUnitServiceStatus: filterObj.filterUnitServiceStatus,
      filterServiceUser: filterObj.filterServiceUser,
      filterServiceFrom: filterObj.filterServiceFrom,
      filterServiceTo: filterObj.filterServiceTo,
      UserId: Cookies.get(`userid-${envName}`),
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllUnitServiceListThunk(retData),
    onFailure: err => setUnitServiceErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllUnitServiceListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let unitservices = (retData && retData.UnitServices) || [];
  dispatch(setAllUnitServiceList(messageCode, unitservices));
};

export const setAllUnitServiceList = (messageCode, Unitservices) => ({
  type: GET_ALL_UNITSERVICE_DETAILS,
  payload: {
    data: {
      unitServices: Unitservices,
      filteredResults: Unitservices,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_UNITSERVICE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_UNITSERVICE_PAGE_FILTER,
  payload: { filter }
});

export const describeUnitServiceThunk = (unitServiceId, checklistId, widgetcode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeUnitService(unitServiceId, checklistId, widgetcode, orgId));
};

export const describeUnitService = (unitServiceId, checklistId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/maintenance/services/unitservices`,
    data: {
      UnitServiceId: unitServiceId ?? 0,
      ChecklistId: checklistId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setUnitServiceThunk(retData),
    onFailure: err => setUnitServiceErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setUnitServiceThunk = ({ retData }) => dispatch => {
  let data = retData.UnitService;
  dispatch(setUnitService(data));
};

export const setUnitService = data => ({
  type: SET_UNITSERVICE,
  payload: data
});

export const saveUnitServiceThunk = (unitservice, widgetCode) => dispatch => {
  let url = '/maintenance/services/unitservices/save';
  let actionName = unitservice.UnitServiceId === 0 ? CREATE_UNITSERVICE : UPDATE_UNITSERVICE;
  let log = logEntry(UNITSERVICE_MANAGEMENT, actionName, unitservice);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  unitservice.OrganisationId = orgId;
  dispatch(saveUnitService(unitservice, url, log, widgetCode));
};

export const saveUnitService = (unitservice, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setUnitServiceStatusThunk(retData),
    onFailure: err => setUnitServiceErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      UnitServices: unitservice,
      log: log
    },
    widgetcode: widgetCode
  });

export const setUnitServiceStatusThunk = message => dispatch => {
  dispatch(setUnitServiceStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitServiceStatus(message, false));
  }, 2500);
};

export const setUnitServiceStatus = (messageCode, status) => ({
  type: UPDATE_UNITSERVICE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setUnitServiceChangeThunk = retData => dispatch => {
  dispatch(setUnitServiceChange(retData));
};

export const setUnitServiceChange = retData => ({
  type: SET_UNITSERVICE_CHANGE,
  payload: retData
});

export const setUnitServiceFilteredDataThunk = retData => dispatch => {
  dispatch(setUnitServiceFilteredData(retData));
};

export const setUnitServiceFilteredData = retData => ({
  type: SET_UNITSERVICE_FILTERED_RESULTS,
  payload: retData
});

export const getComponentPart = (unitId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/componentParts`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      UnitId: unitId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllComponentPartListThunk(retData),
    onFailure: err => setUnitServiceErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllComponentPartListThunk = retData => dispatch => {
  let componentParts = (retData && retData.retData.componentPart) || [];
  dispatch(setAllComponentPartList(componentParts));
};

export const setAllComponentPartList = componentParts => ({
  type: UNIT_SERVICING_COMPONENT_PARTS,
  payload: {
    selectedUnitService: {
      ComponentParts: componentParts
    }
  }
});

export const getUnitServiceFilterThunk = (widgetCode, includeUnit = true) => dispatch => {
  dispatch(getUnitServiceFilter(widgetCode, includeUnit));
};

export const getUnitServiceFilter = (widgetCode, includeUnit) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      fUnits: includeUnit ? true : null,
      fAssignees: true,
      filterFor: 'unitservice'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllUnitServiceFilterThunk(retData),
    onFailure: err => setUnitServiceErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllUnitServiceFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllUnitServiceFilter(messageCode, filterData, itemCount));
};

export const setAllUnitServiceFilter = (messageCode, filterData, itemCount) => ({
  type: SET_UNITSERVICE_FILTERS,
  payload: {
    data: {
      filterData: filterData,
      isLoading: false,
      servicesListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const cleanUpThunk = retData => dispatch => {
  dispatch(cleanUp(retData));
};

export const cleanUp = retData => ({
  type: UNITSERVICE_CLEANUP,
  payload: retData
});

export const getExportDataThunk = (filterObj, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(startExport());
  dispatch(getExportData(filterObj, widgetCode, orgId));
};

export const getExportData = (filterObj, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/maintenance/services/unitservices/list`,
    data: {
      ExportData: true,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      filterUnitId: filterObj.filterUnitId,
      filterServiceUser: filterObj.filterServiceUser,
      filterServiceFrom: filterObj.filterServiceFrom,
      filterServiceTo: filterObj.filterServiceTo,
      UserId: Cookies.get(`userid-${envName}`),
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      OrganisationId: orgId
    },
    method: 'GET',
    // onLoad: status => startExport(status),
    onSuccess: retData => setExportDataThunk(retData),
    onFailure: err => setUnitServiceErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const startExport = () => ({
  type: START_UNITSERVICE_EXPORT
});

export const setExportCompleteThunk = status => dispatch => {
  dispatch(setExportComplete(status));
};

export const setExportComplete = status => ({
  type: SET_COMPUTE_UNITSERVICE_EXPORT,
  payload: { status: status }
});

export const setExportDataThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let unitservices = (retData && retData.UnitServices) || [];
  dispatch(setExportData(messageCode, unitservices));
};

export const setExportData = (messageCode, Unitservices) => ({
  type: SET_UNITSERVICE_EXPORT_DATA,
  payload: {
    data: {
      exportedResults: Unitservices,
      exportComplete: true,
      displayMessageCode: messageCode,
      isOpSuccessful: true
    }
  }
});

export const setUnitServiceExportErrorThunk = err => dispatch => {
  dispatch(setUnitServiceError(err, true));
  setTimeout(() => {
    dispatch(setUnitServiceError(err, false));
  }, 2500);
};
