import React from 'react';
import s from './Calibration.module.scss';
import { FormattedMessage } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import uuidv4 from 'uuid/v4';
import Cookies from 'js-cookie';

const fixedDecimal = 2;

const CalibrationResults = ({ calibrationManager, setCalibrationResults, isViewMode, UpdateAllowed, intl }) => {
  const selectedCalibration = calibrationManager.SelectedCalibration;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const addNewResult = () => {
    const resultsRowNew = {
      CalibrationId: selectedCalibration.CalibrationId,
      CalibrationResultId: 0,
      GuidIdentifier: uuidv4(),
      Control: '',
      DesiredResult: null,
      PreCalResult: null,
      PostCalResult: null,
      isChanged: false,
      isDeleted: false,
      UpdatedBy: Cookies.get(`userid-${envName}`) || null
    };
    saveResultsHandler(resultsRowNew);
  };

  const calculateCalError = type => {
    let calError = '-';
    let calErrorValue = 0;
    let isZeroDetected = false;
    selectedCalibration.CalibrationResults.map(result => {
      const resCalResult = CalError(result, type, true);
      if (resCalResult !== null) {
        if (parseInt(resCalResult) === 0) {
          isZeroDetected = true;
        }
        calErrorValue += resCalResult;
      }
    });
    if (calErrorValue > 0 || (calErrorValue === 0 && isZeroDetected)) {
      calError = (calErrorValue / selectedCalibration.CalibrationResults.length).toFixed(fixedDecimal).toString() + '%';
    }

    return calError;
  };

  const saveResultsHandler = calibrationResult => {
    let listData = selectedCalibration.CalibrationResults;

    if (calibrationResult.CalibrationResultId > 0) {
      const updatedData = listData.map(x => (x.GuidIdentifier === calibrationResult.GuidIdentifier ? { ...x, ...calibrationResult } : x));
      setCalibrationResults({
        CalibrationResults: updatedData
      });
    } else {
      let hasItem = listData.filter(item => {
        if (item.GuidIdentifier === calibrationResult.GuidIdentifier) return item;
      });

      if (hasItem.length > 0) {
        const updatedData = listData.map(x => (x.GuidIdentifier === calibrationResult.GuidIdentifier ? { ...x, ...calibrationResult } : x));
        setCalibrationResults({
          CalibrationResults: updatedData
        });
      } else {
        listData.push(calibrationResult);
        setCalibrationResults({
          CalibrationResults: listData
        });
      }
    }
  };

  return (
    <div className={s.calibration}>
      <div className={s.contentWrapper}>
        <div className={s.calibrationResults}>
          <Row className={s.standardRow}>
            <Col sm={6} lg={8}>
              <h4 className={s.subheading}>
                <FormattedMessage id="calibration.results" defaultMessage="Results" />
              </h4>
            </Col>
            <Col sm={6} lg={4}>
              {!isViewMode && (
                <Button
                  className={s.addbutton}
                  // style={WidgetVisibility(userProfileWidgets, 'CALIBRATIONEQUATIONADD')}
                  onClick={addNewResult}
                  // disabled={disableMode}
                  data-unittest="addReultsButton"
                >
                  <FormattedMessage id="calibration.addResults" defaultMessage="ADD NEW" />
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Table variant className={s.innerTable}>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="calibration.controlRange" defaultMessage="Control Range" />
                    </th>
                    <th>
                      <FormattedMessage id="calibration.desiredResults" defaultMessage="Desired Results" />
                    </th>
                    <th>
                      <FormattedMessage id="calibration.preCalResults" defaultMessage="Pre-Cal Results" />
                    </th>
                    <th>
                      <FormattedMessage id="calibration.error" defaultMessage="Error" />
                    </th>
                    <th>
                      <FormattedMessage id="calibration.postCalResults" defaultMessage="Post-Cal Results" />
                    </th>
                    <th>
                      <FormattedMessage id="calibration.error" defaultMessage="Error" />
                    </th>
                    <th className={s.emptyCol}></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCalibration.CalibrationResults.map(resultsRow => {
                    if (resultsRow.isDeleted !== true) {
                      if (!isViewMode) {
                        return <ResultRow key={resultsRow.GuidIdentifier} resultRow={resultsRow} saveResults={saveResultsHandler} />;
                      } else {
                        return <ResultRowView key={resultsRow.GuidIdentifier} resultRow={resultsRow} />;
                      }
                    }
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className={s.preCalErrorRow}>
            <Col md={2} className={s.preCalErrorRowPadding}>
              <Form.Label>
                <FormattedMessage id="calibration.preCalError" defaultMessage="Pre-Cal Error" />
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Label>{calculateCalError('PRECAL')}</Form.Label>
            </Col>
          </Row>
          <Row className={s.postCalErrorRow}>
            <Col md={2} className={s.postCalErrorRowPadding}>
              <Form.Label>
                <FormattedMessage id="calibration.postCalError" defaultMessage="Post-Cal Error" />
              </Form.Label>
            </Col>
            <Col md={4}>
              <Form.Label>{calculateCalError('POSTCAL')}</Form.Label>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CalibrationResults;

const CalError = (resultRow, type, resultTypeNumber = false) => {
  let errorPercentage = '';
  let errorPercentageValue = null;

  const divisibleDesiredResult = parseFloat(resultRow.DesiredResult) === 0 ? 1 : resultRow.DesiredResult;
  if (!divisibleDesiredResult) {
    return '-';
  }

  if (type === 'PRECAL') {
    if (resultRow.DesiredResult !== null && resultRow.PreCalResult) {
      const error = ((resultRow.DesiredResult - resultRow.PreCalResult) / divisibleDesiredResult) * 100;

      errorPercentageValue = Math.abs(error);
      errorPercentage =
        Math.abs(error)
          .toFixed(fixedDecimal)
          .toString() + '%';
    }
  }
  if (type === 'POSTCAL') {
    if (resultRow.DesiredResult !== null && resultRow.PostCalResult) {
      const error = ((resultRow.DesiredResult - resultRow.PostCalResult) / divisibleDesiredResult) * 100;
      errorPercentageValue = Math.abs(error);
      errorPercentage =
        Math.abs(error)
          .toFixed(fixedDecimal)
          .toString() + '%';
    }
  }
  if (resultTypeNumber) {
    return errorPercentageValue;
  } else {
    return errorPercentage;
  }
};

const ResultRow = ({ resultRow, saveResults }) => {
  const removeHandler = () => {
    saveResults({ ...resultRow, isDeleted: true });
  };

  const onChange = e => {
    saveResults({ ...resultRow, [e.target.name]: e.target.value, isChanged: true });
  };

  return (
    <tr>
      <td>
        <Form.Control type="text" name="Control" onChange={onChange} value={resultRow.Control} className={s.textInput} />
      </td>
      <td>
        <InputNumber
          name="DesiredResult"
          step=".1"
          onInputChange={onChange}
          customClassName={s.textResultInput}
          value={resultRow.DesiredResult?.toString()}
          id="formDesiredResult"
        />
      </td>
      <td>
        <InputNumber
          name="PreCalResult"
          step=".1"
          onInputChange={onChange}
          customClassName={s.textResultInput}
          value={resultRow.PreCalResult?.toString()}
          id="formPreCalResult"
        />
      </td>
      <td className={s.cellClass}>
        <div className={s.errorCalCell}>{CalError(resultRow, 'PRECAL')}</div>
      </td>
      <td>
        <InputNumber
          name="PostCalResult"
          step=".1"
          onInputChange={onChange}
          customClassName={s.textResultInput}
          value={resultRow.PostCalResult?.toString()}
          id="formPostCalResult"
        />
      </td>
      <td className={s.cellClass}>
        <div className={s.errorCalCell}> {CalError(resultRow, 'POSTCAL')} </div>
      </td>
      <td className={s.emptyCol}></td>
      <td className={s.cellClass}>
        <div className={s.errorCalCell}>
          <Button
            variant="outline-secondary"
            className={s.removeButton}
            href="#"
            onClick={removeHandler.bind(this)}
            data-unittest="changeData"
          >
            <FormattedMessage id="calibration.remove" defaultMessage="Remove" />
          </Button>
        </div>
      </td>
    </tr>
  );
};

const ResultRowView = ({ resultRow }) => {
  return (
    <tr>
      <td>
        <div>{resultRow.Control}</div>
      </td>
      <td>
        <div>{resultRow.DesiredResult}</div>
      </td>
      <td>
        <div>{resultRow.PreCalResult}</div>
      </td>
      <td>
        <div>{CalError(resultRow, 'PRECAL')}</div>
      </td>
      <td>
        <div>{resultRow.PostCalResult}</div>
      </td>
      <td>{CalError(resultRow, 'POSTCAL')}</td>
      <td className={s.emptyCol}></td>
    </tr>
  );
};
