import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelConfiguration.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { initialModelState } from '../../reducers/initialState';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import {
  getModelTelemetryLinks,
  getComponentPositionsModel,
  getModelVersionManager,
  getModelFirmwareVersionManager
} from '../../selectors/index';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import { WidgetVisibility } from '../../utils/widgetManager';
import ModelVersionList from '../ModelVersionList/ModelVersionListContainer';
import ModelFirmwareVersionList from '../ModelFirmwareVersionList/ModelFirmwareVersionListContainer';
import ComponentPositionList from '../ComponentPositionModel/ComponentPositionModelContainer';
import TelemetryLinksList from '../ModelTelemetryLink/ModelTelemetryLinkList';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const ModelConfiguration = ({ modelManager, describeModel, userProfileWidgets, intl }) => {
  let { ModelId } = useParams();
  const [modelVersionOpen, setModelVersionOpen] = useState(false);
  const [modelFirmwareVersionOpen, setModelFirmwareVersionOpen] = useState(false);
  const [componentPositionOpen, setComponentPositionOpen] = useState(false);
  const [telemetryLinksOpen, setTelemetryLinksOpen] = useState(false);
  const telemetryLinkManager = useSelector(state => getModelTelemetryLinks(state));
  const componentPositionManager = useSelector(state => getComponentPositionsModel(state));
  const modelVersionManager = useSelector(state => getModelVersionManager(state));
  const modelFirmwareVersionManager = useSelector(state => getModelFirmwareVersionManager(state));

  useEffect(() => {
    describeModel(ModelId);
  }, [describeModel, ModelId]);

  let heading = intl.formatMessage({ id: 'model.configureModel', defaultMessage: 'Configure' });

  let messageId = (modelManager && modelManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const modelName = (modelManager.selectedModel ? modelManager.selectedModel.ModelName : '') || '';

  return (
    <div className={s.modelConfiguration}>
      <AdminControlsContentTemplate selectedPage="manageModels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          {modelManager.isLoading && <LoadingSpinner />}
          <div className={s.modelHeader}>
            <Row>
              <Col xs={12} md={6}>
                <Link className={s.backLink} to={'/admin-controls/model-list'}>
                  &lt; &nbsp;
                  <FormattedMessage id="model.backToModels" defaultMessage="BACK TO MODELS" />
                </Link>
                <h3 data-unittest="headingLabel">
                  {heading} {modelName} : {modelManager.selectedModel.Name}
                </h3>
              </Col>
            </Row>
          </div>
          <div className={s.modelContent}>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONLIST')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={modelVersionOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setModelVersionOpen(!modelVersionOpen)}
                      aria-controls="model-version-section"
                      aria-expanded={modelVersionOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="model.modelversion" defaultMessage="Versions" />
                    </h4>
                    <span className={s.circle}>{modelVersionManager.modelVersions.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={modelVersionOpen}>
                <div className={s.innerComponent} id="model-version-section">
                  <ModelVersionList />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELCOMPONENTPOSITIONLIST')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={componentPositionOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setComponentPositionOpen(!componentPositionOpen)}
                      aria-controls="component-position-section"
                      aria-expanded={componentPositionOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="model.componentpositions" defaultMessage="Component Positions" />
                    </h4>
                    <span className={s.circle}>{componentPositionManager.componentPositionModels.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={componentPositionOpen}>
                <div className={s.innerComponent} id="component-position-section">
                  <ComponentPositionList ModelId={ModelId} userProfileWidgets={userProfileWidgets} />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODEL_TELEMETRY_LINKS')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={telemetryLinksOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setTelemetryLinksOpen(!telemetryLinksOpen)}
                      aria-controls="telemetry-links-section"
                      aria-expanded={telemetryLinksOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="model.telemetryLinks.title" defaultMessage="Component Position Telemetry Links" />
                    </h4>
                    <span className={s.circle}>{telemetryLinkManager.TelemetryLinks.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={telemetryLinksOpen}>
                <div className={s.innerComponent} id="telemetry-links-section">
                  <TelemetryLinksList ModelId={ModelId} userProfileWidgets={userProfileWidgets} />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONLIST')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={modelFirmwareVersionOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setModelFirmwareVersionOpen(!modelFirmwareVersionOpen)}
                      aria-controls="model-firmware-version-section"
                      aria-expanded={modelFirmwareVersionOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="model.modelFirmwareVersion" defaultMessage="Model Firmware Versions" />
                    </h4>
                    <span className={s.circle}>{modelFirmwareVersionManager.modelFirmwareVersions.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={modelFirmwareVersionOpen}>
                <div className={s.innerComponent} id="model-firmware-version-section">
                  <ModelFirmwareVersionList />
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ModelConfiguration.defaultProps = {
  modelManager: {
    ...initialModelState.modelManager
  }
};

ModelConfiguration.propTypes = {
  modelManager: PropTypes.object.isRequired,
  describeModel: PropTypes.func.isRequired,
  userProfileWidgets: PropTypes.object
};

export default injectIntl(ModelConfiguration);
