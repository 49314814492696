import React, { Fragment, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionUnits.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ModelVersionUnits from './ModelVersionUnits';
import { Link } from 'react-router-dom';
import { WidgetVisibility } from '../../utils/widgetManager';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ModelVersionUnitsListTable = ({
  modelVersionUnitManager,
  allocatedUnits,
  ModelId,
  saveModelVersionUnits,
  setModelVersionUnitsChange,
  disableMode,
  cancelHandler,
  userProfileWidgets,
  intl
}) => {
  const editModelVersionUnitContent = (
    <ModelVersionUnits
      ModelId={ModelId}
      saveModelVersionUnits={saveModelVersionUnits}
      modelVersionUnitManager={modelVersionUnitManager}
      setModelVersionUnitsChange={setModelVersionUnitsChange}
      cancelHandler={cancelHandler}
    />
  );

  useLayoutEffect(() => {
    if (disableMode) {
      const scrollPos = window.scrollY + window.innerHeight;
      window.scrollTo(0, scrollPos);
    }
  }, [disableMode]);

  let newRowClass = modelVersionUnitManager.showAddEditScreen ? s.newRow : '';

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="modelVersion.units.unitNumber" defaultMessage="Unit Number" />
            </th>
            <th>
              <FormattedMessage id="modelVersion.units.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="modelVersion.units.deviceSerialNumber" defaultMessage="Device Serial Number" />
            </th>
            <th>
              <FormattedMessage id="modelVersion.units.installationStreetAddress" defaultMessage="Address" />
            </th>
            <th>
              <FormattedMessage id="modelVersion.units.installationCity" defaultMessage="City" />
            </th>
            <th>
              <FormattedMessage id="modelVersion.units.installationSuburbLocation" defaultMessage="Suburb/Location" />
            </th>
            <th>
              <FormattedMessage id="modelVersion.units.installationCountry" defaultMessage="Country" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {allocatedUnits.map(n => {
            return (
              <Fragment key={n.UnitId}>
                <tr key={n.UnitId} data-unittest="allocatedUnits">
                  <td>{n.UnitSerialNumber}</td>
                  <td>{n.Name}</td>
                  <td>{n.DeviceSerialNumber}</td>
                  <td>{n.InstallationStreetAddress || '-'}</td>
                  <td>{n.InstallationCity || '-'}</td>
                  <td>{n.InstallationRegion || '-'}</td>
                  <td>{n.InstallationCountry || '-'}</td>
                  <td className={s.btnCol}>
                    <Link to={`/unit/process-flow/${n.UnitSerialNumber}`}>
                      <Button
                        variant="outline-secondary"
                        className={s.viewButton}
                        disabled={disableMode}
                        style={WidgetVisibility(userProfileWidgets, `MODELVERSION_UNIT_UNITVIEW`)}
                      >
                        <FormattedMessage id="modelVersion.units.view" defaultMessage="VIEW" />
                      </Button>
                    </Link>
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className={newRowClass}>
        <Row>
          <Col lg={12}>{modelVersionUnitManager.showAddEditScreen && editModelVersionUnitContent}</Col>
        </Row>
      </div>
    </div>
  );
};

ModelVersionUnitsListTable.defaultProps = {
  allocatedUnits: []
};

ModelVersionUnitsListTable.propTypes = {
  selectedModelComponentPositionLinkId: PropTypes.number,
  saveModelVersionUnits: PropTypes.func.isRequired,
  modelVersionUnitManager: PropTypes.object.isRequired,
  setModelVersionUnitsChange: PropTypes.func.isRequired,
  allocatedUnits: PropTypes.array,
  disableMode: PropTypes.bool
};

export default injectIntl(ModelVersionUnitsListTable);
