import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  NOTIFICATIONS_ERROR,
  SET_NOTIFICATION_TYPE_USERS,
  NOTIFICATIONS_IS_LOADING,
  SET_NOTIFICATIONS_TYPE_USERS_CHANGE,
  SET_NOTIFICATION_TYPES,
  UPDATE_NOTIFICATION_TYPE_USERS_SAVE_STATUS,
  NOTIFICATIONS_CLEANUP,
  SET_NOTIFICATION_HISTORY,
  RESET_NOTIFICATION_ALERT,
  SET_NOTIFICATIONS_FOR_BOARD,
  SET_NOTIFICATION_HISTORY_CURRENT_PAGE
} from '../constants/index';
import { NOTIFICATION_MANAGEMENT, UPDATE_NOTIFICATION_USERS } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setNotificationsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setNotificationsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setNotificationsError(messageCode, false));
  }, 2500);
};

export const setNotificationsError = (messageCode, status) => {
  return {
    type: NOTIFICATIONS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: NOTIFICATIONS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getNotificationTypesThunk = widget => dispatch => {
  dispatch(getNotificationTypes(widget));
};

export const getNotificationTypes = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_NOTIFICATIONS}/management/notifications/notificationtypes`,
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setNotificationTypesThunk(retData),
    onFailure: err => setNotificationsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setNotificationTypesThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let notificationTypes = (retData && retData.retData.data) || {};
  dispatch(setNotificationTypes(messageCode, notificationTypes));
};

export const setNotificationTypes = (messageCode, notificationTypes) => ({
  type: SET_NOTIFICATION_TYPES,
  payload: {
    data: {
      notificationTypes: notificationTypes,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const saveNotificationTypeUsersThunk = (notificationUsers, widgetCode) => dispatch => {
  let url = '/management/notifications/users/save';
  notificationUsers.UpdatedBy = Cookies.get(`userid-${envName}`);
  let actionName = UPDATE_NOTIFICATION_USERS;
  let log = logEntry(NOTIFICATION_MANAGEMENT, actionName, notificationUsers);
  dispatch(saveNotificationsSettings(notificationUsers, url, log, widgetCode));
};

export const saveNotificationsSettings = (notificationUsers, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_NOTIFICATIONS}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setNotificationUsersSaveStatusThunk(retData),
    onFailure: err => setNotificationsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      notificationUsers: notificationUsers,
      log: log
    },
    widgetcode: widgetCode
  });

export const setNotificationUsersSaveStatusThunk = retData => dispatch => {
  let messageCode = retData;
  dispatch(setNotificationUsersSaveStatus(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setNotificationUsersSaveStatus(messageCode, false));
  }, 2500);
};

export const setNotificationUsersSaveStatus = (messageCode, status) => ({
  type: UPDATE_NOTIFICATION_TYPE_USERS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setNotificationUsersChangeThunk = retData => dispatch => {
  dispatch(setNotificationUsersChange(retData));
};

export const setNotificationUsersChange = retData => ({
  type: SET_NOTIFICATIONS_TYPE_USERS_CHANGE,
  payload: retData
});

export const getNotificationTypeUsersThunk = (widget, notificationTypeId) => dispatch => {
  dispatch(getNotificationUsers(widget, notificationTypeId));
};

export const getNotificationUsers = (widgetCode, notificationTypeId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_NOTIFICATIONS}/management/notifications/users`,
    data: {
      UserId: Cookies.get(`userid-${envName}`) || 0,
      NotificationTypeId: notificationTypeId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setNotificationTypeUsersThunk(retData),
    onFailure: err => setNotificationsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setNotificationTypeUsersThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  dispatch(setNotificationTypeUsers(messageCode, retData.retData.data, retData.retData.notificationType[0]));
};

export const setNotificationTypeUsers = (messageCode, notificationTypeUsers, notificationType) => ({
  type: SET_NOTIFICATION_TYPE_USERS,
  payload: {
    data: {
      notificationTypeUsers: notificationTypeUsers,
      selectedNotificationType: notificationType,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const cleanUpThunk = retData => dispatch => {
  dispatch(cleanUp(retData));
};

export const cleanUp = retData => ({
  type: NOTIFICATIONS_CLEANUP,
  payload: retData
});

export const getNotificationsForBoard = (widgetCode, isPolling = false) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_NOTIFICATIONS}/management/notifications/history`,
    data: {
      UserId: Cookies.get(`userid-${envName}`) || ''
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(false),
    onSuccess: retData => setNotificationsForBoardThunk(retData, isPolling),
    onFailure: err => setNotificationsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setNotificationsForBoardThunk = (retData, isPolling) => dispatch => {
  let messageCode = retData && retData.message;
  let notificationsHistory = (retData && retData.retData.data) || {};
  dispatch(setNotificationsForBoard(messageCode, notificationsHistory, isPolling));
};

export const setNotificationsForBoard = (messageCode, notificationsHistory, isPolling) => ({
  type: SET_NOTIFICATIONS_FOR_BOARD,
  payload: {
    data: {
      notificationsHistory: notificationsHistory,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    },
    isPolling: isPolling
  }
});

export const notificationPollingThunk = widget => dispatch => {
  dispatch(getNotificationsForBoard(widget, true));
};

export const getNotificationHistory = (widgetCode, filterData) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_NOTIFICATIONS}/management/notifications/history`,
    data: {
      UserId: Cookies.get(`userid-${envName}`) || '',
      filterDateFrom: filterData.filterDateFrom,
      filterDateTo: filterData.filterDateTo
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setNotificationHistoryThunk(retData),
    onFailure: err => setNotificationsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setNotificationHistoryThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let notificationsList = (retData && retData.retData.data) || {};
  dispatch(setNotificationHistory(messageCode, notificationsList));
};

export const setNotificationHistory = (messageCode, notificationsList) => ({
  type: SET_NOTIFICATION_HISTORY,
  payload: {
    data: {
      notificationsList: notificationsList,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const getNotificationHistoryThunk = (widget, filterData) => dispatch => {
  dispatch(getNotificationHistory(widget, filterData));
};

export const resetNotificationAlertThunk = () => dispatch => {
  dispatch(resetNotificationAlert());
};

export const resetNotificationAlert = () => ({
  type: RESET_NOTIFICATION_ALERT,
  payload: true
});

export const setCurrentPage = currentPage => ({
  type: SET_NOTIFICATION_HISTORY_CURRENT_PAGE,
  payload: { currentPage }
});
