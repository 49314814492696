import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';
import apiMiddleware from './middleware/apiMiddleware';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), apiMiddleware, thunk))
  );

  return store;
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return {};
    return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    // ignore write errors
  }
};

export const filterOfflineChartData = charts => {
  // filter out the actual data readings so we only store the chart config
  return Object.keys(charts).reduce((acc, curr) => {
    !(curr in acc) && (acc[curr] = {});
    Object.keys(charts[curr]).forEach(chart => {
      acc[curr][chart] = { ...charts[curr][chart], data: [] };
    });
    return acc;
  }, {});
};
