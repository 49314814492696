import { initialReleaseNotesState } from './initialState';

import {
  RELEASENOTES_ERROR,
  GET_ALL_RELEASENOTES_DETAILS,
  RELEASENOTES_IS_LOADING,
  SET_RELEASENOTES_CURRENT_PAGE,
  SET_RELEASENOTES_PAGE_FILTER,
  SET_RELEASENOTES_CHANGE,
  SET_RELEASENOTES_CLEANUP,
  SET_RELEASENOTE,
  SET_RELEASENOTES_VIEW,
  UPDATE_RELEASENOTES_SAVE_STATUS,
  SET_RELEASENOTES_FILTERED_RESULTS
} from '../constants/index';

const releaseNotesReducer = (state = initialReleaseNotesState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_RELEASENOTES_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          ...data
        }
      };
    }

    case SET_RELEASENOTES_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          currentPage
        }
      };
    }

    case SET_RELEASENOTES_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          filter
        }
      };
    }

    case SET_RELEASENOTES_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          ...filteredResults
        }
      };
    }

    case UPDATE_RELEASENOTES_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          ...data
        }
      };
    }

    case RELEASENOTES_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          isLoading: isLoading
        }
      };
    }

    case RELEASENOTES_ERROR: {
      const { data } = payload;
      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          ...data
        }
      };
    }

    case SET_RELEASENOTE: {
      let { data } = payload;

      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          isOpSuccessful: false,
          showBanner: false,
          selectedReleaseNote: {
            ...state.releaseNote.selectedReleaseNote,
            ...data
          }
        }
      };
    }

    case SET_RELEASENOTES_VIEW: {
      let { data } = payload;

      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          isOpSuccessful: false,
          showBanner: false,
          viewReleaseNotes: data,
          isRedirect: false
        }
      };
    }

    case SET_RELEASENOTES_CHANGE: {
      let { selectedReleaseNote } = payload;

      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          isOpSuccessful: false,
          showBanner: false,
          selectedReleaseNote: {
            ...state.releaseNote.selectedReleaseNote,
            ...selectedReleaseNote
          }
        }
      };
    }
    case SET_RELEASENOTES_CLEANUP: {
      let selectedReleaseNote = payload;
      return {
        ...state,
        releaseNote: {
          ...state.releaseNote,
          releaseNotes: [],
          viewReleaseNotes: [],
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          selectedReleaseNote: selectedReleaseNote
        }
      };
    }

    default:
      return state;
  }
};

export default releaseNotesReducer;
