import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './Alarms.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialAlarmsState } from '../../reducers/initialState';
import AlarmsListTable from './AlarmsListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';

const EVENT_CODE = 'EVENT_CODE';
const SENSOR = 'SENSOR';
const STATUS = 'STATUS';
const UNIT = 'UNIT';
const ASSIGNE = 'ASSIGNE';
const SEARCH_FILTER = 'SEARCH_FILTER';

const Alarms = ({
  getAlarm,
  getAlarmFilters,
  setFilterData,
  setCurrentPage,
  setPageFilter,
  alarms,
  alarmsStatus,
  redirect,
  intl,
  userProfileWidgets,
  currentTimezone,
  setSelectedUnit
}) => {
  let filter = alarms.filterBy.filter || '';
  let offset = alarms.offset || 0;
  let limit = alarms.limit || 1000;

  let filterRedEventCode = alarms.filterBy.filterEventCode || null;
  let filterRedSensor = alarms.filterBy.filterSensor || null;
  let filterRedStatus = alarms.filterBy.filterStatus || null;
  let filterRedUnit = alarms.filterBy.filterUnit || null;
  let filterRedAssignee = alarms.filterBy.filterAssignee || null;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterEventCode, setFilterEventCode] = useState('0');
  const [filterSensor, setFilterSensor] = useState(0);
  const [filterStatus, setFilterStatus] = useState('0');
  const [filterUnit, setFilterUnit] = useState(0);
  const [filterAssignee, setFilterAssignee] = useState(0);

  const [asendingOrder, setAsendingOrder] = useState(false);

  useEffect(() => {
    let filterObj = {
      filterEventCode: filterRedEventCode,
      filterSensor: filterRedSensor,
      filterStatus: filterRedStatus,
      filterUnit: filterRedUnit,
      filterAssignee: filterRedAssignee,
      offset: offset,
      limit: limit
    };
    getAlarm(filterObj);
  }, [getAlarm, offset, limit, filter, filterRedEventCode, filterRedSensor, filterRedStatus, filterRedUnit, filterRedAssignee]);

  useEffect(() => {
    getAlarmFilters();
  }, [getAlarmFilters]);

  const eventCodeOptions = () => {
    let eventCodesData = [];
    eventCodesData.push({
      label: <FormattedMessage id="alarms.allEvenCodes" defaultMessage="All Event Codes" />,
      value: '0'
    });

    alarms.filterData.EventCodes.forEach(element => {
      eventCodesData.push({
        label: element.Code,
        value: element.Code
      });
    });
    return eventCodesData;
  };

  const sensorOptions = () => {
    let sensorsData = [];

    sensorsData.push({
      label: <FormattedMessage id="alarms.allSensors" defaultMessage="All Sensors" />,
      value: 0
    });

    alarms.filterData.Sensors.forEach(element => {
      sensorsData.push({
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return sensorsData;
  };

  const statusOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="alarms.allStatus" defaultMessage="All Status" />,
      value: '0'
    });

    alarmsStatus.forEach(element => {
      statusData.push({
        label: element.value,
        value: element.text
      });
    });
    return statusData;
  };

  const unitOptions = () => {
    let unitData = [];

    unitData.push({
      label: <FormattedMessage id="alarms.allUnits" defaultMessage="All Units" />,
      value: 0
    });

    alarms.filterData.Units.forEach(element => {
      unitData.push({
        label: element.Name,
        value: element.UnitId
      });
    });
    return unitData;
  };

  const assigneeOptions = () => {
    let assigneeData = [];

    assigneeData.push({
      label: <FormattedMessage id="alarms.allAssignee" defaultMessage="All Assignee" />,
      value: 0
    });

    alarms.filterData.Assignees.forEach(element => {
      assigneeData.push({
        label: element.FullName,
        value: element.UserId
      });
    });
    return assigneeData;
  };

  const onEventCodeChange = e => {
    setFilterEventCode(e.value);
    filteredResults(EVENT_CODE, e.value);
    setCurrentPage(1);
  };

  const onSensorChange = e => {
    setFilterSensor(e.value);
    filteredResults(SENSOR, e.value);
    setCurrentPage(1);
  };

  const onStatusChange = e => {
    setFilterStatus(e.value);
    filteredResults(STATUS, e.value);
    setCurrentPage(1);
  };

  const onUnitChange = e => {
    setFilterUnit(e.value);
    filteredResults(UNIT, e.value);
    setCurrentPage(1);
  };

  const onAssigneeChange = e => {
    setFilterAssignee(e.value);
    filteredResults(ASSIGNE, e.value);
    setCurrentPage(1);
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
    // setPageFilter(filter);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  let alarmsObj = Object.values(alarms.filteredResults);

  if (asendingOrder) {
    alarmsObj = alarmsObj.sort(compareValues('EventDateTime'));
  } else {
    alarmsObj = alarmsObj.sort(compareValues('EventDateTime', 'desc'));
  }

  const filteredResults = (filterType, value) => {
    let filteredList = alarms.alarmsList;

    let apifilterObj = {
      filter: null,
      filterEventCode: null,
      filterSensor: null,
      filterStatus: null,
      filterUnit: null,
      filterAssignee: null
    };

    if (filterType === EVENT_CODE) {
      if (value !== '0') {
        apifilterObj.filterEventCode = value;
        filteredList = filteredList.filter(function(item) {
          return item.EventCode === value;
        });
      }
    } else {
      if (filterEventCode !== '0') {
        apifilterObj.filterEventCode = filterEventCode;
        filteredList = filteredList.filter(function(item) {
          return item.EventCode === filterEventCode;
        });
      }
    }

    if (filterType === SENSOR) {
      if (value !== 0) {
        apifilterObj.filterSensor = value;
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentPositionId === value;
        });
      }
    } else {
      if (filterSensor !== 0) {
        apifilterObj.filterSensor = filterSensor;
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentPositionId === filterSensor;
        });
      }
    }

    if (filterType === STATUS) {
      if (value !== '0') {
        apifilterObj.filterStatus = value;
        filteredList = filteredList.filter(function(item) {
          return item.EventStatus === value;
        });
      }
    } else {
      if (filterStatus !== '0') {
        apifilterObj.filterStatus = filterStatus;
        filteredList = filteredList.filter(function(item) {
          return item.EventStatus === filterStatus;
        });
      }
    }

    if (filterType === UNIT) {
      if (value !== 0) {
        apifilterObj.filterUnit = value;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === value;
        });
      }
    } else {
      if (filterUnit !== 0) {
        apifilterObj.filterUnit = filterUnit;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === filterUnit;
        });
      }
    }

    if (filterType === ASSIGNE) {
      if (value !== 0) {
        apifilterObj.filterAssignee = value;
        filteredList = filteredList.filter(function(item) {
          return item.EventActionAssignedToUserId === value;
        });
      }
    } else {
      if (filterAssignee !== 0) {
        apifilterObj.filterAssignee = filterAssignee;
        filteredList = filteredList.filter(function(item) {
          return item.EventActionAssignedToUserId === filterAssignee;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.EventTitle.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.EventTitle.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (alarms.alarmListCount > alarms.alarmsList.length) {
      setPageFilter(apifilterObj);
    } else {
      setFilterData({ filteredResults: { filteredResults: filteredList } });
    }
  };

  let messageId = (alarms && alarms.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'ALARMMANAGE');

  return (
    <div>
      <div className={s.fullWidth}>
        <div className={s.fullWidthWrapper}>
          <h1 className={s.tabHeader}>{<FormattedMessage id="alarms.alarms" defaultMessage="Alarms" />}</h1>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.alarms}>
          {alarms.isLoading && <LoadingSpinner />}
          <Banner failureText={messageText} showBanner={alarms.showBanner} status={alarms.isOpSuccessful} successText={messageText} />
          <div className={s.contentWrapper}>
            <Row>
              <Col sm={12}>
                <span className={s.textHeading}>
                  <FormattedMessage id="alarms.search" defaultMessage="Search" />
                </span>
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={4}>
                <SearchBar
                  searchHandler={searchHandler}
                  clearSearchInVisible={false}
                  initialText={searchFilter}
                  placeHolderTextId="alarms.alarmSearchName"
                />
              </Col>
              <Col sm={6} lg={8}></Col>
            </Row>
            <Row>
              <Col sm={12}>
                <span className={s.textHeading}>
                  {' '}
                  <FormattedMessage id="alarms.filterBy" defaultMessage="Filter By" />
                </span>
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpEventId"
                  dataArray={eventCodeOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="alarms.eventCode" defaultMessage="Event Code" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onEventCodeChange(e)}
                  selectedOption={eventCodeOptions().filter(option => option.value === filterEventCode)}
                  disabled={false}
                />
              </Col>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpSensorId"
                  dataArray={sensorOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="alarms.sensor" defaultMessage="Sensor" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onSensorChange(e)}
                  selectedOption={sensorOptions().filter(option => option.value === filterSensor)}
                  disabled={false}
                />
              </Col>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpStatusId"
                  dataArray={statusOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="alarms.status" defaultMessage="Status" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onStatusChange(e)}
                  selectedOption={statusOptions().filter(option => option.value === filterStatus)}
                  disabled={false}
                />
              </Col>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpUnitId"
                  dataArray={unitOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="alarms.unit" defaultMessage="Unit" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onUnitChange(e)}
                  selectedOption={unitOptions().filter(option => option.value === filterUnit)}
                  disabled={false}
                />
              </Col>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpAssignee"
                  dataArray={assigneeOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="alarms.assignee" defaultMessage="Assignee" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onAssigneeChange(e)}
                  selectedOption={assigneeOptions().filter(option => option.value === filterAssignee)}
                  disabled={false}
                />
              </Col>
              <Col sm={6} lg={2}></Col>
            </Row>

            <div className="">
              <Pagination
                data={alarmsObj}
                pageSize={DEFAULT_PAGE_SIZE}
                onPageChange={onPageChangeHandler}
                startingPage={alarms.currentPage}
              >
                <AlarmsListTable
                  redirect={redirect}
                  UpdateAllowed={isUpdateAllowed}
                  currentPage={alarms.currentPage}
                  currentTimezone={currentTimezone}
                  onSortOrder={onSortOrder}
                  asendingOrder={asendingOrder}
                  setSelectedUnit={setSelectedUnit}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Alarms.defaultProps = {
  ...initialAlarmsState
};

Alarms.propTypes = {
  getAlarm: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(Alarms);
