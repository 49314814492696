import { initialTaskTemplatesState } from './initialState';

import {
  TASK_TEMPLATE_ERROR,
  GET_ALL_TASK_TEMPLATE_DETAILS,
  TASK_TEMPLATE_IS_LOADING,
  SET_TASK_TEMPLATE_CURRENT_PAGE,
  SET_TASK_TEMPLATE_PAGE_FILTER,
  SET_TASK_TEMPLATE_CHANGE,
  SET_TASK_TEMPLATE,
  UPDATE_TASK_TEMPLATE_SAVE_STATUS
} from '../constants/index';

const taskTemplateReducer = (state = initialTaskTemplatesState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_TASK_TEMPLATE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        taskTemplate: {
          ...state.taskTemplate,
          ...data
        }
      };
    }

    case SET_TASK_TEMPLATE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        taskTemplate: {
          ...state.taskTemplate,
          currentPage
        }
      };
    }

    case SET_TASK_TEMPLATE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        taskTemplate: {
          ...state.taskTemplate,
          filter
        }
      };
    }

    case UPDATE_TASK_TEMPLATE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        taskTemplate: {
          ...state.taskTemplate,
          ...data
        }
      };
    }

    case TASK_TEMPLATE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        taskTemplate: {
          ...state.taskTemplate,
          isLoading: isLoading
        }
      };
    }

    case TASK_TEMPLATE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        taskTemplate: {
          ...state.taskTemplate,
          ...data
        }
      };
    }

    case SET_TASK_TEMPLATE: {
      let { SelectedTaskTemplate, isOpSuccessful } = payload;

      return {
        ...state,
        taskTemplate: {
          ...state.taskTemplate,
          SelectedTaskTemplate: {
            ...SelectedTaskTemplate
          },
          isOpSuccessful: isOpSuccessful
        }
      };
    }

    case SET_TASK_TEMPLATE_CHANGE: {
      let { SelectedTaskTemplate } = payload;

      return {
        ...state,
        taskTemplate: {
          ...state.taskTemplate,
          SelectedTaskTemplate: {
            ...state.taskTemplate.SelectedTaskTemplate,
            ...SelectedTaskTemplate
          }
        }
      };
    }

    default:
      return state;
  }
};

export default taskTemplateReducer;
