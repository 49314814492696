import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ApprovedComponentView from './ApprovedComponentView';
import { getUserProfileWidget, getApprovedComponent } from '../../selectors/index';

import { describeApprovedComponentThunk, resetModelComponentThunk } from '../../actions/approvedComponents';

const mapStateToProps = state => {
  return {
    approvedComponent: getApprovedComponent(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeApprovedComponent: (approvedComponentId, widgetCode) => describeApprovedComponentThunk(approvedComponentId, widgetCode),
      resetModelComponent: approvedComponent => resetModelComponentThunk(approvedComponent)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedComponentView);
