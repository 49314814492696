import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ComponentPositionModel.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelState } from '../../reducers/initialState';
import ComponentPositionModelListTable from '../ComponentPositionModel/ComponentPositionModelListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const ComponentPositionModelList = ({
  ComponentPositionsManager,
  intl,
  ModelId,
  userProfileWidgets,
  modelComponentPositions,
  getComponentPositions,
  saveComponentPosition,
  deleteComponentPosition,
  setComponentPositionChange,
  setComponentPositionShowScreenStatus,
  uploadComponentToS3UpdateShadow
}) => {
  let initialLimit = 6;
  let initialRecords = ComponentPositionsManager.componentPositionModels.slice(0, initialLimit);
  const emptyObj = { ModelComponentPositionId: 0, ModelComponentPositionLinkId: 0 };
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    getComponentPositions(ModelId);
  }, [getComponentPositions, ModelId]);

  const [currentEditId, setCurrentEditId] = useState(-1);
  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [deleteObj, setDeleteObj] = useState(emptyObj);
  const [searchText, setSearchText] = useState('');

  const localSettingLength = localSettings.length;
  const componentPositionLength = ComponentPositionsManager.componentPositionModels.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(ComponentPositionsManager.componentPositionModels);
    }
    setShowAll(!showAll);
  };

  const disableModeHandler = status => {
    setComponentPositionShowScreenStatus(status);
  };

  const cancelHandler = status => {
    setComponentPositionShowScreenStatus(false);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = ComponentPositionsManager.componentPositionModels.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const setAddHandler = () => {
    setComponentPositionShowScreenStatus(true);
    // setCurrentEditId(0);
  };

  const generateFileAndUploadTos3 = () => {
    uploadComponentToS3UpdateShadow(ModelId);
  };

  const filterData = () => {
    let filteredList = ComponentPositionsManager.componentPositionModels;
    if (isFilter) {
      filteredList = ComponentPositionsManager.componentPositionModels.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getComponentPositionsData = () => {
    let ComponentPositionsManager = [];
    if (showAll) {
      ComponentPositionsManager = filterData();
    } else {
      if (isFilter) {
        ComponentPositionsManager = localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        ComponentPositionsManager = initialRecords;
      }
    }
    return ComponentPositionsManager.sort(compareValues('Name'));
  };

  const deleteComponentPositionHandler = obj => {
    setActive(true);
    setDeleteObj(obj);
  };
  const DeleteComponentPosition = () => {
    let saveData = deleteObj;
    saveData.ModelId = parseInt(ModelId);
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    deleteComponentPosition(saveData);
  };

  const handleClose = () => {
    setActive(false);
    setDeleteObj(emptyObj);
  };

  const handleConfirm = () => {
    DeleteComponentPosition();
    handleClose();
  };

  let messageId = (ComponentPositionsManager && ComponentPositionsManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'modelVersion.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelVersion.showLess', defaultMessage: 'SHOW LESS' });

  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'MODEL_COMPONENT_POSTSITON_DELETE');
  const isAddAllowed = IsWidgetAccessible(userProfileWidgets, 'MODEL_COMPONENT_POSTSITON_ADD');
  const isUploadFileAllowed = IsWidgetAccessible(userProfileWidgets, 'MODEL_COMPONENT_POSTSITON_UPLOAD_FILE');

  const dialogTitle = intl.formatMessage({
    id: 'model.componentpositions.confirmDeleteComponentPosition',
    defaultMessage: 'Are you sure you want to delete the component position?'
  });

  return (
    <div className={s.componentPosition}>
      {ComponentPositionsManager.isLoading && <LoadingSpinner />}
      {ComponentPositionsManager.showBanner && (
        <Banner
          failureText={messageText}
          showBanner={ComponentPositionsManager.showBanner}
          status={ComponentPositionsManager.isOpSuccessfull}
          successText={messageText}
        />
      )}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              disabled={ComponentPositionsManager.showAddEditScreen}
              // initialText={filter}
              placeHolderTextId="model.componentpositions.search"
              data-unittest="searchComponentPosition"
            />
          </Col>
          <Col sm={6} lg={8}>
            <Button
              className={s.addbutton}
              style={WidgetVisibility(userProfileWidgets, 'MODEL_COMPONENT_POSTSITON_ADD')}
              onClick={setAddHandler}
              disabled={!isAddAllowed && componentPositionLength.showAddEditScreen}
              data-unittest="addComponentPosition"
            >
              + <FormattedMessage id="model.componentpositions.addNew" defaultMessage="ADD NEW" />
            </Button>
            <Button
              className={s.uploadbutton}
              style={WidgetVisibility(userProfileWidgets, 'MODEL_COMPONENT_POSTSITON_UPLOAD_FILE')}
              onClick={generateFileAndUploadTos3}
              disabled={!isUploadFileAllowed || componentPositionLength == 0}
              data-unittest="uploadFileComponentPosition"
            >
              <FormattedMessage id="model.componentpositions.uploadComponentsToUnit" defaultMessage="UPLOAD COMPONENT" />
            </Button>
          </Col>
        </Row>

        <div>
          <ComponentPositionModelListTable
            ComponentPositionsManager={ComponentPositionsManager}
            ComponentPositionsData={getComponentPositionsData()}
            DeleteAllowed={isDeleteAllowed}
            deleteComponentPosition={deleteComponentPositionHandler}
            selectedModelComponentPositionLinkId={currentEditId}
            ModelId={ModelId}
            saveComponentPosition={saveComponentPosition}
            setComponentPositionChange={setComponentPositionChange}
            modelComponentPositions={modelComponentPositions}
            disableMode={ComponentPositionsManager.showAddEditScreen}
            cancelHandler={cancelHandler}
          />
        </div>
        {((!isFilter && componentPositionLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? componentPositionLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

ComponentPositionModelList.defaultProps = {
  ...initialModelState
};

ComponentPositionModelList.propTypes = {
  modelComponentPositions: PropTypes.array,
  userProfileWidgets: PropTypes.object.isRequired,
  getComponentPositions: PropTypes.func.isRequired,
  saveComponentPosition: PropTypes.func.isRequired,
  setComponentPositionChange: PropTypes.func.isRequired,
  setComponentPositionShowScreenStatus: PropTypes.func.isRequired
};

export default injectIntl(ComponentPositionModelList);
