import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChecklistTemplate from './ChecklistTemplate';
import { getChecklistTemplate, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import {
  saveChecklistTemplateThunk,
  describeChecklistTemplateThunk,
  setChecklistTemplateChangeThunk
} from '../../actions/checklistTemplates';

const mapStateToProps = state => {
  return {
    checklistTemplate: getChecklistTemplate(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveChecklistTemplate: (checklistTemplate, widgetCode) => saveChecklistTemplateThunk(checklistTemplate, widgetCode),
      describeChecklistTemplate: (checklistTemplateId, widgetCode) => describeChecklistTemplateThunk(checklistTemplateId, widgetCode),
      changeChecklistTemplate: checklistTemplate => setChecklistTemplateChangeThunk(checklistTemplate)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistTemplate);
