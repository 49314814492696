import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getSelectedUnit, getAlarms } from '../../selectors/index';
import { getAlarmsThunk } from '../../actions/alarms';
import { compareValues } from '../../utils/';
import { injectIntl, FormattedMessage } from 'react-intl';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { ALARM_STATUS_RESOLVED } from '../../constants';
import s from './EventActionRHS.module.scss';
import { Fragment } from 'react';
import Alert from 'react-bootstrap/Alert';
import alert from '../../assets/alert.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const LinkAlarm = ({ eventAction, isResolved, isUpdateAllowed, onAlarmUnLink, onAlarmLink }) => {
  let { unitSerialNumber } = useParams();
  const dispatch = useDispatch();
  const selectedUnit = useSelector(state => getSelectedUnit(state));
  const alarms = useSelector(state => getAlarms(state));

  let alarmsObj = Object.values(alarms.filteredResults);
  alarmsObj = alarmsObj.sort(compareValues('EventDateTime', 'desc'));

  let apifilterObj = {
    filterUnit: selectedUnit.UnitId || 0
  };

  useEffect(() => {
    if (selectedUnit.UnitId) dispatch(getAlarmsThunk(apifilterObj, 'NAV_ALARMS'));
  }, [getAlarmsThunk, apifilterObj.filterUnit]);

  const AlarmOptions = () => {
    const alarmsData = [];
    alarmsData.push({
      label: <FormattedMessage id="alarms.selectAlarm" defaultMessage="Select Alarm" />,
      value: 0
    });

    alarmsObj.forEach(element => {
      if (
        element.EventActionId !== eventAction.EventActionId &&
        element.EventStatus !== ALARM_STATUS_RESOLVED &&
        (element.LinkStatus === 0 || element.LinkStatus === 1)
      ) {
        alarmsData.push({
          label: element.EventTitle,
          value: element.EventActionId,
          status: element.EventStatus
        });
      }
    });
    return alarmsData;
  };

  const onDdlChange = e => {
    onAlarmLink(e);
  };

  return (
    <Fragment>
      {eventAction.LinkStatus === 0 && eventAction.EventStatus !== ALARM_STATUS_RESOLVED && (
        <Fragment>
          <div className={s.eventActionRHSHeading}>
            <FormattedMessage id="eventAction.linkAlarm" defaultMessage="Link Alarm" />
          </div>

          <div className={s.eventActionRHSContents}>
            <Dropdown
              id="drpLinkAlarm"
              dataArray={AlarmOptions()}
              controlData={{
                placeholderText: '-',
                customClassName: ''
              }}
              onDropdownChange={e => onDdlChange(e)}
            />
          </div>
        </Fragment>
      )}
      {eventAction.LinkStatus === 2 && (
        <Fragment>
          <div className={s.eventActionRHSHeading}>
            <FormattedMessage id="eventAction.linkedAlarm" defaultMessage="Linked Alarm" />
          </div>
          <div className={s.eventActionRHSContents}>
            <Row>
              <Col md={12}>
                <Link className={s.link} to={`/events/action/${unitSerialNumber}/${eventAction.ParentEventActionId}`}>
                  <Alert variant="light" className={`d-flex flex-row align-items-center ${s.alarmAlert}`}>
                    <span>
                      <span>{eventAction.ParentEventTitle}</span>
                    </span>
                  </Alert>
                </Link>
              </Col>
              {isUpdateAllowed && !isResolved && (
                <Col md={12}>
                  <h6
                    className={s.removeLink}
                    onClick={() => {
                      onAlarmUnLink(eventAction.ParentEventActionId);
                    }}
                  >
                    <FormattedMessage id="eventAction.unlinkAlarm" defaultMessage="Unlink Alarm" />
                  </h6>
                </Col>
              )}
            </Row>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default injectIntl(LinkAlarm);
