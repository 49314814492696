import {
  GET_ALL_USER_DETAILS,
  USER_UI_DETAILS,
  USER_IS_LOADING,
  UPDATE_USERPROFILE_SAVE_STATUS,
  SET_SELECTED_USER,
  USER_MANAGEMENT_ERROR,
  SET_USER_CURRENT_PAGE,
  SET_USER_PAGE_FILTER,
  SET_USER_DETAILS,
  UPDATE_CHANGEPASSWORD_STATUS,
  USERDETAILS_CLEANUP
} from '../constants/index';
import { initialUserManagerState } from './initialState';

const userManager = (state = initialUserManagerState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_USER_DETAILS: {
      const { user } = payload;
      return {
        ...state,
        userManager: {
          ...state.userManager,
          ...user
        }
      };
    }

    case SET_USER_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        userManager: {
          ...state.userManager,
          currentPage
        }
      };
    }

    case SET_USER_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        userManager: {
          ...state.userManager,
          filter
        }
      };
    }

    case SET_SELECTED_USER: {
      const { user } = payload;
      return {
        ...state,
        userManager: {
          ...state.userManager,
          selectedUser: user,
          isLoading: false,
          showBanner: false,
          isOpSuccessful: true,
          displayMessageCode: ''
        }
      };
    }

    case USERDETAILS_CLEANUP: {
      const { user } = payload;
      return {
        ...state,
        userManager: {
          ...state.userManager,
          isLoading: false,
          showBanner: false,
          isOpSuccessfull: false,
          displayMessageCode: ''
        }
      };
    }

    case USER_UI_DETAILS: {
      const { user } = payload;

      return {
        ...state,
        userManager: {
          ...state.userManager,
          selectedUser: {
            ...user
          },
          isLoading: false,
          showBanner: false,
          isOpSuccessful: true,
          displayMessageCode: ''
        }
      };
    }

    case UPDATE_USERPROFILE_SAVE_STATUS: {
      const { user } = payload;
      return {
        ...state,
        userManager: {
          ...state.userManager,
          ...user
        }
      };
    }

    case UPDATE_CHANGEPASSWORD_STATUS: {
      const { user } = payload;
      return {
        ...state,
        userManager: {
          ...state.userManager,
          ...user
        }
      };
    }

    case USER_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        userManager: {
          ...state.userManager,
          isLoading: isLoading
        }
      };
    }

    case USER_MANAGEMENT_ERROR: {
      const { user } = payload;
      return {
        ...state,
        userManager: {
          ...state.userManager,
          ...user
        }
      };
    }

    case SET_USER_DETAILS: {
      let { selectedUser, profiles, units } = payload;
      selectedUser = (selectedUser.Email && selectedUser) || state.userManager.selectedUser;

      return {
        ...state,
        userManager: {
          ...state.userManager,
          selectedUser: {
            //...state.userManager.selectedUser,
            ...selectedUser,
            selectedProfiles: selectedUser.selectedProfiles
            // selectedProfiles: selectedUser.selectedProfiles.map(e => {
            //   return { ...e, SKEY: e.SKEY.replace(`#${selectedUser.Email}#`, ''), PKEY: 'PROF#' };
            // })
          },
          profiles: profiles,
          units: units
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: true
      };
    }

    default:
      return state;
  }
};

export default userManager;
