import {
  GET_ALL_STATICDATA_DETAILS,
  STATICDATA_IS_LOADING,
  STATICDATA_MANAGEMENT_ERROR,
  SET_STATICDATA_CURRENT_PAGE,
  SET_STATICDATA_DETAILS,
  SET_STATICDATA_CHANGE,
  SET_STATICDATA_FILTERED_RESULTS,
  STATICDATA_CLEANUP,
  UPDATE_STATICDATA_SAVE_STATUS
} from '../constants/index';
import { initialStaticDataState } from './initialState';

const staticDataReducer = (state = initialStaticDataState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_STATICDATA_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        staticDataManager: {
          ...state.staticDataManager,
          ...data
        }
      };
    }

    case SET_STATICDATA_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        staticDataManager: {
          ...state.staticDataManager,
          currentPage
        }
      };
    }

    case STATICDATA_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        staticDataManager: {
          ...state.staticDataManager,
          isLoading: isLoading
        }
      };
    }

    case STATICDATA_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        staticDataManager: {
          ...state.staticDataManager,
          ...data
        }
      };
    }

    case UPDATE_STATICDATA_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        staticDataManager: {
          ...state.staticDataManager,
          ...data
        }
      };
    }

    case SET_STATICDATA_DETAILS: {
      let { StaticData } = payload;

      return {
        ...state,
        staticDataManager: {
          ...state.staticDataManager,
          isOpSuccessful: false,
          performDelete: false,
          selectedStaticData: {
            ...state.staticDataManager.selectedStaticData,
            ...StaticData
          }
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: false
      };
    }

    case SET_STATICDATA_CHANGE: {
      let { selectedStaticData } = payload;

      return {
        ...state,
        staticDataManager: {
          ...state.staticDataManager,
          selectedStaticData: selectedStaticData
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: false
      };
    }

    case SET_STATICDATA_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        staticDataManager: {
          ...state.staticDataManager,
          ...filteredResults
        }
      };
    }

    case STATICDATA_CLEANUP: {
      const { data } = payload;
      return {
        ...state,
        staticDataManager: {
          ...state.staticDataManager,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default staticDataReducer;
