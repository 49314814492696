import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import s from './NoticeBoard.module.scss';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import { initialNotificationsState } from '../../../reducers/initialState';
import { IsWidgetAccessible } from '../../../utils/widgetManager';
import moment from 'moment-timezone';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { compareValues } from '../../../utils/';
import { getNotifications, getCurrentTimezone, getUserProfileWidget } from '../../../selectors/index';
import success from '../../../assets/tick-green-filled.svg';
import error from '../../../assets/alert.svg';
import warning from '../../../assets/warning.svg';
import info from '../../../assets/info-blue.svg';

const Tab = ({ selectedPage, pageComponent, title, itemCount }) => (
  <Fragment>
    <div className={classNames({ [s.active]: selectedPage === pageComponent })}>
      {title}
      <span className={s.countRectangle}>{itemCount}</span>
      {selectedPage === pageComponent && <span />}
    </div>
  </Fragment>
);

const NoticeBoardTabs = ({ selectedPage, userProfileWidgets, itemCount }) => (
  <ul className={s.tabNav}>
    {IsWidgetAccessible(userProfileWidgets, 'NOTICEBOARD') && (
      <li>
        <Tab
          title={<FormattedMessage id="notifications.notifications" defaultMessage="Notifications" />}
          selectedPage={selectedPage}
          itemCount={itemCount}
          pageComponent="notifications"
        />
      </li>
    )}
  </ul>
);

const NoticeBoard = ({ intl, show, handleClose }) => {
  const notifications = useSelector(state => getNotifications(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  let notificationsObj = Object.values(notifications.notificationsHistory).sort(compareValues('DeliveryTime', 'desc'));

  let messageId = (notifications && notifications.displayMessageCode) || '';
  let messageText = '';
  messageText = messageId !== '' ? intl.formatMessage({ id: messageId, defaultMessage: messageId }) : '';

  return (
    <div className={s.notifications}>
      {notifications.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={notifications.showBanner}
        status={notifications.isOpSuccessful}
        successText={messageText}
      />
      <Modal show={show} onHide={handleClose} animation={false} dialogClassName={s.modalCustom} scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="notifications.noticeBoard" defaultMessage="Notice Board" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={s.contentWrapper}>
            <div className="">
              {<NoticeBoardTabs selectedPage="notifications" userProfileWidgets={userProfileWidgets} itemCount={notificationsObj.length} />}
              {notificationsObj.map(n => {
                const iconSrc =
                  n.AlertType === 'danger' ? error : n.AlertType === 'warning' ? warning : n.AlertType === 'success' ? success : info;
                const messageClass =
                  n.AlertType === 'danger'
                    ? s.danger
                    : n.AlertType === 'warning'
                    ? s.warning
                    : n.AlertType === 'success'
                    ? s.success
                    : s.info;
                return (
                  <Fragment key={n.NotificationQueueId}>
                    <div className={s.notificationSection}>
                      <Row>
                        <Col md={12}>
                          <div className={s.flexContainer}>
                            <div className={s.flexChildLeft}>{iconSrc && <img src={iconSrc} alt="icon" className={s.icon} />}</div>

                            <div className={s.flexChildRight}>
                              <Row>
                                <Col xs={12}>
                                  <span className={s.messageTitle}>{n.Name.replace('_', ' ') || ''}</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col className={s.message}>
                                  <span className={classNames(s.messageStyle, messageClass)}> {n.Message}</span>{' '}
                                </Col>
                              </Row>
                              <Row>
                                <Col className={s.notificationTime}>
                                  {n.DeliveryTime
                                    ? moment
                                        .unix(n.DeliveryTime)
                                        .tz(currentTimezone)
                                        .format('DD-MMM-YYYY HH:mm z')
                                    : '-'}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

NoticeBoard.defaultProps = {
  ...initialNotificationsState
};

export default injectIntl(NoticeBoard);
