import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TaskList from './TaskList';
import { getTaskManager, getUserProfileWidget, getUserProfileUnit, getCurrentTimezone } from '../../selectors/index';
import {
  getTasksThunk,
  setCurrentPage,
  setPageFilter,
  setTaskFilteredDataThunk,
  setTasksChangeThunk,
  saveTasksThunk,
  getTasksFilterThunk
} from '../../actions/tasks';

const mapStateToProps = state => {
  return {
    tasksManager: getTaskManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    userUnits: getUserProfileUnit(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTasks: filterObj => getTasksThunk(filterObj, 'TASKLIST'),
      getTasksFilters: () => getTasksFilterThunk('TASKLIST'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filterBy => setPageFilter(filterBy),
      setFilterData: currentPage => setTaskFilteredDataThunk(currentPage),
      saveTask: (task, widgetCode) => saveTasksThunk(task, widgetCode),
      changeTask: task => setTasksChangeThunk(task)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
