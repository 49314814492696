import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  FAQ_CATEGORY_ERROR,
  GET_ALL_FAQ_CATEGORY_DETAILS,
  FAQ_CATEGORY_IS_LOADING,
  SET_FAQ_CATEGORY_CURRENT_PAGE,
  SET_FAQ_CATEGORY_PAGE_FILTER,
  SET_FAQ_CATEGORY_CHANGE,
  SET_FAQ_CATEGORY,
  SET_FAQ_CATEGORY_CLEANUP,
  UPDATE_FAQ_CATEGORY_SAVE_STATUS
} from '../constants/index';

import { FAQ_CATEGORY_MANAGEMENT, CREATE_FAQ_CATEGORY, UPDATE_FAQ_CATEGORY } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setFAQCategoryErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setFAQCategoryError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setFAQCategoryError(messageCode, false));
  }, 2500);
};

export const setFAQCategoryError = (messageCode, status) => {
  return {
    type: FAQ_CATEGORY_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: FAQ_CATEGORY_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getFAQCategoryThunk = (widgetCode, includeQuestions) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getFAQCategory(widgetCode, includeQuestions, orgId));
};

export const getFAQCategory = (widgetCode, includeQuestions, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/faqs/categories`,
    method: 'GET',
    data: {
      OrgId: orgId,
      IncludeQuestions: includeQuestions || false
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFAQCategoryListThunk(retData),
    onFailure: err => setFAQCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFAQCategoryListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllFAQCategoryList(messageCode, data));
};

export const setAllFAQCategoryList = (messageCode, data) => ({
  type: GET_ALL_FAQ_CATEGORY_DETAILS,
  payload: {
    data: {
      faqCategories: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_FAQ_CATEGORY_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_FAQ_CATEGORY_PAGE_FILTER,
  payload: { filter }
});

export const describeFAQCategoryThunk = (FAQCategoryId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeFAQCategory(FAQCategoryId, widgetCode, orgId));
};

export const describeFAQCategory = (FAQCategoryId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/faqs/categories`,
    data: {
      FAQCategoryId: FAQCategoryId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setFAQCategoryThunk(retData),
    onFailure: err => setFAQCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setFAQCategoryThunk = ({ retData }) => dispatch => {
  let data = retData.data;
  dispatch(setFAQCategory(data));
};

export const setFAQCategory = data => ({
  type: SET_FAQ_CATEGORY,
  payload: data[0]
});

export const saveFAQCategoryThunk = (data, widgetCode) => dispatch => {
  let url = '/management/faqs/categories/save';
  let actionName = data.FAQCategoryId === 0 ? CREATE_FAQ_CATEGORY : UPDATE_FAQ_CATEGORY;
  let log = logEntry(FAQ_CATEGORY_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveFAQCategory(data, url, log, widgetCode));
};

export const saveFAQCategory = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setFAQCategoryStatusThunk(retData),
    onFailure: err => setFAQCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setFAQCategoryStatusThunk = message => dispatch => {
  dispatch(setFAQCategoryStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setFAQCategoryStatus(message, false));
  }, 2500);
};

export const setFAQCategoryStatus = (messageCode, status) => ({
  type: UPDATE_FAQ_CATEGORY_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: true,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setFAQCategoryChangeThunk = retData => dispatch => {
  dispatch(setFAQCategoryChange(retData));
};

export const setFAQCategoryChange = retData => ({
  type: SET_FAQ_CATEGORY_CHANGE,
  payload: retData
});

export const setFAQCategoryCleanUpThunk = selectedFAQCategory => dispatch => {
  dispatch({ type: SET_FAQ_CATEGORY_CLEANUP, payload: selectedFAQCategory });
};
