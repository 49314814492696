import React from 'react';
import PropTypes from 'prop-types';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import { compareValues } from '../../utils/';
import InputTemplate from '../InputTemplate/InputTemplate';
import { EVENT_TYPES } from '../../constants/device';
import { FormattedMessage } from 'react-intl';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'js-cookie';
import s from '../../styles/inputs.module.scss';

const EventInputs = ({
  unitSerialNumber,
  dateFrom,
  dateTo,
  type,
  descOrder,
  code,
  updateEventInputs,
  currentTimezone,
  flowSequences,
  alarms,
  warnings,
  infoEvents,
  fetchEvents,
  view,
  limit
}) => {
  const currentTimestamp = moment().tz(currentTimezone);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let typeCodes = [];
  if (parseInt(type) === 3) {
    typeCodes = flowSequences && flowSequences.filter(x => x.Name !== 'N/A').sort(compareValues('Name'));
  }
  if (parseInt(type) === 1) typeCodes = warnings && warnings.sort(compareValues('Code'));
  if (parseInt(type) === 2) typeCodes = alarms && alarms.sort(compareValues('Code'));
  if (parseInt(type) === 4) typeCodes = infoEvents && infoEvents.sort(compareValues('Code'));

  const disableFutureDates = current => current.isBefore(currentTimestamp);
  const disablePastDates = current =>
    current.isAfter(
      moment(dateFrom)
        .tz(currentTimezone)
        .subtract(1, 'day')
    ) && current.isBefore(currentTimestamp);

  // json to csv converter
  const jsonToCsv = items => {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    // Makes the timestamp data the first element
    var first = 'timestamp';
    header.sort(function(x, y) {
      return x == first ? -1 : y == first ? 1 : 0;
    });
    let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    return csv.join('\r\n');
  };

  //CSV file downloader
  const downloadCsvFile = (data, fileName) => {
    const element = document.createElement('a');
    const file = new Blob([data], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const exportLogs = async () => {
    try {
      //API call
      let respData = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${unitSerialNumber}/events?unitSerialNumber=${unitSerialNumber}&limit=10000&from=${dateFrom}&to=${dateTo}&type=${type}&code=${code}&descOrder=${descOrder}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + Cookies.get(`access-${envName}`),
            widgetcode: 'EVENTLOGSUMMARY',
            accessid: Cookies.get(`selectedprofileid-${envName}`) || ''
          }
        }
      );

      //Reading from stream and converting to JSON
      let retData = await respData.json();

      let csvData = '';
      //formatting timestamp
      if (retData.Items && retData.Items.length > 0) {
        let eventLog = retData.Items.map(event => {
          return {
            ...event,
            timestamp: moment(event.timestamp)
              .tz(currentTimezone)
              .format('DD-MMM-YYYY HH:mm:ss z'),
            code: getCodeName(event.type, event.code)
          };
        });
        csvData += jsonToCsv(eventLog);
      }

      downloadCsvFile(csvData, `${unitSerialNumber}eventlog.csv`);
    } catch (err) {
      console.log('eeeee', err);
      let messageCode = (err && err.errorMessage) || null;
      messageCode = messageCode && messageCode.replace('[400]=>', '');
    }
  };
  const getCodeName = (type, code) => {
    let name = '';
    switch (type) {
      case 1: {
        let warning = warnings.filter(x => x.Code.toString() === code?.toString());
        name = warning.length > 0 ? warning[0].Name : '';
        break;
      }
      case 2: {
        let alarm = alarms.filter(x => x.Code.toString() === code?.toString());
        name = alarm.length > 0 ? alarm[0].Name : '';
        break;
      }
      case 3: {
        let flowSequence = flowSequences.filter(x => x.SequenceCode && x.SequenceCode.toString() === code?.toString());
        name = flowSequence.length > 0 ? flowSequence[0].Description : '';
        break;
      }
      case 4: {
        let infoEvent = infoEvents.filter(x => x.Code.toString() === code?.toString());
        name = infoEvent.length > 0 ? infoEvent[0].Name : '';
        break;
      }
    }
    return `${code}-${name}`;
  };

  return (
    <div className={s.inputWrapper}>
      <Row>
        <Col lg={2} xs={6}>
          <InputTemplate label="Type">
            <select className={s.customSelect} value={type} onChange={e => updateEventInputs(unitSerialNumber, 'type', e.target.value)}>
              <option value="all">All</option>
              {Object.keys(EVENT_TYPES).map(key => (
                <option key={`type-${key}`} value={key}>
                  {EVENT_TYPES[key].name}
                </option>
              ))}
            </select>
          </InputTemplate>
        </Col>

        {type !== 'all' && (
          <Col lg={2} xs={6}>
            <InputTemplate label={EVENT_TYPES[type].name}>
              <select className={s.customSelect} value={code} onChange={e => updateEventInputs(unitSerialNumber, 'code', e.target.value)}>
                <option value="all">All</option>
                {typeCodes &&
                  typeCodes.map(key => (
                    <option
                      key={`event-${parseInt(type) === 3 ? key.SequenceCode : key.Code}`}
                      value={parseInt(type) === 3 ? key.SequenceCode : key.Code}
                    >
                      {key.Name}
                    </option>
                  ))}
              </select>
            </InputTemplate>
          </Col>
        )}

        <Col lg={2} xs={6}>
          <InputTemplate label="Date/Time from">
            <DateTime
              isValidDate={disableFutureDates}
              onChange={m => updateEventInputs(unitSerialNumber, 'dateFrom', m.valueOf())}
              timeFormat="HH:mm"
              dateFormat="DD-MM-YYYY"
              value={moment(dateFrom).tz(currentTimezone)}
              defaultValue={moment(dateFrom).tz(currentTimezone)}
              closeOnSelect
            />
          </InputTemplate>
        </Col>
        <Col lg={2} xs={6}>
          <InputTemplate label="Date/Time to">
            <DateTime
              isValidDate={disablePastDates}
              onChange={m => updateEventInputs(unitSerialNumber, 'dateTo', m.valueOf())}
              timeFormat="HH:mm"
              dateFormat="DD-MM-YYYY"
              value={moment(dateTo).tz(currentTimezone)}
              defaultValue={moment(dateTo).tz(currentTimezone)}
              closeOnSelect
            />
          </InputTemplate>
        </Col>
        <Col lg={4} xs={12} className={s.button}>
          <Button
            variant="primary"
            className={s.searchButton}
            onClick={() => fetchEvents(limit, view, dateFrom, dateTo, code, type, descOrder)}
            noValidate
          >
            <FormattedMessage id="common.search" defaultMessage="Search" />
          </Button>
          <Button variant="primary" className={s.exportButton} onClick={exportLogs} noValidate>
            <FormattedMessage id="common.export" defaultMessage="Export" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

EventInputs.propTypes = {
  unitSerialNumber: PropTypes.string.isRequired,
  dateFrom: PropTypes.number.isRequired,
  dateTo: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  updateEventInputs: PropTypes.func.isRequired,
  flowSequences: PropTypes.array.isRequired,
  alarms: PropTypes.array.isRequired,
  warnings: PropTypes.array.isRequired,
  infoEvents: PropTypes.array.isRequired,
  fetchEvents: PropTypes.func.isRequired
};

export default EventInputs;
