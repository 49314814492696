import React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from './ActivityHistory.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
/**
 *  activity :{
 *      Title: '',
 *      ActivityDate: 101010101,
 *      Description: 'Test'
 * }
 *
 */

const ActivityHistory = ({ currentTimezone, activity, intl }) => {
  let commentTagline = intl.formatMessage({ id: 'tasksManagement.addedAComment', defaultMessage: 'added a comment' });

  return (
    <div className={s.activityHistory}>
      <div className={s.activityHistoryContainer}>
        <Row>
          <Col xs={1} className={s.avatarDiv}>
            <div className={s.avatar}></div>
          </Col>
          <Col xs={11}>
            <div>
              <p className={s.activityHistoryTitle}>
                {activity.Title && activity.Title.length > 0
                  ? activity.Title
                  : activity.CreatedByName
                  ? `${activity.CreatedByName} ${commentTagline}`
                  : ''}
              </p>
              <p className={s.activityDate}>
                {activity.ActivityDate
                  ? moment
                      .unix(parseInt(activity.ActivityDate))
                      .tz(currentTimezone)
                      .format('DD-MMM-YYYY HH:mm:ss z')
                  : '-'}
              </p>
            </div>
            <div className={s.activityHistoryContents}>{activity.Description ? activity.Description : ''}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default injectIntl(ActivityHistory);
