import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  UNIT_CONFIGURATION_GET_COMPONENT_PARTS,
  UNIT_CONFIGURATION_COMPONENT_PARTS_IS_LOADING,
  UNIT_CONFIGURATION_MANAGEMENT_ERROR,
  UPDATE_COMPONENT_PARTS_SHOW_SCREEN,
  UNIT_CONFIGURATION_SET_COMPONENT_PART_CHANGE,
  UNIT_CONFIGURATION_GET_SPARE_COMPONENT_PARTS,
  UNIT_CONFIGURATION_UPDATE_COMPONENT_PARTS_SAVE_STATUS
} from '../constants/index';

import { UNIT_CONFIGURATION_COMPONENT_PARTS_MANAGEMENT, CREATE_UNIT_COMPONENT_PART, REPLACE_UNIT_COMPONENT_PART } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setError(messageCode, false));
  }, 2500);
};

export const setError = (messageCode, status) => {
  return {
    type: UNIT_CONFIGURATION_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getComponentPartsByUnitThunk = (unitId, widgetCode) => dispatch => {
  dispatch(getComponentPart(unitId, widgetCode));
};

export const setComponentPartsLoadingStatus = isLoading => ({
  type: UNIT_CONFIGURATION_COMPONENT_PARTS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getComponentPart = (unitId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/componentParts`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      UnitId: unitId
    },
    method: 'GET',
    onLoad: status => setComponentPartsLoadingStatus(status),
    onSuccess: retData => setAllComponentPartListThunk(retData),
    onFailure: err => setErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllComponentPartListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let componentParts = (retData && retData.retData.componentPart) || [];
  dispatch(setAllComponentPartList(messageCode, componentParts));
};

export const setAllComponentPartList = (messageCode, componentParts) => ({
  type: UNIT_CONFIGURATION_GET_COMPONENT_PARTS,
  payload: {
    data: {
      componentPartsList: componentParts,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const getSpareComponentPartThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getSpareComponentPart(filterObj, widgetCode));
};

export const getSpareComponentPart = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/componentParts`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      SpareComponent: true
    },
    method: 'GET',
    onLoad: status => setComponentPartsLoadingStatus(status),
    onSuccess: retData => setSpareComponentPartListThunk(retData),
    onFailure: err => setErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setSpareComponentPartListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let componentParts = (retData && retData.retData.componentPart) || [];
  dispatch(setSpareComponentPartList(messageCode, componentParts));
};

export const setSpareComponentPartList = (messageCode, componentParts) => ({
  type: UNIT_CONFIGURATION_GET_SPARE_COMPONENT_PARTS,
  payload: {
    data: {
      spareComponentPartsList: componentParts,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const saveComponentPartThunk = (componentPart, widgetCode) => dispatch => {
  let url = `/management/componentParts/save`;
  let actionName = componentPart.ReplaceComponentPart ? REPLACE_UNIT_COMPONENT_PART : CREATE_UNIT_COMPONENT_PART;
  let log = logEntry(UNIT_CONFIGURATION_COMPONENT_PARTS_MANAGEMENT, actionName, componentPart);
  dispatch(saveComponentPart(componentPart, url, log, widgetCode));
};

export const saveComponentPart = (componentPart, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setComponentPartsLoadingStatus(status),
    onSuccess: retData => setComponentPartStatusThunk(retData),
    onFailure: err => setErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      componentPart: componentPart,
      log: log
    },
    widgetcode: widgetCode
  });

export const setComponentPartStatusThunk = retData => dispatch => {
  let messageCode = retData.message;
  let componentParts = retData.componentParts;
  let spareComponentParts = retData.spareComponentParts;
  dispatch(setComponentPartStatus(messageCode, true, componentParts, spareComponentParts));
  setTimeout(() => {
    //Hide the banner
    dispatch(setComponentPartStatus('', false, componentParts));
  }, 2500);
};

export const setComponentPartStatus = (messageCode, status, componentParts, spareComponentParts) => ({
  type: UNIT_CONFIGURATION_UPDATE_COMPONENT_PARTS_SAVE_STATUS,
  payload: {
    data: {
      componentPartsList: componentParts,
      spareComponentPartsList: spareComponentParts,
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setComponentPartShowScreenStatusThunk = status => dispatch => {
  dispatch(setComponentPartShowScreenStatus(status));
};

export const setComponentPartShowScreenStatus = status => ({
  type: UPDATE_COMPONENT_PARTS_SHOW_SCREEN,
  payload: { showAddEditScreen: status }
});

export const setComponentPartChangeThunk = retData => dispatch => {
  dispatch(setComponentPartChange(retData));
};

export const setComponentPartChange = retData => ({
  type: UNIT_CONFIGURATION_SET_COMPONENT_PART_CHANGE,
  payload: retData
});
