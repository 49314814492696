import moment from 'moment';
import get from 'lodash.get';

import { initialChartState } from './initialState';
import {
  ADD_CHART,
  CHART_IS_LOADING,
  CHART_ERROR,
  UPDATE_CHART,
  RESTORE_CHART_CONFIG,
  UPDATE_METRIC_DATA,
  REMOVE_CHART,
  UPDATE_CHART_FILTERS
} from '../constants/index';
import { filterObject } from '../utils/index';
import { loadState } from '../configureStore';

const chartsReducer = (state = initialChartState, action) => {
  const { payload, type } = action;
  switch (type) {
    case RESTORE_CHART_CONFIG: {
      const { charts: offlineChartData } = loadState();
      return {
        ...state,
        ...offlineChartData
      };
    }
    case ADD_CHART: {
      const { id, unitSerialNumber } = payload;
      const chart = {
        id,
        sensors: [],
        dateFrom: moment()
          .subtract(1, 'day')
          .valueOf(),
        dateTo: moment().valueOf(),
        lowerThreshold: '',
        upperThreshold: '',
        limit: 200,
        resultType: 3,
        data: [],
        errorMessage: ''
      };
      return {
        ...state,
        [unitSerialNumber]: {
          ...(state[unitSerialNumber] || {}),
          [id]: chart
        }
      };
    }
    case UPDATE_CHART: {
      const { id, unitSerialNumber, field, value } = payload;
      if (!get(state, [unitSerialNumber, id])) return state;
      return {
        ...state,
        [unitSerialNumber]: {
          ...state[unitSerialNumber],
          [id]: {
            ...state[unitSerialNumber][id],
            [field]: value,
            errorMessage: ''
          }
        }
      };
    }
    case UPDATE_CHART_FILTERS: {
      const { id, unitSerialNumber, data } = payload;
      if (!get(state, [unitSerialNumber, id])) return state;
      return {
        ...state,
        [unitSerialNumber]: {
          ...state[unitSerialNumber],
          [id]: {
            ...state[unitSerialNumber][id],
            ...data,
            errorMessage: ''
          }
        }
      };
    }
    case UPDATE_METRIC_DATA: {
      const {
        unitSerialNumber,
        id,
        data: { results }
      } = payload;
      if (!get(state, [unitSerialNumber, id])) return state;
      let showData = results.some(r => Object.keys(r).length > 1); // Not just the timestamp
      return {
        ...state,
        [unitSerialNumber]: {
          ...state[unitSerialNumber],
          [id]: {
            ...state[unitSerialNumber][id],
            data: showData ? results : [],
            errorMessage: ''
          }
        }
      };
    }
    case CHART_IS_LOADING: {
      const { unitSerialNumber, id, status } = payload;
      if (!get(state, [unitSerialNumber, id])) return state;
      return {
        ...state,
        [unitSerialNumber]: {
          ...state[unitSerialNumber],
          [id]: {
            ...state[unitSerialNumber][id],
            isLoading: status
          }
        }
      };
    }
    case REMOVE_CHART: {
      const { id, unitSerialNumber } = payload;
      if (!get(state, [unitSerialNumber, id])) return state;
      return {
        ...state,
        [unitSerialNumber]: {
          ...filterObject(state[unitSerialNumber], 'id', id)
        }
      };
    }
    case CHART_ERROR: {
      const { id, unitSerialNumber, err } = payload;
      let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
      messageCode = messageCode && messageCode.replace('[400]=>', '');
      if (!get(state, [unitSerialNumber, id])) return state;
      return {
        ...state,
        [unitSerialNumber]: {
          ...state[unitSerialNumber],
          [id]: {
            ...state[unitSerialNumber][id],
            data: [],
            errorMessage: messageCode
          }
        }
      };
    }
    default:
      return state;
  }
};

export default chartsReducer;
