import {
  GET_ALL_UNIT_TAGOUT_HISTORY_DETAILS,
  UNIT_TAGOUT_HISTORY_IS_LOADING,
  UNIT_TAGOUT_HISTORY_ERROR,
  GET_ALL_UNIT_TAGOUT_HISTORY_FILTERS,
  SET_UNIT_TAGOUT_HISTORY_FILTERED_RESULTS,
  SET_UNIT_TAGOUT_HISTORY_CURRENT_PAGE,
  SET_UNIT_TAGOUT_HISTORY_PAGE_FILTER
} from '../constants/index';
import { initialUnitTagHistoryState } from './initialState';

const unitTagHistory = (state = initialUnitTagHistoryState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_UNIT_TAGOUT_HISTORY_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        unitTagHistory: {
          ...state.unitTagHistory,
          ...data
        }
      };
    }

    case GET_ALL_UNIT_TAGOUT_HISTORY_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        unitTagHistory: {
          ...state.unitTagHistory,
          ...data
        }
      };
    }

    case SET_UNIT_TAGOUT_HISTORY_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        unitTagHistory: {
          ...state.unitTagHistory,
          ...filteredResults
        }
      };
    }

    case UNIT_TAGOUT_HISTORY_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        unitTagHistory: {
          ...state.unitTagHistory,
          isLoading: isLoading
        }
      };
    }

    case UNIT_TAGOUT_HISTORY_ERROR: {
      const { data } = payload;
      return {
        ...state,
        unitTagHistory: {
          ...state.unitTagHistory,
          ...data
        }
      };
    }

    case SET_UNIT_TAGOUT_HISTORY_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        unitTagHistory: {
          ...state.unitTagHistory,
          currentPage
        }
      };
    }

    case SET_UNIT_TAGOUT_HISTORY_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        unitTagHistory: {
          ...state.unitTagHistory,
          filterBy: {
            ...filter
          }
        }
      };
    }

    default:
      return state;
  }
};

export default unitTagHistory;
