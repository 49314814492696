import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import GeneralGlobalSettingsDetail from './GeneralGlobalSettingsDetail';
import { getGlobalSettings, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import { describeGlobalSettingsThunk, saveGlobalSettingsThunk, setGlobalSettingsChangeThunk } from '../../actions/globalSettings';

const mapStateToProps = state => {
  return {
    globalSettings: getGlobalSettings(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeGlobalSettings: (generalGlobalSettingId, widgetCode) => describeGlobalSettingsThunk(generalGlobalSettingId, widgetCode),
      changeGlobalSettings: globalSetting => setGlobalSettingsChangeThunk(globalSetting),
      saveGlobalSettings: (globalSetting, widgetCode) => saveGlobalSettingsThunk(globalSetting, widgetCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GeneralGlobalSettingsDetail);
