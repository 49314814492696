import React, { useState } from 'react';
import s from './FAQ.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../../assets/collapse-down.svg';
import collapseUp from '../../../assets/collapse-up.svg';

const FAQItem = ({ faqItem }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div>
      <Row className={s.questionContainer}>
        <Col xs={12} md={6}>
          <Row>
            <img
              src={collapse ? collapseDown : collapseUp}
              alt="Collapse section"
              onClick={() => setCollapse(!collapse)}
              aria-controls="faq-section"
              aria-expanded={collapse}
              className={s.collapseImage}
            ></img>
            <span className={s.question}>{faqItem.Question}</span>
          </Row>
        </Col>
      </Row>
      <Collapse in={collapse}>
        <div id="faq-section" className={s.answerContainer}>
          <span className={s.answer}>{faqItem.Answer}</span>
        </div>
      </Collapse>
    </div>
  );
};

export default FAQItem;
