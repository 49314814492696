import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './ApprovedComponentList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const ApprovedComponentListTable = ({
  ApprovedComponentData,
  RemoveApprovedComponent,
  ViewApprovedComponent,
  UpdateAllowed,
  DeleteAllowed,
  ViewAllowed
}) => {
  return (
    <div className={s.contentWrapper}>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="approvedComponents.type" defaultMessage="Type" />
            </th>
            <th>
              <FormattedMessage id="approvedComponents.brand" defaultMessage="Brand" />
            </th>
            <th>
              <FormattedMessage id="approvedComponents.model" defaultMessage="Model" />
            </th>
            {(UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {ApprovedComponentData.map(n => {
            return (
              <Fragment key={n.ApprovedComponentId}>
                <tr data-unittest="approvedComponentData">
                  <td>{n.ModelComponentType}</td>
                  <td>{n.Brand}</td>
                  <td>{n.Model}</td>
                  {(ViewAllowed || DeleteAllowed) && (
                    <td className={s.btnCol}>
                      {ViewAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.viewButton}
                          href="#"
                          onClick={() => {
                            ViewApprovedComponent(n.ApprovedComponentId);
                          }}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="approvedComponents.view" defaultMessage="VIEW" />
                        </Button>
                      )}
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.removeButton}
                          href="#"
                          onClick={() => RemoveApprovedComponent(n.ApprovedComponentId)}
                          data-unittest="removeData"
                        >
                          <FormattedMessage id="approvedComponents.remove" defaultMessage="Remove" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ApprovedComponentListTable.defaultProps = {
  ApprovedComponentData: []
};

ApprovedComponentListTable.propTypes = {
  ApprovedComponentData: PropTypes.array.isRequired,
  RemoveApprovedComponent: PropTypes.func.isRequired,
  ViewApprovedComponent: PropTypes.func.isRequired,
  UpdateAllowed: PropTypes.bool,
  DeleteAllowed: PropTypes.bool
};

export default injectIntl(ApprovedComponentListTable);
