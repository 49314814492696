import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './ToggleSwitch.module.scss';

const ToggleSwitch = ({ checked, handleClick, classname, labelChecked, labelUnchecked, tooltip, labelPosition, disabled = false }) => (
  <Fragment>
    <p className={classNames(s.toggleSwitchWrapper, [classname])}>
      {labelPosition !== 'Right' ? (checked ? labelChecked : labelUnchecked) : ''}
      <label className={s.toggleSwitch}>
        <input type="checkbox" onChange={handleClick} checked={checked} disabled={disabled} />
        <span className={s.slider} data-tip={tooltip}></span>
      </label>
      <span>{labelPosition === 'Right' ? (checked ? labelChecked : labelUnchecked) : ''}</span>
    </p>
  </Fragment>
);

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  classname: PropTypes.string,
  handleClick: PropTypes.func.isRequired
};

export default ToggleSwitch;
