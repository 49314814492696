import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UnitConfigurationParts from './UnitConfigurationParts';
import { getUnitConfiguration, getUnitManager } from '../../selectors/index';
import {
  getComponentPartsByUnitThunk,
  setComponentPartShowScreenStatusThunk,
  setComponentPartChangeThunk,
  saveComponentPartThunk
} from '../../actions/unitConfiguration';
import { getSelectedUserProfileWidgetsThunk } from '../../actions/UserProfileWidgets';

const mapStateToProps = state => {
  return {
    unitConfiguration: getUnitConfiguration(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getComponentPartsByUnit: unitId => getComponentPartsByUnitThunk(unitId, 'UNIT_PARTS'),
      setComponentPartShowScreenStatus: status => setComponentPartShowScreenStatusThunk(status),
      changeComponentPart: selectedComponentPart => setComponentPartChangeThunk(selectedComponentPart),
      saveComponentPart: componentPart => saveComponentPartThunk(componentPart, 'UNIT_PARTS_REPLACE'),
      getSelectedUserProfileWidgets: (profileId, userName) => getSelectedUserProfileWidgetsThunk(profileId, userName, 'GENERALACCESS')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UnitConfigurationParts);
