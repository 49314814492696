import { connect } from 'react-redux';

import AdminControls from './AdminControls';
import { getUserProfileWidget } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    userProfileWidgets: getUserProfileWidget(state)
  };
};

export default connect(mapStateToProps, null)(AdminControls);
