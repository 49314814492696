import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import s from './Activity.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ActivityHistory from './ActivityHistory';

const Activity = ({
  onActivityModified,
  onCancelClick,
  onSaveClick,
  description,
  isUpdateAllowed,
  isViewAllowed,
  activities,
  noActivityMessage,
  headingText,
  placeHolderText,
  errorMessageText,
  saveBtnText,
  cancelBtnText,
  currentTimezone,
  intl
}) => {
  const [IsError, SetError] = useState(false);

  const onSaveBtnClick = () => {
    if (!description || description.trim() === '') {
      SetError(true);
    } else {
      SetError(false);
      onSaveClick();
    }
  };

  const onCancelBtnClick = () => {
    SetError(false);
    onCancelClick();
  };

  const headingContent = headingText ? headingText : '';

  let defaultPlaceholderText = intl.formatMessage({ id: 'eventAction.addAComment', defaultMessage: 'Add a comment' });
  return (
    <div className={s.activity}>
      {isUpdateAllowed && (
        <div className={s.activityContainer}>
          <div>
            <div className={s.activityHeading}>{headingContent}</div>
            <div className={s.activityContents}>
              <Row>
                <Col xs={1} className={s.avatarDiv}>
                  <div className={s.avatar}></div>
                </Col>
                <Col xs={11}>
                  <div>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder={placeHolderText || defaultPlaceholderText}
                      value={description || ''}
                      name="Description"
                      onChange={onActivityModified}
                      className={`${s.textArea} ${IsError ? s.errorBorder : ''}`}
                    />
                    {IsError && (
                      <p role="alert" className={s.error}>
                        {errorMessageText || <FormattedMessage id="eventAction.commentsRequired" defaultMessage="Required comment" />}
                      </p>
                    )}
                  </div>
                  <div>
                    <Button id="saveButton" variant="outline-secondary" className={s.activityButton} onClick={onSaveBtnClick}>
                      {saveBtnText || <FormattedMessage id="eventAction.save" defaultMessage="SAVE" />}
                    </Button>
                    <Button variant="outline-secondary" className={s.activityButton} onClick={onCancelBtnClick}>
                      {cancelBtnText || <FormattedMessage id="eventAction.save" defaultMessage="CANCEL" />}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}

      {isViewAllowed && (
        <div className={activities.length === 0 ? s.activityHistNoActivity : s.activityHistory}>
          {activities.length === 0 ? (
            <div>{noActivityMessage || <FormattedMessage id="eventAction.noActivityToShow" defaultMessage="No acitivity to show" />}</div>
          ) : (
            activities.map(activity => {
              return <ActivityHistory key={activity.Id} activity={activity} currentTimezone={currentTimezone} />;
            })
          )}
        </div>
      )}
    </div>
  );
};

Activity.propTypes = {
  onActivityModified: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  isUpdateAllowed: PropTypes.bool.isRequired,
  isViewAllowed: PropTypes.bool.isRequired,
  activities: PropTypes.array.isRequired,
  noActivityMessage: PropTypes.string,
  headingText: PropTypes.string.isRequired,
  placeHolderText: PropTypes.string,
  errorMessageText: PropTypes.string,
  saveBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  currentTimezone: PropTypes.string
};

export default injectIntl(Activity);
