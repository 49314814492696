import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './CalibrationEquationList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialCalibrationEquationState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import CalibrationEquationListTable from '../CalibrationEquationList/CalibrationEquationListTable';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';

const CalibrationEquationList = ({
  getCalibrationEquation,
  setCurrentPage,
  setPageFilter,
  calibrationEquationManager,
  intl,
  userProfileWidgets,
  currentTimezone
}) => {
  let filter = calibrationEquationManager.filter || '';
  let offset = calibrationEquationManager.offset || 0;
  let limit = calibrationEquationManager.limit || 1000;

  useEffect(() => {
    getCalibrationEquation(offset, limit, filter);
  }, [getCalibrationEquation, offset, limit, filter]);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const calibrationEquationObj = Object.values(calibrationEquationManager.CalibrationEquationList);

  let messageId = (calibrationEquationManager && calibrationEquationManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'CALIBRATIONEQUATIONEDIT');

  return (
    <div className={s.calibrationEquation}>
      {calibrationEquationManager.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={calibrationEquationManager.showBanner}
        status={calibrationEquationManager.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageCalibrations" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.calibrationEquationHeader}>
            <div className={s.subHeading}>
              <FormattedMessage id="calibrationEquation.calibrationEquation" defaultMessage="Calibration Equation" />
              <span data-unittest="calibrationEquationDataCount" className={s.circle}>
                {calibrationEquationManager.CalibrationEquationList.length}
              </span>
            </div>
          </div>

          <Row>
            <Col sm={6} lg={8}>
              <FormattedMessage
                id="calibrationEquation.headerInfo"
                defaultMessage="The following equations are defined and used for the calibration of sensors within the Units."
              />
            </Col>
            <Col sm={6} lg={4}>
              <Link to={'/admin-controls/calibration-equation'}>
                <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `CALIBRATIONEQUATIONADD`)}>
                  + <FormattedMessage id="calibrationEquation.addNew" defaultMessage="+ ADD NEW" />
                </Button>
              </Link>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={calibrationEquationObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={calibrationEquationManager.currentPage}
            >
              <CalibrationEquationListTable UpdateAllowed={isUpdateAllowed} currentTimezone={currentTimezone} />
            </Pagination>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

CalibrationEquationList.defaultProps = {
  ...initialCalibrationEquationState
};

CalibrationEquationList.propTypes = {
  getCalibrationEquation: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(CalibrationEquationList);
