import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ComponentPartDetails from './ComponentPartDetails';
import { getComponentParts, getUserProfileWidget } from '../../selectors/index';
import { describeComponentPartThunk, setComponentPartChangeThunk } from '../../actions/componentParts';

const mapStateToProps = state => {
  return {
    componentParts: getComponentParts(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeComponentPart: componentPartId => describeComponentPartThunk(componentPartId, 'PARTSVIEW'),
      changeComponentPart: componentPart => setComponentPartChangeThunk(componentPart)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ComponentPartDetails);
