import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UnitGlobalSettingsList from './UnitGlobalSettingsList';
import { getModelVersionUnitGlobalSettings } from '../../selectors/index';
import {
  getUnitGlobalSettingsThunk,
  describeUnitGlobalSettingThunk,
  postUnitGlobalSettingThunk,
  setUnitGlobalSettingChangeThunk,
  setUnitGlobalSettingShowScreenStatusThunk
} from '../../actions/modelVersion';
import { getCurrentTimezone } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    unitGlobalSettings: getModelVersionUnitGlobalSettings(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUnitGlobalSettings: (offset, limit, filter, modelVersionId) =>
        getUnitGlobalSettingsThunk(offset, limit, filter, modelVersionId, 'UNITGLOBALSETTINGS'),

      saveUnitGlobalSetting: (unitGlobalSetting, widgetCode) => postUnitGlobalSettingThunk(unitGlobalSetting, widgetCode),

      describeUnitGlobalSetting: unitGlobalSettingId => describeUnitGlobalSettingThunk(unitGlobalSettingId, 'UNITGLOBALSETTINGSEDIT'),

      changeUnitGlobalSetting: selectedUnitGlobalSettings => setUnitGlobalSettingChangeThunk(selectedUnitGlobalSettings),
      setUnitGlobalSettingShowScreenStatus: status => setUnitGlobalSettingShowScreenStatusThunk(status)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UnitGlobalSettingsList);
