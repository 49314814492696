import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Task from './Task';
import {
  getTaskManager,
  getTaskTemplateManager,
  getUserProfileWidget,
  getProfile,
  getUserProfileUnit,
  getUnitEventActions,
  getUserManager,
  getCurrentTimezone,
  getSelectedDeviceDefault
} from '../../selectors/index';
import { getProfileThunk } from '../../actions/profiles';
import { describeTasksThunk, saveTasksThunk, setTasksChangeThunk } from '../../actions/tasks';
import { getTaskTemplateThunk } from '../../actions/taskTemplates';
import { fetchUnitEventActionThunk } from '../../actions/eventAction';
import { getUsersThunk } from '../../actions/userManager';

const mapStateToProps = state => {
  return {
    taskManager: getTaskManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    profile: getProfile(state),
    taskTemplate: getTaskTemplateManager(state),
    userUnits: getUserProfileUnit(state),
    alarms: getUnitEventActions(state),
    userManager: getUserManager(state),
    currentTimezone: getCurrentTimezone(state),
    selectedDevice: getSelectedDeviceDefault(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeTask: taskId => describeTasksThunk(taskId, 'TASKEDIT'),
      saveTask: (task, widgetCode) => saveTasksThunk(task, widgetCode),
      changeTask: task => setTasksChangeThunk(task),
      getProfileInfo: widgetCode => getProfileThunk(widgetCode),
      getTaskTemplates: () => getTaskTemplateThunk('TASKADDNEW'),
      getUsers: orgId => getUsersThunk(null, null, orgId, 'TASKADDNEW'),
      getUnitAlarms: (unitId, status, widgetCode) => fetchUnitEventActionThunk(unitId, status, widgetCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Task);
