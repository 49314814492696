import {
  UNIT_CONFIGURATION_MANAGEMENT_ERROR,
  UNIT_CONFIGURATION_GET_COMPONENT_PARTS,
  UNIT_CONFIGURATION_COMPONENT_PARTS_IS_LOADING,
  UPDATE_COMPONENT_PARTS_SHOW_SCREEN,
  UNIT_CONFIGURATION_SET_COMPONENT_PART_CHANGE,
  UNIT_CONFIGURATION_GET_SPARE_COMPONENT_PARTS,
  UNIT_CONFIGURATION_UPDATE_COMPONENT_PARTS_SAVE_STATUS
} from '../constants/index';
import { initialUnitConfigurationState } from './initialState';

const unitConfiguration = (state = initialUnitConfigurationState, action) => {
  const { payload, type } = action;
  switch (type) {
    case UNIT_CONFIGURATION_GET_COMPONENT_PARTS: {
      const { data } = payload;
      return {
        ...state,
        unitConfiguration: {
          ...state.unitConfiguration,
          componentParts: {
            ...state.unitConfiguration.componentParts,
            ...data
          }
        }
      };
    }

    case UNIT_CONFIGURATION_GET_SPARE_COMPONENT_PARTS: {
      const { data } = payload;
      return {
        ...state,
        unitConfiguration: {
          ...state.unitConfiguration,
          componentParts: {
            ...state.unitConfiguration.componentParts,
            ...data
          }
        }
      };
    }

    case UNIT_CONFIGURATION_COMPONENT_PARTS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        unitConfiguration: {
          ...state.unitConfiguration,
          componentParts: {
            ...state.unitConfiguration.componentParts,
            isLoading: isLoading
          }
        }
      };
    }

    case UPDATE_COMPONENT_PARTS_SHOW_SCREEN: {
      const { showAddEditScreen } = payload;
      return {
        ...state,
        unitConfiguration: {
          ...state.unitConfiguration,
          componentParts: {
            ...state.unitConfiguration.componentParts,
            showAddEditScreen: showAddEditScreen
          }
        }
      };
    }

    case UNIT_CONFIGURATION_SET_COMPONENT_PART_CHANGE: {
      let { selectedComponentPart } = payload;

      return {
        ...state,
        unitConfiguration: {
          ...state.unitConfiguration,
          componentParts: {
            ...state.unitConfiguration.componentParts,
            selectedComponentPart: {
              ...state.unitConfiguration.componentParts.selectedComponentPart,
              ...selectedComponentPart
            }
          }
        }
      };
    }

    case UNIT_CONFIGURATION_UPDATE_COMPONENT_PARTS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        unitConfiguration: {
          ...state.unitConfiguration,
          componentParts: {
            ...state.componentParts,
            ...data
          }
        }
      };
    }

    case UNIT_CONFIGURATION_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        unitConfiguration: {
          ...state.unitConfiguration,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default unitConfiguration;
