import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  ORGANISATION_REGION_ERROR,
  GET_ALL_ORGANISATION_REGION_DETAILS,
  ORGANISATION_REGION_IS_LOADING,
  SET_ORGANISATION_REGION_CURRENT_PAGE,
  SET_ORGANISATION_REGION_PAGE_FILTER,
  SET_ORGANISATION_REGION_CHANGE,
  SET_ORGANISATION_REGION,
  SET_ORGANISATION_REGION_CLEANUP,
  UPDATE_ORGANISATION_REGION_SAVE_STATUS
} from '../constants/index';

import { ORGANISATION_REGION_MANAGEMENT, CREATE_ORGANISATION_REGION, UPDATE_ORGANISATION_REGION } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setOrganisationRegionErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setOrganisationRegionError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setOrganisationRegionError(messageCode, false));
  }, 2500);
};

export const setOrganisationRegionError = (messageCode, status) => {
  return {
    type: ORGANISATION_REGION_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: ORGANISATION_REGION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getOrganisationRegionThunk = (widgetCode, orgId) => dispatch => {
  dispatch(getOrganisationRegion(widgetCode, orgId));
};

export const getOrganisationRegion = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/organisation/regions`,
    method: 'GET',
    data: {
      OrgId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllOrganisationRegionListThunk(retData),
    onFailure: err => setOrganisationRegionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllOrganisationRegionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllOrganisationRegionList(messageCode, data));
};

export const setAllOrganisationRegionList = (messageCode, data) => ({
  type: GET_ALL_ORGANISATION_REGION_DETAILS,
  payload: {
    data: {
      organisationRegions: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_ORGANISATION_REGION_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_ORGANISATION_REGION_PAGE_FILTER,
  payload: { filter }
});

export const describeOrganisationRegionThunk = (OrganisationRegionId, organisationId, widgetCode) => dispatch => {
  dispatch(describeOrganisationRegion(OrganisationRegionId, widgetCode, organisationId));
};

export const describeOrganisationRegion = (OrganisationRegionId, widgetCode, organisationId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/organisation/regions`,
    data: {
      OrganisationRegionId: OrganisationRegionId,
      OrgId: organisationId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setOrganisationRegionThunk(retData),
    onFailure: err => setOrganisationRegionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setOrganisationRegionThunk = ({ retData }) => dispatch => {
  let data = retData.data;
  dispatch(setOrganisationRegion(data));
};

export const setOrganisationRegion = data => ({
  type: SET_ORGANISATION_REGION,
  payload: data[0]
});

export const saveOrganisationRegionThunk = (data, widgetCode, organisationId) => dispatch => {
  let url = '/management/organisation/regions/save';
  let actionName = data.OrganisationRegionId === 0 ? CREATE_ORGANISATION_REGION : UPDATE_ORGANISATION_REGION;
  let log = logEntry(ORGANISATION_REGION_MANAGEMENT, actionName, data);
  data.OrgId = organisationId;
  dispatch(saveOrganisationRegion(data, url, log, widgetCode));
};

export const saveOrganisationRegion = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setOrganisationRegionStatusThunk(retData),
    onFailure: err => setOrganisationRegionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setOrganisationRegionStatusThunk = message => dispatch => {
  dispatch(setOrganisationRegionStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setOrganisationRegionStatus(message, false));
  }, 2500);
};

export const setOrganisationRegionStatus = (messageCode, status) => ({
  type: UPDATE_ORGANISATION_REGION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: true,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setOrganisationRegionChangeThunk = retData => dispatch => {
  dispatch(setOrganisationRegionChange(retData));
};

export const setOrganisationRegionChange = retData => ({
  type: SET_ORGANISATION_REGION_CHANGE,
  payload: retData
});

export const setOrganisationRegionCleanUpThunk = selectedOrganisationRegion => dispatch => {
  dispatch({ type: SET_ORGANISATION_REGION_CLEANUP, payload: selectedOrganisationRegion });
};
