import { initialFAQCategoryState } from './initialState';

import {
  FAQ_CATEGORY_ERROR,
  GET_ALL_FAQ_CATEGORY_DETAILS,
  FAQ_CATEGORY_IS_LOADING,
  SET_FAQ_CATEGORY_CURRENT_PAGE,
  SET_FAQ_CATEGORY_PAGE_FILTER,
  SET_FAQ_CATEGORY_CHANGE,
  SET_FAQ_CATEGORY_CLEANUP,
  SET_FAQ_CATEGORY,
  UPDATE_FAQ_CATEGORY_SAVE_STATUS
} from '../constants/index';

const faqCategoryReducer = (state = initialFAQCategoryState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_FAQ_CATEGORY_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        faqCategory: {
          ...state.faqCategory,
          ...data
        }
      };
    }

    case SET_FAQ_CATEGORY_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        faqCategory: {
          ...state.faqCategory,
          currentPage
        }
      };
    }

    case SET_FAQ_CATEGORY_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        faqCategory: {
          ...state.faqCategory,
          filter
        }
      };
    }

    case UPDATE_FAQ_CATEGORY_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        faqCategory: {
          ...state.faqCategory,
          ...data
        }
      };
    }

    case FAQ_CATEGORY_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        faqCategory: {
          ...state.faqCategory,
          isLoading: isLoading
        }
      };
    }

    case FAQ_CATEGORY_ERROR: {
      const { data } = payload;
      return {
        ...state,
        faqCategory: {
          ...state.faqCategory,
          ...data
        }
      };
    }

    case SET_FAQ_CATEGORY: {
      let data = payload;

      return {
        ...state,
        faqCategory: {
          ...state.faqCategory,
          isOpSuccessful: false,
          showBanner: false,
          selectedFAQCategory: {
            ...state.faqCategory.selectedFAQCategory,
            ...data
          }
        }
      };
    }

    case SET_FAQ_CATEGORY_CHANGE: {
      let { selectedFAQCategory } = payload;

      return {
        ...state,
        faqCategory: {
          ...state.faqCategory,
          isOpSuccessful: false,
          showBanner: false,
          selectedFAQCategory: {
            ...state.faqCategory.selectedFAQCategory,
            ...selectedFAQCategory
          }
        }
      };
    }
    case SET_FAQ_CATEGORY_CLEANUP: {
      let selectedFAQCategory = payload;
      return {
        ...state,
        faqCategory: {
          ...state.faqCategory,
          faqCategories: [],
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          selectedFAQCategory: selectedFAQCategory
        }
      };
    }

    default:
      return state;
  }
};

export default faqCategoryReducer;
