import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CalibrationList from './CalibrationList';
import { getCalibration, getUserProfileWidget, getCurrentTimezone, getUnitManager } from '../../selectors/index';
import { getCalibrationThunk, setCurrentPage, setPageFilter } from '../../actions/calibration';

const mapStateToProps = state => {
  return {
    unitManager: getUnitManager(state),
    calibrationManager: getCalibration(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCalibration: (unitSerialNumber, filterObject) => getCalibrationThunk(unitSerialNumber, filterObject, 'UNIT_CALIBRATION'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CalibrationList);
