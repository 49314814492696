import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { Label, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';
import moment from 'moment-timezone';

import { LINE_COLOURS } from '../../constants/index';
import s from './Chart.module.scss';

class Chart extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { data, lowerThreshold, upperThreshold, currentTimezone } = this.props;
    const { data: nextData, lowerThreshold: nextLower, upperThreshold: nextUpper, currentTimezone: nextCurrentTimezone } = nextProps;
    const thresholdChange = lowerThreshold !== nextLower || upperThreshold !== nextUpper;
    const timezoneChange = currentTimezone !== nextCurrentTimezone;
    return !isEqual(data, nextData) || thresholdChange || timezoneChange;
  }

  formatXAxis = time =>
    moment(time)
      .tz(this.props.currentTimezone)
      .format('DD/MM HH:mm');

  formatYAxis = value => (isNaN(value) ? value : Math.round(Math.floor(value * 1000)) / 1000);

  formatTooltipLabel = time =>
    moment(time)
      .tz(this.props.currentTimezone)
      .format('DD-MMM-YYYY HH:mm:ss z');

  formatTooltip = (value, name) => {
    const { unitComponentParts } = this.props;
    const sensor = name.split('_')[0];
    const unitsResult = unitComponentParts.filter(comp => comp.UoM && comp.Name.toLowerCase() === sensor);
    const units = unitsResult && unitsResult.length > 0 ? ` ${unitsResult[0].UoM}` : '';
    return [`${value}${units}`, sensor];
  };

  showLabel = (sensors, unitComponentParts) => {
    let returnStatus = false;
    if (sensors.length > 0) {
      returnStatus = true;
      const unitsResult = unitComponentParts.filter(comp => comp.UoM && comp.Name.toLowerCase() === sensors[0]);
      if (unitsResult[0]) {
        sensors.forEach(sensor => {
          const res = unitComponentParts.filter(comp => comp.Name.toLowerCase() === sensor);
          if (!res || !unitsResult || (unitsResult.lenght > 0 && res.length > 0 && res[0].UoM !== unitsResult[0].UoM)) {
            returnStatus = false;
          }
        });
      }
    }
    return returnStatus;
  };
  displayLabel = (sensors, unitComponentParts) => {
    if (unitComponentParts && unitComponentParts.length > 0) {
      const unitsResult = unitComponentParts.filter(comp => comp.UoM && comp.Name.toLowerCase() === sensors[0]);
      return unitsResult && unitsResult.length > 0 && unitsResult[0].UoM ? ` ${unitsResult[0].UoM}` : '';
    }
    return '';
  };
  showThreshold = threshold => typeof threshold === 'number';

  render() {
    const { data, id, sensors, lowerThreshold, upperThreshold, unitComponentParts } = this.props;
    var tz = moment()
      .tz(this.props.currentTimezone)
      .format('z');
    let xLabel = this.props.currentTimezone ? 'Time (' + tz + ')' : 'Time';

    data.forEach(element => {
      sensors.map(sensor => {
        element[sensor] = parseFloat(element[sensor]);
      });
      element.LocalDateTime = moment(parseInt(element.Timestamp))
        .tz(this.props.currentTimezone)
        .format('DD-MMM-YYYY HH:mm:ss');
    });

    return (
      <div className={s.chart}>
        <ResponsiveContainer>
          <LineChart data={data} margin={{ top: 5, right: 5, left: -5, bottom: 5 }}>
            {sensors.map((sensor, i) => (
              <Line key={`line-${id}-${i}`} dot={false} type="monotone" dataKey={sensor} stroke={LINE_COLOURS[i]} />
            ))}
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="LocalDateTime" minTickGap={10} tickFormatter={this.formatXAxis} height={40} domain={['auto', 'auto']}>
              <Label dy={15} value={xLabel} />
            </XAxis>

            <YAxis domain={['auto', 'auto']} tickFormatter={this.formatYAxis}>
              {this.showLabel(sensors, unitComponentParts) && (
                <Label angle={270} dx={-20} value={this.displayLabel(sensors, unitComponentParts)} />
              )}
            </YAxis>

            <Tooltip labelFormatter={this.formatTooltipLabel} formatter={this.formatTooltip} />

            {this.showThreshold(lowerThreshold) && (
              <ReferenceLine y={lowerThreshold} label="Lower" stroke="#223658" strokeDasharray="3 3" />
            )}
            {this.showThreshold(upperThreshold) && (
              <ReferenceLine y={upperThreshold} label="Upper" stroke="#223658" strokeDasharray="3 3" />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  lowerThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  upperThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sensors: PropTypes.array.isRequired
};

export default Chart;
