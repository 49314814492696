import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import chartsReducer from './charts';
import devicesReducer from './devices';
import eventsReducer from './events';
import uiReducer from './ui';
import userReducer from './user';
import sensorsReducer from './sensors';
import localeReducer from './locale';
import timezoneReducer from './timezone';
import appWidgetReducer from './appwidgets';
import { LOGOUT } from '../constants/index';
import profilesReducer from './profiles';
import sidebarReducer from './sidebar';
import userProfileWidgetsReducer from './userProfileWidgets';
import userProfileUnitsReducer from './userProfileUnits';
import userManagerReducer from './userManager';
import unitManagerReducer from './unitManager';
import organisationManagerReducer from './organisationManager';
import modelVersionReducer from './modelVersion';
import globalSettingsReducer from './globalSettings';
import modelComponentPositionsReducer from './modelComponentPositions';
import alarmsReducer from './alarms';
import eventActionReducer from './eventAction';
import eventActionStatusReducer from './eventActionStatus';
import calibrationEquationReducer from './calibrationEquation';
import taskTemplateReducer from './taskTemplates';
import checklistTemplateReducer from './checklistTemplates';
import checklistReducer from './checklist';
import tasksReducer from './tasks';
import calibrationReducer from './calibration';
import eventCodeReducer from './eventCode';
import modelReducer from './model';
import componentPartsReducer from './componentParts';
import modelComponentTypeReducer from './modelComponentTypes';
import approvedComponentReducer from './approvedComponents';
import unitConfigurationReducer from './unitConfiguration';
import telemetryLinksReducer from './telemetryLinks';
import unitTagoutHistoryReducer from './unitTagoutHistory';
import forgotPasswordReducer from './forgotPassword';
import commandSchedulerReducer from './commandScheduler';
import unitServicesReducer from './unitServices';
import dailySummaryReducer from './dailySummary';
import unitStatisticsReducer from './unitStatistics';
import telemetryPartsReducer from './telemetryParts';
import notificationsReducer from './notifications';
import faqCategoryReducer from './faqCategories';
import faQuestionReducer from './faQuestions';
import organisationRegionReducer from './organisationRegions';
import modelFirmwareVersionReducer from './modelFirmwareVersion';
import releaseNotesReducer from './releaseNotes';
import unitReducer from './unit';
import sequenceVersionReducer from './sequenceVersion';
import staticDataReducer from './staticData';

const appReducer = history =>
  combineReducers({
    charts: chartsReducer,
    devices: devicesReducer,
    events: eventsReducer,
    user: userReducer,
    ui: uiReducer,
    router: connectRouter(history),
    sensors: sensorsReducer,
    locale: localeReducer,
    timezone: timezoneReducer,
    profile: profilesReducer,
    sidebarOpen: sidebarReducer,
    appWidgets: appWidgetReducer,
    userProfileWidgets: userProfileWidgetsReducer,
    userProfileUnits: userProfileUnitsReducer,
    userManager: userManagerReducer,
    unitManager: unitManagerReducer,
    modelManager: modelReducer,
    modelVersionManager: modelVersionReducer,
    modelFirmwareVersionManager: modelFirmwareVersionReducer,
    organisationManager: organisationManagerReducer,
    globalSettings: globalSettingsReducer,
    modelComponentPositionManager: modelComponentPositionsReducer,
    alarms: alarmsReducer,
    eventAction: eventActionReducer,
    eventActionStatus: eventActionStatusReducer,
    calibrationEquation: calibrationEquationReducer,
    taskTemplate: taskTemplateReducer,
    checklistTemplate: checklistTemplateReducer,
    tasks: tasksReducer,
    calibration: calibrationReducer,
    checklist: checklistReducer,
    event: checklistReducer,
    eventCode: eventCodeReducer,
    componentParts: componentPartsReducer,
    modelComponentType: modelComponentTypeReducer,
    approvedComponent: approvedComponentReducer,
    unitConfiguration: unitConfigurationReducer,
    telemetryLinks: telemetryLinksReducer,
    unitTagoutHistory: unitTagoutHistoryReducer,
    forgotPassword: forgotPasswordReducer,
    commandScheduler: commandSchedulerReducer,
    unitService: unitServicesReducer,
    dailySummary: dailySummaryReducer,
    unitStatistics: unitStatisticsReducer,
    telemetryParts: telemetryPartsReducer,
    notifications: notificationsReducer,
    faqCategory: faqCategoryReducer,
    faQuestion: faQuestionReducer,
    organisationRegion: organisationRegionReducer,
    releaseNote: releaseNotesReducer,
    unit: unitReducer,
    sequenceVersionManager: sequenceVersionReducer,
    staticDataManager: staticDataReducer
  });

const rootReducer = history => (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

export default rootReducer;
