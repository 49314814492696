import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './CalibrationList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialCalibrationState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useParams, useHistory } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import CalibrationListTable from '../CalibrationList/CalibrationListTable';
import { WidgetVisibility } from '../../utils/widgetManager';
import ContentTemplate from '../ContentTemplate/ContentTemplate';

const CalibrationList = ({
  unitManager,
  getCalibration,
  setCurrentPage,
  calibrationManager,
  intl,
  userProfileWidgets,
  currentTimezone
}) => {
  let filter = calibrationManager.filter || '';
  let offset = calibrationManager.offset || 0;
  let limit = calibrationManager.limit || 1000;

  let { unitSerialNumber } = useParams();

  useEffect(() => {
    let filterObj = {
      filter: filter,
      offset: offset,
      limit: limit
    };
    getCalibration(unitSerialNumber, filterObj);
  }, [getCalibration, offset, limit, filter]);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const unitId = (unitManager.selectedUnit && unitManager.selectedUnit.UnitId) || 0;

  const calibrationObj = Object.values(calibrationManager.CalibrationList);

  let messageId = (calibrationManager && calibrationManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <div className={s.calibration}>
      {calibrationManager.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={calibrationManager.showBanner}
        status={calibrationManager.isOpSuccessful}
        successText={messageText}
      />
      <ContentTemplate
        selectedPage="calibration"
        userProfileWidgets={userProfileWidgets}
        widgetCode={'UNIT_CALIBRATION'}
        tagoutRequired={false}
      >
        <div className={s.contentWrapper}>
          <Row>
            <Col sm={6} lg={8}>
              <div className={s.calibrationHeader}>
                <div className={s.subHeading}>
                  <FormattedMessage id="calibration.calibrations" defaultMessage="Calibrations" />
                  <span data-unittest="calibrationDataCount" className={s.circle}>
                    {calibrationManager.CalibrationList.length}
                  </span>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <Link to={`/unit/calibrations/${unitSerialNumber}/add/${unitId}/`}>
                <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `CALIBRATION_ADD`)}>
                  + <FormattedMessage id="calibration.addNew" defaultMessage="+ ADD NEW" />
                </Button>
              </Link>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={calibrationObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={calibrationManager.currentPage}
            >
              <CalibrationListTable
                userProfileWidgets={userProfileWidgets}
                currentTimezone={currentTimezone}
                unitId={unitId}
                unitSerialNumber={unitSerialNumber}
              />
            </Pagination>
          </div>
        </div>
      </ContentTemplate>
    </div>
  );
};

CalibrationList.defaultProps = {
  ...initialCalibrationState
};

CalibrationList.propTypes = {
  getCalibration: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(CalibrationList);
