import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './ModelTelemetryLink.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { TELEMETRY_TYPES } from '../../constants/index';

const ModelTelemetryLinkListTable = ({ telemetryLinksData, IsChangeAllowed, IsRemoveAllowed, ModelId, onRemoveClick, intl }) => {
  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="model.telemetryLinks.position" defaultMessage="Position" />
            </th>
            <th>
              <FormattedMessage id="model.telemetryLinks.description" defaultMessage="Description" />
            </th>
            <th>
              <FormattedMessage id="model.telemetryLinks.telemetryType" defaultMessage="Telemetry Type" />
            </th>
            <th>
              <FormattedMessage id="model.telemetryLinks.sourcePosition" defaultMessage="Source Position" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {telemetryLinksData.map(n => {
            return (
              <Fragment key={n.TelemetryLinkId}>
                <tr key={n.TelemetryLinkId} data-unittest="telemetryLinksData">
                  <td>{n.TargetComponentPositionName}</td>
                  <td>{n.Description}</td>
                  <td>{TELEMETRY_TYPES[n.TelemetryType].name}</td>
                  <td>{n.SourceComponentPositionName}</td>
                  <td className={s.btnCol}>
                    {IsRemoveAllowed && (
                      <Button
                        onClick={() => {
                          onRemoveClick(n.TelemetryLinkId);
                        }}
                        variant="outline-secondary"
                        className={s.changeButton}
                        data-unittest="removeData"
                      >
                        <FormattedMessage id="model.telemetryLinks.remove" defaultMessage="Remove" />
                      </Button>
                    )}
                    {IsChangeAllowed && (
                      <Link to={`/admin-controls/model-configuration/${ModelId}/telemetry-link/${n.TelemetryLinkId}`}>
                        <Button variant="outline-secondary" className={s.changeButton} data-unittest="changeData">
                          <FormattedMessage id="model.telemetryLinks.change" defaultMessage="Change" />
                        </Button>
                      </Link>
                    )}
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ModelTelemetryLinkListTable.defaultProps = {
  telemetryLinksData: []
};

ModelTelemetryLinkListTable.propTypes = {
  telemetryLinksData: PropTypes.array.isRequired,
  IsChangeAllowed: PropTypes.bool.isRequired
};

export default injectIntl(ModelTelemetryLinkListTable);
