import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModelVersionThresholdsList from './ModelVersionThresholdsList';
import { getModelVersionThreshold, getFlowSequenceGlobalSettings, getModelVersionModelComponentPositions } from '../../selectors/index';
import {
  getThresholdsThunk,
  describeThresholdThunk,
  postThresholdThunk,
  setThresholdChangeThunk,
  setThresholdShowScreenStatusThunk
} from '../../actions/modelVersion';

const mapStateToProps = state => {
  return {
    thresholds: getModelVersionThreshold(state),
    flowSequences: getFlowSequenceGlobalSettings(state),
    modelComponentPositions: getModelVersionModelComponentPositions(state) // TODO
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getModelVersionsThresholds: (offset, limit, filter, modelVersionId) =>
        getThresholdsThunk(offset, limit, filter, modelVersionId, 'MODELVERSIONTHRESHOLDS'),
      saveModelVersionThreshold: (modelVersionThreshold, widgetCode) => postThresholdThunk(modelVersionThreshold, widgetCode),
      describeModelVersionThreshold: modelVersionThresholdId =>
        describeThresholdThunk(modelVersionThresholdId, 'MODELVERSIONTHRESHOLDSEDIT'),
      changeModelVersionThreshold: selectedModelVersion => setThresholdChangeThunk(selectedModelVersion),
      setThresholdShowScreenStatus: status => setThresholdShowScreenStatusThunk(status)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelVersionThresholdsList);
