import React from 'react';
import PropTypes from 'prop-types';
import s from './ModelFirmwareVersion.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';

const ModelUnitsTable = ({ paginatedData, currentTimezone }) => {
  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="modelFirmwareVersion.unitName" defaultMessage="Unit Name" />
            </th>
            <th>
              <FormattedMessage id="modelFirmwareVersion.serialNumber" defaultMessage="Serial Number" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelFirmwareVersion.updatedDateOn" defaultMessage="Updated on" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelFirmwareVersion.currentVersion" defaultMessage="Current Version" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.UnitId} data-unittest="modelUnitsData">
                <td>{n.UnitName || ''}</td>
                <td>{n.UnitSerialNumber || ''}</td>
                <td className="d-none d-md-table-cell">
                  {n.UpdatedDateTime
                    ? moment
                        .unix(n.UpdatedDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                </td>
                <td className="d-none d-md-table-cell">{n.CurrentVersion ?? '-'}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ModelUnitsTable.defaultProps = {
  paginatedData: []
};

ModelUnitsTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(ModelUnitsTable);
