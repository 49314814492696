import React, { Fragment, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionThresholds.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ModelVersionThreshold from './ModelVersionThreshold';

const ModelVersionThresholdsListTable = ({
  setEditHandler,
  selectedModelVersionId,
  saveModelVersionThreshold,
  modelVersionThreshold,
  describeModelVersionThreshold,
  changeModelVersionThreshold,
  thresholdsData,
  flowSequences,
  modelComponentPositions,
  disableMode,
  disableModeHandler,
  UpdateAllowed,
  DeleteAllowed,
  removeThreshold,
  ReadOnly
}) => {
  const fetchDataHandler = ModelVersionThresholdId => {
    setEditHandler(ModelVersionThresholdId);
    disableModeHandler(true);
    describeModelVersionThreshold(ModelVersionThresholdId);
  };

  const cancelHandler = () => {
    setEditHandler(-1);
    disableModeHandler(false);
  };
  useLayoutEffect(() => {
    if (disableMode) {
      const scrollPos = window.scrollY + window.innerHeight;
      window.scrollTo(0, scrollPos);
    }
  }, [disableMode]);
  const editModelVersionContent = (
    <ModelVersionThreshold
      ModelVersionThresholdId={selectedModelVersionId}
      saveModelVersionThreshold={saveModelVersionThreshold}
      modelVersionThreshold={modelVersionThreshold}
      changeModelVersionThreshold={changeModelVersionThreshold}
      flowSequences={flowSequences}
      modelComponentPositions={modelComponentPositions}
      cancelHandler={cancelHandler}
    />
  );

  let editorMain = (
    <tr>
      <td colSpan="10">{editModelVersionContent}</td>
    </tr>
  );

  let newRowClass = modelVersionThreshold.showAddEditScreen && selectedModelVersionId === 0 ? s.newRow : '';

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="modelVersion.thresholdFlowSequence" defaultMessage="Flow Sequence" />
            </th>
            <th>
              <FormattedMessage id="modelVersion.thresholdSensor" defaultMessage="Sensor" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelVersion.thresholdUnitOfMeasure" defaultMessage="Unit Of Measure" />
            </th>
            <th className="d-none d-md-table-cell">
              <span className={s.red}>
                <FormattedMessage id="modelVersion.thresholdLowLow" defaultMessage="Low Low" />
              </span>
            </th>
            <th className="d-none d-md-table-cell">
              <span className={s.orange}>
                <FormattedMessage id="modelVersion.thresholdLow" defaultMessage="Low" />
              </span>
            </th>
            <th className="d-none d-md-table-cell">
              <span className={s.green}>
                <FormattedMessage id="modelVersion.thresholdOpLow" defaultMessage="Op Low" />
              </span>
            </th>
            <th className="d-none d-md-table-cell">
              <span className={s.green}>
                <FormattedMessage id="modelVersion.thresholdOpHigh" defaultMessage="Op High" />
              </span>
            </th>
            <th className="d-none d-md-table-cell">
              <span className={s.orange}>
                {' '}
                <FormattedMessage id="modelVersion.thresholdHigh" defaultMessage="High" />
              </span>
            </th>
            <th className="d-none d-md-table-cell">
              <span className={s.red}>
                <FormattedMessage id="modelVersion.thresholdHighHigh" defaultMessage="High High" />
              </span>
            </th>
            {!ReadOnly && (UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {thresholdsData.map(n => {
            return (
              <Fragment key={n.ModelVersionThresholdId}>
                <tr key={n.ModelVersionThresholdId} data-unittest="thresholdsData">
                  <td>{n.FlowSequence}</td>
                  <td>{n.ModelComponentName}</td>
                  <td className="d-none d-md-table-cell">{n.UnitOfMeasure}</td>
                  <td className="d-none d-md-table-cell">
                    <span className={s.red}>{n.Min}</span>
                  </td>
                  <td className="d-none d-md-table-cell">
                    <span className={s.orange}>{n.CriticalLow}</span>
                  </td>
                  <td className="d-none d-md-table-cell">
                    <span className={s.green}>{n.Low}</span>{' '}
                  </td>
                  <td className="d-none d-md-table-cell">
                    <span className={s.green}>{n.High}</span>{' '}
                  </td>
                  <td className="d-none d-md-table-cell">
                    <span className={s.orange}>{n.CriticalHigh}</span>
                  </td>
                  <td className="d-none d-md-table-cell">
                    <span className={s.red}>{n.Max}</span>
                  </td>
                  {!ReadOnly && (UpdateAllowed || DeleteAllowed) && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={fetchDataHandler.bind(this, n.ModelVersionThresholdId)}
                          disabled={disableMode}
                          data-unittest="btnChange"
                        >
                          <FormattedMessage id="modelVersion.change" defaultMessage="Change" />
                        </Button>
                      )}
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={() => removeThreshold(n.ModelVersionThresholdId)}
                          disabled={disableMode}
                          data-unittest="btnRemove"
                        >
                          <FormattedMessage id="modelVersion.remove" defaultMessage="Remove" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
                {modelVersionThreshold.showAddEditScreen && selectedModelVersionId === n.ModelVersionThresholdId && editorMain}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className={newRowClass}>
        {modelVersionThreshold.showAddEditScreen && selectedModelVersionId === 0 && editModelVersionContent}
      </div>
    </div>
  );
};

ModelVersionThresholdsListTable.defaultProps = {
  thresholdsData: []
};

ModelVersionThresholdsListTable.propTypes = {
  describeModelVersionThreshold: PropTypes.func.isRequired,
  changeModelVersionThreshold: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionThresholdsListTable);
