import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './GeneralGlobalSettingsList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { initialGlobalSettingsState } from '../../reducers/initialState';
import Banner from '../Banner/Banner';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE, GLOBAL_SETTINGS_TYPE, FLOWSEQUENCE_TYPE, SERVICE_TYPE } from '../../constants';
import GeneralGlobalSettingsListTable from './GeneralGlobalSettingsListTable';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import SearchBar from '../SearchBar/SearchBar';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const GeneralGlobalSettingsList = ({
  getGlobalSettings,
  setCurrentPage,
  setPageFilter,
  globalSettings,
  saveGlobalSettings,
  intl,
  userProfileWidgets,
  currentTimezone,
  setGlobalSettingsChange
}) => {
  let filter = globalSettings.filter || '';
  let offset = globalSettings.offset || 0;
  let limit = globalSettings.limit || 1000;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterType, setFilterType] = useState('0');
  const [active, setActive] = useState(false);
  const [generalGlobalSettingId, setGeneralGlobalSettingId] = useState(0);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  // if (globalSettings.isOpSuccessful) {
  //   setGlobalSettingsChange({
  //     selectedGlobalSettings: {
  //       ...initialGlobalSettingsState.globalSettings
  //     }
  //   });
  //   getGlobalSettings(offset, limit, filter);
  // }

  useEffect(() => {
    return () => {
      setGlobalSettingsChange(initialGlobalSettingsState.globalSettings);
    };
  }, [setGlobalSettingsChange]);

  useEffect(() => {
    getGlobalSettings(offset, limit, filter);
  }, [getGlobalSettings, setGlobalSettingsChange, offset, limit, filter, globalSettings.isOpSuccessful]);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    setCurrentPage(1);
  };

  const onTypeChange = e => {
    setFilterType(e.value);
    setCurrentPage(1);
  };

  const filteredResults = () => {
    let filteredList = globalSettings.globalSettings;

    if (filterType !== '0') {
      filteredList = filteredList.filter(function(item) {
        return item.Type === filterType;
      });
    }

    if (searchFilter !== '') {
      filteredList = filteredList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
      });
    }
    return filteredList;
  };

  const typeOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="globalSettingsManagement.showAll" defaultMessage="Show: All" />,
      value: '0'
    });

    statusData.push({
      label: <FormattedMessage id="globalSettingsManagement.globalSetting" defaultMessage="Global Setting" />,
      value: GLOBAL_SETTINGS_TYPE
    });

    statusData.push({
      label: <FormattedMessage id="globalSettingsManagement.flowSequence" defaultMessage="Flow Sequence" />,
      value: FLOWSEQUENCE_TYPE
    });

    statusData.push({
      label: <FormattedMessage id="globalSettingsManagement.serviceType=" defaultMessage="Service Type" />,
      value: SERVICE_TYPE
    });

    return statusData;
  };

  const onDeleteClick = globalSettingId => {
    setActive(true);
    setGeneralGlobalSettingId(globalSettingId);
  };

  const deleteGlobalSettings = () => {
    let saveData = { GeneralGlobalSettingId: generalGlobalSettingId };
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    saveData.MarkAsDeleted = true;
    saveGlobalSettings(saveData, 'GENERALGLOBALSETTINGSDELETE');
  };

  const handleConfirm = () => {
    deleteGlobalSettings();
    handleClose();
  };

  const handleClose = () => setActive(false);

  const dialogTitle = intl.formatMessage({
    id: 'globalSettingsManagement.confirmDeleteGlobalSetting',
    defaultMessage: 'Are you sure you want to delete the global setting?'
  });

  let messageId = (globalSettings && globalSettings.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isChangedAllowed = IsWidgetAccessible(userProfileWidgets, 'GENERALGLOBALSETTINGSUPDATE');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'GENERALGLOBALSETTINGSDELETE');
  const isDuplicate = IsWidgetAccessible(userProfileWidgets, 'GENERALGLOBALSETTINGSCLONE');

  return (
    <div className={s.globalSettings}>
      {globalSettings.isLoading && <LoadingSpinner />}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <Banner
        failureText={messageText}
        showBanner={globalSettings.showBanner}
        status={globalSettings.isOpSuccessful}
        successText={messageText}
      />

      <AdminControlsContentTemplate selectedPage="globalSettings" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper} style={WidgetVisibility(userProfileWidgets, 'GENERALGLOBALSETTINGSLIST')}>
          <div className={s.globalSettingsHeader}>
            <div className={s.subHeading}>
              <FormattedMessage id="globalSettingsManagement.globalSettings" defaultMessage="Global Settings" />
              <span data-unittest="globalSettingsDataCount" className={s.circle}>
                {globalSettings.globalSettings.length}
              </span>
            </div>
          </div>

          <Row>
            <Col sm={4} lg={3}>
              <div className={s.topColumns}>
                <SearchBar
                  searchHandler={searchHandler}
                  clearSearchInVisible={false}
                  initialText={searchFilter}
                  placeHolderTextId="globalSettingsManagement.search"
                />
              </div>
            </Col>
            <Col sm={4} lg={3}>
              <div className={s.topColumns}>
                <Dropdown
                  id="drpStatusId"
                  dataArray={typeOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="globalSettingsManagement.showAll" defaultMessage="Show: All" />,
                    customClassName: ''
                  }}
                  onDropdownChange={onTypeChange}
                  selectedOption={typeOptions().filter(option => option.value === filterType)}
                  disabled={false}
                />
              </div>
            </Col>
            <Col sm={4} lg={6}>
              <Link to={'/admin-controls/general-global-settings/0'}>
                <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `GENERALGLOBALSETTINGSADDNEW`)}>
                  + <FormattedMessage id="globalSettingsManagement.addNew" defaultMessage="+ ADD NEW" />
                </Button>
              </Link>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={filteredResults()}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={globalSettings.currentPage}
            >
              <GeneralGlobalSettingsListTable
                onDeleteClick={onDeleteClick}
                deleteAllowed={isDeleteAllowed}
                duplicateAllowed={isDuplicate}
                changeAllowed={isChangedAllowed}
                currentTimezone={currentTimezone}
              />
            </Pagination>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

GeneralGlobalSettingsList.defaultProps = {
  globalSettings: {
    globalSettings: []
  }
};

GeneralGlobalSettingsList.propTypes = {
  getGlobalSettings: PropTypes.func.isRequired,
  saveGlobalSettings: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(GeneralGlobalSettingsList);
