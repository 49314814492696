import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ProfileSettings.module.scss';
import { FormattedMessage } from 'react-intl';
import defaultUser from '../../images/default-user.png';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import LoadingSpinner from '..//LoadingSpinner/LoadingSpinner';
import Cookies from 'js-cookie';
import PasswordChange from './PasswordChange';
import UserDetailsUpdate from './UserDetailsUpdate';
import AccountSettingsContentTemplate from '../AccountSettings/AccountSettingsContentTemplate';

const ProfileSettings = ({ editPassword, editUser, describeUser, userManager, saveUser, submitPassword, setUserCleanup }) => {
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let selectedProfile = Cookies.get(`selectedprofile-${envName}`) || '';
  let userName = Cookies.get(`username-${envName}`) || '';
  let userId = Cookies.get(`userid-${envName}`) || 0;
  const [passwordEditable, setpasswordEditable] = useState(false);
  const [userEditable, setUserEditable] = useState(false);

  useEffect(() => {
    describeUser(userId);
  }, [describeUser, userId, userEditable]);

  useEffect(() => {
    if (userManager.isOpSuccessfull && !userManager.showBanner) {
      setUserCleanup();
      setUserEditable(false);
    }
  }, [setUserCleanup, userId, setUserEditable, userManager]);

  const Item = ({ handleClick }) => (
    <Col>
      <Button variant="outline-secondary" className={s.button} onClick={handleClick}>
        <FormattedMessage id="profileSettings.edit" defaultMessage="Edit" />
      </Button>
    </Col>
  );

  editPassword = () => {
    setpasswordEditable(!passwordEditable);
  };

  editUser = () => {
    setUserEditable(!userEditable);
  };

  const saveUserDetails = saveData => {
    saveUser(saveData);
  };

  return (
    <div className={s.profile}>
      <AccountSettingsContentTemplate selectedPage="profile">
        <div className={s.contentWrapper}>
          <Row>
            <Col lg={2} xs={4}>
              <Image src={defaultUser} width="138px" height="138px" roundedCircle />
            </Col>
            <Col lg={10} xs={8} className="justify-content-center align-self-center">
              <h3>{`${userManager.selectedUser.FirstName} ${userManager.selectedUser.LastName}`}</h3>
              <span className={s.spanHeading}>{selectedProfile}</span>
            </Col>
          </Row>
          <div className={s.contentRow}>
            {!userEditable && (
              <div className="p-5">
                {userManager.isLoading && <LoadingSpinner />}
                <Row>
                  <Col>
                    <h4>
                      <FormattedMessage id="profileSettings.personal" defaultMessage="Personal" />
                    </h4>
                  </Col>
                  <Item handleClick={editUser}></Item>
                </Row>
                <Row>
                  <Col md={3} xs={6}>
                    <span className={s.spanHeading}>
                      <FormattedMessage id="profileSettings.name" defaultMessage="Name" />
                    </span>
                  </Col>
                  <Col md={9} xs={6}>
                    {`${userManager.selectedUser.FirstName} ${userManager.selectedUser.LastName}`}
                  </Col>
                </Row>
                <Row>
                  <Col md={3} xs={6}>
                    <span className={s.spanHeading}>
                      <FormattedMessage id="profileSettings.contactNumber" defaultMessage="Contact Number" />
                    </span>
                  </Col>
                  <Col md={9} xs={6}>
                    {userManager.selectedUser.PhoneNumber}
                  </Col>
                </Row>
              </div>
            )}
            {userEditable && (
              <UserDetailsUpdate editUser={editUser} userManager={userManager} saveUser={saveUserDetails}></UserDetailsUpdate>
            )}
            <hr />
            <div className="p-5">
              <Row>
                <Col>
                  <h4>
                    <FormattedMessage id="profileSettings.emailAddress" defaultMessage="Username/Email Address" />
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col>{userName}</Col>
              </Row>
            </div>
            <hr />
            {!passwordEditable && (
              <div className="p-5">
                <Row>
                  <Col>
                    <h4>
                      <FormattedMessage id="profileSettings.password" defaultMessage="Password" />
                    </h4>
                  </Col>
                  <Item handleClick={editPassword}></Item>
                </Row>
                <Row>
                  <Col>
                    <span className={s.password}>.......</span>
                  </Col>
                </Row>
              </div>
            )}
            {passwordEditable && (
              <PasswordChange editPassword={editPassword} submitPassword={submitPassword} userManager={userManager}></PasswordChange>
            )}
          </div>
          {/* <div>
            <span className={s.italicContent}>Joined on January 16, 2020</span>
          </div> */}
        </div>
      </AccountSettingsContentTemplate>
    </div>
  );
};

ProfileSettings.propTypes = {
  editPassword: PropTypes.func,
  describeUser: PropTypes.func,
  userManager: PropTypes.object.isRequired,
  submitPassword: PropTypes.func,
  saveUser: PropTypes.func
};

export default ProfileSettings;
