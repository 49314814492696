import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './OrganisationSwitch.module.scss';
import cross from '../../assets/cross-small.svg';
import { FormattedMessage } from 'react-intl';

const OrganisationSwitch = ({ closePopup, selectedOrganisationId, organisations, onOrganisationChange }) => {
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    closePopup();
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div className={s.popup}>
      <div className={s.popup_inner} ref={node}>
        <div className={s.dismiss}>
          <img src={cross} alt="close" onClick={closePopup} />
        </div>
        <div className="mb-3">
          <span>
            <FormattedMessage id="sidebar.switchOrganisations" defaultMessage="Switch organisations" />
          </span>
        </div>
        <div className={s.scrollDiv}>
          {organisations &&
            organisations.length > 0 &&
            organisations.map(org => {
              let orgId = org.OrganisationId;
              let orgName = org.Name;
              let checked = false;
              if (!selectedOrganisationId) {
                selectedOrganisationId = orgId;
              }
              if (selectedOrganisationId === orgId) {
                checked = true;
              }
              return (
                <div className={s.formCheck} key={org.OrganisationId}>
                  <label className={s.container}>
                    <input
                      type="radio"
                      name="organisations"
                      value={orgName}
                      defaultChecked={checked}
                      onClick={() => {
                        onOrganisationChange(org);
                      }}
                    />
                    {orgName}
                    <span className={s.checkmark}></span>
                  </label>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

OrganisationSwitch.propTypes = {
  closePopup: PropTypes.func,
  organisations: PropTypes.array,
  selectedOrganisationId: PropTypes.string,
  onOrganisationChange: PropTypes.func
};

export default OrganisationSwitch;
