import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './Organisation.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialOrganisationManagerState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Redirect, Link, useParams } from 'react-router-dom';
import OrganisationUsers from './OrganisationUsers';
import OrganisationUnits from './OrganisationUnits';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import uuid from 'uuid';
import countryList from 'react-select-country-list';
import Cookies from 'js-cookie';
import Select from 'react-select';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import Files from 'react-files';
import uploadFile from '../../assets/cloud-upload-file-grey.svg';
import { Container } from 'react-bootstrap';

const Organisation = ({
  organisationManager,
  getOrganisations,
  describeOrganisation,
  saveOrganisation,
  updateSelectedOrganisation,
  setParentOrganisationData,
  setOrganisationUsers,
  setOrganisationUnits,
  userProfileWidgets,
  intl
}) => {
  let IsEdit = false;
  let IsRedirect = false;
  let countries = countryList().getData();
  let { OrganisationId, ParentOrganisationId } = useParams();
  let filter = organisationManager.filter || '';
  let limit = organisationManager.limit || 1000;
  let offset = organisationManager.offset || 0;
  let orgUserKey = '';
  let orgUnitKey = '';
  const [formErrors, setFormErrors] = useState({});
  const [userFilter, setUserFilter] = useState('');
  const [unitFilter, setUnitFilter] = useState('');
  const [organisationGuid, setOrganisationGuid] = useState(uuidv4());
  const [fileUploadStarted, setFileUploadStarted] = useState(false);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let currentOrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;

  let messageId = (organisationManager && organisationManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let ddlParentClass = formErrors && formErrors.ParentOrganisationId ? s.ddlError : '';
  let ddlDefaultProfileClass = formErrors && formErrors.DefaultProfileId ? s.ddlError : '';
  let ddlClass = formErrors && formErrors.Country ? s.ddlError : '';
  let ddlClassUnitManager = formErrors && formErrors.UnitManagerId ? s.ddlError : '';
  let ddlClassTechnician = formErrors && formErrors.UnitTechnicianId ? s.ddlError : '';

  let selectedCountryCode = countryList().getValue(organisationManager.selectedOrganisation.Country);
  let selectedObj = { value: selectedCountryCode, label: organisationManager.selectedOrganisation.Country };

  if (OrganisationId) {
    IsEdit = true;
    //redirecting to list if selected organisation is empty and OrganisationId is not blank
    if (!OrganisationId) {
      IsRedirect = true;
    }
  }

  const userList = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
      value: 0
    });

    const selectedUsers = organisationManager.selectedOrganisation.Users || [];

    organisationManager.parentOrganisationData.toFilterParentOrgUsers.forEach(element => {
      if (selectedUsers.find(x => x === element.UserId)) {
        itemData.push({
          label: element.FullName,
          value: element.UserId
        });
      }
    });
    return itemData;
  };

  let widgetCode = IsEdit ? 'ORGANISATIONUPDATE' : 'ORGANISATIONINSERT';
  useEffect(() => {
    getOrganisations(offset, limit, filter, widgetCode);
  }, [getOrganisations, offset, limit, filter, widgetCode]);

  useEffect(() => {
    if (OrganisationId) {
      describeOrganisation(OrganisationId, widgetCode);
    } else {
      setLocalSelectedOrganisation({
        ...organisationManager,
        selectedOrganisation: {
          ...initialOrganisationManagerState.organisationManager.selectedOrganisation,
          ParentOrganisationId: parseInt(currentOrganisationId)
        },
        parentOrganisationData: { ...initialOrganisationManagerState.organisationManager.parentOrganisationData },
        toFilterUsers: [],
        toFilterUnits: [],
        units: [],
        users: []
      });
    }
  }, [describeOrganisation, OrganisationId, widgetCode]);

  useEffect(() => {
    if (OrganisationId && organisationManager.selectedOrganisation.GuidIdentifier) {
      setOrganisationGuid(organisationManager.selectedOrganisation.GuidIdentifier);
    }
  }, [OrganisationId, organisationManager.selectedOrganisation.GuidIdentifier]);

  // Update redux store
  const setLocalSelectedOrganisation = currentState => {
    updateSelectedOrganisation(currentState);
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    return () => {
      setLocalSelectedOrganisation({
        ...organisationManager,
        selectedOrganisation: {
          ...initialOrganisationManagerState.organisationManager.selectedOrganisation,
          Users: [],
          Units: []
        },
        parentOrganisationData: { ...initialOrganisationManagerState.organisationManager.parentOrganisationData },
        toFilterUsers: [],
        toFilterUnits: [],
        units: [],
        users: []
      });
    };
  }, []);

  const setParentOrgData = parentOrgData => {
    setParentOrganisationData(parentOrgData);
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();
    scrollToTop();
    if (!validateInput()) {
      return;
    }

    let saveData = organisationManager.selectedOrganisation;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.OrganisationId = 0;
      saveData.GuidIdentifier = organisationGuid;
    }
    //saving Organisation
    saveOrganisation(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !organisationManager ||
      !organisationManager.selectedOrganisation ||
      !organisationManager.selectedOrganisation.Name ||
      isRequired(organisationManager.selectedOrganisation.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'organisationManagement.nameMandatory',
        defaultMessage: 'Organisation name is mandatory field'
      });
      isValid = false;
    }

    if (
      !organisationManager ||
      !organisationManager.selectedOrganisation ||
      !organisationManager.selectedOrganisation.ParentOrganisationId ||
      isRequired(organisationManager.selectedOrganisation.ParentOrganisationId, 1)
    ) {
      formErrors.ParentOrganisationId = intl.formatMessage({
        id: 'organisationManagement.parentMandatory',
        defaultMessage: 'Parent group is mandatory field'
      });
      isValid = false;
    }

    if (
      !organisationManager ||
      !organisationManager.selectedOrganisation ||
      !organisationManager.selectedOrganisation.DefaultProfileId ||
      isRequired(organisationManager.selectedOrganisation.DefaultProfileId, 1)
    ) {
      formErrors.DefaultProfileId = intl.formatMessage({
        id: 'organisationManagement.defaultProfileMandatory',
        defaultMessage: 'Default profile is mandatory field'
      });
      isValid = false;
    }

    if (
      !organisationManager ||
      !organisationManager.selectedOrganisation ||
      !organisationManager.selectedOrganisation.OrganisationLogo ||
      isRequired(organisationManager.selectedOrganisation.OrganisationLogo, 1)
    ) {
      formErrors.OrganisationLogo = intl.formatMessage({
        id: 'organisationManagement.organisationLogoMandatory',
        defaultMessage: 'Logo is a mandatory field'
      });
      isValid = false;
    }

    if (
      !organisationManager ||
      !organisationManager.selectedOrganisation ||
      !organisationManager.selectedOrganisation.Country ||
      isRequired(organisationManager.selectedOrganisation.Country, 1)
    ) {
      formErrors.Country = intl.formatMessage({
        id: 'organisationManagement.countryMandatory',
        defaultMessage: 'Location Country is mandatory field'
      });
      isValid = false;
    }

    if (
      !organisationManager ||
      !organisationManager.selectedOrganisation ||
      !organisationManager.selectedOrganisation.Users ||
      !organisationManager.selectedOrganisation.Users.length > 0
    ) {
      formErrors.Users = intl.formatMessage({
        id: 'organisationManagement.userMandatory',
        defaultMessage: 'Select atleast 1 user for this organisation'
      });
      isValid = false;
    }

    if (
      !organisationManager ||
      !organisationManager.selectedOrganisation ||
      !organisationManager.selectedOrganisation.UnitManagerId ||
      isRequired(organisationManager.selectedOrganisation.UnitManagerId, 1)
    ) {
      formErrors.UnitManagerId = intl.formatMessage({
        id: 'organisationManagement.unitManagerId',
        defaultMessage: 'Unit supervising manager is mandatory field'
      });
      isValid = false;
    }

    if (
      !organisationManager ||
      !organisationManager.selectedOrganisation ||
      !organisationManager.selectedOrganisation.UnitTechnicianId ||
      isRequired(organisationManager.selectedOrganisation.UnitTechnicianId, 1)
    ) {
      formErrors.UnitTechnicianId = intl.formatMessage({
        id: 'organisationManagement.unitTechnicianId',
        defaultMessage: 'Unit supervising technician is mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = (e, data) => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      if (
        e.target.name === 'IsActive' &&
        (organisationManager.selectedOrganisation.SubOrganisations.length > 0 || organisationManager.selectedOrganisation.Units.length > 0)
      ) {
        let formErrors = {};
        formErrors.IsActive = intl.formatMessage({
          id: 'organisation.inActiveError',
          defaultMessage: 'Organisation can not be marked as inactive as it may contain units or sub-organisation.'
        });

        setFormErrors(formErrors);
        return false;
      } else {
        let formErrors = {};
        formErrors.IsActive = '';
        setFormErrors(formErrors);
      }

      setLocalSelectedOrganisation({
        ...organisationManager,
        selectedOrganisation: { ...organisationManager.selectedOrganisation, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalSelectedOrganisation({
        ...organisationManager,
        selectedOrganisation: { ...organisationManager.selectedOrganisation, [e.target.name]: e.target.value }
      });
    }
  };

  const onUnitManagerChange = e => {
    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: { ...organisationManager.selectedOrganisation, UnitManagerId: e.value }
    });
  };

  const onUnitTechnicianChange = e => {
    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: { ...organisationManager.selectedOrganisation, UnitTechnicianId: e.value }
    });
  };

  const onParentGroupDropdownChange = async (selectedValue, initialLoad) => {
    try {
      let queryString = `&CurrentOrganisationId=${currentOrganisationId}`;
      queryString += OrganisationId > 0 ? `&incUserCreatedInOrg=true&SelectedOrgId=${OrganisationId}` : '';

      let respData = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/organisation/describe?OrganisationId=${selectedValue}${queryString}`,
        {
          method: 'get',

          headers: {
            widgetcode: widgetCode,
            accessid: Cookies.get(`selectedprofileid-${envName}`),
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + Cookies.get(`access-${envName}`)
          }
        }
      );

      orgUnitKey = uuidv4();
      orgUserKey = uuidv4();
      //Reading from stream and converting to JSON
      let retData = await respData.json();

      if (!respData.ok) {
        //fetch do not throw exception for http error 400, so manually throwing exception
        throw retData;
      }

      if (!initialLoad) {
        setLocalSelectedOrganisation({
          ...organisationManager,
          selectedOrganisation: {
            ...organisationManager.selectedOrganisation,
            ParentOrganisationId: selectedValue
          }
        });
      }

      setParentOrgData({
        ...organisationManager,
        parentOrganisationData: {
          parentOrganisationUsers: retData.retData.orgUsers,
          toFilterParentOrgUsers: retData.retData.orgUsers
        }
      });
    } catch (err) {
      let messageCode = (err && err.errorMessage) || null;
      messageCode = messageCode && messageCode.replace('[400]=>', '');
    }
  };

  const onDefaultProfileDropdownChange = async selectedValue => {
    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: {
        ...organisationManager.selectedOrganisation,
        DefaultProfileId: selectedValue
      }
    });
  };

  useEffect(() => {
    if (ParentOrganisationId) {
      onParentGroupDropdownChange(ParentOrganisationId, true);
    } else if (currentOrganisationId) {
      onParentGroupDropdownChange(currentOrganisationId, true);
    }
  }, []);

  const onCountryChange = selectedCountry => {
    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: { ...organisationManager.selectedOrganisation, Country: selectedCountry.label }
    });
  };

  //Invoked on user selection updated
  const onUserSelectionChange = selectedUserIds => {
    let changeObj = { Users: selectedUserIds };

    if (!selectedUserIds.includes(organisationManager.selectedOrganisation.UnitManagerId)) {
      changeObj.UnitManagerId = 0;
    }
    if (!selectedUserIds.includes(organisationManager.selectedOrganisation.UnitTechnicianId)) {
      changeObj.UnitTechnicianId = 0;
    }

    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: { ...organisationManager.selectedOrganisation, ...changeObj }
    });
  };

  //handler methods for user component
  const onUserSearch = filter => {
    setUserFilter(filter);
    if (filter === '') {
      let initialParentUsers = organisationManager.parentOrganisationData.parentOrganisationUsers;
      let initialOrganisationUsers = organisationManager.users;

      setParentOrgData({
        ...organisationManager,
        parentOrganisationData: {
          ...organisationManager.parentOrganisationData,
          toFilterParentOrgUsers: initialParentUsers
        }
      });

      setOrganisationUsers({
        ...organisationManager,
        toFilterUsers: initialOrganisationUsers
      });
    }
    let parentOrgUsers = organisationManager.parentOrganisationData.parentOrganisationUsers.filter(function(item) {
      return item.FullName.toLowerCase().includes(filter.toLowerCase());
    });
    let orgUsers =
      organisationManager.users &&
      organisationManager.users.filter(function(item) {
        return item.FullName.toLowerCase().includes(filter.toLowerCase());
      });

    setParentOrgData({
      ...organisationManager,
      parentOrganisationData: {
        ...organisationManager.parentOrganisationData,
        toFilterParentOrgUsers: parentOrgUsers
      }
    });

    setOrganisationUsers({
      ...organisationManager,
      toFilterUsers: orgUsers
    });
  };

  //handler methods for unit component
  const onUnitSearch = filter => {
    setUnitFilter(filter);
    if (filter === '') {
      let initialOrganisationUnits = organisationManager.units;
      setOrganisationUnits({
        ...organisationManager,
        toFilterUnits: initialOrganisationUnits
      });
    }

    let orgUnits =
      organisationManager.units &&
      organisationManager.units.filter(function(item) {
        return (
          item.UnitName.toLowerCase().includes(filter.toLowerCase()) || item.ModelVersionName.toLowerCase().includes(filter.toLowerCase())
        );
      });

    setOrganisationUnits({
      ...organisationManager,
      toFilterUnits: orgUnits
    });
  };

  const parentOrganisationOptions = () => {
    let organisations = [];
    organisationManager.organisations.forEach(element => {
      organisations.push({
        label: element.Name,
        value: element.OrganisationId,
        isDisabled: element.OrganisationId === parseInt(OrganisationId)
      });
    });
    return organisations;
  };

  const defaultProfileOptions = () => {
    let profiles = [];
    organisationManager.defaultProfiles.forEach(element => {
      profiles.push({
        label: element.Name,
        value: element.ProfileId,
        isDisabled: element.IsDefault === true
      });
    });
    return profiles;
  };

  const onUploadClick = uploadData => {
    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: {
        ...organisationManager.selectedOrganisation,
        OrganisationLogo: uploadData.imageFile
      }
    });
  };

  //FILE UPLOAD
  const onUploadStart = files => {
    setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    setFileUploadStarted(false);
    if (data && data.length > 0 && data[0].S3URL) {
      setLocalSelectedOrganisation({
        ...organisationManager,
        selectedOrganisation: {
          ...organisationManager.selectedOrganisation,
          OrganisationLogo: data[0].S3URL
        }
      });
    }
  };

  //END FILE UPLOAD

  const onError = error => {
    var regex = RegExp(/. is not a valid file type/);
    let formErrors = {};
    if (regex.test(error)) {
      formErrors.OrganisationLogo = intl.formatMessage({
        id: 'organisationManagement.organisationLogoType',
        defaultMessage: 'Only files with the following extensions are allowed: .jpg, .jpeg, .png, .svg'
      });
    } else {
      formErrors.OrganisationLogo = error;
    }
    setFormErrors(formErrors);
  };

  return (
    <div className={s.organisation}>
      {IsRedirect ? <Redirect to="/admin-controls/organisation-list" /> : ''}
      {organisationManager.isLoading && <LoadingSpinner />}

      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={organisationManager.showBanner}
        status={organisationManager.isOpSuccessful}
        successText={messageText}
      />
      {organisationManager.isOpSuccessful && !organisationManager.showBanner ? <Redirect to="/admin-controls/organisation-list" /> : ''}

      <AdminControlsContentTemplate selectedPage="manageOrganisations" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.organisationHeader}>
            <Link className={s.backLink} to={'/admin-controls/organisation-list'}>
              &lt; &nbsp;
              <FormattedMessage id="organisationManagement.backToOrganisations" defaultMessage="BACK TO ORGANISATIONS" />
            </Link>
            <h3>
              {IsEdit ? (
                <FormattedMessage id="organisation.editOrganisation" defaultMessage="Edit organisation details" />
              ) : (
                <FormattedMessage id="organisation.addNewOrganisation" defaultMessage="Add new organisation" />
              )}
            </h3>
          </div>
          <div className={s.organisationContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Container className={s.rowContainer}>
                    <Row>
                      <Col>
                        <Form.Group controlId="formOrganisationName">
                          <Form.Label>
                            <FormattedMessage id="organisationManagement.organisationName" defaultMessage="Organisation Name" />
                          </Form.Label>

                          <Form.Control
                            type="text"
                            name="Name"
                            onChange={onChange}
                            value={organisationManager.selectedOrganisation.Name}
                            className={`${s.formControl} ${formErrors && formErrors.Name ? s.formControlError : ''}`}
                          />
                          {formErrors && formErrors.Name && (
                            <p role="alert" className={s.error}>
                              {formErrors.Name}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="formParentGroup">
                          <Form.Label>
                            <FormattedMessage id="organisationManagement.parentGroup" defaultMessage="Parent group" />
                          </Form.Label>
                          <Select
                            isDisabled={true}
                            placeholder="None"
                            className={`${s.wsaDropdown} ${ddlParentClass}`}
                            options={parentOrganisationOptions()}
                            onChange={e => onParentGroupDropdownChange(e.value, false)}
                            value={parentOrganisationOptions().filter(
                              option => option.value === organisationManager.selectedOrganisation.ParentOrganisationId
                            )}
                          />
                          {formErrors && formErrors.ParentOrganisationId && (
                            <p role="alert" className={s.error}>
                              {formErrors.ParentOrganisationId}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="formDefaultProfile">
                          <Form.Label>
                            <FormattedMessage id="organisationManagement.defaultProfile" defaultMessage="Default Profile" />
                          </Form.Label>
                          <Select
                            isDisabled={currentOrganisationId == OrganisationId}
                            placeholder="None"
                            className={`${s.wsaDropdown} ${ddlDefaultProfileClass}`}
                            options={defaultProfileOptions()}
                            onChange={e => onDefaultProfileDropdownChange(e.value)}
                            value={defaultProfileOptions().filter(
                              option => option.value === organisationManager.selectedOrganisation.DefaultProfileId
                            )}
                          />
                          {formErrors && formErrors.DefaultProfileId && (
                            <p role="alert" className={s.error}>
                              {formErrors.DefaultProfileId}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col>
                      <Form.Group controlId="formOrganisationLogo">
                        <Form.Label>
                          <FormattedMessage id="organisationManagement.organisationLogo" defaultMessage="Organisation Logo" />
                        </Form.Label>
                        <FileUploader
                          widgetCode={widgetCode}
                          allowedFileTypes={[
                            ['image/svg+xml', 'svg'],
                            ['image/png', 'png'],
                            ['image/jpeg', 'jpeg']
                          ]}
                          customFileGuid={organisationGuid}
                          location={`organisation-logo`}
                          enableMultipleUpload={false}
                          maxFiles={1}
                          colSize={12}
                          onUploadComplete={onUploadComplete}
                          onUploadStart={onUploadStart}
                        />

                        {formErrors && formErrors.OrganisationLogo && (
                          <p role="alert" className={s.error}>
                            {formErrors.OrganisationLogo}
                          </p>
                        )}
                      </Form.Group>
                      <div>
                        {organisationManager.selectedOrganisation.OrganisationLogo && (
                          <img
                            src={
                              process.env.REACT_APP_FILEUPLOAD_URL +
                              '/' +
                              organisationManager.selectedOrganisation.OrganisationLogo +
                              '?' +
                              Math.random()
                            }
                            className={s.uploadImage}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <h4>
                {' '}
                <FormattedMessage id="organisationManagement.defaultUnitSupervisor" defaultMessage="Default Unit Supervisors" />
              </h4>
              <Row>
                <Col lg={6}>
                  <Form.Label>
                    <FormattedMessage id="unit.unitSupervisingManager" defaultMessage="Unit Supervising Manager" />
                  </Form.Label>
                  <Dropdown
                    id="drpUnitManagerId"
                    dataArray={userList()}
                    controlData={{
                      placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                      customClassName: `${ddlClassUnitManager}`
                    }}
                    onDropdownChange={e => onUnitManagerChange(e)}
                    selectedOption={userList().filter(option => option.value === organisationManager.selectedOrganisation.UnitManagerId)}
                    disabled={false}
                  />
                  {formErrors && formErrors.UnitManagerId && (
                    <p role="alert" className={s.error}>
                      {formErrors.UnitManagerId}
                    </p>
                  )}
                </Col>
                <Col lg={6}>
                  <Form.Label>
                    <FormattedMessage id="unit.unitSupervisingTechnician" defaultMessage="Unit Supervising Technician" />
                  </Form.Label>
                  <Dropdown
                    id="drpUnitTechnicianId"
                    dataArray={userList()}
                    controlData={{
                      placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                      customClassName: `${ddlClassTechnician}`
                    }}
                    onDropdownChange={e => onUnitTechnicianChange(e)}
                    selectedOption={userList().filter(option => option.value === organisationManager.selectedOrganisation.UnitTechnicianId)}
                    disabled={false}
                  />
                  {formErrors && formErrors.UnitTechnicianId && (
                    <p role="alert" className={s.error}>
                      {formErrors.UnitTechnicianId}
                    </p>
                  )}
                </Col>
              </Row>
              <h4>
                {' '}
                <FormattedMessage id="organisationManagement.location" defaultMessage="Location" />
              </h4>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formLocationTown">
                    <Form.Label>
                      <FormattedMessage id="organisationManagement.town" defaultMessage="Town" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Town"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'organisationManagement.enterTown',
                        defaultMessage: 'Enter town'
                      })}
                      value={organisationManager.selectedOrganisation.Town}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formLocationCity">
                    <Form.Label>
                      <FormattedMessage id="organisationManagement.Region" defaultMessage="Region/Site" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Region"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'organisationManagement.entrrRegion',
                        defaultMessage: 'Enter region/site'
                      })}
                      value={organisationManager.selectedOrganisation.Region}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formLocationCountry">
                    <Form.Label>
                      <FormattedMessage id="organisationManagement.country" defaultMessage="Country" />
                    </Form.Label>

                    <Select
                      placeholder="Select Country"
                      className={`${s.wsaDropdown} ${ddlClass}`}
                      options={countries}
                      onChange={onCountryChange}
                      value={selectedObj}
                    />
                    {formErrors && formErrors.Country && (
                      <p role="alert" className={s.error}>
                        {formErrors.Country}
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formLocationPostcode">
                    <Form.Label>
                      <FormattedMessage id="organisationManagement.postCode" defaultMessage="PostCode" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="PostCode"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'organisationManagement.enterPostcode',
                        defaultMessage: 'Enter postcode'
                      })}
                      value={organisationManager.selectedOrganisation.PostCode}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h4>
                    <FormattedMessage id="organisationManagement.users" defaultMessage="Users" />
                  </h4>
                  <p>
                    <FormattedMessage id="organisationManagement.addUsersToOrganisation" defaultMessage="Add users to your organisation" />
                  </p>
                  {formErrors && formErrors.Users && (
                    <p role="alert" className={s.error}>
                      {formErrors.Users}
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} className={s.borderBox}>
                  <OrganisationUsers
                    key={orgUserKey}
                    organisationManager={organisationManager}
                    onUserSearch={onUserSearch}
                    parentOrganisationUsers={organisationManager.parentOrganisationData.toFilterParentOrgUsers}
                    onUserSelectionChange={onUserSelectionChange}
                    selectedUserIds={organisationManager.selectedOrganisation.Users}
                    initialText={userFilter}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>
                    <FormattedMessage id="organisationManagement.units" defaultMessage="Units" />
                  </h4>
                  <p>
                    <FormattedMessage id="organisationManagement.addUnitsToOrganisation" defaultMessage="Add units to your organisation" />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className={s.borderBox}>
                  <OrganisationUnits
                    key={orgUnitKey}
                    organisationManager={organisationManager}
                    onUnitSearch={onUnitSearch}
                    selectedUnitIds={organisationManager.selectedOrganisation.Units}
                    initialText={unitFilter}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formActiveOrganisation">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsActive',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'organisationManagement.organisationActive',
                            defaultMessage: 'Organisation is active'
                          }),
                          isChecked: organisationManager.selectedOrganisation.IsActive
                        }
                      ]}
                      onSelectionChange={onChange}
                    />
                    {formErrors && formErrors.IsActive && (
                      <p role="alert" className={s.error}>
                        {formErrors.IsActive}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <div>
                <Button
                  variant="primary"
                  data-unittest="saveData"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                >
                  <FormattedMessage id="organisationManagement.saveOrganisation" defaultMessage="Save Organisation" />
                </Button>
                <Link to="/admin-controls/organisation-list">
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="profile.profileCancel" defaultMessage="Cancel" />
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

let FileUpload = class FileUpload extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      imageFile: {},
      filename: ''
    };

    this.fileReader = new FileReader();
    this.fileReader.onload = event => {
      this.setState({ imageFile: event.target.result }, () => {
        let uploadData = {};
        uploadData.imageFile = this.state.imageFile;
        uploadData.filename = this.state.filename;
        this.props.onUploadClick(uploadData);
      });
    };
  }

  componentWillUnmount() {
    this.refs.files.removeFiles();
  }

  onFilesChange = (files, event) => {
    if (files.length > 0) {
      this.fileReader.readAsDataURL(files[0]);
      this.setState({ filename: files[0].name, hasFileInfo: true }, () => {});
    } else {
      this.setState({ filename: '', hasFileInfo: false }, () => {});
    }
  };

  onFilesError = (error, file) => {
    this.props.onError(error.message);
  };

  render() {
    return (
      <div>
        <Files
          className="files-dropzone"
          ref="files"
          onChange={this.onFilesChange}
          onError={this.onFilesError}
          accepts={['.jpg', '.jpeg', '.png', '.svg']}
          multiple
          maxFiles={1}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          <Row>
            <Col>
              <div className="text-center">
                <Row>
                  <Col>
                    <img src={uploadFile} alt="icon" className={s.uploadicon}></img>
                  </Col>
                </Row>
                <Row>
                  <Col className={s.textDrag}>
                    <FormattedMessage id="organisationManagement.dragndrop" defaultMessage="Drag and drop file here" />
                  </Col>
                </Row>
                <Row>
                  <Col className={s.or}>
                    <FormattedMessage id="organisationManagement.or" defaultMessage="or" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button variant="outline-secondary" className={s.btnUpload}>
                <FormattedMessage id="organisationManagement.browse" defaultMessage="BROWSE FILE" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className={s.texFile}>
              <FormattedMessage id="organisationManagement.acceptedfile" defaultMessage="Accepted file formats: .jpg, .jpeg, .png, .svg" />
            </Col>
          </Row>
        </Files>
      </div>
    );
  }
};

Organisation.defaultProps = {
  organisationManager: {
    ...initialOrganisationManagerState.organisationManager,
    users: [],
    units: [],
    toFilterUsers: [],
    toFilterUnits: []
  }
};

Organisation.propTypes = {
  saveOrganisation: PropTypes.func.isRequired,
  organisationManager: PropTypes.object.isRequired,
  getOrganisations: PropTypes.func.isRequired,
  describeOrganisation: PropTypes.func.isRequired,
  updateSelectedOrganisation: PropTypes.func.isRequired,
  setParentOrganisationData: PropTypes.func.isRequired,
  setOrganisationUsers: PropTypes.func.isRequired,
  setOrganisationUnits: PropTypes.func.isRequired,
  userProfileWidgets: PropTypes.object.isRequired
};

export default injectIntl(Organisation);
