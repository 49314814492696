import { connect } from 'react-redux';
import ProcessFlow from './ProcessFlow';
import {
  getBannerStatus,
  getCommandStatus,
  getDeviceSequence,
  getDeviceSensors,
  getEventData,
  getSelectedDeviceDefault,
  getCurrentTimezone,
  getUserProfileWidget,
  getUnitManager,
  getFlowSequenceGlobalSettings,
  getDeviceDisplayMessage,
  getDeviceErrorStatus,
  getSelectedUnit
} from '../../selectors/index';

const mapStateToProps = state => {
  return {
    currentSequence: getDeviceSequence(state),
    events: getEventData(state),
    selectedDevice: getSelectedDeviceDefault(state),
    sensorData: getDeviceSensors(state),
    showBanner: getBannerStatus(state),
    commandStatus: getCommandStatus(state),
    currentTimezone: getCurrentTimezone(state),
    userProfileWidgets: getUserProfileWidget(state),
    unitManager: getUnitManager(state),
    displayStatusMessage: getDeviceDisplayMessage(state),
    errorStatus: getDeviceErrorStatus(state),
    flowSequences: getFlowSequenceGlobalSettings(state),
    selectedUnit: getSelectedUnit(state)
  };
};

export default connect(mapStateToProps, null)(ProcessFlow);
