exports.templateJSONData = {
  name: 'samplestate',
  description: '',
  entry: {
    set_sequence: [
      {
        set: {}
      }
    ],
    configure_components: [
      {
        configure: {}
      }
    ],
    control: []
  },
  loop: {
    verify: {},
    control: [],
    substate: [],
    thresholds: {}
  },
  triggers: [],
  exit: {
    set_sequence: [
      {
        set: {}
      }
    ],
    control: []
  }
};
