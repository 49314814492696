import axios from 'axios';
import 'core-js/features/promise/finally';
import { API, APP_VERSION } from '../constants/index';
import { logout, refreshTokenThunk } from '../actions/user';
import Cookies from 'js-cookie';

const apiMiddleware = ({ dispatch }) => next => action => {
  next(action);

  if (action.type !== API) return;

  const {
    url,
    method,
    data: inputData,
    accessToken,
    onLoad,
    onSuccess,
    onFailure,
    skipCallback,
    skipRefresh,
    widgetcode,
    accessid
  } = action.payload;
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  // axios default configs
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  if (widgetcode) {
    axios.defaults.headers.common['widgetcode'] = widgetcode;
    // headers.widgetcode = 'ADD';
  }

  //console.log(Headers());

  axios.defaults.headers.common['widgetcode'] = widgetcode || 'GENERALACCESS';

  axios.defaults.headers.common['accessid'] = accessid || Cookies.get(`selectedprofileid-${envName}`);

  if (onLoad) dispatch(onLoad(true));

  return axios
    .request({
      url,
      method,
      [dataOrParams]: inputData
    })
    .then(({ data }) => {
      if (!skipCallback) dispatch(onSuccess(data, inputData));
      if (!skipRefresh) dispatch(refreshTokenThunk());
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        dispatch(logout(false));
      }
      if (onFailure) dispatch(onFailure(error));
    })
    .finally(() => {
      if (onLoad) dispatch(onLoad(false));
    });
};

export default apiMiddleware;
