import {
  GET_ALL_USER_PROFILE_WIDGET_DETAILS,
  GET_ALL_USER_PROFILE_WIDGET_ERROR,
  SET_USER_PROFILE_WIDGET_DETAILS,
  GET_ALL_USER_PROFILE_UNITS,
  SET_USER_PROFILE_WIDGET_DETAILS_LOADING
} from '../constants/index';

const initialState = {
  userProfileWidgets: [],
  userProfileUnits: [],
  isProfileWidgetsPresent: false,
  isLoading: false
};

const userProfileWidgets = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_USER_PROFILE_WIDGET_DETAILS: {
      const { profile } = payload;
      return {
        ...state,
        userProfileWidgets: profile.profile.ProfileWigets,
        isProfileWidgetsPresent: true,
        isLoading: false
      };
    }

    case GET_ALL_USER_PROFILE_UNITS: {
      const { profile } = payload;
      return {
        ...state,
        userProfileUnits: profile.profile.units,
        isLoading: false
      };
    }

    case GET_ALL_USER_PROFILE_WIDGET_ERROR: {
      return {
        ...state,
        userProfileWidgets: [],
        isProfileWidgetsPresent: false,
        isLoading: false
      };
    }

    case SET_USER_PROFILE_WIDGET_DETAILS: {
      const { profileWidgets } = payload;
      return {
        ...state,
        userProfileWidgets: profileWidgets,
        isProfileWidgetsPresent: true,
        isLoading: false
      };
    }
    case SET_USER_PROFILE_WIDGET_DETAILS_LOADING: {
      const { status } = payload;
      return {
        ...state,
        isLoading: status
      };
    }

    default:
      return state;
  }
};

export default userProfileWidgets;
