import React, { useState, useEffect } from 'react';
import s from './ReleaseNote.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getReleaseNote } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialReleaseNotesState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Editor from 'react-simple-wysiwyg';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

import Banner from '../Banner/Banner';
import {
  describeReleaseNoteThunk,
  saveReleaseNoteThunk,
  setReleaseNoteChangeThunk,
  setReleaseNoteCleanUpThunk
} from '../../actions/releaseNotes';
import uuid from 'uuid';
import ReactTooltip from 'react-tooltip';

const ReleaseNote = ({ intl }) => {
  let history = useHistory();
  let { releaseNoteId } = useParams();

  const labelUnchecked = <FormattedMessage id="common.no" defaultMessage="No" />;
  const labelChecked = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const dispatch = useDispatch();
  const releaseNote = useSelector(state => getReleaseNote(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let IsEdit = false;
  if (releaseNoteId != '0') {
    IsEdit = true;
  }

  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let backUrl = '/admin-controls/release-notes';
  let widgetCode = 'RELEASENOTEMANAGE';

  useEffect(() => {
    if (releaseNoteId != '0') {
      dispatch(describeReleaseNoteThunk(releaseNoteId, 'RELEASENOTELIST'));
    }
  }, [describeReleaseNoteThunk, dispatch, releaseNoteId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(setReleaseNoteCleanUpThunk(initialReleaseNotesState.releaseNote.selectedReleaseNote));
    };
  }, []);

  // Update redux store
  const setLocalReleaseNote = currentState => {
    dispatch(setReleaseNoteChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = releaseNote.selectedReleaseNote;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.ReleaseNoteId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'RELEASENOTEMANAGE';
    }
    dispatch(saveReleaseNoteThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !releaseNote ||
      !releaseNote.selectedReleaseNote ||
      !releaseNote.selectedReleaseNote.Name ||
      isRequired(releaseNote.selectedReleaseNote.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'releaseNotes.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }
    if (
      !releaseNote ||
      !releaseNote.selectedReleaseNote ||
      !releaseNote.selectedReleaseNote.VersionNo ||
      isRequired(releaseNote.selectedReleaseNote.VersionNo, 1)
    ) {
      formErrors.VersionNo = intl.formatMessage({
        id: 'releaseNotes.versionNoRequired',
        defaultMessage: 'Version number is a mandatory field'
      });
      isValid = false;
    }
    if (
      !releaseNote ||
      !releaseNote.selectedReleaseNote ||
      !releaseNote.selectedReleaseNote.Notes ||
      isRequired(releaseNote.selectedReleaseNote.Notes, 1)
    ) {
      formErrors.Notes = intl.formatMessage({
        id: 'releaseNotes.noteRequired',
        defaultMessage: 'Note is a mandatory field'
      });
      isValid = false;
    }
    setFormErrors(formErrors);

    return isValid;
  };

  const onToggleChange = name => {
    setLocalReleaseNote({
      ...releaseNote,
      selectedReleaseNote: {
        ...releaseNote.selectedReleaseNote,
        [name]: !releaseNote.selectedReleaseNote[name]
      }
    });
  };

  //on control value change
  const onChange = e => {
    setLocalReleaseNote({
      ...releaseNote,
      selectedReleaseNote: { ...releaseNote.selectedReleaseNote, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.goBack();
  };

  let messageId = (releaseNote && releaseNote.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const noteName = (releaseNote.selectedReleaseNote ? releaseNote.selectedReleaseNote.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + noteName;
  } else {
    heading = intl.formatMessage({
      id: 'releaseNotes.addReleaseNote',
      defaultMessage: 'Add release note'
    });
  }

  return (
    <div className={s.releaseNote}>
      {releaseNote.isLoading && <LoadingSpinner />}
      {releaseNote.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={releaseNote.showBanner}
        status={releaseNote.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageReleaseNotes" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.releaseNoteHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.releaseNoteContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formReleaseNoteName">
                      <Form.Label>
                        <FormattedMessage id="releaseNotes.name" defaultMessage="Name" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={releaseNote.selectedReleaseNote.Name || ''}
                        className={`${s.formControl} ${localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'releaseNotes.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formReleaseNoteVersionNo">
                      <Form.Label>
                        <FormattedMessage id="releaseNotes.version" defaultMessage="Version" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="VersionNo"
                        onChange={onChange}
                        value={releaseNote.selectedReleaseNote.VersionNo || ''}
                        className={`${s.formControl} ${localformErrors.VersionNo ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'releaseNotes.version',
                          defaultMessage: 'Version'
                        })}
                      />
                      {localformErrors && localformErrors.VersionNo && (
                        <p role="alert" className={s.error}>
                          {localformErrors.VersionNo}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="formNotes">
                      <Form.Label>
                        <FormattedMessage id="releaseNotes.notes" defaultMessage="Notes" />
                      </Form.Label>
                      <div className={s.editor}>
                        <Editor
                          containerProps={{ style: { minHeight: '200px' } }}
                          name="Notes"
                          value={releaseNote.selectedReleaseNote.Notes}
                          onChange={onChange}
                        />
                      </div>
                      {localformErrors && localformErrors.Notes && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Notes}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formPublished">
                      <Form.Label>
                        <FormattedMessage id="releaseNotes.published" defaultMessage="Published" />
                      </Form.Label>
                      <ToggleSwitch
                        id="IsPublished"
                        handleClick={() => {
                          onToggleChange('IsPublished');
                        }}
                        classname={s.switch}
                        checked={releaseNote.selectedReleaseNote.IsPublished}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
        <ReactTooltip />
      </AdminControlsContentTemplate>
    </div>
  );
};

ReleaseNote.defaultProps = {
  releaseNote: {
    ...initialReleaseNotesState
  }
};

export default injectIntl(ReleaseNote);
