import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import EventInputs from './EventInputs';
import { getFlowSequenceGlobalSettings, getWarningEvents, getAlarmEvents, getInfoEvents } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    flowSequences: getFlowSequenceGlobalSettings(state),
    alarms: getAlarmEvents(state),
    warnings: getWarningEvents(state),
    infoEvents: getInfoEvents(state)
  };
};

export default connect(mapStateToProps, null)(EventInputs);
