import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionGlobalSettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelVersionState } from '../../reducers/initialState';
import ModelVersionGlobalSettingsListTable from '../ModelVersionGlobalSettings/ModelVersionGlobalSettingsListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const ModelVersionGlobalSettingsList = ({
  globalSettings,
  intl,
  ModelVersionId,
  ReadOnly,
  userProfileWidgets,
  unitGlobalSettings,
  getModelVersionsGlobalSettings,
  saveModelVersionGlobalSetting,
  describeModelVersionGlobalSetting,
  changeModelVersionGlobalSetting,
  setGlobalSetingShowScreenStatus
}) => {
  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;
  let initialRecords = globalSettings.GlobalSettingsList.slice(0, initialLimit);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    getModelVersionsGlobalSettings(offset, limit, filter, ModelVersionId);
  }, [getModelVersionsGlobalSettings, offset, limit, filter, ModelVersionId]);

  const [currentEditId, setCurrentEditId] = useState(-1);
  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [globalSettingId, setGlobalSettingId] = useState(0);
  const [searchText, setSearchText] = useState('');

  const localSettingLength = localSettings.length;
  const globalSettingLength = globalSettings.GlobalSettingsList.length;
  const addNewRef = useRef(null);

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(globalSettings.GlobalSettingsList);
    }
    setShowAll(!showAll);
  };

  const disableModeHandler = status => {
    setGlobalSetingShowScreenStatus(status);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = globalSettings.GlobalSettingsList.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const setAddHandler = () => {
    setGlobalSetingShowScreenStatus(true);
    setCurrentEditId(0);
    //Clearing existing settings
    changeModelVersionGlobalSetting({
      SelectedGlobalSetting: {
        ...initialModelVersionState.modelVersionManager.selectedModelVersion.GlobalSettings.SelectedGlobalSetting,
        ModelVersionId: ModelVersionId
      }
    });
    window.scrollTo(0, addNewRef.current.offsetTop);
  };

  const setEditHandler = modelVersionGlobalSettingId => {
    setCurrentEditId(modelVersionGlobalSettingId);
    if (modelVersionGlobalSettingId === -1) {
      changeModelVersionGlobalSetting({
        SelectedGlobalSetting: {
          ...initialModelVersionState.modelVersionManager.selectedModelVersion.GlobalSettings.SelectedGlobalSetting,
          ModelVersionId: ModelVersionId
        }
      });
      setGlobalSetingShowScreenStatus(false);
    } else {
      setGlobalSetingShowScreenStatus(true);
    }
  };

  const filterData = () => {
    let filteredList = globalSettings.GlobalSettingsList;
    if (isFilter) {
      filteredList = globalSettings.GlobalSettingsList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getSettings = () => {
    let globalSettings = [];
    if (showAll) {
      globalSettings = filterData();
    } else {
      if (isFilter) {
        globalSettings = localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        globalSettings = initialRecords;
      }
    }
    return globalSettings.sort(compareValues('Name'));
  };

  const removeGlobalSettingClick = globalSettingId => {
    setActive(true);
    setGlobalSettingId(globalSettingId);
  };
  const DeleteGlobalSetting = () => {
    let saveData = globalSettings.SelectedGlobalSetting;
    saveData.PerformDelete = true;
    saveData.ModelVersionId = ModelVersionId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.ModelVersionGlobalSettingId = globalSettingId;
    saveModelVersionGlobalSetting(saveData, 'MODELVERSIONGLOBALSETTINGSDELETE');
  };

  const handleClose = () => {
    setActive(false);
    setGlobalSettingId(0);
  };

  const handleConfirm = () => {
    DeleteGlobalSetting();
    handleClose();
  };

  let messageId = (globalSettings && globalSettings.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'modelVersion.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelVersion.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSIONGLOBALSETTINGSEDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSIONGLOBALSETTINGSDELETE');

  const dialogTitle = intl.formatMessage({
    id: 'modelVersion.confirmDeleteGlobalSettings',
    defaultMessage: 'Are you sure you want to delete the global setting?'
  });

  return (
    <div className={s.modelVersion}>
      {globalSettings.isLoading && <LoadingSpinner />}
      {globalSettings.showBanner && (
        <Banner
          failureText={messageText}
          showBanner={globalSettings.showBanner}
          status={globalSettings.isOpSuccessfull}
          successText={messageText}
        />
      )}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              disabled={globalSettings.showAddEditScreen}
              // initialText={filter}
              placeHolderTextId="modelVersion.search"
              data-unittest="searchGlobalSettings"
            />
          </Col>
          <Col sm={6} lg={8}>
            {!ReadOnly ? (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONGLOBALSETTINGSADD')}
                onClick={setAddHandler}
                disabled={globalSettings.showAddEditScreen}
                data-unittest="addGlobalSettingButton"
              >
                + <FormattedMessage id="modelVersion.addNew" defaultMessage="ADD NEW" />
              </Button>
            ) : (
              ''
            )}
          </Col>
        </Row>

        <div>
          <ModelVersionGlobalSettingsListTable
            setEditHandler={setEditHandler}
            ModelVersionId={ModelVersionId}
            selectedModelVersionGlobalSettingId={currentEditId}
            saveModelVersionGlobalSetting={saveModelVersionGlobalSetting}
            modelVersionGlobalSettings={globalSettings}
            describeModelVersionGlobalSetting={describeModelVersionGlobalSetting}
            changeModelVersionGlobalSetting={changeModelVersionGlobalSetting}
            unitGlobalSettings={unitGlobalSettings}
            UpdateAllowed={isUpdateAllowed}
            DeleteAllowed={isDeleteAllowed}
            globalSettingsData={getSettings()}
            disableMode={globalSettings.showAddEditScreen}
            disableModeHandler={disableModeHandler}
            addNewRef={addNewRef}
            ReadOnly={ReadOnly}
            removeGlobalSetting={removeGlobalSettingClick}
          />
        </div>
        {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

ModelVersionGlobalSettingsList.defaultProps = {
  ...initialModelVersionState
};

ModelVersionGlobalSettingsList.propTypes = {
  unitGlobalSettings: PropTypes.array,
  userProfileWidgets: PropTypes.object.isRequired,
  getModelVersionsGlobalSettings: PropTypes.func.isRequired,
  saveModelVersionGlobalSetting: PropTypes.func.isRequired,
  describeModelVersionGlobalSetting: PropTypes.func.isRequired,
  changeModelVersionGlobalSetting: PropTypes.func.isRequired,
  setGlobalSetingShowScreenStatus: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionGlobalSettingsList);
