import React, { useEffect, useState, Fragment, useRef } from 'react';
import s from './StaticData.module.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import Banner from '../Banner/Banner';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Link, useParams } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getStaticData, getUserProfileWidget, getCurrentTimezone, getUnitManager, getModelVersionManager } from '../../selectors/index';
import { setStaticDataChangeThunk, describeStaticDataThunk, saveStaticDataThunk, staticDataCleanUpThunk } from '../../actions/staticData';
import { getModelComponentPositionsThunk } from '../../actions/modelVersion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import GoBack from '../WSAControls/GoBack/GoBack';
import uuidv4 from 'uuid/v4';
import Table from 'react-bootstrap/Table';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import Cookies from 'js-cookie';
import { initialStaticDataState } from '../../reducers/initialState';

const StaticData = ({ intl }) => {
  var d = new Date();
  const currentStartTimestamp = moment(d).unix();

  let telemetryData = {
    UnitStaticDataTelemetryId: 0,
    ModelComponentPositionId: 0,
    ModelComponentPositionName: '',
    IsPublishable: false,
    Value: '',
    StartDateTime: 0,
    EndDateTime: 0,
    IsDeleted: false,
    IsActive: false,
    GuidIdentifier: uuidv4()
  };

  const dispatch = useDispatch();
  const addNewRef = useRef(null);
  const staticDataManager = useSelector(state => getStaticData(state));
  const modelVersionManager = useSelector(state => getModelVersionManager(state));
  const unitManager = useSelector(state => getUnitManager(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const [localformErrors, setFormErrors] = useState({});
  const [showAddEditScreen, setShowAddEditScreen] = useState(false);
  const [localTelemetryData, setLocalTelemetryData] = useState(telemetryData);
  const [currentEditId, setCurrentEditId] = useState(0);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const modelId = unitManager.selectedUnit.ModelId || 0;
  const unitId = unitManager.selectedUnit.UnitId || 0;
  let { unitSerialNumber, staticDataId } = useParams();
  let IsRedirect = false;
  const returnUrl = `/unit/static-data/${unitSerialNumber}`;

  let widgetCode = '';
  if (staticDataId) {
    widgetCode = 'UNITSTATICDATAUPDATE';
  } else {
    widgetCode = 'UNITSTATICDATAADD';
  }

  staticDataId = parseInt(staticDataId);

  useEffect(() => {
    if (staticDataId && unitId) {
      dispatch(describeStaticDataThunk(staticDataId, unitId, widgetCode));
    }
  }, [describeStaticDataThunk, staticDataId, unitId]);

  useEffect(() => {
    dispatch(getModelComponentPositionsThunk(modelId, true, null, 'UNIT_CONFIGURATION'));
  }, [getModelComponentPositionsThunk, modelId]);

  const setLocalStaticData = currentState => {
    dispatch(setStaticDataChangeThunk(currentState));
  };

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        staticDataCleanUpThunk({
          ...staticDataManager,
          selectedStaticData: { ...initialStaticDataState.staticDataManager.selectedStaticData }
        })
      );
    };
  }, []);

  let modelComponentPositions = (modelVersionManager && modelVersionManager.selectedModelVersion.ModelComponentPositions) || [];

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalStaticData({
        ...staticDataManager,
        selectedStaticData: { ...staticDataManager.staticDataManager, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalStaticData({
        ...staticDataManager,
        selectedStaticData: { ...staticDataManager.selectedStaticData, [e.target.name]: e.target.value }
      });
    }
  };

  const saveTelemetryDataHandler = savedTelemetryData => {
    setShowAddEditScreen(false);
    let telemetryDataList = staticDataManager.selectedStaticData.StaticDataTelemetry || [];

    let index = telemetryDataList.findIndex(obj => obj.GuidIdentifier === savedTelemetryData.GuidIdentifier);

    // Update the object if it exists
    if (index !== -1) {
      telemetryDataList[index] = savedTelemetryData;
    } else {
      telemetryDataList.push(savedTelemetryData);
    }

    setLocalTelemetryData(telemetryData);
    setLocalStaticData({
      ...staticDataManager,
      selectedStaticData: { ...staticDataManager.selectedStaticData, StaticDataTelemetry: telemetryDataList }
    });
  };

  const addTelemetryDataHandler = () => {
    setShowAddEditScreen(true);
    setCurrentEditId(0);
    window.scrollTo(0, addNewRef.current.offsetTop);
  };

  const cancelTelemetryDataHandler = () => {
    setShowAddEditScreen(false);
  };

  const editTelemetrytHandler = id => {
    let telemetryDataList = staticDataManager.selectedStaticData.StaticDataTelemetry || [];

    if (telemetryDataList.length > 0) {
      let editTelemetryData = telemetryDataList.find(x => x.GuidIdentifier === id);
      setLocalTelemetryData(editTelemetryData);
      setShowAddEditScreen(true);
      setCurrentEditId(id);
    }
  };

  const deleteTelemetrytHandler = id => {
    let telemetryDataList = staticDataManager.selectedStaticData.StaticDataTelemetry || [];

    if (telemetryDataList.length > 0) {
      let deleteTelemetryData = telemetryDataList.find(x => x.GuidIdentifier === id);
      if (deleteTelemetryData.UnitStaticDataTelemetryId) {
        telemetryDataList.forEach(item => {
          if (item.GuidIdentifier === id) {
            item.IsDeleted = 1;
          }
        });
      } else {
        telemetryDataList = telemetryDataList.filter(x => x.GuidIdentifier !== id);
      }
    }
    setLocalTelemetryData(telemetryData);
    setLocalStaticData({
      ...staticDataManager,
      selectedStaticData: { ...staticDataManager.selectedStaticData, StaticDataTelemetry: telemetryDataList }
    });
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !staticDataManager ||
      !staticDataManager.selectedStaticData ||
      !staticDataManager.selectedStaticData.Name ||
      isRequired(staticDataManager.selectedStaticData.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'staticData.nameMandatory',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const saveHandler = () => {
    if (!validateInput()) {
      return;
    }
    let saveData = staticDataManager.selectedStaticData;
    if (staticDataId === 0) {
      saveData.UnitId = unitId;
      saveData.GuidIdentifier = uuidv4();
    }
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`);
    dispatch(saveStaticDataThunk(saveData, widgetCode));
  };

  let messageId = (staticDataManager && staticDataManager.displayMessageCode) || '';
  let messageText = '';
  messageText = messageId !== '' ? intl.formatMessage({ id: messageId, defaultMessage: messageId }) : '';

  return (
    <div className={s.staticData}>
      {staticDataManager.isLoading && <LoadingSpinner />}
      {staticDataManager.isOpSuccessful && !staticDataManager.showBanner ? <Redirect to={returnUrl} /> : ''}
      {IsRedirect ? <Redirect to={returnUrl} /> : ''}
      <Banner
        failureText={messageText}
        showBanner={staticDataManager.showBanner}
        status={staticDataManager.isOpSuccessful}
        successText={messageText}
      />
      <ContentTemplate
        selectedPage="staticData"
        userProfileWidgets={userProfileWidgets}
        widgetCode={'UNITSTATICDATALIST'}
        tagoutRequired={true}
      >
        <div className={s.contentWrapper}>
          <div className={s.schedulerHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="schedule.back" defaultMessage="BACK" />
            </GoBack>
            <h3 data-unittest="headingLabel">
              <FormattedMessage id="staticData.staticData'" defaultMessage="New Static Data" />
            </h3>
          </div>
          <div className={s.staticDataContent}>
            <Row>
              <Col>
                <Link to={returnUrl}>
                  <Button variant="outline-secondary" className={classNames(s.margin5, s.btnMainCancel, s.floatRight)}>
                    <FormattedMessage id="staticData.cancel" defaultMessage="CANCEL" />
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveMainChanges, s.floatRight)}
                  onClick={saveHandler}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="staticData.save" defaultMessage="SAVE" />
                </Button>
              </Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={3} lg={6}>
                <Form.Group controlId="frmstaticDataName">
                  <Form.Label>
                    <FormattedMessage id="staticData.name" defaultMessage="Name" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Name"
                    onChange={onChange}
                    value={staticDataManager.selectedStaticData.Name}
                    className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                  />
                  {localformErrors && localformErrors.Name && (
                    <p role="alert" className={s.error}>
                      {localformErrors.Name}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} lg={6}>
                <Form.Group controlId="frmStaticDataDescription">
                  <Form.Label>
                    <FormattedMessage id="staticData.description" defaultMessage="Description" />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="Description"
                    onChange={onChange}
                    placeholder={intl.formatMessage({
                      id: 'staticData.enterDescription',
                      defaultMessage: 'Enter Static data description'
                    })}
                    value={staticDataManager.selectedStaticData.Description}
                    className={`${s.textArea} ${localformErrors && localformErrors.Description ? s.formControlError : ''}`}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6} lg={6}>
                <h4 className={s.section}>
                  <FormattedMessage id="staticData.telemetryData" defaultMessage="Telemetry Data" />
                </h4>
              </Col>
              <Col sm={6} lg={6}>
                <Button className={s.addbutton} onClick={addTelemetryDataHandler} disabled={showAddEditScreen}>
                  <FormattedMessage id="staticData.addNewTelemetryData" defaultMessage="ADD TELEMETRY DATA" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr></hr>
              </Col>
            </Row>
            <Row>
              <Col>
                <StaticDataTelemetryTable
                  showAddEditScreen={showAddEditScreen}
                  currentEditId={currentEditId}
                  componentPositions={modelComponentPositions}
                  currentTimezone={currentTimezone}
                  localTelemetryData={localTelemetryData}
                  onChangeLocalTelemetryData={setLocalTelemetryData}
                  selectedStaticData={staticDataManager.selectedStaticData}
                  saveTelemetryDataHandler={saveTelemetryDataHandler}
                  cancelTelemetryDataHandler={cancelTelemetryDataHandler}
                  editTelemetrytHandler={editTelemetrytHandler}
                  deleteTelemetrytHandler={deleteTelemetrytHandler}
                  addNewRef={addNewRef}
                  intl={intl}
                />
              </Col>
            </Row>
          </div>
        </div>
      </ContentTemplate>
    </div>
  );
};

const StaticDataTelemetryTable = ({
  showAddEditScreen,
  currentEditId,
  localTelemetryData,
  onChangeLocalTelemetryData,
  componentPositions,
  selectedStaticData,
  currentTimezone,
  cancelTelemetryDataHandler,
  saveTelemetryDataHandler,
  editTelemetrytHandler,
  deleteTelemetrytHandler,
  addNewRef,
  intl
}) => {
  let data =
    selectedStaticData.StaticDataTelemetry && selectedStaticData.StaticDataTelemetry.length > 0
      ? selectedStaticData.StaticDataTelemetry.filter(x => !x.IsDeleted)
      : [];

  const editContent = (
    <TelemetryAddEdit
      telemetryDataList={data}
      currentEditId={currentEditId}
      localTelemetryData={localTelemetryData}
      onChangeLocalTelemetryData={onChangeLocalTelemetryData}
      componentPositions={componentPositions}
      intl={intl}
      cancelTelemetryDataHandler={cancelTelemetryDataHandler}
      saveTelemetryDataHandler={saveTelemetryDataHandler}
      currentTimezone={currentTimezone}
    />
  );

  let editorMain = (
    <tr>
      <td colSpan="7">{editContent}</td>
    </tr>
  );

  let newRowClass = s.newRow;

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="staticData.componentPosition" defaultMessage="Component Position" />
            </th>
            <th>
              <FormattedMessage id="staticData.telemetryValue" defaultMessage="Telemetry Value" />
            </th>
            <th>
              <FormattedMessage id="staticData.telemetryStartDate" defaultMessage="Start Date" />
            </th>
            <th>
              <FormattedMessage id="staticData.telemetryEndDate" defaultMessage="End Date" />
            </th>
            <th>
              <FormattedMessage id="staticData.isPublishable" defaultMessage="Is Publishable" />
            </th>
            <th>
              <FormattedMessage id="staticData.isActive" defaultMessage="Is Data Active" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map(n => {
            return (
              <Fragment key={n.GuidIdentifier}>
                <tr key={n.GuidIdentifier} data-unittest="staticData">
                  <td>{n.ModelComponentPositionName || ''}</td>
                  <td>{n.Value}</td>
                  <td>
                    {n.StartDateTime
                      ? moment
                          .unix(n.StartDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm z')
                      : '-'}
                  </td>

                  <td>
                    {n.EndDateTime
                      ? moment
                          .unix(n.EndDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm z')
                      : '-'}
                  </td>
                  <td>
                    {n.IsPublishable ? (
                      <FormattedMessage id="staticData.yes" defaultMessage="Yes" />
                    ) : (
                      <FormattedMessage id="staticData.no" defaultMessage="No" />
                    )}
                  </td>
                  <td>
                    {n.IsActive ? (
                      <FormattedMessage id="staticData.yes" defaultMessage="Yes" />
                    ) : (
                      <FormattedMessage id="staticData.no" defaultMessage="No" />
                    )}
                  </td>
                  <td>
                    <Button variant="outline-secondary" className={s.changeButton} onClick={() => editTelemetrytHandler(n.GuidIdentifier)}>
                      <FormattedMessage id="staticData.edit" defaultMessage="Edit" />
                    </Button>
                    <Button
                      variant="outline-secondary"
                      className={s.changeButton}
                      onClick={() => deleteTelemetrytHandler(n.GuidIdentifier)}
                    >
                      <FormattedMessage id="staticData.remove" defaultMessage="Remove" />
                    </Button>
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className={newRowClass} ref={addNewRef}>
        {showAddEditScreen && editContent}
      </div>
    </div>
  );
};

export default injectIntl(StaticData);

const TelemetryAddEdit = ({
  telemetryDataList,
  currentEditId,
  localTelemetryData,
  onChangeLocalTelemetryData,
  componentPositions,
  isEdit,
  currentTimezone,
  saveTelemetryDataHandler,
  cancelTelemetryDataHandler,
  intl
}) => {
  const [localformErrors, setFormErrors] = useState({});

  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      onChangeLocalTelemetryData({
        ...localTelemetryData,
        [e.target.name]: e.target.checked
      });
    } else {
      onChangeLocalTelemetryData({
        ...localTelemetryData,
        [e.target.name]: e.target.value
      });
    }
  };
  const onDropdownChange = e => {
    onChangeLocalTelemetryData({
      ...localTelemetryData,
      ModelComponentPositionId: e.value,
      ModelComponentPositionName: e.label
    });
  };

  const onDateTimeChange = (label, value) => {
    if (value && value.unix) {
      onChangeLocalTelemetryData({
        ...localTelemetryData,
        [label]: value.unix()
      });
    }
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!localTelemetryData || isRequired(localTelemetryData.ModelComponentPositionId, 1)) {
      formErrors.GeneralGlobalSettingId = intl.formatMessage({
        id: 'staticData.componentRequired',
        defaultMessage: 'Component is required'
      });
      isValid = false;
    }

    if (!localTelemetryData || isRequired(localTelemetryData.Value, 1)) {
      formErrors.Value = intl.formatMessage({
        id: 'staticData.telemetryValueRequired',
        defaultMessage: 'Telemetry value is required'
      });
      isValid = false;
    }
    if (!localTelemetryData || !localTelemetryData.StartDateTime) {
      formErrors.StartDateTime = intl.formatMessage({
        id: 'staticData.telemetryStartDateRequired',
        defaultMessage: 'Telemetry start date is required'
      });
      formErrors.StartDateTimeBorder = s.ddlError;
      isValid = false;
    }

    if (!localTelemetryData || (localTelemetryData.EndDateTime && localTelemetryData.StartDateTime > localTelemetryData.EndDateTime)) {
      formErrors.EndDateTime = intl.formatMessage({
        id: 'staticData.telemetryEndDateInvalid',
        defaultMessage: 'Telemetry end date is invalid'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const componentPositionsOptions = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="filters.allpositions" defaultMessage="All Positions" />,
      value: 0
    });
    const filteredData = componentPositions.filter(x => x.IsStatic);

    filteredData.forEach(element => {
      if (telemetryDataList.filter(x => x.ModelComponentPositionId === element.ModelComponentPositionId).length === 1) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }
      itemData.push({
        ...element,
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return itemData.sort(compareValues('label'));
  };

  let ddlClass = localformErrors && localformErrors.ModelComponentPositionId ? s.ddlError : '';

  const addUpdateLocalHandler = e => {
    if (!validateInput()) {
      return;
    }
    saveTelemetryDataHandler(localTelemetryData);
  };
  return (
    <div className={s.staticData}>
      <div className={s.contentWrapper}>
        <Row>
          <Col lg={3}>
            <Form.Group controlId="formModelComponentPositionId">
              <Form.Label>
                <FormattedMessage id="staticData.componentName" defaultMessage="Component Name" />
              </Form.Label>
              <Dropdown
                id="formModelComponentPositionId"
                dataArray={componentPositionsOptions()}
                controlData={{ placeholderText: 'Select Setting', customClassName: ddlClass }}
                onDropdownChange={onDropdownChange}
                selectedOption={componentPositionsOptions().filter(option => option.value === localTelemetryData.ModelComponentPositionId)}
                disabled={isEdit}
                data-unittest="formModelComponentPosition"
              />
              {localformErrors && localformErrors.ModelComponentPositionId && (
                <p role="alert" className={s.error}>
                  {localformErrors.ModelComponentPositionId}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="formTelemetryValue">
              <Form.Label>
                <FormattedMessage id="staticData.telemetryValue" defaultMessage="Telemetry value" />
              </Form.Label>
              <InputNumber
                name="Value"
                step=".1"
                onInputChange={onChange}
                value={localTelemetryData.Value}
                id="formTelemetryValue"
                customClassName={`${localformErrors && localformErrors.Value ? s.formControlError : ''}`}
              />
              {localformErrors && localformErrors.Value && (
                <p role="alert" className={s.error}>
                  {localformErrors.Value}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="formTelemetryFromDate">
              <Form.Label>
                <FormattedMessage id="staticData.telemetryStartDate" defaultMessage="Start Date" />
              </Form.Label>
              <DateTime
                className={`${localformErrors.StartDateTime ? localformErrors.StartDateTimeBorder : s.frmCalendar}`}
                onChange={m => onDateTimeChange('StartDateTime', m)}
                timeFormat="HH:mm"
                dateFormat="DD-MMM-YYYY"
                value={(localTelemetryData.StartDateTime && moment.unix(localTelemetryData.StartDateTime).tz(currentTimezone)) || ''}
                closeOnSelect
              />
              {localformErrors && localformErrors.StartDateTime && (
                <p role="alert" className={s.error}>
                  {localformErrors.StartDateTime}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="formTelemetryEndDate">
              <Form.Label>
                <FormattedMessage id="staticData.telemetryEndDate" defaultMessage="End Date" />
              </Form.Label>
              <DateTime
                onChange={m => onDateTimeChange('EndDateTime', m)}
                timeFormat="HH:mm"
                dateFormat="DD-MMM-YYYY"
                value={localTelemetryData.EndDateTime && moment.unix(localTelemetryData.EndDateTime).tz(currentTimezone)}
                defaultValue={localTelemetryData.EndDateTime && moment.unix(localTelemetryData.EndDateTime).tz(currentTimezone)}
                closeOnSelect
              />

              {localformErrors && localformErrors.EndDateTime && (
                <p role="alert" className={s.error}>
                  {localformErrors.EndDateTime}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formIsPublishable">
              <Checkbox
                key={uuidv4()}
                dataArray={[
                  {
                    SKEY: 'IsPublishable',
                    target: { type: 'checkbox' },
                    label: intl.formatMessage({
                      id: 'staticData.isPublishable',
                      defaultMessage: 'Is Publishable'
                    }),
                    isChecked: localTelemetryData.IsPublishable
                  }
                ]}
                onSelectionChange={onChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Button
              variant="primary"
              className={classNames(s.margin5, s.btnSaveChanges)}
              onClick={addUpdateLocalHandler}
              noValidate
              data-unittest="saveData"
            >
              {currentEditId ? (
                <FormattedMessage id="staticData.update" defaultMessage="UPDATE" />
              ) : (
                <FormattedMessage id="staticData.add" defaultMessage="ADD" />
              )}
            </Button>
            <Button
              variant="outline-secondary"
              className={classNames(s.btnCancel)}
              onClick={cancelTelemetryDataHandler}
              noValidate
              data-unittest="saveCancel"
            >
              <FormattedMessage id="staticData.cancel" defaultMessage="CANCEL" />
            </Button>
          </Col>
          <Col lg={6}></Col>
        </Row>
      </div>
    </div>
  );
};
