import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './Task.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { initialTasksState, initialUserManagerState, initialTaskTemplatesState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import alert from '../../assets/alert.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import TasksAndChecklistsContentTemplate from '../TasksAndChecklists/TasksAndChecklistsContentTemplate';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import uuid from 'uuid';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import { TASK_STATUS_COMPLETE, TASK_STATUS_ACTIVE } from '../../constants';
import { getQuerystring } from '../../utils';
import GoBack from '../WSAControls/GoBack/GoBack';

const Task = ({
  saveTask,
  describeTask,
  taskManager,
  changeTask,
  profile,
  getProfileInfo,
  intl,
  currentTimezone,
  getTaskTemplates,
  taskTemplate,
  getUsers,
  userUnits,
  userManager,
  getUnitAlarms,
  userProfileWidgets,
  alarms,
  selectedDevice
}) => {
  let IsEdit = false;
  let heading = '';
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  let userId = Cookies.get(`userid-${envName}`) || 0;
  const STATUS_UNRESOLVED = 'Unresolved';

  let { TaskId } = useParams();
  const [localformErrors, setFormErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [localAlarms, setLocalAlarms] = useState([]);

  let checklistId = getQuerystring('checklistId') || 0;
  checklistId = parseInt(checklistId);

  let isServiceChecklist = getQuerystring('isServiceChecklist') === 'true' ? true : false;
  let unitId = getQuerystring('unitId') ? parseInt(getQuerystring('unitId')) : 0;

  let eventActionId = getQuerystring('eventActionId') || 0;
  eventActionId = parseInt(eventActionId);

  let prevPath = getQuerystring('prevPath') || '';

  let history = useHistory();
  let selectedUnitId = 0;

  if (TaskId) {
    IsEdit = true;
    heading = intl.formatMessage({ id: 'tasksManagement.edit', defaultMessage: 'Edit' });
  } else {
    heading = intl.formatMessage({ id: 'tasksManagement.newTask', defaultMessage: 'New Task' });
  }

  let widgetCode = '';

  if (TaskId) {
    widgetCode = 'TASKEDIT';
  } else {
    widgetCode = 'TASKADDNEW';
  }

  useEffect(() => {
    if (TaskId) {
      describeTask(TaskId);
    } else {
      setLocalTask({
        ...taskManager,
        selectedTask: { ...initialTasksState.tasks.selectedTask }
      });
    }
  }, [describeTask, TaskId]);

  useEffect(() => {
    return () => {
      setLocalTask({
        ...taskManager,
        selectedTask: { ...initialTasksState.tasks.selectedTask },
        isOpSuccessful: false
      });
    };
  }, []);

  useEffect(() => {
    getProfileInfo(widgetCode);
  }, [getProfileInfo, widgetCode]);

  useEffect(() => {
    getTaskTemplates();
  }, [getTaskTemplates]);

  useEffect(() => {
    getUsers(OrganisationId);
  }, [getUsers, OrganisationId]);

  const profilesObj = (profile.profiles && Object.values(profile.profiles.filter(p => p.IsActive && !p.IsDeleted))) || [];

  const unitsObj =
    userUnits && userUnits.userProfileUnits && userUnits.userProfileUnits.filter(x => x.OrganisationId === parseInt(OrganisationId));

  // Update redux store
  const setLocalTask = currentState => {
    changeTask(currentState);
  };

  useEffect(() => {
    if (unitId > 0) {
      let selectedUnit = unitOptions().find(option => option.value === unitId);
      setLocalTask({
        ...taskManager,
        selectedTask: { ...taskManager.selectedTask, UnitId: unitId, UnitName: (selectedUnit && selectedUnit.label) || '' }
      });
    }
  }, [unitId]);

  const disablePastDates = current =>
    current.isAfter(
      moment()
        .tz(currentTimezone)
        .subtract(1, 'day')
    );

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = taskManager.selectedTask;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = userId;
    saveData.OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
    saveData.alarms = localAlarms.map(item => item.EventActionId);
    if (saveData.Status !== TASK_STATUS_COMPLETE) {
      saveData.Status = TASK_STATUS_ACTIVE;
    }
    if (isServiceChecklist) {
      saveData.IsServiceTask = true;
    }

    if (!IsEdit) {
      saveData.TaskId = 0;
      saveData.GuidIdentifier = uuidv4();
      if (checklistId) {
        saveData.ChecklistId = checklistId;
      }
    }
    //saving Model Version
    saveTask(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!taskManager || !taskManager.selectedTask || !taskManager.selectedTask.Name || isRequired(taskManager.selectedTask.Name, 1)) {
      formErrors.Name = intl.formatMessage({
        id: 'taskTemplateManagement.nameMandatory',
        defaultMessage: 'Task Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalTask({
        ...taskManager,
        selectedTask: { ...taskManager.selectedTask, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalTask({
        ...taskManager,
        selectedTask: { ...taskManager.selectedTask, [e.target.name]: e.target.value }
      });
    }
  };

  const profileOptions = () => {
    let profileData = [];
    profilesObj.forEach(element => {
      profileData.push({
        ...element,
        label: element.Name,
        value: element.ProfileId
      });
    });
    return profileData.sort(compareValues('label'));
  };

  const templateOptions = () => {
    let templateData = [];
    taskTemplate.TaskTemplates.forEach(element => {
      templateData.push({
        ...element,
        label: element.Name,
        value: element.TaskTemplateId
      });
    });

    //Add none option
    templateData.push({
      label: 'None',
      value: 0
    });
    return templateData.sort(compareValues('label'));
  };

  const unitOptions = () => {
    let unitData = [];
    unitsObj &&
      unitsObj.forEach(element => {
        unitData.push({
          ...element,
          label: element.Name,
          value: element.UnitId
        });
      });
    return unitData.sort(compareValues('label'));
  };

  const userOptions = () => {
    let userData = [];
    users &&
      users.forEach(element => {
        userData.push({
          ...element,
          label: element.FullName,
          value: element.UserId
        });
      });
    return userData.sort(compareValues('label'));
  };

  const alarmOptions = () => {
    let alarmsData = [];
    if (taskManager.selectedTask.UnitId > 0 && alarms && alarms.length > 0) {
      alarms.forEach(element => {
        alarmsData.push({
          ...element,
          label: element.EventTitle,
          value: element.EventActionId
        });
      });

      if (eventActionId && localAlarms.length === 0) {
        let selectedAlarm = alarms.filter(x => x.EventActionId === eventActionId);
        let alarm = [];
        if (selectedAlarm && selectedAlarm.length > 0) {
          alarm.push({
            EventTitle: selectedAlarm[0].EventTitle,
            EventActionId: selectedAlarm[0].EventActionId
          });
        }
        setLocalAlarms(alarm);
      }
    }

    return alarmsData.sort(compareValues('label'));
  };

  const onRoleDropdownChange = e => {
    setLocalTask({
      ...taskManager,
      selectedTask: { ...taskManager.selectedTask, AssignedToProfileId: e.value }
    });

    let userObj = userManager && userManager.users && userManager.users.filter(x => x.selectedProfiles.includes(e.value));
    setUsers(userObj);
  };

  let assignedToProfileId = taskManager.selectedTask.AssignedToProfileId;
  useEffect(() => {
    if (IsEdit) {
      let userObj = userManager && userManager.users && userManager.users.filter(x => x.selectedProfiles.includes(assignedToProfileId));
      setUsers(userObj);
    }
  }, [assignedToProfileId]);

  const onTemplateDropdownChange = e => {
    const selectedTemplate = taskTemplate.TaskTemplates.filter(x => x.TaskTemplateId === e.value);

    if (selectedTemplate.length > 0) {
      setLocalTask({
        ...taskManager,
        selectedTask: {
          ...taskManager.selectedTask,
          Name: selectedTemplate[0].Name,
          Description: selectedTemplate[0].Description,
          Activities: selectedTemplate[0].Activities,
          EstimatedEffort: selectedTemplate[0].EstimatedEffort || 0,
          AssignedToProfileId: selectedTemplate[0].AssignedToProfileId || null,
          IsServiceTask: selectedTemplate[0].IsServiceTask
        }
      });
      if (selectedTemplate[0].AssignedToProfileId) {
        let userObj =
          userManager &&
          userManager.users &&
          userManager.users.filter(x => x.selectedProfiles.includes(selectedTemplate[0].AssignedToProfileId));
        setUsers(userObj);
      }
    } else {
      setLocalTask({
        ...taskManager,
        selectedTask: {
          ...taskManager.selectedTask,
          Name: '',
          Description: '',
          Activities: '',
          EstimatedEffort: 0,
          AssignedToProfileId: null,
          IsServiceTask: false
        }
      });
    }
  };

  const onUnitDropdownChange = e => {
    setLocalTask({
      ...taskManager,
      selectedTask: { ...taskManager.selectedTask, UnitId: e.value, UnitName: e.label, Alarms: [], alarms: null }
    });
    getUnitAlarms(e.value, STATUS_UNRESOLVED, widgetCode);
    setLocalAlarms([]);
  };

  useEffect(
    () => {
      if ((IsEdit || unitId > 0) && taskManager.selectedTask.UnitId > 0) {
        getUnitAlarms(taskManager.selectedTask.UnitId, STATUS_UNRESOLVED, widgetCode);
        setLocalAlarms(taskManager.selectedTask.Alarms);
      }
    },
    [getUnitAlarms, taskManager.selectedTask.UnitId],
    widgetCode
  );

  if (eventActionId) {
    let selectedUnit = unitsObj && unitsObj.filter(x => x.UnitSerialNumber === selectedDevice.unitSerialNumber);
    selectedUnitId = selectedUnit && selectedUnit[0].UnitId;
  }

  useEffect(() => {
    if (eventActionId) {
      getUnitAlarms(selectedUnitId, STATUS_UNRESOLVED, widgetCode);

      setLocalTask({
        ...taskManager,
        selectedTask: { ...initialTasksState.tasks.selectedTask, UnitId: selectedUnitId, UnitSerialNumber: selectedDevice.unitSerialNumber }
      });
    }
  }, [getUnitAlarms, selectedUnitId, widgetCode]);

  const onAssignToDropdownChange = e => {
    setLocalTask({
      ...taskManager,
      selectedTask: { ...taskManager.selectedTask, AssignedToUserId: e.value, AssignedByUserId: userId }
    });
  };

  const onAlarmsDropdownChange = e => {
    setLocalTask({
      ...taskManager,
      selectedTask: { ...taskManager.selectedTask, alarms: e.value }
    });

    let alarms = localAlarms;
    if (!alarms.some(x => x.EventActionId === e.value)) {
      alarms.push({
        EventTitle: e.label,
        EventActionId: e.value
      });
    }
    setLocalAlarms(alarms);
  };

  const removeLocalAlarm = id => {
    let alarms = localAlarms.filter(item => item.EventActionId !== id);

    setLocalAlarms(alarms);
  };

  const onDueDateChange = newVal => {
    let dateTime = null;
    if (newVal && newVal.unix) {
      dateTime = newVal.unix();
    }

    setLocalTask({
      ...taskManager,
      selectedTask: { ...taskManager.selectedTask, DueDateTime: dateTime }
    });
  };

  let messageId = (taskManager && taskManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const taskName = (taskManager.selectedTask ? taskManager.selectedTask.Name : '') || '';
  const selectAlarms = intl.formatMessage({ id: 'tasksManagement.selectAlarms', defaultMessage: 'Select current alarms for' });

  let backToMessage = intl.formatMessage({ id: 'tasksManagement.backtoTasks', defaultMessage: 'BACK TO TASKS' });
  if (checklistId) {
    backToMessage = intl.formatMessage({ id: 'checklistManagement.backtoChecklist', defaultMessage: 'BACK TO CHECKLIST' });
  }
  if (eventActionId) {
    backToMessage = intl.formatMessage({ id: 'tasksManagement.backtoAlarm', defaultMessage: 'BACK TO ALARM' });
  }

  const redirect = () => {
    if (taskManager.isOpSuccessful && !taskManager.showBanner) {
      return <Redirect to={prevPath} />;
    }
  };

  return (
    <div className={s.task}>
      {taskManager.isLoading && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={taskManager.showBanner}
        status={taskManager.isOpSuccessful}
        successText={messageText}
      />

      {redirect()}
      <TasksAndChecklistsContentTemplate selectedPage="myTasks" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.taskHeader}>
            <GoBack>
              &lt; &nbsp;
              {backToMessage}
            </GoBack>
            <h3 data-unittest="headingLabel">{!IsEdit ? heading : heading + ' ' + taskName}</h3>
          </div>
          <div className={s.taskContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTaskTemplate">
                    <Form.Label>
                      <FormattedMessage id="tasksManagement.selectTask" defaultMessage="Select from task templates" />
                    </Form.Label>

                    <Dropdown
                      id="formTaskTemplate"
                      dataArray={templateOptions()}
                      controlData={{ placeholderText: 'Select task template' }}
                      onDropdownChange={onTemplateDropdownChange}
                      // selectedOption={templateOptions().filter(option => option.value === taskManager.selectedTask.AssignedToProfileId)}
                      data-unittest="formTemplate"
                      disabled={IsEdit}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTaskName">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.task" defaultMessage="Task" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={taskManager.selectedTask.Name}
                      placeholder={intl.formatMessage({
                        id: 'taskTemplateManagement.enterTask',
                        defaultMessage: 'Enter task'
                      })}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTaskUnit">
                    <Form.Label>
                      <FormattedMessage id="tasksManagement.unit" defaultMessage="Unit" />
                    </Form.Label>

                    <Dropdown
                      id="formTaskUnit"
                      dataArray={unitOptions()}
                      controlData={{ placeholderText: 'Select unit' }}
                      onDropdownChange={onUnitDropdownChange}
                      selectedOption={unitOptions().filter(option => option.value === taskManager.selectedTask.UnitId)}
                      data-unittest="formUnit"
                      disabled={eventActionId || unitId > 0}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formTaskDescription">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.description" defaultMessage="Description / Objective" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'taskTemplateManagement.enterDescription',
                        defaultMessage: 'Enter description'
                      })}
                      value={taskManager.selectedTask.Description}
                      className={s.textArea}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTaskRole">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.role" defaultMessage="Role" />
                    </Form.Label>

                    <Dropdown
                      id="formTaskRole"
                      dataArray={profileOptions()}
                      controlData={{ placeholderText: 'Select role' }}
                      onDropdownChange={onRoleDropdownChange}
                      selectedOption={profileOptions().filter(option => option.value === taskManager.selectedTask.AssignedToProfileId)}
                      data-unittest="formRole"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formAssignTo">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.assignTo" defaultMessage="Assign to" />
                    </Form.Label>

                    <Dropdown
                      id="formAssignTo"
                      dataArray={userOptions()}
                      controlData={{ placeholderText: 'Select user' }}
                      onDropdownChange={onAssignToDropdownChange}
                      selectedOption={userOptions().filter(option => option.value === taskManager.selectedTask.AssignedToUserId)}
                      data-unittest="formUser"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formTaskEffort">
                    <div>
                      <Form.Label>
                        <FormattedMessage id="taskTemplateManagement.estimatedEffort" defaultMessage="Estimated effort" />
                      </Form.Label>
                    </div>
                    <div className="float-left">
                      <InputNumber
                        name="EstimatedEffort"
                        step="1"
                        onInputChange={onChange}
                        value={taskManager.selectedTask.EstimatedEffort}
                        id="formTaskEffort"
                        placeholder="Enter number"
                      />
                    </div>
                    <div className={s.hours}>hours</div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formDueDate">
                    <Form.Label>
                      <FormattedMessage id="tasksManagement.dueDateTime" defaultMessage="Due date & time" />
                    </Form.Label>

                    <DateTime
                      isValidDate={disablePastDates}
                      onChange={m => onDueDateChange(m)}
                      timeFormat="HH:mm:ss"
                      dateFormat="DD-MMM-YYYY"
                      value={
                        taskManager.selectedTask.DueDateTime ? moment.unix(taskManager.selectedTask.DueDateTime).tz(currentTimezone) : null
                      }
                      // defaultValue={moment().tz(currentTimezone)}
                      closeOnSelect={true}
                      className={s.dueDateTime}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formTaskActivities">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.activities" defaultMessage="Activities" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Activities"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'taskTemplateManagement.enterActivities',
                        defaultMessage: 'Enter activities'
                      })}
                      value={taskManager.selectedTask.Activities}
                      className={s.textArea}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formServiceTask">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsServiceTask',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'taskTemplateManagement.serviceTask',
                            defaultMessage: 'Service task'
                          }),
                          disable: isServiceChecklist,
                          isChecked: taskManager.selectedTask.IsServiceTask || isServiceChecklist
                        }
                      ]}
                      onSelectionChange={onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formLinkToAlarms">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.linkToAlarms" defaultMessage="Link to alarms" />
                    </Form.Label>

                    <Dropdown
                      id="formLinkToAlarms"
                      dataArray={alarmOptions()}
                      controlData={{ placeholderText: `${selectAlarms} ${taskManager.selectedTask.UnitName}` }}
                      onDropdownChange={onAlarmsDropdownChange}
                      selectedOption={alarmOptions().find(option => option.value === taskManager.selectedTask.alarms) || null}
                      data-unittest="formAlarm"
                      disabled={eventActionId}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {localAlarms.map(item => {
                return (
                  <Row key={item.EventActionId}>
                    <Col lg={6}>
                      <Alert variant="light" className={`d-flex flex-row align-items-center ${s.alarmAlert}`}>
                        <img src={alert} alt="icon" />
                        <span>
                          <span>{item.EventTitle}</span>
                        </span>
                      </Alert>
                    </Col>
                    <Col lg={1} className="pl-0">
                      <h6
                        className={`${eventActionId ? s.removeLinkDisable : s.removeLink}`}
                        onClick={() => (eventActionId ? '' : removeLocalAlarm(item.EventActionId))}
                      >
                        <FormattedMessage id="tasksManagement.remove" defaultMessage="REMOVE" />
                      </h6>
                    </Col>
                  </Row>
                );
              })}
              <div>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  {IsEdit ? (
                    <FormattedMessage id="taskTemplateManagement.saveChanges" defaultMessage="SAVE CHANGES" />
                  ) : (
                    <FormattedMessage id="tasksManagement.createTask" defaultMessage="Create Task" />
                  )}
                </Button>
                <Button variant="outline-secondary" className={s.btnCancel} onClick={() => history.goBack()}>
                  <FormattedMessage id="taskTemplateManagement.cancel" defaultMessage="CANCEL" />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </TasksAndChecklistsContentTemplate>
    </div>
  );
};

Task.defaultProps = {
  taskManager: {
    ...initialTasksState.tasks
  },
  profile: { profiles: [] },
  userUnits: {
    userProfileUnits: []
  },
  userManager: {
    ...initialUserManagerState.userManager
  },
  taskTemplate: {
    ...initialTaskTemplatesState.taskTemplate
  },
  selectedDevice: { unitSerialNumber: '' }
};

Task.propTypes = {
  saveTask: PropTypes.func.isRequired,
  taskManager: PropTypes.object.isRequired,
  describeTask: PropTypes.func.isRequired,
  changeTask: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  getProfileInfo: PropTypes.func.isRequired,
  getTaskTemplates: PropTypes.func.isRequired,
  taskTemplate: PropTypes.object.isRequired,
  userUnits: PropTypes.object.isRequired,
  userManager: PropTypes.object.isRequired,
  getUnitAlarms: PropTypes.func.isRequired,
  alarms: PropTypes.array,
  getUsers: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  selectedDevice: PropTypes.object
};

export default injectIntl(Task);
