import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './Checklists.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { initialChecklistState } from '../../reducers/initialState';
import ChecklistTable from './ChecklistTable';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import {
  DEFAULT_PAGE_SIZE,
  CHECKLIST_STATUS_ACTIVE,
  CHECKLIST_STATUS_COMPLETE,
  CHECKLIST_ASSIGNED_TO_ME,
  CHECKLIST_CREATED_BY_ME
} from '../../constants';
import { IsWidgetAccessible, WidgetVisibility } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { compareValues } from '../../utils';
import TasksAndChecklistsContentTemplate from '../TasksAndChecklists/TasksAndChecklistsContentTemplate';
import Cookies from 'js-cookie';

const STATUS = 'STATUS';
const SERVICE_CHECKLIST_FILTER = 'SERVICE_CHECKLIST_FILTER';
const SEARCH_FILTER = 'SEARCH_FILTER';
const USER_FILTER = 'USER_FILTER';
const UNIT_FILTER = 'UNIT_FILTER';

const Checklists = ({
  getChecklists,
  getChecklistsFilters,
  setFilterData,
  setCurrentPage,
  setPageFilter,
  checklist,
  redirect,
  intl,
  userProfileWidgets,
  userUnits,
  currentTimezone
}) => {
  let filter = checklist.filterBy.filter || '';
  let offset = checklist.offset || 0;
  let limit = checklist.limit || 1000;

  let filterByStatus = checklist.filterBy.filterStatus || null;
  let filterByIsServiceChecklist = checklist.filterBy.filterIsServiceChecklist || null;
  let filterByUnitId = checklist.filterBy.filterUnitId || null;
  let filterAssignedByUser = checklist.filterBy.filterAssignedByUser || null;
  let filterCreatedByUser = checklist.filterBy.filterCreatedByUser || null;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterStatus, setFilterStatus] = useState('0');
  const [filterIsServiceChecklist, setFilterIsServiceChecklist] = useState('0');
  const [filterUnitId, setFilterUnitId] = useState(0);
  const [filterUser, setFilterUser] = useState(0);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const [asendingOrder, setAsendingOrder] = useState(false);
  let OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  let userId = Cookies.get(`userid-${envName}`) || '0';

  useEffect(() => {
    getChecklistsFilters();
  }, [getChecklistsFilters]);

  useEffect(() => {
    let filterObj = {
      filterStatus: filterByStatus,
      offset: offset,
      limit: limit,
      filter: filter,
      filterIsServiceChecklist: filterByIsServiceChecklist,
      filterUnitId: filterByUnitId
    };
    getChecklists(filterObj);
  }, [getChecklists, offset, limit, filter, filterByStatus, filterByIsServiceChecklist, filterByUnitId]);

  const statusOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
      value: '0'
    });
    statusData.push({
      label: CHECKLIST_STATUS_ACTIVE,
      value: CHECKLIST_STATUS_ACTIVE
    });
    statusData.push({
      label: CHECKLIST_STATUS_COMPLETE,
      value: CHECKLIST_STATUS_COMPLETE
    });

    return statusData;
  };

  const serviceChecklistOptions = () => {
    let serviceData = [];

    serviceData.push({
      label: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
      value: '0'
    });
    serviceData.push({
      label: <FormattedMessage id="checklistManagement.serviceChecklist" defaultMessage="Service checklist" />,
      value: true
    });
    serviceData.push({
      label: <FormattedMessage id="checklistManagement.nonServiceChecklist" defaultMessage="Non-service checklist" />,
      value: false
    });

    return serviceData;
  };

  const userOptions = () => {
    let userData = [];

    userData.push({
      label: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
      value: '0'
    });
    userData.push({
      label: CHECKLIST_ASSIGNED_TO_ME,
      value: '1'
    });
    userData.push({
      label: CHECKLIST_CREATED_BY_ME,
      value: '2'
    });

    return userData;
  };

  const onStatusChange = e => {
    setFilterStatus(e.value);
    filteredResults(STATUS, e.value);
    setCurrentPage(1);
  };

  const onUserFilterChange = e => {
    setFilterUser(e.value);
    filteredResults(USER_FILTER, e.value);
    setCurrentPage(1);
  };

  const onIsServiceChecklistChange = e => {
    setFilterIsServiceChecklist(e.value);
    filteredResults(SERVICE_CHECKLIST_FILTER, e.value);
    setCurrentPage(1);
  };

  const onUnitIdChange = e => {
    setFilterUnitId(e.value);
    filteredResults(UNIT_FILTER, e.value);
    setCurrentPage(1);
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };
  const unitsObj =
    userUnits && userUnits.userProfileUnits && userUnits.userProfileUnits.filter(x => x.OrganisationId === parseInt(OrganisationId));

  const unitOptions = () => {
    let unitData = [];
    unitsObj &&
      unitsObj.forEach(element => {
        unitData.push({
          ...element,
          label: element.Name,
          value: element.UnitId
        });
      });
    unitData.sort(compareValues('label'));
    return [
      {
        label: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
        value: '0'
      },
      ...unitData
    ];
  };

  let checklistObj = Object.values(checklist.filteredResults);

  if (asendingOrder) {
    checklistObj = checklistObj.sort(compareValues('DueDateTime', 'desc'));
  } else {
    checklistObj = checklistObj.sort(compareValues('DueDateTime'));
  }

  const filteredResults = (filterType, value) => {
    let filteredList = checklist.Checklists;

    let apifilterObj = {
      filter: null,
      filterStatus: null,
      filterIsServiceChecklist: null,
      filterUnitId: null,
      filterAssignedUser: null,
      filterCreatedUser: null
    };

    if (filterType === STATUS) {
      if (value !== '0') {
        apifilterObj.filterStatus = value;
        filteredList = filteredList.filter(function(item) {
          return item.Status === value;
        });
      }
    } else {
      if (filterStatus !== '0') {
        apifilterObj.filterStatus = filterStatus;
        filteredList = filteredList.filter(function(item) {
          return item.Status === filterStatus;
        });
      }
    }

    let myUserId = parseInt(userId);
    if (filterType === USER_FILTER) {
      if (value !== '0') {
        if (value === '1') {
          apifilterObj.filterAssignedUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.AssignedToUserId === myUserId;
          });
        } else if (value === '2') {
          apifilterObj.filterCreatedUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.CreatedByUserId === myUserId;
          });
        }
      }
    } else {
      if (filterUser !== '0') {
        if (filterUser === '1') {
          apifilterObj.filterAssignedUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.AssignedToUserId === myUserId;
          });
        } else if (filterUser === '2') {
          apifilterObj.filterCreatedUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.CreatedByUserId === myUserId;
          });
        }
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (filterType === SERVICE_CHECKLIST_FILTER) {
      if (value !== '0') {
        apifilterObj.filterIsServiceChecklist = value;
        filteredList = filteredList.filter(function(item) {
          return item.IsServiceChecklist === value;
        });
      }
    } else {
      if (filterIsServiceChecklist !== '0') {
        apifilterObj.filterIsServiceChecklist = filterIsServiceChecklist;
        filteredList = filteredList.filter(function(item) {
          return item.IsServiceChecklist === filterIsServiceChecklist;
        });
      }
    }

    if (filterType === UNIT_FILTER) {
      if (value !== '' && value !== '0') {
        apifilterObj.filterUnitId = value;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === value;
        });
      }
    } else {
      if (filterUnitId && filterUnitId !== '' && filterUnitId !== '0') {
        apifilterObj.filterUnitId = filterUnitId;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === filterUnitId;
        });
      }
    }

    if (checklist.checklistCount > checklist.Checklists.length) {
      setPageFilter(apifilterObj);
    } else {
      setFilterData({ filteredResults: { filteredResults: filteredList } });
    }
  };

  //let messageId = (checklist && checklist.displayMessageCode) || 'none';
  //const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'CHECKLISTVIEW');

  return (
    <TasksAndChecklistsContentTemplate selectedPage="myChecklists" userProfileWidgets={userProfileWidgets}>
      <div style={WidgetVisibility(userProfileWidgets, `CHECKLISTLIST`)}>
        <div className={s.pageContent}>
          <div className={s.checklists}>
            {checklist.isLoading && <LoadingSpinner />}
            <div className={s.contentWrapper}>
              <Row>
                <Col sm={6} lg={4}>
                  <div>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={true}
                      initialText={searchFilter}
                      placeHolderTextId="checklistManagement.search"
                    />
                  </div>
                </Col>
                <Col sm={6} lg={8} style={WidgetVisibility(userProfileWidgets, `CHECKLISTADDNEW`)}>
                  <Link to="/tasks-and-checklists/checklist-detail/0">
                    <Button className={s.addbutton}>
                      + <FormattedMessage id="checklistManagement.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <span className={s.textHeading}>
                    {' '}
                    <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={3} lg={2}>
                  <div className={s.topColumns}>
                    <Dropdown
                      id="drpUnitId"
                      dataArray={unitOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
                        customClassName: ''
                      }}
                      onDropdownChange={onUnitIdChange}
                      selectedOption={unitOptions().filter(option => option.value === filterUnitId)}
                      disabled={false}
                    />
                  </div>
                </Col>
                <Col sm={3} lg={2}>
                  <div className={s.topColumns}>
                    <Dropdown
                      id="drpStatusId"
                      dataArray={statusOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
                        customClassName: ''
                      }}
                      onDropdownChange={onStatusChange}
                      selectedOption={statusOptions().filter(option => option.value === filterStatus)}
                      disabled={false}
                    />
                  </div>
                </Col>
                <Col sm={3} lg={2}>
                  <Dropdown
                    id="drpUserId"
                    dataArray={userOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
                      customClassName: ''
                    }}
                    onDropdownChange={onUserFilterChange}
                    selectedOption={userOptions().filter(option => option.value === filterUser)}
                    disabled={false}
                  />
                </Col>
                <Col sm={3} lg={2}>
                  <div className={s.topColumns}>
                    <Dropdown
                      id="drpChecklistId"
                      dataArray={serviceChecklistOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
                        customClassName: ''
                      }}
                      onDropdownChange={onIsServiceChecklistChange}
                      selectedOption={serviceChecklistOptions().filter(option => option.value === filterIsServiceChecklist)}
                      disabled={false}
                    />
                  </div>
                </Col>
              </Row>
              <div className="">
                <Pagination
                  data={checklistObj}
                  pageSize={DEFAULT_PAGE_SIZE}
                  onPageChange={onPageChangeHandler}
                  startingPage={checklist.currentPage}
                >
                  <ChecklistTable
                    redirect={redirect}
                    ViewAllowed={isViewAllowed}
                    currentPage={checklist.currentPage}
                    currentTimezone={currentTimezone}
                    onSortOrder={onSortOrder}
                    asendingOrder={asendingOrder}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TasksAndChecklistsContentTemplate>
  );
};

Checklists.defaultProps = {
  checklist: initialChecklistState.checklist
};

Checklists.propTypes = {
  setFilterData: PropTypes.func.isRequired,
  getChecklists: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  checklist: PropTypes.object.isRequired
};

export default injectIntl(Checklists);
