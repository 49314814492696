import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import GeneralGlobalSettingsList from './GeneralGlobalSettingsList';
import { getGlobalSettings, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import {
  saveGlobalSettingsThunk,
  setGlobalSettingsChangeThunk,
  getGlobalSettingsThunk,
  setCurrentPage,
  setPageFilter
} from '../../actions/globalSettings';

const mapStateToProps = state => {
  return {
    globalSettings: getGlobalSettings(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGlobalSettings: (offset, limit, filter) => getGlobalSettingsThunk(offset, limit, filter, 'GLOBALSETTINGS_TAB'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter),
      saveGlobalSettings: (globalSettings, widgetCode) => saveGlobalSettingsThunk(globalSettings, widgetCode),
      setGlobalSettingsChange: globalSettings => setGlobalSettingsChangeThunk(globalSettings)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GeneralGlobalSettingsList);
