import moment from 'moment';
import get from 'lodash.get';

import { EVENTS_ERROR, EVENT_IS_LOADING, SET_EVENTS, UPDATE_EVENT_INPUT, SET_SELECTED_DEVICE } from '../constants/index';
import { initialEventsState } from './initialState';

const eventsReducer = (state = initialEventsState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_EVENTS: {
      const { unitSerialNumber, Items, LastEvaluatedKey: lastEvaluatedKey, limit } = payload;
      const view = limit === 5 ? 'processFlow' : 'viewAll';
      return {
        ...state,
        [unitSerialNumber]: {
          ...state[unitSerialNumber],
          [view]: {
            ...state[unitSerialNumber][view],
            items: [...Items],
            lastEvaluatedKey
          }
        }
      };
    }

    case SET_SELECTED_DEVICE: {
      // Use setting the selected device to initalize input values if they don't exist
      const { unitSerialNumber } = payload;
      if (get(state, [unitSerialNumber, 'viewAll', 'inputs'])) return state;
      return {
        ...state,
        [unitSerialNumber]: {
          viewAll: {
            inputs: {
              type: '3',
              code: 'all',
              dateFrom: moment()
                .subtract(1, 'days')
                .valueOf(),
              dateTo: moment().valueOf()
            }
          }
        }
      };
    }

    case UPDATE_EVENT_INPUT: {
      const { unitSerialNumber, field, value } = payload;
      if (!get(state, [unitSerialNumber, 'viewAll', 'inputs'])) return state;
      return {
        ...state,
        [unitSerialNumber]: {
          ...state[unitSerialNumber],
          viewAll: {
            ...state[unitSerialNumber].viewAll,
            inputs: {
              ...state[unitSerialNumber].viewAll.inputs,
              [field]: value,
              ...(field === 'type' && { code: 'all' })
            }
          }
        }
      };
    }

    case EVENT_IS_LOADING: {
      const { unitSerialNumber, status, view } = payload;
      if (!get(state, [unitSerialNumber, view])) return state;
      return {
        ...state,
        [unitSerialNumber]: {
          ...state[unitSerialNumber],
          [view]: {
            ...state[unitSerialNumber][view],
            isLoading: status
          }
        }
      };
    }

    case EVENTS_ERROR: {
      const { unitSerialNumber, view } = payload;
      if (!get(state, [unitSerialNumber, view])) return state;
      return {
        ...state,
        [unitSerialNumber]: {
          ...state[unitSerialNumber],
          [view]: {
            ...state[unitSerialNumber][view],
            items: []
          }
        }
      };
    }

    default:
      return state;
  }
};

export default eventsReducer;
