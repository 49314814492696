import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelComponentPositionList from './ModelComponentPositionList';
import { getUserProfileWidget, getModelComponentPositionManagerWithParentList } from '../../selectors/index';

import {
  getModelComponentPositionThunk,
  saveModelComponentPositionThunk,
  setModelComponentPositionChangeThunk
} from '../../actions/modelComponentPositions';

const mapStateToProps = state => {
  return {
    modelComponentPositionManager: getModelComponentPositionManagerWithParentList(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getModelComponentPosition: (offset, limit, filter) =>
        getModelComponentPositionThunk({ offset, limit, filter }, 'MODELCOMPONENTPOSITION_LIST'),
      saveModelComponentPosition: (modelComponentPosition, widgetCode) =>
        saveModelComponentPositionThunk(modelComponentPosition, widgetCode),
      setModelComponentPositionChange: selectedModelComponentPosition =>
        setModelComponentPositionChangeThunk(selectedModelComponentPosition)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelComponentPositionList);
