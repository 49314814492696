import {
  GET_ALL_ORGANISATION_DETAILS,
  ORGANISATION_IS_LOADING,
  UPDATE_ORGANISATION_USER_SAVE_STATUS,
  ORGANISATION_MANAGEMENT_ERROR,
  SET_ORGANISATION_CURRENT_PAGE,
  SET_ORGANISATION_PAGE_FILTER,
  SET_ORGANISATION_DETAILS,
  UPDATE_ORGANISATION_DETAILS,
  SET_PARENT_ORGANISATION_DETAILS,
  UPDATE_ORGANISATION_USERS,
  UPDATE_ORGANISATION_UNITS
} from '../constants/index';
import { initialOrganisationManagerState } from './initialState';

const organisationManager = (state = initialOrganisationManagerState, action) => {
  const getSelectedUserIds = selectedUsers => {
    let userIds = selectedUsers.map(selUser => {
      return selUser.UserId;
    });
    return userIds;
  };

  const getSelectedUnitIds = selectedUnits => {
    let unitIds = selectedUnits.map(selUnit => {
      return selUnit.UnitId;
    });
    return unitIds;
  };

  const { payload, type } = action;
  switch (type) {
    case GET_ALL_ORGANISATION_DETAILS: {
      const { organisation, defaultProfiles = [] } = payload;
      return {
        ...state,
        defaultProfiles: defaultProfiles,
        organisationManager: {
          ...state.organisationManager,
          ...organisation
        }
      };
    }

    case SET_ORGANISATION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        organisationManager: {
          ...state.organisationManager,
          currentPage
        }
      };
    }

    case SET_ORGANISATION_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        organisationManager: {
          ...state.organisationManager,
          filter
        }
      };
    }

    case UPDATE_ORGANISATION_USER_SAVE_STATUS: {
      const { organisation } = payload;
      return {
        ...state,
        organisationManager: {
          ...state.organisationManager,
          ...organisation
        }
      };
    }

    case ORGANISATION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        organisationManager: {
          ...state.organisationManager,
          isLoading: isLoading
        }
      };
    }

    case ORGANISATION_MANAGEMENT_ERROR: {
      const { organisation } = payload;
      return {
        ...state,
        organisationManager: {
          ...state.organisationManager,
          ...organisation
        }
      };
    }

    case SET_ORGANISATION_DETAILS: {
      let { orgDetails, orgUsers, orgUnits, subOrgs, orgModelVersions, orgModels } = payload;
      orgDetails = (orgDetails.length > 0 && orgDetails[0]) || state.organisationManager.selectedOrganisation;

      return {
        ...state,
        organisationManager: {
          ...state.organisationManager,
          selectedOrganisation: {
            ...orgDetails,
            Users: getSelectedUserIds(orgUsers),
            Units: getSelectedUnitIds(orgUnits),
            SubOrganisations: subOrgs
          },
          users: orgUsers,
          units: orgUnits,
          orgModelVersions: orgModelVersions,
          orgModels: orgModels,
          toFilterUsers: orgUsers,
          toFilterUnits: orgUnits
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: true
      };
    }

    case UPDATE_ORGANISATION_DETAILS: {
      const { selectedOrganisation, parentOrganisationData, toFilterUsers, toFilterUnits, units, users } = payload;
      return {
        ...state,
        organisationManager: {
          ...state.organisationManager,
          selectedOrganisation: selectedOrganisation,
          parentOrganisationData: parentOrganisationData,
          toFilterUsers: toFilterUsers,
          toFilterUnits: toFilterUnits,
          units: units ? units : state.organisationManager.units,
          users: users ? users : state.organisationManager.users
        }
      };
    }

    case UPDATE_ORGANISATION_USERS: {
      const { toFilterUsers } = payload;
      return {
        ...state,
        organisationManager: {
          ...state.organisationManager,
          toFilterUsers: toFilterUsers
        }
      };
    }

    case UPDATE_ORGANISATION_UNITS: {
      const { toFilterUnits } = payload;
      return {
        ...state,
        organisationManager: {
          ...state.organisationManager,
          toFilterUnits: toFilterUnits
        }
      };
    }

    case SET_PARENT_ORGANISATION_DETAILS: {
      const { parentOrganisationData } = payload;
      return {
        ...state,
        organisationManager: {
          ...state.organisationManager,
          parentOrganisationData: parentOrganisationData
        }
      };
    }

    default:
      return state;
  }
};

export default organisationManager;
