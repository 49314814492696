import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelList from './ModelList';
import { getModelManager, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import { getModelsThunk, setCurrentPage, setPageFilter, setFilteredDataThunk } from '../../actions/model';

const mapStateToProps = state => {
  return {
    modelManager: getModelManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getModels: (offset, limit, filter, organisationId) => getModelsThunk(offset, limit, filter, 'MODELS_TAB', organisationId),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter),
      setFilterData: filteredData => setFilteredDataThunk(filteredData)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelList);
