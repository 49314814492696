import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Model from './Model';
import { getModelManager, getUserProfileWidget } from '../../selectors/index';
import {
  postModelThunk,
  describeModelThunk,
  setModelChangeThunk,
  modelCleanUpThunk,
  getModelVersionsThunk,
  setDuplicateChangeThunk
} from '../../actions/model';

const mapStateToProps = state => {
  return {
    modelManager: getModelManager(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveModel: (model, widgetCode) => postModelThunk(model, widgetCode),
      describeModel: (modelId, isDuplicate, widgetCode) => describeModelThunk(modelId, isDuplicate, widgetCode),
      changeModel: selectedModel => setModelChangeThunk(selectedModel),
      modelCleanUp: eventCode => modelCleanUpThunk(eventCode),
      getModelVersions: (modelId, offset, limit, filter, organisationId, includeSeqenceList) =>
        getModelVersionsThunk(modelId, offset, limit, filter, 'MODELVERSIONLIST', organisationId, includeSeqenceList),
      setDuplicateChange: modelVersions => setDuplicateChangeThunk(modelVersions)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Model);
