import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './Maintenance.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const Tab = ({ selectedPage, pageComponent, url, title }) => (
  <Fragment>
    <Link className={classNames({ [s.active]: selectedPage === pageComponent })} to={url}>
      {title}
      {selectedPage === pageComponent && <span />}
    </Link>
  </Fragment>
);

const MaintenanceTabs = ({ selectedPage, userProfileWidgets }) => (
  <ul className={s.tabNav}>
    {IsWidgetAccessible(userProfileWidgets, 'TELEMETRY_TAB') && (
      <li>
        <Tab
          url={`/maintenance/telemetry`}
          title={<FormattedMessage id="maintenance.tagout.telemetry" defaultMessage="Telemetry" />}
          selectedPage={selectedPage}
          pageComponent="telemetry"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'PARTS_TAB') && (
      <li>
        <Tab
          url={`/maintenance/parts`}
          title={<FormattedMessage id="maintenance.parts" defaultMessage="Parts" />}
          selectedPage={selectedPage}
          pageComponent="parts"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'SERVICE_TAB') && (
      <li>
        <Tab
          url={`/maintenance/service`}
          title={<FormattedMessage id="maintenance.service" defaultMessage="Service" />}
          selectedPage={selectedPage}
          pageComponent="service"
        />
      </li>
    )}

    {IsWidgetAccessible(userProfileWidgets, 'TAGOUT_TAB') && (
      <li>
        <Tab
          url={`/maintenance/tagout-history`}
          title={<FormattedMessage id="maintenance.tagoutHistory" defaultMessage="Tag Out History" />}
          selectedPage={selectedPage}
          pageComponent="tagoutHistory"
        />
      </li>
    )}
  </ul>
);

MaintenanceTabs.propTypes = {
  selectedPage: PropTypes.string.isRequired
};

export default MaintenanceTabs;
