import React from 'react';
import PropTypes from 'prop-types';
import s from './ModelFirmwareVersionList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const ModelFirmwareVersionListTable = ({ FirmwareVersionData, ModelId, UpdateAllowed, currentTimezone }) => {
  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="modelFirmwareVersion.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="modelFirmwareVersion.version" defaultMessage="Version" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelFirmwareVersion.modelComponentPosition" defaultMessage="Component" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelFirmwareVersion.publishedDate" defaultMessage="Published Date" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelFirmwareVersion.publishedBy" defaultMessage="Published By" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelFirmwareVersion.activeVersion" defaultMessage="Active Version" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {FirmwareVersionData.map(n => {
            return (
              <tr key={n.ModelFirmwareVersionId} data-unittest="modelFirmwareFirmwareVersionData">
                <td>{n.Name || ''}</td>
                <td>{n.Version || ''}</td>
                <td className="d-none d-md-table-cell">{n.ModelComponentPositionName || ''}</td>
                <td className="d-none d-md-table-cell">
                  {n.PublishedDateTime
                    ? moment
                        .unix(n.PublishedDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY  HH:mm:ss z')
                    : '-'}
                </td>
                <td className="d-none d-md-table-cell">{n.PublishedBy || ''}</td>
                <td className="d-none d-md-table-cell">
                  {n.IsCurrentVersion ? (
                    <FormattedMessage id="common.yes" defaultMessage="yes" />
                  ) : (
                    <FormattedMessage id="common.no" defaultMessage="no" />
                  )}
                </td>
                <td>
                  {UpdateAllowed ? (
                    <Link to={`/admin-controls/model-configuration/${ModelId}/firmware/edit/${n.ModelFirmwareVersionId}`}>
                      <Button variant="outline-secondary" className={s.changeButton}>
                        <FormattedMessage id="modelFirmwareVersion.edit" defaultMessage="EDIT" />
                      </Button>
                    </Link>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ModelFirmwareVersionListTable.defaultProps = {
  FirmwareVersionData: []
};

ModelFirmwareVersionListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(ModelFirmwareVersionListTable);
