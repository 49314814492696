import React from 'react';
import PropTypes from 'prop-types';
import s from './UnitList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import online from '../../assets/online.svg';
import offline from '../../assets/offline.svg';
import alarm from '../../assets/alarm.svg';
import warning from '../../assets/warning-large.svg';
import pendingAlarm from '../../assets/alarm-outline.svg';
import { ALARM_STATUS_NEW, ALARM_STATUS_PENDING } from '../../constants';
import moment from 'moment';

const UnitListTable = ({ paginatedData, setSelectedUnit, devicesStatus, deviceAlarms, intl }) => {
  const history = useHistory();
  const onRowClick = unit => {
    setSelectedUnit(unit.UnitSerialNumber);
    history.push(`/unit/process-flow/${unit.UnitSerialNumber}`);
  };

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="unitListing.unitName" defaultMessage="Unit Name" />
            </th>
            <th className={'d-none d-md-table-cell '}>
              <FormattedMessage id="unitListing.serialNumber" defaultMessage="Serial number" />
            </th>
            <th className={'d-none d-md-table-cell '}>
              <FormattedMessage id="unitListing.model" defaultMessage="Model" />
            </th>
            <th className={'d-none d-md-table-cell'}>
              <FormattedMessage id="unitListing.location" defaultMessage="Location" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="unitListing.status" defaultMessage="Status" />
            </th>
            <th>
              <FormattedMessage id="unitListing.connection" defaultMessage="Connection" />
            </th>
            <th>
              <FormattedMessage id="unitListing.alerts" defaultMessage="Alerts" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            const deviceSensorData =
              devicesStatus &&
              devicesStatus.deviceSensorReadings &&
              devicesStatus.deviceSensorReadings.filter(sensor => sensor.unitserialnumber === n.UnitSerialNumber);
            const timestamp = deviceSensorData && deviceSensorData.map(item => item.timestamp);
            const deviceOnline = timestamp && timestamp[0] && moment(timestamp[0]).isBetween(moment().subtract(1, 'minute'), moment());

            const deviceEventData =
              devicesStatus && devicesStatus.events && devicesStatus.events.filter(event => event.unitSerialNumber === n.UnitSerialNumber);

            const deviceAlarmsData = deviceAlarms && deviceAlarms.filter(x => x.UnitSerialNumber === n.UnitSerialNumber);
            const warnings =
              deviceEventData &&
              deviceEventData.length > 0 &&
              deviceEventData[0].events &&
              deviceEventData[0].events.filter(item => item.type === 1);

            const newAlarms =
              deviceAlarmsData &&
              deviceAlarmsData.filter(event => {
                return event.EventStatus === ALARM_STATUS_NEW;
              });

            const pendingAlarms =
              deviceAlarmsData &&
              deviceAlarmsData.filter(event => {
                return event.EventStatus === ALARM_STATUS_PENDING;
              });

            let warningCount = (warnings && warnings.length) || 0;
            let newAlarmsCount = (newAlarms && newAlarms.length) || 0;
            let pendingAlarmsCount = (pendingAlarms && pendingAlarms.length) || 0;

            const eventTypeClass = newAlarmsCount > 0 || pendingAlarmsCount > 0 ? s.borderRed : warningCount > 0 ? s.borderYellow : '';
            let newAlarmMessage = intl.formatMessage({ id: 'unitListing.newAlarms', defaultMessage: 'New alarms' });
            let pendingAlarmMessage = intl.formatMessage({ id: 'unitListing.pendingAlarms', defaultMessage: 'Pending alarms' });
            let warningMessage = intl.formatMessage({ id: 'unitListing.warnings', defaultMessage: 'Warnings in the last 24h' });

            return (
              <tr
                key={n.UnitId}
                data-unittest="unitListingData"
                className={eventTypeClass}
                onClick={() => {
                  onRowClick(n);
                }}
              >
                <td>{n.Name || ''}</td>
                <td className={'d-none d-md-table-cell '}>{n.UnitSerialNumber || ''}</td>
                <td className={'d-none d-md-table-cell '}>{n.ModelName || ''}</td>
                <td className={'d-none d-md-table-cell'}>{n.Location}</td>
                <td className={'d-none d-md-table-cell '}>
                  {n.Status || <FormattedMessage id="unitListing.dataNotAvaialable" defaultMessage="Data not available" />}
                </td>
                <td>
                  <img src={deviceOnline ? online : offline} />
                </td>
                <td>
                  {newAlarmsCount > 0 ? <img title={`${newAlarmMessage} (${newAlarmsCount})`} className={s.icon} src={alarm} /> : ''}
                  {pendingAlarmsCount > 0 ? (
                    <img title={`${pendingAlarmMessage} (${pendingAlarmsCount})`} className={s.icon} src={pendingAlarm} />
                  ) : (
                    ''
                  )}
                  {warningCount > 0 ? <img title={`${warningMessage} (${warningCount})`} className={s.icon} src={warning} /> : ''}
                  {!newAlarmsCount && !pendingAlarmsCount && !warningCount && (
                    <FormattedMessage id="unitListing.none" defaultMessage="None" />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

UnitListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

UnitListTable.propTypes = {
  paginatedData: PropTypes.array.isRequired
};

export default injectIntl(UnitListTable);
