import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AccountSettingsTab from './AccountSettingsTabs';
import s from './AccountSettingsContentTemplate.module.scss';

const AccountSettingsContentTemplate = ({ backLink, children, selectedPage }) => (
  <Fragment>
    <div className={s.fullWidth}>
      <div className={s.fullWidthWrapper}>
        <h1 className={s.tabHeader}>{<FormattedMessage id="accountSettings.settings" defaultMessage="Account Settings" />}</h1>
        {<AccountSettingsTab selectedPage={selectedPage} />}
      </div>
    </div>

    <div className={s.pageContent}>{children}</div>
  </Fragment>
);

AccountSettingsContentTemplate.defaultProps = {
  backLink: '/',
  selectedPage: ''
};

AccountSettingsContentTemplate.propTypes = {
  backLink: PropTypes.string,
  children: PropTypes.node.isRequired,
  selectedPage: PropTypes.string
};

export default AccountSettingsContentTemplate;
