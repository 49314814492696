import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UnitUpload from './UnitUpload';
import { getUnitManager } from '../../selectors/index';
import { postUnitSaveThunk } from '../../actions/unitManager';

const mapStateToProps = (state, props) => {
  return {
    unitManager: getUnitManager(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveUnit: unit => postUnitSaveThunk(unit, 'UPLOAD_UNIT')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UnitUpload);
