import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { FormattedMessage } from 'react-intl';
import s from './SensorTable.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getCompPositionLabel } from '../../utils/helperMethods';

const SensorTable = ({ sensorData, unitComponentParts }) => {
  const { timestamp, ...sensorReadings } = sensorData;
  const sensors = Object.keys(sensorReadings).sort();
  const showLoading = sensors.length === 0;

  // const modelSensors = unitComponentParts.filter(comp => comp.UoM);
  // const sensorOptions = modelSensors.sort().map(sensor => ({ value: sensor.Name.toLowerCase(), label: sensor.Name.toLowerCase() }));

  return (
    <div className={s.SensorTableWrapper}>
      <span>
        <FormattedMessage id="sensorTable.realTimeSensor" defaultMessage="Real Time Sensor" />
      </span>

      {showLoading && <LoadingSpinner />}

      {!showLoading && sensors.length > 0 && (
        <div className="mt-2">
          {sensors.map((sensor, i) => {
            let sensorName = sensor;
            let isCCPorOCP = false;
            let ccpocpStyle = '';
            let SensorInfo = unitComponentParts.filter(e => e.Name.toLowerCase() === sensorName.toLowerCase());
            if (SensorInfo.length > 0) {
              sensorName = getCompPositionLabel(SensorInfo[0]);
              isCCPorOCP = SensorInfo[0].IsCCP || SensorInfo[0].IsOCP || false;
            }

            if (isCCPorOCP) {
              ccpocpStyle = s.isCCPorOCP;
            }
            //isCCPorOCP

            if (!SensorInfo || SensorInfo.length === 0) return null;

            return (
              <Row key={`${sensor}-${i}`} className={s.sensorContent}>
                <Col md={6} xs={12} className={`${s.sensorName} ${ccpocpStyle}`}>
                  {/* {SensorInfo[0].Description} {SensorInfo[0].Description ? '-' : ''} {sensorName} */}
                  {sensorName.toUpperCase()}
                </Col>
                <Col md={6} xs={12} className={`${ccpocpStyle}`}>{`${Math.round(sensorData[sensor] * 1000) / 1000} ${
                  SensorInfo[0].UoM ? SensorInfo[0].UoM : ''
                }`}</Col>
              </Row>
            );
          })}
        </div>
      )}
    </div>
  );
};

SensorTable.propTypes = {
  sensorData: PropTypes.object.isRequired,
  unitComponentParts: PropTypes.array.isRequired
};

export default SensorTable;
