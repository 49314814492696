import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Calibration from './Calibration';
import {
  getCalibration,
  getUserProfileWidget,
  getCurrentTimezone,
  getDevicesUnitComponentParts,
  getDevicesUnitSubComponent
} from '../../selectors/index';
import {
  describeCalibrationThunk,
  setCalibrationChangeThunk,
  setCalibrationResultChangeThunk,
  describeCalibrationEquationThunk,
  setCalibrationConstantThunk,
  saveCalibrationThunk,
  saveCalibrationCommentThunk
} from '../../actions/calibration';
import { getUnitComponentPartsThunk } from '../../actions/devices';

const mapStateToProps = state => {
  return {
    calibrationManager: getCalibration(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state),
    unitComponentParts: getDevicesUnitComponentParts(state),
    unitSubComponentDetails: getDevicesUnitSubComponent(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCalibrationChange: calibration => setCalibrationChangeThunk(calibration),
      setCalibrationResultChange: calibrationResults => setCalibrationResultChangeThunk(calibrationResults),
      describeCalibration: (calibrationId, unitSerialNumber) =>
        describeCalibrationThunk(calibrationId, unitSerialNumber, 'CALIBRATION_EDIT'),
      describeCalibrationEquation: (calibrationEquationId, calibrationId) =>
        describeCalibrationEquationThunk(calibrationEquationId, calibrationId, 'CALIBRATION_ADD'),
      setCalibrationConstant: calibrationCompConstants => setCalibrationConstantThunk(calibrationCompConstants),
      getUnitComponentParts: (unitSerialNumber, unitId) => getUnitComponentPartsThunk(unitSerialNumber, unitId, 'CALIBRATION_ADD'),
      saveCalibration: (calibration, unitSerialNumber, isOpSuccessful, widgetCode) =>
        saveCalibrationThunk(calibration, unitSerialNumber, isOpSuccessful, widgetCode),
      saveCalibrationComment: (calibration, unitSerialNumber, widgetCode) =>
        saveCalibrationCommentThunk(calibration, unitSerialNumber, widgetCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Calibration);
