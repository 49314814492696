import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ChecklistDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialChecklistTemplateState, initialChecklistState, initialTasksState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Link, useParams, useHistory, Redirect } from 'react-router-dom';
import bs from '../../styles/bootstrap-overrides.scss';
import ChecklistDetailTask from './ChecklistDetailTask';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TasksAndChecklistsContentTemplate from '../TasksAndChecklists/TasksAndChecklistsContentTemplate';
import classNames from 'classnames';
import { isRequired, getQuerystring, compareValues } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';
import MoreOption from '../WSAControls/MoreOption/MoreOption';
import { CHECKLIST_STATUS_ACTIVE, CHECKLIST_STATUS_COMPLETE } from '../../constants';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import ProgressBar from '../WSAControls/ProgressBar/ProgressBar';
import GoBack from '../WSAControls/GoBack/GoBack';
import greentick from '../../assets/greentick.svg';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';

const ChecklistDetail = ({
  intl,
  userProfileWidgets,
  currentTimezone,
  checklistTemplate,
  checklist,
  getChecklistTemplate,
  describeChecklistTemplate,
  describeChecklist,
  saveChecklist,
  changeChecklist,
  resetTasks,
  userUnits,
  changeChecklistTemplate,
  getUsers,
  userManager,
  getProfileInfo,
  profile
}) => {
  let IsEdit = false;
  let IsView = false;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  let userId = Cookies.get(`userid-${envName}`) || 0;

  let { checklistId } = useParams();
  let view = getQuerystring('view');
  const history = useHistory();

  const [formError, setFormError] = useState({});
  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchStatusType, setSearchStatusType] = useState('');
  const [sortOrder, setSortOrder] = useState(false);
  const [active, setActive] = useState(false);

  const [showServiceDialog, setShowServiceDialog] = useState(false);
  const [showUnitDialog, setShowUnitDialog] = useState(false);
  const [users, setUsers] = useState([]);

  checklistId = parseInt(checklistId);
  const dialogServiceTitle = intl.formatMessage({
    id: 'checklistTemplateManagement.confirmServiceTask',
    defaultMessage: 'Tasks attached to the checklist will be converted to service tasks. Do you want to continue?'
  });
  const dialogUnitTitle = intl.formatMessage({
    id: 'checklistTemplateManagement.confirmUnitTask',
    defaultMessage: 'Units attached to the checklist will be inherited by tasks. Do you want to continue?'
  });

  IsEdit = checklistId !== 0 && !view;
  IsView = checklistId !== 0 && view === '1';

  const unitsObj =
    userUnits && userUnits.userProfileUnits && userUnits.userProfileUnits.filter(x => x.OrganisationId === parseInt(OrganisationId));

  const profilesObj = (profile.profiles && Object.values(profile.profiles.filter(p => p.IsActive && !p.IsDeleted))) || [];

  const changeAllowed = IsWidgetAccessible(userProfileWidgets, `CHECKLISTEDIT`);
  const deleteAllowed =
    IsWidgetAccessible(userProfileWidgets, `CHECKLISTDELETE`) &&
    checklist.SelectedChecklist.CreatedByUserId &&
    Cookies.get(`userid-${envName}`) == checklist.SelectedChecklist.CreatedByUserId;

  let isChecklistCompleted = checklist.SelectedChecklist.Status === CHECKLIST_STATUS_COMPLETE;

  let messageId = (checklist && checklist.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const checklistName = (checklist.SelectedChecklist ? checklist.SelectedChecklist.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'checklistDetail.edit', defaultMessage: 'Edit' }) + ' ' + checklistName;
  } else if (IsView) {
    heading = checklistName + ' ' + intl.formatMessage({ id: 'checklistDetail.checklist', defaultMessage: 'Checklist' });
  } else {
    heading = intl.formatMessage({ id: 'checklistDetail.newChecklist', defaultMessage: 'New Checklist' });
  }

  const backLink = !IsEdit ? '/tasks-and-checklists/checklists' : `/tasks-and-checklists/checklist-detail/${checklistId}?view=1`;

  //Checking permission and redirecting to unauth page if unauthorised
  if (IsEdit && !IsWidgetAccessible(userProfileWidgets, 'CHECKLISTEDIT')) {
    history.push('/unauth');
  }
  if (IsView && !IsWidgetAccessible(userProfileWidgets, 'CHECKLISTVIEW')) {
    history.push('/unauth');
  }
  if (checklistId === 0 && !IsWidgetAccessible(userProfileWidgets, 'CHECKLISTADDNEW')) {
    history.push('/unauth');
  }

  let widgetCode = '';

  if (checklistId) {
    if (IsView) widgetCode = 'CHECKLISTVIEW';
    else widgetCode = 'CHECKLISTEDIT';
  } else {
    widgetCode = 'CHECKLISTADDNEW';
  }

  useEffect(() => {
    if (widgetCode !== 'CHECKLISTVIEW') {
      getProfileInfo(widgetCode);
    }
  }, [getProfileInfo, widgetCode]);

  useEffect(() => {
    if (widgetCode !== 'CHECKLISTVIEW') {
      getUsers(OrganisationId, widgetCode);
    }
  }, [getUsers, OrganisationId, widgetCode]);

  useEffect(() => {
    //Clearing Task state
    resetTasks(initialTasksState);
    changeChecklist(initialChecklistState.checklist);
    changeChecklistTemplate({
      SelectedChecklistTemplate: {
        ...initialChecklistTemplateState.checklistTemplate.SelectedChecklistTemplate,
        SelectedTaskTemplates: []
      }
    });

    if (checklistId === 0) getChecklistTemplate(widgetCode);
    else describeChecklist(checklistId, widgetCode);
  }, [getChecklistTemplate, describeChecklist, checklistId, changeChecklist, IsView, resetTasks, changeChecklistTemplate]);

  useEffect(() => {
    let selectedTemplate = checklistTemplate.SelectedChecklistTemplate;
    let selectedChecklistTemplate = {
      SelectedChecklist: {
        ChecklistTemplateId: selectedTemplate.ChecklistTemplateId,
        Name: selectedTemplate.Name,
        Description: selectedTemplate.Description,
        IsServiceChecklist: selectedTemplate.IsServiceChecklist,
        SelectedTasks: selectedTemplate.SelectedTaskTemplates
      }
    };

    changeChecklist(selectedChecklistTemplate);
  }, [changeChecklist, checklistTemplate.SelectedChecklistTemplate]);

  const disablePastDates = current =>
    current.isAfter(
      moment()
        .tz(currentTimezone)
        .subtract(1, 'day')
    );

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = checklist.SelectedChecklist;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    saveData.MarkAsComplete = false;
    saveData.MarkAsDeleted = false;

    if (!IsEdit) {
      saveData.Status = CHECKLIST_STATUS_ACTIVE;

      saveData.SelectedTasks = saveData.SelectedTasks.map(elem => {
        return {
          ...elem,
          Status: CHECKLIST_STATUS_ACTIVE,
          GuidIdentifier: uuidv4(),
          DueDateTime: saveData.DueDateTime,
          UpdatedBy: Cookies.get(`userid-${envName}`) || 1
        };
      });
      saveData.TaskId = 0;
      saveData.GuidIdentifier = uuidv4();
    }

    saveChecklist(saveData, widgetCode);
  };

  const validateInput = () => {
    let formError = {};

    let isValid = true;

    if (
      !checklist ||
      !checklist.SelectedChecklist ||
      !checklist.SelectedChecklist.Name ||
      isRequired(checklist.SelectedChecklist.Name, 1)
    ) {
      formError.Name = intl.formatMessage({
        id: 'checklistManagement.nameMandatory',
        defaultMessage: 'Checklist Name is a mandatory field'
      });
      isValid = false;
    }
    setFormError(formError);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      checklist.SelectedChecklist = {
        ...checklist.SelectedChecklist,
        [e.target.name]: e.target.checked
      };
      if (e.target.checked && checklist.SelectedChecklist.SelectedTasks.length > 0) {
        setShowServiceDialog(true);
      }
    } else {
      checklist.SelectedChecklist = {
        ...checklist.SelectedChecklist,
        [e.target.name]: e.target.value
      };
    }
    changeChecklist(checklist);
  };

  const onTaskSearch = keyWords => {
    setSearchKeywords(keyWords);
  };

  const onTaskStatusSearch = value => {
    setSearchStatusType(value);
  };

  const onSortOrder = () => {
    setSortOrder(!sortOrder);
  };

  const getSelectedTasks = () => {
    let filteredData = checklist.SelectedChecklist.SelectedTasks.filter(
      task => task.Name.toLowerCase().indexOf(searchKeywords.toLowerCase()) >= 0
    );
    if (searchStatusType !== '' && searchStatusType !== 'ALL') {
      filteredData = filteredData.filter(
        task => task.Status === searchStatusType || (!task.Status && searchStatusType === CHECKLIST_STATUS_ACTIVE)
      );
    }
    if (sortOrder) {
      filteredData = filteredData.sort(compareValues('DueDateTime'));
    } else {
      filteredData = filteredData.sort(compareValues('DueDateTime', 'desc'));
    }
    return filteredData;
  };

  const onTaskRemove = selectedTask => {
    let selectedTasks = [];
    if (selectedTask.TaskId) selectedTasks = checklist.SelectedChecklist.SelectedTasks.filter(e => e.TaskId !== selectedTask.TaskId);
    else selectedTasks = checklist.SelectedChecklist.SelectedTasks.filter(e => e.TaskTemplateId !== selectedTask.TaskTemplateId);

    checklist.SelectedChecklist.SelectedTasks = selectedTasks;
    changeChecklist(checklist);
  };

  const addNewbtnClick = () => {
    let newUrl = `/tasks-and-checklists/task?checklistId=${checklistId}&isServiceChecklist=${checklist.SelectedChecklist.IsServiceChecklist}`;
    if (checklist.SelectedChecklist.UnitId) {
      newUrl += `&unitId=${checklist.SelectedChecklist.UnitId}`;
    }

    newUrl += `&prevPath=${history.location.pathname}`;
    history.push(newUrl);
  };

  const viewBtnClick = url => {
    let newUrl = `${url}?checklistId=${checklistId}${IsView ? '&view=1' : ''}&isServiceChecklist=${
      checklist.SelectedChecklist.IsServiceChecklist
    }`;
    if (checklist.SelectedChecklist.UnitId) {
      newUrl += `&unitId=${checklist.SelectedChecklist.UnitId}`;
    }

    history.push(newUrl);
  };

  const checklistTemplatesOptions = () => {
    let checklistTemplatesData = [];
    checklistTemplate.ChecklistTemplates.forEach(element => {
      checklistTemplatesData.push({
        ...element,
        label: element.Name,
        value: element.ChecklistTemplateId
      });
    });

    let sortedData = checklistTemplatesData.sort(compareValues('label'));
    return [{ value: 0, label: 'None' }, ...sortedData];
  };

  const onDropdownChange = e => {
    setFormError({});
    changeChecklist({
      SelectedChecklist: {
        ...initialChecklistState.checklist.SelectedChecklist
      }
    });
    if (e.value) {
      describeChecklistTemplate(e.value, widgetCode);
    }
  };

  const redirect = () => {
    if (checklist.isOpSuccessful && !checklist.showBanner) {
      changeChecklist({
        SelectedChecklist: {
          ...initialChecklistState.checklist.SelectedChecklist,
          SelectedTasks: []
        }
      });
      if (checklist.MarkAsComplete && checklist.IsServiceChecklist) {
        let redirectUrl = `/maintenance/service/${checklist.SelectedChecklist.ChecklistId}`;
        if (checklist.SelectedChecklist.UnitServiceId) {
          redirectUrl += `/${checklist.SelectedChecklist.UnitServiceId}`;
        }
        return <Redirect to={redirectUrl} />;
      }
      return history.goBack();
    }
  };

  const handleCommand = () => {
    setActive(true);
  };

  const onDueDateChange = newVal => {
    if (newVal && newVal.unix) checklist.SelectedChecklist.DueDateTime = newVal.unix();
    else checklist.SelectedChecklist.DueDateTime = null;

    changeChecklist(checklist);
  };

  const getMoreOptions = () => {
    let options = [];

    if (changeAllowed) {
      options.push({
        Url: `/tasks-and-checklists/checklist-detail/${checklistId}`,
        Name: <FormattedMessage id="checklistDetail.change" defaultMessage="Change" />
      });
    }

    if (deleteAllowed) {
      options.push({
        // Url: `/tasks-and-checklists/checklist-detail/${checklistId}`,
        Name: <FormattedMessage id="checklistDetail.delete" defaultMessage="Delete" />,
        Click: handleCommand
      });
    }

    return options;
  };

  const profileOptions = () => {
    let profileData = [];
    profilesObj.forEach(element => {
      profileData.push({
        ...element,
        label: element.Name,
        value: element.ProfileId
      });
    });
    return profileData.sort(compareValues('label'));
  };

  const userOptions = () => {
    let userData = [];
    users &&
      users.forEach(element => {
        userData.push({
          ...element,
          label: element.FullName,
          value: element.UserId
        });
      });
    return userData.sort(compareValues('label'));
  };

  const onRoleDropdownChange = e => {
    checklist.SelectedChecklist = {
      ...checklist.SelectedChecklist,
      AssignedToProfileId: e.value,
      AssignedToUserId: null
    };

    let userObj = userManager && userManager.users && userManager.users.filter(x => x.selectedProfiles.includes(e.value));
    setUsers(userObj);
  };

  let assignedToProfileId = checklist.SelectedChecklist.AssignedToProfileId;
  useEffect(() => {
    if (IsEdit) {
      let userObj = userManager && userManager.users && userManager.users.filter(x => x.selectedProfiles.includes(assignedToProfileId));
      setUsers(userObj);
    }
  }, [assignedToProfileId]);

  const onAssignToDropdownChange = e => {
    checklist.SelectedChecklist = {
      ...checklist.SelectedChecklist,
      AssignedToUserId: e.value,
      AssignedByUserId: userId
    };
    changeChecklist({
      SelectedChecklist: {
        ...checklist.SelectedChecklist
      }
    });
  };

  const onMarkAsCompleteClick = () => {
    let saveData = checklist.SelectedChecklist;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    saveData.MarkAsComplete = true;
    saveData.MarkAsDeleted = false;
    saveChecklist(saveData, widgetCode);
  };

  const onServiceBtnClick = unitServiceId => {
    if (unitServiceId) history.push(`/maintenance/service-view/${checklist.SelectedChecklist.ChecklistId}/${unitServiceId}`);
    else history.push(`/maintenance/service/${checklist.SelectedChecklist.ChecklistId}`);
  };

  const onDeleteClick = () => {
    let saveData = checklist.SelectedChecklist;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    saveData.MarkAsDeleted = true;
    saveData.MarkAsComplete = false;
    saveChecklist(saveData, 'CHECKLISTDELETE');
  };

  const handleConfirm = () => {
    onDeleteClick();
    handleClose();
  };

  const handleClose = () => setActive(false);

  const handleServiceConfirm = () => {
    setShowServiceDialog(false);
  };

  const handleServiceClose = () => {
    setShowServiceDialog(false);
    checklist.SelectedChecklist = {
      ...checklist.SelectedChecklist,
      IsServiceChecklist: false
    };
    changeChecklist(checklist);
  };

  const handleUnitConfirm = () => {
    setShowUnitDialog(false);
  };

  const handleUnitClose = () => {
    setShowUnitDialog(false);
    checklist.SelectedChecklist = {
      ...checklist.SelectedChecklist,
      UnitId: null
    };
    changeChecklist(checklist);
  };

  const dialogTitle = intl.formatMessage({
    id: 'checklistManagement.confirmDeleteChecklist',
    defaultMessage: 'Are you sure you want to delete the checklist?'
  });

  const getStatusCompletedInPercentage = () => {
    let completed = 0;
    try {
      if (checklist.SelectedChecklist.Status === CHECKLIST_STATUS_COMPLETE) {
        return 100;
      }
      let completedTasks = checklist.SelectedChecklist.SelectedTasks.filter(task => task.Status === CHECKLIST_STATUS_COMPLETE);

      if (checklist.SelectedChecklist.SelectedTasks.length === 0 || completedTasks.length === 0) {
        return completed;
      }

      completed = Math.round((completedTasks.length / checklist.SelectedChecklist.SelectedTasks.length) * 100);
    } catch {}

    return completed;
  };

  const getStatusCompletedMessage = () => {
    let completed = `0/${checklist.SelectedChecklist.SelectedTasks.length} `;
    try {
      let completedTasks = checklist.SelectedChecklist.SelectedTasks.filter(task => task.Status === CHECKLIST_STATUS_COMPLETE);

      if (checklist.SelectedChecklist.SelectedTasks.length === 0 || completedTasks.length === 0) {
        return completed;
      }

      completed = `${completedTasks.length}/${checklist.SelectedChecklist.SelectedTasks.length} `;
    } catch {}

    return completed;
  };

  const unitOptions = () => {
    let unitData = [];
    unitsObj &&
      unitsObj.forEach(element => {
        unitData.push({
          ...element,
          label: element.Name,
          value: element.UnitId
        });
      });
    return unitData.sort(compareValues('label'));
  };

  const onUnitDropdownChange = e => {
    checklist.SelectedChecklist = {
      ...checklist.SelectedChecklist,
      UnitId: e.value
    };
    if (e.value != '') {
      setShowUnitDialog(true);
    }
  };

  return (
    <div className={s.checklistDetail}>
      {(checklist.isLoading || checklistTemplate.isLoading) && <LoadingSpinner />}
      {redirect()}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <ConfirmDialog title={dialogUnitTitle} onConfirm={handleUnitConfirm} onClose={handleUnitClose} showDialog={showUnitDialog} />
      <ConfirmDialog
        title={dialogServiceTitle}
        onConfirm={handleServiceConfirm}
        onClose={handleServiceClose}
        showDialog={showServiceDialog}
      />
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={checklist.showBanner}
        status={checklist.isOpSuccessful}
        warning={checklist.warning}
        successText={messageText}
      />
      <TasksAndChecklistsContentTemplate selectedPage="myChecklists" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.checklistDetailHeader}>
            <GoBack returnUrl={backLink}>
              &lt; &nbsp;
              <FormattedMessage id="checklistDetail.back" defaultMessage="BACK" />
            </GoBack>
            <Row>
              <Col xs={5}>
                <h3 data-unittest="headingLabel">{heading}</h3>
              </Col>
              <Col>
                {IsView && (
                  <Row className={s.colActionButton}>
                    <Fragment>
                      {checklist.SelectedChecklist.Status === CHECKLIST_STATUS_COMPLETE && (
                        <span className={s.completeSection}>
                          <img src={greentick} alt="icon" className={s.greenTick} />
                          <span className={s.checklistCompleteMsg}>
                            <FormattedMessage id="checklistDetail.checklistComplete" defaultMessage="CHECKLIST IS COMPLETE" />
                          </span>
                        </span>
                      )}
                      {(checklist.SelectedChecklist.Status === CHECKLIST_STATUS_COMPLETE ||
                        checklist.SelectedChecklist.UnitServiceId > 0) && (
                        <Button
                          style={WidgetVisibility(userProfileWidgets, `UNIT_SERVICE_ADDNEW`)}
                          variant="primary"
                          data-unittest="saveData"
                          className={`${s.margin5}, ${s.btnSaveChanges}`}
                          noValidate
                          onClick={() => {
                            onServiceBtnClick(checklist.SelectedChecklist.UnitServiceId);
                          }}
                        >
                          {checklist.SelectedChecklist.UnitServiceId ? (
                            <FormattedMessage id="checklistDetail.viewServiceRecord" defaultMessage="View service record" />
                          ) : (
                            <FormattedMessage id="checklistDetail.createServiceRecord" defaultMessage="Create service record" />
                          )}
                        </Button>
                      )}
                    </Fragment>
                    {checklist.SelectedChecklist.Status !== CHECKLIST_STATUS_COMPLETE && (
                      <Button
                        style={WidgetVisibility(userProfileWidgets, `CHECKLISTEDIT`)}
                        variant="primary"
                        data-unittest="saveData"
                        className={classNames(s.margin5, s.btnSaveChanges)}
                        noValidate
                        onClick={onMarkAsCompleteClick}
                        disabled={checklist.SelectedChecklist.Status === CHECKLIST_STATUS_COMPLETE}
                      >
                        <FormattedMessage id="checklistDetail.markAsComplete" defaultMessage="MARK AS COMPLETE" />
                      </Button>
                    )}

                    <MoreOption key={uuidv4()} dataArray={getMoreOptions()} />
                  </Row>
                )}
              </Col>
            </Row>
          </div>
          {IsView && (
            <div className={`${s.checklistDetailContent} d-none d-md-block`}>
              <Form>
                <Row className={s.viewRow}>
                  <Col md={2} lg={2} className={'d-none d-md-block'}>
                    <h4>
                      <FormattedMessage id="checklistDetail.progress" defaultMessage="Progress" />
                    </h4>
                  </Col>
                  <Col className={s.progressBar} md={6} lg={6}>
                    <ProgressBar completed={getStatusCompletedInPercentage()} />
                  </Col>
                  <Col md={4} lg={4}>
                    <div className={s.taskCompleted}>
                      {getStatusCompletedMessage()}
                      <FormattedMessage id="checklistDetail.tasksCompleted" defaultMessage="tasks completed" />{' '}
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
          <div className={s.checklistDetailContent}>
            <h4>
              <FormattedMessage id="checklistDetail.details" defaultMessage="Details" />
            </h4>
            {/* EDIT SCREEN START */}
            {!IsView && (
              <Form className={s.viewRow}>
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group controlId="formGrpChecklistTemplate">
                      <Form.Label>
                        <FormattedMessage
                          id="checklistDetail.selectFromChecklistTemplates"
                          defaultMessage="Select from checklist templates"
                        />
                      </Form.Label>
                      <Dropdown
                        id="formChecklistTemplate"
                        dataArray={checklistTemplatesOptions()}
                        controlData={{ placeholderText: 'None', customClassName: s.ddlTaskTemplate }}
                        onDropdownChange={onDropdownChange}
                        data-unittest="formChecklistTemplate"
                        disabled={IsEdit}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group controlId="formChecklistName">
                      <Form.Label>
                        <FormattedMessage id="checklistDetail.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={checklist.SelectedChecklist.Name}
                        className={`${s.formControl} ${formError && formError.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'checklistDetail.name',
                          defaultMessage: 'Name'
                        })}
                        disabled={isChecklistCompleted}
                      />
                      {formError && formError.Name && (
                        <p role="alert" className={s.error}>
                          {formError.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formChecklistDescription">
                      <Form.Label>
                        <FormattedMessage id="checklistDetail.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.txtArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'checklistDetail.description',
                          defaultMessage: 'Description'
                        })}
                        value={checklist.SelectedChecklist.Description}
                        disabled={isChecklistCompleted}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group controlId="formChecklistDueDate">
                      <Form.Label>
                        <FormattedMessage id="checklistDetail.dueDateTime" defaultMessage="Due date & time" />
                      </Form.Label>

                      <DateTime
                        isValidDate={disablePastDates}
                        onChange={m => onDueDateChange(m)}
                        timeFormat="HH:mm ss"
                        dateFormat="DD-MMM-YYYY"
                        value={
                          checklist.SelectedChecklist.DueDateTime &&
                          moment.unix(checklist.SelectedChecklist.DueDateTime).tz(currentTimezone)
                        }
                        closeOnSelect={true}
                        className={s.dueDateTime}
                        inputProps={{ disabled: isChecklistCompleted }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formServiceChecklist">
                      <Checkbox
                        key={uuidv4()}
                        dataArray={[
                          {
                            SKEY: 'IsServiceChecklist',
                            target: { type: 'checkbox' },
                            label: intl.formatMessage({
                              id: 'checklistDetail.serviceChecklist',
                              defaultMessage: 'Service checklist'
                            }),
                            disable: isChecklistCompleted,
                            isChecked: checklist.SelectedChecklist.IsServiceChecklist
                          }
                        ]}
                        onSelectionChange={onChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formChecklistUnit">
                      <Form.Label>
                        <FormattedMessage id="checklistManagement.unit" defaultMessage="Unit" />
                      </Form.Label>

                      <Dropdown
                        id="formChecklistUnit"
                        dataArray={unitOptions()}
                        controlData={{ placeholderText: 'Select unit' }}
                        onDropdownChange={onUnitDropdownChange}
                        selectedOption={unitOptions().filter(option => option.value === checklist.SelectedChecklist.UnitId)}
                        disabled={isChecklistCompleted}
                        data-unittest="formUnit"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formChecklistRole">
                      <Form.Label>
                        <FormattedMessage id="checklistManagement.role" defaultMessage="Role" />
                      </Form.Label>

                      <Dropdown
                        id="formChecklistRole"
                        dataArray={profileOptions()}
                        controlData={{ placeholderText: 'Select role' }}
                        onDropdownChange={onRoleDropdownChange}
                        selectedOption={profileOptions().filter(option => option.value === checklist.SelectedChecklist.AssignedToProfileId)}
                        data-unittest="formRole"
                        disabled={isChecklistCompleted}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formAssignTo">
                      <Form.Label>
                        <FormattedMessage id="checklistManagement.assignTo" defaultMessage="Assign to" />
                      </Form.Label>

                      <Dropdown
                        id="formAssignTo"
                        dataArray={userOptions()}
                        controlData={{ placeholderText: 'Select user' }}
                        onDropdownChange={onAssignToDropdownChange}
                        selectedOption={userOptions().filter(option => option.value === checklist.SelectedChecklist.AssignedToUserId)}
                        data-unittest="formUser"
                        disabled={isChecklistCompleted}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
            {/* EDIT SCREEN END */}

            {/* VIEW SCREEN START */}
            {IsView && (
              <Form>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="checklistDetail.name" defaultMessage="Name" />
                    </Form.Label>
                  </Col>
                  <Col>{checklist.SelectedChecklist.Name}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Group controlId="formChecklistDescription">
                      <Form.Label>
                        <FormattedMessage id="checklistDetail.description" defaultMessage="Description" />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>{checklist.SelectedChecklist.Description}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Group controlId="formChecklistDueDate">
                      <Form.Label>
                        <FormattedMessage id="checklistDetail.dueDateTime" defaultMessage="Due date & time" />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>
                    {checklist.SelectedChecklist.DueDateTime
                      ? moment
                          .unix(parseInt(checklist.SelectedChecklist.DueDateTime))
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm:ss z')
                      : '-'}
                  </Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Group controlId="formChecklistStatus">
                      <Form.Label>
                        <FormattedMessage id="checklistDetail.status" defaultMessage="Status" />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>
                    {' '}
                    {checklist.SelectedChecklist.Status === CHECKLIST_STATUS_ACTIVE ? (
                      <div className={s.statusActive}></div>
                    ) : (
                      <div className={s.statusComplete}></div>
                    )}
                    {checklist.SelectedChecklist.Status}
                  </Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Group controlId="formServiceChecklist">
                      <Form.Label>
                        <FormattedMessage id="checklistDetail.serviceChecklist" defaultMessage="Service checklist" />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>{checklist.SelectedChecklist.IsServiceChecklist ? 'Yes' : 'No'}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Group controlId="formUnit">
                      <Form.Label>
                        <FormattedMessage id="checklistDetail.unit" defaultMessage="Unit" />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>{checklist.SelectedChecklist.UnitName || '-'}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Group controlId="formAssignedTo">
                      <Form.Label>
                        <FormattedMessage id="checklistDetail.assignedTo" defaultMessage="Assigned to" />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>{checklist.SelectedChecklist.AssignedUsername || '-'}</Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Group controlId="formAssignedBy">
                      <Form.Label>
                        <FormattedMessage id="checklistDetail.assignedBy" defaultMessage="Assigned by" />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>{checklist.SelectedChecklist.AssignedByUsername || '-'}</Col>
                </Row>
              </Form>
            )}
            {/* VIEW SCREEN END */}
          </div>
          <div>
            <ChecklistDetailTask
              onTaskSearch={onTaskSearch}
              onTaskStatusSearch={onTaskStatusSearch}
              onTaskRemove={onTaskRemove}
              addNewBtnClick={addNewbtnClick}
              viewBtnClick={viewBtnClick}
              selectedTasks={getSelectedTasks() || []}
              isView={IsView}
              isEdit={IsEdit}
              isChecklistCompleted={isChecklistCompleted}
              currentTimezone={currentTimezone}
              sortOrder={sortOrder}
              onSortOrder={onSortOrder}
            />
          </div>
          {formError && formError.Task && (
            <p role="alert" className={s.error}>
              {formError.Task}
            </p>
          )}
          <div>
            {!IsView && (
              <Form>
                <Button
                  variant="primary"
                  data-unittest="saveData"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  disabled={isChecklistCompleted}
                >
                  <FormattedMessage id="checklistManagement.saveChanges" defaultMessage="Save Changes" />
                </Button>

                <Link to={backLink}>
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="checklistManagement.cancel" defaultMessage="Cancel" />
                  </Button>
                </Link>
              </Form>
            )}
          </div>
        </div>
      </TasksAndChecklistsContentTemplate>
    </div>
  );
};

ChecklistDetail.defaultProps = {
  checklistTemplate: {
    ...initialChecklistTemplateState.checklistTemplate
  },
  checklist: {
    ...initialChecklistState.checklist
  }
};

ChecklistDetail.propTypes = {
  saveChecklist: PropTypes.func.isRequired,
  checklist: PropTypes.object.isRequired,
  describeChecklist: PropTypes.func.isRequired,
  changeChecklist: PropTypes.func.isRequired
};

export default injectIntl(ChecklistDetail);
