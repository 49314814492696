import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from 'react-bootstrap/Button';
import s from './AddChartButton.module.scss';

const AddChartButton = ({ addChart, count, unitSerialNumber, intl }) => {
  const handleAddChart = () => addChart(unitSerialNumber);
  const intlAddChart = intl.formatMessage({ id: 'chart.addchart', defaultMessage: 'Add new chart' });
  const intlAddAnotherChart = intl.formatMessage({ id: 'chart.addanotherchart', defaultMessage: 'Add another chart' });

  return (
    <Fragment>
      <Button variant="primary" className={s.chartbutton} onClick={handleAddChart}>
        {count > 0 ? intlAddAnotherChart : intlAddChart}
      </Button>
    </Fragment>
  );
};

AddChartButton.propTypes = {
  unitSerialNumber: PropTypes.string.isRequired,
  addChart: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired
};

export default injectIntl(AddChartButton);
