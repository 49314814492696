import React from 'react';
import { getCompPositionLabel } from '../../utils/helperMethods';

const SensorToolTip = sensorData => {
  let { showSensors, sensorName, sensorValue, unitComponentParts, x, y } = sensorData;
  let calcSensorValue = 'N/A';
  let unitOfMeasure = '';
  if (!showSensors) return <svg />;

  let SensorInfo = unitComponentParts && unitComponentParts.filter(e => e.Name.toLowerCase() === sensorName.toLowerCase());

  if (SensorInfo && SensorInfo.length > 0) {
    unitOfMeasure = SensorInfo[0].UoM;
  }

  if (typeof sensorValue !== 'undefined') {
    calcSensorValue = Math.round(sensorValue * 100) / 100 + ' ' + unitOfMeasure;
  }
  let isCCPorOCP = false;
  if (SensorInfo.length > 0) {
    sensorName = getCompPositionLabel(SensorInfo[0]);
    isCCPorOCP = SensorInfo[0].IsCCP || SensorInfo[0].IsOCP || false;
  }

  return (
    <svg x={x} y={y} width="150" height="70" className="clsSensorTooltip" fill={isCCPorOCP ? '#f2aa3e' : '#f3f0f0'}>
      <rect x="0" y="0" width="100%" height="100%" />
      <text x="50%" y="35%" dominantBaseline="middle" textAnchor="middle" fill="black" fontWeight="bold">
        {sensorName}
      </text>
      <text x="50%" y="70%" dominantBaseline="middle" textAnchor="middle" fill="black" fontWeight="bold">
        {calcSensorValue}
      </text>
    </svg>
  );
};

export default SensorToolTip;
