import { connect } from 'react-redux';
import throttle from 'lodash.throttle';

import ChartWrapper from './ChartWrapper';

import { fetchMetricData, updateChartValues, removeChart, updateChartFilterValues } from '../../actions/charts';
import { getCurrentTimezone, getSelectedUnitComponentPositions } from '../../selectors/index';

const updateChartValuesThottled = throttle((dispatch, id, unitSerialNumber, field, value) => {
  if (typeof value === 'number' || field === 'sensors') {
    let newValue = value;
    if ((field === 'lowerThreshold' || field === 'upperThreshold') && isNaN(value)) {
      newValue = '';
    }
    dispatch(updateChartValues(id, unitSerialNumber, field, newValue));
  }
}, 500);

const updateChartFilterValuesThottled = throttle((dispatch, id, unitSerialNumber, data) => {
  dispatch(updateChartFilterValues(id, unitSerialNumber, data));
}, 500);

const mapStateToProps = state => {
  return {
    currentTimezone: getCurrentTimezone(state),
    unitComponentParts: getSelectedUnitComponentPositions(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMetricData: (id, unitSerialNumber, from, to, sensor, limit, resultType) =>
    dispatch(fetchMetricData(id, unitSerialNumber, from, to, sensor, limit, resultType, 'DATATRENDANALYSIS')),
  updateChartValuesThottled: (id, unitSerialNumber, field, value) =>
    updateChartValuesThottled(dispatch, id, unitSerialNumber, field, value),
  updateChartFilterValuesThottled: (id, unitSerialNumber, data) => updateChartFilterValuesThottled(dispatch, id, unitSerialNumber, data),
  removeChart: (id, unitSerialNumber) => dispatch(removeChart(id, unitSerialNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartWrapper);
