import {
  EVENT_ACTION_SET,
  EVENT_ACTION_ERROR,
  EVENT_ACTION_IS_LOADING,
  EVENT_ACTIVITY_IS_SAVING,
  EVENT_ACTIVITY_SAVE,
  EVENT_ACTIVITY_SAVE_ERROR,
  EVENT_ACTIVITY_NEW_SET,
  EVENT_ACTION_SET_FOR_UNIT,
  EVENT_ACTION_UPDATE_CHART,
  EVENT_ACTION_UPDATE_CHART_FILTERS,
  EVENT_ACTION_CHART_IS_LOADING,
  EVENT_ACTION_UPDATE_METRIC_DATA,
  EVENT_ACTION_CHART_ERROR,
  EVENT_ACTION_CLEANUP,
  EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD,
  EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD_SUCCESS
} from '../constants/index';
import { initialEventAction } from './initialState';

const eventActionReducer = (state = initialEventAction, action) => {
  const { payload, type } = action;
  switch (type) {
    case EVENT_ACTION_IS_LOADING: {
      const { isLoading } = payload;

      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          isLoading: isLoading
        }
      };
    }

    case EVENT_ACTION_SET: {
      const { isLoading, eventAction } = payload;

      let eventActionWithChart = eventAction;

      const rangeDateTime = 6 * 60 * 60 * 1000;
      const actionDateTime = eventAction.EventAction.EventDateTime ? parseInt(eventAction.EventAction.EventDateTime) : 0;
      const sensor = [];
      sensor.push(eventAction.EventAction.SensorName.toLowerCase());

      const TrendAnalysisChart = {
        id: 'eventActionChart',
        data: [],
        dateFrom: actionDateTime - rangeDateTime,
        dateTo: actionDateTime + rangeDateTime,
        sensors: sensor,
        lowerThreshold: '',
        upperThreshold: '',
        limit: 200,
        resultType: 3,
        errorMessage: ''
      };

      const telemetryDownloadLinks = eventActionWithChart.TelemetryDownloads
        ? eventActionWithChart.TelemetryDownloads.map(a => a.DownloadLink)
        : [];

      eventActionWithChart.EventAction.TrendAnalysisChart = TrendAnalysisChart;
      eventActionWithChart.TelemetryDownloads = telemetryDownloadLinks;

      return {
        ...state,
        eventAction: {
          isLoading: isLoading,
          ...eventActionWithChart,
          showBanner: false
        }
      };
    }

    case EVENT_ACTION_ERROR: {
      const { eventAction } = payload;

      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          ...eventAction
        }
      };
    }

    case EVENT_ACTIVITY_SAVE: {
      const { isLoading, eventAction } = payload;
      return {
        ...state,
        eventAction: {
          ...eventAction,
          isLoading: isLoading,
          showBanner: false
        }
      };
    }

    case EVENT_ACTIVITY_IS_SAVING: {
      const { isLoading } = payload;

      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          isLoading: isLoading
        }
      };
    }

    case EVENT_ACTIVITY_SAVE_ERROR: {
      const { eventAction } = payload;

      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          ...eventAction
        }
      };
    }

    case EVENT_ACTIVITY_NEW_SET: {
      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          NewActivity: {
            ...payload.NewActivity
          }
        }
      };
    }

    case EVENT_ACTION_SET_FOR_UNIT: {
      const { isLoading, eventActions } = payload;

      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          isLoading: isLoading,
          UnitEventActions: eventActions,
          showBanner: false
        }
      };
    }

    case EVENT_ACTION_UPDATE_CHART: {
      const { field, value } = payload;
      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          EventAction: {
            ...state.eventAction.EventAction,
            TrendAnalysisChart: {
              ...state.eventAction.EventAction.TrendAnalysisChart,
              [field]: value,
              errorMessage: ''
            }
          }
        }
      };
    }
    case EVENT_ACTION_UPDATE_CHART_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          EventAction: {
            ...state.eventAction.EventAction,
            TrendAnalysisChart: {
              ...state.eventAction.EventAction.TrendAnalysisChart,
              ...data,
              isLoading: false,
              errorMessage: ''
            }
          }
        }
      };
    }
    case EVENT_ACTION_UPDATE_METRIC_DATA: {
      const {
        data: { results }
      } = payload;
      let showData = results.some(r => Object.keys(r).length > 1); // Not just the timestamp
      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          EventAction: {
            ...state.eventAction.EventAction,
            TrendAnalysisChart: {
              ...state.eventAction.EventAction.TrendAnalysisChart,
              data: showData ? results : [],
              isLoading: false,
              errorMessage: ''
            }
          }
        }
      };
    }
    case EVENT_ACTION_CHART_IS_LOADING: {
      const { status } = payload;
      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          EventAction: {
            ...state.eventAction.EventAction,
            TrendAnalysisChart: {
              ...state.eventAction.EventAction.TrendAnalysisChart,
              isLoading: status
            }
          }
        }
      };
    }

    case EVENT_ACTION_CHART_ERROR: {
      const { err } = payload;
      let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
      messageCode = messageCode && messageCode.replace('[400]=>', '');
      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          EventAction: {
            ...state.eventAction.EventAction,
            TrendAnalysisChart: {
              ...state.eventAction.EventAction.TrendAnalysisChart,
              data: [],
              errorMessage: messageCode,
              isLoading: false
            }
          }
        }
      };
    }

    case EVENT_ACTION_CLEANUP: {
      const { eventAction } = payload;
      return {
        ...state,
        eventAction: eventAction
      };
    }

    case EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD: {
      const downloadUrl = payload;

      const downloadUrls = state.eventAction.TelemetryDownloads;
      if (downloadUrl.length > 0) downloadUrls.push(downloadUrl);

      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          TelemetryDownloads: downloadUrls
        }
      };
    }

    case EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD_SUCCESS: {
      const { eventAction } = payload;

      return {
        ...state,
        eventAction: {
          ...state.eventAction,
          ...eventAction
        }
      };
    }

    default:
      return state;
  }
};

export default eventActionReducer;
