import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelVersion from './ModelVersionConfiguration';
import { getModelVersionManager, getUserProfileWidget } from '../../selectors/index';
import { describeModelVersionThunk, getModelComponentPositionsThunk } from '../../actions/modelVersion';

const mapStateToProps = state => {
  return {
    modelVersionManager: getModelVersionManager(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeModelVersion: (modelVersionId, isDuplicate) =>
        describeModelVersionThunk(modelVersionId, isDuplicate, 'MODELVERSIONCONFIGURE'),
      getModelComponentPositions: (ModelId, onlyVirtual, telemetryComponent) =>
        getModelComponentPositionsThunk(ModelId, onlyVirtual, telemetryComponent, 'MODELVERSIONCONFIGURE')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelVersion);
