//Organisation Management
export const ORGANISATION_MANAGEMENT = 'ORGANISATION_MANAGEMENT';
export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';

//User Management
export const USER_MANAGEMENT = 'USER_MANAGEMENT';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const UPDATE_USER_PASSWORD_FIRST_LOGIN = 'UPDATE_USER_PASSWORD_FIRST_LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_CONFIRMATION = 'FORGOT_PASSWORD_CONFIRMATION';
export const USER_MFA = 'USER_MFA';

//Profile Management
export const PROFILE_MANAGEMENT = 'PROFILE_MANAGEMENT';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

//Device Management
export const DEVICE_MANAGEMENT = 'DEVICE_MANAGEMENT';
export const SEND_DEVICE_COMMAND = 'SEND_DEVICE_COMMAND';
export const UPDATE_DEVICE_SHADOW = 'UPDATE_DEVICE_SHADOW';

//Widget Management
export const WIDGET_MANAGEMENT = 'WIDGET_MANAGEMENT';
export const CREATE_WIDGET = 'CREATE_WIDGET';

//Unit Management
export const UNIT_MANAGEMENT = 'UNIT_MANAGEMENT';
export const UNITS_REPORT = 'UNITS_REPORT';
export const UPLOAD_UNIT = 'UPLOAD_UNIT';
export const UNIT_TAG_OUT = 'UNIT_TAG_OUT';
export const UNIT_TAG_IN = 'UNIT_TAG_IN';
export const UNIT_DETAILS_UPDATE = 'UNIT_DETAILS_UPDATE';
export const DAILY_SUMMARY_REPORT = 'DAILY_SUMMARY_REPORT';
export const UNIT_SETTINGS_UPDATE = 'UNIT_SETTINGS_UPDATE';

//Model Management
export const MODEL_MANAGEMENT = 'MODEL_MANAGEMENT';
export const UPDATE_MODEL = 'UPDATE_MODEL';
export const CREATE_MODEL = 'CREATE_MODEL';
export const CREATE_MODEL_VERSION = 'CREATE_MODEL_VERSION';
export const UPDATE_MODEL_VERSION = 'UPDATE_MODEL_VERSION';
export const CREATE_MODEL_VERSION_THRESHOLD = 'CREATE_MODEL_VERSION_THRESHOLD';
export const UPDATE_MODEL_VERSION_THRESHOLD = 'UPDATE_MODEL_VERSION_THRESHOLD';
export const DELETE_MODEL_VERSION_THRESHOLD = 'DELETE_MODEL_VERSION_THRESHOLD';
export const ADD_COMPONENT_POSITION_MODEL = 'ADD_COMPONENT_POSITION_MODEL';
export const DELETE_COMPONENT_POSITION_MODEL = 'DELETE_COMPONENT_POSITION_MODEL';

export const CREATE_MODEL_VERSION_GLOBAL_SETTING = 'CREATE_MODEL_VERSION_GLOBAL_SETTING';
export const UPDATE_MODEL_VERSION_GLOBAL_SETTING = 'UPDATE_MODEL_VERSION_GLOBAL_SETTING';
export const DELETE_MODEL_VERSION_GLOBAL_SETTING = 'DELETE_MODEL_VERSION_GLOBAL_SETTING';

export const UPDATE_MODEL_VERSION_UNITS = 'UPDATE_MODEL_VERSION_UNITS';

export const UPDATE_MODEL_VERSION_UNIT_SHADOW = ' UPDATE_MODEL_VERSION_UNIT_SHADOW';
export const UPLOAD_COMPONENT_TO_UNIT = ' UPLOAD_COMPONENT_TO_UNIT';

//Event Action
export const EVENT_ACTION_MANAGEMENT = 'EVENT_ACTION_MANAGEMENT';
export const EVENT_ACTION_ACTIVITY_UPDATE = 'EVENT_ACTION_UPDATE';

// Calibration Equation
export const CALIBRATION_EQUATION_MANAGEMENT = 'CALIBRATION_EQUATION_MANAGEMENT';
export const CREATE_CALIBRATION_EQUATION = 'CREATE_CALIBRATION_EQUATION';
export const UPDATE_CALIBRATION_EQUATION = 'UPDATE_CALIBRATION_EQUATION';

// Task Template
export const TASK_TEMPLATE_MANAGEMENT = 'TASK_TEMPLATE_MANAGEMENT';
export const CREATE_TASK_TEMPLATE = 'CREATE_TASK_TEMPLATE';
export const UPDATE_TASK_TEMPLATE = 'UPDATE_TASK_TEMPLATE';
export const TASKS_MANAGEMENT = 'TASKS_MANAGEMENT';
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const CREATE_TASK_COMMENT = 'CREATE_TASK_COMMENT';

// Checklist Template
export const CHECKLIST_TEMPLATE_MANAGEMENT = 'CHECKLIST_TEMPLATE_MANAGEMENT';
export const CREATE_CHECKLIST_TEMPLATE = 'CREATE_CHECKLIST_TEMPLATE';
export const UPDATE_CHECKLIST_TEMPLATE = 'UPDATE_CHECKLIST_TEMPLATE';

// Checklist
export const CHECKLIST_MANAGEMENT = 'CHECKLIST_MANAGEMENT';
export const CREATE_CHECKLIST = 'CREATE_CHECKLIST';
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';

// Calibrations Template
export const CALIBRATION_MANAGEMENT = 'CALIBRATION_MANAGEMENT';
export const CREATE_CALIBRATION = 'CREATE_CALIBRATION';
export const UPDATE_CALIBRATION = 'UPDATE_CALIBRATION';
export const DELETE_CALIBRATION = 'DELETE_CALIBRATION';

export const CREATE_CALIBRATION_COMMENT = 'UPDATE_CALIBRATION_COMMENT';

export const UPDATE_UNIT_SHADOW = 'UPDATE_UNIT_SHADOW';

//Event Code

export const EVENTCODE_MANAGEMENT = 'EVENTCODE_MANAGEMENT';
export const CREATE_EVENTCODE = 'CREATE_EVENTCODE';
export const UPDATE_EVENTCODE = 'UPDATE_EVENTCODE';
export const DELETE_EVENTCODE = 'DELETE_EVENTCODE';

//General Global settings
export const GENERALGLOBALSETTINGS_MANAGEMENT = 'GENERALGLOBALSETTINGS_MANAGEMENT';
export const CREATE_GENERALGLOBALSETTINGS = 'CREATE_GENERALGLOBALSETTINGS';
export const UPDATE_GENERALGLOBALSETTINGS = 'UPDATE_GENERALGLOBALSETTINGS';
export const DELETE_GENERALGLOBALSETTINGS = 'DELETE_GENERALGLOBALSETTINGS';

//Model Component Type
export const MODELCOMPONENTTYPE_MANAGEMENT = 'MODELCOMPONENTTYPE_MANAGEMENT';
export const CREATE_MODELCOMPONENTTYPE = 'CREATE_MODELCOMPONENTTYPE';
export const UPDATE_MODELCOMPONENTTYPE = 'UPDATE_MODELCOMPONENTTYPE';
export const DELETE_MODELCOMPONENTTYPE = 'DELETE_MODELCOMPONENTTYPE';

//Component Parts
export const COMPONENT_PARTS_MANAGEMENT = 'COMPONENT_PARTS_MANAGEMENT';
export const CREATE_COMPONENT_PART = 'CREATE_COMPONENT_PART';
export const UPDATE_COMPONENT_PART = 'UPDATE_COMPONENT_PART';

//Approved Components
export const APPROVEDCOMPONENT_MANAGEMENT = 'APPROVEDCOMPONENT_MANAGEMENT';
export const CREATE_APPROVEDCOMPONENT = 'CREATE_APPROVEDCOMPONENT';
export const UPDATE_APPROVEDCOMPONENT = 'UPDATE_APPROVEDCOMPONENT';
export const DELETE_APPROVEDCOMPONENT = 'DELETE_APPROVEDCOMPONENT';

//Unit Configuration Component Parts
export const UNIT_CONFIGURATION_COMPONENT_PARTS_MANAGEMENT = 'UNIT_CONFIGURATION_COMPONENT_PARTS_MANAGEMENT';
export const CREATE_UNIT_COMPONENT_PART = 'CREATE_UNIT_COMPONENT_PART';
export const REPLACE_UNIT_COMPONENT_PART = 'REPLACE_UNIT_COMPONENT_PART';
//Model Component Positions
export const MODELCOMPONENTPOSITION_MANAGEMENT = 'MODELCOMPONENTPOSITION_MANAGEMENT';
export const CREATE_MODELCOMPONENTPOSITION = 'CREATE_MODELCOMPONENTPOSITION';
export const UPDATE_MODELCOMPONENTPOSITION = 'UPDATE_MODELCOMPONENTPOSITION';
export const DELETE_MODELCOMPONENTPOSITION = 'DELETE_MODELCOMPONENTPOSITION';

//Telemetry Links
//Component Parts
export const TELEMETRY_LINKS_MANAGEMENT = 'TELEMETRY_LINKS_MANAGEMENT';
export const CREATE_TELEMETRY_LINK = 'CREATE_TELEMETRY_LINK';
export const UPDATE_TELEMETRY_LINK = 'UPDATE_TELEMETRY_LINK';

// CommandSchedule
export const SCHEDULE_MANAGEMENT = 'SCHEDULE_MANAGEMENT';
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const END_SCHEDULE = 'END_SCHEDULE';

//Unit Services
export const UNITSERVICE_MANAGEMENT = 'UNITSERVICE_MANAGEMENT';
export const CREATE_UNITSERVICE = 'CREATE_UNITSERVICE';
export const UPDATE_UNITSERVICE = 'UPDATE_UNITSERVICE';

//Unit Statistics
export const UNITSTATISTICS_MANAGEMENT = 'UNITSTATISTICS_MANAGEMENT';
export const SAVE_UNITSTATISTICS = 'SAVE_UNITSTATISTICS';

//Notifications
export const NOTIFICATION_MANAGEMENT = 'NOTIFICATION_MANAGEMENT';
export const UPDATE_NOTIFICATION_USERS = 'UPDATE_NOTIFICATION_USERS';

//FAQ Categories
export const FAQ_CATEGORY_MANAGEMENT = 'FAQ_CATEGORY_MANAGEMENT';
export const CREATE_FAQ_CATEGORY = 'CREATE_FAQ_CATEGORY';
export const UPDATE_FAQ_CATEGORY = 'UPDATE_FAQ_CATEGORY';

//FAQs
export const FAQ_MANAGEMENT = 'FAQ_MANAGEMENT';
export const CREATE_FAQ = 'CREATE_FAQ';
export const UPDATE_FAQ = 'UPDATE_FAQ';

//Organisation Regions
export const ORGANISATION_REGION_MANAGEMENT = 'ORGANISATION_REGION_MANAGEMENT';
export const CREATE_ORGANISATION_REGION = 'CREATE_ORGANISATION_REGION';
export const UPDATE_ORGANISATION_REGION = 'UPDATE_ORGANISATION_REGION';

// Model version service plan
export const DELETE_MODEL_VERSION_SERVICE_PLAN = 'DELETE_MODEL_VERSION_SERVICE_PLAN';
export const CREATE_MODEL_VERSION_SERVICE_PLAN = 'CREATE_MODEL_VERSION_SERVICE_PLAN';
export const UPDATE_MODEL_VERSION_SERVICE_PLAN = 'UPDATE_MODEL_VERSION_SERVICE_PLAN';

//Model Firmware Version

export const MODEL_FIRMWARE_VERSION_MANAGEMENT = 'MODEL_FIRMWARE_VERSION_MANAGEMENT';
export const UPDATE_MODEL_FIRMWARE_VERSION = 'UPDATE_MODEL_FIRMWARE_VERSION';
export const CREATE_MODEL_FIRMWARE_VERSION = 'CREATE_MODEL_FIRMWARE_VERSION';
export const PUBLISH_MODEL_FIRMWARE_VERSION = 'PUBLISH_MODEL_FIRMWARE_VERSION';

//Release Notes
export const RELEASENOTES_MANAGEMENT = 'RELEASENOTES_MANAGEMENT';
export const CREATE_RELEASENOTES = 'CREATE_RELEASENOTES';
export const UPDATE_RELEASENOTES = 'UPDATE_RELEASENOTES';

//Unit global settings
export const UNITGLOBALSETTINGS_MANAGEMENT = 'UNITGLOBALSETTINGS_MANAGEMENT';
export const CREATE_UNITGLOBALSETTINGS = 'CREATE_UNITGLOBALSETTINGS';
export const UPDATE_UNITGLOBALSETTINGS = 'UPDATE_UNITGLOBALSETTINGS';
export const DELETE_UNITGLOBALSETTINGS = 'DELETE_UNITGLOBALSETTINGS';

//Unit add
export const ADD_UNIT = 'ADD_UNIT';

//Sequence versions
export const SEQUENCEVERSION_MANAGEMENT = 'SEQUENCEVERSION_MANAGEMENT';
export const UPDATE_SEQUENCEVERSION = 'UPDATE_SEQUENCEVERSION';
export const CREATE_SEQUENCEVERSION = 'CREATE_SEQUENCEVERSION';
export const ADD_SEQUENCEVERSION_FILE = 'ADD_SEQUENCEVERSION_FILE';
export const UPDATE_SEQUENCEVERSION_FILE = 'UPDATE_SEQUENCEVERSION_FILE';
export const DELETE_SEQUENCEVERSION_FILE = 'DELETE_SEQUENCEVERSION_FILE';
export const PUBLISH_SEQUENCEVERSION = 'PUBLISH_SEQUENCEVERSION';
export const DUPLICATE_SEQUENCEVERSION = 'DUPLICATE_SEQUENCEVERSION';

//Static data
export const STATICDATA_MANAGEMENT = 'STATICDATA_MANAGEMENT';
export const UPDATE_STATICDATA = 'UPDATE_STATICDATA';
export const CREATE_STATICDATA = 'CREATE_STATICDATA';
export const ADD_STATICDATA_FILE = 'ADD_STATICDATA_FILE';
export const UPDATE_STATICDATA_FILE = 'UPDATE_STATICDATA_FILE';
export const DELETE_STATICDATA_FILE = 'DELETE_STATICDATA_FILE';
export const PUBLISH_STATICDATA = 'PUBLISH_STATICDATA';
export const DUPLICATE_STATICDATA = 'DUPLICATE_STATICDATA';
