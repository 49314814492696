import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelComponent from './ModelComponent';
import { getUserProfileWidget } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModelComponent);
