import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './PartDetails.module.scss';
import PartTelemetryHistoryListTable from './PartTelemetryHistoryListTable';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TELEMETRY_TYPES } from '../../constants/index';

const PartTelemetryHistoryList = ({ intl, telemetryHistoryData }) => {
  let initialLimit = 6;
  let initialRecords = telemetryHistoryData && telemetryHistoryData.slice(0, initialLimit);

  const SEARCH_FILTER_EVENT_TYPE = 'SEARCH_FILTER_EVENT_TYPE';
  const SEARCH_FILTER_TELEMETRY_TYPE = 'SEARCH_FILTER_TELEMETRY_TYPE';

  const [localData, setLocalData] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dateAsendingOrder, setDateAsendingOrder] = useState(false);
  const [searchfilterType, setSearchFilterType] = useState(SEARCH_FILTER_EVENT_TYPE);

  const localDataLength = localData.length;
  const telemetryDataLength = telemetryHistoryData && telemetryHistoryData.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalData(telemetryHistoryData);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = telemetryHistoryData.filter(item => {
      if (searchfilterType === SEARCH_FILTER_EVENT_TYPE) return item.EventType.toLowerCase().includes(value.toLowerCase());
      else return TELEMETRY_TYPES[item.TelemetryType].name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalData(filteredList);
  };

  const filterData = () => {
    let filteredList = telemetryHistoryData;
    if (isFilter) {
      filteredList = telemetryHistoryData.filter(item => {
        if (searchfilterType === SEARCH_FILTER_EVENT_TYPE) return item.EventType.toLowerCase().includes(searchText.toLowerCase());
        else return TELEMETRY_TYPES[item.TelemetryType].name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const onDateSortOrder = () => {
    setDateAsendingOrder(!dateAsendingOrder);
  };

  const typeOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="componentParts.eventType" defaultMessage="Event Type" />,
      value: SEARCH_FILTER_EVENT_TYPE
    });

    statusData.push({
      label: <FormattedMessage id="componentParts.telemetryType" defaultMessage="Telemetry Type" />,
      value: SEARCH_FILTER_TELEMETRY_TYPE
    });

    return statusData;
  };

  const onTypeChange = e => {
    setSearchFilterType(e.value);
  };

  let typePlaceHolder = intl.formatMessage({ id: 'componentParts.eventType', defaultMessage: 'Event Type' });

  const getTelemetryData = () => {
    let telemetryData = [];
    if (showAll) {
      telemetryData = filterData();
    } else {
      if (isFilter) {
        telemetryData = localDataLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        telemetryData = initialRecords;
      }
    }
    if (dateAsendingOrder) {
      telemetryData = telemetryData.sort(compareValues('CreatedDateTime'));
    } else {
      telemetryData = telemetryData.sort(compareValues('CreatedDateTime', 'desc'));
    }

    return telemetryData;
  };

  let showAllText = intl.formatMessage({ id: 'componentParts.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'componentParts.showLess', defaultMessage: 'SHOW LESS' });

  return (
    <div className={s.historyData}>
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={12} lg={8} xl={6}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              placeHolderTextId={
                searchfilterType === SEARCH_FILTER_EVENT_TYPE ? 'componentParts.eventType' : 'componentParts.telemetryType'
              }
              data-unittest="searchTelemetryData"
              id="txtSearchBar"
              showFilterType
              searchfilterType={searchfilterType}
              onFilterTypeChange={onTypeChange}
              filterTypeData={typeOptions()}
              filterPlaceholderText={typePlaceHolder}
            />
          </Col>
          <Col sm={0} lg={2}></Col>
        </Row>

        <div>
          <PartTelemetryHistoryListTable
            telemetryHistoryData={getTelemetryData()}
            onDateSortOrder={onDateSortOrder}
            dateAsendingOrder={dateAsendingOrder}
          />
        </div>
        {((!isFilter && telemetryDataLength > initialLimit) || (isFilter && localDataLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? telemetryDataLength : localDataLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

PartTelemetryHistoryList.defaultProps = {
  telemetryHistoryData: []
};

PartTelemetryHistoryList.propTypes = {
  telemetryHistoryData: PropTypes.array.isRequired
};

export default injectIntl(PartTelemetryHistoryList);
