import {
  GET_ALL_TELEMETRY_LINKS,
  TELEMETRY_LINKS_IS_LOADING,
  TELEMETRY_LINKS_MANAGEMENT_ERROR,
  UPDATE_TELEMETRY_LINKS_SAVE_STATUS,
  SET_TELEMETRY_LINKS_CHANGE,
  SET_TELEMETRY_LINK
} from '../constants/index';
import { initialModelTelemetryLinkState } from './initialState';

const telemetryLinks = (state = initialModelTelemetryLinkState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_TELEMETRY_LINKS: {
      const { data } = payload;
      return {
        ...state,
        TelemetryLink: {
          ...state.TelemetryLink,
          ...data
        }
      };
    }

    case TELEMETRY_LINKS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        TelemetryLink: {
          ...state.TelemetryLink,
          isLoading: isLoading
        }
      };
    }

    case TELEMETRY_LINKS_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        TelemetryLink: {
          ...state.TelemetryLink,
          ...data
        }
      };
    }

    case UPDATE_TELEMETRY_LINKS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        TelemetryLink: {
          ...state.TelemetryLink,
          ...data
        }
      };
    }

    case SET_TELEMETRY_LINK: {
      let { selectedTelemetryLink } = payload;

      return {
        ...state,
        TelemetryLink: {
          ...state.TelemetryLink,
          selectedTelemetryLink: {
            ...state.TelemetryLink.selectedTelemetryLink,
            ...selectedTelemetryLink
          }
        }
      };
    }

    case SET_TELEMETRY_LINKS_CHANGE: {
      let { selectedTelemetryLink } = payload;

      return {
        ...state,
        TelemetryLink: {
          ...state.TelemetryLink,
          selectedTelemetryLink: {
            ...state.TelemetryLink.selectedTelemetryLink,
            ...selectedTelemetryLink
          }
        }
      };
    }

    default:
      return state;
  }
};

export default telemetryLinks;
