import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { getIsUserAuthenticated } from '../selectors/index';
import Layout from '../components/Layout/LayoutContainer';
import { logout } from '../actions/user';

import { checkAndRedirect } from '../utils/';

export default Component => {
  class Auth extends React.Component {
    componentDidMount() {
      const { isAuthenticated, redirect, history, match } = this.props;
      checkAndRedirect(isAuthenticated, redirect);

      // If the user navigates directly to an auth page we want to load the devices
      if (history.action === 'POP') {
        const {
          params: { deviceId }
        } = match;

        // if (deviceId) setSelectedUnit(deviceId);
      }
    }

    componentDidUpdate() {
      const { isAuthenticated, redirect } = this.props;
      checkAndRedirect(isAuthenticated, redirect);
    }

    render() {
      const { isAuthenticated, logout, location } = this.props;
      return isAuthenticated ? (
        <Layout isAuthenticated={isAuthenticated} logout={logout} location={location}>
          <Component {...this.props} />
        </Layout>
      ) : null;
    }
  }

  const mapStateToProps = state => {
    return {
      isAuthenticated: getIsUserAuthenticated()
    };
  };

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        redirect: () => push('/login'),
        logout: () => logout()
      },
      dispatch
    );

  Auth.defaultProps = {
    history: {
      action: ''
    }
  };

  Auth.propTypes = {
    isAuthenticated: PropTypes.bool,
    redirect: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    history: PropTypes.object
  };

  return connect(mapStateToProps, mapDispatchToProps)(Auth);
};
