import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import s from './EventPagination.module.scss';

class EventPagination extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentPage: null,
      pageCount: null
    };
  }

  updateCurrentPage = (dataLength, pageSize, startingPage) => {
    let pageCount = parseInt(dataLength / pageSize);
    if (dataLength % pageSize > 0) {
      pageCount++;
    }
    this.setState({
      currentPage: startingPage,
      pageCount: pageCount
    });
  };

  componentDidMount() {
    const { data, pageSize, startingPage } = this.props;
    this.updateCurrentPage(data.length, pageSize, startingPage);
  }

  componentDidUpdate(prevProps) {
    const { data, pageSize, startingPage } = this.props;
    const { data: prevData } = prevProps;

    if (data.length !== prevData.length) {
      this.updateCurrentPage(data.length, pageSize, startingPage);
    }
  }

  setCurrentPage = page => this.setState({ currentPage: page });

  generateLink = (targetPage, disabled = false, text = null) => {
    const { pageCount, currentPage } = this.state;
    if (!text && (targetPage <= 0 || targetPage > pageCount)) return null;
    return (
      <button
        disabled={disabled}
        className={classNames({ [s.active]: targetPage === currentPage, [s.numbers]: !text })}
        onClick={() => this.setCurrentPage(targetPage)}
      >
        {text || targetPage}
      </button>
    );
  };

  paginationControls() {
    const { currentPage, pageCount } = this.state;

    return (
      <div className={s.controls}>
        {this.generateLink(currentPage - 1, currentPage === 1, 'Prev')}
        <div className={s.pageLinks}>
          {this.generateLink(currentPage - 2)}
          {this.generateLink(currentPage - 1)}
          {this.generateLink(currentPage)}
          {this.generateLink(currentPage + 1)}
          {this.generateLink(currentPage + 2)}
        </div>
        {this.generateLink(currentPage + 1, currentPage === pageCount, 'Next')}
      </div>
    );
  }

  paginatedData() {
    const { data, pageSize } = this.props;
    const { currentPage } = this.state;
    const upperLimit = currentPage * pageSize;
    return data.slice(upperLimit - pageSize, upperLimit);
  }

  render() {
    const { data } = this.props;

    return (
      <div className={s.pagination}>
        {React.cloneElement(this.props.children, { events: this.paginatedData() })}
        {data.length > 0 && this.paginationControls()}
      </div>
    );
  }
}

EventPagination.propTypes = {
  data: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  startingPage: PropTypes.number.isRequired
};

EventPagination.defaultProps = {
  pageSize: 20,
  startingPage: 1
};

export default EventPagination;
