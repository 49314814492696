import React from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import wateranimation from '../../assets/water-animation.gif';
import s from './AppLoading.module.scss';

const AppLoading = () => <Apploader />;
export default AppLoading;

const Apploader = () => {
  return (
    <div className={s.loadingScreen}>
      <img src={wateranimation} alt="Loading please wait" height="50%" />
    </div>
  );
};
