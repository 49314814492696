import React, { Fragment, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ModelComponentType.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { isRequired } from '../../utils';
import uuidv4 from 'uuid/v4';

const ModelSubComponentTypeListTable = ({ intl, onModelSubComponentTypeAddOrEdit, modelSubComponentTypes }) => {
  const [selectedModelSubComponentType, setSelectedModelSubComponentType] = useState({});
  const [enableAddorEditModelSubComponentType, setEnableAddorEditModelSubComponentType] = useState(false);
  const [localformErrors, setFormErrors] = useState({});

  const submitData = e => {
    if (!validateInput()) {
      return;
    }
    let updatedTypes = modelSubComponentTypes;
    if (selectedModelSubComponentType.GuidIdentifier) {
      updatedTypes = modelSubComponentTypes.filter(item => {
        if (item.GuidIdentifier == selectedModelSubComponentType.GuidIdentifier) {
          item.Name = selectedModelSubComponentType.Name;
        }
        return item;
      });
    } else {
      selectedModelSubComponentType.ModelSubComponentTypeId = 0;
      selectedModelSubComponentType.GuidIdentifier = uuidv4();
      updatedTypes.push(selectedModelSubComponentType);
    }

    onModelSubComponentTypeAddOrEdit(updatedTypes);
    setEnableAddorEditModelSubComponentType(false);
    setSelectedModelSubComponentType({});
    setFormErrors({});
  };

  const onChange = e => {
    setSelectedModelSubComponentType({
      ...selectedModelSubComponentType,
      [e.target.name]: e.target.value
    });
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!selectedModelSubComponentType || !selectedModelSubComponentType.Name || isRequired(selectedModelSubComponentType.Name, 1)) {
      formErrors.TypeName = intl.formatMessage({
        id: 'modelComponentTypes.typeRequired',
        defaultMessage: 'Type Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onCancelClick = () => {
    setEnableAddorEditModelSubComponentType(false);
    setSelectedModelSubComponentType({});
    setFormErrors({});
  };

  const onAddNewBtnClick = () => {
    setEnableAddorEditModelSubComponentType(true);
    setSelectedModelSubComponentType({});
    setFormErrors({});
  };

  useLayoutEffect(() => {
    if (enableAddorEditModelSubComponentType) {
      const scrollPos = window.scrollY + window.innerHeight;
      window.scrollTo(0, scrollPos);
    }
  }, [enableAddorEditModelSubComponentType]);

  const onSubmitBtnClick = e => {
    submitData(e);
  };

  const onEditClick = item => {
    setSelectedModelSubComponentType(item);
    setEnableAddorEditModelSubComponentType(true);
  };

  return (
    <div className={s.modelComponentType}>
      <div className={s.subComponentContainer}>
        <div className={s.subHeading}>
          <h4>
            <FormattedMessage id="modelComponentTypes.sububComponentTypes" defaultMessage="Subcomponent Types" />
          </h4>
          <span className={s.circle}>{modelSubComponentTypes.length}</span>
        </div>
        <div>
          <Row>
            <Col xs={12} className={s.colAddButton}>
              <Button
                disabled={enableAddorEditModelSubComponentType}
                variant="primary"
                data-unittest="addnew"
                className={s.addButton}
                onClick={() => onAddNewBtnClick()}
              >
                +<FormattedMessage id="modelComponentTypes.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>
        </div>
        <div>
          <Table variant className={s.innerTable}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="modelComponentTypes.subComponentType" defaultMessage="Subcomponent Type" />
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {modelSubComponentTypes.length === 0 && !enableAddorEditModelSubComponentType && (
                <tr className={s.emptyRow}>
                  <td colSpan="4">
                    <FormattedMessage id="modelComponentTypes.noData" defaultMessage="No Data" />
                  </td>
                </tr>
              )}
              {modelSubComponentTypes.map((n, index) => {
                return (
                  <Fragment key={index}>
                    <tr className={s.modelSubComponentTypeRow} data-unittest="modeComponentTypeData">
                      <td>{n.Name}</td>
                      <td className={s.colBtn}>
                        <Button
                          disabled={enableAddorEditModelSubComponentType}
                          variant="outline-secondary"
                          onClick={() => {
                            onEditClick(n);
                          }}
                          className={s.modelSubComponentTypeViewButton}
                        >
                          <FormattedMessage id="modelComponentTypes.Edit" defaultMessage="Edit" />
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
              {enableAddorEditModelSubComponentType && (
                <Fragment>
                  <tr data-unittest="templateData">
                    <td colSpan="4" className={s.modelSubComponentTypeRow}>
                      <Row>
                        <Col xs={12} md={6}>
                          <Form.Group controlId="formModelSubComponentType">
                            <Form.Label>
                              <FormattedMessage id="modelComponentTypes.subComponentType" defaultMessage="Subcomponent Type" />
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="Name"
                              onChange={onChange}
                              value={selectedModelSubComponentType.Name ?? ''}
                              className={`${s.formControl} ${localformErrors && localformErrors.TypeName ? s.formControlError : ''}`}
                              placeholder={intl.formatMessage({
                                id: 'modelComponentTypes.subComponentType',
                                defaultMessage: 'Subcomponent Type'
                              })}
                            />
                            {localformErrors && localformErrors.TypeName && (
                              <p role="alert" className={s.error}>
                                {localformErrors.TypeName}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            variant="primary"
                            data-unittest="view"
                            onClick={() => onSubmitBtnClick()}
                            className={s.modelSubComponentTypeButton}
                          >
                            <FormattedMessage id="modelComponentTypes.submit" defaultMessage="Submit" />
                          </Button>

                          <Button
                            variant="outline-secondary"
                            data-unittest="cancel"
                            onClick={() => onCancelClick()}
                            className={s.modelSubComponentTypeButton}
                          >
                            <FormattedMessage id="modelComponentTypes.cancel" defaultMessage="Cancel" />
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </Fragment>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

ModelSubComponentTypeListTable.defaultProps = {
  modelSubComponentTypes: []
};

ModelSubComponentTypeListTable.propTypes = {
  onModelSubComponentTypeAddOrEdit: PropTypes.func.isRequired
};

export default injectIntl(ModelSubComponentTypeListTable);
