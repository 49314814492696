import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedUserProfileWidgets } from '../actions/UserProfileWidgets';
import Cookies from 'js-cookie';
import { IsWidgetAccessible } from '../utils/widgetManager';
import NoRoute from '../components/NoRoute/NoRoute';
import UnAuth401 from '../components/Unauthorised/Unauthorised';
import AppLoading from '../components/AppLoading/AppLoading';
import Unauth from '../hocs/unauthorized';
import { getIsUserAuthenticated } from '../selectors/index';

const CustomRoute = props => {
  const dispatch = useDispatch();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const selectedProfileid = Cookies.get(`selectedprofileid-${envName}`) || 0;
  const userName = Cookies.get(`username-${envName}`) || '';
  const userProfileWidgetsPresent = useSelector(state => state.userProfileWidgets.isProfileWidgetsPresent);
  const isLoading = useSelector(state => state.userProfileWidgets.isLoading);
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  useEffect(() => {
    if (getIsUserAuthenticated() && !userProfileWidgetsPresent) {
      dispatch(getSelectedUserProfileWidgets(selectedProfileid, userName, 'GENERALACCESS'));
    }
  }, [getSelectedUserProfileWidgets, selectedProfileid, userName]);

  let status = false;

  if (!getIsUserAuthenticated() || !selectedProfileid || !userName) {
    return <Redirect to="/login" />;
  }
  //return <Route {...props} component={AppLoading} />;
  if (isLoading) {
    return <Route {...props} component={AppLoading} />;
  }

  const pageAccess = IsWidgetAccessible(userProfileWidgets, props.pageAccessCode);

  if (props.accessCodes) {
    for (let i = 0; i < props.accessCodes.length; i++) {
      status = IsWidgetAccessible(userProfileWidgets, props.accessCodes[i]);
      if (status) break;
    }
  }

  if (pageAccess && status && !isLoading) {
    return <Route {...props} />;
  } else if ((!pageAccess || !status) && !isLoading && userProfileWidgetsPresent) {
    return <Route component={Unauth(UnAuth401)} />;
  } else {
    return <Route {...props} component={AppLoading} />;
  }
};

export default CustomRoute;
