import React, { useEffect, useState, Fragment } from 'react';
import s from './StaticData.module.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import Banner from '../Banner/Banner';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { WidgetVisibility } from '../../utils/widgetManager';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Table from 'react-bootstrap/Table';
import SearchBar from '../SearchBar/SearchBar';
import moment from 'moment-timezone';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { getStaticData, getUserProfileWidget, getCurrentTimezone, getUnitManager } from '../../selectors/index';
import { getStaticDataThunk, setCurrentPage, setFilteredDataThunk } from '../../actions/staticData';

const StaticDataListing = ({ intl }) => {
  const dispatch = useDispatch();
  const staticDataManager = useSelector(state => getStaticData(state));
  const unitManager = useSelector(state => getUnitManager(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  let { unitSerialNumber } = useParams();
  const unitId = (unitManager.selectedUnit && unitManager.selectedUnit.UnitId) || 0;
  let filter = staticDataManager.filter || '';
  let offset = staticDataManager.offset || 0;
  let limit = staticDataManager.limit || 1000;
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'UNITSTATICDATAUPDATE');

  useEffect(() => {
    let filterObj = {
      filter: filter,
      offset: offset,
      limit: limit
    };
    dispatch(getStaticDataThunk('UNITSTATICDATALIST', unitId, filterObj));
  }, [getStaticDataThunk, unitId, offset, limit, filter]);

  const handleCommand = unitStaticDataId => {};

  const onPageChangeHandler = currentPage => {
    dispatch(setCurrentPage(currentPage));
  };

  const searchHandler = value => {
    setCurrentPage(1);
    filteredResults(value);
  };

  const filteredResults = value => {
    let filteredList = staticDataManager.staticDataList;

    if (value !== '') {
      filteredList = filteredList.filter(function(item) {
        return item.Name.toLowerCase().includes(value.toLowerCase());
      });
    }

    setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } });
  };

  let staticDataList = Object.values(staticDataManager.filteredResults);

  let messageId = (staticDataManager && staticDataManager.displayMessageCode) || '';
  let messageText = '';
  messageText = messageId !== '' ? intl.formatMessage({ id: messageId, defaultMessage: messageId }) : '';

  return (
    <div className={s.staticDataListing}>
      {staticDataManager.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={staticDataManager.showBanner}
        status={staticDataManager.isOpSuccessful}
        successText={messageText}
      />
      <ContentTemplate
        selectedPage="staticData"
        userProfileWidgets={userProfileWidgets}
        widgetCode={'UNITSTATICDATALIST'}
        tagoutRequired={true}
      >
        <div className={s.contentWrapper}>
          <Row>
            <Col sm={6} lg={8}>
              <div className={s.staticDataHeader}>
                <div className={s.subHeading}>
                  <FormattedMessage id="staticData.staticData" defaultMessage="Static Data" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={6}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={filter}
                placeHolderTextId="staticData.name"
              />
            </Col>

            <Col sm={6} lg={6}>
              <Link to={`/unit/static-data/${unitSerialNumber}/${unitId}/0`}>
                <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `UNITSTATICDATAADD`)}>
                  + <FormattedMessage id="staticData.addNew" defaultMessage="ADD STATIC DATA" />
                </Button>
              </Link>
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={staticDataList}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={staticDataManager.currentPage}
            >
              <StaticDataListTable
                unitId={unitId}
                unitSerialNumber={unitSerialNumber}
                currentTimezone={currentTimezone}
                isUpdateAllowed={isUpdateAllowed}
              />
            </Pagination>
          </div>
        </div>
      </ContentTemplate>
    </div>
  );
};

const StaticDataListTable = ({ paginatedData, unitId, unitSerialNumber, currentTimezone, isUpdateAllowed }) => {
  var d = new Date();

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="staticData.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="staticData.isPublished" defaultMessage="Is Published" />
            </th>
            <th>
              <FormattedMessage id="staticData.publishedDate" defaultMessage="Published Date" />
            </th>
            <th>
              <FormattedMessage id="staticData.lastUpdated" defaultMessage="Last Updated" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <Fragment key={n.UnitStaticDataId}>
                <tr key={n.UnitStaticDataId} data-unittest="staticData">
                  <td>{n.Name || ''}</td>
                  <td>{n.IsPublished ? 'Yes' : 'No'}</td>
                  <td>
                    {n.PublishedByDateTime
                      ? moment
                          .unix(n.PublishedByDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm z')
                      : '-'}
                  </td>

                  <td>
                    {n.ModifiedDateTime
                      ? moment
                          .unix(n.ModifiedDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm z')
                      : '-'}
                  </td>
                  <td>
                    {isUpdateAllowed ? (
                      <Link to={`/unit/static-data/${unitSerialNumber}/${unitId}/${n.UnitStaticDataId}`}>
                        <Button variant="outline-secondary" className={s.changeButton}>
                          <FormattedMessage id="staticData.edit" defaultMessage="EDIT" />
                        </Button>
                      </Link>
                    ) : null}
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default injectIntl(StaticDataListing);
