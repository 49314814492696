import React, { useState, useEffect } from 'react';
import s from './FAQuestion.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getFAQuestion } from '../../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialFAQuestionState } from '../../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired } from '../../../utils';
import Cookies from 'js-cookie';
import AdminControlsContentTemplate from '../../AdminControls/AdminControlsContentTemplate';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import { compareValues } from '../../../utils/';
import Banner from '../../Banner/Banner';
import {
  describeFAQuestionThunk,
  saveFAQuestionThunk,
  setFAQuestionChangeThunk,
  setFAQuestionCleanUpThunk
} from '../../../actions/faQuestions';
import uuid from 'uuid';
import ReactTooltip from 'react-tooltip';

const FAQuestion = ({ intl }) => {
  let history = useHistory();
  let { faQuestionId, faqCategoryId } = useParams();

  const dispatch = useDispatch();
  const faQuestion = useSelector(state => getFAQuestion(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let backUrl = '/admin-controls/faqs';
  if (faqCategoryId && faqCategoryId > 0) {
    backUrl = `/admin-controls/faqs/category/${faqCategoryId}`;
  }

  let widgetCode = '';
  if (faQuestionId && faQuestionId > 0) {
    IsEdit = true;
    widgetCode = 'FAQ_EDIT';
  } else {
    widgetCode = 'FAQ_ADD';
    faQuestionId = 0;
  }

  useEffect(() => {
    if (faQuestionId || faQuestionId === 0) {
      dispatch(describeFAQuestionThunk(faQuestionId, widgetCode));
    }
  }, [describeFAQuestionThunk, dispatch, faQuestionId]);

  useEffect(() => {
    if (faqCategoryId) {
      setLocalFAQuestion({
        ...faQuestion,
        selectedFAQuestion: {
          ...faQuestion.selectedFAQuestion,
          FAQCategoryId: faqCategoryId
        }
      });
    }
  }, [faqCategoryId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(setFAQuestionCleanUpThunk(initialFAQuestionState.faQuestion.selectedFAQuestion));
    };
  }, []);

  // Update redux store
  const setLocalFAQuestion = currentState => {
    dispatch(setFAQuestionChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = faQuestion.selectedFAQuestion;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.FAQuestionId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'FAQ_ADD';
    }

    dispatch(saveFAQuestionThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !faQuestion ||
      !faQuestion.selectedFAQuestion ||
      !faQuestion.selectedFAQuestion.FAQCategoryId ||
      isRequired(faQuestion.selectedFAQuestion.FAQCategoryId, 1)
    ) {
      formErrors.FAQCategoryId = intl.formatMessage({
        id: 'faQuestions.faqCategoryRequired',
        defaultMessage: 'FAQ category is a mandatory field'
      });
      isValid = false;
    }
    if (
      !faQuestion ||
      !faQuestion.selectedFAQuestion ||
      !faQuestion.selectedFAQuestion.Question ||
      isRequired(faQuestion.selectedFAQuestion.Question, 1)
    ) {
      formErrors.Question = intl.formatMessage({
        id: 'faQuestions.questionRequired',
        defaultMessage: 'Question is a mandatory field'
      });
      isValid = false;
    }
    if (
      !faQuestion ||
      !faQuestion.selectedFAQuestion ||
      !faQuestion.selectedFAQuestion.Answer ||
      isRequired(faQuestion.selectedFAQuestion.Answer, 1)
    ) {
      formErrors.Answer = intl.formatMessage({
        id: 'faQuestions.answerRequired',
        defaultMessage: 'Answer is a mandatory field'
      });
      isValid = false;
    }
    setFormErrors(formErrors);

    return isValid;
  };

  const onDropdownChange = e => {
    setLocalFAQuestion({
      ...faQuestion,
      selectedFAQuestion: { ...faQuestion.selectedFAQuestion, FAQCategoryId: e.value }
    });
  };

  //on control value change
  const onChange = e => {
    setLocalFAQuestion({
      ...faQuestion,
      selectedFAQuestion: { ...faQuestion.selectedFAQuestion, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.goBack();
  };

  let messageId = (faQuestion && faQuestion.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const questionName = (faQuestion.selectedFAQuestion ? faQuestion.selectedFAQuestion.Question : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + questionName;
  } else {
    heading = intl.formatMessage({
      id: 'faQuestions.addFAQuestion',
      defaultMessage: 'Add new question'
    });
  }

  const faqCategoryOptions = () => {
    let faqCategorysData = [];

    const data = faQuestion.faqCategories;

    data.forEach(element => {
      faqCategorysData.push({
        label: element.Name,
        value: element.FAQCategoryId
      });
    });
    return faqCategorysData.sort(compareValues('label'));
  };

  const faqCategorySelectedValue = () => {
    if (faQuestionId && faQuestionId > 0) {
      return faqCategoryOptions().filter(option => option.value === faQuestion.selectedFAQuestion.FAQCategoryId);
    } else if (faqCategoryId) {
      return faqCategoryOptions().filter(option => option.value === parseInt(faqCategoryId));
    }
  };

  let ddlClassSensor = localformErrors && localformErrors.FAQCategoryId ? s.ddlError : '';

  return (
    <div className={s.faQuestion}>
      {faQuestion.isLoading && <LoadingSpinner />}
      {faQuestion.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={faQuestion.showBanner}
        status={faQuestion.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageFAQ" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.faQuestionHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.faQuestionContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formFAQCategory">
                      <Form.Label>
                        <FormattedMessage id="faQuestions.faqCategory" defaultMessage="FAQ Category" />
                      </Form.Label>

                      <Dropdown
                        id="formFAQCategory"
                        dataArray={faqCategoryOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="faQuestions.selectFAQCategory" defaultMessage="Select FAQ Category" />,
                          customClassName: ddlClassSensor
                        }}
                        name="FAQCategoryId"
                        onDropdownChange={onDropdownChange}
                        selectedOption={faqCategorySelectedValue()}
                        disabled={IsEdit || faqCategoryId}
                        data-unittest="formFAQCategory"
                      />
                      {localformErrors && localformErrors.FAQCategoryId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.FAQCategoryId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formFAQuestion">
                      <Form.Label>
                        <FormattedMessage id="faQuestions.question" defaultMessage="Question" />
                      </Form.Label>

                      <Form.Control
                        className={`${s.textArea} ${localformErrors.Question ? s.formControlError : ''}`}
                        as="textarea"
                        rows="3"
                        name="Question"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'faQuestions.question',
                          defaultMessage: 'Question'
                        })}
                        value={faQuestion.selectedFAQuestion.Question}
                      />
                      {localformErrors && localformErrors.Question && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Question}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="formFAQAnser">
                      <Form.Label>
                        <FormattedMessage id="faQuestions.answer" defaultMessage="Answer" />
                      </Form.Label>

                      <Form.Control
                        className={`${s.textArea} ${localformErrors.Answer ? s.formControlError : ''}`}
                        as="textarea"
                        rows="5"
                        name="Answer"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'faQuestions.answer',
                          defaultMessage: 'Answer'
                        })}
                        value={faQuestion.selectedFAQuestion.Answer}
                      />
                      {localformErrors && localformErrors.Answer && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Answer}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
        <ReactTooltip />
      </AdminControlsContentTemplate>
    </div>
  );
};

FAQuestion.defaultProps = {
  faQuestion: {
    ...initialFAQuestionState
  }
};

export default injectIntl(FAQuestion);
