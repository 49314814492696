import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SequenceVersionDetail from './SequenceVersionDetail';
import {
  getUserProfileWidget,
  getCurrentTimezone,
  getProfile,
  getFlowSequenceGlobalSettings,
  getSequenceVersion,
  getModelVersionManager
} from '../../selectors/index';

import { saveSequenceVersionThunk, describeSequenceVersionThunk, setSequenceVersionChangeThunk } from '../../actions/sequenceVersion';
import { describeModelVersionThunk } from '../../actions/modelVersion';

const mapStateToProps = state => {
  return {
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state),
    profile: getProfile(state),
    flowSequences: getFlowSequenceGlobalSettings(state),
    sequenceVersionManager: getSequenceVersion(state),
    modelVersionManager: getModelVersionManager(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveSequenceVersion: (sequenceVersion, widgetCode) => saveSequenceVersionThunk(sequenceVersion, widgetCode),
      describeSequenceVersion: (sequenceVersionId, widgetCode) => describeSequenceVersionThunk(sequenceVersionId, widgetCode),
      changeSequenceVersion: sequenceVersion => setSequenceVersionChangeThunk(sequenceVersion),
      describeModelVersion: (modelVersionId, widgetCode) => describeModelVersionThunk(modelVersionId, false, widgetCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SequenceVersionDetail);
