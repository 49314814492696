import { initialFAQuestionState } from './initialState';

import {
  FAQ_ERROR,
  GET_ALL_FAQ_DETAILS,
  FAQ_IS_LOADING,
  SET_FAQ_CURRENT_PAGE,
  SET_FAQ_PAGE_FILTER,
  SET_FAQ_CHANGE,
  SET_FAQ_CLEANUP,
  SET_FAQ,
  SET_FAQS,
  UPDATE_FAQ_SAVE_STATUS
} from '../constants/index';

const faQuestionReducer = (state = initialFAQuestionState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_FAQ_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        faQuestion: {
          ...state.faQuestion,
          ...data
        }
      };
    }

    case SET_FAQ_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        faQuestion: {
          ...state.faQuestion,
          currentPage
        }
      };
    }

    case SET_FAQ_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        faQuestion: {
          ...state.faQuestion,
          filter
        }
      };
    }

    case UPDATE_FAQ_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        faQuestion: {
          ...state.faQuestion,
          ...data
        }
      };
    }

    case FAQ_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        faQuestion: {
          ...state.faQuestion,
          isLoading: isLoading
        }
      };
    }

    case FAQ_ERROR: {
      const { data } = payload;
      return {
        ...state,
        faQuestion: {
          ...state.faQuestion,
          ...data
        }
      };
    }

    case SET_FAQ: {
      let { data, faqCategories } = payload;

      return {
        ...state,
        faQuestion: {
          ...state.faQuestion,
          isOpSuccessful: false,
          showBanner: false,
          faqCategories: faqCategories,
          selectedFAQuestion: {
            ...state.faQuestion.selectedFAQuestion,
            ...data[0]
          }
        }
      };
    }

    case SET_FAQS: {
      let { data, faqCategories } = payload;

      return {
        ...state,
        faQuestion: {
          ...state.faQuestion,
          isOpSuccessful: false,
          showBanner: false,
          faqCategories: faqCategories,
          faQuestions: data,
          isRedirect: false
        }
      };
    }

    case SET_FAQ_CHANGE: {
      let { selectedFAQuestion } = payload;

      return {
        ...state,
        faQuestion: {
          ...state.faQuestion,
          isOpSuccessful: false,
          showBanner: false,
          selectedFAQuestion: {
            ...state.faQuestion.selectedFAQuestion,
            ...selectedFAQuestion
          }
        }
      };
    }
    case SET_FAQ_CLEANUP: {
      let selectedFAQuestion = payload;
      return {
        ...state,
        faQuestion: {
          ...state.faQuestion,
          faqCategories: [],
          faQuestions: [],
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          selectedFAQuestion: selectedFAQuestion
        }
      };
    }

    default:
      return state;
  }
};

export default faQuestionReducer;
