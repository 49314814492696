import { initialDailySummaryState } from './initialState';

import {
  DAILYSUMMART_DATA_ERROR,
  DAILYSUMMART_DATA_IS_LOADING,
  SET_DAILYSUMMART_DATA_CHANGE,
  START_DAILYSUMMART_DATA_EXPORT,
  SET_DAILYSUMMART_EXPORT_DATA,
  SET_COMPUTE_DAILYSUMMART_DATA_EXPORT
} from '../constants/index';

const dailySummaryReducer = (state = initialDailySummaryState, action) => {
  const { payload, type } = action;

  switch (type) {
    case DAILYSUMMART_DATA_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          isLoading: isLoading
        }
      };
    }

    case DAILYSUMMART_DATA_ERROR: {
      const { data } = payload;
      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          ...data
        }
      };
    }

    case SET_DAILYSUMMART_DATA_CHANGE: {
      let { search } = payload;

      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          isOpSuccessful: false,
          showBanner: false,
          search: {
            ...state.dailySummary.search,
            ...search
          }
        }
      };
    }

    case START_DAILYSUMMART_DATA_EXPORT: {
      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          search: {
            ...state.dailySummary.search,
            exportedResults: [],
            exportComplete: false,
            exportCompute: false
          },
          isLoading: true
        }
      };
    }
    case SET_DAILYSUMMART_EXPORT_DATA: {
      const { data } = payload;
      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          displayMessageCode: data.displayMessageCode,
          isOpSuccessful: data.isOpSuccessful,
          search: {
            ...state.dailySummary.search,
            ...data.search
          }
        }
      };
    }
    case SET_COMPUTE_DAILYSUMMART_DATA_EXPORT: {
      const { status } = payload;
      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          isLoading: false,
          search: {
            ...state.dailySummary.search,
            exportCompute: status
          }
        }
      };
    }

    default:
      return state;
  }
};

export default dailySummaryReducer;
