import { initialChecklistTemplateState } from './initialState';

import {
  CHECKLIST_TEMPLATE_ERROR,
  GET_ALL_CHECKLIST_TEMPLATE_DETAILS,
  CHECKLIST_TEMPLATE_IS_LOADING,
  SET_CHECKLIST_TEMPLATE_CURRENT_PAGE,
  SET_CHECKLIST_TEMPLATE_PAGE_FILTER,
  SET_CHECKLIST_TEMPLATE_CHANGE,
  SET_CHECKLIST_TEMPLATE,
  UPDATE_CHECKLIST_TEMPLATE_SAVE_STATUS
} from '../constants/index';

const checklistTemplateReducer = (state = initialChecklistTemplateState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_CHECKLIST_TEMPLATE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        checklistTemplate: {
          ...state.checklistTemplate,
          ...data
        }
      };
    }

    case SET_CHECKLIST_TEMPLATE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        checklistTemplate: {
          ...state.checklistTemplate,
          currentPage
        }
      };
    }

    case SET_CHECKLIST_TEMPLATE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        checklistTemplate: {
          ...state.checklistTemplate,
          filter
        }
      };
    }

    case UPDATE_CHECKLIST_TEMPLATE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        checklistTemplate: {
          ...state.checklistTemplate,
          ...data
        }
      };
    }

    case CHECKLIST_TEMPLATE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        checklistTemplate: {
          ...state.checklistTemplate,
          isLoading: isLoading
        }
      };
    }

    case CHECKLIST_TEMPLATE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        checklistTemplate: {
          ...state.checklistTemplate,
          ...data
        }
      };
    }

    case SET_CHECKLIST_TEMPLATE: {
      let { TaskTemplates, SelectedChecklistTemplate } = payload;

      return {
        ...state,
        checklistTemplate: {
          ...state.checklistTemplate,
          isOpSuccessful: false,
          showBanner: false,
          TaskTemplates: TaskTemplates,
          SelectedChecklistTemplate: {
            ...state.checklistTemplate.SelectedChecklistTemplate,
            ...SelectedChecklistTemplate
          }
        }
      };
    }

    case SET_CHECKLIST_TEMPLATE_CHANGE: {
      let { SelectedChecklistTemplate } = payload;

      return {
        ...state,
        checklistTemplate: {
          ...state.checklistTemplate,
          isOpSuccessful: false,
          showBanner: false,
          SelectedChecklistTemplate: {
            ...state.checklistTemplate.SelectedChecklistTemplate,
            ...SelectedChecklistTemplate
          }
        }
      };
    }

    default:
      return state;
  }
};

export default checklistTemplateReducer;
