import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import s from './Profile.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import Banner from '../Banner/Banner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import uuidv4 from 'uuid/v4';
import ProfileWidgets from './ProfileWidgets';
import SearchBar from '../SearchBar/SearchBar';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { isRequired, compareValues, groupBy, sortObjectKeys } from '../../utils/';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import { SELECT_ALL_PAGES } from '../../constants/index';
import Cookies from 'js-cookie';

class Profile extends React.Component {
  state = {
    profileInfo: {
      Name: '',
      Description: '',
      IsActive: true,
      IsMFAEnabled: false,
      GuidIdentifier: uuidv4(),
      IsDefault: false,
      ProfileId: 0,
      ProfileWigets: []
    },
    isEdit: false,
    selectedWidgets: [],
    formErrors: {},
    filterText: '',
    filterScreenName: ''
  };
  envName = process.env.REACT_APP_ENV_NAME_SHORT;

  componentDidMount() {
    let profileId = this.props.match.params.profileId;
    let widgetCode = profileId ? 'PROFILEUPDATE' : 'PROFILEINSERT';
    this.props.getAppWidgets(widgetCode);
    if (profileId) {
      this.props.getSelectedProfile(profileId, widgetCode);

      let selProfile = this.props.selectedProfile;

      this.setState({
        profileInfo: {
          Name: selProfile.Name || '',
          Description: selProfile.Description || '',
          IsMFAEnabled: selProfile.IsMFAEnabled || false,
          IsActive: selProfile.IsActive,
          GuidIdentifier: selProfile.GuidIdentifier,
          ProfileId: selProfile.ProfileId,
          IsDefault: selProfile.IsDefault,
          ProfileWigets: []
        },
        formErrors: {},
        isEdit: true,
        selectedWidgets: []
      });
    } else {
      let currentProfile = this.props.selectedProfile;
      currentProfile.GuidIdentifier = uuidv4();
      currentProfile.IsDefault = false;
      this.setState({
        profileInfo: currentProfile,
        isEdit: false,
        selectedWidgets: [],
        formErrors: {}
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedProfile && this.props.selectedProfile.ProfileId !== prevProps.selectedProfile.ProfileId) {
      let profileWigets = [];
      if (this.props.selectedProfile.ProfileWigets && this.props.selectedProfile.ProfileWigets.length > 0) {
        profileWigets = this.props.selectedProfile.ProfileWigets.map(a => a.WidgetId);
      }
      this.setState({
        profileInfo: this.props.selectedProfile,
        selectedWidgets: profileWigets
      });
    }
  }

  onChange = event => {
    let { name, value } = event.target;
    let currentProfileInfo = this.state.profileInfo;

    switch (name) {
      case 'Name':
        currentProfileInfo.Name = value;
        break;
      case 'Description':
        currentProfileInfo.Description = value;
        break;
      case 'IsActive':
        currentProfileInfo.IsActive = event.target.checked;
        break;
      case 'IsMFAEnabled':
        currentProfileInfo.IsMFAEnabled = event.target.checked;
        break;
      default:
        break;
    }
    this.setState({ profileInfo: currentProfileInfo });
  };

  submitForm = e => {
    e.preventDefault();

    if (!this.validateInput()) {
      return;
    }

    let data = this.state.profileInfo;
    data.UpdatedBy = Cookies.get(`userid-${this.envName}`) || 1;
    data.ProfileWigets = this.state.selectedWidgets;
    data.OrganisationId = Cookies.get(`selectedorganisationid-${this.envName}`) || 0;
    //Adding NAV_HOME if not included
    if (!data.ProfileWigets.includes(19)) data.ProfileWigets.push(19);

    //Adding NAV_SETTINGS if not included
    if (!data.ProfileWigets.includes(22)) data.ProfileWigets.push(22);

    let widgetCode = data.ProfileId ? 'PROFILEUPDATE' : 'PROFILEINSERT';
    this.props.saveProfile(data, widgetCode);
  };

  validateInput = () => {
    let formErrors = {};
    let isValid = true;
    let currentProfileInfo = this.state.profileInfo;
    this.scrollToTop();
    if (!currentProfileInfo || !currentProfileInfo.Name || isRequired(currentProfileInfo.Name, 2)) {
      formErrors.Name = this.props.intl.formatMessage({
        id: 'profile.validationNameLength',
        defaultMessage: 'Profile Name must be atleast 2 characters long'
      });
      isValid = false;
    }

    if (!currentProfileInfo || !currentProfileInfo.Name || isRequired(currentProfileInfo.Name, 1)) {
      formErrors.Name = this.props.intl.formatMessage({
        id: 'profile.validationName',
        defaultMessage: 'Profile Name is required!'
      });
      isValid = false;
    }

    if (!currentProfileInfo || !currentProfileInfo.Description || isRequired(currentProfileInfo.Description, 1)) {
      formErrors.Description = this.props.intl.formatMessage({
        id: 'profile.validationDescriptionLength',
        defaultMessage: 'Profile Description is required!'
      });
      isValid = false;
    }

    if (!currentProfileInfo || (!currentProfileInfo.IsActive && currentProfileInfo.IsMFAEnabled)) {
      formErrors.ProfileMFA = this.props.intl.formatMessage({
        id: 'profile.validationActiveProfileMFA',
        defaultMessage: 'MFA must be disabled if profile is not active!'
      });
      isValid = false;
    }

    this.setState({
      ...this.state,
      formErrors: {
        ...formErrors
      }
    });

    return isValid;
  };

  onWidgetChange = (widgets, isDelete) => {
    var newSelectedWidgets = this.state.selectedWidgets;
    if (!isDelete) {
      widgets.forEach(id => {
        if (!newSelectedWidgets.includes(id)) {
          newSelectedWidgets.push(id);
        }
      });
    } else {
      widgets.forEach(id => {
        const removeIndex = newSelectedWidgets.indexOf(id);
        if (removeIndex > -1) {
          newSelectedWidgets.splice(removeIndex, 1);
        }
      });
    }

    this.setState({ selectedWidgets: newSelectedWidgets });
  };

  updateProfileAttributes = e => {
    this.props.setSelectedProfile(this.state.profileInfo);
  };

  searchHandler = searchQuery => {
    this.setState({ filterText: searchQuery });
  };

  screenNameOptions = groupWidgets => {
    let screenNamesData = [];
    screenNamesData.push({
      label: SELECT_ALL_PAGES,
      value: '0'
    });

    if (this.state.filterText === '') {
      this.props.widgetData.appWidgets.forEach(element => {
        if (!screenNamesData.some(x => x.label === element.ScreenName)) {
          screenNamesData.push({
            label: element.ScreenName,
            value: element.ScreenId
          });
        }
      });
    } else {
      Object.keys(groupWidgets).forEach(element => {
        if (!screenNamesData.some(x => x.label === element)) {
          screenNamesData.push({
            label: element,
            value: groupWidgets[element].ScreenId
          });
        }
      });
    }

    return screenNamesData.sort(compareValues('label'));
  };

  onScreenNameChange = e => {
    this.setState({ filterScreenName: e.label });
  };

  groupByScreenName = groupBy('ScreenName');

  getSelectedWidgets = widgets => {
    let selectedGroupWidgets = [];
    widgets.forEach(item => {
      if (this.state.selectedWidgets.includes(item.WidgetId)) {
        selectedGroupWidgets.push(item.WidgetId);
      }
    });
    return selectedGroupWidgets;
  };

  getActiveWidgets = groupWidgets => {
    var filteredWidgets = [];
    const serachText = this.state.filterText;
    const filterScreenName = this.state.filterScreenName;

    if (serachText !== '') {
      filteredWidgets = Object.keys(groupWidgets).reduce(function(accumulator, currentValue) {
        var widgets = groupWidgets[currentValue].filter(function(widget) {
          return widget.Name.toLowerCase().includes(serachText.toLowerCase());
        });
        if (widgets.length > 0 && (filterScreenName === '' || filterScreenName === SELECT_ALL_PAGES || currentValue === filterScreenName)) {
          accumulator[currentValue] = widgets;
        }
        return accumulator;
      }, {});
    } else {
      if (filterScreenName === '' || filterScreenName === SELECT_ALL_PAGES) {
        filteredWidgets = groupWidgets;
      } else {
        filteredWidgets = Object.keys(groupWidgets).reduce(function(accumulator, currentValue) {
          if (currentValue === filterScreenName) {
            accumulator[currentValue] = groupWidgets[currentValue];
          }
          return accumulator;
        }, {});
      }
    }
    return filteredWidgets;
  };

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  render() {
    const { Name, Description, IsMFAEnabled, IsActive } = this.state.profileInfo;
    const { isLoading, redirect } = this.props.profile;

    let messageId = (this.props.profile && this.props.profile.errorMessage) || 'none';
    const messageText = this.props.intl.formatMessage({ id: messageId, defaultMessage: messageId });

    const alreadyExistsText = this.props.intl.formatMessage({
      id: 'profile.alreadyExists',
      defaultMessage: 'A profile with this name already exists'
    });

    const enterProfileName = this.props.intl.formatMessage({
      id: 'profile.enterProfileName',
      defaultMessage: 'Enter profile name'
    });

    const enterProfileDescription = this.props.intl.formatMessage({
      id: 'profile.enterProfileDescription',
      defaultMessage: 'Enter profile Description'
    });

    const groupWidgets = this.getActiveWidgets(sortObjectKeys(this.groupByScreenName(this.props.widgetData.appWidgets)));

    return (
      <div className={s.profile}>
        <Banner
          failureText={messageText}
          showBanner={this.props.profile.showBanner}
          status={this.props.profile.isOpSuccessful}
          successText={messageText}
        />
        {isLoading && <LoadingSpinner />}
        <AdminControlsContentTemplate selectedPage="manageProfiles" userProfileWidgets={this.props.userProfileWidgets}>
          <div className={s.contentWrapper}>
            <div className={s.profileHeader}>
              <Link className={s.backLink} to={'/admin-controls/profile-list'}>
                <FormattedMessage id="profile.back" defaultMessage="< BACK TO PROFILES" />
              </Link>
              <h3>
                {this.state.isEdit ? (
                  <FormattedMessage id="profile.profileEdit" defaultMessage="Edit Profile" />
                ) : (
                  <FormattedMessage id="profile.addProfile" defaultMessage="Add Profile" />
                )}
              </h3>
            </div>
            <div className={s.profileContent}>
              {redirect ? <Redirect to="/admin-controls/profile-list" /> : ''}
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formProfileName">
                      <Form.Label>
                        <FormattedMessage id="profile.profileName" defaultMessage="Profile Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder={enterProfileName}
                        value={Name || ''}
                        name="Name"
                        onChange={this.onChange}
                        onBlur={this.updateProfileAttributes}
                        noValidate
                        className={`${this.state.formErrors && this.state.formErrors.Name ? s.formControlError : ''}`}
                      />
                      {this.state.formErrors && this.state.formErrors.Name && (
                        <p role="alert" className={s.error}>
                          {this.state.formErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="formProfileDescription">
                      <Form.Label>
                        <FormattedMessage id="profile.profileDescription" defaultMessage="Profile Description" />
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        placeholder={enterProfileDescription}
                        value={Description || ''}
                        name="Description"
                        onChange={this.onChange}
                        onBlur={this.updateProfileAttributes}
                        noValidate
                        className={`${s.textArea} ${this.state.formErrors && this.state.formErrors.Description ? s.formControlError : ''}`}
                      />
                      {this.state.formErrors && this.state.formErrors.Description && (
                        <p role="alert" className={s.error}>
                          {this.state.formErrors.Description}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formProfileMFA" className={s.profileWidgetsContent}>
                      <label className={s.container}>
                        <FormattedMessage id="profile.EnableMFA" defaultMessage="Multi Factor Authentication (MFA)" />
                        <input
                          type="checkbox"
                          name="IsMFAEnabled"
                          id="IsMFAEnabled"
                          onChange={this.onChange}
                          checked={IsMFAEnabled || false}
                        />
                        <span className={s.checkmark} htmlFor="IsMFAEnabled"></span>
                      </label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formProfileIsActive" className={s.profileWidgetsContent}>
                      <label className={s.container}>
                        <FormattedMessage id="profile.profileActive" defaultMessage="Active" />
                        <input
                          type="checkbox"
                          name="IsActive"
                          id="IsActive"
                          onChange={this.onChange}
                          checked={IsActive || false}
                          onBlur={this.updateProfileAttributes}
                          noValidate
                          className={`${s.textArea} ${this.state.formErrors && this.state.formErrors.ProfileMFA ? s.formControlError : ''}`}
                        />
                        {this.state.formErrors && this.state.formErrors.ProfileMFA && (
                          <p role="alert" className={s.error}>
                            {this.state.formErrors.ProfileMFA}
                          </p>
                        )}
                        <span className={s.checkmark} htmlFor="IsActive"></span>
                      </label>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Profile Widget START */}

                <Form.Group controlId="formProfileWidgets">
                  <Row>
                    <Col lg={6}>
                      <Form.Label>
                        <h4>
                          <FormattedMessage id="profile.widgetAssigned" defaultMessage="Widgets" />
                        </h4>
                      </Form.Label>
                      <div className={s.wigetInfo}>
                        <FormattedMessage
                          id="profile.widgetselection"
                          defaultMessage="Select the widgets that this profile will have access to."
                        />
                      </div>
                      <Row className="mt-3">
                        <Col lg={6} xs={12}>
                          <label className="form-label">
                            <FormattedMessage id="profile.search" defaultMessage="Search" />
                          </label>
                          <SearchBar searchHandler={this.searchHandler} />
                        </Col>
                        <Col lg={6} xs={12}>
                          <label className="form-label">
                            <FormattedMessage id="profile.filterBy" defaultMessage="Filter by" />
                          </label>
                          <Dropdown
                            id="drpWidgetCategory"
                            dataArray={this.screenNameOptions(groupWidgets)}
                            controlData={{
                              placeholderText: <FormattedMessage id="profile.selectPage" defaultMessage="Select page" />,
                              customClassName: ''
                            }}
                            onDropdownChange={e => this.onScreenNameChange(e)}
                            disabled={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {Object.keys(groupWidgets).map(widgetItem => (
                        <ProfileWidgets
                          key={widgetItem}
                          groupName={widgetItem}
                          widgets={groupWidgets[widgetItem].sort(compareValues('Name'))}
                          profileWidgets={this.getSelectedWidgets(groupWidgets[widgetItem])}
                          onWidgetChange={this.onWidgetChange}
                        />
                      ))}
                    </Col>
                  </Row>
                </Form.Group>
                {/* Profile Widget  END */}

                <Button
                  variant="primary"
                  data-unittest="saveData"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={this.submitForm}
                  noValidate
                  disabled={this.state.profileInfo.IsDefault}
                >
                  <FormattedMessage id="profile.profileSave" defaultMessage="Save" />
                </Button>
                <Link to="/admin-controls/profile-list">
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="profile.profileCancel" defaultMessage="Cancel" />
                  </Button>
                </Link>
              </Form>
            </div>
          </div>
        </AdminControlsContentTemplate>
      </div>
    );
  }
}

Profile.defaultProps = {
  profile: {
    profiles: [],
    selectedProfile: {
      ProfileId: 0,
      Name: '',
      Description: '',
      GuidIdentifier: '',
      IsDefault: false,
      IsActive: true,
      ProfileWigets: []
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: true,
    redirect: false,
    errorMessage: '',
    isError: false
  },
  selectedListStateProfile: {},
  widgetData: {
    appWidgets: []
  }
};

Profile.propTypes = {
  saveProfile: PropTypes.func.isRequired,
  setSelectedProfile: PropTypes.func.isRequired,
  getSelectedProfile: PropTypes.func.isRequired,
  getAppWidgets: PropTypes.func.isRequired,
  userProfileWidgets: PropTypes.object.isRequired
};

export default injectIntl(Profile);
