import React from 'react';
import PropTypes from 'prop-types';
import s from './CommandButton.module.scss';
import Button from 'react-bootstrap/Button';

const CommandButton = ({ sendCommand, disabled, title, name, command, cType }) => {
  let variant = 'outline-secondary';
  if (name === 'START') {
    variant = 'success';
  } else if (name === 'STOP') {
    variant = 'danger';
  }

  return (
    <Button variant={variant} disabled={disabled} className={s.commandButton} onClick={() => sendCommand(command, cType)}>
      {title}
    </Button>
  );
};

CommandButton.propTypes = {
  command: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  sendCommand: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default CommandButton;
