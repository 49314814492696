import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UnitConfigurationPartDetails from './UnitConfigurationPartDetails';
import { getComponentParts, getUserProfileWidget, getSelectedDeviceDefault } from '../../selectors/index';
import { describeComponentPartThunk, setComponentPartChangeThunk } from '../../actions/componentParts';

const mapStateToProps = state => {
  return {
    componentParts: getComponentParts(state),
    userProfileWidgets: getUserProfileWidget(state),
    selectedDevice: getSelectedDeviceDefault(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeComponentPart: componentPartId => describeComponentPartThunk(componentPartId, 'UNIT_PARTS_VIEW'),
      changeComponentPart: selectedComponentPart => setComponentPartChangeThunk(selectedComponentPart)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UnitConfigurationPartDetails);
