import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './UnitStatisticsTile.module.scss';
import bs from '../../styles/bootstrap-overrides.scss';
import { FormattedMessage, IntlProvider } from 'react-intl';
import pen from '../../assets/pen.svg';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentTimezone, getUserProfileWidget, getUnitStatistics } from '../../selectors/index';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { setUnitStatisticsChangeThunk, saveUnitStatisticsSettingsThunk } from '../../actions/unitStatistics';
import help from '../../assets/help-white.svg';
import ReactTooltip from 'react-tooltip';
import { injectIntl } from 'react-intl';

const UnitStatisticsTile = ({ statistics, tileEdit, setTileEdit, intl, fromDashboard }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [focus, setFocus] = useState(false);

  //selectors
  const unitStatistics = useSelector(state => getUnitStatistics(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));

  const changeAllowed = IsWidgetAccessible(userProfileWidgets, `UNIT_STATISTICS_SETTINGS_SAVE`);

  const getStatTypes = () => {
    let unitStatsData = [];

    unitStatistics.statisticTypes.forEach(element => {
      unitStatsData.push({
        label: <FormattedMessage id={element.NameTranslationCode} defaultMessage={element.Name} />,
        value: element.StatisticTypeId,
        isDisabled: unitStatistics.statisticSettings.find(
          setting => setting.StatisticTypeId === element.StatisticTypeId && setting.FromDashboard === fromDashboard
        )
          ? true
          : false
      });
    });
    return unitStatsData;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [focus]);

  const onStatTypeChange = e => {
    dispatch(setUnitStatisticsChangeThunk({ selectedStatistics: { ...statistics, StatisticTypeId: e.value } }));
  };

  const getTileColor = tileOrder => {
    let retStyle = s.bgColorBlue;
    if (tileOrder === 2) {
      retStyle = s.bgColorGreen;
    } else if (tileOrder === 3) {
      retStyle = s.bgColorViking;
    }
    return retStyle;
  };

  const onCancel = () => {
    setIsEdit(false);
    setTileEdit(false);
  };

  const submitForm = () => {
    dispatch(saveUnitStatisticsSettingsThunk(unitStatistics.selectedStatistics, 'UNIT_STATISTICS_SETTINGS_SAVE'));
  };

  const getStatTypeName = statTypeId => {
    let statType = unitStatistics.statisticTypes.find(ele => ele.StatisticTypeId === statTypeId);
    if (statType) {
      return <FormattedMessage id={statType.NameTranslationCode} defaultMessage={statType.Name} />;
    }
    return '';
  };

  const getStatTypeDescription = statTypeId => {
    let statType = unitStatistics.statisticTypes.find(ele => ele.StatisticTypeId === statTypeId);
    if (statType) {
      return intl.formatMessage({ id: statType.DescriptionTranslationCode, defaultMessage: statType.Description });
    }
    return '';
  };

  const getStatTypeUOM = statTypeId => {
    let statType = unitStatistics.statisticTypes.find(ele => ele.StatisticTypeId === statTypeId);
    if (statType) {
      return <FormattedMessage id={statType.UOMTranslationCode} defaultMessage={statType.UnitOfMeasure} />;
    }
    return '';
  };

  const onEditClick = () => {
    setIsEdit(true);
    setTileEdit(true);
    dispatch(setUnitStatisticsChangeThunk({ selectedStatistics: statistics, isTabOpen: true }));
  };

  const getStatisticValue = statisticTypeId => {
    let filtData = unitStatistics.statistics.find(stat => stat.StatisticTypeId === statisticTypeId);
    return (filtData && filtData.Value && Number(filtData.Value).toFixed(1)) || '0.0';
  };

  useEffect(() => {
    if (
      (unitStatistics.selectedStatistics.TileOrder === statistics.TileOrder && unitStatistics.isOpSuccessful, unitStatistics.showBanner)
    ) {
      onCancel();
    }
  }, [unitStatistics.isOpSuccessful, unitStatistics.showBanner]);

  let tooltip = getStatTypeDescription(statistics.StatisticTypeId);

  return (
    <div className={classNames(getTileColor(statistics.TileOrder), s.statTile)}>
      {unitStatistics.isLoading && isEdit && unitStatistics.selectedStatistics.TileOrder === statistics.TileOrder && <LoadingSpinner />}
      <div className={s.contentWrapper} onMouseEnter={() => setFocus(true)} onMouseLeave={() => setFocus(false)}>
        {isEdit && (
          <Fragment>
            <div>
              <Dropdown
                id="drpStatType"
                dataArray={getStatTypes()}
                controlData={{
                  placeholderText: <FormattedMessage id="unitStatistics.statisticType" defaultMessage="Statistic Type" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onStatTypeChange(e)}
                selectedOption={getStatTypes().filter(option => option.value === unitStatistics.selectedStatistics.StatisticTypeId)}
                disabled={false}
              />
            </div>
            <div>
              <Row>
                <Col xs={12} lg={6}>
                  <Button
                    variant="primary"
                    data-unittest="saveData"
                    className={classNames(s.margin5, s.btnSaveChanges)}
                    onClick={submitForm}
                    noValidate
                  >
                    <FormattedMessage id="unitStatistics.save" defaultMessage="Save" />
                  </Button>
                </Col>
                <Col xs={12} lg={6}>
                  <Button variant="primary" className={s.btnCancel} onClick={onCancel}>
                    <FormattedMessage id="unitStatistics.cancel" defaultMessage="Cancel" />
                  </Button>
                </Col>
              </Row>
            </div>
          </Fragment>
        )}
        {!isEdit && changeAllowed && (
          <Fragment>
            {focus && !isEdit && !tileEdit && <img className={s.imgIcon} src={pen} onClick={() => onEditClick()} />}
            {focus && !isEdit && !tileEdit && <img src={help} className={s.imgIcon} alt="icon" data-tip={tooltip} />}
            <ReactTooltip />
            <div className={s.statText}>{getStatTypeName(statistics.StatisticTypeId)}</div>
            <div className={s.statValue}>
              {getStatisticValue(statistics.StatisticTypeId)}
              <span className={s.statText}>&nbsp;{getStatTypeUOM(statistics.StatisticTypeId)}</span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default injectIntl(UnitStatisticsTile);
