import React from 'react';
import PropTypes from 'prop-types';
import s from './InputTemplate.module.scss';

const InputTemplate = ({ children, label }) => (
  <div>
    <label className={s.inputlabel}>
      <span className={s.label}>{label}</span>
      {children}
    </label>
  </div>
);

InputTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string
};

export default InputTemplate;
