import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { initialUnitManagerState } from '../../reducers/initialState';
import Files from 'react-files';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import s from './UnitUpload.module.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Alert from 'react-bootstrap/Alert';
import uploadFile from '../../assets/cloud-upload-file-grey.svg';
import tick from '../../assets/tick-green-filled.svg';
import error from '../../assets/error-red-filled.svg';
import alert from '../../assets/alert.svg';

class UnitUpload extends Component {
  constructor() {
    super();
    this.state = {
      jsonFile: {},
      isLoading: false,
      filename: '',
      hasError: false,
      errorMessage: '',
      deletefile: false,
      isSaveError: true,
      uploadComponent: 'preUpload'
    };
  }

  onUploadClick = uploadData => {
    this.setState({
      jsonFile: uploadData.jsonFile,
      filename: uploadData.filename,
      hasError: false,
      deletefile: false,
      uploadComponent: 'uploadStatus'
    });
  };
  onError = error => {
    var regex = RegExp(/. is not a valid file type/);
    if (regex.test(error)) {
      this.setState({
        errorMessage: 'File could not be uploaded. Only files with the following extensions are allowed: JSON',
        hasError: true
      });
    } else {
      this.setState({ errorMessage: error, hasError: true });
    }
  };

  setComponent = component => {
    this.setState({ uploadComponent: component });
  };

  saveUnitInfo = () => {
    let isFormValid = true;
    let data = this.state.jsonFile;
    if (!data || !data.unit || (!data.unit.unit_id && !data.unit.action)) {
      isFormValid = false;
      this.setState({
        errorMessage: (
          <FormattedMessage id="unitupload.invalidfileformat" defaultMessage="File format is invalid. Please upload a valid file" />
        ),
        hasError: true
      });
    }

    if (isFormValid) {
      this.setComponent('inProgress');
      this.props.saveUnit(data);
    }
  };

  render() {
    const uploadComponent = () => {
      switch (this.state.uploadComponent) {
        case 'preUpload':
          return <FileUpload onUploadClick={this.onUploadClick} onError={this.onError} />;
        case 'uploadStatus':
          return <FileUploadStatus filename={this.state.filename} saveUnit={this.saveUnitInfo} setComponent={this.setComponent} />;
        case 'inProgress':
          return this.props.unitManager.isLoading ? (
            <FileUploadInProgress isLoading={this.props.unitManager.isLoading} setComponent={this.setComponent} />
          ) : (
            <FileUploadSaveStatus
              isSaveError={this.props.unitManager.isOpSuccessful}
              filecert={this.props.unitManager.unitIotInfo}
              setComponent={this.setComponent}
            />
          );
        case 'uploadSaveStatus':
          return (
            <FileUploadSaveStatus
              isSaveError={this.props.unitManager.isOpSuccessful}
              filecert={this.props.unitManager.unitIotInfo}
              setComponent={this.setComponent}
            />
          );
        default:
          return <FileUpload onUploadClick={this.onUploadClick} onError={this.onError} />;
      }
    };
    return (
      <div className={s.unitupload}>
        <Link className={s.backLink} to={'/unit'}>
          <FormattedMessage id="unitupload.back" defaultMessage="< BACK TO UNITS" />
        </Link>
        <h1>
          {' '}
          <FormattedMessage id="unitupload.addUnit" defaultMessage="Add New Unit" />
        </h1>
        <Row>
          <Col lg={4}>
            <div className={s.messagebox}>
              {this.state.hasError ? (
                <Alert variant="light">
                  <Row>
                    <Col sm={1}>
                      <img src={alert} alt="icon" className={s.error}></img>
                    </Col>
                    <Col sm={11}>
                      <div className={s.messageText}>{this.state.errorMessage}</div>
                    </Col>
                  </Row>
                </Alert>
              ) : (
                ''
              )}
            </div>
            <div className={s.unitUploadContent}>{uploadComponent()}</div>
          </Col>
        </Row>
      </div>
    );
  }
}

let FileUploadSaveStatus = class SaveStatus extends React.Component {
  changeComponent = () => {
    this.props.setComponent('preUpload');
  };

  downloadCsvFile = data => {
    if (data && data.unitIotInfo && data.unitIotInfo.privateKeyData) {
      let iotData = data.unitIotInfo;
      if (iotData.privateKeyData.fileName.length > 0) {
        const privateKeyElement = document.createElement('a');
        const privatefile = new Blob([iotData.privateKeyData.data], { type: 'plain/text' });
        privateKeyElement.href = URL.createObjectURL(privatefile);
        privateKeyElement.download = iotData.privateKeyData.fileName;
        document.body.appendChild(privateKeyElement); // Required for this to work in FireFox
        privateKeyElement.click();
      }
      if (iotData.certificateData.fileName.length > 0) {
        const publicKeyElement = document.createElement('a');
        const file = new Blob([iotData.certificateData.data], { type: 'plain/text' });
        publicKeyElement.href = URL.createObjectURL(file);
        publicKeyElement.download = iotData.certificateData.fileName;
        document.body.appendChild(publicKeyElement); // Required for this to work in FireFox
        publicKeyElement.click();
      }
    }
  };

  render() {
    return (
      <div className={s.fileupload}>
        {this.downloadCsvFile(this.props.filecert)}
        <Row>
          <Col>
            {!this.props.isSaveError ? (
              <img src={error} alt="icon" className={s.errorCross}></img>
            ) : (
              <img src={tick} alt="icon" className={s.successTick}></img>
            )}
          </Col>
        </Row>
        <Row>
          <Col className={s.textMediumMessage}>
            {!this.props.isSaveError ? (
              <FormattedMessage
                id="unitupload.savefailed"
                defaultMessage="The unit could not be added. Please check the file and try again."
              />
            ) : (
              <FormattedMessage id="unitupload.saveSuccess" defaultMessage="The unit has been successfully added" />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {!this.props.isSaveError ? (
              <Button variant="primary" className={s.btnAddUnit} onClick={this.changeComponent}>
                <FormattedMessage id="unitupload.tryAgain" defaultMessage="TRY AGAIN" />
              </Button>
            ) : (
              <Button variant="primary" className={s.btnViewUnit} href="/unit">
                <FormattedMessage id="unitupload.viewUnit" defaultMessage="VIEW UNIT" />
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
};

let FileUploadInProgress = class InProgress extends React.Component {
  changeComponent = () => {
    this.props.setComponent('uploadSaveStatus');
  };
  render() {
    return (
      <div className={s.fileupload}>
        {/* {this.props.isLoading ? this.changeComponent() : '' } */}
        <Row>
          <Col className={s.spinner}>{this.props.isLoading && <LoadingSpinner />}</Col>
        </Row>
        <Row>
          <Col className={s.textMediumMessage}>
            <FormattedMessage id="unitupload.addingUnit" defaultMessage="Adding Unit" />
          </Col>
        </Row>
        <Row>
          <Col className={s.texFile}>
            <FormattedMessage id="unitupload.approxtime" defaultMessage="This will take approximately 15 seconds" />
          </Col>
        </Row>
      </div>
    );
  }
};

let FileUploadStatus = class UploadStatus extends React.Component {
  changeComponent = () => {
    this.props.setComponent('preUpload');
  };
  savingUnitInfo = () => {
    this.props.saveUnit();
  };
  render() {
    return (
      <div className={s.fileupload}>
        <Row>
          <Col>
            <img src={tick} alt="icon" className={s.successTick}></img>
          </Col>
        </Row>
        <Row>
          <Col className={s.textMediumMessage}>{this.props.filename}</Col>
        </Row>
        <Row>
          <Col>
            <FormattedMessage id="unitupload.hasBeenUploaded" defaultMessage="has been uploaded" />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Button variant="primary" className={s.btnAddUnit} onClick={this.savingUnitInfo}>
              <FormattedMessage id="unitupload.addunit" defaultMessage="ADD UNIT" />
            </Button>
          </Col>
          <Col lg={6}>
            <Button variant="outline-secondary" className={s.btnfileUpload} onClick={this.changeComponent}>
              <FormattedMessage id="unitupload.remove" defaultMessage="REMOVE" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
};

let FileUpload = class FileUpload extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      jsonFile: {},
      filename: ''
    };

    this.fileReader = new FileReader();
    this.fileReader.onload = event => {
      this.setState({ jsonFile: JSON.parse(event.target.result) }, () => {
        let uploadData = {};
        uploadData.jsonFile = this.state.jsonFile;
        uploadData.filename = this.state.filename;
        this.props.onUploadClick(uploadData);
      });
    };
  }

  componentWillUnmount() {
    this.refs.files.removeFiles();
  }

  onFilesChange = (files, event) => {
    if (files.length > 0) {
      this.fileReader.readAsText(files[0]);
      this.setState({ filename: files[0].name, hasFileInfo: true }, () => {});
    } else {
      this.setState({ filename: '', hasFileInfo: false }, () => {});
    }
  };

  onFilesError = (error, file) => {
    this.props.onError(error.message);
  };

  render() {
    return (
      <div>
        <Files
          className="files-dropzone"
          ref="files"
          onChange={this.onFilesChange}
          onError={this.onFilesError}
          accepts={['.json']}
          multiple
          maxFiles={1}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          <Row>
            <Col>
              <div className="text-center">
                <Row>
                  <Col>
                    <img src={uploadFile} alt="icon" className={s.uploadicon}></img>
                  </Col>
                </Row>
                <Row>
                  <Col className={s.textDrag}>
                    <FormattedMessage id="unitupload.dragndrop" defaultMessage="Drag and drop file here" />
                  </Col>
                </Row>
                <Row>
                  <Col className={s.or}>
                    <FormattedMessage id="unitupload.or" defaultMessage="or" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button variant="outline-secondary" className={s.btnUpload}>
                <FormattedMessage id="unitupload.browse" defaultMessage="BROWSE FILE" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className={s.texFile}>
              <FormattedMessage id="unitupload.acceptedfile" defaultMessage="Accepted file formats: .JSON" />
            </Col>
          </Row>
        </Files>
      </div>
    );
  }
};

UnitUpload.defaultProps = {
  unitManager: {
    ...initialUnitManagerState.unitManager
  }
};

UnitUpload.propTypes = {
  saveUnit: PropTypes.func.isRequired
};

export default UnitUpload;
