import React from 'react';
import PropTypes from 'prop-types';
import s from './ComponentPartList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';

const ComponentPartsListTable = ({ paginatedData, currentPage, ViewAllowed, onSortOrder, asendingOrder }) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="componentParts.brand" defaultMessage="Brand" />
            </th>
            <th>
              <FormattedMessage id="componentParts.model" defaultMessage="Model" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="componentParts.serialNumber" defaultMessage="Serial Number" />{' '}
                <img className={s.sortIcon} src={sortIcon} />{' '}
              </span>
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="componentParts.componentType" defaultMessage="Type" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="componentParts.unitSerialNumber" defaultMessage="Unit Serial Number" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.ComponentPartId} data-unittest="componentPartsData" className={n.UnitSerialNumber === '' ? s.spare : s.allocated}>
                <td>{n.Brand || ''}</td>
                <td>{n.Model || ''}</td>
                <td>{n.SerialNumber}</td>
                <td className={'d-none d-md-table-cell '}>{n.ComponentTypeName || ''}</td>
                <td className="d-none d-md-table-cell">{n.UnitSerialNumber}</td>
                <td>
                  {ViewAllowed && (
                    <div className={s.btnView}>
                      <Link to={`/maintenance/parts/part-details/${n.ComponentPartId}`}>
                        <Button variant="outline-secondary" className={s.viewButton}>
                          <FormattedMessage id="componentParts.view" defaultMessage="VIEW" />
                        </Button>
                      </Link>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ComponentPartsListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

ComponentPartsListTable.propTypes = {
  ViewAllowed: PropTypes.bool.isRequired,
  onSortOrder: PropTypes.func.isRequired,
  asendingOrder: PropTypes.bool.isRequired,
  paginatedData: PropTypes.array.isRequired
};

export default injectIntl(ComponentPartsListTable);
