export const getCompPositionLabel = comp => {
  let headerTitle = comp.Name;

  if (comp.IsCCP && comp.IsOCP) {
    headerTitle = `${headerTitle} (CCP)`;
  } else if (comp.IsCCP) {
    headerTitle = `${headerTitle} (CCP)`;
  } else if (comp.IsOCP) {
    headerTitle = `${headerTitle} (OCP)`;
  }

  return headerTitle;
};
