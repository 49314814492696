import React from 'react';
import s from './Calibration.module.scss';
import { FormattedMessage } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import InputNumber from '../WSAControls/InputNumber/InputNumber';

const CalibrationConstants = ({ calibrationManager, setCalibrationRequired, setCalibrationConstant, isViewMode, UpdateAllowed, intl }) => {
  const selectedCalibration = calibrationManager.SelectedCalibration;
  const labelZoomUnchecked = <FormattedMessage id="calibration.calibrationRequired.no" defaultMessage="No" />;
  const labelZoomChecked = <FormattedMessage id="calibration.calibrationRequired.yes" defaultMessage="Yes" />;
  const calibrationReqHandler = () => {
    setCalibrationRequired();
  };

  const saveCompConstHandler = calibrationComponentConstants => {
    let listData = selectedCalibration.CalibrationComponentConstants;

    if (calibrationComponentConstants.CalibrationComponentConstantId > 0) {
      const updatedData = listData.map(x =>
        x.CalibrationComponentConstantId === calibrationComponentConstants.CalibrationComponentConstantId
          ? { ...x, ...calibrationComponentConstants }
          : x
      );
      setCalibrationConstant({
        CalibrationComponentConstants: updatedData
      });
    } else {
      let hasItem = listData.filter(item => {
        if (item.CalibrationEquationConstantId === calibrationComponentConstants.CalibrationEquationConstantId) return item;
      });

      if (hasItem.length > 0) {
        const updatedData = listData.map(x =>
          x.CalibrationEquationConstantId === calibrationComponentConstants.CalibrationEquationConstantId
            ? { ...x, ...calibrationComponentConstants }
            : x
        );
        setCalibrationConstant({
          CalibrationComponentConstants: updatedData
        });
      } else {
        listData.push(calibrationComponentConstants);
        setCalibrationConstant({
          CalibrationComponentConstants: listData
        });
      }
    }
  };

  return (
    <div className={s.calibration}>
      <div className={s.contentWrapper}>
        <div className={s.calibrationConstants}>
          <Row className={s.standardRow}>
            <Col sm={6} lg={8}>
              <h4 className={s.subheading}>
                <FormattedMessage id="calibration.calibrationConstants" defaultMessage="Calibration Constants" />
              </h4>
            </Col>
            <Col sm={6} lg={4}></Col>
          </Row>
          <Row className={s.standardRow}>
            <Col md={2}>
              <Form.Label>
                <FormattedMessage id="calibration.calibrationEquation" defaultMessage="Calibration equation" />
              </Form.Label>
            </Col>
            <Col md={10}>
              <span className={s.padleft}>{selectedCalibration.CalibrationEquationName}</span>
            </Col>
          </Row>
          <Row className={s.standardRow}>
            <Col md={2}>
              <Form.Label>
                <FormattedMessage id="calibration.calibrationFormula" defaultMessage="Calibration formula" />
              </Form.Label>
            </Col>
            <Col md={10}>
              <span className={s.padleft}>{selectedCalibration.CalibrationEquationFormula}</span>
            </Col>
          </Row>
          <Row className={s.standardRow}>
            <Col md={2}>
              <Form.Label>
                <FormattedMessage id="calibration.calibrationRequired" defaultMessage="Calibration required" />
              </Form.Label>
            </Col>
            <Col md={10}>
              {!isViewMode && (
                <ToggleSwitch
                  handleClick={calibrationReqHandler}
                  classname={s.switch}
                  checked={selectedCalibration.CalibrationRequired}
                  labelChecked={labelZoomChecked}
                  labelUnchecked={labelZoomUnchecked}
                />
              )}
              <span className={s.padleft}>
                {isViewMode && (selectedCalibration.CalibrationRequired ? labelZoomChecked : labelZoomUnchecked)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table variant className={s.innerTable}>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="calibration.calibrationConstantsName" defaultMessage="Name" />
                    </th>
                    <th>
                      <FormattedMessage id="calibration.calibrationConstantsDescription" defaultMessage="Description" />
                    </th>
                    <th>
                      <FormattedMessage id="calibration.calibrationConstantsValue" defaultMessage="Value" />
                    </th>
                    <th className={s.emptyCol}></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCalibration.CalibrationComponentConstants.map(compConst => {
                    if (!isViewMode) {
                      return (
                        <ComponentConstantsRow
                          key={compConst.GuidIdentifier}
                          compConstant={compConst}
                          saveCompConstants={saveCompConstHandler}
                        />
                      );
                    } else {
                      return <ComponentConstantsRowView key={compConst.GuidIdentifier} compConstant={compConst} />;
                    }
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CalibrationConstants;

const ComponentConstantsRow = ({ compConstant, saveCompConstants }) => {
  const onChange = e => {
    saveCompConstants({ ...compConstant, [e.target.name]: e.target.value, isChanged: true });
  };

  return (
    <tr>
      <td>
        <div>{compConstant.Name}</div>
      </td>
      <td>
        <div>{compConstant.Description}</div>
      </td>
      <td>
        <InputNumber
          name="ConstantValue"
          step=".1"
          onInputChange={onChange}
          customClassName={s.textConstInput}
          value={compConstant.ConstantValue}
          id="formConstValue"
        />
      </td>

      <td className={s.emptyCol}></td>
    </tr>
  );
};

const ComponentConstantsRowView = ({ compConstant }) => {
  return (
    <tr>
      <td>
        <div>{compConstant.Name}</div>
      </td>
      <td>
        <div>{compConstant.Description}</div>
      </td>
      <td>
        <div>{compConstant.ConstantValue}</div>
      </td>

      <td className={s.emptyCol}></td>
    </tr>
  );
};
