import {
  GET_ALL_UNITS,
  GET_ALL_UNITS_ERROR,
  UNIT_LOADING,
  SET_UNITS_CURRENT_PAGE,
  SET_UNITS_PAGE_FILTER,
  DESCRIBE_UNIT,
  SET_UNIT_IOT_INFO,
  SET_UNIT_TAGOUT,
  RESET_UNIT_MESSAGE,
  GET_ALL_UNIT_FILTERS,
  SET_UNIT_FILTERED_RESULTS,
  SET_UNIT_REGION_LIST,
  SET_UNIT_MANAGER_ENG_LIST,
  SET_UNIT_SETTINGS_LIST,
  UNIT_SETTINGS_CHANGE,
  UNIT_SETTINGS_ERROR,
  UNIT_SETTINGS_UPDATE_STATUS,
  UNIT_SETTINGS_LOADING
} from '../constants/index';

import { initialUnitManagerState } from './initialState';

const unitManager = (state = initialUnitManagerState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_UNITS: {
      const { unit } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          ...unit
        }
      };
    }

    case SET_UNITS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          currentPage
        }
      };
    }

    case SET_UNITS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          filter
        }
      };
    }

    case UNIT_LOADING: {
      const { status } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          isLoading: status
        }
      };
    }

    case GET_ALL_UNITS_ERROR: {
      const { unit } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          ...unit
        }
      };
    }

    case DESCRIBE_UNIT: {
      const { unit } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          selectedUnit: {
            ...state.unitManager.selectedUnit,
            ...unit.selectedUnit
          },
          isDataRendered: unit.isDataRendered ?? false,
          showBanner: unit.showBanner ?? false,
          displayMessageCode: unit.displayMessageCode ?? '',
          detailsSubmitted: unit.detailsSubmitted ?? false
        }
      };
    }

    case SET_UNIT_IOT_INFO: {
      const { unitIotInfo } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          unitIotInfo: unitIotInfo,
          isOpSuccessful: true
        }
      };
    }
    case SET_UNIT_TAGOUT: {
      const { unit, tagout } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          selectedUnit: {
            ...state.unitManager.selectedUnit,
            IsTaggedOut: tagout.IsTaggedOut,
            tagoutDetails: tagout
          },
          isLoading: unit.isLoading ?? false,
          displayMessageCode: unit.displayMessageCode ?? '',
          showBanner: unit.showBanner ?? false,
          isOpSuccessful: unit.isOpSuccessful ?? false
        }
      };
    }

    case RESET_UNIT_MESSAGE: {
      const { message } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          isLoading: false,
          isOpSuccessful: false,
          showBanner: false,
          displayMessageCode: message
        }
      };
    }

    case GET_ALL_UNIT_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          ...data
        }
      };
    }
    case SET_UNIT_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          ...filteredResults
        }
      };
    }

    case SET_UNIT_REGION_LIST: {
      const { regionList } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          selectedUnit: {
            ...state.unitManager.selectedUnit,
            RegionList: regionList
          },
          isLoading: false,
          displayMessageCode: '',
          showBanner: false,
          isOpSuccessful: false
        }
      };
    }

    case SET_UNIT_MANAGER_ENG_LIST: {
      const { userList } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          selectedUnit: {
            ...state.unitManager.selectedUnit,
            UserList: userList
          },
          isLoading: false,
          displayMessageCode: '',
          showBanner: false,
          isOpSuccessful: false
        }
      };
    }

    case SET_UNIT_SETTINGS_LIST: {
      const { data } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          unitSetting: {
            ...state.unitManager.unitSetting,
            unitSettings: data.unitSettings,
            isLoading: false,
            displayMessageCode: '',
            showBanner: false,
            isOpSuccessful: false
          }
        }
      };
    }
    case UNIT_SETTINGS_CHANGE: {
      const { id, value } = payload;

      let unitsettings = (state.unitManager.unitSetting && state.unitManager.unitSetting.unitSettings) || [];
      let unitSettingIndex = unitsettings.findIndex(x => x.UnitSettingId === id);
      unitsettings[unitSettingIndex].Value = value;

      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          unitSetting: {
            ...state.unitManager.unitSetting,
            unitSettings: unitsettings,
            isLoading: false,
            displayMessageCode: '',
            showBanner: false,
            isOpSuccessful: false
          }
        }
      };
    }

    case UNIT_SETTINGS_UPDATE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          unitSetting: {
            ...state.unitManager.unitSetting,
            ...data
          }
        }
      };
    }
    case UNIT_SETTINGS_LOADING: {
      const { status } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          unitSetting: {
            ...state.unitManager.unitSetting,
            isLoading: status
          }
        }
      };
    }

    case UNIT_SETTINGS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          unitSetting: {
            ...state.unitManager.unitSetting,
            ...data
          }
        }
      };
    }

    default:
      return state;
  }
};

export default unitManager;
