import { initialUnitServiceState } from './initialState';

import {
  UNITSERVICE_ERROR,
  GET_ALL_UNITSERVICE_DETAILS,
  UNITSERVICE_IS_LOADING,
  SET_UNITSERVICE_CURRENT_PAGE,
  SET_UNITSERVICE_PAGE_FILTER,
  SET_UNITSERVICE_CHANGE,
  SET_UNITSERVICE,
  UPDATE_UNITSERVICE_SAVE_STATUS,
  SET_UNITSERVICE_FILTERED_RESULTS,
  UNIT_SERVICING_COMPONENT_PARTS,
  SET_UNITSERVICE_FILTERS,
  UNITSERVICE_CLEANUP,
  START_UNITSERVICE_EXPORT,
  SET_UNITSERVICE_EXPORT_DATA,
  SET_COMPUTE_UNITSERVICE_EXPORT
} from '../constants/index';

const unitServiceReducer = (state = initialUnitServiceState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_UNITSERVICE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          ...data
        }
      };
    }

    case SET_UNITSERVICE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          currentPage
        }
      };
    }

    case SET_UNITSERVICE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          filterBy: {
            ...state.unitService.filterBy,
            ...filter
          }
        }
      };
    }

    case UPDATE_UNITSERVICE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          ...data
        }
      };
    }

    case UNITSERVICE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          isLoading: isLoading
        }
      };
    }

    case UNITSERVICE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          ...data
        }
      };
    }

    case SET_UNITSERVICE: {
      let { Tasks, SelectedUnitService } = payload;

      return {
        ...state,
        unitService: {
          ...state.unitService,
          isOpSuccessful: false,
          showBanner: false,
          Tasks: Tasks,
          selectedUnitService: {
            ...state.unitService.selectedUnitService,
            ...SelectedUnitService
          }
        }
      };
    }

    case SET_UNITSERVICE_CHANGE: {
      let { selectedUnitService } = payload;

      return {
        ...state,
        unitService: {
          ...state.unitService,
          isOpSuccessful: false,
          showBanner: false,
          selectedUnitService: {
            ...state.unitService.selectedUnitService,
            ...selectedUnitService
          }
        }
      };
    }

    case SET_UNITSERVICE_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          ...filteredResults
        }
      };
    }

    case UNIT_SERVICING_COMPONENT_PARTS: {
      const { selectedUnitService } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          isOpSuccessful: false,
          showBanner: false,
          selectedUnitService: {
            ...state.unitService.selectedUnitService,
            ...selectedUnitService
          }
        }
      };
    }

    case SET_UNITSERVICE_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          ...data
        }
      };
    }

    case UNITSERVICE_CLEANUP: {
      const { unitService } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          ...unitService
        }
      };
    }
    case START_UNITSERVICE_EXPORT: {
      return {
        ...state,
        unitService: {
          ...state.unitService,
          exportedResults: [],
          exportComplete: false,
          exportCompute: false,
          isLoading: true
        }
      };
    }
    case SET_UNITSERVICE_EXPORT_DATA: {
      const { data } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          ...data
        }
      };
    }
    case SET_COMPUTE_UNITSERVICE_EXPORT: {
      const { status } = payload;
      return {
        ...state,
        unitService: {
          ...state.unitService,
          exportCompute: status,
          isLoading: false
        }
      };
    }

    default:
      return state;
  }
};

export default unitServiceReducer;
