import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import s from './SearchBar.module.scss';
import searchIcon from '../../assets/search-icon.svg';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';

const SearchBar = props => {
  const [searchQuery, setSearchQuery] = useState('');
  const searchCssSize = 10;
  const clearSearchCssSize = 2;

  const updateSearchQuery = event => {
    let query = event.target.value;
    setSearchQuery(query);
    props.searchHandler(query);
  };

  const searchClearQuery = () => {
    setSearchQuery('');
    searchData('');
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      searchData(searchQuery);
    }
  };

  const searchData = paramQuery => {
    props.searchHandler(paramQuery);
  };

  const onTypeChange = value => {
    props.onFilterTypeChange(value);
  };

  const searchText = props.intl.formatMessage({
    id: props.placeHolderTextId || 'profile.search',
    defaultMessage: 'Search'
  });
  const searchClear = props.intl.formatMessage({
    id: 'profile.searchclear',
    defaultMessage: 'Clear'
  });

  return (
    <div className={s.searchContainer}>
      <Row>
        <Col xs={searchCssSize}>
          <div className="form-group has-icon">
            <span className={`form-control-icon ${s.searchBarIcon}`}>
              <img src={searchIcon} alt="icon"></img>
            </span>
            <div className={s.searchtextboxes}>
              {props.showFilterType && (
                <div className={s.searchType}>
                  <Dropdown
                    id="drpStatusId"
                    dataArray={props.filterTypeData}
                    controlData={{
                      placeholderText: props.filterPlaceholderText,
                      customClassName: ''
                    }}
                    onDropdownChange={onTypeChange}
                    selectedOption={props.filterTypeData.filter(option => option.value === props.searchfilterType)}
                    disabled={false}
                  />
                </div>
              )}
              <Form.Control
                type="text"
                placeholder={searchText}
                onChange={updateSearchQuery}
                onKeyPress={handleKeyPress}
                value={searchQuery}
                disabled={props.disabled || false}
                className={s.formControl}
                data-unittest="customSearhBar"
              />
            </div>
          </div>
        </Col>
        {//conditionally making it visible/invisible
        !props.clearSearchInVisible && searchQuery && searchQuery.length > 0 ? (
          <Col xs={clearSearchCssSize} className={s.paddingtop12}>
            <a className={s.clearLink} onClick={searchClearQuery}>
              {searchClear}
            </a>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

SearchBar.propTypes = {
  searchHandler: PropTypes.func.isRequired
};

export default injectIntl(SearchBar);
