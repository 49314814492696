import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SequenceVersion from './SequenceVersion';
import { getSequenceVersion, getUserProfileWidget, getCurrentTimezone, getModelVersionManager } from '../../selectors/index';
import {
  getSequenceVersionThunk,
  setCurrentPage,
  setFilteredDataThunk,
  sequenceVersionCleanUpThunk,
  setSequenceVersionChangeThunk,
  saveSequenceVersionThunk
} from '../../actions/sequenceVersion';

import { describeModelVersionThunk } from '../../actions/modelVersion';

const mapStateToProps = state => {
  return {
    sequenceVersionManager: getSequenceVersion(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state),
    modelVersionManager: getModelVersionManager(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSequenceVersions: modelVersionId => getSequenceVersionThunk('SEQUENCEVERSIONLIST', modelVersionId),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setFilteredData: filter => setFilteredDataThunk(filter),
      sequenceVersionCleanUp: sequenceVersion => sequenceVersionCleanUpThunk(sequenceVersion),
      describeModelVersion: modelVersionId => describeModelVersionThunk(modelVersionId, false, 'SEQUENCEVERSIONLIST'),
      saveSequenceVersion: (sequenceVersion, widgetCode) => saveSequenceVersionThunk(sequenceVersion, widgetCode),
      changeSequenceVersion: sequenceVersion => setSequenceVersionChangeThunk(sequenceVersion)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SequenceVersion);
