import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ComponentPositionModelList from './ComponentPositionModelList';
import { getAllModelComponentPositions, getComponentPositionsModel } from '../../selectors/index';
import {
  getComponentPositionsThunk,
  deleteComponentPositionThunk,
  saveComponentPositionThunk,
  setComponentPositionChangeThunk,
  setComponentPositionShowScreenStatusThunk,
  uploadComponentToS3UpdateShadowThunk
} from '../../actions/model';

const mapStateToProps = state => {
  return {
    modelComponentPositions: getAllModelComponentPositions(state),
    ComponentPositionsManager: getComponentPositionsModel(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getComponentPositions: modelId => getComponentPositionsThunk(modelId, null, null, 'MODELCOMPONENTPOSITIONLIST'),
      saveComponentPosition: componentPositionDetails =>
        saveComponentPositionThunk(componentPositionDetails, 'MODEL_COMPONENT_POSTSITON_ADD'),
      deleteComponentPosition: componentPositionDetails =>
        deleteComponentPositionThunk(componentPositionDetails, 'MODEL_COMPONENT_POSTSITON_DELETE'),
      setComponentPositionChange: componentPositionDetails => setComponentPositionChangeThunk(componentPositionDetails),
      setComponentPositionShowScreenStatus: status => setComponentPositionShowScreenStatusThunk(status),
      uploadComponentToS3UpdateShadow: modelId => uploadComponentToS3UpdateShadowThunk(modelId, 'MODEL_COMPONENT_POSTSITON_UPLOAD_FILE')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ComponentPositionModelList);
