import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Checklists from './Checklists';
import { getChecklist, getUserProfileWidget, getCurrentTimezone, getUserProfileUnit } from '../../selectors/index';
import {
  getChecklistThunk,
  setCurrentPage,
  setPageFilter,
  setChecklistFilteredDataThunk,
  getChecklistsFilterThunk
} from '../../actions/checklist';

const mapStateToProps = state => {
  return {
    checklist: getChecklist(state),
    userProfileWidgets: getUserProfileWidget(state),
    userUnits: getUserProfileUnit(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getChecklistsFilters: () => getChecklistsFilterThunk('CHECKLISTLIST'),
      getChecklists: filterObj => getChecklistThunk(filterObj, 'CHECKLISTLIST'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filterBy => setPageFilter(filterBy),
      setFilterData: currentPage => setChecklistFilteredDataThunk(currentPage)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Checklists);
