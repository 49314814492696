import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ModelComponent.module.scss';
import { injectIntl } from 'react-intl';

import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import ModelComponentTypeList from '../ModelComponentTypeList/ModelComponentTypeListContainer';
import ApprovedComponentList from '../ApprovedComponentList/ApprovedComponentListContainer';
import ModelComponentPositionList from '../ModelComponentPositionList/ModelComponentPositionListContainer';
import { getQuerystring } from '../../utils/index';

const ModelComponent = ({ userProfileWidgets, intl }) => {
  let posRef = useRef();
  let approvedRef = useRef();
  let list = getQuerystring('list');
  useLayoutEffect(() => {
    if (list === '1' && approvedRef.current) {
      window.scrollTo({
        top: approvedRef.current.offsetTop
      });
    } else if (list === '2' && posRef.current) {
      window.scrollTo({
        top: posRef.current.offsetTop
      });
    }
  }, [posRef, approvedRef, list]);

  return (
    <div className={s.modelComponent}>
      <AdminControlsContentTemplate selectedPage="manageComponents" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.modelComponentContent}>
            <ModelComponentTypeList />
            <div ref={approvedRef} />
            <ApprovedComponentList />
            <div ref={posRef} />
            <ModelComponentPositionList />
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ModelComponent.defaultProps = {};

ModelComponent.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired
};

export default injectIntl(ModelComponent);
