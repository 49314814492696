import React from 'react';
import s from './ProgressBar.module.scss';
const ProgressBar = ({ completed }) => {
  return (
    <div className={s.progressBar}>
      <div style={{ width: `${completed}%` }} className={s.fillerStyles}>
        <p style={{ width: `${completed}%` }} className={s.labelStyles}>{`${completed}%`}</p>
      </div>
    </div>
  );
};

export default ProgressBar;
