import React, { useState } from 'react';
import s from './NotificationTypeUser.module.scss';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'js-cookie';
import SearchBar from '../../SearchBar/SearchBar';
import Checkbox from '../../WSAControls/CheckboxContainer/Checkbox';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

const ProfileUsers = ({ userManager, highlightedProfile, onUserSelectionChange, notificationTypeUsers, notificationTypeId }) => {
  let usersObj = [];
  const [localUsers, setLocalUsers] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  if (userManager.users) {
    usersObj = userManager.users
      .filter(p => p.IsActive && !p.IsDeleted && p.selectedProfiles.includes(highlightedProfile.ProfileId))
      .map(user => {
        return {
          ...user,
          SKEY: user.UserId,
          label: user.FullName,
          value: user.UserId,
          isChecked: notificationTypeUsers.filter(x => x.UserId === user.UserId).length > 0
        };
      });
  }

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }

    let filteredUsers = usersObj.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    setLocalUsers(filteredUsers);
  };

  const getUsers = () => {
    if (!isFilter) {
      return usersObj;
    } else {
      return localUsers;
    }
  };

  const onCheckboxSelectionChange = (e, data) => {
    if (data.isChecked) {
      let selectedUser = {
        NotificationUserId: 0,
        NotificationTypeId: notificationTypeId,
        UserId: data.UserId,
        UpdatedBy: Cookies.get(`userid-${envName}`) || 0
      };
      notificationTypeUsers.push(selectedUser);
    } else {
      notificationTypeUsers = notificationTypeUsers.filter(selUser => selUser.UserId !== data.UserId);
    }
    onUserSelectionChange(notificationTypeUsers);
  };

  return (
    <div data-unittest="unit" className={s.userProfiles}>
      {userManager.isLoading && <LoadingSpinner />}
      <Row className={s.row}>
        <Col sm={6}>
          <SearchBar searchHandler={searchHandler} clearSearchInVisible={true} placeHolderTextId="notifications.userName" />
        </Col>
      </Row>

      <Row className={s.selectedProfilesNUsers}>
        <Col sm={12} className={s.selectedCols}>
          <Checkbox dataArray={getUsers()} onSelectionChange={onCheckboxSelectionChange} />
        </Col>
      </Row>

      <div></div>
    </div>
  );
};

ProfileUsers.defaultProps = {
  userManager: {},
  highlightedProfile: {}
};

ProfileUsers.propTypes = {
  onUserSelectionChange: PropTypes.func.isRequired
};

export default injectIntl(ProfileUsers);
