import React, { useState, useEffect } from 'react';
import s from './Profile.module.scss';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import help from '../../assets/help-grey.svg';
import ReactTooltip from 'react-tooltip';

const ProfileWidgets = ({ groupName, widgets, profileWidgets, onWidgetChange, isEdit, intl }) => {
  const [selectedWidgets, setSelectedWidgets] = useState(profileWidgets);
  const [activeWidgets, setActiveWidgets] = useState(widgets);
  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    setActiveWidgets(widgets);
    setSelectedWidgets(profileWidgets);
  }, [widgets, profileWidgets]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [1]);

  const toggle = () => {
    setCollapse(!collapse);
  };

  const onChange = e => {
    let selWidgets = selectedWidgets;
    let selValue = parseInt(e.target.value);
    if (e.target.checked) {
      selWidgets.push(selValue);
      onWidgetChange(selWidgets, false);
    } else {
      const removeIndex = selWidgets.indexOf(selValue);
      if (removeIndex > -1) {
        selWidgets.splice(removeIndex, 1);
      }
      onWidgetChange([selValue], true);
    }
    setSelectedWidgets(selWidgets);
  };

  const onSelectAllChange = e => {
    let selWidgets = selectedWidgets;
    let allSelectedWidgets = selectedWidgets;
    if (e.target.checked) {
      if (activeWidgets && activeWidgets.length > 0) {
        activeWidgets.map(widget => {
          let widgetId = widget.WidgetId;
          if (!allSelectedWidgets.includes(widgetId)) {
            selWidgets.push(widgetId);
          }
        });
      }
      onWidgetChange(selWidgets, false);
    } else {
      selWidgets = [];
      onWidgetChange(selectedWidgets, true);
    }
    setSelectedWidgets(selWidgets);
  };

  const selectAllChecked = selectedWidgets.length === activeWidgets.length;
  let tooltip = intl.formatMessage({ id: 'profile.minimumWidget', defaultMessage: 'Minumum required profile widget' });

  return (
    <div className={s.profileWidgetsContent}>
      <Row className={s.titleRows}>
        <Col lg={6} xs={12}>
          <Row>
            <img
              src={collapse ? collapseDown : collapseUp}
              alt="Collapse section"
              onClick={() => toggle()}
              aria-controls="widgets-section"
              aria-expanded={collapse}
            ></img>
            <h6>{groupName}</h6>
          </Row>
        </Col>
        <Col lg={6}>
          <div className="float-right row">
            <label className={s.container}>
              {' '}
              <span className={s.selectall}>Select all</span>{' '}
              <input type="checkbox" id="cbSelectAll" value="Select all" onChange={onSelectAllChange} checked={selectAllChecked} />
              <span className={s.checkmark} htmlFor="cbSelectAll"></span>
            </label>
          </div>
        </Col>
      </Row>
      <Collapse in={collapse}>
        <ul className={`${s.checkbox} ${s.innerComponent}`}>
          {activeWidgets.map(widget => {
            let widgetId = widget.WidgetId;
            widget.isChecked = false;
            if (selectedWidgets.includes(widgetId)) {
              widget.isChecked = true;
            }
            //NAV_HOME and NAV_SETTINGS are minimum require profile widgets
            if (widgetId === 22 || widgetId === 19) widget.requiredWidget = true;
            else widget.requiredWidget = false;
            return (
              <li key={'li' + widget.WidgetId + widget.isChecked}>
                <label className={s.container}>
                  {widget.Name || ''} {widget.requiredWidget && <img src={help} alt="icon" className={s.icon} data-tip={tooltip}></img>}
                  <input
                    type="checkbox"
                    id={widget.WidgetId}
                    value={widget.WidgetId}
                    onChange={onChange}
                    defaultChecked={widget.isChecked || widget.requiredWidget}
                    name={widget.WidgetId}
                    disabled={widget.requiredWidget}
                  />
                  <span className={s.checkmark} htmlFor={widget.WidgetId}></span>
                </label>
              </li>
            );
          })}
        </ul>
      </Collapse>
      <ReactTooltip />
    </div>
  );
};

ProfileWidgets.defaultProps = {
  widgets: [],
  profileWidgets: [],
  groupName: ''
};

ProfileWidgets.propTypes = {
  groupName: PropTypes.string.isRequired,
  widgets: PropTypes.array.isRequired,
  profileWidgets: PropTypes.array.isRequired,
  onWidgetChange: PropTypes.func.isRequired,
  filterText: PropTypes.string
};

export default injectIntl(ProfileWidgets);
