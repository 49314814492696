import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelVersionServicePlansList from './ModelVersionServicePlanList';
import { getModelVersionServicePlans } from '../../selectors/index';
import {
  getServicePlansThunk,
  describeServicePlanThunk,
  postServicePlanThunk,
  setServicePlanChangeThunk,
  setServicePlanShowScreenStatusThunk
} from '../../actions/modelVersion';
import { getChecklistTemplateThunk } from '../../actions/checklistTemplates';
import { getChecklistTemplate, getCurrentTimezone } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    servicePlans: getModelVersionServicePlans(state),
    checklistTemplate: getChecklistTemplate(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getModelVersionsServicePlans: (offset, limit, filter, modelVersionId) =>
        getServicePlansThunk(offset, limit, filter, modelVersionId, 'MODELVERSIONSERVICEPLANS'),
      saveModelVersionServicePlan: (modelVersionServicePlanId, widgetCode, isApply) =>
        postServicePlanThunk(modelVersionServicePlanId, widgetCode, isApply),
      describeModelVersionServicePlan: modelVersionServicePlanId =>
        describeServicePlanThunk(modelVersionServicePlanId, 'MODELVERSIONSERVICEPLANSEDIT'),
      changeModelVersionServicePlan: selectedModelVersion => setServicePlanChangeThunk(selectedModelVersion),
      setServicePlanShowScreenStatus: status => setServicePlanShowScreenStatusThunk(status),
      getChecklistTemplate: widgetCode => getChecklistTemplateThunk(widgetCode, true)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelVersionServicePlansList);
