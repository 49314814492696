import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './UnitServiceDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialUnitServiceState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Link, useParams, useHistory } from 'react-router-dom';
import bs from '../../styles/bootstrap-overrides.scss';
import UnitPartService from './UnitPartService';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { isRequired, getQuerystring, compareValues, getDateInDDMMYYYHHMMSSFormat } from '../../utils';
import { UNIT_SERVICE_STATUS, CHECKLIST_STATUS_COMPLETE } from '../../constants';
import Cookies from 'js-cookie';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';
import MaintenanceContentTemplate from '../Maintenance/MaintenanceContentTemplate';
import GoBack from '../WSAControls/GoBack/GoBack';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import {
  describeUnitService,
  setUnitServiceChange,
  saveUnitServiceThunk,
  getComponentPart,
  setAllComponentPartList
} from '../../actions/unitServices';
import { getUnitService, getCurrentTimezone, getUserProfileWidget, getUserProfileUnit, getServiceTypes } from '../../selectors/index';
import classNames from 'classnames';

import InputNumber from '../WSAControls/InputNumber/InputNumber';

const UnitServicesDetail = ({ intl }) => {
  let IsEdit = false;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formError, setFormError] = useState({});
  const [active, setActive] = useState(false);
  const [finalize, setFinalize] = useState(false);
  const [UnitServiceStatusMessage, setUnitServiceStatusMessage] = useState('');
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let { unitServiceId, checklistId } = useParams();
  unitServiceId = (unitServiceId && parseInt(unitServiceId)) || undefined;
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  IsEdit = unitServiceId != undefined;

  //selectors
  const unitService = useSelector(state => getUnitService(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const userProfileUnits = useSelector(state => getUserProfileUnit(state));
  const serviceTypes = useSelector(state => getServiceTypes(state));

  const changeAllowed = IsWidgetAccessible(userProfileWidgets, `UNIT_SERVICE_EDIT`);

  let messageId = (unitService && unitService.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const unitServiceName = (unitService.selectedUnitService ? unitService.selectedUnitService.ChecklistName : '') || '';
  const backToText = intl.formatMessage({ id: 'unitServiceDetail.backTo', defaultMessage: 'BACK TO' });

  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'unitServiceDetail.editServiceRecord', defaultMessage: 'Edit Service Record' });
  } else {
    heading = intl.formatMessage({ id: 'unitServiceDetail.creteServiceRecord', defaultMessage: 'Create a Service Record' });
  }

  const backLink = !IsEdit
    ? `/tasks-and-checklists/checklist-detail/${checklistId}?view=1`
    : `/maintenance/service-view/${checklistId}/${unitServiceId}`;

  //Checking permission and redirecting to unauth page if unauthorised
  if (IsEdit && !IsWidgetAccessible(userProfileWidgets, 'UNIT_SERVICE_EDIT')) {
    history.push('/unauth');
  }

  if (!IsEdit && !IsWidgetAccessible(userProfileWidgets, 'UNIT_SERVICE_ADDNEW')) {
    history.push('/unauth');
  }

  let widgetCode = '';

  if (unitServiceId) {
    widgetCode = 'UNIT_SERVICE_EDIT';
  } else {
    widgetCode = 'UNIT_SERVICE_ADDNEW';
  }

  useEffect(() => {
    dispatch(describeUnitService(unitServiceId, checklistId, widgetCode, orgId));
  }, [describeUnitService, dispatch, checklistId, unitServiceId, widgetCode, orgId]);

  useEffect(() => {
    if (!unitServiceId && !unitService.selectedUnitService.UnitId && unitService.selectedUnitService.ChecklistUnitId) {
      dispatch(
        setUnitServiceChange({
          selectedUnitService: {
            ...unitService.selectedUnitService,
            UnitId: unitService.selectedUnitService.ChecklistUnitId
          }
        })
      );
      dispatch(getComponentPart(unitService.selectedUnitService.ChecklistUnitId, widgetCode));
    }
  }, [
    setUnitServiceChange,
    dispatch,
    checklistId,
    unitServiceId,
    unitService.selectedUnitService.UnitId,
    unitService.selectedUnitService.ChecklistUnitId
  ]);

  useEffect(() => {
    if (!unitServiceId && unitService.selectedUnitService.UnitServiceStatus === UNIT_SERVICE_STATUS[0].name) {
      dispatch(
        setUnitServiceChange({
          selectedUnitService: {
            ...unitService.selectedUnitService,
            UnitServiceStatus: UNIT_SERVICE_STATUS[2].name
          }
        })
      );
    }
  }, [setUnitServiceChange, dispatch, checklistId, unitServiceId, unitService.selectedUnitService.UnitServiceStatus]);

  useEffect(() => {
    return () => {
      console.log('clean up');
      dispatch(
        setUnitServiceChange({
          selectedUnitService: {
            ...initialUnitServiceState.unitService.selectedUnitService,
            SelectedTasks: []
          }
        })
      );
    };
  }, []);
  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = { ...unitService.selectedUnitService };
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    saveData.ComponentParts = [];
    saveData.Tasks = [];
    saveData.WarrantyCost = saveData.WarrantyCost || 0;
    saveData.WarrantyHours = saveData.WarrantyHours || 0;
    saveData.NonWarrantyCost = saveData.NonWarrantyCost || 0;
    saveData.NonWarrantyHours = saveData.NonWarrantyHours || 0;

    if (!IsEdit) {
      saveData.GuidIdentifier = uuidv4();
    }

    dispatch(saveUnitServiceThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formError = {};

    let isValid = true;

    if (!unitService || !unitService.selectedUnitService || !unitService.selectedUnitService.UnitId) {
      formError.unit = intl.formatMessage({
        id: 'unitServiceDetail.unitMandatory',
        defaultMessage: 'Unit is a mandatory field'
      });
      isValid = false;
    }
    setFormError(formError);

    return isValid;
  };

  const onSave = unitPartService => {
    unitPartService.GuidIdentifier = unitPartService.GuidIdentifier ? unitPartService.GuidIdentifier : uuidv4();
    if (unitPartService.UnitPartServiceId) {
      if (
        unitService &&
        unitService.selectedUnitService &&
        unitService.selectedUnitService.UnitPartServices &&
        unitService.selectedUnitService.UnitPartServices.length > 0
      ) {
        let selectedUnitPart = unitService.selectedUnitService.UnitPartServices.find(
          elem => elem.UnitPartServiceId === unitPartService.UnitPartServiceId
        );
        if (selectedUnitPart) {
          selectedUnitPart.TaskId = unitPartService.TaskId;
          selectedUnitPart.TaskName = unitPartService.TaskName;
          selectedUnitPart.ServiceTypeId = unitPartService.ServiceTypeId;
          selectedUnitPart.ServiceTypeName = unitPartService.ServiceTypeName;
          selectedUnitPart.WarrantyCost = unitPartService.WarrantyCost || 0;
          selectedUnitPart.WarrantyHours = unitPartService.WarrantyHours || 0;
          selectedUnitPart.NonWarrantyHours = unitPartService.NonWarrantyHours || 0;
          selectedUnitPart.NonWarrantyCost = unitPartService.NonWarrantyCost || 0;
          selectedUnitPart.SerialNumber = unitPartService.SerialNumber;
          selectedUnitPart.ModelComponentPositionName = unitPartService.ModelComponentPositionName;
          selectedUnitPart.ComponentPartId = unitPartService.ComponentPartId;
          selectedUnitPart.IsEdit = 1;

          let unitPartServicesData = [
            ...unitService.selectedUnitService.UnitPartServices.map(el =>
              el.UnitPartServiceId === unitPartService.UnitPartServiceId ? selectedUnitPart : el
            )
          ];
          let unitServiceData = {
            ...unitService
          };

          unitServiceData.selectedUnitService.UnitPartServices = unitPartServicesData;
          dispatch(setUnitServiceChange(unitServiceData));
          return;
        }
      }
    } else {
      if (
        unitService &&
        unitService.selectedUnitService &&
        unitService.selectedUnitService.UnitPartServices &&
        unitService.selectedUnitService.UnitPartServices.length > 0
      ) {
        let selectedUnitPart = unitService.selectedUnitService.UnitPartServices.find(
          elem => elem.GuidIdentifier === unitPartService.GuidIdentifier
        );
        if (selectedUnitPart) {
          selectedUnitPart.TaskId = unitPartService.TaskId;
          selectedUnitPart.TaskName = unitPartService.TaskName;
          selectedUnitPart.SerialNumber = unitPartService.SerialNumber;
          selectedUnitPart.ServiceTypeId = unitPartService.ServiceTypeId;
          selectedUnitPart.ServiceTypeName = unitPartService.ServiceTypeName;
          selectedUnitPart.WarrantyCost = unitPartService.WarrantyCost || 0;
          selectedUnitPart.WarrantyHours = unitPartService.WarrantyHours || 0;
          selectedUnitPart.NonWarrantyHours = unitPartService.NonWarrantyHours || 0;
          selectedUnitPart.NonWarrantyCost = unitPartService.NonWarrantyCost || 0;
          selectedUnitPart.ModelComponentPositionName = unitPartService.ModelComponentPositionName;
          selectedUnitPart.ComponentPartId = unitPartService.ComponentPartId;
          selectedUnitPart.IsEdit = 0;
          let unitPartServicesData = [
            ...unitService.selectedUnitService.UnitPartServices.map(el => (el.TaskId === unitPartService.TaskId ? selectedUnitPart : el))
          ];
          let unitServiceData = {
            ...unitService
          };

          unitServiceData.selectedUnitService.UnitPartServices = unitPartServicesData;
          dispatch(setUnitServiceChange(unitServiceData));
          return;
        }
      }

      unitPartService.WarrantyCost = unitPartService.WarrantyCost || 0;
      unitPartService.WarrantyHours = unitPartService.WarrantyHours || 0;
      unitPartService.NonWarrantyHours = unitPartService.NonWarrantyHours || 0;
      unitPartService.NonWarrantyCost = unitPartService.NonWarrantyCost || 0;

      let unitPartServicesData = [];
      if (unitService.selectedUnitService.UnitPartServices.length > 0) {
        unitPartServicesData = [...unitService.selectedUnitService.UnitPartServices];
      }
      unitPartServicesData.push(unitPartService);

      let unitServiceData = {
        ...unitService
      };
      unitServiceData.selectedUnitService.UnitPartServices = unitPartServicesData;
      dispatch(setUnitServiceChange(unitServiceData));
    }
  };

  //on control value change
  const onChange = e => {
    unitService.selectedUnitService = {
      ...unitService.selectedUnitService,
      [e.target.name]: e.target.value
    };
    dispatch(setUnitServiceChange(unitService));
  };

  const onPartServiceRemove = part => {
    if (
      unitService &&
      unitService.selectedUnitService &&
      unitService.selectedUnitService.UnitPartServices &&
      unitService.selectedUnitService.UnitPartServices.length > 0
    ) {
      let unitPartServicesData = unitService.selectedUnitService.UnitPartServices.filter(el => el.TaskId !== part.TaskId);

      let unitServiceData = {
        ...unitService
      };

      unitServiceData.selectedUnitService.UnitPartServices = unitPartServicesData;
      dispatch(setUnitServiceChange(unitServiceData));
      return;
    }
  };

  const onPartServiceEdit = part => {};

  const onDropdownChange = e => {
    dispatch(setUnitServiceChange({ selectedUnitService: { UnitId: e.value } }));
    if (e.value) {
      dispatch(getComponentPart(e.value, widgetCode));
    } else {
      dispatch(setAllComponentPartList([]));
    }
  };

  const onUnitServiceStatusChange = e => {
    if (e.value === UNIT_SERVICE_STATUS[0].name && unitService.selectedUnitService.ChecklistStatus === CHECKLIST_STATUS_COMPLETE) {
      setUnitServiceStatusMessage(
        intl.formatMessage({
          id: 'unitServiceDetail.cannotBeSetToScheduled',
          defaultMessage: 'Unit service record with completed checklist cannot be set to scheduled status'
        })
      );
      return;
    } else if (e.value === UNIT_SERVICE_STATUS[2].name && unitService.selectedUnitService.ChecklistStatus !== CHECKLIST_STATUS_COMPLETE) {
      setUnitServiceStatusMessage(
        intl.formatMessage({
          id: 'unitServiceDetail.cannotBeSetToCompleted',
          defaultMessage: 'Unit service record with active checklist cannot be set to completed status'
        })
      );
      return;
    } else {
      setUnitServiceStatusMessage('');
    }

    unitService.selectedUnitService = {
      ...unitService.selectedUnitService,
      UnitServiceStatus: e.value
    };
    dispatch(setUnitServiceChange(unitService));
  };

  const redirect = () => {
    if (unitService.isOpSuccessful && !unitService.showBanner) {
      dispatch(
        setUnitServiceChange({
          selectedUnitService: {
            ...initialUnitServiceState.unitService.selectedUnitService,
            SelectedTasks: []
          }
        })
      );
      history.goBack();
      //history.push(backLink);
    }
  };

  const disableUnitAndPartsSelection = () => {
    if (IsEdit) return true;

    if (unitService.selectedUnitService.UnitPartServices.length > 0) return true;
    if (finalize) return true;
    if (unitService.selectedUnitService.ChecklistUnitId != null) return true;

    return false;
  };

  const unitOptions = () => {
    let unitData = [];

    if (userProfileUnits && userProfileUnits.userProfileUnits && userProfileUnits.userProfileUnits.length > 0) {
      userProfileUnits.userProfileUnits
        .filter(elem => elem.OrganisationId == orgId)
        .forEach(element => {
          unitData.push({
            ...element,
            label: element.Name,
            value: element.UnitId
          });
        });
    }

    let sortedData = unitData.sort(compareValues('label'));
    return [{ value: 0, label: 'Select' }, ...sortedData];
  };

  const unitServiceStatusOptions = () => {
    let unitServiceStatusData = [];
    Object.keys(UNIT_SERVICE_STATUS).map(element => {
      unitServiceStatusData.push({
        label: UNIT_SERVICE_STATUS[element].name,
        value: UNIT_SERVICE_STATUS[element].name
      });
    });
    return unitServiceStatusData;
  };

  const onFinalize = () => {
    setFinalize(true);
  };

  const handleCommand = () => {
    if (!validateInput()) {
      return;
    }
    setActive(true);
  };

  const handleConfirm = () => {
    onFinalize();
    handleClose();
  };

  const handleClose = () => setActive(false);

  const dialogTitle = intl.formatMessage({
    id: 'unitServiceDetail.confirmFinalize',
    defaultMessage: 'Do you wish to finalize this service?'
  });

  return (
    <div className={s.unitServiceDetail}>
      {unitService.isLoading && <LoadingSpinner />}
      {redirect()}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <Banner
        key={uuidv4()}
        failureText={messageText}
        showBanner={unitService.showBanner}
        status={unitService.isOpSuccessful}
        successText={messageText}
      />

      <MaintenanceContentTemplate selectedPage="service">
        <div className={s.contentWrapper}>
          <div className={s.unitServiceDetailHeader}>
            <GoBack defaultLink={`/maintenance/service-view/${checklistId}/${unitServiceId}`}>
              &lt; &nbsp;
              {`${backToText} ${unitServiceName.toUpperCase()}`}
            </GoBack>
            <h3 data-unittest="headingLabel">{heading}</h3>
          </div>
          <div className={s.unitServiceDetailContent}>
            <h4>
              <FormattedMessage id="unitServiceDetail.unitDetails" defaultMessage="Unit Details" />
            </h4>
            <Form className={s.viewRow}>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceChecklist">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.checklist" defaultMessage="Checklist" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{unitService.selectedUnitService.ChecklistName}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceDateTime">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.dateTime" defaultMessage="Date and time" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{getDateInDDMMYYYHHMMSSFormat(unitService.selectedUnitService.ServiceDateTime, currentTimezone)}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceUser">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.user" defaultMessage="User" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{unitService.selectedUnitService.UserName}</Col>
              </Row>

              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formGrpUnitService">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.unit" defaultMessage="Unit" />
                    </Form.Label>
                    <Dropdown
                      id="formUnit"
                      dataArray={unitOptions()}
                      controlData={{ placeholderText: 'Select', customClassName: formError.unit ? s.ddlError : '' }}
                      onDropdownChange={onDropdownChange}
                      data-unittest="formUnit"
                      disabled={disableUnitAndPartsSelection()}
                      selectedOption={unitOptions().filter(option => option.value === unitService.selectedUnitService.UnitId)}
                    />
                    <div className={s.warningMsg}>
                      * <FormattedMessage id="unitServiceDetail.unitWarningMsg" defaultMessage="Unit can not be changed once finalized" />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {formError && formError.unit && (
                <p role="alert" className={s.error}>
                  {formError.unit}
                </p>
              )}
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formGrpUnitService">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.serviceStatus" defaultMessage="Service Status" />
                    </Form.Label>
                    <Dropdown
                      id="drpUnitServiceStatus"
                      dataArray={unitServiceStatusOptions()}
                      onDropdownChange={e => onUnitServiceStatusChange(e)}
                      selectedOption={unitServiceStatusOptions().filter(
                        option => option.value === unitService.selectedUnitService.UnitServiceStatus
                      )}
                    />
                    <div className={s.statusMessage}>{UnitServiceStatusMessage}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formWarrantyCost">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.warrantyCost" defaultMessage="Warranty Cost" />
                    </Form.Label>
                    <InputNumber
                      className={`${s.formControl}`}
                      name="WarrantyCost"
                      step="1"
                      onInputChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'unitServiceDetail.warrantyCost',
                        defaultMessage: 'Warranty Cost'
                      })}
                      value={unitService.selectedUnitService.WarrantyCost || ''}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formWarrantyHours">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.warrantyHours" defaultMessage="Warranty Hours" />
                    </Form.Label>
                    <InputNumber
                      className={`${s.formControl}`}
                      name="WarrantyHours"
                      step="1"
                      onInputChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'unitServiceDetail.warrantyHours',
                        defaultMessage: 'Warranty Hours'
                      })}
                      value={unitService.selectedUnitService.WarrantyHours || ''}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formNonWarrantyCost">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.nonWarrantyCost" defaultMessage="Non-Warranty Cost" />
                    </Form.Label>
                    <InputNumber
                      className={`${s.formControl}`}
                      name="NonWarrantyCost"
                      step="1"
                      onInputChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'unitServiceDetail.nonWarrantyCost',
                        defaultMessage: 'Non-Warranty Cost'
                      })}
                      value={unitService.selectedUnitService.NonWarrantyCost || ''}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formNonWarrantyHours">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.nonWarrantyHours" defaultMessage="Non-Warranty Hours" />
                    </Form.Label>
                    <InputNumber
                      className={`${s.formControl}`}
                      name="NonWarrantyHours"
                      step="1"
                      onInputChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'unitServiceDetail.nonWarrantyHours',
                        defaultMessage: 'Non-Warranty Hours'
                      })}
                      value={unitService.selectedUnitService.NonWarrantyHours || ''}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
          <div className={s.unitServiceDetailContent}>
            <UnitPartService
              onRemove={onPartServiceRemove}
              selectedPartServices={unitService.selectedUnitService.UnitPartServices}
              checklistTasks={unitService.selectedUnitService.Tasks}
              componentParts={unitService.selectedUnitService.ComponentParts}
              serviceTypes={serviceTypes}
              isEdit={IsEdit}
              onSave={onSave}
            />
          </div>

          <div>
            <Form>
              <Button
                variant="primary"
                data-unittest="finalize"
                className={classNames(s.margin5, s.btnSaveChanges)}
                onClick={handleCommand}
                disabled={finalize}
                noValidate
              >
                <FormattedMessage id="unitServiceDetail.finalize" defaultMessage="Finalize" />
              </Button>

              <Button
                variant="primary"
                data-unittest="saveData"
                className={classNames(s.margin5, s.btnSaveChanges)}
                onClick={submitForm}
                noValidate
                disabled={!finalize}
              >
                <FormattedMessage id="unitServiceDetail.saveServiceRecord" defaultMessage="Save service record" />
              </Button>

              <Button variant="outline-secondary" className={s.btnCancel} onClick={() => history.goBack()}>
                <FormattedMessage id="unitServiceDetail.cancel" defaultMessage="Cancel" />
              </Button>
            </Form>
          </div>
        </div>
      </MaintenanceContentTemplate>
    </div>
  );
};

UnitServicesDetail.defaultProps = {
  unitService: {
    ...initialUnitServiceState.unitService
  },
  userProfileUnits: []
};

export default injectIntl(UnitServicesDetail);
