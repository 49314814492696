import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ComponentPart.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialComponentPartsState, initialUnitConfigurationState, initialModelVersionState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import Banner from '../Banner/Banner';
import Form from 'react-bootstrap/Form';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useHistory, Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import classNames from 'classnames';
import uuid from 'uuid';
import uuidv4 from 'uuid/v4';
import { isRequired, compareValues } from '../../utils';
import Cookies from 'js-cookie';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import MaintenanceContentTemplate from '../Maintenance/MaintenanceContentTemplate';

const ComponentPart = ({
  intl,
  componentParts,
  describeComponentPart,
  saveComponentPart,
  changeComponentPart,
  organisationManager,
  getOrganisations,
  getApprovedComponents,
  getSelectedUserProfileWidgets,
  allModelComponentPositions,
  allowedUnits,
  approvedComponents,
  unitConfiguration,
  getComponentPartsByUnit,
  getModelComponentPositions,
  modelVersionManager
}) => {
  let { ComponentPartId } = useParams();
  let history = useHistory();
  let IsEdit = false;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  let userId = Cookies.get(`userid-${envName}`) || 0;
  let filter = organisationManager.filter || '';
  let offset = organisationManager.offset || 0;
  let limit = organisationManager.limit || 1000;

  const [localformErrors, setFormErrors] = useState({});
  const [localPositions, setLocalPositions] = useState([]);

  let widgetCode = ComponentPartId ? 'PARTSEDIT' : 'PARTSADDNEW';
  let modelComponentTypeId = componentParts.selectedComponentPart.ModelComponentTypeId;
  let unitId = componentParts.selectedComponentPart.UnitId;
  let modelId = componentParts.selectedComponentPart.UnitModelId;
  let modelComponentPositions = modelVersionManager && modelVersionManager.selectedModelVersion.ModelComponentPositions;

  useEffect(() => {
    getOrganisations(offset, limit, filter, widgetCode);
  }, [getOrganisations, offset, limit, filter]);

  useEffect(() => {
    getApprovedComponents({ offset, limit, filter }, widgetCode);
  }, [getApprovedComponents, offset, limit, filter, widgetCode]);

  useEffect(() => {
    getComponentPartsByUnit(unitId, widgetCode);
  }, [getComponentPartsByUnit, unitId]);

  useEffect(() => {
    getModelComponentPositions(modelId, false, null, widgetCode);
  }, [getModelComponentPositions, modelId]);

  useEffect(() => {
    if (ComponentPartId) {
      IsEdit = true;
      describeComponentPart(ComponentPartId);
    } else {
      setLocalComponentPart({
        ...componentParts,
        selectedComponentPart: { ...initialComponentPartsState.componentParts.selectedComponentPart }
      });
    }
  }, [describeComponentPart, ComponentPartId]);

  useEffect(() => {
    if (IsEdit) {
      setLocalPositions(
        allModelComponentPositions.filter(x => x.ModelComponentTypeId === modelComponentTypeId && !x.IsVirtual && !x.IsDerived)
      );
    }
  }, [modelComponentTypeId]);

  useEffect(() => {
    return () => {
      setLocalComponentPart({
        ...componentParts,
        selectedComponentPart: { ...initialComponentPartsState.componentParts.selectedComponentPart },
        isOpSuccessful: false
      });
    };
  }, []);

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !componentParts ||
      !componentParts.selectedComponentPart ||
      !componentParts.selectedComponentPart.SerialNumber ||
      isRequired(componentParts.selectedComponentPart.SerialNumber, 1)
    ) {
      formErrors.SerialNumber = intl.formatMessage({
        id: 'componentParts.serialNumberMandatory',
        defaultMessage: 'Serial Number is mandatory'
      });
      isValid = false;
    }

    if (
      !componentParts ||
      !componentParts.selectedComponentPart ||
      !componentParts.selectedComponentPart.ApprovedComponentId ||
      isRequired(componentParts.selectedComponentPart.ApprovedComponentId, 1)
    ) {
      formErrors.ApprovedComponent = intl.formatMessage({
        id: 'componentParts.approvedComponentMandatory',
        defaultMessage: 'Approved Component is mandatory'
      });
      isValid = false;
    }

    //if Model component position is selected and Unit is not selected
    if (
      componentParts &&
      componentParts.selectedComponentPart &&
      componentParts.selectedComponentPart.ModelComponentPositionId &&
      !isRequired(componentParts.selectedComponentPart.ModelComponentPositionId, 1) &&
      (!componentParts ||
        !componentParts.selectedComponentPart ||
        !componentParts.selectedComponentPart.UnitId ||
        isRequired(componentParts.selectedComponentPart.UnitId, 1))
    ) {
      formErrors.UnitId = intl.formatMessage({
        id: 'componentParts.unitMandatory',
        defaultMessage: 'Unit Serial Number is mandatory if Model Component Position is selected'
      });
      isValid = false;
    }

    //if Unit is selected and Model component position is not selected
    if (
      componentParts &&
      componentParts.selectedComponentPart &&
      componentParts.selectedComponentPart.UnitId &&
      !isRequired(componentParts.selectedComponentPart.UnitId, 1) &&
      (!componentParts ||
        !componentParts.selectedComponentPart ||
        !componentParts.selectedComponentPart.ModelComponentPositionId ||
        isRequired(componentParts.selectedComponentPart.ModelComponentPositionId, 1))
    ) {
      formErrors.ModelComponentPositionId = intl.formatMessage({
        id: 'componentParts.modelComponentPositionMandatory',
        defaultMessage: 'Model Component Position is mandatory if Unit Serial Number is selected'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const setLocalComponentPart = currentState => {
    changeComponentPart(currentState);
  };

  const onChange = e => {
    setLocalComponentPart({
      ...componentParts,
      selectedComponentPart: { ...componentParts.selectedComponentPart, [e.target.name]: e.target.value }
    });
  };

  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = componentParts.selectedComponentPart;
    saveData.UpdatedBy = userId;
    saveData.OrganisationId = parseInt(organisationId);
    let widgetCode = 'PARTSEDIT';
    if (!IsEdit && saveData.ComponentPartId === 0) {
      saveData.ComponentPartId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'PARTSADDNEW';
    }
    //saving Model Version
    saveComponentPart(saveData, widgetCode);
  };

  let messageId = (componentParts && componentParts.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const selectedOrganisation =
    organisationManager &&
    organisationManager.organisations &&
    organisationManager.organisations.filter(x => x.OrganisationId === parseInt(organisationId));

  const unitsObj =
    allowedUnits &&
    allowedUnits.userProfileUnits &&
    allowedUnits.userProfileUnits.filter(x => x.OrganisationId === parseInt(organisationId));

  const onUnitDropdownChange = e => {
    let selectedUnit = unitsObj && unitsObj.filter(x => x.UnitId === e.value);
    let unitModelId = (selectedUnit.length > 0 && selectedUnit[0].ModelId) || null;

    setLocalComponentPart({
      ...componentParts,
      selectedComponentPart: {
        ...componentParts.selectedComponentPart,
        UnitId: e.value,
        UnitSerialNumber: e.label,
        ModelComponentPositionId: null,
        UnitModelId: unitModelId
      }
    });

    //filter model component positions based on model component type in selected approved component
    let selectedApprovedComponentId = componentParts.selectedComponentPart.ApprovedComponentId;

    if (selectedApprovedComponentId > 0) {
      let modelComponentTypeId =
        approvedComponents &&
        approvedComponents.approvedComponentList.filter(x => x.ApprovedComponentId === selectedApprovedComponentId)[0].ModelComponentTypeId;
      setLocalPositions(modelComponentPositions.filter(x => x.ModelComponentTypeId === modelComponentTypeId && !x.IsDerived));
    } else {
      setLocalPositions(modelComponentPositions.filter(x => !x.IsDerived));
    }
  };

  const onApprovedComponentDropdownChange = e => {
    setLocalComponentPart({
      ...componentParts,
      selectedComponentPart: {
        ...componentParts.selectedComponentPart,
        ApprovedComponentId: e.value,
        ComponentTypeName: e.ModelComponentType
      }
    });

    //filter global(All) component positions based on model component type in selected approved component
    let modelComponentTypeId =
      approvedComponents && approvedComponents.approvedComponentList.filter(x => x.ApprovedComponentId === e.value)[0].ModelComponentTypeId;

    if (unitId > 0) {
      setLocalPositions(modelComponentPositions.filter(x => x.ModelComponentTypeId === modelComponentTypeId && !x.IsDerived));
    } else {
      setLocalPositions(
        allModelComponentPositions.filter(x => x.ModelComponentTypeId === modelComponentTypeId && !x.IsDerived && !x.IsVirtual)
      );
    }
  };

  const onModelComponentPositionDropdownChange = e => {
    let modelComponentPositionId = e.value;
    if (modelComponentPositionId === 0) {
      modelComponentPositionId = null;
    }
    setLocalComponentPart({
      ...componentParts,
      selectedComponentPart: { ...componentParts.selectedComponentPart, ModelComponentPositionId: modelComponentPositionId }
    });
  };

  const faultyReqHandler = () => {
    setLocalComponentPart({
      ...componentParts,
      selectedComponentPart: { ...componentParts.selectedComponentPart, IsFaulty: !componentParts.selectedComponentPart.IsFaulty }
    });
  };

  const activeReqHandler = () => {
    setLocalComponentPart({
      ...componentParts,
      selectedComponentPart: { ...componentParts.selectedComponentPart, IsActive: !componentParts.selectedComponentPart.IsActive }
    });
  };

  const selectUnitText = intl.formatMessage({ id: 'componentParts.selectUnitSerialNumber', defaultMessage: 'Select Unit Serial Number' });

  const unitOptions = () => {
    let unitData = [];
    unitsObj &&
      unitsObj.forEach(element => {
        unitData.push({
          ...element,
          label: element.UnitSerialNumber,
          value: element.UnitId
        });
      });

    unitData.push({
      label: selectUnitText,
      value: 0
    });
    return unitData.sort(compareValues('label'));
  };

  const approvedComponentOptions = () => {
    let approvedComponentsData = [];
    approvedComponents &&
      approvedComponents.approvedComponentList.forEach(element => {
        approvedComponentsData.push({
          ...element,
          label: `${element.Brand} - ${element.Model}`,
          value: element.ApprovedComponentId
        });
      });

    approvedComponentsData.push({
      label: <FormattedMessage id="componentParts.selectApprovedComponent" defaultMessage="Select Approved Component" />,
      value: ''
    });

    return approvedComponentsData.sort(compareValues('label'));
  };

  const modelComponentsOptions = () => {
    let modelComponentsData = [];

    localPositions.forEach(element => {
      if (
        unitConfiguration.componentParts &&
        unitConfiguration.componentParts.componentPartsList.filter(x => x.ModelComponentPositionId === element.ModelComponentPositionId)
          .length === 1
      ) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }

      modelComponentsData.push({
        ...element,
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });

    modelComponentsData.push({
      label: <FormattedMessage id="componentParts.selectModleComponentPosition" defaultMessage="Select Model Component Position" />,
      value: 0
    });
    return modelComponentsData.sort(compareValues('label'));
  };

  const labelUnchecked = <FormattedMessage id="common.no" defaultMessage="No" />;
  const labelChecked = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const ddlApprovedComponentError = localformErrors && localformErrors.ApprovedComponent ? s.ddlError : '';
  const ddlUnitError = localformErrors && localformErrors.UnitId ? s.ddlError : '';
  const ddlComponentPositionError = localformErrors && localformErrors.ModelComponentPositionId ? s.ddlError : '';
  if (componentParts.isOpSuccessful && !componentParts.showBanner) {
    if (componentParts.selectedComponentPart.ComponentTypeName === 'COMPUTER_BOARD_CONTROL-SYSTEM') {
      //Refreshing all page to refresh unit, if SBC is updated
      setTimeout(() => {
        let selectedProfileid = Cookies.get(`selectedprofileid-${envName}`) || 0;
        let userName = Cookies.get(`username-${envName}`) || '';
        getSelectedUserProfileWidgets(selectedProfileid, userName);
      }, 1000);
    }
    history.goBack();
  }

  return (
    <div className={s.componentParts}>
      <MaintenanceContentTemplate selectedPage="parts">
        {/* <div className={s.fullWidth}>
          <div className={s.fullWidthWrapper}>
            <h1 className={s.tabHeader}>{<FormattedMessage id="componentParts.parts" defaultMessage="Parts" />}</h1>
          </div>
        </div> */}

        <div className={s.pageContent}>
          <div>
            {componentParts.isLoading && <LoadingSpinner />}
            <div className={s.contentWrapper}>
              <Banner
                key={uuid()}
                failureText={messageText}
                showBanner={componentParts.showBanner}
                status={componentParts.isOpSuccessful}
                successText={messageText}
              />

              <div className={s.componentPartHeader}>
                <Link className={s.backLink} to={'/maintenance/parts'}>
                  &lt; &nbsp;
                  <FormattedMessage id="componentParts.backToParts" defaultMessage="BACK TO PARTS" />
                </Link>
                <Row>
                  <Col md={6} xs={12}>
                    <h3>
                      {componentParts.selectedComponentPart.ComponentPartId === 0 ? (
                        <FormattedMessage id="componentParts.addNewPart" defaultMessage="Add New Part" />
                      ) : (
                        <FormattedMessage id="componentParts.editPart" defaultMessage="Edit Part" />
                      )}
                    </h3>
                  </Col>
                </Row>
              </div>
              <div className={`${s.componentPartContent}`}>
                <Form>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formSerialNumber">
                        <Form.Label>
                          <FormattedMessage id="componentParts.serialNumber" defaultMessage="Serial Number" />
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="SerialNumber"
                          onChange={onChange}
                          value={componentParts.selectedComponentPart.SerialNumber}
                          className={`${s.formControl} ${localformErrors && localformErrors.SerialNumber ? s.formControlError : ''}`}
                        />
                        <p className={s.serialNumberInfo}>
                          <FormattedMessage
                            id="componentParts.thingNameSerialNumberForSBC"
                            defaultMessage="Serial number must be IoT Thing name for SBC"
                          />
                        </p>
                        {localformErrors && localformErrors.SerialNumber && (
                          <p role="alert" className={s.error}>
                            {localformErrors.SerialNumber}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formLocation">
                        <Form.Label>
                          <FormattedMessage id="componentParts.location" defaultMessage="Location" />
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="Location"
                          onChange={onChange}
                          value={componentParts.selectedComponentPart.Location}
                          className={`${s.formControl}`}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formFaulty">
                        <Form.Label>
                          <FormattedMessage id="componentParts.isFaulty" defaultMessage="Is Faulty" />
                        </Form.Label>

                        <ToggleSwitch
                          handleClick={faultyReqHandler}
                          classname={s.switch}
                          checked={componentParts.selectedComponentPart.IsFaulty}
                          labelChecked={labelChecked}
                          labelUnchecked={labelUnchecked}
                          labelPosition="Right"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formFaulty">
                        <Form.Label>
                          <FormattedMessage id="componentParts.isActive" defaultMessage="Is Active" />
                        </Form.Label>

                        <ToggleSwitch
                          handleClick={activeReqHandler}
                          classname={s.switch}
                          checked={componentParts.selectedComponentPart.IsActive}
                          labelChecked={labelChecked}
                          labelUnchecked={labelUnchecked}
                          labelPosition="Right"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formOrganisation">
                        <Form.Label>
                          <FormattedMessage id="componentParts.organisation" defaultMessage="Organisation" />
                        </Form.Label>
                        <br />
                        <label>{selectedOrganisation.length > 0 && selectedOrganisation[0].Name}</label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formApprovedComponent">
                        <Form.Label>
                          <FormattedMessage id="componentParts.approvedComponent" defaultMessage="Approved Component" />
                        </Form.Label>

                        <Dropdown
                          id="formApprovedComponent"
                          dataArray={approvedComponentOptions()}
                          controlData={{
                            placeholderText: (
                              <FormattedMessage id="componentParts.selectApprovedComponent" defaultMessage="Select Approved Component" />
                            ),
                            customClassName: ddlApprovedComponentError
                          }}
                          onDropdownChange={onApprovedComponentDropdownChange}
                          selectedOption={approvedComponentOptions().filter(
                            option => option.value === componentParts.selectedComponentPart.ApprovedComponentId
                          )}
                          data-unittest="formApprovedComponent"
                        />
                        {localformErrors && localformErrors.ApprovedComponent && (
                          <p role="alert" className={s.error}>
                            {localformErrors.ApprovedComponent}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formUnitSerialNumber">
                        <Form.Label>
                          <FormattedMessage id="componentParts.unitSerialNumber" defaultMessage="Unit Serial Number" />
                        </Form.Label>

                        <Dropdown
                          id="formUnitSerialNumber"
                          dataArray={unitOptions()}
                          controlData={{
                            placeholderText: (
                              <FormattedMessage id="componentParts.selectUnitSerialNumber" defaultMessage="Select Unit Serial Number" />
                            ),
                            customClassName: ddlUnitError
                          }}
                          onDropdownChange={onUnitDropdownChange}
                          selectedOption={unitOptions().filter(option => option.value === componentParts.selectedComponentPart.UnitId)}
                          data-unittest="formUnitSerialNumber"
                        />
                        {localformErrors && localformErrors.UnitId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.UnitId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formModelCompositionPosition">
                        <Form.Label>
                          <FormattedMessage id="componentParts.modelComponentPosition" defaultMessage="Model Component Position" />
                        </Form.Label>

                        <Dropdown
                          id="formModelCompositionPosition"
                          dataArray={modelComponentsOptions()}
                          controlData={{
                            placeholderText: (
                              <FormattedMessage
                                id="componentParts.selectModleComponentPosition"
                                defaultMessage="Select Model Component Position"
                              />
                            ),
                            customClassName: ddlComponentPositionError
                          }}
                          onDropdownChange={onModelComponentPositionDropdownChange}
                          selectedOption={modelComponentsOptions().filter(
                            option => option.value === componentParts.selectedComponentPart.ModelComponentPositionId
                          )}
                          data-unittest="formModelCompositionPosition"
                        />
                        {localformErrors && localformErrors.ModelComponentPositionId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.ModelComponentPositionId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <div>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.savebutton)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="componentParts.save" defaultMessage="Save" />
                    </Button>
                    <Button variant="outline-secondary" className={s.btnCancel} onClick={() => history.goBack()}>
                      <FormattedMessage id="componentParts.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </MaintenanceContentTemplate>
    </div>
  );
};

ComponentPart.defaultProps = {
  componentParts: {
    ...initialComponentPartsState.componentParts
  },
  allowedUnits: {
    userProfileUnits: []
  },
  organisationManager: {},
  unitConfiguration: {
    ...initialUnitConfigurationState
  },
  modelVersionManager: {
    ...initialModelVersionState
  }
};

ComponentPart.propTypes = {
  componentParts: PropTypes.object.isRequired,
  describeComponentPart: PropTypes.func.isRequired,
  saveComponentPart: PropTypes.func.isRequired,
  changeComponentPart: PropTypes.func.isRequired,
  getOrganisations: PropTypes.func.isRequired,
  getApprovedComponents: PropTypes.func.isRequired,
  allModelComponentPositions: PropTypes.array.isRequired,
  oragnisationManager: PropTypes.object,
  allowedUnits: PropTypes.object.isRequired,
  approvedComponents: PropTypes.object.isRequired,
  unitConfiguration: PropTypes.object.isRequired,
  getComponentPartsByUnit: PropTypes.func.isRequired,
  getModelComponentPositions: PropTypes.func.isRequired,
  modelVersionManager: PropTypes.object.isRequired
};

export default injectIntl(ComponentPart);
