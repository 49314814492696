import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelFirmwareVersionList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelFirmwareVersionState } from '../../reducers/initialState';
import { useParams, Link } from 'react-router-dom';
import ModelFirmwareVersionListTable from '../ModelFirmwareVersionList/ModelFirmwareVersionListTable';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { WidgetVisibility } from '../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { compareValues } from '../../utils';
import Cookies from 'js-cookie';

const ModelFirmwareVersionList = ({
  getModelFirmwareVersions,
  setPageFilter,
  modelFirmwareVersionManager,
  intl,
  userProfileWidgets,
  currentTimezone
}) => {
  let { ModelId } = useParams();
  let filter = modelFirmwareVersionManager.filter || '';
  let offset = modelFirmwareVersionManager.offset || 0;
  let limit = modelFirmwareVersionManager.limit || 1000;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [searchText, setSearchText] = useState('');

  let initialLimit = 6;
  let initialRecords = modelFirmwareVersionManager.modelFirmwareVersions.slice(0, initialLimit);
  const localSettingLength = localSettings.length;
  const modelFirmwareVersionLength = modelFirmwareVersionManager.modelFirmwareVersions.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(modelFirmwareVersionManager.modelFirmwareVersions);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = modelFirmwareVersionManager.modelFirmwareVersions.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const filterData = () => {
    let filteredList = modelFirmwareVersionManager.modelFirmwareVersions;
    if (isFilter) {
      filteredList = modelFirmwareVersionManager.modelFirmwareVersions.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getTableData = () => {
    let tableData = [];
    if (showAll) {
      tableData = filterData();
    } else {
      if (isFilter) {
        tableData = localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        tableData = initialRecords;
      }
    }
    return tableData.sort(compareValues('ModelFirmwareVersionId', 'desc'));
  };

  useEffect(() => {
    if (ModelId) {
      getModelFirmwareVersions(ModelId, offset, limit, filter);
    }
  }, [getModelFirmwareVersions, ModelId, offset, limit, filter]);

  let showAllText = intl.formatMessage({ id: 'model.modelFirmwareVersion.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'model.modelFirmwareVersion.showLess', defaultMessage: 'SHOW LESS' });

  let messageId = (modelFirmwareVersionManager && modelFirmwareVersionManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isAddAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELFIRMWAREVERSIONADD');
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELFIRMWAREVERSIONUPDATE');

  return (
    <div className={s.modelFirmwareVersion}>
      {modelFirmwareVersionManager.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={modelFirmwareVersionManager.showBanner}
        status={modelFirmwareVersionManager.isOpSuccessful}
        successText={messageText}
      />
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              initialText={filter}
              placeHolderTextId="modelFirmwareVersion.modelFirmwareVersion"
            />
          </Col>
          <Col sm={6} lg={8}>
            <Link to={`/admin-controls/model-configuration/${ModelId}/firmware/add`}>
              <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `MODELFIRMWAREVERSIONADD`)}>
                + <FormattedMessage id="modelFirmwareVersion.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Link>
          </Col>
        </Row>

        <div className="">
          <ModelFirmwareVersionListTable
            FirmwareVersionData={getTableData()}
            UpdateAllowed={isUpdateAllowed}
            currentTimezone={currentTimezone}
            ModelId={ModelId}
          />
        </div>
        {((!isFilter && modelFirmwareVersionLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? modelFirmwareVersionLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

ModelFirmwareVersionList.defaultProps = {
  ...initialModelFirmwareVersionState
};

ModelFirmwareVersionList.propTypes = {
  getModelFirmwareVersions: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(ModelFirmwareVersionList);
