import Cookies from 'js-cookie';
import { apiAction } from '../utils/index';

import {
  TELEMETRYPARTS_ERROR,
  GET_ALL_TELEMETRYPARTS_DETAILS,
  TELEMETRYPARTS_IS_LOADING,
  SET_TELEMETRYPARTS_CURRENT_PAGE,
  SET_TELEMETRYPARTS_PAGE_FILTER,
  SET_TELEMETRYPARTS_FILTERED_RESULTS,
  SET_TELEMETRYPARTS_FILTERS,
  TELEMETRYPARTS_CLEANUP,
  START_TELEMETRYPARTS_EXPORT,
  SET_TELEMETRYPARTS_EXPORT_DATA,
  SET_COMPUTE_TELEMETRYPARTS_EXPORT
} from '../constants/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setTelemetryPartsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setTelemetryPartsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTelemetryPartsError(messageCode, false));
  }, 2500);
};

export const setTelemetryPartsError = (messageCode, status) => {
  return {
    type: TELEMETRYPARTS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || '',
        exportedResults: [],
        exportComplete: false,
        exportCompute: false
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: TELEMETRYPARTS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getTelemetryPartsThunk = (filterObj, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getTelemetryParts(filterObj, widgetCode, orgId));
};

export const getTelemetryParts = (filterObj, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/maintenance/telemetry/telemetryparts/list`,
    data: {
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      filterUnitId: filterObj.filterUnitId,
      filterBrand: filterObj.filterBrand,
      filterModel: filterObj.filterModel,
      filterComponentTypeId: filterObj.filterComponentTypeId,
      filterComponentPosition: filterObj.filterComponentPosition,
      filterTelemetryType: filterObj.filterTelemetryType,
      filterStatus: filterObj.filterStatus,
      UserId: Cookies.get(`userid-${envName}`),
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllTelemetryPartsListThunk(retData),
    onFailure: err => setTelemetryPartsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllTelemetryPartsListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let telemetryparts = (retData && retData.TelemetryParts) || [];
  dispatch(setAllTelemetryPartsList(messageCode, telemetryparts));
};

export const setAllTelemetryPartsList = (messageCode, telemetryparts) => ({
  type: GET_ALL_TELEMETRYPARTS_DETAILS,
  payload: {
    data: {
      telemetryParts: telemetryparts,
      filteredResults: telemetryparts,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_TELEMETRYPARTS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_TELEMETRYPARTS_PAGE_FILTER,
  payload: { filter }
});

export const setTelemetryPartsFilteredDataThunk = retData => dispatch => {
  dispatch(setTelemetryPartsFilteredData(retData));
};

export const setTelemetryPartsFilteredData = retData => ({
  type: SET_TELEMETRYPARTS_FILTERED_RESULTS,
  payload: retData
});

export const getTelemetryPartsFilterThunk = (widgetCode, includeUnit = true) => dispatch => {
  dispatch(getTelemetryPartsFilter(widgetCode, includeUnit));
};

export const getTelemetryPartsFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      fUnits: true,
      fSensor: true,
      fApprovedComponentModels: true,
      fBrands: true,
      fComponentTypes: true,
      filterFor: 'telemetryparts'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllTelemetryPartsFilterThunk(retData),
    onFailure: err => setTelemetryPartsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllTelemetryPartsFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllTelemetryPartsFilter(messageCode, filterData, itemCount));
};

export const setAllTelemetryPartsFilter = (messageCode, filterData, itemCount) => ({
  type: SET_TELEMETRYPARTS_FILTERS,
  payload: {
    data: {
      filterData: {
        Units: filterData.Units,
        Brands: filterData.brands,
        Models: filterData.models,
        ComponentTypes: filterData.componentTypes,
        Positions: filterData.Sensors
      },
      isLoading: false,
      telemetryPartListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const cleanUpThunk = retData => dispatch => {
  dispatch(cleanUp(retData));
};

export const cleanUp = retData => ({
  type: TELEMETRYPARTS_CLEANUP,
  payload: retData
});

export const getExportDataThunk = (filterObj, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(startExport());
  dispatch(getExportData(filterObj, widgetCode, orgId));
};

export const getExportData = (filterObj, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/maintenance/telemetry/telemetryparts/list`,
    data: {
      ExportData: true,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      filterUnitId: filterObj.filterUnitId,
      filterBrand: filterObj.filterBrand,
      filterModel: filterObj.filterModel,
      filterComponentTypeId: filterObj.filterComponentTypeId,
      filterComponentPosition: filterObj.filterComponentPosition,
      filterTelemetryType: filterObj.filterTelemetryType,
      filterStatus: filterObj.filterStatus,
      UserId: Cookies.get(`userid-${envName}`),
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      OrganisationId: orgId
    },
    method: 'GET',
    // onLoad: status => startExport(status),
    onSuccess: retData => setExportDataThunk(retData),
    onFailure: err => setTelemetryPartsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const startExport = () => ({
  type: START_TELEMETRYPARTS_EXPORT
});

export const setExportCompleteThunk = status => dispatch => {
  dispatch(setExportComplete(status));
};

export const setExportComplete = status => ({
  type: SET_COMPUTE_TELEMETRYPARTS_EXPORT,
  payload: { status: status }
});

export const setExportDataThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let telemetryparts = (retData && retData.TelemetryParts) || [];
  dispatch(setExportData(messageCode, telemetryparts));
};

export const setExportData = (messageCode, Telemetryparts) => ({
  type: SET_TELEMETRYPARTS_EXPORT_DATA,
  payload: {
    data: {
      exportedResults: Telemetryparts,
      exportComplete: true,
      displayMessageCode: messageCode,
      isOpSuccessful: true
    }
  }
});

export const setTelemetryPartsExportErrorThunk = err => dispatch => {
  dispatch(setTelemetryPartsError(err, true));
  setTimeout(() => {
    dispatch(setTelemetryPartsError(err, false));
  }, 2500);
};
