import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import Form from 'react-bootstrap/Form';
import InputTemplate from '../InputTemplate/InputTemplate';
import s from './ChartInputs.module.scss';
import { injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MultiselectDropdown from '../WSAControls/MultiselectDropdownContainer/MultiselectDropdown';
import arrowDown from '../../assets/arrow-down.svg';
import Button from 'react-bootstrap/Button';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { timeOptions } from '../../utils/index';
import { getCompPositionLabel } from '../../utils/helperMethods';

const ChartInputs = ({
  dateFrom,
  dateTo,
  unitSerialNumber,
  id,
  sensors,
  sensorOptions,
  lowerThreshold,
  upperThreshold,
  updateChartValuesThottled,
  updateChartFilterValuesThottled,
  limit,
  resultType,
  currentTimezone,
  intl
}) => {
  const currentTimestamp = moment().tz(currentTimezone);
  const defaultTimeOption = (dateTo - dateFrom) / 1000;
  const [filterTime, setfilterTime] = useState(defaultTimeOption ? defaultTimeOption : 86400);
  const disableFutureDates = current => current.isBefore(currentTimestamp);

  const selectedOptions = sensors.map(sensor => ({ value: sensor, label: sensor }));
  const handleChange = opts => {
    const selectedOption = opts || [];
    const formatedOptions = selectedOption.map(option => option.value);
    updateChartValuesThottled(id, unitSerialNumber, 'sensors', formatedOptions);
  };

  const onLimitChange = (id, unitSerialNumber, limit, value) => {
    if (value > 0 && value < 1001) {
      updateChartValuesThottled(id, unitSerialNumber, limit, value);
    }
  };

  const onFromDateChange = value => {
    const toDate = value + filterTime * 1000;
    const data = {
      dateFrom: value,
      dateTo: toDate
    };
    updateChartFilterValuesThottled(id, unitSerialNumber, data);
  };

  const updateChartHandler = () => {
    const data = {
      updateChart: moment()
    };
    updateChartFilterValuesThottled(id, unitSerialNumber, data);
  };

  const nextPrevChange = type => {
    let fromDate = dateFrom;

    const nextDay = 86400000;
    if (type === 'next') {
      fromDate = fromDate + nextDay;
    } else {
      fromDate = fromDate - nextDay;
    }
    const toDate = fromDate + filterTime * 1000;

    const data = {
      dateFrom: fromDate,
      dateTo: toDate
    };
    updateChartFilterValuesThottled(id, unitSerialNumber, data);
  };

  const nextPrevHourChange = type => {
    let fromDate = dateFrom;
    const nextHour = filterTime * 1000;
    if (type === 'next') {
      fromDate = fromDate + nextHour;
    } else {
      fromDate = fromDate - nextHour;
    }
    const toDate = fromDate + filterTime * 1000;

    const data = {
      dateFrom: fromDate,
      dateTo: toDate
    };
    updateChartFilterValuesThottled(id, unitSerialNumber, data);
  };

  const ontimeOptionChange = e => {
    setfilterTime(e.value);
    const toDate = dateFrom + e.value * 1000;
    updateChartValuesThottled(id, unitSerialNumber, 'dateTo', toDate);
  };

  const getNextHourText = () => {
    const timeData = timeOptions() || [];
    const filteredData = timeData.find(x => x.value === filterTime);
    if (filteredData) {
      return filteredData.label;
    } else {
      return '';
    }
  };

  const intlSensor = intl.formatMessage({ id: 'chart.sensors', defaultMessage: 'Sensor(s)' });
  const intlDateTimeFrom = intl.formatMessage({ id: 'chart.datetimefrom', defaultMessage: 'Date/Time from' });
  const duration = intl.formatMessage({ id: 'chart.duration', defaultMessage: 'Duration' });
  const intlDataPlotLimit = intl.formatMessage({ id: 'chart.dataplotlimit', defaultMessage: 'Plot Point' });
  const intlLowerThreshold = intl.formatMessage({ id: 'chart.lowerthreshold', defaultMessage: 'Lower Threshold' });
  const intlUpperThreshold = intl.formatMessage({ id: 'chart.upperthreshold', defaultMessage: 'Upper Threshold' });

  const intlPrevNextDay = intl.formatMessage({ id: 'chart.prevNextDay', defaultMessage: 'Prev/Next Day' });
  const intlPrevNext = intl.formatMessage({ id: 'chart.prevNext', defaultMessage: 'Prev/Next' });
  const intUpdateChart = intl.formatMessage({ id: 'chart.updateChart', defaultMessage: 'Update Chart' });

  let sensorOptionsWithCCP = sensorOptions.map(comp => {
    return {
      ...comp,
      label: getCompPositionLabel(comp)
    };
  });

  return (
    <div>
      <div className={s.inputWrapper}>
        <Row className={s.row}>
          <Col lg={5}>
            <InputTemplate label={intlSensor}>
              <MultiselectDropdown
                selectedOptions={selectedOptions}
                handleChange={handleChange}
                sensorOptions={sensorOptionsWithCCP}
              ></MultiselectDropdown>
            </InputTemplate>
          </Col>
          <Col lg={2}>
            <InputTemplate label={intlDataPlotLimit}>
              <Form.Control
                type="number"
                step="100"
                value={limit}
                name="limit"
                onChange={e => onLimitChange(id, unitSerialNumber, 'limit', parseFloat(e.target.value))}
              />
            </InputTemplate>
          </Col>
          <Col lg={4}>
            <Row>
              <Col xl={6}>
                <InputTemplate label={intlLowerThreshold}>
                  <Form.Control
                    type="number"
                    step="0.1"
                    value={lowerThreshold}
                    name="lowerThreshold"
                    onChange={e => updateChartValuesThottled(id, unitSerialNumber, 'lowerThreshold', parseFloat(e.target.value))}
                  />
                </InputTemplate>
              </Col>
              <Col xl={6}>
                <InputTemplate label={intlUpperThreshold}>
                  <Form.Control
                    type="number"
                    step="0.1"
                    value={upperThreshold}
                    name="upperThreshold"
                    onChange={e => updateChartValuesThottled(id, unitSerialNumber, 'upperThreshold', parseFloat(e.target.value))}
                  />
                </InputTemplate>
              </Col>
            </Row>
          </Col>
          <Col lg={1}></Col>
        </Row>
      </div>
      <div className={s.inputWrapper}>
        <Row className={s.row}>
          <Col lg={3} md={4}>
            <InputTemplate label={intlDateTimeFrom}>
              <div className="form-group has-icon">
                <span className="form-control-icon">
                  <img src={arrowDown} alt="icon"></img>
                </span>
                <DateTime
                  isValidDate={disableFutureDates}
                  onChange={m => onFromDateChange(m.valueOf())}
                  timeFormat="HH:mm"
                  dateFormat="DD-MMM-YYYY"
                  value={moment(dateFrom).tz(currentTimezone)}
                  defaultValue={moment(dateFrom).tz(currentTimezone)}
                  closeOnSelect
                />
              </div>
            </InputTemplate>
          </Col>
          <Col lg={2} md={4} className={s.vAlign}>
            <InputTemplate label={duration}>
              <div className="form-group has-icon">
                <span className="form-control-icon">
                  <img src={arrowDown} alt="icon"></img>
                </span>
                <Dropdown
                  id="drpTimeId"
                  dataArray={timeOptions()}
                  controlData={{
                    customClassName: 'dropdown'
                  }}
                  onDropdownChange={e => ontimeOptionChange(e)}
                  selectedOption={timeOptions().filter(option => option.value === filterTime)}
                  disabled={false}
                />
              </div>
            </InputTemplate>
          </Col>
          <Col lg={2} md={4}>
            <div className={s.label}> {intlPrevNext + ' ' + getNextHourText()} </div>
            <Button className={s.next} onClick={() => nextPrevHourChange('prev')}>
              {'-'}
            </Button>
            <Button className={s.next} onClick={() => nextPrevHourChange('next')}>
              {'+'}
            </Button>
          </Col>
          <Col lg={2} md={4}>
            <div className={s.label}> {intlPrevNextDay} </div>
            <Button className={s.next} onClick={() => nextPrevChange('prev')}>
              {'<'}
            </Button>
            <Button className={s.next} onClick={() => nextPrevChange('next')}>
              {'>'}
            </Button>
          </Col>
          <Col lg={3} md={4} className={s.vAlign}>
            <Button onClick={() => updateChartHandler()}>{intUpdateChart}</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

ChartInputs.defaultProps = {
  currentTimezone: moment.tz.guess()
};

ChartInputs.propTypes = {
  unitSerialNumber: PropTypes.string.isRequired,
  dateFrom: PropTypes.number.isRequired,
  dateTo: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  lowerThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  upperThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sensors: PropTypes.array.isRequired,
  sensorOptions: PropTypes.array.isRequired,
  updateChartValuesThottled: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default React.memo(injectIntl(ChartInputs));
