import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelComponentType from './ModelComponentType';
import { getUserProfileWidget, getModelComponentType } from '../../selectors/index';

import {
  describeModelComponentTypeThunk,
  saveModelComponentTypeThunk,
  setModelComponentTypeChangeThunk,
  resetModelComponentThunk
} from '../../actions/modelComponentTypes';

const mapStateToProps = state => {
  return {
    modelComponentType: getModelComponentType(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveModelComponentType: (modelComponentType, widgetCode) => saveModelComponentTypeThunk(modelComponentType, widgetCode),
      describeModelComponentType: modelComponentTypeId => describeModelComponentTypeThunk(modelComponentTypeId, 'MODELCOMPONENTTYPE_EDIT'),
      changeModelComponentType: selectedModelComponentType => setModelComponentTypeChangeThunk(selectedModelComponentType),
      resetModelComponent: modelComponentType => resetModelComponentThunk(modelComponentType)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelComponentType);
