import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import s from './AddUnit.module.scss';
import { FormattedMessage } from 'react-intl';
import { initialUnitState } from '../../reducers/initialState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingSpinner from '..//LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { isRequired } from '../../utils/';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import moment from 'moment-timezone';
import Select from 'react-select';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import countryList from 'react-select-country-list';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { getUnit, getCurrentTimezone } from '../../selectors/index';
import { Redirect } from 'react-router-dom';
import {
  getModelsThunk,
  getSpareIotDevicesThunk,
  getApprovedComponentThunk,
  getOrganisationRegionThunk,
  getComponentPartThunk,
  getModelVersionsThunk,
  getUnitManagerAndEngineersThunk,
  getModelComponentPositionsThunk,
  setUnitChangeThunk,
  postUnitSaveThunk,
  setUnitCleanUpThunk
} from '../../actions/unit';
import ManagePartsList from './ManagePartsList';
import InputNumber from '../WSAControls/InputNumber/InputNumber';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;
const countries = countryList().getData();

const AddUnit = ({ intl }) => {
  let messageText = '';
  const dispatch = useDispatch();
  const unitManager = useSelector(state => getUnit(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const countries = countryList().getData();
  const orgId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  const userId = Cookies.get(`userid-${envName}`) || 0;
  const widgetCode = 'ADD_UNIT';
  const [formErrors, setFormErrors] = useState({ Name: false, CommissionGpsLatitude: false, CommissionGpsLongitude: false });
  const selectedUnit = unitManager.selectedUnit || {};
  const modelId = selectedUnit.ModelId || 0;

  useEffect(() => {
    dispatch(getModelsThunk(widgetCode));
  }, [getModelsThunk, dispatch, widgetCode]);

  useEffect(() => {
    dispatch(getSpareIotDevicesThunk(widgetCode));
  }, [getSpareIotDevicesThunk, dispatch, widgetCode]);

  useEffect(() => {
    dispatch(getApprovedComponentThunk(widgetCode));
  }, [getApprovedComponentThunk, dispatch, widgetCode]);

  useEffect(() => {
    if (orgId) {
      dispatch(getOrganisationRegionThunk(widgetCode, orgId));
    }
  }, [getOrganisationRegionThunk, dispatch, widgetCode, orgId]);

  useEffect(() => {
    if (orgId) {
      dispatch(getUnitManagerAndEngineersThunk(widgetCode, orgId));
    }
  }, [getUnitManagerAndEngineersThunk, dispatch, widgetCode, orgId]);

  useEffect(() => {
    if (orgId) {
      dispatch(getComponentPartThunk(orgId, widgetCode));
    }
  }, [getComponentPartThunk, dispatch, widgetCode, orgId]);

  useEffect(() => {
    if (orgId && modelId) {
      dispatch(getModelVersionsThunk(modelId, orgId, widgetCode));
    }
  }, [getModelVersionsThunk, dispatch, widgetCode, orgId, modelId]);

  useEffect(() => {
    if (modelId) {
      dispatch(getModelComponentPositionsThunk(modelId, widgetCode));
    }
  }, [getModelComponentPositionsThunk, dispatch, widgetCode, modelId]);

  useEffect(() => {
    if (!window.google) {
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
    }
  }, []);

  //clean up
  useEffect(() => {
    return () => {
      setCleanUpUnit({
        data: initialUnitState.unit
      });
    };
  }, []);

  const setCleanUpUnit = currentState => {
    dispatch(setUnitCleanUpThunk(currentState));
  };

  //change handlers

  const setLocalUnit = currentState => {
    dispatch(setUnitChangeThunk(currentState));
  };

  const addUpdatePartHandler = data => {
    let partsData =
      (unitManager.selectedUnit.BillOfMaterials && unitManager.selectedUnit.BillOfMaterials.filter(x => x.id !== data.id)) || [];
    partsData.push(data);
    dispatch(setUnitChangeThunk({ BillOfMaterials: partsData }));
  };

  const removePartHandler = id => {
    let partsData = (unitManager.selectedUnit.BillOfMaterials && unitManager.selectedUnit.BillOfMaterials.filter(x => x.id !== id)) || [];
    dispatch(setUnitChangeThunk({ BillOfMaterials: partsData }));
  };

  const handleAddressChange = e => {
    e.preventDefault();

    const latlng = {
      lat: parseFloat(selectedUnit.CommissionGpsLatitude || '0'),
      lng: parseFloat(selectedUnit.CommissionGpsLongitude || '0')
    };
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          let streetAddress = '';
          let city = '';
          let postCode = '';
          let region = '';
          let country = '';
          results[0].address_components.forEach(item => {
            if (item.types.includes('street_number')) {
              streetAddress = item.long_name;
            } else if (item.types.includes('route')) {
              streetAddress = `${streetAddress} ${item.long_name}`;
            } else if (item.types.includes('locality')) {
              city = item.long_name;
            } else if (item.types.includes('postal_code')) {
              postCode = item.long_name;
            } else if (item.types.includes('country')) {
              country = item.long_name;
            }
          });
          let regionFields = results[0].address_components.filter(
            x => x.types.includes('postal_town') || x.types.includes('administrative_area_level_1')
          );
          if (regionFields.length > 0) {
            region = regionFields[0].long_name;
          }
          setLocalUnit({
            InstallationStreetAddress: streetAddress,
            InstallationCity: city,
            InstallationRegion: region,
            InstallationPostCode: postCode,
            InstallationCountry: country,
            InstallationCountryCode: { value: countryList().getValue(country), label: country }
          });
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  };

  const handleInput = (e, data) => {
    const { name } = e.target;

    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalUnit({
        [name]: e.target.checked
      });
    } else {
      const { value } = e.target;
      setLocalUnit({
        [name]: value
      });
    }
  };

  const onRegionChange = e => {
    setLocalUnit({
      RegionId: e.value
    });
  };

  const onGenericDropdownChange = (e, fieldName) => {
    setLocalUnit({
      [fieldName]: e.value
    });
  };

  const onCountryChange = selectedCountry => {
    setLocalUnit({
      InstallationCountry: selectedCountry.label,
      InstallationCountryCode: selectedCountry
    });
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;
    if (!unitManager.selectedUnit.ModelId || isRequired(unitManager.selectedUnit.ModelId, 1)) {
      formErrors.ModelId = intl.formatMessage({
        id: 'unit.modelIsMandatory',
        defaultMessage: 'Model is mandatory'
      });
      formErrors.ModelIdErrorBorder = s.ddlError;
      isValid = false;
    }

    if (!unitManager.selectedUnit.ModelVersionId || isRequired(unitManager.selectedUnit.ModelVersionId, 1)) {
      formErrors.ModelVersionId = intl.formatMessage({
        id: 'unit.modelVersionIsMandatory',
        defaultMessage: 'Model version is mandatory'
      });
      formErrors.ModelVersionIdErrorBorder = s.ddlError;
      isValid = false;
    }

    if (!unitManager.selectedUnit.Name || isRequired(unitManager.selectedUnit.Name, 1)) {
      formErrors.Name = intl.formatMessage({
        id: 'unit.unitNameMandatory',
        defaultMessage: 'Unit name is mandatory'
      });
      formErrors.NameErrorBorder = s.formControlError;
      isValid = false;
    }

    if (!unitManager.selectedUnit.UnitNumber || isRequired(unitManager.selectedUnit.UnitNumber, 1)) {
      formErrors.UnitNumber = intl.formatMessage({
        id: 'unit.unitNumberMandatory',
        defaultMessage: 'Unit serial number is mandatory'
      });
      formErrors.UnitNumberErrorBorder = s.formControlError;
      isValid = false;
    }

    if (!unitManager.selectedUnit.DeviceSerialNumber || isRequired(unitManager.selectedUnit.DeviceSerialNumber, 1)) {
      formErrors.DeviceSerialNumber = intl.formatMessage({
        id: 'unit.deviceSerialNumberIsMandatory',
        defaultMessage: 'Device Serial Number is mandatory'
      });
      formErrors.DeviceSerialNumberErrorBorder = s.ddlError;
      isValid = false;
    }

    let warrantyCheckFailed = false;
    if (
      (unitManager.selectedUnit.WarrantyEndDate && !unitManager.selectedUnit.WarrantyStartDate) ||
      (!unitManager.selectedUnit.WarrantyEndDate && unitManager.selectedUnit.WarrantyStartDate)
    ) {
      formErrors.WarrantyStartDate = intl.formatMessage({
        id: 'unit.warrantyStartDateIsMandatory',
        defaultMessage: 'Warranty start date is required'
      });
      formErrors.WarrantyEndDate = intl.formatMessage({
        id: 'unit.warrantyEndDateDateIsMandatory',
        defaultMessage: 'Warranty end date is required'
      });
      formErrors.WarrantyStartDateErrorBorder = s.ddlError;
      formErrors.WarrantyEndDateErrorBorder = s.ddlError;
      isValid = false;
      warrantyCheckFailed = true;
    }

    if (
      !warrantyCheckFailed &&
      unitManager.selectedUnit.WarrantyEndDate &&
      unitManager.selectedUnit.WarrantyStartDate &&
      unitManager.selectedUnit.WarrantyEndDate < unitManager.selectedUnit.WarrantyStartDate
    ) {
      formErrors.WarrantyEndDate = intl.formatMessage({
        id: 'unit.warrantyEndDateIsInvalid',
        defaultMessage: 'Warranty end date cannot be earlier than start date'
      });
      formErrors.WarrantyStartDateErrorBorder = s.formControlError;
      isValid = false;
    }

    if (
      unitManager.selectedUnit.ManufactureDate &&
      unitManager.selectedUnit.InstallationDate &&
      unitManager.selectedUnit.ManufactureDate > unitManager.selectedUnit.InstallationDate
    ) {
      formErrors.ManufactureDate = intl.formatMessage({
        id: 'unit.manufactureDateIsInvalid',
        defaultMessage: 'Manufacture date cannot be after installation date'
      });
      formErrors.ManufactureDateErrorBorder = s.formControlError;
      isValid = false;
    }

    if (
      (unitManager.selectedUnit.ManufactureDate &&
        unitManager.selectedUnit.CommissionDate &&
        unitManager.selectedUnit.ManufactureDate > unitManager.selectedUnit.CommissionDate) ||
      (unitManager.selectedUnit.InstallationDate &&
        unitManager.selectedUnit.CommissionDate &&
        unitManager.selectedUnit.InstallationDate > unitManager.selectedUnit.CommissionDate)
    ) {
      formErrors.CommissionDate = intl.formatMessage({
        id: 'unit.commissionDateIsInvalid',
        defaultMessage: 'Commission date cannot be before installation date/manufacture date'
      });
      formErrors.CommissionDateErrorBorder = s.formControlError;
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };
  const handleSubmit = e => {
    if (!validateInput()) {
      return false;
    }

    let saveObj = {};

    let modelName = unitManager.unitDropdownList.models.find(x => x.ModelId == unitManager.selectedUnit.ModelId).Name;

    let unit = {
      user_id: userId,
      organization_id: orgId,
      id: 0,
      modelversionid: unitManager.selectedUnit.ModelVersionId,
      unit_id: unitManager.selectedUnit.Name,
      unit_serial_number: unitManager.selectedUnit.UnitNumber,
      device_serial_number: unitManager.selectedUnit.DeviceSerialNumber,
      timestamp: moment.unix().tz(currentTimezone),
      description: unitManager.selectedUnit.Description,
      model: modelName,
      appversion: 'alpha2'
    };
    let manufacture = {
      id: unitManager.selectedUnit.ManufactureId,
      date: unitManager.selectedUnit.ManufactureDate
    };

    let installation = {
      date: unitManager.selectedUnit.InstallationDate,
      status: unitManager.selectedUnit.InstallationStatus,
      engineer: unitManager.selectedUnit.InstallationEngineer,
      street_address: unitManager.selectedUnit.InstallationStreetAddress,
      city: unitManager.selectedUnit.InstallationCity,
      region: unitManager.selectedUnit.InstallationRegion,
      country: unitManager.selectedUnit.InstallationCountry,
      postcode: unitManager.selectedUnit.InstallationPostCode
    };

    let commission = {
      date: unitManager.selectedUnit.CommissionDate,
      status: unitManager.selectedUnit.CommissionStatus,
      unit_name: unitManager.selectedUnit.Name,
      gps_lat: unitManager.selectedUnit.CommissionGpsLatitude,
      gps_long: unitManager.selectedUnit.CommissionGpsLongitude,
      power_on_test: unitManager.selectedUnit.InstallationRegion,
      call_home: unitManager.selectedUnit.CommissionCallHome,
      warranty_start_date: unitManager.selectedUnit.WarrantyStartDate,
      warranty_end_date: unitManager.selectedUnit.WarrantyEndDate,
      unit_manager: unitManager.selectedUnit.UnitManagerId,
      unit_technician: unitManager.selectedUnit.UnitTechnicianId
    };
    let billOfMaterialLists = [];

    if (unitManager.selectedUnit.BillOfMaterials) {
      unitManager.selectedUnit.BillOfMaterials.forEach(element => {
        billOfMaterialLists.push({
          material_name: element.ModelComponentPositionName,
          serial_number: element.SerialNumber,
          approved_component_ref: element.ApprovedComponentRef,
          ComponentPartId: element.ComponentPartId || null
        });
      });
    }

    saveObj.unit = unit;
    saveObj.manufacture = manufacture;
    saveObj.installation = installation;
    saveObj.commission = commission;
    saveObj.bill_of_materials = billOfMaterialLists;

    dispatch(postUnitSaveThunk(saveObj, widgetCode));
  };

  const getFormattedate = date => {
    if (!date) return '';

    return moment.unix(date).tz(currentTimezone);
  };

  const getDateInDDMMYYYFormat = date => {
    if (!date) return '';
    return moment
      .unix(date)
      .tz(currentTimezone)
      .format('DD-MMM-YYYY');
  };

  let messageId = (unitManager && unitManager.displayMessageCode) || 'none';
  messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });
  let ddlClass = formErrors && formErrors.InstallationCountry ? s.ddlError : '';

  //dropdown data
  const modelList = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
      value: 0
    });

    unitManager.unitDropdownList.models.forEach(element => {
      itemData.push({
        label: element.Name,
        value: element.ModelId
      });
    });
    return itemData;
  };

  const deviceList = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
      value: 0
    });

    unitManager.unitDropdownList.deviceList.forEach(element => {
      itemData.push({
        label: element.thingName,
        value: element.thingName
      });
    });
    return itemData;
  };

  const modelVersionList = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
      value: 0
    });

    unitManager.unitDropdownList.modelVersions.forEach(element => {
      itemData.push({
        label: element.Name,
        value: element.ModelVersionId
      });
    });
    return itemData;
  };
  const userList = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
      value: 0
    });

    unitManager.unitDropdownList.userList.forEach(element => {
      itemData.push({
        label: element.FullName,
        value: element.UserId
      });
    });
    return itemData;
  };

  const regionList = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
      value: 0
    });

    unitManager.unitDropdownList.regionList.forEach(element => {
      itemData.push({
        label: element.Name,
        value: element.OrganisationRegionId
      });
    });
    return itemData;
  };

  return (
    <div className={s.unit}>
      {unitManager.isLoading && <LoadingSpinner />}
      {unitManager.isOpSuccessful && !unitManager.showBanner ? <Redirect to="/unit" /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={unitManager.showBanner}
        status={unitManager.isOpSuccessful}
        successText={messageText}
      />
      <div className={s.fullWidth}>
        <Row>
          <Col sm={6}>
            <div className={s.fullWidthWrapper}>
              <h1 className={s.tabHeader}>{<FormattedMessage id="unitListing.addUnit" defaultMessage="Add Units" />}</h1>
            </div>
          </Col>
        </Row>
      </div>
      <div className={`${s.innerComponent} mt-2`} id="unit-add-section">
        <div className={s.unitAdd}>
          <Form>
            <Row>
              <Col>
                <div className={s.buttonContainer}>
                  <Button disabled={unitManager.isLoading} onClick={handleSubmit} className={s.saveButton}>
                    <FormattedMessage id="unit.save" defaultMessage="Save" />
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="modelVersion.modelName" defaultMessage="Model" />
                </Form.Label>
                <Dropdown
                  id="drpUnitModelId"
                  dataArray={modelList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: formErrors.ModelIdErrorBorder || ''
                  }}
                  onDropdownChange={e => onGenericDropdownChange(e, 'ModelId')}
                  selectedOption={modelList().filter(option => option.value === unitManager.selectedUnit.ModelId)}
                  disabled={false}
                />
                {formErrors && formErrors.ModelId && (
                  <p role="alert" className={s.error}>
                    {formErrors.ModelId}
                  </p>
                )}
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="modelVersion.version" defaultMessage="Version" />
                </Form.Label>
                <Dropdown
                  id="drpUnitModelVersionId"
                  dataArray={modelVersionList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: formErrors.ModelVersionIdErrorBorder || ''
                  }}
                  onDropdownChange={e => onGenericDropdownChange(e, 'ModelVersionId')}
                  selectedOption={modelVersionList().filter(option => option.value === unitManager.selectedUnit.ModelVersionId)}
                  disabled={false}
                />
                {formErrors && formErrors.ModelVersionId && (
                  <p role="alert" className={s.error}>
                    {formErrors.ModelVersionId}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.unitName" defaultMessage="Unit Name" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="Name"
                  value={unitManager.selectedUnit.Name}
                  onChange={handleInput}
                  className={formErrors.NameErrorBorder || ''}
                />

                {formErrors.Name && (
                  <p role="alert" className={s.error}>
                    {formErrors.Name}
                  </p>
                )}
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.unitSerialNumber" defaultMessage="Unit Serial Number" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="UnitNumber"
                  onChange={handleInput}
                  value={unitManager.selectedUnit.UnitNumber}
                  className={formErrors.UnitNumberErrorBorder || ''}
                />
                {formErrors.UnitNumber && (
                  <p role="alert" className={s.error}>
                    {formErrors.UnitNumber || ''}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.deviceSerialNumber" defaultMessage="Device Serial Number" />
                </Form.Label>
                <Dropdown
                  id="drpUnitDeviceList"
                  dataArray={deviceList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: formErrors.DeviceSerialNumberErrorBorder || ''
                  }}
                  onDropdownChange={e => onGenericDropdownChange(e, 'DeviceSerialNumber')}
                  selectedOption={deviceList().filter(option => option.value === unitManager.selectedUnit.DeviceSerialNumber)}
                  disabled={false}
                />
                {formErrors.DeviceSerialNumber && (
                  <p role="alert" className={s.error}>
                    {formErrors.DeviceSerialNumber || ''}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={8}>
                <Form.Label>
                  <FormattedMessage id="unit.description" defaultMessage="Desciption" />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="Description"
                  onChange={handleInput}
                  placeholder={intl.formatMessage({
                    id: 'unit.enterDescription',
                    defaultMessage: 'Enter description'
                  })}
                  value={unitManager.selectedUnit.Description}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <h4 className={s.section}>
                  <FormattedMessage id="unit.manufacture" defaultMessage="Manufacture Details" />
                </h4>
                <hr></hr>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.manufactureid" defaultMessage="Manufacture Id" />
                </Form.Label>
                <InputNumber
                  name="ManufactureId"
                  step="1"
                  onInputChange={handleInput}
                  customClassName={s.formControl}
                  value={unitManager.selectedUnit.ManufactureId?.toString()}
                  label=""
                  id="ManufactureId"
                  placeholder={intl.formatMessage({
                    id: 'unit.manufactureid',
                    defaultMessage: 'Manufacture Id'
                  })}
                />
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.manufactureDate" defaultMessage="Manufacture Date" />
                </Form.Label>
                <DateTimePicker
                  className={formErrors.ManufactureDateErrorBorder || s.frmCalendar}
                  onChange={m => {
                    handleInput({ target: { name: 'ManufactureDate', value: (m && m.unix && m.unix()) || null } });
                  }}
                  timeFormat={null}
                  dateFormat="DD-MMM-YYYY"
                  value={getFormattedate(unitManager.selectedUnit.ManufactureDate)}
                  defaultValue={getFormattedate(unitManager.selectedUnit.ManufactureDate)}
                  closeOnSelect={true}
                  showClear={false}
                />
                {formErrors.ManufactureDate && (
                  <p role="alert" className={s.error}>
                    {formErrors.ManufactureDate}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 className={s.section}>
                  <FormattedMessage id="unit.installation" defaultMessage="Installation Details" />
                </h4>
                <hr></hr>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>{''}</Form.Label>
                <Form.Group controlId="formActiveModel">
                  <Checkbox
                    key="InstallationStatus"
                    dataArray={[
                      {
                        SKEY: 'InstallationStatus',
                        target: { type: 'checkbox' },
                        label: intl.formatMessage({
                          id: 'unit.installationStatus',
                          defaultMessage: 'Installation Completed'
                        }),
                        isChecked: unitManager.selectedUnit.InstallationStatus
                      }
                    ]}
                    onSelectionChange={handleInput}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.installationDate" defaultMessage="Installation Date" />
                </Form.Label>
                <DateTimePicker
                  className={`${formErrors.InstallationDate ? s.formControlError : s.frmCalendar}`}
                  onChange={m => {
                    handleInput({ target: { name: 'InstallationDate', value: (m && m.unix && m.unix()) || null } });
                  }}
                  timeFormat={null}
                  dateFormat="DD-MMM-YYYY"
                  value={getFormattedate(unitManager.selectedUnit.InstallationDate)}
                  defaultValue={getFormattedate(unitManager.selectedUnit.InstallationDate)}
                  closeOnSelect={true}
                  showClear={false}
                />
                {formErrors.InstallationDate && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage id="unit.installationDateError" defaultMessage="Invalid installation date " />
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.installationEngineer" defaultMessage="Engineer" />
                </Form.Label>
                <Dropdown
                  id="drpUnitManagerId"
                  dataArray={userList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onGenericDropdownChange(e, 'InstallationEngineer')}
                  selectedOption={userList().filter(option => option.value === unitManager.selectedUnit.InstallationEngineer)}
                  disabled={false}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={6} lg={4}></Col>
              <Col xs={6} lg={4}>
                <Button type="submit" disabled={unitManager.isLoading} onClick={handleAddressChange} className={s.updateButton}>
                  <FormattedMessage id="unit.addAddressFromGps" defaultMessage="Add Address From GPS" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.installationStreetAddress" defaultMessage="Street Address" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="InstallationStreetAddress"
                  value={unitManager.selectedUnit.InstallationStreetAddress}
                  onChange={handleInput}
                  className={`${formErrors.InstallationStreetAddress ? s.formControlError : ''}`}
                />
                {formErrors.InstallationStreetAddress && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage id="unit.streetAddressException" defaultMessage="Street Address is mandatory field" />
                  </p>
                )}
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.installationSuburbLocation" defaultMessage="Suburb/Location" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="InstallationRegion"
                  onChange={handleInput}
                  value={unitManager.selectedUnit.InstallationRegion}
                  className={`${formErrors.InstallationRegion ? s.formControlError : ''}`}
                />
                {formErrors.InstallationRegion && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage id="unit.suburbLocationException" defaultMessage="Suburb/Location is mandatory field" />
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.installationCity" defaultMessage="City" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="InstallationCity"
                  value={unitManager.selectedUnit.InstallationCity}
                  onChange={handleInput}
                  className={`${formErrors.InstallationCity ? s.formControlError : ''}`}
                />
                {formErrors.InstallationCity && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage id="unit.cityException" defaultMessage="City is mandatory field" />
                  </p>
                )}
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.installationPostCode" defaultMessage="Post code" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="InstallationPostCode"
                  value={unitManager.selectedUnit.InstallationPostCode}
                  onChange={handleInput}
                  className={`${formErrors.InstallationPostCode ? s.formControlError : ''}`}
                />
                {formErrors.InstallationPostCode && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage id="unit.postCodeException" defaultMessage="Post code is mandatory field" />
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.region" defaultMessage="Region/Site" />
                </Form.Label>
                <Dropdown
                  id="drpRegionId"
                  dataArray={regionList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onGenericDropdownChange(e, 'RegionId')}
                  selectedOption={regionList().filter(option => option.value === unitManager.selectedUnit.RegionId)}
                  disabled={false}
                />
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.installationCountry" defaultMessage="Country" />
                </Form.Label>
                <Select
                  isSearchable
                  name="InstallationCountry"
                  label="InstallationCountry"
                  placeholder="Select Country"
                  className={`${s.wsaDropdown} ${ddlClass}`}
                  options={countries}
                  onChange={onCountryChange}
                  value={unitManager.selectedUnit.InstallationCountryCode}
                  output={country => country && country.value}
                />
                {formErrors.InstallationCountry && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage id="unit.countryException" defaultMessage="Country is mandatory field" />
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 className={s.section}>
                  <FormattedMessage id="unit.commission" defaultMessage="Commission Details" />
                </h4>
                <hr></hr>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>{''}</Form.Label>
                <Form.Group controlId="formActiveModel">
                  <Checkbox
                    key="CommissionStatus"
                    dataArray={[
                      {
                        SKEY: 'CommissionStatus',
                        target: { type: 'checkbox' },
                        label: intl.formatMessage({
                          id: 'unit.commissionStatus',
                          defaultMessage: 'Commission Completed'
                        }),
                        isChecked: unitManager.selectedUnit.CommissionStatus
                      }
                    ]}
                    onSelectionChange={handleInput}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.commissionDate" defaultMessage="Commission Date" />
                </Form.Label>
                <DateTimePicker
                  className={formErrors.CommissionDateErrorBorder || s.frmCalendar}
                  onChange={m => {
                    handleInput({ target: { name: 'CommissionDate', value: (m && m.unix && m.unix()) || null } });
                  }}
                  timeFormat={null}
                  dateFormat="DD-MMM-YYYY"
                  value={getFormattedate(unitManager.selectedUnit.CommissionDate)}
                  defaultValue={getFormattedate(unitManager.selectedUnit.CommissionDate)}
                  closeOnSelect={true}
                  showClear={false}
                />
                {formErrors.CommissionDate && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage
                      id="unit.commissionDateError"
                      defaultMessage="Commission date cannot be before the installation date/manufacture date "
                    />
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>{''}</Form.Label>
                <Form.Group controlId="formCommissionPowerOnTest">
                  <Checkbox
                    key="CommissionPowerOnTest"
                    dataArray={[
                      {
                        SKEY: 'CommissionPowerOnTest',
                        target: { type: 'checkbox' },
                        label: intl.formatMessage({
                          id: 'unit.commissionPowerOnTest',
                          defaultMessage: 'Power On Test'
                        }),
                        isChecked: unitManager.selectedUnit.CommissionPowerOnTest
                      }
                    ]}
                    onSelectionChange={handleInput}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.commissionWaterSample" defaultMessage="Water Sample" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="CommissionWaterSample"
                  value={unitManager.selectedUnit.CommissionWaterSample}
                  onChange={handleInput}
                  className=""
                />
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.commissionCallHome" defaultMessage="Call Home" />
                </Form.Label>
                <Form.Control type="text" name="CommissionCallHome" defaultValue={unitManager.selectedUnit.CommissionCallHome} />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.unitSupervisingManager" defaultMessage="Unit Supervising Manager" />
                </Form.Label>
                <Dropdown
                  id="drpUnitManagerId"
                  dataArray={userList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onGenericDropdownChange(e, 'UnitManagerId')}
                  selectedOption={userList().filter(option => option.value === unitManager.selectedUnit.UnitManagerId)}
                  disabled={false}
                />
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.unitSupervisingTechnician" defaultMessage="Unit Supervising Technician" />
                </Form.Label>
                <Dropdown
                  id="drpUnitTechnicianId"
                  dataArray={userList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onGenericDropdownChange(e, 'UnitTechnicianId')}
                  selectedOption={userList().filter(option => option.value === unitManager.selectedUnit.UnitTechnicianId)}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.warrantyStartDate" defaultMessage="Warranty start date" />
                </Form.Label>
                <DateTimePicker
                  className={`${formErrors.WarrantyStartDate ? s.WarrantyStartDateErrorBorder : s.frmCalendar}`}
                  onChange={m => {
                    handleInput({ target: { name: 'WarrantyStartDate', value: (m && m.unix && m.unix()) || null } });
                  }}
                  timeFormat={null}
                  dateFormat="DD-MMM-YYYY"
                  value={getFormattedate(unitManager.selectedUnit.WarrantyStartDate)}
                  defaultValue={getFormattedate(unitManager.selectedUnit.WarrantyStartDate)}
                  closeOnSelect={true}
                  showClear={false}
                />
                {formErrors.WarrantyStartDate && (
                  <p role="alert" className={s.error}>
                    {formErrors.WarrantyStartDate || ''}
                  </p>
                )}
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.warrantyEndDate" defaultMessage="Warranty end date" />
                </Form.Label>
                <DateTimePicker
                  className={`${formErrors.WarrantyEndDate ? s.WarrantyEndDateErrorBorder : s.frmCalendar}`}
                  onChange={m => {
                    handleInput({ target: { name: 'WarrantyEndDate', value: (m && m.unix && m.unix()) || null } });
                  }}
                  timeFormat={null}
                  dateFormat="DD-MMM-YYYY"
                  value={getFormattedate(unitManager.selectedUnit.WarrantyEndDate)}
                  defaultValue={getFormattedate(unitManager.selectedUnit.WarrantyEndDate || 0)}
                  closeOnSelect={true}
                  showClear={false}
                />
                {formErrors.WarrantyEndDate && (
                  <p role="alert" className={s.error}>
                    {formErrors.WarrantyEndDate || ''}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.latitude" defaultMessage="Latitude" />
                </Form.Label>
                <InputNumber
                  name="CommissionGpsLatitude"
                  step=".1"
                  onInputChange={handleInput}
                  customClassName={`${formErrors.CommissionGpsLatitude ? s.formControlError : ''}`}
                  value={unitManager.selectedUnit.CommissionGpsLatitude?.toString()}
                  label=""
                  id="CommissionGpsLatitude"
                />
                {formErrors.CommissionGpsLatitude && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage id="unit.unitGpsLatitude" defaultMessage="Unit GPS latitude is mandatory field" />
                  </p>
                )}
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.longitude" defaultMessage="Longitude" />
                </Form.Label>
                <InputNumber
                  name="CommissionGpsLongitude"
                  step=".1"
                  onInputChange={handleInput}
                  customClassName={`${formErrors.CommissionGpsLongitude ? s.formControlError : ''}`}
                  value={unitManager.selectedUnit.CommissionGpsLongitude?.toString()}
                  label=""
                  id="CommissionGpsLongitude"
                />
                {formErrors.CommissionGpsLongitude && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage id="unit.unitGpsLongitude" defaultMessage="Unit GPS longitude is mandatory field" />
                  </p>
                )}
              </Col>
            </Row>
          </Form>
          <Row>
            <Col>
              <h4 className={s.section}>
                <FormattedMessage id="unit.billOfMaterials" defaultMessage="Bill Of Materials" />
              </h4>
              <hr className={s.sectionTable}></hr>
            </Col>
          </Row>
          <ManagePartsList
            unitManagerParts={unitManager.selectedUnit.BillOfMaterials}
            dropdownDatalist={unitManager.unitDropdownList}
            addUpdatePartHandler={addUpdatePartHandler}
            removePartHandler={removePartHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AddUnit);
