import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';
import uuidv4 from 'uuid/v4';

import {
  COMMAND_SCHEDULER_ERROR,
  GET_ALL_COMMAND_SCHEDULER_DETAILS,
  COMMAND_SCHEDULER_IS_LOADING,
  SET_COMMAND_SCHEDULER_CURRENT_PAGE,
  SET_COMMAND_SCHEDULER_PAGE_FILTER,
  SET_COMMAND_SCHEDULER_CHANGE,
  SET_COMMAND_SCHEDULER,
  UPDATE_COMMAND_SCHEDULER_SAVE_STATUS,
  UPDATE_COMMAND_SCHEDULER_SAVE_STATUS_DATA
} from '../constants/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setCommandSchedulerErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setCommandSchedulerError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setCommandSchedulerError(messageCode, false));
  }, 2500);
};

export const setCommandSchedulerError = (messageCode, status) => {
  return {
    type: COMMAND_SCHEDULER_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: COMMAND_SCHEDULER_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getUnitCommandScheduler = (unitSerialNumber, unitId, filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${unitSerialNumber}/commandSchedules`,
    data: {
      unitSerialNumber: unitSerialNumber,
      unitId: unitId,
      Offset: filterObj.offset,
      Limit: filterObj.limit
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllCommandSchedulerListThunk(retData),
    onFailure: err => setCommandSchedulerErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllCommandSchedulerListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let commandSchedules = (retData && retData.retData.commandSchedules) || [];
  dispatch(setAllCommandSchedulerList(messageCode, commandSchedules));
};

export const setAllCommandSchedulerList = (messageCode, commandSchedules) => ({
  type: GET_ALL_COMMAND_SCHEDULER_DETAILS,
  payload: {
    data: {
      commandSchedules: commandSchedules,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_COMMAND_SCHEDULER_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_COMMAND_SCHEDULER_PAGE_FILTER,
  payload: { filter }
});

export const saveCommandScheduler = (commandScheduler, log, unitSerialNumber, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${unitSerialNumber}/commandSchedules/save`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCommandSchedulerStatusThunk(retData, widgetCode),
    onFailure: err => setCommandSchedulerErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      commandSchedule: commandScheduler,
      log: log
    },
    widgetcode: widgetCode
  });

export const setCommandSchedulerStatusThunk = (retData, widgetCode) => dispatch => {
  if (widgetCode === 'SCHEDULE_END') {
    let messageCode = retData.message;
    let commandSchedules = (retData && retData.commandSchedules) || [];

    dispatch(setCommandSchedulerStatusAndData(messageCode, commandSchedules, true));
    setTimeout(() => {
      //Hide the banner
      dispatch(setCommandSchedulerStatus('', false, false));
    }, 2500);
  } else {
    let messageCode = retData;
    dispatch(setCommandSchedulerStatus(messageCode, true));
    setTimeout(() => {
      //Hide the banner
      dispatch(setCommandSchedulerStatus('', false));
    }, 2500);
  }
};

export const setCommandSchedulerStatusAndData = (messageCode, data, status) => ({
  type: UPDATE_COMMAND_SCHEDULER_SAVE_STATUS_DATA,
  payload: {
    data: {
      commandSchedules: data,
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setCommandSchedulerStatus = (messageCode, status, isOpSuccessful = true) => ({
  type: UPDATE_COMMAND_SCHEDULER_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: isOpSuccessful,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setCommandSchedulerChange = retData => ({
  type: SET_COMMAND_SCHEDULER_CHANGE,
  payload: retData
});
