import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './NotificationTypeUser.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { initialUserManagerState, initialNotificationsState } from '../../../reducers/initialState';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import { Redirect, Link, useParams } from 'react-router-dom';
import Profiles from './Profiles';
import ProfileUsers from './ProfileUsers';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminControlsContentTemplate from '../../AdminControls/AdminControlsContentTemplate';
import classNames from 'classnames';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import {
  getNotificationTypeUsersThunk,
  saveNotificationTypeUsersThunk,
  setNotificationUsersChangeThunk,
  cleanUpThunk
} from '../../../actions/notifications';
import { getUsersThunk } from '../../../actions/userManager';
import { getProfileThunk } from '../../../actions/profiles';
import { getUserProfileWidget, getNotifications, getUserManager, getProfile } from '../../../selectors/index';

const NotificationTypeUser = ({ intl }) => {
  let { notificationTypeId } = useParams();
  const [localformErrors, setFormErrors] = useState({});
  const [highlightedProfile, setHighlightedProfile] = useState({});

  const dispatch = useDispatch();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const notifications = useSelector(state => getNotifications(state));
  const userManager = useSelector(state => getUserManager(state));
  const profile = useSelector(state => getProfile(state));
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const profilesObj = (profile.profiles && Object.values(profile.profiles)) || [];
  let organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  useEffect(() => {
    dispatch(getNotificationTypeUsersThunk('NOTIFICATION_USERS_EDIT', notificationTypeId));
  }, [getNotificationTypeUsersThunk, notificationTypeId]);

  useEffect(() => {
    dispatch(getUsersThunk(1000, '', organisationId, 'NOTIFICATION_USERS_EDIT'));
  }, [getUsersThunk, organisationId]);

  useEffect(() => {
    dispatch(getProfileThunk('NOTIFICATION_USERS_EDIT'));
  }, [getProfileThunk]);

  useEffect(() => {
    return () => {
      dispatch(
        cleanUpThunk({
          ...initialNotificationsState.notifications
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalNotificationState = currentState => {
    dispatch(setNotificationUsersChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = {};
    saveData.Users = notifications.notificationTypeUsers;
    let widgetCode = 'NOTIFICATION_USERS_EDIT';
    saveData.NotificationTypeId = notificationTypeId;
    //saving User
    dispatch(saveNotificationTypeUsersThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!notifications || !notifications.notificationTypeUsers || notifications.notificationTypeUsers.length === 0) {
      formErrors.NotificationTypeUsers = intl.formatMessage({
        id: 'notifications.userMandatory',
        defaultMessage: 'Please add at least one user'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onSelectedProfileClick = profile => {
    setHighlightedProfile(profile);
  };

  const onUserSelectionChange = selectedUsers => {
    setLocalNotificationState({
      ...notifications,
      notificationTypeUsers: selectedUsers
    });
  };

  let messageId = (notifications && notifications.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <div className={s.user}>
      {notifications.isLoading && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={notifications.showBanner}
        status={notifications.isOpSuccessful}
        successText={messageText}
      />
      {notifications.isOpSuccessful && !notifications.showBanner ? <Redirect to="/admin-controls/notifications" /> : ''}

      <AdminControlsContentTemplate selectedPage="manageNotifications" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.userHeader}>
            <Link className={s.backLink} to={'/admin-controls/notifications'}>
              &lt; &nbsp;
              <FormattedMessage id="notifications.backtoNotifications" defaultMessage="BACK TO NOTIFICATIONS" />
            </Link>
            <h3>
              <FormattedMessage id="notifications.editNotificationUsers" defaultMessage="Edit users for notification type " />
              {notifications.selectedNotificationType.Name}
            </h3>
          </div>
          <div className={s.userContent}>
            <Form className={s.userProfileContainer}>
              <Container className={s.userProfileAndUsersContainer} fluid={true}>
                <Row>
                  <Col lg={6} className="pl-0">
                    <Form.Label className={s.subHeading2}>
                      <FormattedMessage id="notifications.selectProfile" defaultMessage="1. Select a profile" />
                    </Form.Label>
                  </Col>
                  <Col lg={6} className="d-none d-lg-table-cell pl-0">
                    <Form.Label className={s.subHeading2}>
                      <FormattedMessage id="notifications.selectUser" defaultMessage="2. Select a user(s) for profile " />
                      {highlightedProfile && highlightedProfile.Name}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className={s.outerRow}>
                  <Col lg={6} className={s.outerLeftCol}>
                    <Profiles profiles={profilesObj} onSelectedProfileClick={onSelectedProfileClick} />
                  </Col>
                  <Col lg={6} className="d-lg-none pl-0">
                    {localformErrors && localformErrors.NotificationTypeUsers && (
                      <p role="alert" className={s.error}>
                        {localformErrors.NotificationTypeUsers}
                      </p>
                    )}
                  </Col>
                  <Col lg={6} className="d-lg-none pl-0">
                    <Form.Label className={s.subHeading2}>
                      <FormattedMessage id="notifications.selectUser" defaultMessage="2. Select a user(s) for profile " />
                      {highlightedProfile && highlightedProfile.Name}
                    </Form.Label>
                  </Col>
                  <Col lg={6} className={s.outerRightCol}>
                    <ProfileUsers
                      userManager={userManager}
                      highlightedProfile={highlightedProfile}
                      onUserSelectionChange={onUserSelectionChange}
                      notificationTypeUsers={notifications.notificationTypeUsers}
                      notificationTypeId={notificationTypeId}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="d-none d-lg-table-cell pl-0">
                    {localformErrors && localformErrors.NotificationTypeUsers && (
                      <p role="alert" className={s.error}>
                        {localformErrors.NotificationTypeUsers}
                      </p>
                    )}
                  </Col>
                </Row>
              </Container>

              {/* User Profiles END */}
              <div>
                <Button variant="primary" className={classNames(s.margin5, s.btnSaveChanges)} onClick={submitForm} noValidate>
                  <FormattedMessage id="profile.profileSave" defaultMessage="Save Changes" />
                </Button>
                <Link to="/admin-controls/notifications">
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="profile.profileCancel" defaultMessage="Cancel" />
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

export default injectIntl(NotificationTypeUser);
