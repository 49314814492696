import React from 'react';
import PropTypes from 'prop-types';
import s from './PartDetails.module.scss';
import { FormattedMessage } from 'react-intl';
import { initialComponentPartsState } from '../../reducers/initialState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TELEMETRY_TYPES } from '../../constants/index';
import PartTelemetryHistoryList from './PartTelemetryHistoryList';
import PartFirmwareHistoryList from './PartFirmwareHistoryList';

const PartDetails = ({ componentParts }) => {
  return (
    <div>
      {componentParts.selectedComponentPart && (
        <div>
          <div className={`${s.componentPartContent}`}>
            <h4>
              <FormattedMessage id="componentParts.details" defaultMessage="Details" />
            </h4>
            <Row>
              <Col md={3}>
                <span>
                  <FormattedMessage id="componentParts.serialNumber" defaultMessage="Serial Number" />
                </span>
              </Col>
              <Col md={9}>{componentParts.selectedComponentPart.SerialNumber}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <span>
                  <FormattedMessage id="componentParts.location" defaultMessage="Location" />
                </span>
              </Col>
              <Col md={9}>{componentParts.selectedComponentPart.Location}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <span>
                  <FormattedMessage id="componentParts.faulty" defaultMessage="Faulty" />
                </span>
              </Col>
              <Col md={9}>
                {componentParts.selectedComponentPart.IsFaulty ? (
                  <FormattedMessage id="componentParts.yes" defaultMessage="Yes" />
                ) : (
                  <FormattedMessage id="componentParts.no" defaultMessage="No" />
                )}
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <span>
                  <FormattedMessage id="componentParts.active" defaultMessage="Active" />
                </span>
              </Col>
              <Col md={9}>
                {componentParts.selectedComponentPart.IsActive ? (
                  <FormattedMessage id="componentParts.yes" defaultMessage="Yes" />
                ) : (
                  <FormattedMessage id="componentParts.no" defaultMessage="No" />
                )}
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <span>
                  <FormattedMessage id="componentParts.organisation" defaultMessage="Organisation" />
                </span>
              </Col>
              <Col md={9}>{componentParts.selectedComponentPart.OrganisationName}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <span>
                  <FormattedMessage id="componentParts.brand" defaultMessage="Brand" />
                </span>
              </Col>
              <Col md={9}>{componentParts.selectedComponentPart.Brand}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <span>
                  <FormattedMessage id="componentParts.model" defaultMessage="Model" />
                </span>
              </Col>
              <Col md={9}>{componentParts.selectedComponentPart.Model}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <span>
                  <FormattedMessage id="componentParts.componentType" defaultMessage="Component Type" />
                </span>
              </Col>
              <Col md={9}>{componentParts.selectedComponentPart.ComponentTypeName}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <span>
                  <FormattedMessage id="componentParts.modelComponentPosition" defaultMessage="Model Component Position" />
                </span>
              </Col>
              <Col md={9}>{componentParts.selectedComponentPart.ModelComponentPositionName}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <span>
                  <FormattedMessage id="componentParts.unitSerialNumber" defaultMessage="Unit Serial Number" />
                </span>
              </Col>
              <Col md={9}>{componentParts.selectedComponentPart.UnitSerialNumber}</Col>
            </Row>
          </div>

          <div className={`${s.componentPartContent}`}>
            <h4>
              <FormattedMessage id="componentParts.currentTelemetryData" defaultMessage="Current Telemetry Data" />
            </h4>
            {componentParts.selectedComponentPart.TelemetryData.map(partdetail => {
              return (
                <Row key={partdetail.TelemetryPartId}>
                  <Col md={3}>
                    <span>{TELEMETRY_TYPES[partdetail.TelemetryType].name}</span>
                  </Col>
                  <Col md={9}>{partdetail.Value}</Col>
                </Row>
              );
            })}
          </div>
          <div className={`${s.componentPartContent} ${s.partsHistory}`}>
            <h4>
              <FormattedMessage id="componentParts.historicalTelemetryData" defaultMessage="Historical Telemetry Data" />
            </h4>
            <PartTelemetryHistoryList
              telemetryHistoryData={componentParts.selectedComponentPart.TelemetryHistory}
            ></PartTelemetryHistoryList>
          </div>
          {componentParts.selectedComponentPart.FirmwareHistory && componentParts.selectedComponentPart.FirmwareHistory.length > 0 && (
            <div className={`${s.componentPartContent} ${s.partsHistory}`}>
              <h4>
                <FormattedMessage id="componentParts.historicalFirmwareupdateData" defaultMessage="Historical Firmware Update Data" />
              </h4>
              <PartFirmwareHistoryList firmwareHistoryData={componentParts.selectedComponentPart.FirmwareHistory}></PartFirmwareHistoryList>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

PartDetails.defaultProps = {
  componentParts: {
    ...initialComponentPartsState.componentParts
  }
};

PartDetails.propTypes = {
  componentParts: PropTypes.object.isRequired
};

export default PartDetails;
