import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelComponentTypeList from './ModelComponentTypeList';
import { getUserProfileWidget, getModelComponentType } from '../../selectors/index';

import {
  getModelComponentTypeThunk,
  saveModelComponentTypeThunk,
  setModelComponentTypeChangeThunk
} from '../../actions/modelComponentTypes';

const mapStateToProps = state => {
  return {
    modelComponentType: getModelComponentType(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getModelComponentType: (offset, limit, filter) => getModelComponentTypeThunk({ offset, limit, filter }, 'MODELCOMPONENTTYPE_LIST'),
      saveModelComponentType: (modelComponentType, widgetCode) => saveModelComponentTypeThunk(modelComponentType, widgetCode),
      setModelComponentTypeChange: selectedModelComponentType => setModelComponentTypeChangeThunk(selectedModelComponentType)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelComponentTypeList);
