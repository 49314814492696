import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './SequenceVersion.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const SequenceVersionTable = ({
  paginatedData,
  modelId,
  modelVersionId,
  currentTimezone,
  changeAllowed,
  duplicateSequenceVersion,
  intl
}) => {
  const lblYes = intl.formatMessage({ id: 'common.yes', defaultMessage: 'Yes' });
  const lblNo = intl.formatMessage({ id: 'common.no', defaultMessage: 'No' });
  const [active, setActive] = useState(false);
  const [duplicateSequenceVersionId, setDuplicateSequenceVersionId] = useState(0);
  const onDuplicateClick = id => {
    setDuplicateSequenceVersionId(id);
    handleCommand();
  };

  const handleCommand = () => {
    setActive(true);
  };

  const handleConfirm = () => {
    duplicateSequenceVersion(duplicateSequenceVersionId);
    handleClose();
  };

  const handleClose = () => {
    setActive(false);
    setDuplicateSequenceVersionId(0);
  };

  const dialogDuplicate = intl.formatMessage({
    id: 'sequenceVersionManagement.confirmCopy',
    defaultMessage: 'Are you sure you want to duplicate this sequence version?'
  });

  return (
    <div className="">
      <ConfirmDialog title={dialogDuplicate} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="sequenceVersionManagement.name" defaultMessage="Name" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="sequenceVersionManagement.versionNo" defaultMessage="Version No" />
            </th>
            <th>
              <FormattedMessage id="sequenceVersionManagement.published" defaultMessage="Published" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="sequenceVersionManagement.datePublished" defaultMessage="Date Published" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="sequenceVersionManagement.publishedUser" defaultMessage="Published User" />
            </th>
            <th>
              <FormattedMessage id="sequenceVersionManagement.active" defaultMessage="Active" />
            </th>
            {changeAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.SequenceVersionId} data-unittest="sequenceVersionData" className={n.IsActive === 1 ? s.active : s.complete}>
                <td>{n.Name || ''}</td>
                <td className="d-none d-md-table-cell">{n.VersionNo}</td>
                <td>{n.IsPublished ? lblYes : lblNo}</td>
                <td className="d-none d-md-table-cell">
                  {n.PublishedByDateTime
                    ? moment
                        .unix(parseInt(n.PublishedByDateTime))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                </td>
                <td className="d-none d-md-table-cell">{n.PublishedByUserName || ''}</td>
                <td>{n.IsActive ? lblYes : lblNo}</td>
                {changeAllowed && (
                  <td>
                    <div className={s.btnView}>
                      <Link
                        to={`/admin-controls/model-configuration/${modelId}/model-version-configuration/${modelVersionId}/sequence-version/${n.SequenceVersionId}`}
                      >
                        <Button variant="outline-secondary" data-unittest="view" className={s.viewButton}>
                          {n.IsPublished ? (
                            <FormattedMessage id="sequenceVersionManagement.view" defaultMessage="VIEW" />
                          ) : (
                            <FormattedMessage id="sequenceVersionManagement.change" defaultMessage="CHANGE" />
                          )}
                        </Button>
                      </Link>
                      <Button
                        onClick={() => onDuplicateClick(n.SequenceVersionId)}
                        variant="outline-secondary"
                        data-unittest="view"
                        className={s.viewButton}
                      >
                        <FormattedMessage id="sequenceVersionManagement.duplicate" defaultMessage="DUPLICATE" />
                      </Button>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

SequenceVersionTable.defaultProps = {
  paginatedData: []
};

SequenceVersionTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(SequenceVersionTable);
