import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import EventInputs from '../EventInputs/EventInputsContainer';
import EventLog from '../EventLog/EventLogContainer';
import EventPagination from '../EventPagination/EventPagination';

import s from './Events.module.scss';
import { compareValues } from '../../utils';

const Events = ({ events, selectedDevice, eventInputs, updateEventInputs, fetchEvents, currentTimezone, userProfileWidgets }) => {
  const { unitSerialNumber } = useParams();

  const devicesObj = Object.values(selectedDevice);
  let { descOrder } = { ...eventInputs };

  if (descOrder === '1') {
    events = events.sort(compareValues('timestamp', 'desc'));
  } else {
    events = events.sort(compareValues('timestamp', 'asc'));
  }

  return (
    <div className={s.events}>
      {devicesObj.length === 0 && <LoadingSpinner />}
      <ContentTemplate
        selectedPage="eventLog"
        userProfileWidgets={userProfileWidgets}
        tagoutRequired={false}
        backLink={`/unit/process-flow/${unitSerialNumber}`}
        widgetCode={'EVENTLOGSUMMARY'}
      >
        {/* <div className={s.exportRowContainer}>
          <h3>Event Log</h3>
        </div> */}
        <div className={s.contentWrapper}>
          <h3>EVENTS</h3>
          {eventInputs && (
            <Fragment>
              <EventInputs
                {...eventInputs}
                updateEventInputs={updateEventInputs}
                fetchEvents={fetchEvents}
                view="viewAll"
                limit={1000}
                unitSerialNumber={unitSerialNumber}
                currentTimezone={currentTimezone}
              />

              <EventPagination data={events}>
                <EventLog
                  view="viewAll"
                  limit={1000}
                  {...eventInputs}
                  selectedDevice={selectedDevice}
                  updateEventInputs={updateEventInputs}
                />
              </EventPagination>
            </Fragment>
          )}
        </div>
      </ContentTemplate>
    </div>
  );
};

Events.defaultProps = {
  selectedDevice: {
    unitSerialNumber: ''
  }
};

Events.propTypes = {
  events: PropTypes.array.isRequired,
  eventInputs: PropTypes.object,
  selectedDevice: PropTypes.object,
  updateEventInputs: PropTypes.func.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default Events;
