import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import { DOMAIN_NAME, IS_LOCALHOST } from '../constants/index';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export default () => {
  //check if the defaultTimezone cookie exists
  const defaultTimezone = Cookies.get(`defaultTimezone-${envName}`);

  if (defaultTimezone !== undefined && defaultTimezone.length > 0) {
    return defaultTimezone;
  } else {
    // Attempt to obtain timezone from browser navigator
    const timezone = moment.tz.guess();
    Cookies.set(`defaultTimezone-${envName}`, timezone, { domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    return timezone;
  }
};
