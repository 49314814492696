import React from 'react';
import ReactCodeInput from 'react-code-input';
import { reactCodeInput } from './CodeInputField.scss';
import * as style from '../../../styles/variables.scss';

const VerificationCodeInput = ({ type, fields, value, onChange }) => {
  const props = {
    className: reactCodeInput,
    inputStyle: {
      fontFamily: 'Barlow',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '41px',
      borderRadius: '3px',
      fontSize: '22px',
      fontWeight: 500,
      height: '41px',
      paddingLeft: '13px',
      color: '#3d3d41',
      border: '1px solid #d3dbdf'
    },
    inputStyleInvalid: {
      fontFamily: 'Barlow',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '41px',
      fontWeight: 500,
      borderRadius: '3px',
      fontSize: '22px',
      height: '41px',
      paddingLeft: '13px',
      color: '#3d3d41',
      border: '1px solid red'
    }
  };
  return <ReactCodeInput type={type} fields={fields} value={value} onChange={onChange} {...props} />;
};

export default VerificationCodeInput;
