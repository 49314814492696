import Cookies from 'js-cookie';
import {
  GET_ALL_UNITS,
  GET_ALL_UNITS_ERROR,
  UNIT_LOADING,
  SET_UNITS_CURRENT_PAGE,
  SET_UNITS_PAGE_FILTER,
  DESCRIBE_UNIT,
  SET_UNIT_IOT_INFO,
  SET_UNIT_SEARCH_RESULT,
  SET_UNIT_TAGOUT,
  RESET_UNIT_MESSAGE,
  DISABLE_COMMANDS,
  GET_ALL_UNIT_FILTERS,
  SET_UNIT_FILTERED_RESULTS,
  SET_UNIT_REGION_LIST,
  SET_UNIT_MANAGER_ENG_LIST,
  SET_UNIT_SETTINGS_LIST,
  UNIT_SETTINGS_CHANGE,
  UNIT_SETTINGS_ERROR,
  UNIT_SETTINGS_UPDATE_STATUS,
  UNIT_SETTINGS_LOADING
} from '../constants/index';
import { apiAction, logEntry } from '../utils/index';
import { setDeviceTimezone } from './devices';
import { UNIT_MANAGEMENT, UPLOAD_UNIT, UNIT_TAG_OUT, UNIT_TAG_IN, UNIT_DETAILS_UPDATE, UNIT_SETTINGS_UPDATE } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setUnitsError = value => ({
  type: GET_ALL_UNITS_ERROR,
  payload: { value }
});

export const getUnitsThunk = (limit, filter, orgId, widgetCode, profileId) => dispatch => {
  dispatch(getUnits(limit, filter, orgId, widgetCode, profileId));
};

export const getUnits = (limit, filter, orgId, widgetCode, profileId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/unit`,
    data: {
      filter: filter.filter || filter || undefined,
      region: filter.filterRegion || undefined,
      model: filter.filterModel || undefined,
      limit: limit,
      organisationId: orgId,
      profileId: profileId
    },
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setUnitsThunk(retData),
    onFailure: error => setUnitErrorThunk(error, false),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setUnitsThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let units = (retData && retData.unit && retData.unit.units) || [];
  dispatch(setUnits(messageCode, units));
};

export const setUnits = (messageCode, units) => ({
  type: GET_ALL_UNITS,
  payload: {
    unit: {
      units: units,
      filteredResults: units,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setUnitsLoadingStatus = status => ({
  type: UNIT_LOADING,
  payload: { status }
});

export const setCurrentPage = currentPage => ({
  type: SET_UNITS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_UNITS_PAGE_FILTER,
  payload: { filter }
});

//Unit Management
export const setUnitErrorThunk = (err, isDescribedUnitFailed = false) => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setUnitError(messageCode, true, isDescribedUnitFailed));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitError(messageCode, false, isDescribedUnitFailed));
  }, 5000);
};

export const setUnitError = (messageCode, status, isDescribedUnitFailed) => {
  return {
    type: GET_ALL_UNITS_ERROR,
    payload: {
      unit: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || '',
        isDataRendered: isDescribedUnitFailed
      }
    }
  };
};

//Unit Creation
//=============

export const postUnitSaveThunk = (unit, widgetCode) => dispatch => {
  let url = '/management/unit/save';
  let log = logEntry(UNIT_MANAGEMENT, UPLOAD_UNIT, unit);
  unit.log = log;
  dispatch(saveUnit(unit, url, widgetCode));
};

export const saveUnit = (unit, url, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setUnitSaveThunk(retData),
    onFailure: error => setUnitError(error),
    accessToken: Cookies.get(`access-${envName}`),
    data: unit,
    widgetcode: widgetCode
  });

export const setUnitSaveThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let unit = (retData && retData.unit && retData.unit.result) || '{}';
  dispatch(setIotUnitCreation(messageCode, JSON.parse(unit)));
};

export const setIotUnitCreation = (messageCode, unit) => ({
  type: SET_UNIT_IOT_INFO,
  payload: {
    unitIotInfo: {
      //State machine code temporarly disabled
      //===================================
      // unitIotInfo: {
      //   privateKeyData: unit.privateKeyData,
      //   certificateData: unit.certificateData
      // },
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isDataRendered: false
    }
  }
});

//Unit Reports
//============

export const describeUnitThunk = (unitSerialNumber, widgetCode, tagoutDetailsRequired = false) => dispatch => {
  dispatch(describeUnit(unitSerialNumber, widgetCode, tagoutDetailsRequired));
};

export const describeUnit = (unitSerialNumber, widgetCode, tagoutDetailsRequired) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/unit/describe`,
    data: {
      unitSerialNumber: unitSerialNumber || undefined,
      tagoutRequired: tagoutDetailsRequired
    },
    method: 'POST',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setDescribeUnitThunk(retData),
    onFailure: err => setUnitErrorThunk(err, true),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setDescribeUnitThunk = (retData, updateSuccessful = false) => dispatch => {
  let messageCode = retData && retData.message;
  let unit = (retData && retData.unit) || {};
  dispatch(setDescribeUnit(messageCode, unit, updateSuccessful));

  //set device timezone based on latitude and longitude
  if (retData.unit) {
    const { CommissionGpsLatitude, CommissionGpsLongitude } = retData.unit;
    dispatch(setDeviceTimezone(CommissionGpsLatitude, CommissionGpsLongitude));
  }

  if (updateSuccessful) {
    setTimeout(() => {
      //Hide the banner
      dispatch(setDescribeUnit(messageCode, unit, false));
    }, 5000);
  }
};

export const setDescribeUnit = (messageCode, unit, updateSuccessful) => ({
  type: DESCRIBE_UNIT,
  payload: {
    unit: {
      selectedUnit: {
        ...unit,
        WarrantyStartDate: unit.WarrantyStartDate || null,
        WarrantyEndDate: unit.WarrantyEndDate || null,
        RegionId: unit.RegionId || null,
        RegionName: unit.RegionName || null
      },
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: updateSuccessful,
      detailsSubmitted: updateSuccessful,
      isDataRendered: true
    }
  }
});

//Report fetch
export const initSearchThunk = query => dispatch => {
  dispatch(initSearch(query));
};

export const initSearch = query =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/reports/unit`,
    data: {
      query
    },
    method: 'POST',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setSearchReportThunk(retData),
    onFailure: err => setUnitErrorThunk(err, false),
    accessToken: Cookies.get(`access-${envName}`)
  });

export const setSearchReportThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let searchResults = (retData && retData.unitReport) || {};
  dispatch(setSearchReport(messageCode, searchResults));
};

export const setSearchReport = (messageCode, searchResults) => ({
  type: SET_UNIT_SEARCH_RESULT,
  payload: {
    search: {
      results: searchResults,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

//Tag out

export const unitTagOutThunk = (unitTagOutDetails, widgetCode) => dispatch => {
  dispatch(disableCommandButtonStatus(true));
  let url = '/maintenance/tagout/save';
  let actionName = unitTagOutDetails.UnitTagOutId === 0 ? UNIT_TAG_OUT : UNIT_TAG_IN;
  let log = logEntry(UNIT_MANAGEMENT, actionName, unitTagOutDetails);
  dispatch(unitTagOut(unitTagOutDetails, url, log, widgetCode));
};

const unitTagOut = (unitTagOutDetails, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    widgetcode: widgetCode,
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setUnitTagOutThunk(retData, unitTagOutDetails.UnitSerialNumber),
    onFailure: err => setUnitErrorThunk(err, true),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      UnitTagOut: unitTagOutDetails,
      log: log
    }
  });

export const setUnitTagOutThunk = (retData, unitSerialNumber) => dispatch => {
  let messageCode = retData && retData.message;
  let unitTagout = (retData && retData.unitTagout) || {};
  dispatch(disableCommandButtonStatus(false));
  dispatch(setUnitTagOut(messageCode, unitTagout, unitSerialNumber));

  setTimeout(() => {
    //Hide the banner
    dispatch(setDefaultUnitMessage());
  }, 2500);
};

export const setUnitTagOut = (messageCode, unitTagout, unitSerialNumber) => ({
  type: SET_UNIT_TAGOUT,
  payload: {
    unit: {
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: true,
      isDataRendered: true,
      isOpSuccessful: true
    },
    tagout: unitTagout,
    unitSerialNumber: unitSerialNumber
  }
});

export const setDefaultUnitMessage = () => ({
  type: RESET_UNIT_MESSAGE,
  payload: {
    message: ''
  }
});

export const disableCommandButtonStatus = disabled => ({
  type: DISABLE_COMMANDS,
  payload: { disabled }
});

//Save Unit Details
//================
export const saveUnitDetailsThunk = (unitData, widgetCode) => dispatch => {
  let log = logEntry(UNIT_MANAGEMENT, UPLOAD_UNIT, unitData);
  unitData.log = log;
  dispatch(saveUnitDetails(unitData, widgetCode));
};

export const saveUnitDetails = (unitData, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/unit/update`,
    data: {
      ...unitData
    },
    method: 'POST',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setDescribeUnitThunk(retData, true),
    onFailure: err => setUnitErrorThunk(err, true),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

//Unit Listing
export const getUnitsFilterThunk = widgetCode => dispatch => {
  dispatch(getUnitsFilter(widgetCode));
};

export const getUnitsFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      fUnitRegion: true,
      fModels: true,
      filterFor: 'unitlisting'
    },
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setAllUnitsFilterThunk(retData),
    onFailure: err => setUnitErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllUnitsFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;

  dispatch(setAllUnitsFilter(messageCode, filterData, itemCount));
};

export const setAllUnitsFilter = (messageCode, eventActionFilter, itemCount) => ({
  type: GET_ALL_UNIT_FILTERS,
  payload: {
    data: {
      filterData: eventActionFilter,
      unitListCount: itemCount,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setUnitsFilteredDataThunk = retData => dispatch => {
  dispatch(setUnitsFilteredData(retData));
};

export const setUnitsFilteredData = retData => ({
  type: SET_UNIT_FILTERED_RESULTS,
  payload: retData
});

export const getOrganisationRegionThunk = (widgetCode, orgId) => dispatch => {
  dispatch(getOrganisationRegion(widgetCode, orgId));
};

export const getOrganisationRegion = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/organisation/regions`,
    method: 'GET',
    data: {
      OrgId: orgId
    },
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setRegionListThunk(retData),
    onFailure: err => setUnitErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setRegionListThunk = retData => dispatch => {
  let data = (retData && retData.retData && retData.retData.data) || [];
  dispatch(setRegionList(data));
};

export const setRegionList = retData => ({
  type: SET_UNIT_REGION_LIST,
  payload: {
    regionList: retData
  }
});

export const getUnitManagerAndEngineersThunk = (widgetCode, orgId) => dispatch => {
  dispatch(getUnitManagerAndEngineers(widgetCode, orgId));
};

export const getUnitManagerAndEngineers = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/management/user`,
    data: {
      Limit: 1000,
      Filter: null,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setUnitManagerAndEngineersListThunk(retData),
    onFailure: err => setUnitErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setUnitManagerAndEngineersListThunk = retData => dispatch => {
  let users = (retData && retData.user && retData.user.users) || [];
  dispatch(setUnitManagerAndEngineersList(users));
};

export const setUnitManagerAndEngineersList = retData => ({
  type: SET_UNIT_MANAGER_ENG_LIST,
  payload: {
    userList: retData
  }
});

export const getUnitSettingsThunk = (unitId, widgetCode) => dispatch => {
  dispatch(getUnitSettings(unitId, widgetCode));
};

export const getUnitSettings = (unitId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/unit/unitsettings`,
    data: {
      UnitId: unitId
    },
    method: 'GET',
    onLoad: status => setUnitSettingsLoadingStatus(status),
    onSuccess: retData => setAllUnitSettingsListThunk(retData),
    onFailure: err => setUnitSettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllUnitSettingsListThunk = ({ retData }) => dispatch => {
  let unitSettings = (retData && retData.unitSettings) || [];
  dispatch(setAllUnitSettingsList(unitSettings));
};

export const setAllUnitSettingsList = unitSettings => ({
  type: SET_UNIT_SETTINGS_LIST,
  payload: {
    data: {
      unitSettings: unitSettings
    }
  }
});

export const setUnitSettingsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setUnitSettingsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitSettingsError(messageCode, false));
  }, 2500);
};

export const setUnitSettingsError = (messageCode, status) => {
  return {
    type: UNIT_SETTINGS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const postUnitSettingsThunk = (unitSetting, widgetCode) => dispatch => {
  let url = '/management/unit/unitsettings/save';
  let actionName = UNIT_SETTINGS_UPDATE;
  let log = logEntry(UNIT_MANAGEMENT, actionName, unitSetting);
  dispatch(saveUnitSettings(unitSetting, url, log, widgetCode));
};

export const saveUnitSettings = (unitSetting, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setUnitSettingsLoadingStatus(status),
    onSuccess: message => setUnitSettingsStatusThunk(message),
    onFailure: err => setUnitSettingsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      UnitSetting: unitSetting,
      log: log
    },
    widgetcode: widgetCode
  });

export const setUnitSettingsStatusThunk = message => dispatch => {
  dispatch(setUnitSettingsStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitSettingsStatus('', false));
  }, 2500);
};

export const setUnitSettingsStatus = (messageCode, status) => ({
  type: UNIT_SETTINGS_UPDATE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setUnitSettingsChangeThunk = retData => dispatch => {
  dispatch(setUnitSettingsChange(retData));
};

export const setUnitSettingsChange = retData => ({
  type: UNIT_SETTINGS_CHANGE,
  payload: retData
});

export const setUnitSettingsLoadingStatus = status => ({
  type: UNIT_SETTINGS_LOADING,
  payload: { status }
});
