import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import moment from 'moment-timezone';
import ChartInputs from '../ChartInputs/ChartInputs';
import Chart from '../Chart/Chart';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import s from './ChartWrapper.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { CSVLink } from 'react-csv';
import RangeSlider from '../WSAControls/RangeSlider/RangeSlider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import help from '../../assets/help-grey.svg';

class ChartWrapper extends React.PureComponent {
  componentDidMount() {
    const { data, dateFrom, dateTo, unitSerialNumber, fetchMetricData, id, sensors, limit, resultType } = this.props;
    if (data.length === 0) {
      fetchMetricData(id, unitSerialNumber, dateFrom, dateTo, sensors, limit, resultType, 'DATATRENDANALYSIS');
    }
  }

  componentDidUpdate(prevProps) {
    const { dateFrom, dateTo, unitSerialNumber, fetchMetricData, id, sensors, limit, resultType, updateChart } = this.props;
    const {
      dateFrom: prevFrom,
      dateTo: prevTo,
      sensors: prevSensors,
      limit: prevLimit,
      resultType: prevResultType,
      updateChart: prevUpdateChart
    } = prevProps;

    /* onClicked used instead of the below filters  */
    // prevFrom !== dateFrom ||
    // prevTo !== dateTo ||
    // prevLimit !== limit ||
    // prevResultType !== resultType ||

    if (prevUpdateChart !== updateChart || (!isEqual(prevSensors, sensors) && sensors.length > 0)) {
      fetchMetricData(id, unitSerialNumber, dateFrom, dateTo, sensors, limit, resultType, 'DATATRENDANALYSIS');
    }
  }

  render() {
    const {
      data,
      dateFrom,
      dateTo,
      unitSerialNumber,
      updateChart,
      id,
      index,
      isLoading,
      removeChart,
      sensors,
      sensorOptions,
      lowerThreshold,
      upperThreshold,
      limit,
      resultType,
      updateChartValuesThottled,
      updateChartFilterValuesThottled,
      currentTimezone,
      unitComponentParts,
      userProfileWidgets,
      errorMessage,
      fromDataTrend,
      intl
    } = this.props;

    const showChart = data.length > 0 && sensors.length > 0;
    const showNoData = data.length === 0 || sensors.length === 0;
    const modelSensors = unitComponentParts.filter(comp => comp.UoM);

    let UpdatedData = [];
    if (data && data.length > 0) {
      data.forEach(element => {
        let item = element;
        if (item.timestamp !== undefined) {
          item.timestamp = moment(item.timestamp)
            .tz(currentTimezone)
            .format('DD-MMM-YYYY HH:mm:ss');
        }
        UpdatedData.push(item);
      });
    }
    const chartCount = index + 1;

    const sliderLabels = {
      1: intl.formatMessage({ id: 'datatrend.analysis.minimum', defaultMessage: 'Minimum' }),
      2: intl.formatMessage({ id: 'datatrend.analysis.average', defaultMessage: 'Average' }),
      3: intl.formatMessage({ id: 'datatrend.analysis.maximum', defaultMessage: 'Maximum' })
    };

    const onResultTypeChange = (id, unitSerialNumber, resultType, value) => {
      updateChartValuesThottled(id, unitSerialNumber, resultType, value);
    };

    return (
      <div className={s.chartWrapper}>
        <div className={s.chartContent}>
          <div className={s.chartHeader}>
            <div>
              {fromDataTrend && (
                <h4>
                  <FormattedMessage id="chart.chart" defaultMessage="CHART" /> {index + 1}
                </h4>
              )}
            </div>
            {IsWidgetAccessible(userProfileWidgets, 'DATATRENDANALYSIS_EXPORT') && (
              <div className={s.chartbuttons}>
                <CSVLink filename={'chart_' + chartCount + '.csv'} data={UpdatedData} className={s.exportButton}>
                  <FormattedMessage id="chart.exportchart" defaultMessage="Export" />
                </CSVLink>
                {removeChart && (
                  <button
                    className={s.selectlink}
                    onClick={() => {
                      removeChart(id, unitSerialNumber);
                    }}
                  >
                    <FormattedMessage id="chart.removeChart" defaultMessage="Remove Chart" />
                  </button>
                )}
              </div>
            )}
          </div>
          <p className={s.intro}>
            <FormattedMessage id="chart.selectoreditchart" defaultMessage="Select / edit variables for chart" />
          </p>
          <Row>
            <Col>
              <div className={s.divWarning}>
                <div>
                  {' '}
                  <img src={help} alt="icon" className={s.icon}></img>
                  <FormattedMessage
                    id="chart.messageDownloadSize"
                    defaultMessage="Only 2 days (48 hours) of data can only be viewed at a time due to the size limitation"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <ChartInputs
            dateFrom={dateFrom}
            dateTo={dateTo}
            unitSerialNumber={unitSerialNumber}
            id={id}
            sensors={sensors}
            sensorOptions={sensorOptions}
            lowerThreshold={lowerThreshold}
            upperThreshold={upperThreshold}
            limit={limit}
            resultType={resultType}
            updateChartValuesThottled={updateChartValuesThottled}
            updateChartFilterValuesThottled={updateChartFilterValuesThottled}
            currentTimezone={currentTimezone}
          />

          {isLoading && <LoadingSpinner />}

          {showChart && (
            <Chart
              sensors={sensors}
              lowerThreshold={lowerThreshold}
              upperThreshold={upperThreshold}
              data={data}
              id={id}
              currentTimezone={currentTimezone}
              unitComponentParts={modelSensors}
            />
          )}
          {showChart && (
            <div className={s.chartSlider}>
              <Row>
                <Col xl={4}></Col>
                <Col xl={4}>
                  <RangeSlider
                    min={1}
                    max={3}
                    value={resultType}
                    labels={sliderLabels}
                    onChange={e => onResultTypeChange(id, unitSerialNumber, 'resultType', e)}
                  />
                </Col>
                <Col xl={4}></Col>
              </Row>
            </div>
          )}

          {showNoData && (!errorMessage || errorMessage.length === 0) && (
            <p className={s.noData}>
              <FormattedMessage id="chart.nodataavailable" defaultMessage="No data currently available" />
            </p>
          )}
          {errorMessage.length > 0 && (
            <p className={s.error}>
              <FormattedMessage id={errorMessage} defaultMessage="No data currently available" />
            </p>
          )}
        </div>
      </div>
    );
  }
}

ChartWrapper.defaultProps = {
  currentTimezone: moment.tz.guess(),
  unitComponentParts: []
};

ChartWrapper.propTypes = {
  data: PropTypes.array.isRequired,
  unitSerialNumber: PropTypes.string.isRequired,
  dateFrom: PropTypes.number.isRequired,
  dateTo: PropTypes.number.isRequired,
  fetchMetricData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  lowerThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  upperThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sensors: PropTypes.array.isRequired,
  sensorOptions: PropTypes.array.isRequired,
  updateChartValuesThottled: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  unitComponentParts: PropTypes.array.isRequired
};

export default injectIntl(ChartWrapper);
