import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './Calibration.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialCalibrationState } from '../../reducers/initialState';
import { Redirect, Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import Banner from '../Banner/Banner';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import CalibrationResults from './CalibrationResults';
import CalibrationConstants from './CalibrationConstants';
import CalibrationComments from './CalibrationComments';
import CalibrationFinalize from './CalibrationFinalize';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Cookies from 'js-cookie';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const CalibrationView = ({
  calibrationManager,
  userProfileWidgets,
  currentTimezone,
  setCalibrationChange,
  setCalibrationConstant,
  saveCalibration,
  deleteCalibration,
  updateUnitShadow,
  describeCalibration,
  saveCalibrationComment,
  selectedUnit,
  intl
}) => {
  let IsEdit = false;
  let IsRedirect = false;
  let { unitSerialNumber, unitId, calibrationId } = useParams();
  const [active, setActive] = useState(false);
  const [commandId, setCommandId] = useState(0);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let userName = Cookies.get(`name-${envName}`) || '';

  const isCommentAllowed = IsWidgetAccessible(userProfileWidgets, 'CALIBRATION_COMMENT_ADD');

  if (calibrationId && parseInt(calibrationId) > 0) {
    IsEdit = true;
    userName = calibrationManager.SelectedCalibration.FullName;
  }

  useEffect(() => {
    if (unitSerialNumber.length > 0 && calibrationId > 0) {
      describeCalibration(calibrationId, unitSerialNumber);
    } else {
      setCalibrationChange({
        SelectedCalibration: { ...calibrationManager.SelectedCalibration, ...initialCalibrationState.calibration.SelectedCalibration }
      });
    }
  }, [describeCalibration, calibrationId, unitSerialNumber]);

  const returnUrl = '/unit/calibrations/' + unitSerialNumber;
  let messageId = (calibrationManager && calibrationManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const handleConfirm = () => {
    deleteCalibrations();
    handleClose();
  };

  const deleteCalibrations = () => {
    if (calibrationId && unitSerialNumber) {
      deleteCalibration(calibrationId, unitSerialNumber, true);
      IsRedirect = true;
    }
  };

  const handleClose = () => setActive(false);

  const handleCommand = commandId => {
    setActive(true);
    setCommandId(commandId);
  };

  const dialogTitle = intl.formatMessage({
    id: 'calibration.delete.confirmation',
    defaultMessage: 'Are you sure you want to delete this calibration?'
  });

  return (
    <div className={s.calibration}>
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      {IsRedirect ? <Redirect to={returnUrl} /> : ''}
      {calibrationManager.isOpSuccessful && !calibrationManager.showBanner ? <Redirect to={returnUrl} /> : ''}
      {calibrationManager.showBanner && (
        <Banner
          key={'b1'}
          failureText={messageText}
          showBanner={calibrationManager.showBanner}
          status={calibrationManager.isOpSuccessful}
          successText={messageText}
        />
      )}
      {calibrationManager.isLoading && <LoadingSpinner />}
      <ContentTemplate
        selectedPage="calibration"
        userProfileWidgets={userProfileWidgets}
        widgetCode={'CALIBRATION_VIEW'}
        tagoutRequired={false}
      >
        <div className={s.contentWrapper}>
          <Row>
            <Col sm={6} lg={8}>
              <div className={s.calibrationHeader}>
                <Link className={s.backLink} to={returnUrl}>
                  &lt; &nbsp;
                  <FormattedMessage id="calibration.backToCalibrations" defaultMessage="BACK" />
                </Link>
                <h3 data-unittest="headingLabel">
                  <FormattedMessage id="calibration.calibration" defaultMessage="Calibration" />
                </h3>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <Link to={`/unit/calibrations/${unitSerialNumber}/edit/${unitId}/${calibrationId}`}>
                <Button className={s.changeCalibration} style={WidgetVisibility(userProfileWidgets, `CALIBRATION_EDIT`)}>
                  <FormattedMessage id="calibration.changeCalibration" defaultMessage="Change" />
                </Button>
              </Link>
            </Col>
          </Row>

          <div className={s.calibrationContent}>
            <Row className={s.breakrow}>
              <Col sm={12}>
                <h4>
                  <FormattedMessage id="calibration.details" defaultMessage="Details" />
                </h4>
              </Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.unit" defaultMessage="Unit" />
                </Form.Label>
              </Col>
              <Col sm={10}>{unitSerialNumber}</Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.reporter" defaultMessage="Reporter" />
                </Form.Label>
              </Col>
              <Col sm={10}>{userName}</Col>
            </Row>

            <hr className={s.breakhr} />
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.sensor" defaultMessage="Sensor" />
                </Form.Label>
              </Col>
              <Col sm={10}>{calibrationManager.SelectedCalibration.ComponentPartName}</Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.calibrationComponent" defaultMessage="Calibration Component" />
                </Form.Label>
              </Col>
              <Col sm={10}>
                {calibrationManager.SelectedCalibration.ComponentPartName +
                  '_' +
                  calibrationManager.SelectedCalibration.CalibrationComponentName}
              </Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.calibrationDatetime" defaultMessage="Calibration Date Time" />
                </Form.Label>
              </Col>
              <Col sm={10}>
                {calibrationManager.SelectedCalibration.CalibrationDateTime
                  ? moment
                      .unix(calibrationManager.SelectedCalibration.CalibrationDateTime)
                      .tz(currentTimezone)
                      .format('DD-MMM-YYYY HH:mm')
                  : '-'}
              </Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.tolerance" defaultMessage="Tolerance" />
                </Form.Label>
              </Col>
              <Col sm={10}>{calibrationManager.SelectedCalibration.CalibrationAcceptedErrorTolerance}</Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={2}>
                <Form.Label>
                  <FormattedMessage id="calibration.sensorRange" defaultMessage="Sensor Range" />
                </Form.Label>
              </Col>
              <Col sm={10}>
                <Row>
                  <Col md={2}>
                    <Form.Label>
                      <FormattedMessage id="calibration.low" defaultMessage="Low" />
                    </Form.Label>{' '}
                    : {calibrationManager.SelectedCalibration.OutputRangeLow}
                  </Col>
                  <Col md={2}>
                    <Form.Label>
                      <FormattedMessage id="calibration.high" defaultMessage="High" />
                    </Form.Label>{' '}
                    : {calibrationManager.SelectedCalibration.OutputRangeHigh}
                  </Col>
                  <Col md={2}>
                    <Form.Label>
                      {' '}
                      <FormattedMessage id="calibration.unitOfMeasure" defaultMessage="Unit Of Measure" />
                    </Form.Label>{' '}
                    : {calibrationManager.SelectedCalibration.UnitOfMeasure}
                  </Col>
                  <Col md={6}></Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <CalibrationResults
          UpdateAllowed={false}
          calibrationManager={calibrationManager}
          setCalibrationResults={null}
          isViewMode={true}
          intl={intl}
        />
        <CalibrationConstants
          UpdateAllowed={false}
          calibrationManager={calibrationManager}
          setCalibrationRequired={null}
          setCalibrationConstant={setCalibrationConstant}
          isViewMode={true}
          intl={intl}
        />
        {isCommentAllowed && (
          <CalibrationComments
            UpdateAllowed={true}
            calibrationManager={calibrationManager}
            intl={intl}
            currentTimezone={currentTimezone}
            saveCalibrationComment={saveCalibrationComment}
            unitSerialNumber={unitSerialNumber}
          />
        )}
        <Row className={s.breakRow}>
          <Col sm={12}>
            <Link to={`/unit/calibrations/${unitSerialNumber}/edit/${unitId}/${calibrationId}`}>
              <Button className={s.changeCalibrationAlt} style={WidgetVisibility(userProfileWidgets, `CALIBRATION_COMMENT_ADD`)}>
                <FormattedMessage id="calibration.changeCalibration" defaultMessage="Change" />
              </Button>
            </Link>
            {calibrationId && (
              <Button
                className={s.deleteButton}
                variant="outline-secondary"
                onClick={handleCommand}
                noValidate
                data-unittest="deleteData"
                style={WidgetVisibility(userProfileWidgets, `CALIBRATIONDELETE`)}
              >
                <FormattedMessage id="calibration.delete" defaultMessage="DELETE CALIBRATIONS" />
              </Button>
            )}
          </Col>
          <Col sm={12} md={2}></Col>
        </Row>
        <CalibrationFinalize
          calibrationManager={calibrationManager}
          saveCalibration={saveCalibration}
          unitSerialNumber={unitSerialNumber}
          updateUnitShadow={updateUnitShadow}
          userProfileWidgets={userProfileWidgets}
          selectedUnit={selectedUnit}
        />
      </ContentTemplate>
    </div>
  );
};

CalibrationView.defaultProps = {
  calibrationManager: {
    ...initialCalibrationState.Calibration
  },
  unitComponentParts: []
};

CalibrationView.propTypes = {
  describeCalibration: PropTypes.func.isRequired
};

export default injectIntl(CalibrationView);
