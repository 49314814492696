import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import s from './NotificationAlert.module.scss';
import bs from '../../../styles/bootstrap-overrides.scss';
import success from '../../../assets/tick-green-filled.svg';
import error from '../../../assets/alert.svg';
import warning from '../../../assets/warning.svg';
import Alert from 'react-bootstrap/Alert';
import info from '../../../assets/info-blue.svg';

const NotificationAlert = ({ status, showBanner, message, closeHandler }) => {
  const iconSrc = status === 'danger' ? error : status === 'warning' ? warning : status === 'success' ? success : info;

  const [show, setShow] = useState(true);

  const localCloseHandler = () => {
    setShow(false);
    closeHandler();
  };

  if (!message || message === '') {
    return <div></div>;
  }
  return (
    <Transition mountOnEnter unmountOnExit timeout={5000} in={showBanner}>
      {state => {
        if (show) {
          return (
            <div
              className={classNames(s.notification, {
                [s.enter]: state === 'entered',
                [s.error]: status === 'danger',
                [s.warning]: status === 'warning',
                [s.success]: status === 'success'
              })}
            >
              <Alert variant={'plain'} className={s.alert} onClose={() => localCloseHandler()} dismissible>
                {iconSrc && <img src={iconSrc} alt="icon" className={s.icon} />}
                {message}
              </Alert>
            </div>
          );
        }
      }}
    </Transition>
  );
};

NotificationAlert.propTypes = {
  status: PropTypes.string,
  failureText: PropTypes.string,
  showBanner: PropTypes.bool.isRequired,
  successText: PropTypes.string
};

export default NotificationAlert;
