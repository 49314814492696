import React from 'react';
import PropTypes from 'prop-types';
import s from './UnitTagOutHistory.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';

const UnitTagOutHistoryTable = ({ paginatedData, onSortOrder, asendingOrder, currentTimezone }) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="maintenance.tagoutHistory.unit" defaultMessage="Unit" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="maintenance.tagoutHistory.outDateTime" defaultMessage="Out Date Time" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="maintenance.tagoutHistory.tagoutUser" defaultMessage="Tagged Out By" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="maintenance.tagoutHistory.inDateTimes" defaultMessage="In Date Time" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="maintenance.tagoutHistory.taginUser" defaultMessage="Tagged In By" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="maintenance.tagoutHistory.description" defaultMessage="Description" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.UnitTagOutId} className={!n.TagInDateTime ? s.new : s.resolved}>
                <td>{n.Name || ''}</td>
                <td className="d-none d-md-table-cell">
                  {n.TagOutDateTime
                    ? moment
                        .unix(n.TagOutDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                </td>

                <td>{n.TagOutByUser || n.Name}</td>
                <td className="d-none d-md-table-cell">
                  {n.TagInDateTime
                    ? moment
                        .unix(n.TagInDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                </td>
                <td className="d-none d-md-table-cell">{n.TagInByUser}</td>
                <td className="d-none d-md-table-cell">{n.Description}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

UnitTagOutHistoryTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

UnitTagOutHistoryTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(UnitTagOutHistoryTable);
