import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './TaskDetail.module.scss';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Activity from '../WSAControls/Activity/Activity';
import uuidv4 from 'uuid/v4';
import Cookies from 'js-cookie';
import { initialTasksState } from '../../reducers/initialState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { compareValues } from '../../utils';

const TaskComments = ({ taskManager, saveTaskComment, UpdateAllowed, intl, currentTimezone }) => {
  const [taskComment, setTaskComment] = useState('');
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const commentChangeHandler = e => {
    setTaskComment(e.target.value);
  };

  const commentSaveHandler = () => {
    if (taskComment.length > 0) {
      const comment = [
        {
          GuidIdentifier: uuidv4(),
          Description: taskComment,
          IsActive: true,
          UpdatedBy: Cookies.get(`userid-${envName}`) || null
        }
      ];

      const saveData = {
        TaskId: taskManager.selectedTask.TaskId,
        Comments: comment
      };
      saveTaskComment(saveData);
      setTaskComment('');
    }
  };

  const commentCancelHandler = () => {
    setTaskComment('');
  };

  const comments = () => {
    let commentstData = taskManager.selectedTask.Comments || [];

    if (commentstData && commentstData.length > 0) {
      commentstData = commentstData.map(comment => ({ ...comment, Id: comment.TaskCommentId }));
    }

    return commentstData.sort(compareValues('ActivityDate', 'desc'));
  };

  const headertext = '';

  return (
    <div className={s.taskComment}>
      {taskManager.isLoading && <LoadingSpinner />}
      <Row className={s.breakrow}>
        <Col sm={12}>
          <h4>
            <FormattedMessage id="tasksManagement.comments" defaultMessage="Comments" />
            <span data-unittest="taskDataCount" className={s.circle}>
              {taskManager.selectedTask.Comments.length}
            </span>
          </h4>
        </Col>
      </Row>
      <Row className={s.breakrow}>
        <Col sm={12}>
          <Activity
            description={taskComment || ''}
            onActivityModified={commentChangeHandler}
            onSaveClick={commentSaveHandler}
            onCancelClick={commentCancelHandler}
            currentTimezone={currentTimezone}
            activities={comments()}
            isUpdateAllowed={UpdateAllowed}
            isViewAllowed={true}
            headingText={headertext}
          />
        </Col>
      </Row>
    </div>
  );
};

TaskComments.defaultProps = {
  taskManager: {
    ...initialTasksState.tasks
  }
};

TaskComments.propTypes = {
  taskManager: PropTypes.object.isRequired,
  saveTaskComment: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  UpdateAllowed: PropTypes.bool.isRequired
};

export default TaskComments;
