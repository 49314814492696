import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CalibrationView from './CalibrationView';
import { getCalibration, getUserProfileWidget, getCurrentTimezone, getSelectedDevice } from '../../selectors/index';
import {
  describeCalibrationThunk,
  saveCalibrationCommentThunk,
  saveCalibrationThunk,
  deleteCalibrationThunk,
  updateUnitShadowThunk
} from '../../actions/calibration';

const mapStateToProps = state => {
  return {
    calibrationManager: getCalibration(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state),
    selectedUnit: getSelectedDevice(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeCalibration: (calibrationId, unitSerialNumber) =>
        describeCalibrationThunk(calibrationId, unitSerialNumber, 'CALIBRATION_VIEW'),
      saveCalibration: (calibration, unitSerialNumber, isOpSuccessful, widgetCode) =>
        saveCalibrationThunk(calibration, unitSerialNumber, isOpSuccessful, widgetCode),
      deleteCalibration: (calibrationId, unitSerialNumber, isOpSuccessful) =>
        deleteCalibrationThunk(calibrationId, unitSerialNumber, isOpSuccessful, 'CALIBRATIONDELETE'),
      saveCalibrationComment: (calibration, unitSerialNumber, widgetCode) =>
        saveCalibrationCommentThunk(calibration, unitSerialNumber, widgetCode),
      updateUnitShadow: (calibration, unitSerialNumber, shadowData, isOpSuccessful) =>
        updateUnitShadowThunk(calibration, unitSerialNumber, shadowData, isOpSuccessful, 'UPLOAD_UNIT_SHADOW')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CalibrationView);
