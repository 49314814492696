import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import { APP_VERSION } from '../constants';
import { USER_LOGIN } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const checkAndRedirect = (isAuthenticated, redirect) => {
  if (!isAuthenticated) {
    redirect();
  }
};

export const isValidEmail = value => {
  const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegEx.test(value);
};

export const isValidPassword = value => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@£$%^&*()_\-=+#{}[\]`~;'\\:"|,./<>?]{10,}$/;
  return passwordRegex.test(value);
};

export const arrayToObject = (arr, keyField) =>
  arr.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

export const filterObject = (obj, keyField, filterValue) =>
  Object.keys(obj).reduce(
    (acc, val) =>
      obj[val][keyField] === filterValue
        ? acc
        : {
            ...acc,
            [val]: obj[val]
          },
    {}
  );

export const isRequired = (value, characters) => {
  return value.length < characters;
};

export const isGraterThan = (value, characters) => {
  return value.length > characters;
};

export const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => {
    if (val.required && val.value.length === 0) {
      valid = false;
    }
  });
  Object.values(errors).forEach(val => val.error.length > 0 && (valid = false));
  return valid;
};

export const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};

export const convertNametoSequenceKey = (name = '') => name.replace(/\s/g, '').toLowerCase();

export const apiAction = ({
  accessToken = null,
  data = null,
  method = 'GET',
  onLoad = null,
  onSuccess = () => {},
  onFailure = null,
  skipCallback = false,
  skipRefresh = false,
  url = '',
  widgetcode = null,

  accessid = null
}) => ({
  type: 'API',
  payload: {
    accessToken,
    data,
    method,
    onLoad,
    onSuccess,
    onFailure,
    skipCallback,
    skipRefresh,
    url,
    widgetcode,
    accessid
  }
});

export const geocode = async address => {
  return new Promise(function(resolve, reject) {
    try {
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, function(results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          resolve(results);
        } else {
          reject(status);
        }
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const isMobile = () => {
  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobile;
};

export const logEntry = (functionName, action, payload = {}, unitId = 0, sessionId = '') => {
  let log = {
    Timestamp: moment().unix(),
    Username: Cookies.get(`username-${envName}`) ? Cookies.get(`username-${envName}`) : action === USER_LOGIN ? payload.username : '',
    SessionId: sessionId,
    UserId: Cookies.get(`userid-${envName}`) ? Cookies.get(`userid-${envName}`) : action === USER_LOGIN ? payload.username : '',
    OrganisationId: Cookies.get(`selectedorganisationid-${envName}`),
    UnitId: unitId,
    Function: functionName,
    Action: action,
    Payload: JSON.stringify(payload)
  };
  return log;
};

//USAGE

// // array is sorted by band, in ascending order by default
// singers.sort(compareValues('band'));

// // array is sorted by band in descending order
// singers.sort(compareValues('band', 'desc'));

// // array is sorted by name in ascending order
// singers.sort(compareValues('name'));

// // array is sorted by date if birth in descending order
// singers.sort(compareValues('born', 'desc'));

export const compareValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export const getQuerystring = queryName => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get(queryName);
};

export const getOverdueStatus = dueDateTime => {
  if (dueDateTime) {
    let dueDate = moment.unix(parseInt(dueDateTime));
    let currentDate = moment();
    let diff = dueDate.diff(currentDate, 'days');

    if (diff >= 0) return `${diff} days`;
    else return 'Overdue';
  }
};

export const groupBy = key => array =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
    }),
    {}
  );

export const sortObjectKeys = objKeys => {
  return (
    Object.keys(objKeys)

      // Sort and calling a method on
      // keys on sorted fashion.
      .sort()
      .reduce(function(Obj, key) {
        // Adding the key-value pair to the
        // new object in sorted keys manner
        Obj[key] = objKeys[key];
        return Obj;
      }, {})
  );
};

export const getFormattedate = (date, currentTimezone) => {
  if (date && typeof date === 'number') {
    return moment(date).tz(currentTimezone);
  }

  return date;
};

export const getDateInDDMMYYYHHMMSSFormat = (date, currentTimezone) => {
  if (!date || !currentTimezone) return '';
  return moment
    .unix(date)
    .tz(currentTimezone)
    .format('DD-MMM-YYYY HH:mm:ss z');
};

export const timeOptions = () => {
  const timelist = [
    {
      label: '1 hour',
      value: 3600
    }
  ];

  for (let i = 2; i <= 48; +i++) {
    timelist.push({
      label: `${i} hours`,
      value: i * 3600
    });
  }
  return timelist;
};

export const downloadCsvFile = (data, fileName) => {
  const element = document.createElement('a');
  const file = new Blob([data], { type: 'text/csv' });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export const flattenObject = obj => {
  const flattened = {};

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};

export const getSequenceName = (allsequences, stateName) => {
  let sequence = { Name: '' };
  if (!stateName) return sequence;
  stateName = stateName.toUpperCase().replace('STATE', '');
  sequence.Name = stateName;
  if (allsequences.length > 0) {
    let obj = allsequences.find(x => x.Code.toUpperCase() == stateName);
    if (obj) {
      sequence.Code = obj.Code;
      sequence.Name = obj.Name;
      sequence.SequenceCode = obj.SequenceCode;
      sequence.Description = obj.Description;
      sequence.TranslationCode = obj.TranslationCode;
    }
  }
  return sequence;
};
