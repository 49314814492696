import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import EventAction from './EventAction';
import { getCurrentTimezone, getUserProfileWidget, getEventAction, getUserManager } from '../../selectors/index';
import { fetchEventActionThunk, postEventActivitiyThunk, onActivityChangeThunk, eventActionCleanUpThunk } from '../../actions/eventAction';
import { getUsersThunk } from '../../actions/userManager';

const mapStateToProps = state => {
  return {
    currentTimezone: getCurrentTimezone(state),
    eventAction: getEventAction(state),
    userProfileWidgets: getUserProfileWidget(state),
    userManager: getUserManager(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEventAction: eventActionId => fetchEventActionThunk(eventActionId, 'ALARMMANAGE'),
      getUsers: orgId => getUsersThunk(null, null, orgId, 'ALARMMANAGE'),
      postEventActivitiy: () => postEventActivitiyThunk('ALARMMANAGE'),
      onActivitychange: newActivity => onActivityChangeThunk(newActivity),
      eventActionCleanUp: eventAction => eventActionCleanUpThunk(eventAction)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventAction);
