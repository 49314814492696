import {
  GET_ALL_ALARMS_DETAILS,
  ALARMS_IS_LOADING,
  ALARMS_ERROR,
  GET_ALL_ALARMS_FILTERS,
  SET_ALARMS_FILTERED_RESULTS,
  SET_ALARMS_CURRENT_PAGE,
  SET_ALARMS_PAGE_FILTER
} from '../constants/index';
import { initialAlarmsState } from './initialState';

const alarms = (state = initialAlarmsState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_ALARMS_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        alarms: {
          ...state.alarms,
          ...data
        }
      };
    }

    case GET_ALL_ALARMS_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        alarms: {
          ...state.alarms,
          ...data
        }
      };
    }

    case SET_ALARMS_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        alarms: {
          ...state.alarms,
          ...filteredResults
        }
      };
    }

    case ALARMS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        alarms: {
          ...state.alarms,
          isLoading: isLoading
        }
      };
    }

    case ALARMS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        alarms: {
          ...state.alarms,
          ...data
        }
      };
    }

    case SET_ALARMS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        alarms: {
          ...state.alarms,
          currentPage
        }
      };
    }

    case SET_ALARMS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        alarms: {
          ...state.alarms,
          filterBy: {
            ...filter
          }
        }
      };
    }

    default:
      return state;
  }
};

export default alarms;
