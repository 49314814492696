import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  UNITSTATISTICS_ERROR,
  SET_UNITSTATISTICS_SETTINGS,
  UNITSTATISTICS_IS_LOADING,
  SET_UNITSTATISTICS_CHANGE,
  SET_UNITSTATISTICS,
  UPDATE_UNITSTATISTICS_SAVE_STATUS,
  UNITSTATISTICS_CLEANUP
} from '../constants/index';
import { UNITSTATISTICS_MANAGEMENT, SAVE_UNITSTATISTICS } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setUnitStatisticsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setUnitStatisticsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitStatisticsError(messageCode, false));
  }, 2500);
};

export const setUnitStatisticsError = (messageCode, status) => {
  return {
    type: UNITSTATISTICS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: UNITSTATISTICS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getUnitStatisticsSettingsThunk = widget => dispatch => {
  dispatch(getUnitStatisticsSettings(widget));
};

export const getUnitStatisticsSettings = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/unitstatistics/settings`,
    data: {
      UserId: Cookies.get(`userid-${envName}`)
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setUnitStatisticsSettingsThunk(retData),
    onFailure: err => setUnitStatisticsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setUnitStatisticsSettingsThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let UnitStatistics = (retData && retData.UnitStatistics) || {};
  dispatch(setUnitStatisticsSettings(messageCode, UnitStatistics));
};

export const setUnitStatisticsSettings = (messageCode, UnitStatistics) => ({
  type: SET_UNITSTATISTICS_SETTINGS,
  payload: {
    data: {
      unitStatistics: UnitStatistics,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const saveUnitStatisticsSettingsThunk = (statisticSettings, widgetCode) => dispatch => {
  let url = '/device/unitstatistics/settings/save';
  statisticSettings.UpdatedBy = Cookies.get(`userid-${envName}`);
  let actionName = SAVE_UNITSTATISTICS;
  let log = logEntry(UNITSTATISTICS_MANAGEMENT, actionName, statisticSettings);
  dispatch(saveUnitStatisticsSettings(statisticSettings, url, log, widgetCode));
};

export const saveUnitStatisticsSettings = (statisticSettings, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setUnitStatisticsSaveStatusThunk(retData),
    onFailure: err => setUnitStatisticsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      statisticSettings: statisticSettings,
      log: log
    },
    widgetcode: widgetCode
  });

export const setUnitStatisticsSaveStatusThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let UnitStatistics = (retData && retData.UnitStatistics) || {};
  dispatch(setUnitStatisticsSaveStatus(UnitStatistics, messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitStatisticsSaveStatus(UnitStatistics, messageCode, false));
  }, 2500);
};

export const setUnitStatisticsSaveStatus = (UnitStatistics, messageCode, status) => ({
  type: UPDATE_UNITSTATISTICS_SAVE_STATUS,
  payload: {
    data: {
      ...UnitStatistics,
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setUnitStatisticsChangeThunk = retData => dispatch => {
  dispatch(setUnitStatisticsChange(retData));
};

export const setUnitStatisticsChange = retData => ({
  type: SET_UNITSTATISTICS_CHANGE,
  payload: retData
});

export const getUnitStatisticsThunk = (widget, payload, fromDate, toDate) => dispatch => {
  dispatch(getUnitStatistics(widget, payload, fromDate, toDate));
};

export const getUnitStatistics = (widgetCode, payload, fromDate, toDate) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/unitstatistics`,
    data: {
      UserId: Cookies.get(`userid-${envName}`) || 0,
      organisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      fromDate: fromDate,
      toDate: toDate,
      statisticTypes: payload.statisticTypes,
      unitSerialNumbers: payload.unitSerialNumbers || [],
      fromDashboard: payload.fromDashboard || false
    },
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setUnitStatisticsThunk(retData),
    onFailure: err => setUnitStatisticsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setUnitStatisticsThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  dispatch(setUnitStatistics(messageCode, retData.statistics));
};

export const setUnitStatistics = (messageCode, statistics) => ({
  type: SET_UNITSTATISTICS,
  payload: {
    data: {
      statistics: statistics,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const cleanUpThunk = retData => dispatch => {
  dispatch(cleanUp(retData));
};

export const cleanUp = retData => ({
  type: UNITSTATISTICS_CLEANUP,
  payload: retData
});
