import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserList from './UserList';
import { getUserManager, getUserProfileWidget } from '../../selectors/index';
import { getUsersThunk, setCurrentPage, setPageFilter } from '../../actions/userManager';

const mapStateToProps = state => {
  return {
    userManager: getUserManager(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsers: (limit, filter, orgId) => getUsersThunk(limit, filter, orgId, 'USERS_TAB'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
