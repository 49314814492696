import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ComponentPartList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialComponentPartsState } from '../../reducers/initialState';
import ComponentPartsListTable from './ComponentPartsListTable';
import Pagination from '../Pagination/Pagination';
import { Link } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { IsWidgetAccessible, WidgetVisibility } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import Button from 'react-bootstrap/Button';
import MaintenanceContentTemplate from '../Maintenance/MaintenanceContentTemplate';
import Cookies from 'js-cookie';

const BRAND = 'BRAND';
const MODEL = 'MODEL';
const COMPONENT_TYPE = 'COMPONENT_TYPE';
const UNIT = 'UNIT';
const ALLOCATED = 'ALLOCATED';
const UNIT_ID = 'UNIT_ID';
const SPARE = 'SPARE';
const SEARCH_FILTER = 'SEARCH_FILTER';

const ComponentPartList = ({
  getComponentParts,
  getComponentPartFilters,
  setFilterData,
  setCurrentPage,
  setPageFilter,
  componentParts,
  redirect,
  intl,
  userProfileWidgets,
  currentTimezone,
  userProfileUnits
}) => {
  let filter = componentParts.filterBy.filter || '';
  let offset = componentParts.offset || 0;
  let limit = componentParts.limit || 1000;

  let filterByBrand = componentParts.filterBy.filterBrand || null;
  let filterByModel = componentParts.filterBy.filterModel || null;
  let filterByComponentType = componentParts.filterBy.filterComponentType || null;
  let filterByUnit = componentParts.filterBy.filterUnit || null;
  let filterByUnitId = componentParts.filterBy.filterUnitId || null;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterBrand, setFilterBrand] = useState(0);
  const [filterModel, setFilterModel] = useState(0);
  const [filterComponentType, setFilterComponentType] = useState(0);
  const [filterUnit, setFilterUnit] = useState(0);
  const [filterUnitId, setFilterUnitId] = useState(0);

  const [unitIdDisabled, setUnitIdDisabled] = useState(false);
  const [asendingOrder, setAsendingOrder] = useState(false);

  let userUnits = [];
  if (userProfileUnits && userProfileUnits.userProfileUnits) {
    userUnits = userProfileUnits.userProfileUnits.filter(unit => unit.OrganisationId == OrganisationId);
  }

  useEffect(() => {
    let filterObj = {
      filterBrand: filterByBrand,
      filterModel: filterByModel,
      filterComponentType: filterByComponentType,
      filterUnit: filterByUnit,
      filterUnitId: filterByUnitId,
      offset: offset,
      limit: limit
    };
    getComponentParts(filterObj);
  }, [getComponentParts, offset, limit, filter, filterByBrand, filterByModel, filterByComponentType, filterByUnit, filterByUnitId]);

  useEffect(() => {
    getComponentPartFilters();
  }, [getComponentPartFilters]);

  const brandOptions = () => {
    let brandsData = [];
    brandsData.push({
      label: <FormattedMessage id="componentParts.allBrands" defaultMessage="All Brands" />,
      value: 0
    });

    componentParts.filterData.brands &&
      componentParts.filterData.brands.forEach(element => {
        brandsData.push({
          label: element.Brand,
          value: element.ApprovedComponentId
        });
      });
    return brandsData;
  };

  const modelOptions = () => {
    let modelsData = [];

    modelsData.push({
      label: <FormattedMessage id="componentParts.allModels" defaultMessage="All Models" />,
      value: 0
    });

    componentParts.filterData.models.forEach(element => {
      modelsData.push({
        label: element.Model,
        value: element.ApprovedComponentId
      });
    });
    return modelsData;
  };

  const componentTypeOptions = () => {
    let componentTypeData = [];

    componentTypeData.push({
      label: <FormattedMessage id="componentParts.allComponentTypes" defaultMessage="All Types" />,
      value: 0
    });

    componentParts.filterData.componentTypes.forEach(element => {
      componentTypeData.push({
        label: element.Name,
        value: element.ModelComponentTypeId
      });
    });
    return componentTypeData;
  };

  const unitOptions = () => {
    let unitData = [];

    unitData.push({
      label: <FormattedMessage id="componentParts.allUnits" defaultMessage="All" />,
      value: 0
    });

    unitData.push({
      label: ALLOCATED,
      value: 'True'
    });

    unitData.push({
      label: SPARE,
      value: 'False'
    });

    return unitData;
  };

  const unitIdOptions = () => {
    let unitIdsData = [];

    unitIdsData.push({
      label: <FormattedMessage id="componentParts.allUnitIds" defaultMessage="All Units" />,
      value: 0
    });

    userUnits.forEach(element => {
      unitIdsData.push({
        label: element.UnitSerialNumber,
        value: element.UnitId
      });
    });
    return unitIdsData;
  };

  const onBrandChange = e => {
    setFilterBrand(e.value);
    filteredResults(BRAND, e.value);
  };

  const onModelChange = e => {
    setFilterModel(e.value);
    filteredResults(MODEL, e.value);
  };

  const onComponentTypeChange = e => {
    setFilterComponentType(e.value);
    filteredResults(COMPONENT_TYPE, e.value);
  };

  const onUnitChange = e => {
    setFilterUnit(e.value);
    if (e.value === 'True' || e.value === 0) {
      setUnitIdDisabled(false);
    } else {
      setUnitIdDisabled(true);
    }
    filteredResults(UNIT, e.value);
  };

  const onUnitIdChange = e => {
    setFilterUnitId(e.value);
    filteredResults(UNIT_ID, e.value);
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  let componentPartsObj = Object.values(componentParts.filteredResults);

  if (asendingOrder) {
    componentPartsObj = componentPartsObj.sort(compareValues('SerialNumber'));
  } else {
    componentPartsObj = componentPartsObj.sort(compareValues('SerialNumber', 'desc'));
  }

  const filteredResults = (filterType, value) => {
    let filteredList = componentParts.componentPartsList;

    let apifilterObj = {
      filter: null,
      filterBrand: null,
      filterModel: null,
      filterComponentType: null,
      filterUnit: null,
      filterUnitId: null
    };

    if (filterType === BRAND) {
      if (value !== 0) {
        apifilterObj.filterBrand = value;
        filteredList = filteredList.filter(function(item) {
          return item.ApprovedComponentId === value;
        });
      }
    } else {
      if (filterBrand !== 0) {
        apifilterObj.filterBrand = filterBrand;
        filteredList = filteredList.filter(function(item) {
          return item.ApprovedComponentId === filterBrand;
        });
      }
    }

    if (filterType === MODEL) {
      if (value !== 0) {
        apifilterObj.filterModel = value;
        filteredList = filteredList.filter(function(item) {
          return item.ApprovedComponentId === value;
        });
      }
    } else {
      if (filterModel !== 0) {
        apifilterObj.filterModel = filterModel;
        filteredList = filteredList.filter(function(item) {
          return item.ApprovedComponentId === filterModel;
        });
      }
    }

    if (filterType === COMPONENT_TYPE) {
      if (value !== 0) {
        apifilterObj.filterComponentType = value;
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentTypeId === value;
        });
      }
    } else {
      if (filterComponentType !== 0) {
        apifilterObj.filterComponentType = filterComponentType;
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentTypeId === filterComponentType;
        });
      }
    }

    if (filterType === UNIT) {
      if (value !== 0) {
        apifilterObj.filterUnit = value;
        if (value === 'True') {
          filteredList = filteredList.filter(function(item) {
            return item.UnitId > 0;
          });
        } else {
          filteredList = filteredList.filter(function(item) {
            return item.UnitId === 0 || item.UnitId === null;
          });
        }
      }
    } else {
      if (filterUnit !== 0) {
        apifilterObj.filterUnit = filterUnit;
        if (filterUnit === 'True') {
          filteredList = filteredList.filter(function(item) {
            return item.UnitId > 0;
          });
        } else {
          filteredList = filteredList.filter(function(item) {
            return item.UnitId === 0 || item.UnitId === null;
          });
        }
      }
    }

    if (filterType === UNIT_ID) {
      if (value !== 0) {
        apifilterObj.filterUnitId = value;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === value;
        });
      }
    } else {
      if (filterUnitId !== 0) {
        apifilterObj.filterUnitId = filterUnitId;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === filterUnitId;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.SerialNumber.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.SerialNumber.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (componentParts.componentPartsListCount > componentParts.componentPartsList.length) {
      setPageFilter(apifilterObj);
    } else {
      setFilterData({ filteredResults: { filteredResults: filteredList } });
    }
  };

  let messageId = (componentParts && componentParts.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'PARTSVIEW');

  return (
    <div className={s.componentParts}>
      <MaintenanceContentTemplate selectedPage="parts">
        <div className={s.fullWidth}>
          <div className={s.fullWidthWrapper}>
            <h4 className={s.tabHeader}>{<FormattedMessage id="componentParts.parts" defaultMessage="Parts" />}</h4>
          </div>
        </div>

        <div className={s.pageContent}>
          <div>
            {componentParts.isLoading && <LoadingSpinner />}
            <Banner
              failureText={messageText}
              showBanner={componentParts.showBanner}
              componentType={componentParts.isOpSuccessful}
              successText={messageText}
            />
            <div className={s.contentWrapper}>
              <Row>
                <Col sm={12} lg={3}>
                  <SearchBar
                    searchHandler={searchHandler}
                    clearSearchInVisible={false}
                    initialText={searchFilter}
                    placeHolderTextId="componentParts.serialNumber"
                  />
                </Col>
                <Col sm={12} lg={5}>
                  {' '}
                </Col>
                <Col sm={12} lg={2}>
                  <div className="float-right">
                    {/* <Link to="#">
                    <Button className={`${s.addbutton} mr-2`} style={WidgetVisibility(userProfileWidgets, `PARTSUPLOADJSON`)}>
                      + <FormattedMessage id="componentParts.uploadJson" defaultMessage="Upload .JSON File" />
                    </Button>
                  </Link> */}
                  </div>
                </Col>
                <Col sm={12} lg={2}>
                  <div className="float-right">
                    <Link to="/maintenance/parts/part">
                      <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `PARTSADDNEW`)}>
                        + <FormattedMessage id="componentParts.addNew" defaultMessage="ADD NEW" />
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <span className={s.textHeading}>
                    {' '}
                    <FormattedMessage id="componentParts.filterBy" defaultMessage="Filter by" />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpBrandId"
                    dataArray={brandOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="componentParts.brand" defaultMessage="Brand" />,
                      customClassName: ''
                    }}
                    onDropdownChange={e => onBrandChange(e)}
                    selectedOption={brandOptions().filter(option => option.value === filterBrand)}
                    disabled={false}
                  />
                </Col>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpModelId"
                    dataArray={modelOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="componentParts.model" defaultMessage="Model" />,
                      customClassName: ''
                    }}
                    onDropdownChange={e => onModelChange(e)}
                    selectedOption={modelOptions().filter(option => option.value === filterModel)}
                    disabled={false}
                  />
                </Col>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpComponentTypeId"
                    dataArray={componentTypeOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="componentParts.componentType" defaultMessage="Type" />,
                      customClassName: ''
                    }}
                    onDropdownChange={e => onComponentTypeChange(e)}
                    selectedOption={componentTypeOptions().filter(option => option.value === filterComponentType)}
                    disabled={false}
                  />
                </Col>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpUnitId"
                    dataArray={unitOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="componentParts.unitAllocated" defaultMessage="Allocated" />,
                      customClassName: ''
                    }}
                    onDropdownChange={e => onUnitChange(e)}
                    selectedOption={unitOptions().filter(option => option.value === filterUnit)}
                    disabled={false}
                  />
                </Col>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpUnitSerialId"
                    dataArray={unitIdOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="componentParts.unitId" defaultMessage="Unit" />,
                      customClassName: ''
                    }}
                    onDropdownChange={e => onUnitIdChange(e)}
                    selectedOption={unitIdOptions().filter(option => option.value === filterUnitId)}
                    disabled={unitIdDisabled}
                  />
                </Col>
                <Col sm={6} lg={2}></Col>
              </Row>

              <div className="">
                <Pagination
                  data={componentPartsObj}
                  pageSize={DEFAULT_PAGE_SIZE}
                  onPageChange={onPageChangeHandler}
                  startingPage={componentParts.currentPage}
                >
                  <ComponentPartsListTable
                    ViewAllowed={isViewAllowed}
                    currentPage={componentParts.currentPage}
                    onSortOrder={onSortOrder}
                    asendingOrder={asendingOrder}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </MaintenanceContentTemplate>
    </div>
  );
};

ComponentPartList.defaultProps = {
  ...initialComponentPartsState
};

ComponentPartList.propTypes = {
  getComponentParts: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  getComponentPartFilters: PropTypes.func.isRequired,
  componentParts: PropTypes.object.isRequired
};

export default injectIntl(ComponentPartList);
