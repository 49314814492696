import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  GET_ALL_MODEL_FIRMWARE_VERSION_DETAILS,
  UPDATE_MODEL_FIRMWARE_VERSION_SAVE_STATUS,
  MODEL_FIRMWARE_VERSION_IS_LOADING,
  MODEL_FIRMWARE_VERSION_ERROR,
  SET_MODEL_FIRMWARE_VERSION_CURRENT_PAGE,
  SET_MODEL_FIRMWARE_VERSION_PAGE_FILTER,
  SET_MODEL_FIRMWARE_VERSION_DETAILS,
  SET_MODEL_FIRMWARE_VERSION_CHANGE,
  SET_MODEL_FIRMWARE_VERSION_FILTERED_RESULTS,
  MODEL_FIRMWARE_VERSION_CLEANUP,
  SET_ALL_COMPONENT_POSITION_MODEL_FIRMWARE_VERSION,
  SET_MODEL_FIRMWARE_VERSION_UNITS_CURRENT_PAGE,
  SET_MODEL_FIRMWARE_VERSION_UNITS_PAGE_FILTER,
  SET_MODEL_FIRMWARE_VERSION_UNITS_FILTERED_RESULTS
} from '../constants/index';

import {
  MODEL_FIRMWARE_VERSION_MANAGEMENT,
  UPDATE_MODEL_FIRMWARE_VERSION,
  CREATE_MODEL_FIRMWARE_VERSION,
  PUBLISH_MODEL_FIRMWARE_VERSION
} from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

//Model Management
export const setModelFirmwareVersionErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;

  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setModelFirmwareVersionError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelFirmwareVersionError(messageCode, false));
  }, 2500);
};

export const setModelFirmwareVersionError = (messageCode, status) => ({
  type: MODEL_FIRMWARE_VERSION_ERROR,
  payload: {
    data: {
      isOpSuccessful: false,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const getModelFirmwareVersionsThunk = (modelId, offset, limit, filter, widgetCode) => dispatch => {
  dispatch(getModelFirmwareVersion(modelId, offset, limit, filter, widgetCode));
};

export const setLoadingStatus = isLoading => ({
  type: MODEL_FIRMWARE_VERSION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getModelFirmwareVersion = (modelId, offset, limit, filter, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/model/firmware`,
    data: {
      ModelId: modelId,
      Offset: offset,
      Limit: limit,
      Filter: filter
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllModelFirmwareVersionListThunk(retData),
    onFailure: err => setModelFirmwareVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllModelFirmwareVersionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelFirmwareVersions = (retData && retData.modelFirmwareVersions) || [];
  dispatch(setAllModelFirmwareVersionList(messageCode, modelFirmwareVersions));
};

export const setAllModelFirmwareVersionList = (messageCode, modelFirmwareVersions) => ({
  type: GET_ALL_MODEL_FIRMWARE_VERSION_DETAILS,
  payload: {
    data: {
      modelFirmwareVersions: modelFirmwareVersions,
      filteredResults: modelFirmwareVersions,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_MODEL_FIRMWARE_VERSION_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_MODEL_FIRMWARE_VERSION_PAGE_FILTER,
  payload: { filter }
});

export const postModelFirmwareVersionThunk = (modelFirmwareVersion, widgetCode) => dispatch => {
  let url = '/management/model/firmware/save';
  let actionName = modelFirmwareVersion.ModelFirmwareVersionId === 0 ? CREATE_MODEL_FIRMWARE_VERSION : UPDATE_MODEL_FIRMWARE_VERSION;

  if (modelFirmwareVersion.IsCurrentVersion) actionName = PUBLISH_MODEL_FIRMWARE_VERSION;
  let log = logEntry(MODEL_FIRMWARE_VERSION_MANAGEMENT, actionName, modelFirmwareVersion);
  dispatch(saveModelFirmwareVersion(modelFirmwareVersion, url, log, widgetCode));
};

export const saveModelFirmwareVersion = (modelFirmwareVersion, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    widgetcode: widgetCode,
    onLoad: status => setLoadingStatus(status),
    onSuccess: message => setModelFirmwareVersionStatusThunk(message),
    onFailure: err => setModelFirmwareVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      ModelFirmwareVersion: modelFirmwareVersion,
      log: log
    }
  });

export const setModelFirmwareVersionStatusThunk = message => dispatch => {
  dispatch(setModelFirmwareVersionStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelFirmwareVersionStatus('', false));
  }, 2500);
};

export const setModelFirmwareVersionStatus = (messageCode, status) => ({
  type: UPDATE_MODEL_FIRMWARE_VERSION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const describeModelFirmwareVersionThunk = (modelFirmwareVersionId, modelId, widgetCode) => dispatch => {
  dispatch(describeModelFirmwareVersion(modelFirmwareVersionId, modelId, widgetCode));
};

export const describeModelFirmwareVersion = (modelFirmwareVersionId, modelId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/model/firmware`,
    data: {
      ModelFirmwareVersionId: modelFirmwareVersionId || undefined,
      ModelId: modelId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setdescribeModelFirmwareVersionThunk(retData),
    onFailure: err => setModelFirmwareVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribeModelFirmwareVersionThunk = retData => dispatch => {
  dispatch(setDescribeModelFirmwareVersion(retData));
};

export const setDescribeModelFirmwareVersion = retData => ({
  type: SET_MODEL_FIRMWARE_VERSION_DETAILS,
  payload: retData
});

export const setModelFirmwareVersionChangeThunk = retData => dispatch => {
  dispatch(setModelFirmwareVersionChange(retData));
};

export const setModelFirmwareVersionChange = retData => ({
  type: SET_MODEL_FIRMWARE_VERSION_CHANGE,
  payload: retData
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_MODEL_FIRMWARE_VERSION_FILTERED_RESULTS,
  payload: retData
});

export const modelFirmwareVersionCleanUpThunk = model => dispatch => {
  dispatch(modelFirmwareVersionCleanUp(model));
};

export const modelFirmwareVersionCleanUp = model => ({
  type: MODEL_FIRMWARE_VERSION_CLEANUP,
  payload: {
    data: model
  }
});

// Component Positions

export const setComponentPositionsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setComponentPositionsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setComponentPositionsError(messageCode, false));
  }, 2500);
};

export const setComponentPositionsError = (messageCode, status) => {
  return {
    type: MODEL_FIRMWARE_VERSION_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getComponentPositionsThunk = (modelId, widgetCode) => dispatch => {
  if (modelId) {
    dispatch(getComponentPositions(modelId, widgetCode));
  }
};

export const setComponentPositionsLoadingStatus = isLoading => ({
  type: MODEL_FIRMWARE_VERSION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getComponentPositions = (modelId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/model/componentposition`,
    data: {
      ModelId: modelId,
      FirmwareComponent: true
    },
    method: 'GET',
    onLoad: status => setComponentPositionsLoadingStatus(status),
    onSuccess: retData => setAllComponentPositionsListThunk(retData),
    onFailure: err => setComponentPositionsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllComponentPositionsListThunk = ({ retData }) => dispatch => {
  let messageCode = retData && retData.message;
  let componentPositionDetails = (retData && retData.componentPositionDetails) || [];
  dispatch(setAllComponentPositionsList(messageCode, componentPositionDetails));
};

export const setAllComponentPositionsList = (messageCode, componentPositionDetails) => ({
  type: SET_ALL_COMPONENT_POSITION_MODEL_FIRMWARE_VERSION,
  payload: {
    data: {
      ModelComponentPositions: componentPositionDetails,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setModelUnitsCurrentPage = currentPage => ({
  type: SET_MODEL_FIRMWARE_VERSION_UNITS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setModelUnitsPageFilter = filter => ({
  type: SET_MODEL_FIRMWARE_VERSION_UNITS_PAGE_FILTER,
  payload: { filter }
});

export const getModelUnitsFilteredDataThunk = (
  modelId,
  modelFirmwareVersionId,
  modelComponentPositionId,
  offset,
  limit,
  filter,
  widgetCode
) => dispatch => {
  if (modelId && modelComponentPositionId) {
    dispatch(getModelUnitsFilteredData(modelId, modelFirmwareVersionId, modelComponentPositionId, offset, limit, filter, widgetCode));
  }
};

export const getModelUnitsFilteredData = (modelId, modelFirmwareVersionId, modelComponentPositionId, offset, limit, filter, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/model/firmware`,
    data: {
      ModelId: modelId,
      ModelFirmwareVersionId: modelFirmwareVersionId || 0,
      ModelComponentPositionId: modelComponentPositionId,
      filter: filter,
      offset: offset,
      limit: limit
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setModelUnitsFilteredDataThunk(retData),

    onFailure: err => setModelFirmwareVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setModelUnitsFilteredDataThunk = retData => dispatch => {
  dispatch(setModelUnitsFilteredData(retData));
};

export const setModelUnitsFilteredData = retData => ({
  type: SET_MODEL_FIRMWARE_VERSION_UNITS_FILTERED_RESULTS,
  payload: retData
});
