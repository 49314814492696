import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TaskTemplate from './TaskTemplate';
import { getTaskTemplateManager, getUserProfileWidget, getProfile } from '../../selectors/index';
import { getProfileThunk } from '../../actions/profiles';
import { describeTaskTemplateThunk, saveTaskTemplateThunk, setTaskTemplateChangeThunk } from '../../actions/taskTemplates';

const mapStateToProps = state => {
  return {
    taskTemplateManager: getTaskTemplateManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    profile: getProfile(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeTaskTemplate: (taskTemplateId, widgetCode) => describeTaskTemplateThunk(taskTemplateId, widgetCode),
      saveTaskTemplate: (taskTemplate, widgetCode) => saveTaskTemplateThunk(taskTemplate, widgetCode),
      changeTaskTemplate: taskTemplate => setTaskTemplateChangeThunk(taskTemplate),
      getProfileInfo: widgetCode => getProfileThunk(widgetCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TaskTemplate);
