import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './UnitServiceDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialUnitServiceState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Link, useParams, useHistory } from 'react-router-dom';
import bs from '../../styles/bootstrap-overrides.scss';
import UnitPartService from './UnitPartService';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues, getDateInDDMMYYYHHMMSSFormat } from '../../utils';

import Cookies from 'js-cookie';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import MaintenanceContentTemplate from '../Maintenance/MaintenanceContentTemplate';
import GoBack from '../WSAControls/GoBack/GoBack';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import {
  describeUnitService,
  setUnitServiceChange,
  saveUnitServiceThunk,
  getComponentPart,
  setAllComponentPartList
} from '../../actions/unitServices';
import { getUnitService, getCurrentTimezone, getUserProfileWidget, getUserProfileUnit, getServiceTypes } from '../../selectors/index';
import classNames from 'classnames';

const UnitServicesDetailView = ({ intl }) => {
  let IsEdit = false;
  const history = useHistory();
  const dispatch = useDispatch();

  let { unitServiceId, checklistId } = useParams();
  unitServiceId = (unitServiceId && parseInt(unitServiceId)) || undefined;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  //selectors
  const unitService = useSelector(state => getUnitService(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const userProfileUnits = useSelector(state => getUserProfileUnit(state));
  const serviceTypes = useSelector(state => getServiceTypes(state));

  let messageId = (unitService && unitService.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const unitServiceName = (unitService.selectedUnitService ? unitService.selectedUnitService.ChecklistName : '') || '';
  const backToText = intl.formatMessage({ id: 'unitServiceDetail.back', defaultMessage: 'BACK' });

  let heading = intl.formatMessage({ id: 'unitServiceDetail.viewServiceRecord', defaultMessage: 'View Service Record' });

  const backLink = !IsEdit
    ? `/tasks-and-checklists/checklist-detail/${checklistId}?view=1`
    : `/maintenance/service-view/${checklistId}/${unitServiceId}`;

  //Checking permission and redirecting to unauth page if unauthorised
  if (!IsWidgetAccessible(userProfileWidgets, 'UNIT_SERVICE_VIEW')) {
    history.push('/unauth');
  }

  let widgetCode = 'UNIT_SERVICE_VIEW';

  useEffect(() => {
    dispatch(describeUnitService(unitServiceId, checklistId, widgetCode, orgId));
  }, [describeUnitService, dispatch, checklistId, unitServiceId, widgetCode, orgId]);

  useEffect(() => {
    return () => {
      dispatch(
        setUnitServiceChange({
          selectedUnitService: {
            ...initialUnitServiceState.unitService.selectedUnitService,
            SelectedTasks: []
          }
        })
      );
    };
  }, []);

  const onEditButtonClick = () => {
    history.push(`/maintenance/service/${checklistId}/${unitServiceId}`);
  };

  return (
    <div className={s.unitServiceDetail}>
      {unitService.isLoading && <LoadingSpinner />}
      <Banner
        key={uuidv4()}
        failureText={messageText}
        showBanner={unitService.showBanner}
        status={unitService.isOpSuccessful}
        successText={messageText}
      />

      <MaintenanceContentTemplate selectedPage="service">
        <div className={s.contentWrapper}>
          <div className={s.unitServiceDetailHeader}>
            <GoBack defaultLink={'/maintenance/service'}>
              &lt; &nbsp;
              {`${backToText}`}
            </GoBack>
            <Row>
              <Col xs={5}>
                <h3 data-unittest="headingLabel">{heading}</h3>
              </Col>
              <Col>
                <Row className={s.colActionButton}>
                  <Button
                    style={WidgetVisibility(userProfileWidgets, `UNIT_SERVICE_VIEW`)}
                    variant="primary"
                    data-unittest="saveData"
                    className={classNames(s.margin5, s.btnSaveChanges)}
                    noValidate
                    onClick={onEditButtonClick}
                  >
                    <FormattedMessage id="unitServiceDetail.edit" defaultMessage="Edit" />
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={s.unitServiceDetailContent}>
            <h4>
              <FormattedMessage id="unitServiceDetail.unitDetails" defaultMessage="Unit Details" />
            </h4>
            <Form className={s.viewRow}>
              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceChecklist">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.checklist" defaultMessage="Checklist" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{unitService.selectedUnitService.ChecklistName}</Col>
              </Row>

              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceDateTime">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.dateTime" defaultMessage="Date and time" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{getDateInDDMMYYYHHMMSSFormat(unitService.selectedUnitService.ServiceDateTime, currentTimezone)}</Col>
              </Row>

              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceUser">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.user" defaultMessage="User" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{unitService.selectedUnitService.UserName}</Col>
              </Row>
              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceUnitName">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.unitName" defaultMessage="Unit Name" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{unitService.selectedUnitService.UnitName}</Col>
              </Row>
              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceUnitServiceStatus">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.serviceStatus" defaultMessage="Service Status" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{unitService.selectedUnitService.UnitServiceStatus || '-'}</Col>
              </Row>
              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceWarrantyCost">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.warrantyCost" defaultMessage="Warranty Cost" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{unitService.selectedUnitService.WarrantyCost || ''}</Col>
              </Row>
              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceWarrantyHours">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.warrantyHours" defaultMessage="Warranty Hours" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{unitService.selectedUnitService.WarrantyHours || ''}</Col>
              </Row>

              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceNonWarrantyCost">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.nonWarrantyCost" defaultMessage="Non-Warranty Cost" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{unitService.selectedUnitService.NonWarrantyCost || ''}</Col>
              </Row>

              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formUnitServiceNonWarrantyHours">
                    <Form.Label>
                      <FormattedMessage id="unitServiceDetail.nonWarrantyHours" defaultMessage="Non-Warranty Hours" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{unitService.selectedUnitService.NonWarrantyHours || ''}</Col>
              </Row>
            </Form>
          </div>
          <div className={s.unitServiceDetailContent}>
            <UnitPartService
              selectedPartServices={unitService.selectedUnitService.UnitPartServices}
              checklistTasks={unitService.selectedUnitService.Tasks}
              componentParts={unitService.selectedUnitService.ComponentParts}
              serviceTypes={serviceTypes}
              isView={true}
            />
          </div>
        </div>
      </MaintenanceContentTemplate>
    </div>
  );
};

UnitServicesDetailView.defaultProps = {
  unitService: {
    ...initialUnitServiceState.unitService
  },
  userProfileUnits: []
};

export default injectIntl(UnitServicesDetailView);
