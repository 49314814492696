import React from 'react';
import s from './Organisation.module.scss';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { initialOrganisationManagerState } from '../../reducers/initialState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchBar from '../SearchBar/SearchBar';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const OrganisationUnits = ({ organisationManager, onUnitSearch, initialText, disable }) => {
  let formattedSelectedUnits = [];

  const searchHandler = value => {
    onUnitSearch(value);
  };
  if (organisationManager.toFilterUnits) {
    formattedSelectedUnits = organisationManager.toFilterUnits.map(sl => {
      return {
        ...sl,
        SKEY: sl.UnitId,
        label: `${sl.UnitName} / ${sl.ModelVersionName}`,
        value: sl.UnitId
      };
    });
  }

  return (
    <div className={s.organisationUsers} id="organisationUnits">
      {organisationManager.toFilterUnits.length === 0 && <LoadingSpinner centeredLoading={false} />}
      <Row className={s.row}>
        <Col sm={12}>
          <SearchBar
            searchHandler={searchHandler}
            initialText={initialText}
            clearSearchInVisible={true}
            placeHolderTextId="organisationManagement.search"
          />
        </Col>
      </Row>
      <Row className={s.selectedUsersAndUnits}>
        <Col sm={12} className={s.selectedCols}>
          <ul>{formattedSelectedUnits && formattedSelectedUnits.map(unit => <li key={unit.value}>{unit.label}</li>)}</ul>
        </Col>
      </Row>

      <div></div>
    </div>
  );
};

OrganisationUnits.defaultProps = {
  parentOrganisationUnits: [],
  organisationManager: {
    ...initialOrganisationManagerState.organisationManager
  }
};

OrganisationUnits.propTypes = {
  onUnitSelectionChange: PropTypes.func
};

export default injectIntl(OrganisationUnits);
