import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ComponentPartDetails.module.scss';
import { FormattedMessage } from 'react-intl';
import { initialComponentPartsState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { WidgetVisibility } from '../../utils/widgetManager';
import PartDetails from '../PartDetails/PartDetails';
import MaintenanceContentTemplate from '../Maintenance/MaintenanceContentTemplate';

const ComponentPartDetails = ({ userProfileWidgets, componentParts, describeComponentPart, changeComponentPart }) => {
  let { ComponentPartId } = useParams();

  useEffect(() => {
    if (ComponentPartId) {
      describeComponentPart(ComponentPartId);
    }
  }, [describeComponentPart, ComponentPartId]);

  useEffect(() => {
    return () => {
      setLocalComponentPart({
        ...componentParts,
        selectedComponentPart: { ...initialComponentPartsState.componentParts.selectedComponentPart },
        isOpSuccessful: false
      });
    };
  }, []);

  const setLocalComponentPart = currentState => {
    changeComponentPart(currentState);
  };

  return (
    <div className={s.componentParts}>
      <MaintenanceContentTemplate selectedPage="parts">
        {/* <div className={s.fullWidth}>
          <div className={s.fullWidthWrapper}>
            <h3 className={s.tabHeader}>{<FormattedMessage id="componentParts.parts" defaultMessage="Parts" />}</h3>
          </div>
        </div> */}
        <div className={s.pageContent}>
          <div>
            {componentParts.isLoading && <LoadingSpinner />}
            <div className={s.contentWrapper}>
              <div className={s.componentPartHeader}>
                <Link className={s.backLink} to={'/maintenance/parts'}>
                  &lt; &nbsp;
                  <FormattedMessage id="componentParts.backToParts" defaultMessage="BACK TO PARTS" />
                </Link>
                <Row>
                  <Col md={6} xs={12}>
                    <h3>
                      {componentParts.selectedComponentPart && componentParts.selectedComponentPart.Brand}
                      {componentParts.selectedComponentPart && componentParts.selectedComponentPart.Model}
                    </h3>
                  </Col>
                  <Col md={6} xs={12}>
                    <Link to={`/maintenance/parts/part/${componentParts.selectedComponentPart.ComponentPartId}`}>
                      <Button className={`${s.addbutton} float-right`} style={WidgetVisibility(userProfileWidgets, `PARTSEDIT`)}>
                        <FormattedMessage id="componentParts.change" defaultMessage="Change" />
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </div>
              <PartDetails componentParts={componentParts}></PartDetails>
            </div>
          </div>
        </div>
      </MaintenanceContentTemplate>
    </div>
  );
};

ComponentPartDetails.defaultProps = {
  componentParts: {
    ...initialComponentPartsState.componentParts
  }
};

ComponentPartDetails.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired,
  componentParts: PropTypes.object.isRequired,
  describeComponentPart: PropTypes.func.isRequired,
  changeComponentPart: PropTypes.func.isRequired
};

export default ComponentPartDetails;
