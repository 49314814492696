import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelVersionGlobalSettingsList from './ModelVersionGlobalSettingsList';
import { getModelVersionGlobalSettings, getUnitGlobalSettings } from '../../selectors/index';
import {
  getGlobalSetingsThunk,
  describeGlobalSetingThunk,
  postGlobalSetingThunk,
  setGlobalSetingChangeThunk,
  setGlobalSetingShowScreenStatusThunk
} from '../../actions/modelVersion';

const mapStateToProps = state => {
  return {
    globalSettings: getModelVersionGlobalSettings(state),
    unitGlobalSettings: getUnitGlobalSettings(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getModelVersionsGlobalSettings: (offset, limit, filter, modelVersionId) =>
        getGlobalSetingsThunk(offset, limit, filter, modelVersionId, 'MODELVERSIONGLOBALSETTINGS'),
      saveModelVersionGlobalSetting: (modelVersionGlobalSettingId, widgetCode) =>
        postGlobalSetingThunk(modelVersionGlobalSettingId, widgetCode),
      describeModelVersionGlobalSetting: modelVersionGlobalSettingId =>
        describeGlobalSetingThunk(modelVersionGlobalSettingId, 'MODELVERSIONGLOBALSETTINGSEDIT'),
      changeModelVersionGlobalSetting: selectedModelVersion => setGlobalSetingChangeThunk(selectedModelVersion),
      setGlobalSetingShowScreenStatus: status => setGlobalSetingShowScreenStatusThunk(status)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelVersionGlobalSettingsList);
