import { initialCalibrationState } from './initialState';

import {
  CALIBRATION_ERROR,
  GET_ALL_CALIBRATION_DETAILS,
  CALIBRATION_IS_LOADING,
  SET_CALIBRATION_CURRENT_PAGE,
  SET_CALIBRATION_PAGE_FILTER,
  SET_CALIBRATION_CHANGE,
  SET_CALIBRATION,
  UPDATE_CALIBRATION_SAVE_STATUS,
  SET_CALIBRATION_RESULT_CHANGE,
  SET_CALIBRATION_CONSTANT_CHANGE,
  SET_CALIBRATION_COMMENT_CHANGE,
  CALIBRATION_VIEW_STATUS,
  SET_CALIBRATION_CONST
} from '../constants/index';

const calibrationReducer = (state = initialCalibrationState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_CALIBRATION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        calibration: {
          ...state.calibration,
          ...data
        }
      };
    }

    case SET_CALIBRATION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        calibration: {
          ...state.calibration,
          currentPage
        }
      };
    }

    case SET_CALIBRATION_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        calibration: {
          ...state.calibration,
          filter
        }
      };
    }

    case UPDATE_CALIBRATION_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        calibration: {
          ...state.calibration,
          ...data
        }
      };
    }

    case CALIBRATION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        calibration: {
          ...state.calibration,
          isLoading: isLoading
        }
      };
    }

    case CALIBRATION_ERROR: {
      const { data } = payload;
      return {
        ...state,
        calibration: {
          ...state.calibration,
          ...data
        }
      };
    }

    case SET_CALIBRATION: {
      let { SelectedCalibration, isOpSuccessful, showBanner } = payload;

      return {
        ...state,
        calibration: {
          ...state.calibration,
          SelectedCalibration: {
            ...state.calibration.SelectedCalibration,
            ...SelectedCalibration
          },
          isOpSuccessful: isOpSuccessful,
          showBanner: showBanner
        }
      };
    }

    case SET_CALIBRATION_CONST: {
      let data = payload;

      return {
        ...state,
        calibration: {
          ...state.calibration,
          SelectedCalibration: {
            ...state.calibration.SelectedCalibration,
            ...data
          },
          isOpSuccessful: false,
          showBanner: false
        }
      };
    }

    case SET_CALIBRATION_CHANGE: {
      let { SelectedCalibration } = payload;

      return {
        ...state,
        calibration: {
          ...state.calibration,
          SelectedCalibration: {
            ...state.calibration.SelectedCalibration,
            ...SelectedCalibration
          }
        }
      };
    }

    case SET_CALIBRATION_RESULT_CHANGE: {
      let { CalibrationResults } = payload;

      return {
        ...state,
        calibration: {
          ...state.calibration,
          SelectedCalibration: {
            ...state.calibration.SelectedCalibration,
            CalibrationResults: CalibrationResults
          }
        }
      };
    }

    case SET_CALIBRATION_CONSTANT_CHANGE: {
      let { CalibrationComponentConstants } = payload;

      return {
        ...state,
        calibration: {
          ...state.calibration,
          SelectedCalibration: {
            ...state.calibration.SelectedCalibration,
            CalibrationComponentConstants: CalibrationComponentConstants
          }
        }
      };
    }

    case SET_CALIBRATION_COMMENT_CHANGE: {
      let { CalibrationComments } = payload;

      return {
        ...state,
        calibration: {
          ...state.calibration,
          SelectedCalibration: {
            ...state.calibration.SelectedCalibration,
            CalibrationComments: CalibrationComments
          }
        }
      };
    }

    case CALIBRATION_VIEW_STATUS: {
      const { status } = payload;
      return {
        ...state,
        calibration: {
          ...state.calibration,
          isViewMode: status
        }
      };
    }

    default:
      return state;
  }
};

export default calibrationReducer;
