import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Organisation from './Organisation';
import { getOrganisationManager, getUserProfileWidget } from '../../selectors/index';
import {
  postOrganisationThunk,
  describeOrganisationThunk,
  getOrganisationsThunk,
  setCurrentPage,
  setPageFilter,
  updateSelectedOrganisationThunk,
  setParentOrganisationDataThunk,
  setOrganisationUsersThunk,
  setOrganisationUnitsThunk
} from '../../actions/organisationManager';

const mapStateToProps = state => {
  return {
    organisationManager: getOrganisationManager(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrganisations: (offset, limit, filter, widgetCode) => getOrganisationsThunk(offset, limit, filter, widgetCode),
      saveOrganisation: (organisation, widgetCode) => postOrganisationThunk(organisation, widgetCode),
      describeOrganisation: (organisationId, widgetCode) => describeOrganisationThunk(organisationId, widgetCode),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter),
      updateSelectedOrganisation: selectedOrganisation => updateSelectedOrganisationThunk(selectedOrganisation),
      setParentOrganisationData: parentOrganisationData => setParentOrganisationDataThunk(parentOrganisationData),
      setOrganisationUsers: orgUsers => setOrganisationUsersThunk(orgUsers),
      setOrganisationUnits: orgUnits => setOrganisationUnitsThunk(orgUnits)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Organisation);
