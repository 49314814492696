import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import s from './EventAction.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import help from '../../assets/help-grey.svg';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import Button from 'react-bootstrap/Button';
import { getFormattedate } from '../../utils/';
import { getAllModelComponentPositions, getUnitManager } from '../../selectors/index';
import { addTelemetryDownload } from '../../actions/eventAction';
import arrowDown from '../../assets/arrow-down.svg';
import urlDownload from '../../assets/url-download.svg';
import Cookies from 'js-cookie';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { APP_VERSION } from '../../constants';

const EventActionTelemetry = ({ eventAction, telemetryDownloads, intl, currentTimezone }) => {
  const dispatch = useDispatch();
  const currentTimestamp = moment().tz(currentTimezone);
  const rangeDateTime = 6 * 60 * 60 * 1000;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const actionDateTime = eventAction.EventDateTime ? parseInt(eventAction.EventDateTime) : moment.tz(currentTimezone);

  const [fromDate, setFromDate] = useState(actionDateTime - rangeDateTime);
  const [toDate, setToDate] = useState(actionDateTime + rangeDateTime);
  const [errorMessageCode, setErrorMessageCode] = useState('');
  const [formErrors, setFromErrors] = useState({
    sizeWarning: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const unitManager = useSelector(state => getUnitManager(state));
  let componentPositions = unitManager.selectedUnit.unitComponentPositions || [];
  componentPositions = componentPositions.filter(x => x.IsParent === 0);
  const disableFutureDates = current => current.isBefore(currentTimestamp);
  const disablePastDates = current =>
    current.isAfter(
      moment(fromDate)
        .tz(currentTimezone)
        .subtract(1, 'days')
    ) && current.isBefore(currentTimestamp);

  const errorMessage = intl.formatMessage({
    id: 'eventAction.unableToDownloadReport',
    defaultMessage: 'Unable to download report '
  });

  const submitForm = async e => {
    e.preventDefault();

    let queryData = {
      unitSerialNumber: eventAction.UnitSerialNumber,
      fromDate: fromDate,
      toDate: toDate,
      components: componentPositions
    };

    await downloadReport(queryData);
  };

  const getCurrentOrgIdentifier = () => {
    let currentOrgId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
    const organisations = JSON.parse(localStorage.getItem(`organisations-${envName}`)) || [];
    //const organisations = JSON.parse(Cookies.get(`organisations-${envName}`)) || [];
    const selectedOrg = organisations.find(x => x.OrganisationId === parseInt(currentOrgId));
    if (selectedOrg) {
      return selectedOrg.OrgUuid;
    } else {
      return currentOrgId;
    }
  };

  const downloadReport = async queryData => {
    //setting Loading status to true
    setIsLoading(true);
    setDownloadLink('');
    queryData.components = queryData.components.map(comp => {
      return { Name: comp.NeedState ? `state.${comp.Name}` : comp.Name };
    });

    try {
      let respData = await fetch(`${process.env.REACT_APP_API_ENDPOINT_UNITS}/reports/unitreportdownload`, {
        method: 'post',
        body: JSON.stringify({
          unitSerialNumber: queryData.unitSerialNumber,
          from: queryData.fromDate,
          to: queryData.toDate,
          organisationId: getCurrentOrgIdentifier(),
          userId: Cookies.get(`userid-${envName}`) || '',
          timezone: currentTimezone,
          components: queryData.components,
          modelId: 55
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + Cookies.get(`access-${envName}`),
          widgetcode: 'UNITREPORT',
          accessid: Cookies.get(`selectedprofileid-${envName}`) || ''
        }
      });
      //Reading from stream and converting to JSON
      let retData = await respData.json();

      if (retData.statusCode === 200) {
        setIsLoading(false);
        setDownloadLink(retData.downloadurl);
        saveDownloadLink(retData.downloadurl);
        setErrorMessageCode('');
      } else {
        throw retData;
      }
    } catch (err) {
      let messageCode = (err && err.errorMessage) || null;
      messageCode = messageCode && messageCode.replace('[400]=>', '');
      setIsLoading(false);
      setErrorMessageCode(errorMessage);
      setTimeout(() => {
        //Hide the banner
        setErrorMessageCode('');
      }, 3500);
    }
  };

  const saveDownloadLink = downloadUrl => {
    dispatch(addTelemetryDownload(downloadUrl, eventAction.EventActionId, eventAction.UnitSerialNumber));
  };

  return (
    <div className={s.telemetry}>
      <Banner key={'bannerId'} failureText={errorMessageCode} showBanner={errorMessageCode.length > 0} status={false} successText={''} />

      {isLoading && <LoadingSpinner centeredLoading={false} />}
      <div className={s.telemetryContent}>
        <Form>
          <Row>
            <Col>
              <div className={s.subHeading}>
                <FormattedMessage id="unitreports.selectedateandtimerange" defaultMessage="Select date and time range" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={s.divWarning}>
                <div>
                  {' '}
                  <img src={help} alt="icon" className={s.icon}></img>
                  <FormattedMessage
                    id="unitreports.messageDownloadSize"
                    defaultMessage="Reports with data more two days (48 hours) may take longer due to its size limitation"
                  />
                  {downloadLink.length > 0 ? (
                    <div className={s.downloadInfo}>
                      <FormattedMessage id="unitreports.reportReady" defaultMessage="Your report is now ready to be downloaded" />{' '}
                      <a href={downloadLink}>
                        <FormattedMessage id="unitreports.downloadreport" defaultMessage="click here to download" />
                      </a>{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Row>
                <Col>
                  <div className={s.subHeading2}>
                    <FormattedMessage id="unitreports.fromdatetime" defaultMessage="From date/time" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <div className="form-group has-icon">
                    <span className="form-control-icon">
                      <img src={arrowDown} alt="icon"></img>
                    </span>
                    <DateTimePicker
                      className={s.datetimeInput}
                      isValidDate={disableFutureDates}
                      onChange={m => setFromDate(m.valueOf())}
                      timeFormat="HH:mm"
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(fromDate, currentTimezone)}
                      defaultValue={getFormattedate(fromDate, currentTimezone)}
                      closeOnSelect={true}
                      showClear={false}
                      //timeConstraints={{ minutes: { step: 15 } }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col>
                  <div className={s.subHeading2}>
                    <FormattedMessage id="unitreports.todatetime" defaultMessage="To date/time" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <div className="form-group has-icon">
                    <span className="form-control-icon">
                      <img src={arrowDown} alt="icon"></img>
                    </span>
                    <DateTimePicker
                      className={s.datetimeInput}
                      isValidDate={disablePastDates}
                      onChange={m => setToDate(m.valueOf())}
                      timeFormat="HH:mm"
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(toDate, currentTimezone)}
                      defaultValue={getFormattedate(toDate, currentTimezone)}
                      closeOnSelect={true}
                      showClear={false}
                      //timeConstraints={{ minutes: { step: 15 } }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                {formErrors && formErrors.sizeWarning && (
                  <p role="alert" className={s.error}>
                    {formErrors.sizeWarning}
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <Button variant="primary" className={s.margin5} onClick={submitForm} noValidate disabled={isLoading}>
            <FormattedMessage id="unitreports.generateReport" defaultMessage="GENERATE REPORT" />
          </Button>
        </Form>
        <hr className={s.breakhr} />
        <TelemetryDownloadLinks telemetryDownloads={telemetryDownloads} saveDownloadLink={saveDownloadLink} />
      </div>
    </div>
  );
};

export default injectIntl(EventActionTelemetry);

const TelemetryDownloadLinks = ({ telemetryDownloads, saveDownloadLink }) => {
  return (
    <div className={s.telemetryLinks}>
      {telemetryDownloads.length > 0 ? (
        <div className={s.downloadHistoryInfo}>
          <FormattedMessage id="eventAction.reportDownloadAnalysis" defaultMessage="Your previously analysed report(s)" />
        </div>
      ) : (
        ''
      )}
      <ul className={s.telemetryLinkItems}>
        {Object.keys(telemetryDownloads).map((downloadItem, i) => (
          <li key={i}>
            <a href={telemetryDownloads[i]}>
              <img src={urlDownload} alt="icon" className={s.downloadIcon} />
              <FormattedMessage id="eventAction.downloadedReportLink" defaultMessage="Downloaded Link" /> {` ${i + 1}`}
            </a>
          </li>
        ))}
      </ul>
      <SaveDownloadLinks saveDownloadLink={saveDownloadLink} />
    </div>
  );
};

const SaveDownloadLinks = ({ saveDownloadLink }) => {
  const [downloadLink, setDownloadLink] = useState('');

  const saveLink = () => {
    if (downloadLink && downloadLink.length > 0) {
      saveDownloadLink(downloadLink);
      setDownloadLink('');
    }
  };

  const onChange = e => {
    if (e.target.value) {
      setDownloadLink(e.target.value);
    }
  };

  return (
    <div>
      <div className={s.downloadHistoryInfo}>
        <FormattedMessage id="eventAction.reportAddCustomDownload" defaultMessage="Add custom report link" />
      </div>
      <Row>
        <Col xs={6}>
          <Form.Control type="text" name="DownloadUrl" onChange={onChange} value={downloadLink} className={s.formControl} />
        </Col>
        <Col xs={6}>
          <Button variant="primary" className={s.margin5} onClick={saveLink} noValidate>
            <FormattedMessage id="eventAction.saveDownloadLink" defaultMessage="Save Link" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};
