import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModelVersionUnitsList from './ModelVersionUnitsList';
import { getModelVersionUnits } from '../../selectors/index';
import {
  getModelVersionUnitsThunk,
  saveModelVersionUnitsThunk,
  setModelVersionUnitsChangeThunk,
  setModelVersionUnitsScreenStatusThunk
} from '../../actions/modelVersion';

const mapStateToProps = state => {
  return {
    modelVersionUnitManager: getModelVersionUnits(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getModelVersionUnits: item => getModelVersionUnitsThunk(item, 'MODELVERSION_UNIT_VIEW'),
      saveModelVersionUnits: modelversionunits => saveModelVersionUnitsThunk(modelversionunits, 'MODELVERSION_UNIT_ALLOCATE'),
      setModelVersionUnitsChange: selectedModelversionunits => setModelVersionUnitsChangeThunk(selectedModelversionunits),
      setModelVersionUnitsScreenStatus: status => setModelVersionUnitsScreenStatusThunk(status)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelVersionUnitsList);
