import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './EventCodeList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialEventCodeState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import EventCodeListTable from './EventCodeListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import SearchBar from '../SearchBar/SearchBar';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';

const MODELCOMPONENT = 'MODELCOMPONENT';
const FLOWSEQUENCE = 'FLOWSEQUENCE';
const SEARCH_FILTER = 'SEARCH_FILTER';
const SEARCH_FILTER_TYPE_NAME = 'SEARCH_FILTER_TYPE_NAME';
const SEARCH_FILTER_TYPE_CODE = 'SEARCH_FILTER_TYPE_CODE';

const EventCodeList = ({
  getEventCodes,
  setCurrentPage,
  setPageFilter,
  eventCodeManager,
  intl,
  userProfileWidgets,
  currentTimezone,
  modelComponentPositions,
  flowSequences,
  setFilterData
}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [searchfilterType, setSearchFilterType] = useState(SEARCH_FILTER_TYPE_CODE);
  const [filterComponent, setFilterComponent] = useState(0);
  const [filterFlowSequence, setFilterFlowSequence] = useState(0);

  useEffect(() => {
    getEventCodes();
  }, [getEventCodes]);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const flowSequenceOptions = () => {
    let flowsequenceData = [];
    flowsequenceData.push({
      label: <FormattedMessage id="eventCodes.allFlowSequence" defaultMessage="All Flow Sequences" />,
      value: 0
    });

    flowSequences.forEach(element => {
      flowsequenceData.push({
        label: element.Name,
        value: element.GeneralGlobalSettingId
      });
    });
    return flowsequenceData;
  };

  const modelComponentOptions = () => {
    let modelComponentData = [];

    modelComponentData.push({
      label: <FormattedMessage id="eventCodes.allSensor" defaultMessage="All Sensors" />,
      value: 0
    });

    modelComponentPositions.forEach(element => {
      modelComponentData.push({
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return modelComponentData;
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onModelComponentChange = e => {
    setFilterComponent(e.value);
    filteredResults(MODELCOMPONENT, e.value);
    setCurrentPage(1);
  };

  const onFlowSequenceChange = e => {
    setFilterFlowSequence(e.value);
    filteredResults(FLOWSEQUENCE, e.value);
    setCurrentPage(1);
  };

  const onTypeChange = e => {
    setSearchFilterType(e.value);
    setCurrentPage(1);
  };

  let typePlaceHolder = intl.formatMessage({ id: 'eventCodes.eventCodes', defaultMessage: 'Event Code' });

  const typeOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="eventCodes.eventCode" defaultMessage="Event Code" />,
      value: SEARCH_FILTER_TYPE_CODE
    });

    statusData.push({
      label: <FormattedMessage id="eventCodes.name" defaultMessage="Name" />,
      value: SEARCH_FILTER_TYPE_NAME
    });

    return statusData;
  };

  const filteredResults = (filterType, value) => {
    let filteredList = eventCodeManager.eventCodeList;

    if (filterType === MODELCOMPONENT) {
      if (value !== 0) {
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentPositionId === value;
        });
      }
    } else {
      if (filterComponent !== 0) {
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentPositionId === filterComponent;
        });
      }
    }

    if (filterType === FLOWSEQUENCE) {
      if (value !== 0) {
        filteredList = filteredList.filter(function(item) {
          return item.GeneralGlobalSettingId === value;
        });
      }
    } else {
      if (filterFlowSequence !== 0) {
        filteredList = filteredList.filter(function(item) {
          return item.GeneralGlobalSettingId === filterFlowSequence;
        });
      }
    }

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_TYPE_CODE) {
      if (value !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Code.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_TYPE_CODE) {
        filteredList = filteredList.filter(function(item) {
          return item.Code.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_TYPE_NAME) {
      if (value !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_TYPE_NAME) {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    setFilterData({ filteredResults: { filteredResults: filteredList } });
  };

  const eventCodeObj = Object.values(eventCodeManager.filteredResults);

  let messageId = (eventCodeManager && eventCodeManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <div className={s.eventCode}>
      {eventCodeManager.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={eventCodeManager.showBanner}
        status={eventCodeManager.isOpSuccessful}
        successText={messageText}
      />

      <AdminControlsContentTemplate selectedPage="manageEventCodes" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper} style={WidgetVisibility(userProfileWidgets, 'EVENTCODELIST')}>
          <Row>
            <Col sm={6} lg={8}>
              <div className={s.eventCodeHeader}>
                <div className={s.subHeading}>
                  <FormattedMessage id="eventCodes.eventCodes" defaultMessage="Event Codes" />
                  <span data-unittest="eventCodeDataCount" className={s.circle}>
                    {eventCodeManager.eventCodeList.length}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={4}>
              <span className={s.textHeading}>
                <FormattedMessage id="eventCodes.search" defaultMessage="Search" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={6}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId={
                  searchfilterType === SEARCH_FILTER_TYPE_NAME ? 'eventCodes.searchLabelName' : 'eventCodes.searchLabelCode'
                }
                id="txtSearchBar"
                showFilterType
                searchfilterType={searchfilterType}
                onFilterTypeChange={onTypeChange}
                filterTypeData={typeOptions()}
                filterPlaceholderText={typePlaceHolder}
              />
            </Col>
          </Row>
          <Col sm={3} lg={7}></Col>
          <Row>
            <Col sm={12}>
              <span className={s.textHeading}>
                {' '}
                <FormattedMessage id="eventCodes.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={3}>
              <Dropdown
                id="drpComponentId"
                dataArray={modelComponentOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="eventCodes.allSensor" defaultMessage="All Sensors" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onModelComponentChange(e)}
                selectedOption={modelComponentOptions().filter(option => option.value === filterComponent)}
                disabled={false}
              />
            </Col>
            <Col sm={6} lg={3}>
              <Dropdown
                id="drpGlobalSettingId"
                dataArray={flowSequenceOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="eventCodes.allFlowSequence" defaultMessage="All Flow Sequences" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onFlowSequenceChange(e)}
                selectedOption={flowSequenceOptions().filter(option => option.value === filterFlowSequence)}
                disabled={false}
              />
            </Col>
            <Col sm={6} lg={6}>
              <Link to={`/admin-controls/event-codes`}>
                <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `EVENTCODEADDNEW`)}>
                  + <FormattedMessage id="eventCodes.addNew" defaultMessage="+ ADD NEW" />
                </Button>
              </Link>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={eventCodeObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={eventCodeManager.currentPage}
            >
              <EventCodeListTable userProfileWidgets={userProfileWidgets} />
            </Pagination>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

EventCodeList.defaultProps = {
  ...initialEventCodeState
};

EventCodeList.propTypes = {
  getEventCodes: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(EventCodeList);
