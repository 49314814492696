export const IsWidgetAccessible = (userProfileWidgets, widgetId) => {
  if (!userProfileWidgets || !widgetId) {
    return false;
  }

  if (
    userProfileWidgets.userProfileWidgets &&
    userProfileWidgets.userProfileWidgets.filter &&
    userProfileWidgets.userProfileWidgets.filter(widget => widget.Code === widgetId).length > 0
  ) {
    return true;
  }
  return false;
};

export const WidgetVisibility = (userProfileWidgets, widgetId) => {
  if (!userProfileWidgets || !widgetId) {
    return { display: 'none' };
  }

  if (
    userProfileWidgets.userProfileWidgets &&
    userProfileWidgets.userProfileWidgets.filter &&
    userProfileWidgets.userProfileWidgets.filter(widget => widget.Code === widgetId && widget.IsActive).length > 0
  ) {
    return { display: 'block' };
  }
  return { display: 'none' };
};

export const IsWidgetPresent = (userProfileWidgets, widgetId) => {
  if (!userProfileWidgets || !widgetId) {
    return false;
  }

  if (userProfileWidgets && userProfileWidgets.filter && userProfileWidgets.filter(widget => widget.Code === widgetId).length > 0) {
    return true;
  }
  return false;
};

export const IsWidgetViewableInSwitchView = (userProfileWidgets, widgetId) => {
  if (!userProfileWidgets || !widgetId) {
    return false;
  }

  if (
    userProfileWidgets.userProfileWidgets &&
    userProfileWidgets.userProfileWidgets.filter &&
    userProfileWidgets.userProfileWidgets.filter(widget => widget.Code === widgetId && widget.IsViewable).length > 0
  ) {
    return true;
  }
  return false;
};
