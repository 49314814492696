import get from 'lodash.get';

import {
  SET_DEVICE_SEQUENCE,
  SET_BUTTON_STATE,
  UPDATE_COMMAND_STATUS,
  SET_DEVICES,
  SET_SELECTED_DEVICE,
  SET_SELECTED_VIEW,
  SET_DEVICE_TIMEZONE,
  GET_DEVICES_STATUS,
  GET_DEVICES_ALARMS_DETAILS,
  GET_UNIT_COMPONENT_PARTS,
  GET_UNIT_COMPONENT_PARTS_ERROR,
  DISABLE_COMMANDS,
  DEVICE_ERROR,
  SET_UNIT_TAGOUT,
  DEVICE_LOADING
} from '../constants/index';
import { initialDeviceState } from './initialState';
import { arrayToObject } from '../utils/index';

const deviceReducer = (state = initialDeviceState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_DEVICES: {
      const { devices } = payload;
      return {
        ...state,
        devices: {
          ...arrayToObject(devices, 'unitSerialNumber'),
          isLoading: false
        }
      };
    }
    case SET_SELECTED_DEVICE: {
      const { unitSerialNumber } = payload;
      return {
        ...state,
        unitSerialNumber
      };
    }
    case SET_SELECTED_VIEW: {
      const { viewName } = payload;
      return {
        ...state,
        selectedView: viewName
      };
    }
    case SET_BUTTON_STATE: {
      const { disabled, command } = payload;
      return {
        ...state,
        disableCommandButtons: disabled,
        ...(command && { commandId: command })
      };
    }
    case UPDATE_COMMAND_STATUS: {
      const { status, showBanner, message } = payload;
      return {
        ...state,
        ...(status !== null && { commandStatus: status }),
        showBanner,
        displayMessageCode: message,
        isOpSuccessful: true
      };
    }
    case DEVICE_ERROR: {
      const { error } = payload;
      return {
        ...state,
        ...error
      };
    }
    case SET_DEVICE_SEQUENCE: {
      const { unitSerialNumber, sequence } = payload;

      if (Object.keys(state.devices[unitSerialNumber]).length === 0) return state;
      let selectedDevice = state.devices[unitSerialNumber];
      selectedDevice.sequence = sequence;

      return {
        ...state,
        devices: {
          ...state.devices,
          [unitSerialNumber]: selectedDevice
        }
      };
    }
    case SET_DEVICE_TIMEZONE: {
      const { deviceTimezone } = payload;
      return {
        ...state,
        deviceTimezone
      };
    }
    case GET_DEVICES_STATUS: {
      const { devicesStatus } = payload;
      return {
        ...state,
        devicesStatus: devicesStatus
      };
    }

    case GET_DEVICES_ALARMS_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        alarmsList: data
      };
    }

    case GET_UNIT_COMPONENT_PARTS: {
      const { UnitComponentParts, UnitSubComponentDetails } = payload;
      return {
        ...state,
        unitComponentParts: UnitComponentParts,
        unitSubComponentDetails: UnitSubComponentDetails
      };
    }

    case GET_UNIT_COMPONENT_PARTS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        deviceError: data
      };
    }
    case DISABLE_COMMANDS: {
      const { disabled } = payload;
      return {
        ...state,
        disableCommandButtons: disabled
      };
    }

    case SET_UNIT_TAGOUT: {
      const { tagout, unitSerialNumber } = payload;

      if (Object.keys(state.devices[unitSerialNumber]).length === 0) return state;
      let selectedDevice = state.devices[unitSerialNumber];
      selectedDevice.isTaggedOut = tagout.IsTaggedOut;
      selectedDevice.tagOutByUser = tagout.TagOutByUser || '';

      return {
        ...state,
        devices: {
          ...state.devices,
          [unitSerialNumber]: selectedDevice
        }
      };
    }

    case DEVICE_LOADING: {
      const { status } = payload;
      return {
        ...state,
        isLoading: status
      };
    }

    default:
      return state;
  }
};

export default deviceReducer;
