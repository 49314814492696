import React, { useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import s from './FAQControl.module.scss';
import { injectIntl } from 'react-intl';
import FAQCategoryList from '../FAQCategory/FAQCategoryList';
import FAQuestionList from '../FAQuestion/FAQuestionList';
import AdminControlsContentTemplate from '../../AdminControls/AdminControlsContentTemplate';
import { getQuerystring } from '../../../utils/index';

const FAQControl = ({ intl }) => {
  let posRef = useRef();
  let approvedRef = useRef();
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let list = getQuerystring('list');
  useLayoutEffect(() => {
    if (list === '1' && approvedRef.current) {
      window.scrollTo({
        top: approvedRef.current.offsetTop
      });
    } else if (list === '2' && posRef.current) {
      window.scrollTo({
        top: posRef.current.offsetTop
      });
    }
  }, [posRef, approvedRef, list]);

  return (
    <div className={s.mainComponent}>
      <AdminControlsContentTemplate selectedPage="manageFAQ" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.mainComponentContent}>
            <FAQCategoryList userProfileWidgets={userProfileWidgets} />
            <FAQuestionList userProfileWidgets={userProfileWidgets} faqCategorySpecificQuestions={false} />
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

FAQControl.defaultProps = {};

export default injectIntl(FAQControl);
