import React, { useState, useEffect } from 'react';
import s from './OrganisationRegion.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganisationRegion } from '../../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialOrganisationRegionState } from '../../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired } from '../../../utils';
import Cookies from 'js-cookie';
import AdminControlsContentTemplate from '../../AdminControls/AdminControlsContentTemplate';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import {
  describeOrganisationRegionThunk,
  saveOrganisationRegionThunk,
  setOrganisationRegionChangeThunk,
  setOrganisationRegionCleanUpThunk
} from '../../../actions/organisationRegions';

import uuid from 'uuid';

const OrganisationRegion = ({ intl }) => {
  let history = useHistory();
  let { organisationRegionId, organisationId } = useParams();

  const dispatch = useDispatch();
  const organisationRegion = useSelector(state => getOrganisationRegion(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const backUrl = `/admin-controls/organisation-list/${organisationId}/configure`;
  let widgetCode = '';
  if (organisationRegionId) {
    IsEdit = true;
    widgetCode = 'ORGANISATION_REGION_EDIT';
  }

  useEffect(() => {
    if (organisationRegionId) {
      dispatch(describeOrganisationRegionThunk(organisationRegionId, organisationId, widgetCode));
    }
  }, [describeOrganisationRegionThunk, dispatch, organisationRegionId, organisationId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(setOrganisationRegionCleanUpThunk(initialOrganisationRegionState.organisationRegion.selectedOrganisationRegion));
    };
  }, []);

  // Update redux store
  const setLocalOrganisationRegion = currentState => {
    dispatch(setOrganisationRegionChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = organisationRegion.selectedOrganisationRegion;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.OrganisationRegionId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'ORGANISATION_REGION_ADD';
    }

    dispatch(saveOrganisationRegionThunk(saveData, widgetCode, organisationId));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !organisationRegion ||
      !organisationRegion.selectedOrganisationRegion ||
      !organisationRegion.selectedOrganisationRegion.Name ||
      isRequired(organisationRegion.selectedOrganisationRegion.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'common.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    setLocalOrganisationRegion({
      ...organisationRegion,
      selectedOrganisationRegion: { ...organisationRegion.selectedOrganisationRegion, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (organisationRegion && organisationRegion.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const regionName = (organisationRegion.selectedOrganisationRegion ? organisationRegion.selectedOrganisationRegion.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + regionName;
  } else {
    heading = intl.formatMessage({ id: 'organisationRegions.addOrganisationRegion', defaultMessage: 'Add Organisation Region/Site' });
  }

  return (
    <div className={s.organisationRegion}>
      {organisationRegion.isLoading && <LoadingSpinner />}
      {organisationRegion.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={organisationRegion.showBanner}
        status={organisationRegion.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageOrganisations" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.organisationRegionHeader}>
            <GoBack className={s.backLink} returnUrl={backUrl}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.organisationRegionContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formOrganisationRegionName">
                      <Form.Label>
                        <FormattedMessage id="common.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={organisationRegion.selectedOrganisationRegion.Name || ''}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formOrganisationRegionDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={organisationRegion.selectedOrganisationRegion.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

OrganisationRegion.defaultProps = {
  organisationRegion: {
    ...initialOrganisationRegionState
  }
};

export default injectIntl(OrganisationRegion);
