import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionServicePlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ModelVersionServicePlan from './ModelVersionServicePlan';
import moment from 'moment-timezone';

const ModelVersionServicePlanListTable = ({
  setEditHandler,
  ModelVersionId,
  selectedModelVersionServicePlanId,
  saveModelVersionServicePlan,
  modelVersionServicePlans,
  describeModelVersionServicePlan,
  changeModelVersionServicePlan,
  checklistTemplate,
  servicePlansData,
  UpdateAllowed,
  ReadOnly,
  disableMode,
  disableModeHandler,
  addNewRef,
  intl,
  currentTimezone
}) => {
  const fetchDataHandler = modelVersionServicePlanId => {
    setEditHandler(modelVersionServicePlanId);
    disableModeHandler(true);
    describeModelVersionServicePlan(modelVersionServicePlanId);
  };

  const cancelHandler = () => {
    setEditHandler(-1);
    disableModeHandler(false);
  };

  const editModelVersionContent = (
    <ModelVersionServicePlan
      ModelVersionServicePlanId={selectedModelVersionServicePlanId}
      ModelVersionId={ModelVersionId}
      saveModelVersionServicePlan={saveModelVersionServicePlan}
      describeModelVersionServicePlan={describeModelVersionServicePlan}
      modelVersionServicePlan={modelVersionServicePlans}
      changeModelVersionServicePlan={changeModelVersionServicePlan}
      checklistTemplate={checklistTemplate}
      cancelHandler={cancelHandler}
      currentTimezone={currentTimezone}
      setEditHandler={setEditHandler}
    />
  );

  let editorMain = (
    <tr>
      <td colSpan="10">{editModelVersionContent}</td>
    </tr>
  );

  let newRowClass = modelVersionServicePlans.showAddEditScreen && selectedModelVersionServicePlanId === 0 ? s.newRow : '';

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="modelVersionServicePlan.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="modelVersionServicePlan.description" defaultMessage="Description" />
            </th>
            <th>
              <FormattedMessage id="modelVersionServicePlan.checklistName" defaultMessage="Checklist Template" />
            </th>
            <th>
              <FormattedMessage id="modelVersionServicePlan.startDateTime" defaultMessage="Start Date" />
            </th>
            <th>
              <FormattedMessage id="modelVersionServicePlan.scheduleEvery" defaultMessage="Schedule Every" />
            </th>
            <th>
              <FormattedMessage id="modelVersionServicePlan.scheduleByPeriod" defaultMessage="Schedule Period" />
            </th>

            {!ReadOnly && UpdateAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {servicePlansData.map(n => {
            return (
              <Fragment key={n.ModelVersionServicePlanId}>
                <tr key={n.ModelVersionServicePlanId} data-unittest="servicePlansData">
                  <td>{n.Name}</td>
                  <td>{n.Description}</td>
                  <td>{n.ChecklistName}</td>
                  <td>
                    {n.StartDateTime
                      ? moment
                          .unix(parseInt(n.StartDateTime))
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm:ss z')
                      : '-'}
                  </td>
                  <td>{n.ScheduleBy}</td>
                  <td>{n.ScheduleByPeriod}</td>
                  {!ReadOnly && UpdateAllowed && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={fetchDataHandler.bind(this, n.ModelVersionServicePlanId)}
                          disabled={disableMode}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="modelVersion.change" defaultMessage="Change" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
                {modelVersionServicePlans.showAddEditScreen &&
                  selectedModelVersionServicePlanId === n.ModelVersionServicePlanId &&
                  editorMain}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className={newRowClass} ref={addNewRef}>
        {modelVersionServicePlans.showAddEditScreen && selectedModelVersionServicePlanId === 0 && editModelVersionContent}
      </div>
    </div>
  );
};

ModelVersionServicePlanListTable.defaultProps = {
  servicePlansData: []
};

ModelVersionServicePlanListTable.propTypes = {
  setEditHandler: PropTypes.func.isRequired,
  selectedModelVersionServicePlanId: PropTypes.number,
  saveModelVersionServicePlan: PropTypes.func.isRequired,
  modelVersionServicePlans: PropTypes.object.isRequired,
  describeModelVersionServicePlan: PropTypes.func.isRequired,
  changeModelVersionServicePlan: PropTypes.func.isRequired,
  checklistTemplate: PropTypes.object.isRequired,
  servicePlansData: PropTypes.array,
  UpdateAllowed: PropTypes.bool,
  disableMode: PropTypes.bool,
  disableModeHandler: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionServicePlanListTable);
