import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  MODELCOMPONENTTYPE_ERROR,
  GET_ALL_MODELCOMPONENTTYPE_DETAILS,
  MODELCOMPONENTTYPE_IS_LOADING,
  SET_MODELCOMPONENTTYPE_CURRENT_PAGE,
  SET_MODELCOMPONENTTYPE_PAGE_FILTER,
  SET_MODELCOMPONENTTYPE_CHANGE,
  SET_MODELCOMPONENTTYPE,
  UPDATE_MODELCOMPONENTTYPE_SAVE_STATUS,
  MODELCOMPONENTTYPE_CLEANUP,
  SET_MODELCOMPONENTTYPE_FILTERED_RESULTS
} from '../constants/index';

import {
  MODELCOMPONENTTYPE_MANAGEMENT,
  CREATE_MODELCOMPONENTTYPE,
  UPDATE_MODELCOMPONENTTYPE,
  DELETE_MODELCOMPONENTTYPE
} from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setModelComponentTypeErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setModelComponentTypeError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelComponentTypeError(messageCode, false));
  }, 2500);
};

export const setModelComponentTypeError = (messageCode, status) => {
  return {
    type: MODELCOMPONENTTYPE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: MODELCOMPONENTTYPE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getModelComponentTypeThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getModelComponentType(filterObj, widgetCode));
};

export const getModelComponentType = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelComponentTypes`,
    data: {
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllModelComponentTypeListThunk(retData),
    onFailure: err => setModelComponentTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllModelComponentTypeListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelComponentTypes = (retData && retData.modelComponentTypes) || [];
  dispatch(setAllModelComponentTypeList(messageCode, modelComponentTypes));
};

export const setAllModelComponentTypeList = (messageCode, modelComponentTypes) => ({
  type: GET_ALL_MODELCOMPONENTTYPE_DETAILS,
  payload: {
    data: {
      modelComponentTypeList: modelComponentTypes,
      filteredResults: modelComponentTypes,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setModelComponentTypeFilteredDataThunk = retData => dispatch => {
  dispatch(setModelComponentTypeFilteredData(retData));
};

export const setModelComponentTypeFilteredData = retData => ({
  type: SET_MODELCOMPONENTTYPE_FILTERED_RESULTS,
  payload: retData
});

export const setCurrentPage = currentPage => ({
  type: SET_MODELCOMPONENTTYPE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_MODELCOMPONENTTYPE_PAGE_FILTER,
  payload: { filter }
});

export const describeModelComponentTypeThunk = (ModelComponentTypeId, widgetCode) => dispatch => {
  dispatch(describeModelComponentType(ModelComponentTypeId, widgetCode));
};

export const describeModelComponentType = (ModelComponentTypeId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelComponentTypes`,
    data: {
      ModelComponentTypeId: ModelComponentTypeId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setModelComponentTypeThunk(retData),
    onFailure: err => setModelComponentTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setModelComponentTypeThunk = retData => dispatch => {
  dispatch(setModelComponentType(retData));
};

export const setModelComponentType = data => ({
  type: SET_MODELCOMPONENTTYPE,
  payload: {
    selectedModelComponentType: {
      ...data.selectedModelComponentType
    },
    isOpSuccessful: false,
    showBanner: false,
    isRedirect: false
  }
});

export const saveModelComponentTypeThunk = (modelComponentType, widgetCode) => dispatch => {
  let url = '/management/modelComponentTypes/save';
  let actionName =
    modelComponentType.ModelComponentTypeId === 0
      ? CREATE_MODELCOMPONENTTYPE
      : modelComponentType.PerformDelete
      ? DELETE_MODELCOMPONENTTYPE
      : UPDATE_MODELCOMPONENTTYPE;

  let log = logEntry(MODELCOMPONENTTYPE_MANAGEMENT, actionName, modelComponentType);
  dispatch(saveModelComponentType(modelComponentType, url, log, widgetCode));
};

export const saveModelComponentType = (modelComponentType, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setModelComponentTypeStatusThunk(retData, modelComponentType),
    onFailure: err => setModelComponentTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      ModelComponentType: modelComponentType,
      log: log
    },
    widgetcode: widgetCode
  });

export const setModelComponentTypeStatusThunk = (message, modelComponentType) => dispatch => {
  dispatch(setModelComponentTypeStatus(message, true, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelComponentTypeStatus('', false, modelComponentType.PerformDelete ? true : false));
  }, 2500);
};

export const setModelComponentTypeStatus = (messageCode, status, isOpSuccessful) => ({
  type: UPDATE_MODELCOMPONENTTYPE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: isOpSuccessful,
      showBanner: status,
      isLoading: false,
      isRedirect: !status && !isOpSuccessful,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setModelComponentTypeChangeThunk = retData => dispatch => {
  dispatch(setModelComponentTypeChange(retData));
};

export const setModelComponentTypeChange = retData => ({
  type: SET_MODELCOMPONENTTYPE_CHANGE,
  payload: retData
});

export const resetModelComponentThunk = retData => dispatch => {
  dispatch(resetModelComponent(retData));
};

export const resetModelComponent = retData => ({
  type: MODELCOMPONENTTYPE_CLEANUP,
  payload: retData
});
