import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ApprovedComponent.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialApprovedComponentState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import uuid from 'uuid';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { compareValues } from '../../utils/';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import ApprovedSubComponentTable from './ApprovedSubComponentTable';

const ApprovedComponent = ({
  userProfileWidgets,
  approvedComponent,
  saveApprovedComponent,
  describeApprovedComponent,
  changeApprovedComponent,
  changeApprovedSubComponent,
  setApprovedSubComponentList,
  resetModelComponent,
  showAddEditScreen,
  intl
}) => {
  let history = useHistory();
  let { approvedComponentId } = useParams();
  let IsEdit = false;
  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const [currentApprovedComponentId, setCurrentApprovedComponentId] = useState(-1);
  const [currentApprovedComponentIdentifier, setCurrentApprovedComponentIdentifier] = useState('');

  let ddlClassComponentType = localformErrors && localformErrors.ModelComponentTypeId ? s.ddlError : '';

  //Checking permission and redirecting to unauth page if unauthorised
  if (approvedComponentId === '0' && !IsWidgetAccessible(userProfileWidgets, 'APPROVEDCOMPONENT_ADDNEW')) {
    history.push('/unauth');
  }
  if (approvedComponentId !== '0' && !IsWidgetAccessible(userProfileWidgets, 'APPROVEDCOMPONENT_EDIT')) {
    history.push('/unauth');
  }
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'APPROVEDCOMPONENT_EDIT');

  let widgetCode = '';
  if (approvedComponentId && approvedComponentId !== '0') {
    IsEdit = true;
    widgetCode = 'APPROVEDCOMPONENT_EDIT';
  } else {
    widgetCode = 'APPROVEDCOMPONENT_ADDNEW';
  }

  let backUrl = '';
  if (IsEdit) {
    backUrl = `/admin-controls/model-component/approved-component-view/${approvedComponentId}`;
  } else {
    backUrl = '/admin-controls/model-component?list=1';
  }

  useEffect(() => {
    if (approvedComponentId) {
      describeApprovedComponent(approvedComponentId, widgetCode);
    }
  }, [describeApprovedComponent, approvedComponentId]);

  //clean up
  useEffect(() => {
    return () => {
      resetModelComponent({
        ...initialApprovedComponentState
      });
    };
  }, []);

  // Update redux store
  const setLocalApprovedComponent = currentState => {
    changeApprovedComponent(currentState);
  };

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();
    topFunction();

    if (!validateInput()) {
      return;
    }

    let saveData = approvedComponent.selectedApprovedComponent;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.ApprovedComponentId = 0;
      saveData.GuidIdentifier = uuidv4();
    }

    saveApprovedComponent(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !approvedComponent ||
      !approvedComponent.selectedApprovedComponent ||
      !approvedComponent.selectedApprovedComponent.Brand ||
      isRequired(approvedComponent.selectedApprovedComponent.Brand, 1)
    ) {
      formErrors.Brand = intl.formatMessage({
        id: 'approvedComponents.brandRequired',
        defaultMessage: 'Brand is a mandatory field'
      });
      isValid = false;
    }

    if (
      !approvedComponent ||
      !approvedComponent.selectedApprovedComponent ||
      !approvedComponent.selectedApprovedComponent.Model ||
      isRequired(approvedComponent.selectedApprovedComponent.Model, 1)
    ) {
      formErrors.Model = intl.formatMessage({
        id: 'approvedComponents.modelRequired',
        defaultMessage: 'Model is a mandatory field'
      });
      isValid = false;
    }

    if (
      !approvedComponent ||
      !approvedComponent.selectedApprovedComponent ||
      !approvedComponent.selectedApprovedComponent.ModelComponentTypeId
    ) {
      formErrors.ModelComponentTypeId = intl.formatMessage({
        id: 'approvedComponents.componentTypeRequired',
        defaultMessage: 'Component type is a mandatory field'
      });
      isValid = false;
    }

    if (
      !approvedComponent ||
      !approvedComponent.selectedApprovedComponent ||
      !approvedComponent.selectedApprovedComponent.ApprovedComponentRef ||
      isRequired(approvedComponent.selectedApprovedComponent.ApprovedComponentRef, 1)
    ) {
      formErrors.ApprovedComponentRef = intl.formatMessage({
        id: 'approvedComponents.referenceRequired',
        defaultMessage: 'Approved component reference is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const componentOptions = () => {
    let componentOptions = [];

    approvedComponent.modelComponentTypes.forEach(element => {
      componentOptions.push({
        label: element.Name,
        value: element.ModelComponentTypeId
      });
    });
    return componentOptions.sort(compareValues('label'));
  };

  const subComponentOptions = id => {
    let subComponentOptions = [];

    let filteredList = approvedComponent.modelSubComponentTypes || [];

    if (id) {
      filteredList = filteredList.filter(x => x.ModelComponentTypeId == id);

      filteredList.forEach(element => {
        if (
          approvedComponent &&
          approvedComponent.selectedApprovedComponent &&
          approvedComponent.selectedApprovedComponent.ApprovedSubComponentsData.filter(
            x => x.ModelSubComponentTypeId === element.ModelSubComponentTypeId && !x.IsDeleted
          ).length === 1
        ) {
          element.isDisabled = true;
        } else {
          element.isDisabled = false;
        }

        subComponentOptions.push({
          ...element,
          label: element.Name,
          value: element.ModelSubComponentTypeId
        });
      });
    }

    return subComponentOptions.sort(compareValues('label'));
  };

  const disableModeHandler = status => {};
  const equationOptions = () => {
    let equationOptions = [];

    approvedComponent.calibrationEquations.forEach(element => {
      equationOptions.push({
        label: element.Name,
        value: element.CalibrationEquationId
      });
    });
    let sortedData = equationOptions.sort(compareValues('label'));
    return [{ value: 0, label: 'None' }, ...sortedData];
  };

  //on control value change
  const onChange = e => {
    setLocalApprovedComponent({
      ...approvedComponent,
      selectedApprovedComponent: { ...approvedComponent.selectedApprovedComponent, [e.target.name]: e.target.value }
    });
  };

  const onToggleChange = name => {
    setLocalApprovedComponent({
      ...approvedComponent,
      selectedApprovedComponent: {
        ...approvedComponent.selectedApprovedComponent,
        [name]: !approvedComponent.selectedApprovedComponent[name]
      }
    });
  };

  const onDropdownComponentTypeChange = e => {
    setLocalApprovedComponent({
      ...approvedComponent,
      selectedApprovedComponent: { ...approvedComponent.selectedApprovedComponent, ModelComponentTypeId: e.value }
    });
  };

  const showAddSubComponents = approvedComponent.selectedApprovedComponent.ModelComponentTypeId ? true : false;

  const cancelHandler = () => {
    history.push(backUrl);
  };

  const labelUnchecked = <FormattedMessage id="common.no" defaultMessage="No" />;
  const labelChecked = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  let messageId = (approvedComponent && approvedComponent.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const approvedComponentName =
    (approvedComponent.selectedApprovedComponent
      ? `${approvedComponent.selectedApprovedComponent.Brand} ${approvedComponent.selectedApprovedComponent.Model}`
      : '') || '';

  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'approvedComponents.edit', defaultMessage: 'Edit' }) + ' ' + approvedComponentName;
  } else {
    heading = intl.formatMessage({ id: 'approvedComponents.addNewApprovedComponent', defaultMessage: 'Add new approved component' });
  }

  const setAddHandler = () => {
    let data = initialApprovedComponentState.approvedComponent.selectedApprovedComponent.SelectedApprovedSubComponent;
    data.ApprovedComponentId = parseInt(approvedComponentId);
    showAddEditScreen(true);
    changeApprovedSubComponent({
      selectedSubApprovedComponent: data
    });
    setCurrentApprovedComponentId(0);
    setCurrentApprovedComponentIdentifier('');
  };

  const setEditHandler = (approvedComponentId, identifer) => {
    if (approvedComponentId > -1) {
      setCurrentApprovedComponentId(approvedComponentId);
      setCurrentApprovedComponentIdentifier(identifer);
      const list =
        (approvedComponent.selectedApprovedComponent && approvedComponent.selectedApprovedComponent.ApprovedSubComponentsData) || [];
      let subcomponents = list.filter(item => {
        if (item.GuidIdentifier === identifer) return item;
      });
      if (subcomponents.length > 0) {
        changeApprovedSubComponent({
          selectedSubApprovedComponent: subcomponents[0]
        });
        showAddEditScreen(true);
      }
    } else {
      let data = initialApprovedComponentState.approvedComponent.selectedApprovedComponent.SelectedApprovedSubComponent;
      setCurrentApprovedComponentId(-1);
      setCurrentApprovedComponentIdentifier('');
      showAddEditScreen(false);
      changeApprovedSubComponent({
        SelectedEquationConstant: data
      });
    }
  };

  return (
    <div className={s.approvedComponent}>
      {approvedComponent.isLoading && <LoadingSpinner />}
      {approvedComponent.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={approvedComponent.showBanner}
        status={approvedComponent.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageComponents" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.approvedComponentHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="approvedComponents.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.approvedComponentContent}>
            <div className={s.topRow}>
              <Form>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentBrand">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.brand" defaultMessage="Brand" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Brand"
                        onChange={onChange}
                        value={approvedComponent.selectedApprovedComponent.Brand}
                        className={`${s.formControl} ${localformErrors && localformErrors.Brand ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'approvedComponents.brand',
                          defaultMessage: 'Brand'
                        })}
                      />
                      {localformErrors && localformErrors.Brand && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Brand}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentModel">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.model" defaultMessage="Model" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Model"
                        onChange={onChange}
                        value={approvedComponent.selectedApprovedComponent.Model}
                        className={`${s.formControl} ${localformErrors && localformErrors.Model ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'approvedComponents.model',
                          defaultMessage: 'Model'
                        })}
                      />
                      {localformErrors && localformErrors.Model && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Model}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentReference">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.reference" defaultMessage="Approved Component Reference" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="ApprovedComponentRef"
                        onChange={onChange}
                        value={approvedComponent.selectedApprovedComponent.ApprovedComponentRef}
                        className={`${s.formControl} ${localformErrors && localformErrors.ApprovedComponentRef ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'approvedComponents.reference',
                          defaultMessage: 'Approved Component Reference'
                        })}
                      />
                      {localformErrors && localformErrors.ApprovedComponentRef && (
                        <p role="alert" className={s.error}>
                          {localformErrors.ApprovedComponentRef}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={4}></Col>
                </Row>
                <Row>
                  <Col lg={8}>
                    <Form.Group controlId="formApprovedComponentDescription">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'approvedComponents.description',
                          defaultMessage: 'Description'
                        })}
                        value={approvedComponent.selectedApprovedComponent.Description || ''}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentUnitOfMeasure">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.uOM" defaultMessage="Unit of measure" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="UnitOfMeasure"
                        onChange={onChange}
                        value={approvedComponent.selectedApprovedComponent.UnitOfMeasure || ''}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'approvedComponents.uOM',
                          defaultMessage: 'Unit of measure'
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group controlId="formComponentType">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.componentType" defaultMessage="Component type" />
                      </Form.Label>
                      <Dropdown
                        id="ddlComponentType"
                        dataArray={componentOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="approvedComponents.pleaseSelectOne" defaultMessage="Please select one" />,
                          customClassName: ddlClassComponentType
                        }}
                        onDropdownChange={onDropdownComponentTypeChange}
                        selectedOption={componentOptions().filter(
                          option => option.value === approvedComponent.selectedApprovedComponent.ModelComponentTypeId
                        )}
                        disabled={approvedComponent.selectedApprovedComponent.ApprovedSubComponentsData.length > 0}
                        data-unittest="ddlComponentType"
                      />
                      {localformErrors && localformErrors.ModelComponentTypeId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.ModelComponentTypeId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <hr />
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentHasState">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.hasState" defaultMessage="Has state" />
                      </Form.Label>
                      <ToggleSwitch
                        id="HasState"
                        handleClick={() => {
                          onToggleChange('HasState');
                        }}
                        classname={s.switch}
                        checked={approvedComponent.selectedApprovedComponent.HasState}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentHasFeedback">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.hasFeedback" defaultMessage="Has feedback" />
                      </Form.Label>
                      <ToggleSwitch
                        id="HasFeedback"
                        handleClick={() => {
                          onToggleChange('HasFeedback');
                        }}
                        classname={s.switch}
                        checked={approvedComponent.selectedApprovedComponent.HasFeedback}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <hr></hr>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentSupplierName">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.supplierName" defaultMessage="Supplier Name" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="SupplierName"
                        onChange={onChange}
                        value={approvedComponent.selectedApprovedComponent.SupplierName || ''}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'approvedComponents.supplierName',
                          defaultMessage: 'Supplier Name'
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentSupplierAddress">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.supplierAddress" defaultMessage="Supplier address" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="SupplierAddress"
                        onChange={onChange}
                        value={approvedComponent.selectedApprovedComponent.SupplierAddress || ''}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'approvedComponents.supplierAddress',
                          defaultMessage: 'Supplier address'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentManufacturerName">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.manufacturerName" defaultMessage="Manufacturer name" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="ManufacturerName"
                        onChange={onChange}
                        value={approvedComponent.selectedApprovedComponent.ManufacturerName || ''}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'approvedComponents.manufacturerName',
                          defaultMessage: 'Manufacturer name'
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentManufacturerAddress">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.manufacturerAddress" defaultMessage="Manufacturer address" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="ManufacturerAddress"
                        onChange={onChange}
                        value={approvedComponent.selectedApprovedComponent.ManufacturerAddress || ''}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'approvedComponents.manufacturerAddress',
                          defaultMessage: 'Manufacturer address'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentDataSheetURL">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.dataSheetURL" defaultMessage="Data sheet URL" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="DatasheetURL"
                        onChange={onChange}
                        value={approvedComponent.selectedApprovedComponent.DatasheetURL || ''}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'approvedComponents.dataSheetURL',
                          defaultMessage: 'Data sheet URL'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentServiceable">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.serviceable" defaultMessage="Serviceable" />
                      </Form.Label>
                      <ToggleSwitch
                        id="IsServiceable"
                        handleClick={() => {
                          onToggleChange('IsServiceable');
                        }}
                        classname={s.switch}
                        checked={approvedComponent.selectedApprovedComponent.IsServiceable}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentConsumable">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.consumable" defaultMessage="Consumable" />
                      </Form.Label>
                      <ToggleSwitch
                        id="IsConsumable"
                        handleClick={() => {
                          onToggleChange('IsConsumable');
                        }}
                        classname={s.switch}
                        checked={approvedComponent.selectedApprovedComponent.IsConsumable}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formApprovedComponentActive">
                      <Form.Label>
                        <FormattedMessage id="approvedComponents.active" defaultMessage="Active" />
                      </Form.Label>
                      <ToggleSwitch
                        id="IsActive"
                        handleClick={() => {
                          onToggleChange('IsActive');
                        }}
                        classname={s.switch}
                        checked={approvedComponent.selectedApprovedComponent.IsActive}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                        labelPosition="Right"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <hr></hr>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div>
                      <ApprovedSubComponentTable
                        SelectedApprovedComponent={approvedComponent.selectedApprovedComponent}
                        setEditHandler={setEditHandler}
                        disableMode={approvedComponent.selectedApprovedComponent.showAddEditScreen}
                        disableModeHandler={disableModeHandler}
                        selectedApprovedComponentConstantId={currentApprovedComponentId}
                        selectedIdentifier={currentApprovedComponentIdentifier}
                        changeApprovedSubComponent={changeApprovedSubComponent}
                        setApprovedSubComponentList={setApprovedSubComponentList}
                        componentSubTypeOptions={subComponentOptions(approvedComponent.selectedApprovedComponent.ModelComponentTypeId)}
                        equationOptions={equationOptions()}
                        userProfileWidgets={userProfileWidgets}
                        UpdateAllowed={isUpdateAllowed}
                        setAddHandler={setAddHandler}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <hr></hr>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="approvedComponents.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="approvedComponents.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ApprovedComponent.defaultProps = {
  approvedComponent: {
    ...initialApprovedComponentState.approvedComponent
  }
};

ApprovedComponent.propTypes = {
  saveApprovedComponent: PropTypes.func.isRequired,
  changeApprovedComponent: PropTypes.func.isRequired,
  approvedComponent: PropTypes.object.isRequired
};

export default injectIntl(ApprovedComponent);
