import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './UnitServiceList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import UnitServiceListTable from './UnitServiceListTable';
import Pagination from '../Pagination/Pagination';
import { UNIT_SERVICE_STATUS, DEFAULT_PAGE_SIZE } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues, downloadCsvFile, flattenObject } from '../../utils/';
import MaintenanceContentTemplate from '../Maintenance/MaintenanceContentTemplate';
import moment from 'moment-timezone';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import { useParams } from 'react-router-dom';
import { getUserProfileWidget, getSelectedDeviceDefault, getUnitService, getCurrentTimezone } from '../../selectors/index';
import { initialUnitServiceState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import { IsWidgetAccessible } from '../../utils/widgetManager';

import {
  getUnitServiceThunk,
  getUnitServiceFilterThunk,
  setUnitServiceFilteredDataThunk,
  setCurrentPage,
  setPageFilter,
  cleanUpThunk,
  getExportDataThunk,
  setExportCompleteThunk,
  setUnitServiceExportErrorThunk
} from '../../actions/unitServices';

const UNIT = 'UNIT';
const SERVICEUSER = 'SERVICEUSER';
const SERVICED_FROM = 'SERVICED_FROM';
const SERVICED_TO = 'SERVICED_TO';
const UNITSERVICESTATUS = 'UNITSERVICESTATUS';
const DAY_TIME = 86399;

const UnitServiceList = ({ intl }) => {
  const { unitSerialNumber } = useParams();
  const dispatch = useDispatch();
  const isUnitPage = unitSerialNumber && unitSerialNumber.length > 0 ? true : false;
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  //clean up
  useEffect(() => {
    return () => {
      dispatch(cleanUpThunk({ ...initialUnitServiceState }));
    };
  }, []);

  return (
    <div className={s.unitService}>
      {isUnitPage ? (
        <UnitPageListingTab intl={intl} isUnitPage={isUnitPage} userProfileWidgets={userProfileWidgets} />
      ) : (
        <ServiceListingTab intl={intl} isUnitPage={isUnitPage} userProfileWidgets={userProfileWidgets} />
      )}
    </div>
  );
};

export default injectIntl(UnitServiceList);

const UnitPageListingTab = ({ intl, isUnitPage, userProfileWidgets }) => {
  const selectedDevice = useSelector(state => getSelectedDeviceDefault(state));

  return (
    <ContentTemplate
      selectedPage="unitService"
      userProfileWidgets={userProfileWidgets}
      widgetCode={'UNIT_CONFIGURATION'}
      tagoutRequired={false}
    >
      <UnitServiceListContent intl={intl} unitId={selectedDevice.UnitId} isUnitPage={isUnitPage} userProfileWidgets={userProfileWidgets} />
    </ContentTemplate>
  );
};

const ServiceListingTab = ({ intl, isUnitPage, userProfileWidgets }) => {
  return (
    <MaintenanceContentTemplate selectedPage="service">
      <UnitServiceListContent intl={intl} unitId={0} isUnitPage={isUnitPage} userProfileWidgets={userProfileWidgets} />
    </MaintenanceContentTemplate>
  );
};

const UnitServiceListContent = ({ intl, unitId, isUnitPage, userProfileWidgets }) => {
  const dispatch = useDispatch();
  const unitService = useSelector(state => getUnitService(state));

  const [filterUnitId, setFilterUnitId] = useState(unitId);
  const [filterServiceUser, setFilterServiceUser] = useState(0);
  const [filterServiceFrom, setFilterServiceFrom] = useState(null);
  const [filterServiceTo, setFilterServiceTo] = useState(null);
  const [filterUnitServiceStatus, setFilterUnitServiceStatus] = useState(null);

  const [asendingOrder, setAsendingOrder] = useState(false);
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  let offset = unitService.offset || 0;
  let limit = unitService.limit || 1000;

  let filterLocalUnitId = unitService.filterBy.filterUnitId || null;
  let filterLocalServiceUser = unitService.filterBy.filterServiceUser || null;
  let filterLocalServiceFrom = unitService.filterBy.filterServiceFrom || null;
  let filterLocalServiceTo = unitService.filterBy.filterServiceTo || null;
  let filterLocalUnitServiceStatus = unitService.filterBy.filterUnitServiceStatus || null;
  let exportComplete = unitService.exportComplete || false;
  let isOpSuccessful = unitService.isOpSuccessful || false;
  let exportedResults = unitService.exportedResults || [];
  let disableControls = unitService.isLoading || false;

  useEffect(() => {
    if (isUnitPage) {
      if (unitId > 0) {
        dispatch(getUnitServiceFilterThunk('SERVICE_TAB', false));
      }
    } else {
      dispatch(getUnitServiceFilterThunk('SERVICE_TAB'));
    }
  }, [getUnitServiceFilterThunk, isUnitPage, unitId]);

  useEffect(() => {
    let filterObj = {
      filterUnitId: filterLocalUnitId,
      filterServiceUser: filterLocalServiceUser,
      filterServiceFrom: filterLocalServiceFrom,
      filterServiceTo: filterLocalServiceTo,
      filterUnitServiceStatus: filterLocalUnitServiceStatus,
      offset: offset,
      limit: limit
    };
    if (isUnitPage) {
      if (unitId > 0) {
        filterObj.filterUnitId = unitId;
        dispatch(getUnitServiceThunk(filterObj, 'SERVICE_TAB'));
      }
    } else {
      dispatch(getUnitServiceThunk(filterObj, 'SERVICE_TAB'));
    }
  }, [
    getUnitServiceThunk,
    offset,
    limit,
    filterLocalServiceTo,
    filterLocalServiceFrom,
    filterLocalServiceUser,
    filterLocalUnitId,
    filterLocalUnitServiceStatus,
    isUnitPage,
    unitId
  ]);

  //export check
  useEffect(() => {
    if (exportComplete && isOpSuccessful) {
      computeExportData();
    }
  }, [setExportCompleteThunk, exportComplete, isOpSuccessful]);

  let messageId = (unitService && unitService.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const placeHolderTextFromDate = intl.formatMessage({
    id: 'maintenance.unitService.fromDate',
    defaultMessage: 'From Date'
  });
  const placeHolderTexttoDate = intl.formatMessage({ id: 'maintenance.unitService.toDate', defaultMessage: 'To Date' });
  const noDataText = intl.formatMessage({ id: 'maintenance.unitService.noData', defaultMessage: 'No data is available' });
  const isExportAllowed = IsWidgetAccessible(userProfileWidgets, 'UNIT_SERVICE_EXPORT');

  const unitOptions = () => {
    let unitData = [];

    unitData.push({
      label: <FormattedMessage id="maintenance.unitService.allUnits" defaultMessage="All Units" />,
      value: 0
    });

    unitService.filterData.Units.forEach(element => {
      unitData.push({
        label: element.Name,
        value: element.UnitId
      });
    });
    return unitData;
  };

  const unitServiceStatusOptions = () => {
    let unitServiceStatusData = [];

    unitServiceStatusData.push({
      label: <FormattedMessage id="filters.allstatus" defaultMessage="All Status" />,
      value: '0'
    });
    Object.keys(UNIT_SERVICE_STATUS).map(element => {
      unitServiceStatusData.push({
        label: UNIT_SERVICE_STATUS[element].name,
        value: UNIT_SERVICE_STATUS[element].name
      });
    });
    return unitServiceStatusData;
  };

  const servicedByUserOptions = () => {
    let assigneeData = [];

    assigneeData.push({
      label: <FormattedMessage id="maintenance.unitService.allServicedUsers" defaultMessage="Serviced By All" />,
      value: 0
    });

    unitService.filterData.Assignees.forEach(element => {
      assigneeData.push({
        label: element.FullName,
        value: element.UserId
      });
    });
    return assigneeData;
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const onUnitChange = e => {
    setFilterUnitId(e.value);
    filteredResults(UNIT, e.value);
    setCurrentPage(1);
  };

  const onUnitServiceStatusChange = e => {
    setFilterUnitServiceStatus(e.value);
    filteredResults(UNITSERVICESTATUS, e.value);
    setCurrentPage(1);
  };

  const onServiceByUserChange = e => {
    setFilterServiceUser(e.value);
    filteredResults(SERVICEUSER, e.value);
    setCurrentPage(1);
  };

  const onFromDateChange = value => {
    if (value !== null && value > 0) {
      setFilterServiceFrom(value);
    } else setFilterServiceFrom(null);

    filteredResults(SERVICED_FROM, value);
    setCurrentPage(1);
  };

  const onToDateChange = value => {
    if (value !== null && value > 0) {
      setFilterServiceTo(value);
    } else setFilterServiceTo(null);
    filteredResults(SERVICED_TO, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = unitService.unitServices;

    let apifilterObj = {
      filterUnitId: null,
      filterServiceUser: null,
      filterServiceFrom: null,
      filterServiceTo: null,
      filterUnitServiceStatus: null
    };

    if (filterType === UNIT) {
      if (value !== 0) {
        apifilterObj.filterUnitId = value;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === value;
        });
      }
    } else {
      if (filterUnitId !== 0) {
        apifilterObj.filterUnitId = filterUnitId;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === filterUnitId;
        });
      }
    }

    if (filterType === SERVICEUSER) {
      if (value !== 0) {
        apifilterObj.filterServiceUser = value;
        filteredList = filteredList.filter(function(item) {
          return item.ServicedBy === value;
        });
      }
    } else {
      if (filterServiceUser !== 0) {
        apifilterObj.filterServiceUser = filterServiceUser;
        filteredList = filteredList.filter(function(item) {
          return item.ServicedBy === filterServiceUser;
        });
      }
    }

    if (filterType === SERVICED_FROM) {
      if (value !== null && value !== '') {
        const timestampValue = value / 1000;
        apifilterObj.filterServiceFrom = timestampValue;

        filteredList = filteredList.filter(function(item) {
          return item.ServiceDateTime >= timestampValue;
        });
      }
    } else {
      if (filterServiceFrom !== null && filterServiceFrom !== '') {
        const timestampValue = filterServiceFrom / 1000;
        apifilterObj.filterServiceFrom = timestampValue;
        filteredList = filteredList.filter(function(item) {
          return item.ServiceDateTime >= timestampValue;
        });
      }
    }

    if (filterType === SERVICED_TO) {
      if (value !== null && value !== '') {
        const timestampValue = value / 1000;
        apifilterObj.filterServiceTo = timestampValue;

        filteredList = filteredList.filter(function(item) {
          return item.ServiceDateTime <= timestampValue;
        });
      }
    } else {
      if (filterServiceTo !== null && filterServiceFrom !== '') {
        const timestampValue = filterServiceTo / 1000;
        apifilterObj.filterServiceTo = timestampValue;

        filteredList = filteredList.filter(function(item) {
          return item.ServiceDateTime <= timestampValue;
        });
      }
    }

    if (filterType === UNITSERVICESTATUS) {
      if (value && value !== '0') {
        apifilterObj.filterUnitServiceStatus = value;
        filteredList = filteredList.filter(function(item) {
          return item.UnitServiceStatus === value;
        });
      }
    } else {
      if (filterUnitServiceStatus && filterUnitServiceStatus !== '0') {
        apifilterObj.filterUnitServiceStatus = filterUnitServiceStatus;
        filteredList = filteredList.filter(function(item) {
          return item.UnitServiceStatus === filterUnitServiceStatus;
        });
      }
    }

    if (unitService.servicesListCount > unitService.unitServices.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setUnitServiceFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let unitServicesObj = Object.values(unitService.filteredResults);

  if (asendingOrder) {
    unitServicesObj = unitServicesObj.sort(compareValues('ServiceDateTime'));
  } else {
    unitServicesObj = unitServicesObj.sort(compareValues('ServiceDateTime', 'desc'));
  }

  //Export Code
  const exportReportHandler = () => {
    const requireApiFilter = unitService.servicesListCount > unitService.unitServices.length ? true : false;

    let filterObj = {
      filterUnitId: requireApiFilter ? filterLocalUnitId : filterUnitId ? filterUnitId : null,
      filterUnitServiceStatus: requireApiFilter ? filterLocalUnitServiceStatus : filterUnitServiceStatus ? filterUnitServiceStatus : null,
      filterServiceUser: requireApiFilter ? filterLocalServiceUser : filterServiceUser ? filterServiceUser : null,
      filterServiceFrom: requireApiFilter ? filterLocalServiceFrom : filterServiceFrom ? filterServiceFrom / 1000 : null,
      filterServiceTo: requireApiFilter ? filterLocalServiceTo : filterServiceTo ? filterServiceTo / 1000 : null,
      offset: offset,
      limit: limit
    };

    if (isUnitPage) {
      if (unitId > 0) {
        filterObj.filterUnitId = unitId;
        dispatch(getExportDataThunk(filterObj, 'SERVICE_TAB'));
      }
    } else {
      dispatch(getExportDataThunk(filterObj, 'SERVICE_TAB'));
    }
  };

  const computeExportData = useCallback(() => {
    const computePromise = new Promise((resolve, reject) => {
      try {
        let exportFormattedData = exportedResults.map(item => {
          return {
            ...item,
            ServiceDateTime: moment
              .unix(item.ServiceDateTime)
              .tz(currentTimezone)
              .format('DD-MMM-YYYY HH:mm:ss z')
          };
        });
        let convertToCSV = jsonToCsv([{ NoData: noDataText }]);

        if (exportFormattedData.length > 0) {
          convertToCSV = jsonToCsv(exportFormattedData);
        }
        downloadCsvFile(convertToCSV, `unitservice_${moment().unix()}.csv`);
        resolve(dispatch(setExportCompleteThunk()));
      } catch (error) {
        reject(dispatch(setUnitServiceExportErrorThunk('maintenance.unitService.unableToExport')));
      }
    });

    return computePromise;
  }, [dispatch, exportedResults]);

  return (
    <div className={s.contentWrapper}>
      {unitService.isLoading && <LoadingSpinner />}
      <Banner failureText={messageText} showBanner={unitService.showBanner} status={unitService.isOpSuccessful} successText={messageText} />
      <div className={s.unitServiceHeader}>
        <div className={s.subHeading}>
          <FormattedMessage id="maintenance.unitService" defaultMessage="Unit Service" />
          {/* <span className={s.circle}>{unitService.servicesListCount}</span> */}
        </div>
      </div>
      <Row>
        <Col sm={12}>
          <span className={s.textHeading}>
            {' '}
            <FormattedMessage id="maintenance.unitService.filterBy" defaultMessage="Filter By" />
          </span>
        </Col>
      </Row>
      <Row>
        {!isUnitPage && (
          <Col sm={6} lg={2}>
            <Dropdown
              id="drpUnitId"
              dataArray={unitOptions()}
              controlData={{
                placeholderText: <FormattedMessage id="maintenance.unitService.unit" defaultMessage="Unit" />,
                customClassName: ''
              }}
              onDropdownChange={e => onUnitChange(e)}
              selectedOption={unitOptions().filter(option => option.value === filterUnitId)}
              disabled={disableControls}
            />
          </Col>
        )}

        <Col sm={6} lg={2}>
          <Dropdown
            id="drpServicedBy"
            dataArray={servicedByUserOptions()}
            controlData={{
              placeholderText: <FormattedMessage id="maintenance.unitService.servicedBy" defaultMessage="Serviced By" />,
              customClassName: ''
            }}
            onDropdownChange={e => onServiceByUserChange(e)}
            selectedOption={servicedByUserOptions().filter(option => option.value === filterServiceUser)}
            disabled={disableControls}
          />
        </Col>
        <Col sm={6} lg={2}>
          <Dropdown
            id="drpUnitServiceStatus"
            dataArray={unitServiceStatusOptions()}
            controlData={{
              placeholderText: <FormattedMessage id="filters.allstatus" defaultMessage="All Status" />,
              customClassName: ''
            }}
            onDropdownChange={e => onUnitServiceStatusChange(e)}
            selectedOption={unitServiceStatusOptions().filter(option => option.value === filterUnitServiceStatus)}
            disabled={disableControls}
          />
        </Col>
        <Col sm={6} lg={2}>
          <DateTimePicker
            className={s.datetimeInput}
            onChange={m => onFromDateChange(m.valueOf())}
            timeFormat={null}
            dateFormat="DD-MMM-YYYY"
            checkValue={filterServiceFrom}
            value={moment(filterServiceFrom).tz(currentTimezone)}
            defaultValue={moment(filterServiceFrom).tz(currentTimezone)}
            placeHolderText={placeHolderTextFromDate}
            closeOnSelect
            showClear={true}
            disabled={disableControls}
            onClear={m => onFromDateChange(null)}
          />
        </Col>
        <Col sm={6} lg={2}>
          <DateTimePicker
            className={s.datetimeInput}
            onChange={m => onToDateChange(m.valueOf())}
            timeFormat={null}
            dateFormat="DD-MMM-YYYY"
            checkValue={filterServiceTo}
            value={moment(filterServiceTo).tz(currentTimezone)}
            defaultValue={moment(filterServiceTo).tz(currentTimezone)}
            placeHolderText={placeHolderTexttoDate}
            closeOnSelect
            showClear={true}
            disabled={disableControls}
            onClear={m => onToDateChange(null)}
          />
        </Col>
        <Col sm={6} lg={isUnitPage ? 4 : 2}>
          {isExportAllowed && (
            <Button className={s.export} onClick={exportReportHandler} disabled={disableControls}>
              <FormattedMessage id="maintenance.unitService.export" defaultMessage="Export" />
            </Button>
          )}
        </Col>
      </Row>
      <div className="">
        <Pagination
          data={unitServicesObj}
          pageSize={DEFAULT_PAGE_SIZE}
          onPageChange={onPageChangeHandler}
          startingPage={unitService.currentPage}
        >
          <UnitServiceListTable currentTimezone={currentTimezone} onSortOrder={onSortOrder} asendingOrder={asendingOrder} />
        </Pagination>
      </div>
    </div>
  );
};

const jsonToCsv = items => {
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = Object.keys(flattenObject(items[0]));

  let csv = items.map(row => {
    let flattenedRow = flattenObject(row);
    return header.map(fieldName => JSON.stringify(flattenedRow[fieldName], replacer)).join(',');
  });
  csv.unshift(header.join(','));
  return csv.join('\r\n');
};
