import { initialSidebarState } from './initialState';
import * as sidebarActionTypes from '../constants/index';

export default (state = initialSidebarState, action = {}) => {
  switch (action.type) {
    case sidebarActionTypes.TOGGLE_SIDEBAR:
      return { ...state, sidebarOpen: action.sidebarOpen };

    default:
      return state;
  }
};
