import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EventCode from './EventCode';
import {
  getEventCode,
  getAllModelComponentPositions,
  getFlowSequenceGlobalSettings,
  getUserProfileWidget,
  getCurrentTimezone
} from '../../selectors/index';
import {
  describeEventCodeThunk,
  deleteEventCodeThunk,
  setEventCodeChangeThunk,
  saveEventCodeThunk,
  eventCodeCleanUpThunk
} from '../../actions/eventCode';

const mapStateToProps = state => {
  return {
    eventCodeManager: getEventCode(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state),
    flowSequences: getFlowSequenceGlobalSettings(state),
    modelComponentPositions: getAllModelComponentPositions(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeEventCode: eventCodeId => describeEventCodeThunk(eventCodeId, 'EVENTCODEUPDATE'),
      deleteEventCode: eventCodeId => deleteEventCodeThunk(eventCodeId, 'EVENTCODEDELETE'),
      setEventCodeChange: eventCode => setEventCodeChangeThunk(eventCode),
      saveEventCode: (eventCode, widgetCode) => saveEventCodeThunk(eventCode, widgetCode),
      eventCodeCleanUp: eventCode => eventCodeCleanUpThunk(eventCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventCode);
