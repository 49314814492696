exports.sampleJsonData = {
  name: 'samplestate',
  description: '',
  entry: {
    set_sequence: [
      {
        set: {
          vsd101_state: 'off',
          uvb101_state: 'off',
          aco101_state: 'on',
          ppa101_state: 'off',
          ppc101_state: 'off',
          ppa102_speed: 0,
          vbl101_position: 'open',
          vbl102_position: 'closed'
        },
        wait_for: "setting('ballvalvecycletime')"
      }
    ],
    configure_components: [
      {
        configure: {
          trb101_turbidity: 2,
          trb102_turbidity: 1,
          ctr101_conductivity: 4
        }
      }
    ],
    control: [
      {
        name: 'INITIALIZE Pre-Filter 1 Flush Clean Poison Timer',
        condition_exp:
          "((component('vbl102_position')=='open') and (component('ptc101_pressure') <= threshold('ptc101_pressure', 'highhigh')))",
        action: {
          type: 'store',
          location: 'PF1FlushCleanPoisonEndTime',
          value: "now() + setting('poisontimerdurationgeneral') + setting('prefilter1cleancycleduration')"
        }
      }
    ]
  },
  loop: {
    verify: {
      vsd101_state: 'off',
      vbl101_position: 'open',
      vbl102_position: 'closed'
    },
    control: [
      {
        name: 'START Pre-Filter 1 Flush Clean - OPEN Pre-Filter 1 Drain Valve',
        condition_exp:
          "((component('ptc101_pressure') <= threshold('ptc101_pressure', 'highhigh')) and (local('PF1FluchCleanEndTime') == 0))",
        action: {
          type: 'command',
          commands: [
            {
              set: {
                vbl102_position: 'open'
              }
            }
          ]
        }
      },
      {
        name: 'SETUP & START Pre-Filter 1 Flush Clean End Time',
        condition_exp:
          "((component('vbl101_position')=='open') and (component('vbl102_position')=='open') and (local('PF1FluchCleanEndTime') == 0))",
        action: {
          type: 'store',
          location: 'PF1FluchCleanEndTime',
          value:
            "now() + local('prefilter1cleancycleduration') + setting('prefilter1cleancycleduration') + component('ptc101_pressure') + threshold('ptc101_pressure', 'highhigh')"
        }
      },
      {
        name: 'STOP Pre-Filter 1 Flush Clean - If Poison Timer Has Ended - Transition to TagOut State',
        condition_exp: "now() >= local('PF1FlushCleanPoisonEndTime')",
        action: {
          type: 'transition',
          event_code: 4003,
          transition: 'tagoutstate'
        }
      }
    ],
    substate: [
      {
        name: 'Production Initialization',
        condition_exp: '1==1',
        order: 1,
        action: {
          type: 'transition',
          transition: 'productioninitializationstate'
        }
      },
      {
        name: 'Back Flush Tank Fill',
        condition_exp:
          "((component('tnk102_volume') < threshold('tnk102_volume', 'ophigh')) and (local('ProductionUVOperationalStatus')=='READY'))",
        order: 2,
        action: {
          type: 'transition',
          transition: 'productionbackflushtankfillstate'
        }
      }
    ],
    thresholds: {
      ttr101_temperature: {
        name: 'Fluid Temperature - Enclosure Air Temperature',
        highhigh: {
          event_code: 3000,
          transition: 'tagoutstate'
        },
        high: {
          event_code: 3001
        },
        low: {
          event_code: 3002
        },
        lowlow: {
          event_code: 3003,
          transition: 'tagoutstate'
        }
      },
      ctr101_temperature: {
        name: 'Fluid Temperature - System Water Input',
        highhigh: {
          condition_exp: "component('vbl101_position')=='open'",
          event_code: 3214,
          transition: 'tagoutstate'
        },
        high: {
          condition_exp: "component('vbl101_position')=='open'",
          event_code: 3215
        },
        low: {
          condition_exp:
            "component('vbl101_position')=='open' and local('prefilter1cleancycleduration') > setting('prefilter1cleancycleduration') or component('ptc101_pressure') == threshold('ptc101_pressure', 'highhigh')",
          event_code: 3216
        },
        lowlow: {
          condition_exp: "component('vbl101_position')=='open'",
          event_code: 3217,
          transition: 'tagoutstate'
        }
      }
    }
  },
  triggers: [
    {
      name: 'Automatic Transition To Next State',
      condition_exp: '',
      action: {
        type: 'transition',
        transition: 'waitstate'
      }
    },
    {
      name: 'STOP Pre-Filter 1 Flush Clean',
      condition_exp: "((now() > local('PF1FluchCleanEndTime')) and (local('PF1FluchCleanEndTime') != 0))",
      action: {
        type: 'transition',
        transition: 'prefilterflushcleanstate'
      }
    }
  ],
  exit: {
    set_sequence: [
      {
        set: {
          vsd101_state: 'on',
          uvb101_state: 'off',
          aco101_state: 'on',
          ppa101_state: 'off',
          ppc101_state: 'off',
          ppf101_state: 'off',
          ppa102_speed: 0,
          vbl101_position: 'closed',
          vbl102_position: 'open'
        },
        wait_for: "setting('ballvalvecycletime')"
      }
    ],
    control: [
      {
        name: 'INCRIMENT Total Membrane Water Volume Processed in Liters',
        condition_exp:
          "component('vbl101_position')=='open' and local('prefilter1cleancycleduration') > setting('prefilter1cleancycleduration') or component('ptc101_pressure') == threshold('ptc101_pressure', 'highhigh')",
        action: {
          type: 'store',
          location: 'UFMembraneWaterVolumeProcessedTotal',
          value: "((local('UFMembraneWaterVolumeProcessedTotal')) + (local('ProductionWaterVolumeProcessedTotal')))"
        }
      },
      {
        name: 'SET Membrane Water Volume Status',
        condition_exp: '1==1',
        action: {
          type: 'store',
          location: 'UFMembrWaterVolumeStatus',
          value: "'FULL'"
        }
      }
    ]
  }
};
