import {
  GET_ALL_COMPONENT_PARTS_DETAILS,
  COMPONENT_PARTS_IS_LOADING,
  COMPONENT_PARTS_ERROR,
  GET_ALL_COMPONENT_PARTS_FILTERS,
  SET_COMPONENT_PARTS_CURRENT_PAGE,
  SET_COMPONENT_PARTS_FILTERED_RESULTS,
  SET_COMPONENT_PARTS_PAGE_FILTER,
  SET_COMPONENT_PARTS_CHANGE,
  SET_COMPONENT_PARTS,
  UPDATE_COMPONENT_PARTS_SAVE_STATUS
} from '../constants/index';
import { initialComponentPartsState } from './initialState';

const componentParts = (state = initialComponentPartsState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_COMPONENT_PARTS_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        componentParts: {
          ...state.componentParts,
          ...data
        }
      };
    }

    case GET_ALL_COMPONENT_PARTS_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        componentParts: {
          ...state.componentParts,
          ...data
        }
      };
    }

    case SET_COMPONENT_PARTS_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        componentParts: {
          ...state.componentParts,
          ...filteredResults
        }
      };
    }

    case COMPONENT_PARTS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        componentParts: {
          ...state.componentParts,
          isLoading: isLoading
        }
      };
    }

    case COMPONENT_PARTS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        componentParts: {
          ...state.componentParts,
          ...data
        }
      };
    }

    case SET_COMPONENT_PARTS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        componentParts: {
          ...state.componentParts,
          currentPage
        }
      };
    }

    case SET_COMPONENT_PARTS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        componentParts: {
          ...state.componentParts,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_COMPONENT_PARTS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        componentParts: {
          ...state.componentParts,
          ...data
        }
      };
    }

    case SET_COMPONENT_PARTS: {
      let { selectedComponentPart, isOpSuccessful } = payload;

      return {
        ...state,
        componentParts: {
          ...state.componentParts,
          selectedComponentPart: {
            ...state.componentParts.selectedComponentPart,
            ...selectedComponentPart
          },
          isOpSuccessful: isOpSuccessful
        }
      };
    }

    case SET_COMPONENT_PARTS_CHANGE: {
      let { selectedComponentPart } = payload;

      return {
        ...state,
        componentParts: {
          ...state.componentParts,
          selectedComponentPart: {
            ...state.componentParts.selectedComponentPart,
            ...selectedComponentPart
          }
        }
      };
    }

    default:
      return state;
  }
};

export default componentParts;
