import React from 'react';
import PropTypes from 'prop-types';
import s from './ModelList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import MoreOption from '../WSAControls/MoreOption/MoreOption';

const ModelListTable = ({ paginatedData, currentPage, UpdateAllowed, DuplicateAllowed, ConfigurationAllowed, currentTimezone }) => {
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="model.modelName" defaultMessage="Model Name" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="model.description" defaultMessage="Description" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="model.dateCreated" defaultMessage="Date created" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="model.dateModified" defaultMessage="Date modified" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="model.versions" defaultMessage="Versions" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.ModelId} data-unittest="modelData">
                <td>{n.Name || ''}</td>
                <td className="d-none d-md-table-cell">{n.Description || ''}</td>
                <td className="d-none d-md-table-cell">
                  {n.CreatedDateTime
                    ? moment
                        .unix(n.CreatedDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY')
                    : '-'}
                </td>
                <td className="d-none d-md-table-cell">
                  {n.ModifiedDateTime
                    ? moment
                        .unix(n.ModifiedDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY')
                    : '-'}
                </td>
                <td className="d-none d-md-table-cell">{n.NumberOfVersions || 0}</td>
                <td>
                  <MoreOption
                    dataArray={[
                      ConfigurationAllowed
                        ? {
                            Url: `/admin-controls/model-configuration/${n.ModelId}/`,
                            Name: <FormattedMessage id="model.configuration" defaultMessage="Configuration" />
                          }
                        : {},
                      UpdateAllowed
                        ? {
                            Url: `/admin-controls/model/${n.ModelId}`,
                            Name: <FormattedMessage id="model.edit" defaultMessage="Edit" />
                          }
                        : {},
                      DuplicateAllowed
                        ? {
                            Url: `/admin-controls/model/${n.ModelId}/true`,
                            Name: <FormattedMessage id="model.duplicate" defaultMessage="Duplicate" />
                          }
                        : {}
                      // { Url: `#`, Name: <FormattedMessage id="model.delete" defaultMessage="Delete" /> }
                    ]}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ModelListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

ModelListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(ModelListTable);
