import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import s from './SequenceVersionDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import moment from 'moment-timezone';
// import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Form from 'react-bootstrap/Form';

const SequenceVersionFiles = ({
  intl,
  onSequenceFileRemove,
  addNewBtnClick,
  viewOrChangeBtnClick,
  selectedFiles,
  sequenceGlobalSettingsOptions,
  isPublished,
  isView,
  isEdit,
  deleteAllowed,
  changeAllowed,
  currentTimezone
}) => {
  let { modelId, modelVersionId, sequenceVersionId } = useParams();
  const [searchKeyword, setSearchKeyword] = useState('');

  let filteredSelectedFiles = selectedFiles;
  if (searchKeyword && searchKeyword != '') {
    filteredSelectedFiles = selectedFiles.filter(item => item.SequenceCode.toLowerCase().includes(searchKeyword.toLowerCase()));
  }

  const onChange = e => {
    setSearchKeyword(e.target.value);
  };

  const onAddNewBtnClick = () => {
    addNewBtnClick();
  };

  const onViewOrChangeBtnClick = id => {
    viewOrChangeBtnClick(id);
  };
  const labelNo = <FormattedMessage id="common.no" defaultMessage="No" />;
  const labelYes = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  return (
    <div className={s.taskContainer}>
      <div className={s.subHeading}>
        <h4>
          <FormattedMessage id="sequenceVersionDetailFiles.sequenceFiles" defaultMessage="Sequence Files" />
        </h4>
        <span className={s.circle}>{filteredSelectedFiles.length}</span>
      </div>
      <div>
        <Row>
          <Col xs={6} lg={4}>
            {/* <Dropdown
              id="sequenceGlobalSettingSearch"
              dataArray={sequenceGlobalSettingsOptions()}
              controlData={{
                placeholderText: intl.formatMessage({ id: 'sequenceVersionDetailFile.showAll', defaultMessage: 'Show:All' }),
                customClassName: s.ddlFileStatusSearch
              }}
              onDropdownChange={onDropdownChange}
              data-unittest="sequenceGlobalSettingSearch"
            /> */}

            <Form.Control
              type="text"
              name="sequenceGlobalSettingSearch"
              onChange={onChange}
              value={searchKeyword}
              className={`${s.formControl}`}
              placeholder={intl.formatMessage({
                id: 'sequenceVersionManagement.search',
                defaultMessage: 'Search'
              })}
            />
          </Col>
          <Col xs={6} lg={8} className={s.colAddButton}>
            {!isView && (
              <Button
                disabled={!isEdit || isPublished}
                variant="primary"
                data-unittest="addnew"
                className={s.addButton}
                onClick={() => onAddNewBtnClick()}
              >
                + <FormattedMessage id="sequenceVersionDetailFiles.addNew" defaultMessage="ADD NEW" />
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <div>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="sequenceVersionDetailFiles.sequenceName" defaultMessage="Sequence Name" />
              </th>
              <th>
                <FormattedMessage id="sequenceVersionDetailFiles.subState" defaultMessage="Super State" />
              </th>
              <th>
                <FormattedMessage id="sequenceVersionDetailFiles.superStateName" defaultMessage="Super State Name" />
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {filteredSelectedFiles.length === 0 && (
              <tr className={s.emptyRow} data-unittest="FileData">
                <td colSpan="6">
                  <FormattedMessage id="sequenceVersionDetailFiles.noData" defaultMessage="No Data" />
                </td>
              </tr>
            )}
            {filteredSelectedFiles.map((n, index) => {
              return (
                <Fragment key={index}>
                  <tr data-unittest="FileData">
                    <td>{n.SequenceCode}</td>
                    <td>{n.HasSuperState ? labelYes : labelNo}</td>

                    <td>{n.SuperStateName || '-'}</td>
                    <td>
                      {!isView && !isPublished && deleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          data-unittest="remove"
                          onClick={() => onSequenceFileRemove(n.SequenceVersionFileId)}
                          className={s.taskRowButton}
                        >
                          <FormattedMessage id="sequenceVersionDetailFiles.remove" defaultMessage="REMOVE" />
                        </Button>
                      )}

                      {((isEdit && changeAllowed) || isView) && (
                        <Button
                          onClick={() => {
                            onViewOrChangeBtnClick(n.SequenceVersionFileId);
                          }}
                          variant="outline-secondary"
                          data-unittest="view"
                          className={s.taskRowButton}
                        >
                          {isView ? (
                            <FormattedMessage id="sequenceVersionDetailFiless.view" defaultMessage="VIEW" />
                          ) : (
                            <FormattedMessage id="sequenceVersionDetailFiless.change" defaultMessage="CHANGE" />
                          )}
                        </Button>
                      )}
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

SequenceVersionFiles.defaultProps = {
  selectedFiles: [],
  tasks: []
};

SequenceVersionFiles.propTypes = {
  onFileSearch: PropTypes.func.isRequired,
  onSequenceFileRemove: PropTypes.func.isRequired
};

export default injectIntl(SequenceVersionFiles);
