import {
  GET_GLOBAL_SETTINGS,
  GLOBAL_SETTINGS_IS_LOADING,
  GLOBAL_SETTINGS_MANAGEMENT_ERROR,
  GLOBAL_SETTINGS_SET_CURRENT_PAGE,
  GLOBAL_SETTINGS_SET_PAGE_FILTER,
  GLOBAL_SETTINGS_SET,
  GLOBAL_SETTINGS_UPDATE_SAVE_STATUS,
  GLOBAL_SETTINGS_SET_CHANGE,
  GLOBAL_SETTINGS_SET_FILTERED_RESULTS
} from '../constants/index';
import { initialGlobalSettingsState } from './initialState';

const globalSettingsReducer = (state = initialGlobalSettingsState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_GLOBAL_SETTINGS: {
      const { data } = payload;
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          ...data.globalSettings,
          isDataPresent: true,
          isLoading: data.isLoading,
          redirect: false
        }
      };
    }

    case GLOBAL_SETTINGS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          isLoading: isLoading
        }
      };
    }

    case GLOBAL_SETTINGS_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          ...data
        }
      };
    }

    case GLOBAL_SETTINGS_SET_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          currentPage
        }
      };
    }

    case GLOBAL_SETTINGS_SET_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          filter
        }
      };
    }

    case GLOBAL_SETTINGS_UPDATE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          ...data
        }
      };
    }

    case GLOBAL_SETTINGS_SET: {
      let { globalSettings } = payload;

      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          isOpSuccessful: false,
          showBanner: false,
          selectedGlobalSettings: {
            ...state.globalSettings.selectedGlobalSettings,
            ...globalSettings.selectedGlobalSettings
          }
        }
      };
    }

    case GLOBAL_SETTINGS_SET_CHANGE: {
      let { selectedGlobalSettings } = payload;

      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          isOpSuccessful: false,
          showBanner: false,
          redirect: false,
          selectedGlobalSettings: {
            ...state.globalSettings.selectedGlobalSettings,
            ...selectedGlobalSettings
          }
        }
      };
    }

    case GLOBAL_SETTINGS_SET_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          ...filteredResults
        }
      };
    }

    default:
      return state;
  }
};

export default globalSettingsReducer;
