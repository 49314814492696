import React from 'react';
import PropTypes from 'prop-types';

import Banner from '../Banner/Banner';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import { Link, useParams } from 'react-router-dom';
import CommandPanel from '../CommandPanel/CommandPanelContainer';
import UnitTagOut from '../UnitTagOut/UnitTagOut';
import EventLog from '../EventLog/EventLogContainer';
import DeviceSchematic from '../DeviceSchematic/DeviceSchematicContainer';

import s from './ProcessFlow.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import bs from '../../styles/bootstrap-overrides.scss';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import UnitStatistics from '../UnitStatistics/UnitStatistics';

const ProcessFlow = ({
  events,
  selectedDevice,
  selectedUnit,
  sensorData,
  showBanner,
  currentTimezone,
  intl,
  userProfileWidgets,
  unitManager,
  flowSequences,
  displayStatusMessage,
  errorStatus
}) => {
  const devicesObj = Object.values(selectedDevice);
  const { unitSerialNumber } = useParams();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const displayBanner = showBanner || unitManager.showBanner ? true : false;
  let messageText = '';
  let opStatus = false;
  // Command Status and Message
  if (showBanner) {
    if (displayStatusMessage) messageText = intl.formatMessage({ id: displayStatusMessage, defaultMessage: displayStatusMessage });
    opStatus = errorStatus;
  }
  // Tagout and Unit status messages
  if (unitManager.showBanner) {
    messageText =
      unitManager.displayMessageCode.length > 0
        ? intl.formatMessage({ id: unitManager.displayMessageCode, defaultMessage: unitManager.displayMessageCode })
        : '';
    opStatus = unitManager.isOpSuccessful;
  }

  const unitComponentParts =
    (unitManager.selectedUnit.unitComponentPositions &&
      unitManager.selectedUnit.unitComponentPositions.filter(comp => comp.NeedFeedback)) ||
    [];

  let defaultTimezone = Cookies.get(`defaultTimezone-${envName}`);
  if (defaultTimezone === undefined) {
    defaultTimezone = moment.tz.guess();
  }
  let widgetCode = '';

  if (IsWidgetAccessible(userProfileWidgets, 'DEVICESCHEMATICDIAGRAM')) {
    widgetCode = 'DEVICESCHEMATICDIAGRAM';
  } else if (IsWidgetAccessible(userProfileWidgets, 'REALTIMESENSORS')) {
    widgetCode = 'REALTIMESENSORS';
  } else if (IsWidgetAccessible(userProfileWidgets, 'EVENTLOGSUMMARY')) {
    widgetCode = 'EVENTLOGSUMMARY';
  }

  return (
    <div className={s.processFlow}>
      {unitManager.isLoading && <LoadingSpinner />}

      <Banner failureText={messageText} showBanner={displayBanner} status={opStatus} successText={messageText} />
      <ContentTemplate
        selectedPage="processFlow"
        backLink="/dashboard"
        userProfileWidgets={userProfileWidgets}
        widgetCode={widgetCode}
        tagoutRequired={true}
      >
        {IsWidgetAccessible(userProfileWidgets, 'UNIT_STATISTICS_VIEW') && (
          <div className="row">
            <UnitStatistics fromDashboard={false} />
          </div>
        )}

        <div className="row">
          {IsWidgetAccessible(userProfileWidgets, 'DEVICESCHEMATICDIAGRAM') || IsWidgetAccessible(userProfileWidgets, 'REALTIMESENSORS') ? (
            <div className="col-md-12">
              <DeviceSchematic
                sensorData={sensorData}
                currentTimezone={currentTimezone}
                userProfileWidgets={userProfileWidgets}
                unitComponentParts={unitComponentParts}
                flowSequences={flowSequences}
                selectedUnit={selectedUnit}
              />
            </div>
          ) : (
            <div />
          )}
        </div>

        <div className={`${s.contentRow} row ml-0 mr-0`}>
          <div className={`${s.eventWrapper} col-md-12 col-lg-6 `} style={WidgetVisibility(userProfileWidgets, 'EVENTLOGSUMMARY')}>
            <h4>
              <FormattedMessage id="processFlow.eventLog" defaultMessage="Event Log" />
            </h4>
            <EventLog view="processFlow" limit={5} events={events} currentTimezone={currentTimezone} />
            <div className={s.evenlogButton}>
              <Link to={`/events/${unitSerialNumber}`}>
                <Button variant="primary" className={s.viewAll}>
                  <FormattedMessage id="processFlow.allEventsLog" defaultMessage="View All Event Logs" />
                </Button>
              </Link>
            </div>
          </div>
          <div className={`${s.commandPanel} col-md-12 col-lg-6 pr-0`} style={WidgetVisibility(userProfileWidgets, 'IOTCOMMANDSSUMMARY')}>
            <div className={`${s.commandPanel} col-md-12 pr-0`} style={WidgetVisibility(userProfileWidgets, 'IOTCOMMANDSSUMMARY')}>
              <CommandPanel flowSequences={flowSequences} userProfileWidgets={userProfileWidgets} />
            </div>
            <div className={`${s.commandPanel} col-md-12 pr-0`} style={WidgetVisibility(userProfileWidgets, 'UNIT_TAGGING')}>
              <UnitTagOut
                currentTimezone={currentTimezone}
                userProfileWidgets={userProfileWidgets}
                unitId={unitManager.selectedUnit.UnitId}
                isLoading={unitManager.isLoading}
                flowSequences={flowSequences}
              />
            </div>
          </div>
        </div>
      </ContentTemplate>
    </div>
  );
};

ProcessFlow.defaultProps = {
  selectedDevice: {
    unitSerialNumber: ''
  },
  unitManager: {
    selectedUnit: {
      unitComponentPositions: []
    }
  },
  flowSequences: []
};

ProcessFlow.propTypes = {
  events: PropTypes.array.isRequired,
  selectedDevice: PropTypes.object,
  sensorData: PropTypes.object.isRequired,
  showBanner: PropTypes.bool.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  flowSequences: PropTypes.array.isRequired
};

export default injectIntl(ProcessFlow);
