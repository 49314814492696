import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelComponentPosition from './ModelComponentPosition';
import { getUserProfileWidget, getModelComponentPositionManager } from '../../selectors/index';

import {
  describeModelComponentPositionThunk,
  saveModelComponentPositionThunk,
  setModelComponentPositionChangeThunk,
  resetModelComponentThunk
} from '../../actions/modelComponentPositions';

const mapStateToProps = state => {
  return {
    modelComponentPositionManager: getModelComponentPositionManager(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveModelComponentPosition: (modelComponentPosition, widgetCode) =>
        saveModelComponentPositionThunk(modelComponentPosition, widgetCode),
      describeModelComponentPosition: (modelComponentPositionId, widgetCode, parentModelComponentPositionId = null) =>
        describeModelComponentPositionThunk(modelComponentPositionId, widgetCode, parentModelComponentPositionId),
      changeModelComponentPosition: selectedModelComponentPosition => setModelComponentPositionChangeThunk(selectedModelComponentPosition),
      resetModelComponent: modelComponentPosition => resetModelComponentThunk(modelComponentPosition)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelComponentPosition);
