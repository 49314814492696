import Cookies from 'js-cookie';
import {
  UNIT_ADD_ERROR,
  SET_UNIT_ADD_DROPDOWN_LIST,
  UNIT_ADD_LOADING,
  SET_UNIT_ADD_INFO,
  UNIT_ADD_CHANGE,
  SET_UNIT_ADD_CLEANUP
} from '../constants/index';
import { apiAction, logEntry } from '../utils/index';
import { UNIT_MANAGEMENT, ADD_UNIT } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;
//Iot Core devices
export const getSpareIotDevicesThunk = widgetCode => dispatch => {
  dispatch(getSpareIotDevices(widgetCode));
};

export const getSpareIotDevices = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/iotthing/listing`,
    data: {
      excludeAllocated: true
    },
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setIotThingListThunk(retData),
    onFailure: error => setUnitErrorThunk(error, false),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setIotThingListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let devices = (retData && retData.data) || [];
  dispatch(setDropDownList(messageCode, { deviceList: devices }));
};
// Models
export const getModelsThunk = widgetCode => dispatch => {
  dispatch(getModels(widgetCode));
};

export const getModels = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/models`,
    data: {},
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setModelListThunk(retData),
    onFailure: error => setUnitErrorThunk(error, false),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setModelListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let models = (retData && retData.models) || [];
  dispatch(setDropDownList(messageCode, { models: models }));
};

// Model Versions
export const getModelVersionsThunk = (modelid, organisationId, widgetCode) => dispatch => {
  dispatch(getModelVersions(modelid, organisationId, widgetCode));
};

export const getModelVersions = (modelid, organisationId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion`,
    data: {
      ModelId: modelid,
      OrganisationId: organisationId ? organisationId : undefined
    },
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setModelVersionListThunk(retData),
    onFailure: error => setUnitErrorThunk(error, false),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setModelVersionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelVersions = (retData && retData.modelVersions) || [];
  dispatch(setDropDownList(messageCode, { modelVersions: modelVersions }));
};

// Region
export const getOrganisationRegionThunk = (widgetCode, orgId) => dispatch => {
  dispatch(getOrganisationRegion(widgetCode, orgId));
};

export const getOrganisationRegion = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/organisation/regions`,
    method: 'GET',
    data: {
      OrgId: orgId
    },
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setRegionListThunk(retData),
    onFailure: err => setUnitErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setRegionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData && retData.retData.data) || [];
  dispatch(setDropDownList(messageCode, { regionList: data }));
};

// Users

export const getUnitManagerAndEngineersThunk = (widgetCode, orgId) => dispatch => {
  dispatch(getUnitManagerAndEngineers(widgetCode, orgId));
};

export const getUnitManagerAndEngineers = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/management/user`,
    data: {
      Limit: 1000,
      Filter: null,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setUnitManagerAndEngineersListThunk(retData),
    onFailure: err => setUnitErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setUnitManagerAndEngineersListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let users = (retData && retData.user && retData.user.users) || [];
  dispatch(setDropDownList(messageCode, { userList: users }));
};

export const getApprovedComponentThunk = widgetCode => dispatch => {
  dispatch(getApprovedComponent(widgetCode));
};

export const getApprovedComponent = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/approvedComponents`,
    data: {},
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setAllApprovedComponentListThunk(retData),
    onFailure: err => setUnitErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllApprovedComponentListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let approvedComponents = (retData && retData.approvedComponents) || [];
  dispatch(setDropDownList(messageCode, { approvedComponents: approvedComponents }));
};

// Component Parts
export const getComponentPartThunk = (orgId, widgetCode) => dispatch => {
  dispatch(getComponentPart(orgId, widgetCode));
};

export const getComponentPart = (orgId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/componentParts`,
    data: {
      OrganisationId: orgId,
      SpareComponent: true
    },
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setAllComponentPartListThunk(retData),
    onFailure: err => setUnitErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllComponentPartListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let componentParts = (retData && retData.retData.componentPart) || [];
  dispatch(setDropDownList(messageCode, { componentParts: componentParts }));
};

// Model Component Positions

export const getModelComponentPositionsThunk = (modelId, widgetCode) => dispatch => {
  if (modelId) {
    dispatch(getModelComponentPositions(modelId, widgetCode));
  }
};

export const getModelComponentPositions = (modelId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/model/componentposition`,
    data: {
      ModelId: modelId,
      OnlyVirtual: false
    },
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setAllModelComponentPositionsListThunk(retData),
    onFailure: err => setUnitErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllModelComponentPositionsListThunk = ({ retData }) => dispatch => {
  let messageCode = retData && retData.message;
  let componentPositionDetails = (retData && retData.componentPositionDetails) || [];
  dispatch(setDropDownList(messageCode, { unitComponentPositions: componentPositionDetails }));
};

export const setDropDownList = (messageCode, data) => ({
  type: SET_UNIT_ADD_DROPDOWN_LIST,
  payload: {
    data: data,
    result: {
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setUnitsLoadingStatus = status => ({
  type: UNIT_ADD_LOADING,
  payload: { status }
});

export const setUnitErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  console.log(messageCode);
  dispatch(setUnitError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitError(messageCode, false));
  }, 5000);
};

export const setUnitError = (messageCode, status) => {
  return {
    type: UNIT_ADD_ERROR,
    payload: {
      unit: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setUnitChangeThunk = retData => dispatch => {
  dispatch(setUnitChange(retData));
};

export const setUnitChange = retData => ({
  type: UNIT_ADD_CHANGE,
  payload: retData
});

//Unit save
//=============

export const postUnitSaveThunk = (unit, widgetCode) => dispatch => {
  let url = '/management/unit/save';
  let log = logEntry(UNIT_MANAGEMENT, ADD_UNIT, unit);
  unit.log = log;
  dispatch(saveUnit(unit, url, widgetCode));
};

export const saveUnit = (unit, url, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setUnitSaveThunk(retData),
    onFailure: error => setUnitErrorThunk(error),
    accessToken: Cookies.get(`access-${envName}`),
    data: unit,
    widgetcode: widgetCode
  });

export const setUnitSaveThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let unit = (retData && retData.unit && retData.unit.result) || '{}';
  dispatch(setUnitAdd(messageCode, true, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitAdd('', false, true));
  }, 2500);
};

export const setUnitAdd = (messageCode, showBanner, status) => ({
  type: SET_UNIT_ADD_INFO,
  payload: {
    data: {
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: showBanner,
      isOpSuccessful: status
    }
  }
});

export const setUnitCleanUpThunk = retData => dispatch => {
  dispatch(setUnitCleanUp(retData));
};

export const setUnitCleanUp = retData => ({
  type: SET_UNIT_ADD_CLEANUP,
  payload: retData
});
