import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CalibrationEquation from './CalibrationEquation';
import { getCalibrationEquation, getUserProfileWidget } from '../../selectors/index';
import {
  saveCalibrationEquationThunk,
  describeCalibrationEquationThunk,
  setCalibrationEquationChangeThunk,
  setCalibrationEquationCleanUpThunk,
  setCalibrationEquationConstantThunk,
  setCalibrationEquationConstantListThunk,
  showAddEditSreenThunk,
  setCalibrationEquationCustomErrorThunk
} from '../../actions/calibrationEquation';

const mapStateToProps = state => {
  return {
    calibrationEquationManager: getCalibrationEquation(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveCalibrationEquation: (calibrationEquation, widgetCode) => saveCalibrationEquationThunk(calibrationEquation, widgetCode),
      describeCalibrationEquation: calibrationEquationId =>
        describeCalibrationEquationThunk(calibrationEquationId, 'CALIBRATIONEQUATIONEDIT'),
      changeCalibrationEquation: selectedCalibrationEquation => setCalibrationEquationChangeThunk(selectedCalibrationEquation),
      cleanUpCalibrationEquation: () => setCalibrationEquationCleanUpThunk(),
      setCalibrationEquationCustomError: (messageCode, status) => setCalibrationEquationCustomErrorThunk(messageCode, status),
      changeCalibrationEquationConstant: selectedCalibrationEquationConstant =>
        setCalibrationEquationConstantThunk(selectedCalibrationEquationConstant),
      setCalibrationEquationConstantList: calibrationConstantList => setCalibrationEquationConstantListThunk(calibrationConstantList),

      showAddEditScreen: status => showAddEditSreenThunk(status)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CalibrationEquation);
