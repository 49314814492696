import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelVersionList from './ModelVersionList';
import { getModelVersionManager, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import { getModelVersionsThunk, setCurrentPage, setPageFilter } from '../../actions/modelVersion';

const mapStateToProps = state => {
  return {
    modelVersionManager: getModelVersionManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getModelVersions: (modelId, organisationId, offset, limit, filter) =>
        getModelVersionsThunk(modelId, offset, limit, filter, 'MODELVERSIONLIST', organisationId),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelVersionList);
