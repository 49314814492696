import { initialModelComponentTypeState } from './initialState';

import {
  MODELCOMPONENTTYPE_ERROR,
  GET_ALL_MODELCOMPONENTTYPE_DETAILS,
  MODELCOMPONENTTYPE_IS_LOADING,
  SET_MODELCOMPONENTTYPE_CURRENT_PAGE,
  SET_MODELCOMPONENTTYPE_PAGE_FILTER,
  SET_MODELCOMPONENTTYPE_CHANGE,
  SET_MODELCOMPONENTTYPE,
  UPDATE_MODELCOMPONENTTYPE_SAVE_STATUS,
  MODELCOMPONENTTYPE_CLEANUP,
  SET_MODELCOMPONENTTYPE_FILTERED_RESULTS
} from '../constants/index';

const modelComponentTypeReducer = (state = initialModelComponentTypeState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_MODELCOMPONENTTYPE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        modelComponentType: {
          ...state.modelComponentType,
          ...data
        }
      };
    }

    case SET_MODELCOMPONENTTYPE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        modelComponentType: {
          ...state.modelComponentType,
          currentPage
        }
      };
    }

    case SET_MODELCOMPONENTTYPE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        modelComponentType: {
          ...state.modelComponentType,
          filter
        }
      };
    }

    case UPDATE_MODELCOMPONENTTYPE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelComponentType: {
          ...state.modelComponentType,
          ...data
        }
      };
    }

    case MODELCOMPONENTTYPE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelComponentType: {
          ...state.modelComponentType,
          isLoading: isLoading
        }
      };
    }

    case MODELCOMPONENTTYPE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelComponentType: {
          ...state.modelComponentType,
          ...data
        }
      };
    }

    case SET_MODELCOMPONENTTYPE: {
      let { selectedModelComponentType, isOpSuccessful, showBanner } = payload;

      return {
        ...state,
        modelComponentType: {
          ...state.modelComponentType,
          selectedModelComponentType: {
            ...selectedModelComponentType
          },
          isOpSuccessful: isOpSuccessful,
          showBanner: showBanner
        }
      };
    }

    case SET_MODELCOMPONENTTYPE_CHANGE: {
      let { selectedModelComponentType, isOpSuccessful } = payload;

      return {
        ...state,
        modelComponentType: {
          ...state.modelComponentType,
          selectedModelComponentType: {
            ...state.modelComponentType.selectedModelComponentType,
            ...selectedModelComponentType
          },
          isOpSuccessful: isOpSuccessful || false
        }
      };
    }

    case SET_MODELCOMPONENTTYPE_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        modelComponentType: {
          ...state.modelComponentType,
          ...filteredResults
        }
      };
    }

    case MODELCOMPONENTTYPE_CLEANUP: {
      let { modelComponentType } = payload;
      return {
        ...state,
        modelComponentType: {
          ...state.modelComponentType,
          ...modelComponentType
        }
      };
    }

    default:
      return state;
  }
};

export default modelComponentTypeReducer;
