import React from 'react';
import { useSelector } from 'react-redux';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Maintenance = () => {
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  return (
    <div>
      {IsWidgetAccessible(userProfileWidgets, 'TELEMETRY_TAB') ? (
        <Redirect to="/maintenance/telemetry" />
      ) : IsWidgetAccessible(userProfileWidgets, 'PARTS_TAB') ? (
        <Redirect to="/maintenance/parts" />
      ) : IsWidgetAccessible(userProfileWidgets, 'SERVICE_TAB') ? (
        <Redirect to="/maintenance/service" />
      ) : IsWidgetAccessible(userProfileWidgets, 'TAGOUT_TAB') ? (
        <Redirect to="/maintenance/tagout-history" />
      ) : (
        ''
      )}
      <span>
        <FormattedMessage
          id="maintenance.tagout.NoAccess"
          defaultMessage="You do not have access to maintainence. Please contact your administrator."
        />
      </span>
    </div>
  );
};

export default Maintenance;
