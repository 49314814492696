import React, { useEffect, useState } from 'react';
import s from './OrganisationHierarchy.module.scss';
import TreeView from '../WSAControls/TreeView/TreeView';
import OrganisationHierarchyContentTemplate from './OrganisationHierarchyContentTemplate';
import { useDispatch } from 'react-redux';
import { DOMAIN_NAME, IS_LOCALHOST } from '../../constants/index';
import { postUserThunk } from '../../actions/userManager';
import { setAllAlarmList } from '../../actions/devices';
import { getSelectedUserProfileWidgetsThunk } from '../../actions/UserProfileWidgets';
import { redirectTo } from '../../actions/sidebar';
import Cookies from 'js-cookie';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import help from '../../assets/help-grey.svg';
import { FormattedMessage } from 'react-intl';

const OrganisationHierarchy = ({ intl }) => {
  const envNameShort = process.env.REACT_APP_ENV_NAME_SHORT;
  const organisations_data =
    (localStorage.getItem(`organisations-${envNameShort}`) && JSON.parse(localStorage.getItem(`organisations-${envNameShort}`))) || [];

  const organisations_logos =
    (localStorage.getItem(`organisationslogos-${envNameShort}`) &&
      JSON.parse(localStorage.getItem(`organisationslogos-${envNameShort}`))) ||
    [];

  let [organisations, setOrganisations] = useState(organisations_data);
  let [organisationlogos, setOrganisationlogos] = useState(organisations_logos);
  let [searchFilter, setSearchFilter] = useState('');

  useEffect(() => {
    setOrganisations(organisations_data);
    setOrganisationlogos(organisations_logos);
  }, []);

  const findRootParent = (organisations, organisationId) => {
    // Find the initial organisation
    let currentOrganisation = organisations.find(org => org.OrganisationId === organisationId);

    // Loop to trace back to the root parent
    while (currentOrganisation && currentOrganisation.ParentOrganisationId !== currentOrganisation.OrganisationId) {
      const parentOrg = organisations.find(org => org.OrganisationId === currentOrganisation.ParentOrganisationId);

      // If no parent is found, current organisation is the root
      if (!parentOrg) {
        break;
      }

      // Update the current organisation to its parent
      currentOrganisation = parentOrg;
    }

    return currentOrganisation;
  };

  const getOrgHierarchyChildList = (parentId, orgList) => {
    let returnList = [];
    if (orgList.length > 0) {
      let filteredList = orgList.filter(x => x.ParentOrganisationId === parentId);
      filteredList.forEach(element => {
        let orgLogo = organisationlogos.find(x => x.OrganisationId === element.OrganisationId)?.OrganisationLogo;
        if (orgLogo) orgLogo = process.env.REACT_APP_FILEUPLOAD_URL + '/' + orgLogo;
        let addOrgObj = { key: element.OrganisationId, label: element.Name, compLogo: orgLogo };
        let childrenList = orgList.filter(x => x.ParentOrganisationId === element.OrganisationId);
        if (childrenList.length > 0) {
          addOrgObj.children = getOrgHierarchyChildList(element.OrganisationId, orgList);
        }
        returnList.push(addOrgObj);
      });
    }

    return returnList;
  };

  const getOrgHierarchyList = () => {
    const uniqueOrgIds = [...new Set(organisations.map(item => item.ParentOrganisationId))];
    let returnList = [];

    for (let index = 0; index < uniqueOrgIds.length; index++) {
      const id = uniqueOrgIds[index];

      if (organisations.filter(x => x.OrganisationId === id && x.ParentOrganisationId === id).length > 0) {
        let topParent = organisations.find(x => x.OrganisationId === id);
        let subOrgList = organisations.filter(x => x.OrganisationId !== id);
        let childList = getOrgHierarchyChildList(id, subOrgList);
        let orgLogo = organisationlogos.find(x => x.OrganisationId === id)?.OrganisationLogo;
        if (orgLogo) orgLogo = process.env.REACT_APP_FILEUPLOAD_URL + '/' + orgLogo;

        returnList.push({ key: id, label: topParent.Name, children: childList, compLogo: orgLogo });
        break;
      } else {
        let findCurrentParent = findRootParent(organisations, id);
        if (!findCurrentParent) {
          let childList = getOrgHierarchyChildList(id, organisations);
          returnList.push(...childList);
        }
      }
    }

    return returnList;
  };

  let treeData = getOrgHierarchyList();

  const dispatch = useDispatch();

  let onOrganisationChange = org => {
    let userName = Cookies.get(`username-${envNameShort}`) || '';
    Cookies.set(`selectedorganisationid-${envNameShort}`, org.key, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    dispatch(getSelectedUserProfileWidgetsThunk(null, userName, 'GENERALACCESS'));
    const userId = Cookies.get(`userid-${envNameShort}`) || 0;
    dispatch(setAllAlarmList('', []));
    dispatch(postUserThunk({ CurrentOrganisationId: org.key, UserId: userId }, 'GENERALACCESS', true));
    dispatch(redirectTo('/organisation-hierarchy'));
  };

  const searchHandler = value => {
    setSearchFilter(value);
    let filter_data = organisations_data.filter(item => item.Name.toLowerCase().includes(value.toLowerCase()));
    setOrganisations(filter_data);
  };

  return (
    <OrganisationHierarchyContentTemplate>
      <div className={s.organisationHierarchy}>
        <div className={s.contentWrapper}>
          <Form>
            <Row>
              <Col>
                <SearchBar
                  searchHandler={searchHandler}
                  clearSearchInVisible={true}
                  initialText={searchFilter}
                  placeHolderTextId="common.search"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={s.info}>
                  <img src={help} alt="icon" className={s.icon}></img>
                  <FormattedMessage
                    id="organisationHierarchy.messageSelectOrganisation"
                    defaultMessage="Use select button to switch organisation"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <TreeView treeData={treeData} onNodeSelect={onOrganisationChange} />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </OrganisationHierarchyContentTemplate>
  );
};

OrganisationHierarchy.defaultProps = {};

OrganisationHierarchy.propTypes = {};

export default OrganisationHierarchy;
