import React, { useState, useEffect } from 'react';
import s from './ModelVersionUnits.module.scss';
import PropTypes from 'prop-types';
import { initialModelVersionState } from '../../reducers/initialState';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import SearchBar from '../SearchBar/SearchBar';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';

const ModelVersionUnits = ({ modelVersionUnitManager, saveModelVersionUnits, setModelVersionUnitsChange, cancelHandler, intl }) => {
  const [isFilter, setIsFilter] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [localformErrors, setFormErrors] = useState({});
  let { ModelVersionId, ModelId } = useParams();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);
  };

  const filterData = () => {
    let filteredList = modelVersionUnitManager.UnallocatedUnitsList;
    let selectedUnits = modelVersionUnitManager.SelectedModelVersionUnits;
    if (isFilter) {
      filteredList = modelVersionUnitManager.UnallocatedUnitsList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    let formattedlist = filteredList.map(sl => {
      return {
        ...sl,
        SKEY: sl.UnitId,
        label: sl.Name,
        value: sl.UnitId,
        isChecked: selectedUnits && selectedUnits.filter(unit => unit.UnitId === sl.UnitId).length > 0
      };
    });

    return formattedlist;
  };

  //clean up
  useEffect(() => {
    return () => {
      setModelVersionUnitsChange({ SelectedModelVersionUnits: [] });
    };
  }, []);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = {};
    saveData.ModelVersionId = parseInt(ModelVersionId);
    saveData.ModelId = parseInt(ModelId);
    saveData.Units = modelVersionUnitManager.SelectedModelVersionUnits;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    saveModelVersionUnits(saveData);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !modelVersionUnitManager ||
      !modelVersionUnitManager.SelectedModelVersionUnits > 0 ||
      isRequired(modelVersionUnitManager.SelectedModelVersionUnits, 1)
    ) {
      formErrors.SelectedModelVersionUnits = intl.formatMessage({
        id: 'modelVersion.units.unitsRequire',
        defaultMessage: 'Please select units'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onCheckboxSelectionChange = (e, data) => {
    let selectedUnits = modelVersionUnitManager.SelectedModelVersionUnits;

    if (data.isChecked) {
      selectedUnits.push(data);
    } else {
      selectedUnits = selectedUnits.filter(selUnit => selUnit.UnitId !== data.UnitId);
    }
    setModelVersionUnitsChange({ SelectedModelVersionUnits: selectedUnits });
  };

  return (
    <div className={s.modelversionUnitAllocation}>
      <div className={s.borderBox}>
        <div id="modelversionUnits">
          <Row className={classNames(s.row, s.searchPadding)}>
            <Col sm={6}>
              <SearchBar
                searchHandler={searchHandler}
                initialText={searchText}
                clearSearchInVisible={true}
                placeHolderTextId="modelVersion.units.search"
              />
            </Col>
          </Row>
          <Row className={s.selectedlist}>
            <Col sm={12} className={s.selectedCols}>
              <Checkbox dataArray={filterData()} onSelectionChange={onCheckboxSelectionChange} />
            </Col>
          </Row>
        </div>
      </div>
      <Row className={s.border30}>
        <FormattedMessage
          id="model.componentpositions.unitShdowUpdatedWithLatestSettings"
          defaultMessage="Device shadow will be updated with latest model version setttings."
        />
      </Row>
      <Row className={s.unitButtons}>
        <Col lg={6} xs={12}>
          <Button
            variant="primary"
            className={classNames(s.margin5, s.btnSaveChanges)}
            onClick={submitForm}
            noValidate
            data-unittest="saveData"
          >
            <FormattedMessage id="model.componentpositions.save" defaultMessage="SAVE" />
          </Button>
          <Button
            variant="outline-secondary"
            className={classNames(s.btnCancel)}
            onClick={cancelHandler}
            noValidate
            data-unittest="saveCancel"
          >
            <FormattedMessage id="model.componentpositions.cancel" defaultMessage="CANCEL" />
          </Button>
        </Col>
        <Col lg={6}></Col>
      </Row>
    </div>
  );
};

ModelVersionUnits.defaultProps = {
  modelVersionUnitManager: {
    ...initialModelVersionState.modelVersionManager.selectedModelVersion.ModelVersionUnit
  }
};

ModelVersionUnits.propTypes = {
  setModelVersionUnitsChange: PropTypes.func.isRequired,
  saveModelVersionUnits: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionUnits);
