import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelVersionState } from '../../reducers/initialState';
import { useParams, Link } from 'react-router-dom';
import ModelVersionListTable from '../ModelVersionList/ModelVersionListTable';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { WidgetVisibility } from '../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { compareValues } from '../../utils/';
import Cookies from 'js-cookie';

const ModelVersionList = ({ getModelVersions, setPageFilter, modelVersionManager, intl, userProfileWidgets, currentTimezone }) => {
  let { ModelId } = useParams();
  let filter = modelVersionManager.filter || '';
  let offset = modelVersionManager.offset || 0;
  let limit = modelVersionManager.limit || 1000;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [searchText, setSearchText] = useState('');

  let initialLimit = 6;
  let initialRecords = modelVersionManager.modelVersions.slice(0, initialLimit);
  const localSettingLength = localSettings.length;
  const modelVersionLength = modelVersionManager.modelVersions.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(modelVersionManager.modelVersions);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = modelVersionManager.modelVersions.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const filterData = () => {
    let filteredList = modelVersionManager.modelVersions;
    if (isFilter) {
      filteredList = modelVersionManager.modelVersions.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getTableData = () => {
    let tableData = [];
    if (showAll) {
      tableData = filterData();
    } else {
      if (isFilter) {
        tableData = localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        tableData = initialRecords;
      }
    }
    return tableData.sort(compareValues('Name'));
  };

  useEffect(() => {
    if (ModelId) {
      getModelVersions(ModelId, organisationId, offset, limit, filter);
    }
  }, [getModelVersions, organisationId, ModelId, offset, limit, filter]);

  let showAllText = intl.formatMessage({ id: 'model.modelVersion.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'model.modelVersion.showLess', defaultMessage: 'SHOW LESS' });

  let messageId = (modelVersionManager && modelVersionManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isAddAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSIONADD');
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSIONUPDATE');
  const isDuplicateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSIONDUPLICATE');
  const isConfigurationAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSIONCONFIGURE');
  const isSequenceAllowed = IsWidgetAccessible(userProfileWidgets, 'SEQUENCEVERSIONLIST');

  return (
    <div className={s.modelVersion}>
      {modelVersionManager.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={modelVersionManager.showBanner}
        status={modelVersionManager.isOpSuccessful}
        successText={messageText}
      />
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              initialText={filter}
              placeHolderTextId="modelVersion.modelVersionName"
            />
          </Col>
          <Col sm={6} lg={8}>
            <Link to={`/admin-controls/model-configuration/${ModelId}/add`}>
              <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `MODELVERSIONADD`)}>
                + <FormattedMessage id="modelVersion.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Link>
          </Col>
        </Row>

        <div className="">
          <ModelVersionListTable
            VersionData={getTableData()}
            UpdateAllowed={isUpdateAllowed}
            DuplicateAllowed={isDuplicateAllowed}
            ConfigurationAllowed={isConfigurationAllowed}
            currentTimezone={currentTimezone}
            IsSequenceAllowed={isSequenceAllowed}
          />
        </div>
        {((!isFilter && modelVersionLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? modelVersionLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

ModelVersionList.defaultProps = {
  ...initialModelVersionState
};

ModelVersionList.propTypes = {
  getModelVersions: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(ModelVersionList);
