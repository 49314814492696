import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelFirmwareVersion from './ModelFirmwareVersion';
import { getModelFirmwareVersionManager, getUserProfileWidget, getModelManager, getCurrentTimezone } from '../../selectors/index';
import {
  postModelFirmwareVersionThunk,
  describeModelFirmwareVersionThunk,
  setModelFirmwareVersionChangeThunk,
  getComponentPositionsThunk,
  getModelUnitsFilteredDataThunk,
  setModelUnitsCurrentPage,
  setModelUnitsPageFilter
} from '../../actions/modelFirmwareVersion';
import { describeModelThunk } from '../../actions/model';

const mapStateToProps = state => {
  return {
    modelManager: getModelManager(state),
    modelFirmwareVersionManager: getModelFirmwareVersionManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveModelFirmwareVersion: (modelFirmwareVersion, widgetCode) => postModelFirmwareVersionThunk(modelFirmwareVersion, widgetCode),
      describeModelFirmwareVersion: (modelFirmwareVersionId, modelId, widgetCode) =>
        describeModelFirmwareVersionThunk(modelFirmwareVersionId, modelId, widgetCode),
      changeModelFirmwareVersion: selectedModelFirmwareVersion => setModelFirmwareVersionChangeThunk(selectedModelFirmwareVersion),
      describeModel: (modelId, widgetCode) => describeModelThunk(modelId, null, widgetCode),
      getModelUnitsFilteredData: (ModelId, ModelFirmwareVersionId, ModelComponentPositionId, offset, limit, filter, widgetCode) =>
        getModelUnitsFilteredDataThunk(ModelId, ModelFirmwareVersionId, ModelComponentPositionId, offset, limit, filter, widgetCode),
      getComponentPositions: (modelId, widgetCode) => getComponentPositionsThunk(modelId, widgetCode),
      setModelUnitsCurrentPage: currentPage => setModelUnitsCurrentPage(currentPage),
      setModelUnitsPageFilter: filter => setModelUnitsPageFilter(filter)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelFirmwareVersion);
