import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './UnitConfigurationPartDetails.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialComponentPartsState } from '../../reducers/initialState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import PartDetails from '../PartDetails/PartDetails';

const UnitConfigurationPartDetails = ({
  userProfileWidgets,
  intl,
  componentParts,
  describeComponentPart,
  changeComponentPart,
  selectedDevice
}) => {
  let { ComponentPartId } = useParams();

  useEffect(() => {
    if (ComponentPartId) {
      describeComponentPart(ComponentPartId);
    }
  }, [describeComponentPart, ComponentPartId]);

  useEffect(() => {
    return () => {
      changeComponentPart({
        selectedComponentPart: { ...initialComponentPartsState.componentParts.selectedComponentPart },
        isOpSuccessful: false
      });
    };
  }, []);

  return (
    <div className={s.unitConfiguration}>
      <ContentTemplate
        selectedPage="unitConfiguration"
        userProfileWidgets={userProfileWidgets}
        widgetCode={'UNIT_PARTS_VIEW'}
        tagoutRequired={false}
      >
        <div className={s.contentWrapper}>
          <div className={s.pageContent}>
            <div>
              {componentParts.isLoading && <LoadingSpinner />}
              <div className={s.contentWrapper}>
                <div className={s.componentPartHeader}>
                  <Link className={s.backLink} to={`/unit/unit-configuration/${selectedDevice.unitSerialNumber}`}>
                    &lt; &nbsp;
                    <FormattedMessage id="componentParts.back" defaultMessage="BACK" />
                  </Link>
                  <Row>
                    <Col md={6} xs={12}>
                      <h3>{componentParts.selectedComponentPart && componentParts.selectedComponentPart.ModelComponentPositionName}</h3>
                    </Col>
                  </Row>
                </div>
                <PartDetails componentParts={componentParts}></PartDetails>
              </div>
            </div>
          </div>
        </div>
      </ContentTemplate>
    </div>
  );
};

UnitConfigurationPartDetails.defaultProps = {
  componentParts: {
    ...initialComponentPartsState.componentParts
  }
};

UnitConfigurationPartDetails.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired,
  componentParts: PropTypes.object.isRequired,
  describeComponentPart: PropTypes.func.isRequired,
  selectedDevice: PropTypes.object.isRequired,
  changeComponentPart: PropTypes.func.isRequired
};

export default injectIntl(UnitConfigurationPartDetails);
