import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelVersion from './ModelVersion';
import { getModelVersionManager, getUserProfileWidget, getModelManager } from '../../selectors/index';
import {
  postModelVersionThunk,
  describeModelVersionThunk,
  setModelVersionChangeThunk,
  getSequenceVersionThunk
} from '../../actions/modelVersion';
import { describeModelThunk } from '../../actions/model';

const mapStateToProps = state => {
  return {
    modelManager: getModelManager(state),
    modelVersionManager: getModelVersionManager(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveModelVersion: (modelVersion, widgetCode) => postModelVersionThunk(modelVersion, widgetCode),
      describeModelVersion: (modelVersionId, isDuplicate, widgetCode) => describeModelVersionThunk(modelVersionId, isDuplicate, widgetCode),
      changeModelVersion: selectedModelVersion => setModelVersionChangeThunk(selectedModelVersion),
      describeModel: (modelId, widgetCode) => describeModelThunk(modelId, null, widgetCode),
      sequenceVersionListing: (modelVersionId, widgetCode) => getSequenceVersionThunk(modelVersionId, widgetCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelVersion);
