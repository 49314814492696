import React from 'react';
import PropTypes from 'prop-types';
import s from './Notifications.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import ascendingIcon from '../../../assets/arrow-up.png';
import descendingIcon from '../../../assets/arrow-down.png';
import success from '../../../assets/tick-green-filled.svg';
import error from '../../../assets/alert.svg';
import warning from '../../../assets/warning.svg';
import info from '../../../assets/info-blue.svg';

const NotificationsTable = ({ paginatedData, onSortOrder, asendingOrder, currentTimezone }) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th></th>
            <th>
              <FormattedMessage id="notifications.notificationType" defaultMessage="Notification Type" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="notifications.notificationDateTime" defaultMessage="Notification Date & Time" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="notifications.notification" defaultMessage="Notification" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            const iconSrc =
              n.AlertType === 'danger' ? error : n.AlertType === 'warning' ? warning : n.AlertType === 'success' ? success : info;
            const messageClass =
              n.AlertType === 'danger' ? s.danger : n.AlertType === 'warning' ? s.warning : n.AlertType === 'success' ? s.success : s.info;
            return (
              <tr key={n.NotificationQueueId} className={messageClass}>
                <td>
                  <img src={iconSrc} alt="icon" className={s.icon} />
                </td>
                <td>{n.Name || ''}</td>
                <td>
                  {n.DeliveryTime
                    ? moment
                        .unix(n.DeliveryTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                </td>
                <td>{n.Message || ''}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

NotificationsTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

NotificationsTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(NotificationsTable);
