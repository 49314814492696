import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import s from './OrganisationHierarchyContentTemplate.module.scss';

const OrganisationHierarchyContentTemplate = ({ children }) => (
  <Fragment>
    <div className={s.fullWidth}>
      <div className={s.fullWidthWrapper}>
        <h1 className={s.tabHeader}>
          {<FormattedMessage id="organisationHierarchy.organisationHierarchy" defaultMessage="Organisation Hierarchy" />}
        </h1>
      </div>
    </div>

    <div className={s.pageContent}>{children}</div>
  </Fragment>
);

OrganisationHierarchyContentTemplate.propTypes = {
  children: PropTypes.node.isRequired
};

export default OrganisationHierarchyContentTemplate;
