import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './OrganisationControl.module.scss';
import { injectIntl, FormattedMessage } from 'react-intl';
import OrganisationRegionList from '../OrganisationRegion/OrganisationRegionList';
import AdminControlsContentTemplate from '../../AdminControls/AdminControlsContentTemplate';
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getOrganisationManager } from '../../../selectors/index';
import { describeOrganisationThunk } from '../../../actions/organisationManager';

const OrganisationControl = ({ intl }) => {
  let { organisationId } = useParams();
  let dispatch = useDispatch();
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);
  useEffect(() => {
    dispatch(describeOrganisationThunk(organisationId, 'ORGANISATION_CONFIGURE'));
  }, [describeOrganisationThunk, dispatch, organisationId]);

  let organisationManager = useSelector(state => getOrganisationManager(state));

  let heading = intl.formatMessage({ id: 'common.configure', defaultMessage: 'Configure' });
  const orgnisationName = (organisationManager.selectedOrganisation ? organisationManager.selectedOrganisation.Name : '') || '';

  return (
    <div className={s.mainComponent}>
      <AdminControlsContentTemplate selectedPage="manageOrganisations" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.mainComponentHeader}>
            <Row>
              <Col xs={12} md={6}>
                <Link className={s.backLink} to={'/admin-controls/organisation-list'}>
                  &lt; &nbsp;
                  <FormattedMessage id="organisationControl.backToOrganisations" defaultMessage="BACK TO ORGANISATIONS" />
                </Link>
                <h3 data-unittest="headingLabel">
                  {heading} : {orgnisationName}
                </h3>
              </Col>
            </Row>
          </div>
          <div className={s.mainComponentContent}>
            <OrganisationRegionList userProfileWidgets={userProfileWidgets} />
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

OrganisationControl.defaultProps = {};

export default injectIntl(OrganisationControl);
