import React from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import MoreOption from '../WSAControls/MoreOption/MoreOption';

const ModelVersionListTable = ({
  VersionData,
  UpdateAllowed,
  DuplicateAllowed,
  ConfigurationAllowed,
  IsSequenceAllowed,
  currentTimezone
}) => {
  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="modelVersion.version" defaultMessage="Version" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelVersion.description" defaultMessage="Description" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelVersion.dateCreated" defaultMessage="Date created" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelVersion.dateModified" defaultMessage="Date modified" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="modelVersion.units" defaultMessage="Units" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {VersionData.map(n => {
            return (
              <tr key={n.ModelVersionId} data-unittest="modelVersionData">
                <td>{n.Name || ''}</td>
                <td className="d-none d-md-table-cell">{n.Description || ''}</td>
                <td className="d-none d-md-table-cell">
                  {n.CreatedDateTime
                    ? moment
                        .unix(n.CreatedDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY')
                    : '-'}
                </td>
                <td className="d-none d-md-table-cell">
                  {n.ModifiedDateTime
                    ? moment
                        .unix(n.ModifiedDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY')
                    : '-'}
                </td>
                <td className="d-none d-md-table-cell">{n.NumberOfUnits || 0}</td>
                <td>
                  <MoreOption
                    dataArray={[
                      ConfigurationAllowed
                        ? {
                            Url: `/admin-controls/model-configuration/${n.ModelId}/model-version-configuration/${n.ModelVersionId}`,
                            Name: <FormattedMessage id="modelVersion.configure" defaultMessage="Configure" />
                          }
                        : {},
                      UpdateAllowed
                        ? {
                            Url: `/admin-controls/model-configuration/${n.ModelId}/edit/${n.ModelVersionId}`,
                            Name: <FormattedMessage id="modelVersion.edit" defaultMessage="EDIT" />
                          }
                        : {},
                      DuplicateAllowed
                        ? {
                            Url: `/admin-controls/model-configuration/${n.ModelId}/edit/${n.ModelVersionId}/true`,
                            Name: <FormattedMessage id="modelVersion.duplicate" defaultMessage="Duplicate" />
                          }
                        : {},
                      IsSequenceAllowed
                        ? {
                            Url: `/admin-controls/model-configuration/${n.ModelId}/model-version-configuration/${n.ModelVersionId}/sequence-version`,
                            Name: <FormattedMessage id="modelVersion.sequenceVersion" defaultMessage="Sequence Version" />
                          }
                        : {}
                    ]}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ModelVersionListTable.defaultProps = {
  VersionData: []
};

ModelVersionListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(ModelVersionListTable);
