import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './Notifications.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import NotificationsTable from './NotificationsTable';
import Pagination from '../../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils/';
import HelpControlsContentTemplate from '../HelpControls/HelpControlsContentTemplate';
import { getNotifications, getCurrentTimezone } from '../../../selectors/index';
import moment from 'moment-timezone';
import DateTimePicker from '../../WSAControls/DateTimePicker/DateTimePicker';

import { getNotificationHistoryThunk, setCurrentPage } from '../../../actions/notifications';

const Notifications = ({ intl }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(state => getNotifications(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  const startTimestamp = moment()
    .tz(currentTimezone)
    .subtract(1, 'days')
    .unix();

  const endTimestamp = moment()
    .tz(currentTimezone)
    .unix();

  const [filterDateFrom, setFilterDateFrom] = useState(startTimestamp);
  const [filterDateTo, setFilterDateTo] = useState(endTimestamp);
  const [asendingOrder, setAsendingOrder] = useState(false);

  useEffect(() => {
    let filterObj = {
      filterDateFrom: filterDateFrom,
      filterDateTo: filterDateTo
    };
    dispatch(getNotificationHistoryThunk('NOTIFICATIONS_LIST', filterObj));
  }, [getNotificationHistoryThunk]);

  let messageId = (notifications && notifications.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const onDateFromChange = value => {
    if (value !== null && value > 0) {
      setFilterDateFrom(value);
    } else setFilterDateFrom(null);
    setCurrentPage(1);
  };

  const onDateToChange = value => {
    if (value !== null && value > 0) {
      setFilterDateTo(value);
    } else setFilterDateTo(null);
    setCurrentPage(1);
  };

  const onSearchClick = () => {
    let filterObj = {
      filterDateFrom: filterDateFrom,
      filterDateTo: filterDateTo
    };
    dispatch(getNotificationHistoryThunk('NOTIFICATIONS_LIST', filterObj));
  };

  const getFormattedate = date => {
    if (!date) return '';

    return moment.unix(date).tz(currentTimezone);
  };

  let notificationsObj = Object.values(notifications.notificationsList);

  if (asendingOrder) {
    notificationsObj = notificationsObj.sort(compareValues('DeliveryTime'));
  } else {
    notificationsObj = notificationsObj.sort(compareValues('DeliveryTime', 'desc'));
  }

  return (
    <div className={s.notificationsHistory}>
      {notifications.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={notifications.showBanner}
        status={notifications.isOpSuccessful}
        successText={messageText}
      />
      <HelpControlsContentTemplate selectedPage="notifications">
        <div className={s.contentWrapper}>
          <div className={s.notificationsHeader}>
            <div className={s.subHeading}>
              <FormattedMessage id="help.notifications" defaultMessage="Notifications" />
            </div>
          </div>
          <Row>
            <Col sm={12}>
              <span className={s.textHeading}>
                {' '}
                <FormattedMessage id="common.searchBy" defaultMessage="Search By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={2}>
              <DateTimePicker
                className={s.datetimeInput}
                onChange={m => onDateFromChange(m.unix())}
                timeFormat="HH:mm"
                dateFormat="DD-MMM-YYYY"
                value={getFormattedate(filterDateFrom)}
                defaultValue={getFormattedate(filterDateFrom)}
                closeOnSelect
                showClear={false}
              />
            </Col>
            <Col sm={6} lg={2}>
              <DateTimePicker
                className={s.datetimeInput}
                onChange={m => onDateToChange(m.unix())}
                timeFormat="HH:mm"
                dateFormat="DD-MMM-YYYY"
                value={getFormattedate(filterDateTo)}
                defaultValue={getFormattedate(filterDateTo)}
                closeOnSelect
                showClear={false}
              />
            </Col>
            <Col sm={6} lg={8}>
              <Button variant="primary" className={s.margin5} onClick={onSearchClick} noValidate disabled={notifications.isLoading}>
                <FormattedMessage id="common.search" defaultMessage="SEARCH" />
              </Button>
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={notificationsObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={notifications.currentPage}
            >
              <NotificationsTable currentTimezone={currentTimezone} onSortOrder={onSortOrder} asendingOrder={asendingOrder} />
            </Pagination>
          </div>
        </div>
      </HelpControlsContentTemplate>
    </div>
  );
};

export default injectIntl(Notifications);
