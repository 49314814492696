import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './NotificationTypeUser.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import equal from 'fast-deep-equal';
import Cookies from 'js-cookie';
import SearchBar from '../../SearchBar/SearchBar';

const Profiles = ({ profiles, onSelectedProfileClick }) => {
  const [selectedProfile, setSelectedProfile] = useState({});
  const [localProfiles, setLocalProfiles] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  const onProfileSelected = e => {
    setSelectedProfile(e);
    onSelectedProfileClick(e);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
      setSelectedProfile({});
      onSelectedProfileClick({});
    }

    let filteredProfiles = profiles.filter(x => x.Name.toLowerCase().includes(value.toLowerCase()));
    setLocalProfiles(filteredProfiles);
  };

  const getProfiles = () => {
    if (!isFilter) {
      return profiles;
    } else {
      return localProfiles;
    }
  };

  return (
    <div className={s.userProfiles}>
      <Row className={s.row}>
        <Col sm={6} xs={12}>
          <SearchBar searchHandler={searchHandler} clearSearchInVisible={true} placeHolderTextId="notifications.profileName" />
        </Col>
      </Row>
      <Row className={s.selectedProfilesNUsers}>
        <Col sm={12} className={s.selectedCols}>
          <Table variant className={s.table}>
            <tbody>
              {getProfiles().map((elem, index) => {
                let selectedCssClass = elem.ProfileId === selectedProfile.ProfileId ? s.selected : null;
                return (
                  <tr
                    data-unittest="uerprofile"
                    key={index}
                    onClick={() => {
                      onProfileSelected(elem);
                    }}
                  >
                    <td className={selectedCssClass}> {elem.Name}</td>
                    <td className={selectedCssClass}>
                      <span className="float-right"></span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

Profiles.defaultProps = {
  profiles: []
};

Profiles.propTypes = {
  onSelectedProfileClick: PropTypes.func
};

export default injectIntl(Profiles);
