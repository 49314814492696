import {
  MODELCOMPONENTPOSITION_ERROR,
  GET_ALL_MODELCOMPONENTPOSITION_DETAILS,
  MODELCOMPONENTPOSITION_IS_LOADING,
  SET_MODELCOMPONENTPOSITION_CURRENT_PAGE,
  SET_MODELCOMPONENTPOSITION_PAGE_FILTER,
  SET_MODELCOMPONENTPOSITION_CHANGE,
  SET_MODELCOMPONENTPOSITION,
  UPDATE_MODELCOMPONENTPOSITION_SAVE_STATUS,
  MODELCOMPONENTPOSITION_CLEANUP,
  SET_MODELCOMPONENTPOSITION_FILTERED_RESULTS
} from '../constants/index';

import { initialModelComponentPositionsState } from './initialState';

const modelComponentPositions = (state = initialModelComponentPositionsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_MODELCOMPONENTPOSITION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        modelComponentPositionManager: {
          ...state.modelComponentPositionManager,
          ...data
        }
      };
    }

    case SET_MODELCOMPONENTPOSITION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        modelComponentPositionManager: {
          ...state.modelComponentPositionManager,
          currentPage
        }
      };
    }

    case SET_MODELCOMPONENTPOSITION_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        modelComponentPositionManager: {
          ...state.modelComponentPositionManager,
          filter
        }
      };
    }

    case UPDATE_MODELCOMPONENTPOSITION_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelComponentPositionManager: {
          ...state.modelComponentPositionManager,
          ...data
        }
      };
    }

    case MODELCOMPONENTPOSITION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelComponentPositionManager: {
          ...state.modelComponentPositionManager,
          isLoading: isLoading
        }
      };
    }

    case MODELCOMPONENTPOSITION_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelComponentPositionManager: {
          ...state.modelComponentPositionManager,
          ...data
        }
      };
    }

    case SET_MODELCOMPONENTPOSITION: {
      let { selectedModelComponentPosition, modelComponentTypes, parentModelComponentPositions, isOpSuccessful, showBanner } = payload;

      return {
        ...state,
        modelComponentPositionManager: {
          ...state.modelComponentPositionManager,
          selectedModelComponentPosition: {
            ...state.modelComponentPositionManager.selectedModelComponentPosition,
            ...selectedModelComponentPosition
          },
          modelComponentTypes: modelComponentTypes,
          parentModelComponentPositions: parentModelComponentPositions,
          isOpSuccessful: isOpSuccessful,
          showBanner: showBanner
        }
      };
    }

    case SET_MODELCOMPONENTPOSITION_CHANGE: {
      let { selectedModelComponentPosition, isOpSuccessful } = payload;

      return {
        ...state,
        modelComponentPositionManager: {
          ...state.modelComponentPositionManager,
          selectedModelComponentPosition: {
            ...state.modelComponentPositionManager.selectedModelComponentPosition,
            ...selectedModelComponentPosition
          },
          isOpSuccessful: isOpSuccessful || false
        }
      };
    }

    case SET_MODELCOMPONENTPOSITION_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        modelComponentPositionManager: {
          ...state.modelComponentPositionManager,
          ...filteredResults
        }
      };
    }

    case MODELCOMPONENTPOSITION_CLEANUP: {
      let { modelComponentPositionManager } = payload;
      return {
        ...state,
        modelComponentPositionManager: {
          ...modelComponentPositionManager,
          selectedModelComponentPosition: {
            ...modelComponentPositionManager.selectedModelComponentPosition
          }
        }
      };
    }

    default:
      return state;
  }
};

export default modelComponentPositions;
