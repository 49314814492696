import { connect } from 'react-redux';
import throttle from 'lodash.throttle';

import Events from './Events';

import { updateEventInputs, fetchEventsThunk } from '../../actions/events';
import { getEventData, getSelectedDevice, getEventInputs, getCurrentTimezone, getUserProfileWidget } from '../../selectors/index';

const updateEventInputsThottled = throttle((dispatch, unitSerialNumber, field, value) => {
  if (typeof value === 'number' || ['type', 'code', 'descOrder'].includes(field))
    dispatch(updateEventInputs(unitSerialNumber, field, value));
}, 1000);

const mapStateToProps = state => {
  return {
    events: getEventData(state),
    selectedDevice: getSelectedDevice(state),
    eventInputs: getEventInputs(state),
    currentTimezone: getCurrentTimezone(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch => ({
  updateEventInputs: (unitSerialNumber, field, value) => updateEventInputsThottled(dispatch, unitSerialNumber, field, value),
  fetchEvents: (limit, view, from, to, code, type, descOrder) =>
    dispatch(fetchEventsThunk(limit, view, from, to, code.toString(), type, 'EVENTLOGSUMMARY', descOrder))
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
