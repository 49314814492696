import React from 'react';
import PropTypes from 'prop-types';
import s from './TaskList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import { TASK_STATUS_ACTIVE, TASK_STATUS_COMPLETE } from '../../constants/index';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getOverdueStatus } from '../../utils';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import uuidv4 from 'uuid/v4';

const TaskListTable = ({
  paginatedData,
  ViewAllowed,
  onSortOrder,
  asendingOrder,
  currentTimezone,
  onViewBtnClick,
  onTaskSelectionChange
}) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  const onLocalChange = (e, data) => {
    onTaskSelectionChange(data.value, data.isChecked);
  };

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="model.versionSelected" defaultMessage="Select" />
            </th>
            <th>
              <FormattedMessage id="tasksManagement.task" defaultMessage="Task" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="tasksManagement.unit" defaultMessage="Unit" />
            </th>
            <th className={`d-none d-md-table-cell ${s.sortHeading}`}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="tasksManagement.duedate" defaultMessage="Due Date" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="tasksManagement.serviceTask" defaultMessage="Service Task" />
            </th>
            <th>
              <FormattedMessage id="tasksManagement.status" defaultMessage="Status" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.TaskId} data-unittest="tasksData" className={n.Status === TASK_STATUS_ACTIVE ? s.active : s.complete}>
                <td>
                  {n.Status !== TASK_STATUS_COMPLETE && (
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsSelected',
                          target: { type: 'checkbox' },
                          label: '',
                          value: n.TaskId,
                          isChecked: n.IsSelected
                        }
                      ]}
                      onSelectionChange={onLocalChange}
                    />
                  )}
                </td>
                <td>{n.Name || ''}</td>
                <td className="d-none d-md-table-cell">{n.UnitName || ''}</td>
                <td>
                  {n.DueDateTime
                    ? moment
                        .unix(n.DueDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                  <span className={s.overDue}>{n.Status === TASK_STATUS_ACTIVE && getOverdueStatus(n.DueDateTime)}</span>
                </td>
                <td className="d-none d-md-table-cell">{n.IsServiceTask ? 'Yes' : 'No'}</td>
                <td className={s.status}>
                  <span className={`${s.dot} ${n.Status === TASK_STATUS_ACTIVE ? s.dotActive : s.dotComplete}`}></span> {n.Status || ''}
                </td>
                <td>
                  {ViewAllowed && (
                    <div className={s.btnView}>
                      <Button
                        variant="outline-secondary"
                        data-unittest="view"
                        className={s.viewButton}
                        onClick={() => {
                          onViewBtnClick(`/tasks-and-checklists/task-detail/${n.TaskId}`);
                        }}
                      >
                        <FormattedMessage id="tasksManagement.view" defaultMessage="VIEW" />
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

TaskListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

TaskListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired,
  onViewBtnClick: PropTypes.func.isRequired
};

export default injectIntl(TaskListTable);
