import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CommandPanel from './CommandPanel';
import { getCommandButtonStatus, getSelectedUnitTagStatus } from '../../selectors/index';
import { sendCommand } from '../../actions/devices';

const mapStateToProps = state => {
  return {
    commandButtonStatus: getCommandButtonStatus(state),
    isTaggedOut: getSelectedUnitTagStatus(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendCommand: (command, cType) => sendCommand(command, 'IOTCOMMANDSSUMMARY', true, cType)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommandPanel);
