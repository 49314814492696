import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './SchedulerList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { SCHEDULE_FREQUENCY } from '../../constants/index';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';

const SchedulerListTable = ({
  paginatedData,
  userProfileWidgets,
  currentTimezone,
  isSummary,
  onSortOrder,
  asendingOrder,
  endSchedulehandler
}) => {
  const isEndAllowed = IsWidgetAccessible(userProfileWidgets, 'SCHEDULE_END');
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  var d = new Date();
  const currentDatetime = moment(d).unix();

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="schedule.command" defaultMessage="Command" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="schedule.startDateTime" defaultMessage="Start sending command at" />{' '}
                {!isSummary && <img className={s.sortIcon} src={sortIcon} alt="icon" />}
              </span>
            </th>
            <th>
              <FormattedMessage id="schedule.endDate" defaultMessage="End Date" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="schedule.day" defaultMessage="Day" />
            </th>
            <th>
              <FormattedMessage id="schedule.scheduleFrequency" defaultMessage="Command Frequency" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="schedule.by" defaultMessage="By" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <Fragment key={n.CommandSchedulerId}>
                <tr
                  key={n.CommandSchedulerId}
                  data-unittest="scheduleData"
                  className={n.IsEnded || moment.unix(n.EndDateTime).isBefore(moment.unix(currentDatetime)) ? s.ended : ''}
                >
                  <td>{n.CommandName || ''}</td>
                  <td>
                    {n.StartDateTime
                      ? moment
                          .unix(n.StartDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm z')
                      : '-'}
                  </td>
                  <td>
                    {n.EndDateTime
                      ? moment
                          .unix(n.EndDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm z')
                      : '-'}
                  </td>

                  <td className="d-none d-md-table-cell">
                    {n.ScheduleFrequency === 0 && n.StartDateTime
                      ? moment
                          .unix(n.StartDateTime)
                          .tz(currentTimezone)
                          .format('dddd')
                      : 'Daily'}
                  </td>
                  <td>{SCHEDULE_FREQUENCY[n.ScheduleFrequency].name}</td>
                  <td>{n.FullName}</td>
                  <td>
                    {isEndAllowed && !isSummary && !n.IsEnded && moment.unix(n.EndDateTime).isAfter(moment.unix(currentDatetime)) ? (
                      <Button
                        variant="outline-secondary"
                        className={s.changeButton}
                        onClick={() => endSchedulehandler(n.CommandSchedulerId)}
                      >
                        <FormattedMessage id="schedule.end" defaultMessage="End" />
                      </Button>
                    ) : null}
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

SchedulerListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1,
  isSummary: false
};

SchedulerListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired,
  isSummary: PropTypes.bool.isRequired,
  endSchedulehandler: PropTypes.func,
  userProfileWidgets: PropTypes.object.isRequired,
  onSortOrder: PropTypes.func,
  asendingOrder: PropTypes.bool
};

export default injectIntl(SchedulerListTable);
