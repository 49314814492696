import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './UnitList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialUnitManagerState } from '../../reducers/initialState';
import UnitListTable from './UnitListTable';
import Pagination from '../Pagination/Pagination';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, REFRESH_TIME_OTHER, ALARM_STATUS_NEW, ALARM_STATUS_PENDING, ALARM_STATUS_RESOLVED } from '../../constants';
import { WidgetVisibility } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import Button from 'react-bootstrap/Button';
import alarm from '../../assets/alarm.svg';
import warning from '../../assets/warning-large.svg';
import pendingAlarm from '../../assets/alarm-outline.svg';
import Cookies from 'js-cookie';
import moment from 'moment';
import uuid from 'uuid';

const REGION = 'REGION';
const MODEL = 'MODEL';

const SEARCH_FILTER = 'SEARCH_FILTER';

const UnitList = ({
  getUnitsFilter,
  getUnits,
  getDevicesStatus,
  getDeviceAlarms,
  setCurrentPage,
  setPageFilter,
  unitManager,
  unitAdd,
  setFilterData,
  devicesStatus,
  deviceAlarms,
  flowSequences,
  intl,
  userProfileWidgets,
  setSelectedUnit
}) => {
  let limit = unitManager.limit;
  const history = useHistory();

  let filter = unitManager.filter || '';
  let filterByRegion = unitManager.filterRegion || null;
  let filterByModel = unitManager.filterModel || null;

  let statusFetchInterval = REFRESH_TIME_OTHER;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterRegion, setFilterRegion] = useState('0');
  const [filterModel, setFilterModel] = useState('0');
  const [filterStatus, setFilterStatus] = useState('0');
  const [sortByData, setSortByData] = useState('Name');
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const [time, setTime] = React.useState(0);
  const [interval, setInterval] = React.useState(100);

  useEffect(() => {
    getUnitsFilter();
  }, [getUnitsFilter]);

  useEffect(() => {
    let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
    let profId = Cookies.get(`selectedprofileid-${envName}`) || 0;
    getUnits(limit, filter, orgId, profId);
  }, [getUnits, limit, filter, filterByRegion, filterByModel]);

  let unitSerialNumbers = unitManager.units.map(unit => unit.UnitSerialNumber);
  const toDate = moment().valueOf();
  const fromDate = moment()
    .subtract(1, 'day')
    .valueOf();

  if (time > 50 && interval !== statusFetchInterval) {
    setInterval(statusFetchInterval);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (unitSerialNumbers.length > 0) {
        getDevicesStatus(unitSerialNumbers, fromDate, toDate);
        getDeviceAlarms(unitSerialNumbers, ALARM_STATUS_RESOLVED);
      }

      if (unitSerialNumbers.length > 0 && interval !== statusFetchInterval) {
        setInterval(statusFetchInterval);
      }

      setTime(time + 1);
    }, interval);

    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  const regionOptions = () => {
    let regionData = [];
    regionData.push({
      label: <FormattedMessage id="unitListing.allRegions" defaultMessage="All regions" />,
      value: '0'
    });

    unitManager.filterData.unitRegions.forEach(element => {
      regionData.push({
        label: element.Region,
        value: element.Region
      });
    });
    return regionData;
  };

  const modelOptions = () => {
    let modelData = [];
    modelData.push({
      label: <FormattedMessage id="unitListing.allModels" defaultMessage="All models" />,
      value: '0'
    });

    unitManager.filterData.models.forEach(element => {
      modelData.push({
        label: element.Name,
        value: element.ModelId
      });
    });
    return modelData;
  };

  const statusTypeOptions = () => {
    let statusTypeData = [];

    statusTypeData.push({
      label: <FormattedMessage id="unitListing.anyStatus" defaultMessage="Any status" />,
      value: '0'
    });

    flowSequences.forEach(element => {
      statusTypeData.push({
        label: element.Name,
        value: element.SequenceCode
      });
    });
    return statusTypeData;
  };

  const sortByOptions = () => {
    let unitData = [];

    unitData.push({
      label: <FormattedMessage id="unitListing.sortByName" defaultMessage="Sort by: Name" />,
      value: 'Name'
    });
    unitData.push({
      label: <FormattedMessage id="unitListing.sortBySerialNumber" defaultMessage="Sort by: Serial Number" />,
      value: 'UnitSerialNumber'
    });

    unitData.push({
      label: <FormattedMessage id="unitListing.sortByLocation" defaultMessage="Sort by: Location" />,
      value: 'Location'
    });

    unitData.push({
      label: <FormattedMessage id="unitListing.sortByStatus" defaultMessage="Sort by: Status" />,
      value: 'Status'
    });

    return unitData;
  };

  const onRegionChange = e => {
    setFilterRegion(e.value);
    filteredResults(REGION, e.value);
  };

  const onModelChange = e => {
    setFilterModel(e.value);
    filteredResults(MODEL, e.value);
  };

  const onStatusTypeChange = e => {
    setFilterStatus(e.value);
  };

  const onSortByChange = e => {
    setSortByData(e.value);
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const getUnitsFiltered = () => {
    let filteredData = unitManager.filteredResults.map(unit => {
      let deviceEventData =
        devicesStatus && devicesStatus.events && devicesStatus.events.filter(event => event.unitSerialNumber === unit.UnitSerialNumber);
      const deviceEvent =
        deviceEventData &&
        deviceEventData.length > 0 &&
        deviceEventData[0].events &&
        deviceEventData[0].events.filter(item => item.type === 0);
      const flowSequence =
        deviceEvent &&
        deviceEvent[0] &&
        deviceEvent[0].code &&
        flowSequences &&
        flowSequences.filter(x => x.SequenceCode === parseInt(deviceEvent[0].code));
      const currentSequence = flowSequence && flowSequence.length > 0 && flowSequence[0].Name;
      const currentSequenceCode = flowSequence && flowSequence.length > 0 && flowSequence[0].SequenceCode;
      return {
        ...unit,
        Status: currentSequence,
        SequenceCode: currentSequenceCode,
        Location: `${unit.InstallationCity}, ${unit.InstallationCountry}`
      };
    });
    return Object.values(filteredData);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = unitManager.units;

    let apifilterObj = {
      filter: null,
      filterRegion: null,
      filterModel: null
    };

    if (filterType === REGION) {
      if (value !== '0') {
        apifilterObj.filterRegion = value;
        filteredList = filteredList.filter(function(item) {
          return item.InstallationRegion === value;
        });
      }
    } else {
      if (filterRegion) {
        if (filterRegion !== '0') {
          apifilterObj.filterRegion = filterRegion;
          filteredList = filteredList.filter(function(item) {
            return item.InstallationRegion === filterRegion;
          });
        }
      }
    }

    if (filterType === MODEL) {
      if (value !== '0') {
        apifilterObj.filterModel = value;
        filteredList = filteredList.filter(function(item) {
          return item.ModelId === value;
        });
      }
    } else {
      if (filterModel) {
        if (filterModel !== '0') {
          apifilterObj.filterModel = filterModel;
          filteredList = filteredList.filter(function(item) {
            return item.ModelId === filterModel;
          });
        }
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (unitManager.unitListCount > unitManager.units.length) {
      setPageFilter(apifilterObj);
    } else {
      setFilterData({ filteredResults: { filteredResults: filteredList } });
    }
  };

  const onAddUnitClick = () => {
    history.push('/unit/add');
  };

  const onUploadUnitClick = () => {
    history.push('/unit/unit-upload');
  };

  let messageId = (unitManager && unitManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let messageAddId = (unitAdd && unitAdd.displayMessageCode) || 'none';
  const messageUnitAddText = intl.formatMessage({ id: messageAddId, defaultMessage: messageAddId });

  if (devicesStatus && devicesStatus.events) {
    //sorting events
    devicesStatus.events.forEach((event, index) => {
      event.events.sort((a, b) => {
        return a.timestamp < b.timestamp ? 1 : -1;
      });
    });
  }

  let warnings = 0;

  devicesStatus &&
    devicesStatus.events &&
    devicesStatus.events.forEach(event => {
      warnings += event.events && event.events.filter(x => x.type === 1).length;
    });

  const newAlarms =
    deviceAlarms &&
    deviceAlarms.filter(event => {
      return event.EventStatus === ALARM_STATUS_NEW;
    });

  const pendingAlarms =
    deviceAlarms &&
    deviceAlarms.filter(event => {
      return event.EventStatus === ALARM_STATUS_PENDING;
    });

  let unitsObj = getUnitsFiltered();

  //sorting
  if (sortByData) {
    unitsObj = unitsObj.sort(compareValues(sortByData));
  }

  if (filterStatus && filterStatus !== '0') {
    unitsObj = unitsObj.filter(unit => unit.SequenceCode === filterStatus);
  }

  return (
    <div className={s.unitList}>
      <Banner
        key={uuid()}
        failureText={messageUnitAddText}
        showBanner={unitAdd.showBanner}
        status={unitAdd.isOpSuccessful}
        successText={messageUnitAddText}
      />
      <div className={s.fullWidth}>
        <Row>
          <Col sm={6}>
            <div className={s.fullWidthWrapper}>
              <h1 className={s.tabHeader}>{<FormattedMessage id="unitListing.myUnits" defaultMessage="My units" />}</h1>
            </div>
          </Col>
          <Col>
            <div className={`${s.buttonSpace} float-right`}>
              <Button onClick={onAddUnitClick} className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `ADD_UNIT`)}>
                <FormattedMessage id="unitListing.addNewUnit" defaultMessage="ADD NEW UNIT" />
              </Button>
            </div>
            <div className="float-right">
              <Button onClick={onUploadUnitClick} className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `UPLOAD_UNIT`)}>
                <FormattedMessage id="unitListing.uploadNewUnit" defaultMessage="UPLOAD NEW UNIT" />
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <div className={s.pageContent}>
        <div>
          {unitManager.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={unitManager.showBanner}
            componentType={unitManager.isOpSuccessful}
            successText={messageText}
          />
          <div className={s.contentWrapper}>
            <Row>
              <Col sm={6} lg={3} className={s.searchBar}>
                <SearchBar
                  searchHandler={searchHandler}
                  clearSearchInVisible={false}
                  initialText={searchFilter}
                  placeHolderTextId="unitListing.unitName"
                />
              </Col>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpModelId"
                  dataArray={modelOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="unitListing.allModels" defaultMessage="All models" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onModelChange(e)}
                  selectedOption={modelOptions().filter(option => option.value === filterModel)}
                  disabled={false}
                />
              </Col>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpRegionId"
                  dataArray={regionOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="unitListing.allRegions" defaultMessage="All regions" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onRegionChange(e)}
                  selectedOption={regionOptions().filter(option => option.value === filterRegion)}
                  disabled={false}
                />
              </Col>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpStatusId"
                  dataArray={statusTypeOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="unitListing.anyStatus" defaultMessage="Any status" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onStatusTypeChange(e)}
                  selectedOption={statusTypeOptions().filter(option => option.value === filterStatus)}
                  disabled={false}
                />
              </Col>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpSortById"
                  dataArray={sortByOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="unitListing.sortByName" defaultMessage="Sort by: Name" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onSortByChange(e)}
                  selectedOption={sortByOptions().filter(option => option.value === sortByData)}
                  disabled={false}
                />
              </Col>
            </Row>
            <div className={s.fullWidth}>
              <AlertSummary newAlarms={newAlarms} pendingAlarms={pendingAlarms} warnings={warnings} />
            </div>

            <div className="">
              <Pagination
                data={unitsObj}
                pageSize={DEFAULT_PAGE_SIZE}
                onPageChange={onPageChangeHandler}
                startingPage={unitManager.currentPage}
              >
                <UnitListTable
                  currentPage={unitManager.currentPage}
                  devicesStatus={devicesStatus}
                  deviceAlarms={deviceAlarms}
                  intl={intl}
                  setSelectedUnit={setSelectedUnit}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AlertSummary = ({ newAlarms, pendingAlarms, warnings }) => {
  return (
    <div>
      <Row>
        <Col className={s.horizontalLine}>
          <hr />
        </Col>
      </Row>

      <Row className={s.alertsRow}>
        <Col lg={9} xs={12}>
          <div className="row">
            <div className="mr-3 col-xs-12">
              <img src={alarm} alt="New Alarm"></img>
              <span>
                <FormattedMessage id="unitListing.newAlarms" defaultMessage="New alarms" /> ({newAlarms && newAlarms.length})
              </span>
            </div>
            <div className="mr-3 col-xs-12">
              <img src={pendingAlarm} alt="Pending Alarm"></img>
              <span>
                <FormattedMessage id="unitListing.pendingAlarms" defaultMessage="Pending alarms" /> ({pendingAlarms && pendingAlarms.length}
                )
              </span>
            </div>
            <div className="col-xs-12">
              <img src={warning} alt="Warning"></img>
              <span>
                <FormattedMessage id="unitListing.warnings" defaultMessage="Warnings in the last 24h" /> ({warnings})
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className={s.horizontalLine}>
          <hr />
        </Col>
      </Row>
    </div>
  );
};

UnitList.defaultProps = {
  ...initialUnitManagerState
};

UnitList.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(UnitList);
