import {
  GET_ALL_MODEL_FIRMWARE_VERSION_DETAILS,
  UPDATE_MODEL_FIRMWARE_VERSION_SAVE_STATUS,
  MODEL_FIRMWARE_VERSION_IS_LOADING,
  MODEL_FIRMWARE_VERSION_ERROR,
  SET_MODEL_FIRMWARE_VERSION_CURRENT_PAGE,
  SET_MODEL_FIRMWARE_VERSION_PAGE_FILTER,
  SET_MODEL_FIRMWARE_VERSION_DETAILS,
  SET_MODEL_FIRMWARE_VERSION_CHANGE,
  SET_MODEL_FIRMWARE_VERSION_FILTERED_RESULTS,
  MODEL_FIRMWARE_VERSION_CLEANUP,
  SET_ALL_COMPONENT_POSITION_MODEL_FIRMWARE_VERSION,
  SET_MODEL_FIRMWARE_VERSION_UNITS_CURRENT_PAGE,
  SET_MODEL_FIRMWARE_VERSION_UNITS_PAGE_FILTER,
  SET_MODEL_FIRMWARE_VERSION_UNITS_FILTERED_RESULTS
} from '../constants/index';
import { initialModelFirmwareVersionState } from './initialState';

const modelFirmwareVersionReducer = (state = initialModelFirmwareVersionState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_MODEL_FIRMWARE_VERSION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          ...data
        }
      };
    }

    case SET_MODEL_FIRMWARE_VERSION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          currentPage
        }
      };
    }

    case SET_MODEL_FIRMWARE_VERSION_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          filter
        }
      };
    }

    case SET_MODEL_FIRMWARE_VERSION_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          ...filteredResults
        }
      };
    }

    case UPDATE_MODEL_FIRMWARE_VERSION_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          ...data
        }
      };
    }

    case MODEL_FIRMWARE_VERSION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          isLoading: isLoading
        }
      };
    }

    case MODEL_FIRMWARE_VERSION_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          ...data
        }
      };
    }

    case SET_MODEL_FIRMWARE_VERSION_DETAILS: {
      let { modelFirmwareVersion } = payload;
      let modelFirmwareVersionDetailsData = modelFirmwareVersion
        ? modelFirmwareVersion
        : state.modelFirmwareVersionManager.selectedModelFirmwareVersion;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          selectedModelFirmwareVersion: {
            ...state.modelFirmwareVersionManager.selectedModelFirmwareVersion,
            ...modelFirmwareVersionDetailsData
          }
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: true
      };
    }

    case SET_MODEL_FIRMWARE_VERSION_CHANGE: {
      let { selectedModelFirmwareVersion } = payload;

      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          selectedModelFirmwareVersion: selectedModelFirmwareVersion
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: true
      };
    }

    case MODEL_FIRMWARE_VERSION_CLEANUP: {
      const { data } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          ...data
        }
      };
    }

    case SET_ALL_COMPONENT_POSITION_MODEL_FIRMWARE_VERSION: {
      const { data } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          ...data
        }
      };
    }

    case SET_MODEL_FIRMWARE_VERSION_UNITS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          selectedModelFirmwareVersion: {
            ...state.modelFirmwareVersionManager.selectedModelFirmwareVersion,
            currentPage
          }
        }
      };
    }

    case SET_MODEL_FIRMWARE_VERSION_UNITS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          selectedModelFirmwareVersion: {
            ...state.modelFirmwareVersionManager.selectedModelFirmwareVersion,
            filter
          }
        }
      };
    }

    case SET_MODEL_FIRMWARE_VERSION_UNITS_FILTERED_RESULTS: {
      const { modelUnits, message } = payload;
      return {
        ...state,
        modelFirmwareVersionManager: {
          ...state.modelFirmwareVersionManager,
          selectedModelFirmwareVersion: {
            ...state.modelFirmwareVersionManager.selectedModelFirmwareVersion,
            ModelUnits: modelUnits,
            message: message
          }
        }
      };
    }

    default:
      return state;
  }
};

export default modelFirmwareVersionReducer;
