import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ChecklistTasksTemplates.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import SearchBar from '../SearchBar/SearchBar';
import { initialTaskTemplatesState, initialChecklistTemplateState } from '../../reducers/initialState';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import ChecklistTasksTemplatesTable from './ChecklistTasksTemplatesTable';
import Button from 'react-bootstrap/Button';
import { WidgetVisibility } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';

const SERVICE_TASK_FILTER = 'SERVICE_TASK_FILTER';
const SEARCH_TASK_FILTER = 'SEARCH_FILTER';

const SERVICE_CHECKLIST_FILTER = 'SERVICE_CHECKLIST_FILTER';
const SEARCH_CHECKLIST_FILTER = 'SEARCH_CHECKLIST_FILTER';

const ChecklistTasksTemplates = ({
  userProfileWidgets,
  intl,
  taskTemplateManager,
  getTaskTemplates,
  checklistTemplate,
  getChecklistTemplate
}) => {
  let initialLimit = 6;
  let initialTasktemplateRecords = taskTemplateManager.TaskTemplates.slice(0, initialLimit);
  let initialChecklistTemplateRecords = checklistTemplate.ChecklistTemplates.slice(0, initialLimit);

  const [checklistTemplateOpen, setChecklistTemplateOpen] = useState(false);
  const [taskTemplateOpen, setTaskTemplateOpen] = useState(false);

  const [localChecklistTemplates, setLocalChecklistTemplates] = useState([]);
  const [showAllChecklistTemplates, setShowAllChecklistTemplates] = useState(false);
  const [initialChecklistFilteredRecords, setInitialChecklistFilteredRecords] = useState([]);
  const [isChecklistFilter, setIsChecklistFilter] = useState(false);
  const [filterServiceChecklist, setFilterServiceChecklist] = useState('0');
  const [searchChecklistFilter, setSearchChecklistFilter] = useState('');

  const localChecklistTemplatesLength = localChecklistTemplates.length;
  const checklistTemplatesLength = checklistTemplate.ChecklistTemplates.length;

  const [localTaskTemplates, setLocalTaskTemplates] = useState([]);
  const [showAllTaskTemplates, setShowAllTaskTemplates] = useState(false);
  const [initialTaskFilteredRecords, setInitialTaskFilteredRecords] = useState([]);
  const [isTaskFilter, setIsTaskFilter] = useState(false);
  const [filterServiceTask, setFilterServiceTask] = useState('0');
  const [searchTaskFilter, setSearchTaskFilter] = useState('');

  const localTaskTemplatesLength = localTaskTemplates.length;
  const taskTemplatesLength = taskTemplateManager.TaskTemplates.length;

  useEffect(() => {
    getTaskTemplates();
  }, [getTaskTemplates]);

  useEffect(() => {
    getChecklistTemplate();
  }, [getChecklistTemplate]);

  const showAllChecklistTemplateHandler = () => {
    if (!isChecklistFilter) {
      setLocalChecklistTemplates(checklistTemplate.ChecklistTemplates);
    }
    setShowAllChecklistTemplates(!showAllChecklistTemplates);
  };

  const showAllTaskTemplateHandler = () => {
    if (!isTaskFilter) {
      setLocalTaskTemplates(taskTemplateManager.TaskTemplates);
    }
    setShowAllTaskTemplates(!showAllTaskTemplates);
  };

  const serviceTaskOptions = () => {
    let serviceData = [];

    serviceData.push({
      label: <FormattedMessage id="tasksManagement.showAll" defaultMessage="Show: All" />,
      value: '0'
    });
    serviceData.push({
      label: <FormattedMessage id="tasksManagement.serviceTask" defaultMessage="Service task" />,
      value: true
    });
    serviceData.push({
      label: <FormattedMessage id="tasksManagement.nonServiceTask" defaultMessage="Non-service task" />,
      value: false
    });

    return serviceData;
  };

  const serviceChecklistOptions = () => {
    let serviceData = [];

    serviceData.push({
      label: <FormattedMessage id="checklistManagement.showAll" defaultMessage="Show: All" />,
      value: '0'
    });
    serviceData.push({
      label: <FormattedMessage id="checklistManagement.serviceChecklist" defaultMessage="Service checklist" />,
      value: true
    });
    serviceData.push({
      label: <FormattedMessage id="checklistManagement.nonServiceChecklist" defaultMessage="Non-service checklist" />,
      value: false
    });

    return serviceData;
  };

  const onServiceTaskFilterChange = e => {
    setFilterServiceTask(e.value);
    searchTaskTemplateHandler(SERVICE_TASK_FILTER, e.value);
  };

  const searchHandler = value => {
    setSearchTaskFilter(value);
    searchTaskTemplateHandler(SEARCH_TASK_FILTER, value);
  };

  const onServiceChecklistFilterChange = e => {
    setFilterServiceChecklist(e.value);
    searchChecklistTemplateHandler(SERVICE_CHECKLIST_FILTER, e.value);
  };

  const searchChecklistHandler = value => {
    setSearchChecklistFilter(value);
    searchChecklistTemplateHandler(SEARCH_CHECKLIST_FILTER, value);
  };

  const searchTaskTemplateHandler = (type, value) => {
    let filteredList = taskTemplateManager.TaskTemplates;
    setIsTaskFilter(false);

    if (type === SERVICE_TASK_FILTER) {
      if (value !== '0') {
        filteredList = filteredList.filter(function(item) {
          return item.IsServiceTask === value;
        });
        setIsTaskFilter(true);
      }
    } else {
      if (filterServiceTask !== '0') {
        filteredList = filteredList.filter(function(item) {
          return item.IsServiceTask === filterServiceTask;
        });
        setIsTaskFilter(true);
      }
    }

    if (type === SEARCH_TASK_FILTER) {
      if (value !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
        setIsTaskFilter(true);
      }
    } else {
      if (searchTaskFilter !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchTaskFilter.toLowerCase());
        });
        setIsTaskFilter(true);
      }
    }

    if (filteredList.length > initialLimit) {
      setInitialTaskFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalTaskTemplates(filteredList);
  };

  const searchChecklistTemplateHandler = (type, value) => {
    let filteredList = checklistTemplate.ChecklistTemplates;
    setIsChecklistFilter(false);

    if (type === SERVICE_CHECKLIST_FILTER) {
      if (value !== '0') {
        filteredList = filteredList.filter(function(item) {
          return item.IsServiceChecklist === value;
        });
        setIsChecklistFilter(true);
      }
    } else {
      if (filterServiceChecklist !== '0') {
        filteredList = filteredList.filter(function(item) {
          return item.IsServiceChecklist === filterServiceChecklist;
        });
        setIsChecklistFilter(true);
      }
    }

    if (type === SEARCH_CHECKLIST_FILTER) {
      if (value !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
        setIsChecklistFilter(true);
      }
    } else {
      if (searchChecklistFilter !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchChecklistFilter.toLowerCase());
        });
        setIsChecklistFilter(true);
      }
    }

    if (filteredList.length > initialLimit) {
      setInitialChecklistFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalChecklistTemplates(filteredList);
  };

  const processTaskTemplates = () => {
    let taskTemplates = [];
    let processedTaskTemplates = [];

    if (showAllTaskTemplates) {
      taskTemplates = localTaskTemplates;
    } else {
      if (isTaskFilter) {
        taskTemplates = localTaskTemplatesLength > initialLimit ? initialTaskFilteredRecords : localTaskTemplates;
      } else {
        taskTemplates = initialTasktemplateRecords;
      }
    }

    taskTemplates.forEach(element => {
      processedTaskTemplates.push({
        Name: element.Name,
        Id: element.TaskTemplateId,
        IsServiceTask: element.IsServiceTask,
        Link: `/admin-controls/task-template-detail/${element.TaskTemplateId}`
      });
    });

    return processedTaskTemplates;
  };

  const processChecklistTemplates = () => {
    let checklistTemplates = [];
    let processedChecklistTemplates = [];

    if (showAllChecklistTemplates) {
      checklistTemplates = localChecklistTemplates;
    } else {
      if (isChecklistFilter) {
        checklistTemplates = localChecklistTemplatesLength > initialLimit ? initialChecklistFilteredRecords : localChecklistTemplates;
      } else {
        checklistTemplates = initialChecklistTemplateRecords;
      }
    }

    checklistTemplates.forEach(element => {
      processedChecklistTemplates.push({
        Name: element.Name,
        Id: element.ChecklistTemplateId,
        IsServiceChecklist: element.IsServiceChecklist,
        Link: `/admin-controls/checklist-template/${element.ChecklistTemplateId}?view=1`
      });
    });

    return processedChecklistTemplates;
  };

  let showAllText = intl.formatMessage({ id: 'checklistTaskTemplateList.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'checklistTaskTemplateList.showLess', defaultMessage: 'SHOW LESS' });

  return (
    <div className={s.checklistTasksTemplates}>
      {(checklistTemplate.isLoading || taskTemplateManager.isLoading) && <LoadingSpinner />}
      <AdminControlsContentTemplate selectedPage="checklistTaskTemplate" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, `CHECKLISTTEMPLATELIST`)}>
            <Row>
              <Col>
                <Row>
                  <img
                    src={checklistTemplateOpen ? collapseDown : collapseUp}
                    alt="Collapse section"
                    onClick={() => setChecklistTemplateOpen(!checklistTemplateOpen)}
                    aria-controls="checklist-template-section"
                    aria-expanded={checklistTemplateOpen}
                  ></img>
                  <h4>
                    <FormattedMessage id="checklistTaskTemplateList.checklistTemplates" defaultMessage="Checklist Templates" />
                  </h4>
                  <span className={s.circle}>
                    {(checklistTemplate.ChecklistTemplates && checklistTemplate.ChecklistTemplates.length) || 0}
                  </span>
                </Row>
              </Col>
            </Row>
            <Collapse in={checklistTemplateOpen}>
              <div className={s.innerComponent} id="checklist-template-section">
                <div className={s.component}>
                  <Row className={s.topRow}>
                    <Col sm={6} lg={4}>
                      <SearchBar
                        searchHandler={searchChecklistHandler}
                        clearSearchInVisible={false}
                        // initialText={filter}
                        placeHolderTextId="checklistTemplateManagement.Search"
                        data-unittest="searchTaskTemplates"
                      />
                    </Col>
                    <Col sm={6} lg={3}>
                      <Dropdown
                        id="drpServiceChecklist"
                        dataArray={serviceChecklistOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="tasksManagement.showAll" defaultMessage="Show: All" />,
                          customClassName: ''
                        }}
                        onDropdownChange={onServiceChecklistFilterChange}
                        selectedOption={serviceChecklistOptions().filter(option => option.value === filterServiceChecklist)}
                        disabled={false}
                      />
                    </Col>
                    <Col sm={12} lg={5} className={s.addbuttonCol} style={WidgetVisibility(userProfileWidgets, `CHECKLISTTEMPLATEADDNEW`)}>
                      <Link to="/admin-controls/checklist-template/0">
                        <Button className={s.addbutton}>
                          + <FormattedMessage id="checklistTaskTemplateList.addNew" defaultMessage="ADD NEW" />
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ChecklistTasksTemplatesTable
                        tableData={processChecklistTemplates()}
                        userProfileWidgets={userProfileWidgets}
                        viewName="CHECKLISTTEMPLATEVIEW"
                      />

                      {((!isChecklistFilter && checklistTemplatesLength > initialLimit) ||
                        (isChecklistFilter && localChecklistTemplatesLength > initialLimit)) && (
                        <Row className={s.topRow}>
                          <Col>
                            <Button
                              variant="outline-secondary"
                              className="w-100"
                              onClick={showAllChecklistTemplateHandler}
                              data-unittest="showButton"
                            >
                              {!showAllChecklistTemplates
                                ? `${showAllText} (${
                                    !showAllChecklistTemplates && !isChecklistFilter
                                      ? checklistTemplatesLength
                                      : localChecklistTemplatesLength
                                  })`
                                : showLessText}
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
          </div>
          <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, `TASKTEMPLATELIST`)}>
            <Row>
              <Col>
                <Row>
                  <img
                    src={taskTemplateOpen ? collapseDown : collapseUp}
                    alt="Collapse section"
                    onClick={() => setTaskTemplateOpen(!taskTemplateOpen)}
                    aria-controls="task-template-section"
                    aria-expanded={taskTemplateOpen}
                  ></img>
                  <h4>
                    <FormattedMessage id="checklistTaskTemplateList.tasksTemplates" defaultMessage="Tasks Templates" />
                  </h4>
                  <span className={s.circle}>{taskTemplateManager.TaskTemplates && taskTemplateManager.TaskTemplates.length}</span>
                </Row>
              </Col>
            </Row>

            <Collapse in={taskTemplateOpen}>
              <div className={s.innerComponent} id="task-template-section">
                <div className={s.component}>
                  <Row className={s.topRow}>
                    <Col sm={6} lg={4}>
                      <SearchBar
                        searchHandler={searchHandler}
                        clearSearchInVisible={false}
                        // initialText={filter}
                        placeHolderTextId="checklistTemplateManagement.Search"
                        data-unittest="searchTaskTemplates"
                      />
                    </Col>
                    <Col sm={6} lg={3}>
                      <Dropdown
                        id="drpServiceTask"
                        dataArray={serviceTaskOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="tasksManagement.showAll" defaultMessage="Show: All" />,
                          customClassName: ''
                        }}
                        onDropdownChange={onServiceTaskFilterChange}
                        selectedOption={serviceTaskOptions().filter(option => option.value === filterServiceTask)}
                        disabled={false}
                      />
                    </Col>
                    <Col sm={12} lg={5} className={s.addbuttonCol} style={WidgetVisibility(userProfileWidgets, `TASKTEMPLATEADDNEW`)}>
                      <Link to="/admin-controls/task-template">
                        <Button className={s.addbutton}>
                          + <FormattedMessage id="checklistTaskTemplateList.addNew" defaultMessage="ADD NEW" />
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  <ChecklistTasksTemplatesTable
                    tableData={processTaskTemplates()}
                    userProfileWidgets={userProfileWidgets}
                    viewName="TASKTEMPLATEVIEW"
                  />

                  {((!isTaskFilter && taskTemplatesLength > initialLimit) || (isTaskFilter && localTaskTemplatesLength > initialLimit)) && (
                    <Row className={s.topRow}>
                      <Col>
                        <Button
                          variant="outline-secondary"
                          className="w-100"
                          onClick={showAllTaskTemplateHandler}
                          data-unittest="showButton"
                        >
                          {!showAllTaskTemplates
                            ? `${showAllText} (${!showAllTaskTemplates && !isTaskFilter ? taskTemplatesLength : localTaskTemplatesLength})`
                            : showLessText}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ChecklistTasksTemplates.defaultProps = {
  taskTemplateManager: {
    ...initialTaskTemplatesState.taskTemplate
  },
  checklistTemplate: {
    ...initialChecklistTemplateState.checklistTemplate
  }
};

ChecklistTasksTemplates.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired,
  taskTemplateManager: PropTypes.object.isRequired,
  getTaskTemplates: PropTypes.func.isRequired,
  checklistTemplate: PropTypes.object.isRequired,
  getChecklistTemplate: PropTypes.func.isRequired
};

export default injectIntl(ChecklistTasksTemplates);
