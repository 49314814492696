import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';
import { setIsLoading } from './ui';
import { push } from 'connected-react-router';
import {
  RESET_VERIFICATION_CODE,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_COMPLETE,
  SET_FORGOT_PASSWORD_CONFIRMATION_SENT,
  LOGIN_FORGOT_PASSWORD_STATUS
} from '../constants/index';

import { USER_MANAGEMENT, FORGOT_PASSWORD, FORGOT_PASSWORD_CONFIRMATION } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setForgotPasswordErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setForgotPasswordError(messageCode, true));
};

export const setForgotPasswordError = (messageCode, status) => {
  return {
    type: FORGOT_PASSWORD_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showMessage: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const submitEmailVerificationThunk = data => dispatch => {
  let log = logEntry(USER_MANAGEMENT, FORGOT_PASSWORD, { username: data.email });
  data.log = log;
  dispatch(submitEmailVerification(data));
};

export const submitEmailVerification = data =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/forgotpassword`,
    method: 'POST',
    onSuccess: emailVerificationSuccessThunk,
    onFailure: err => setForgotPasswordErrorThunk(err),
    onLoad: setIsLoading,
    data,
    skipRefresh: true
  });

export const emailVerificationSuccessThunk = () => dispatch => {
  dispatch(emailVerificationSuccess());
};

export const emailVerificationSuccess = () => {
  return {
    type: SET_FORGOT_PASSWORD_CONFIRMATION_SENT,
    payload: {
      data: {
        isOpSuccessful: true,
        showMessage: '',
        isLoading: false,
        showMessage: false,
        verificationSent: true
      }
    }
  };
};

export const submitConfirmationThunk = data => dispatch => {
  let log = logEntry(USER_MANAGEMENT, FORGOT_PASSWORD_CONFIRMATION, { username: data.email });
  data.log = log;
  dispatch(submitConfirmation(data));
};

export const submitConfirmation = data =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/confirmforgotpassword`,
    method: 'POST',
    onSuccess: confirmationSuccessThunk,
    onFailure: err => setForgotPasswordErrorThunk(err),
    onLoad: setIsLoading,
    data,
    skipRefresh: true
  });

export const confirmationSuccessThunk = () => dispatch => {
  let redirectURL = '/login';
  dispatch(confirmationSuccess());
  dispatch(setLoginForgotPassword(true));
  dispatch(push(redirectURL));
};

export const confirmationSuccess = () => {
  return {
    type: FORGOT_PASSWORD_COMPLETE,
    payload: {
      data: {
        isOpSuccessful: true,
        showMessage: '',
        isLoading: false,
        showMessage: false,
        verificationSent: false
      }
    }
  };
};

export const setLoginForgotPassword = value => ({
  type: LOGIN_FORGOT_PASSWORD_STATUS,
  payload: { value }
});

export const resetVerificationCodeThunk = () => dispatch => {
  dispatch(resetVerificationCode());
};

export const resetVerificationCode = () => ({
  type: RESET_VERIFICATION_CODE,
  payload: {
    data: {
      isOpSuccessful: false,
      showMessage: '',
      isLoading: false,
      showMessage: false,
      verificationSent: false
    }
  }
});
