import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './DeviceSchematic.module.scss';
import refresh from '../../assets/refresh-data.svg';
import { FormattedMessage } from 'react-intl';
import { sendCommand } from '../../actions/devices';
import { fetchSensorReadings, updateSensorLoading } from '../../actions/sensors';
import { getDeviceSensorsLoadingStatus } from '../../selectors/index';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const RefreshData = props => {
  const isLoading = useSelector(state => getDeviceSensorsLoadingStatus(state));
  if (isLoading) return <LoadingSpinner className={s.smallerSpinner} centeredLoading={false} />;
  else return <RefreshContent widgetCode={props.widgetCode} />;
};

const RefreshContent = ({ widgetCode }) => {
  const dispatch = useDispatch();
  const { unitSerialNumber } = useSelector(state => state.devices);

  const refreshHandler = () => {
    try {
      dispatch(updateSensorLoading(true));
      dispatch(sendCommand(2035, widgetCode, false));
      setTimeout(() => {
        dispatch(fetchSensorReadings(unitSerialNumber));
      }, 10000);
    } catch (err) {
      dispatch(updateSensorLoading(false));
    }
  };

  return (
    <div className={s.refreshData} onClick={refreshHandler}>
      <img src={refresh} alt="refresh"></img>
      <span className={s.label}>
        <FormattedMessage id="sequence.refresh" defaultMessage="REFRESH" />
      </span>
    </div>
  );
};

export default RefreshData;
