import { initialEventCodeState } from './initialState';

import {
  EVENTCODE_ERROR,
  GET_ALL_EVENTCODE_DETAILS,
  EVENTCODE_IS_LOADING,
  SET_EVENTCODE_CURRENT_PAGE,
  SET_EVENTCODE_PAGE_FILTER,
  SET_EVENTCODE_CHANGE,
  SET_EVENTCODE,
  UPDATE_EVENTCODE_SAVE_STATUS,
  SET_EVENT_CODE_FILTERED_RESULTS,
  EVENTCODE_CLEANUP
} from '../constants/index';

const eventCodeReducer = (state = initialEventCodeState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_EVENTCODE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        eventCode: {
          ...state.eventCode,
          ...data
        }
      };
    }

    case SET_EVENT_CODE_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        eventCode: {
          ...state.eventCode,
          ...filteredResults
        }
      };
    }

    case SET_EVENTCODE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        eventCode: {
          ...state.eventCode,
          currentPage
        }
      };
    }

    case SET_EVENTCODE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        eventCode: {
          ...state.eventCode,
          filter
        }
      };
    }

    case UPDATE_EVENTCODE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        eventCode: {
          ...state.eventCode,
          ...data
        }
      };
    }

    case EVENTCODE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        eventCode: {
          ...state.eventCode,
          isLoading: isLoading
        }
      };
    }

    case EVENTCODE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        eventCode: {
          ...state.eventCode,
          ...data
        }
      };
    }

    case SET_EVENTCODE: {
      let { selectedEventCode } = payload;

      return {
        ...state,
        eventCode: {
          ...state.eventCode,
          selectedEventCode: {
            ...state.eventCode.selectedEventCode,
            ...selectedEventCode
          }
        }
      };
    }

    case SET_EVENTCODE_CHANGE: {
      let { selectedEventCode } = payload;

      return {
        ...state,
        eventCode: {
          ...state.eventCode,
          selectedEventCode: {
            ...state.eventCode.selectedEventCode,
            ...selectedEventCode
          }
        }
      };
    }

    case EVENTCODE_CLEANUP: {
      const { data } = payload;
      return {
        ...state,
        eventCode: {
          ...state.eventCode,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default eventCodeReducer;
