import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import s from './PartDetails.module.scss';
import { FormattedMessage } from 'react-intl';
import Table from 'react-bootstrap/Table';
import { TELEMETRY_TYPES } from '../../constants/index';
import { getCurrentTimezone } from '../../selectors/index';
import moment from 'moment-timezone';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';

const PartTelemetryHistoryListTable = ({ telemetryHistoryData, onDateSortOrder, dateAsendingOrder }) => {
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  let dateSortIcon = dateAsendingOrder ? ascendingIcon : descendingIcon;

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th className={s.sortHeading}>
              <span onClick={onDateSortOrder}>
                <FormattedMessage id="componentParts.eventDate" defaultMessage="Event Date" />{' '}
                <img className={s.sortIcon} src={dateSortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="componentParts.eventType" defaultMessage="Event Type" />
            </th>
            <th>
              <FormattedMessage id="componentParts.unitSerialNumber" defaultMessage="Unit Serial Number" />
            </th>
            <th>
              <FormattedMessage id="componentParts.telemetryType" defaultMessage="Telemetry Type" />
            </th>
            <th>
              <FormattedMessage id="componentParts.telemetryValue" defaultMessage="Value" />
            </th>
          </tr>
        </thead>
        <tbody>
          {telemetryHistoryData.map(n => {
            return (
              <Fragment key={n.TelemetryPartsHistoryId}>
                <tr key={n.TelemetryPartsHistoryId} data-unittest="telemetryHistoryData">
                  <td>
                    {n.CreatedDateTime
                      ? moment
                          .unix(n.CreatedDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm:ss z')
                      : '-'}
                  </td>
                  <td>{n.EventType}</td>
                  <td>{n.UnitSerialNumber}</td>
                  <td>{TELEMETRY_TYPES[n.TelemetryType].name}</td>
                  <td>{n.Value}</td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

PartTelemetryHistoryListTable.defaultProps = {
  telemetryHistoryData: []
};

PartTelemetryHistoryListTable.propTypes = {
  telemetryHistoryData: PropTypes.array.isRequired
};

export default PartTelemetryHistoryListTable;
