import { initialCalibrationEquationState } from './initialState';

import {
  CALIBRATION_EQUATION_ERROR,
  GET_ALL_CALIBRATION_EQUATION_DETAILS,
  CALIBRATION_EQUATION_IS_LOADING,
  SET_CALIBRATION_EQUATION_CURRENT_PAGE,
  SET_CALIBRATION_EQUATION_PAGE_FILTER,
  SET_CALIBRATION_EQUATION_CHANGE,
  SET_CALIBRATION_EQUATION_CLEANUP,
  SET_CALIBRATION_EQUATION,
  UPDATE_CALIBRATION_EQUATION_SAVE_STATUS,
  SET_CALIBRATION_EQUATION_CONSTANT_CHANGE,
  SET_CALIBRATION_EQUATION_CONSTANT_LIST_CHANGE,
  SHOW_CALIBRATION_EQUATION_CONSTANT_ADD_EDIT_SCREEN
} from '../constants/index';

const calibrationEquationReducer = (state = initialCalibrationEquationState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_CALIBRATION_EQUATION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          ...data
        }
      };
    }

    case SET_CALIBRATION_EQUATION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          currentPage
        }
      };
    }

    case SET_CALIBRATION_EQUATION_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          filter
        }
      };
    }

    case UPDATE_CALIBRATION_EQUATION_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          ...data
        }
      };
    }

    case CALIBRATION_EQUATION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          isLoading: isLoading
        }
      };
    }

    case CALIBRATION_EQUATION_ERROR: {
      const { data } = payload;
      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          ...data
        }
      };
    }

    case SET_CALIBRATION_EQUATION: {
      let SelectedCalibrationEquation = payload;

      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          SelectedCalibrationEquation: {
            ...state.calibrationEquation.SelectedCalibrationEquation,
            ...SelectedCalibrationEquation
          }
        }
      };
    }

    case SET_CALIBRATION_EQUATION_CHANGE: {
      let { SelectedCalibrationEquation } = payload;

      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          SelectedCalibrationEquation: {
            ...state.calibrationEquation.SelectedCalibrationEquation,
            ...SelectedCalibrationEquation
          }
        }
      };
    }

    case SET_CALIBRATION_EQUATION_CONSTANT_CHANGE: {
      let { SelectedEquationConstant } = payload;

      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          SelectedCalibrationEquation: {
            ...state.calibrationEquation.SelectedCalibrationEquation,
            EquationConstants: {
              ...state.calibrationEquation.SelectedCalibrationEquation.EquationConstants,
              SelectedEquationConstant: {
                ...SelectedEquationConstant
              }
            }
          }
        }
      };
    }

    case SET_CALIBRATION_EQUATION_CONSTANT_LIST_CHANGE: {
      let { EquationConstantList } = payload;

      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          SelectedCalibrationEquation: {
            ...state.calibrationEquation.SelectedCalibrationEquation,
            EquationConstants: {
              ...state.calibrationEquation.SelectedCalibrationEquation.EquationConstants,
              EquationConstantList: EquationConstantList
            }
          }
        }
      };
    }

    case SHOW_CALIBRATION_EQUATION_CONSTANT_ADD_EDIT_SCREEN: {
      let { showAddEditScreen } = payload;

      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          SelectedCalibrationEquation: {
            ...state.calibrationEquation.SelectedCalibrationEquation,
            EquationConstants: {
              ...state.calibrationEquation.SelectedCalibrationEquation.EquationConstants,
              showAddEditScreen: showAddEditScreen
            }
          }
        }
      };
    }

    case SET_CALIBRATION_EQUATION_CLEANUP: {
      return {
        ...state,
        calibrationEquation: {
          ...state.calibrationEquation,
          SelectedCalibrationEquation: {
            CalibrationEquationId: 0,
            Name: '',
            Description: '',
            GuidIdentifier: '',
            IsActive: true,
            EquationConstants: {
              EquationConstantList: [],
              SelectedEquationConstant: {
                CalibrationEquationConstantId: 0,
                CalibrationEquationId: 0,
                Name: '',
                Description: '',
                Formula: '',
                GuidIdentifier: '',
                isChanged: 0,
                IsActive: true,
                OrderNumber: 0
              },
              showAddEditScreen: false
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default calibrationEquationReducer;
