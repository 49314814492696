import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DeviceHeader from './DeviceHeader';
import { getRoutePathname, getDeviceOnlineStatus, getDeviceTimezone, getCurrentTimezone } from '../../selectors/index';
import { fetchSensorReadings } from '../../actions/sensors';
import { switchTimezone } from '../../actions/timezone';

const mapStateToProps = state => {
  return {
    currentPath: getRoutePathname(state),
    deviceStatus: getDeviceOnlineStatus(state),
    deviceTimezone: getDeviceTimezone(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSensorReadings: unitSerialNumber => fetchSensorReadings(unitSerialNumber),
      changeTimezone: (showLocalTimeZone, deviceTimezone) => deviceTimezone && switchTimezone(showLocalTimeZone, deviceTimezone)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeviceHeader);
