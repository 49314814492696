import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './UpdatePassword.module.scss';
import LoadingSpinner from '..//LoadingSpinner/LoadingSpinner';
import { checkAndRedirect, isValidPassword } from '../../utils/';
import bs from '../../styles/bootstrap-overrides.scss';
import logoBlack from '../../assets/logo-black.svg';
import alert from '../../assets/alert.svg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import classNames from 'classnames';

const UpdatePassword = ({ isAuthenticated, isLoading, loginError, redirect, sessionId, submitPassword, username }) => {
  const [form, setForm] = useState({ newPassword: '', confirmPassword: '' });
  const [formErrors, setFormErrors] = useState({ newPassword: false, confirmPassword: false });

  useEffect(() => {
    if (sessionId) checkAndRedirect(!isAuthenticated, redirect);
  }, []);

  const handleInput = e => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const passwordError = !isValidPassword(form.newPassword);
    const confirmPasswordError = form.newPassword !== form.confirmPassword;

    setFormErrors({
      newPassword: passwordError,
      confirmPassword: confirmPasswordError
    });

    if (!passwordError && !confirmPasswordError) {
      submitPassword({ newPassword: form.newPassword, username, session: sessionId });
    }
  };

  return (
    <div className={s.wrapper}>
      <img src={logoBlack} alt="icon" className={classNames(s.hideDesktop, s.logo)} />

      <div className={s.login}>
        <h1>Update password</h1>

        <Form onSubmit={handleSubmit}>
          {loginError && (
            <Alert variant="light">
              <img src={alert} alt="icon" /> Error logging in
            </Alert>
          )}

          <Form.Label>New password</Form.Label>
          <Form.Control
            type="password"
            name="newPassword"
            value={form.newPassword}
            onChange={handleInput}
            className={formErrors.newPassword ? s.formControlError : ''}
          />

          {formErrors.newPassword && (
            <p role="alert" className={s.error}>
              Password must contain at least 1 uppercase, 1 lowercase and 10 characters
            </p>
          )}

          <Form.Label>Confirm password</Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            value={form.confirmPassword}
            onChange={handleInput}
            className={formErrors.confirmPassword ? s.formControlError : ''}
          />

          {formErrors.confirmPassword && (
            <p role="alert" className={s.error}>
              Password does not match
            </p>
          )}

          <Button disabled={isLoading} type="submit">
            Update Password
          </Button>
        </Form>

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
};

UpdatePassword.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loginError: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired,
  submitPassword: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired
};

export default UpdatePassword;
