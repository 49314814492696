import React from 'react';
import PropTypes from 'prop-types';
import s from './ConfirmDialog.module.scss';
import { FormattedMessage } from 'react-intl';
import Button from 'react-bootstrap/Button';

const ConfirmDialog = ({ onClose, onConfirm, showDialog, title }) => {
  if (!showDialog) return null;
  return (
    <div className={s.confirmDialogWrapper}>
      <div className={s.dialogBox}>
        <button className={s.close} onClick={onClose} />
        <h3>{title}</h3>

        <div className={s.buttonHolder}>
          <Button variant="outline-secondary" onClick={onConfirm}>
            <FormattedMessage id="common.yes" defaultMessage="Yes" />
          </Button>
          <Button variant="outline-secondary" onClick={onClose}>
            <FormattedMessage id="common.no" defaultMessage="No" />
          </Button>
        </div>
      </div>
    </div>
  );
};

ConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  showDialog: PropTypes.bool,
  title: PropTypes.string.isRequired
};

export default ConfirmDialog;
