import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash.throttle';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from './EventAction.module.scss';
import ChartWrapper from '../ChartWrapper/ChartWrapper';
import { getSensorModelComponentPositions, getUnitManager } from '../../selectors/index';
import { updateChartValues, fetchMetricData, updateChartFilterValues } from '../../actions/eventAction';

const EventActionTrendAnalysis = ({ eventAction, intl, currentTimezone, userProfileWidgets, isOpen }) => {
  const dispatch = useDispatch();

  //const componentPositions = useSelector(state => getSensorModelComponentPositions(state));
  const unitManager = useSelector(state => getUnitManager(state));
  let componentPositions = unitManager.selectedUnit.unitComponentPositions || [];
  componentPositions = componentPositions.filter(x => x.NeedFeedback && x.IsParent === 0);
  const modelSensors = componentPositions || [];
  const sensorOptions = modelSensors.sort().map(sensor => ({
    value: sensor.Name.toLowerCase(),
    label: sensor.Name.toLowerCase(),
    IsOCP: sensor.IsOCP,
    IsCCP: sensor.IsCCP,
    Name: sensor.Name
  }));

  const updateChartValuesThottled = throttle((id, unitSerialNumber, field, value) => {
    if (typeof value === 'number' || field === 'sensors') {
      let newValue = value;
      if ((field === 'lowerThreshold' || field === 'upperThreshold') && isNaN(value)) {
        newValue = '';
      }
      dispatch(updateChartValues(field, newValue));
    }
  }, 500);

  const fetchMetricDataForChart = (id, unitSerialNumber, from, to, sensor, limit, resultType) => {
    if (from != 0 && to != 0) {
      dispatch(fetchMetricData(unitSerialNumber, from, to, sensor, limit, resultType, 'ALARMMANAGE_CHART'));
    }
  };

  const updateChartFilterValuesThottled = throttle((id, unitSerialNumber, data) => {
    dispatch(updateChartFilterValues(data));
  }, 500);

  return (
    <div className={s.trendAnalysis}>
      {isOpen && (
        <ChartWrapper
          userProfileWidgets={userProfileWidgets}
          currentTimezone={currentTimezone}
          fetchMetricData={fetchMetricDataForChart}
          updateChartValuesThottled={updateChartValuesThottled}
          updateChartFilterValuesThottled={updateChartFilterValuesThottled}
          removeChart={null}
          unitComponentParts={componentPositions}
          index={0}
          key={'dt1'}
          unitSerialNumber={eventAction.UnitSerialNumber || ''}
          {...eventAction.TrendAnalysisChart}
          sensorOptions={sensorOptions}
          intl={intl}
          fromDataTrend={false}
        />
      )}
    </div>
  );
};

export default injectIntl(EventActionTrendAnalysis);
