import { IS_LOADING } from '../constants/index';
import { initialUiState } from './initialState';

const uiReducer = (state = initialUiState, action) => {
  const { payload, type } = action;
  switch (type) {
    case IS_LOADING: {
      const { value } = payload;
      return {
        ...state,
        isLoading: value
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
