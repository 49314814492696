import Cookies from 'js-cookie';
import { apiAction } from '../utils/index';

import {
  GET_ALL_TELEMETRY_LINKS,
  TELEMETRY_LINKS_IS_LOADING,
  TELEMETRY_LINKS_MANAGEMENT_ERROR,
  UPDATE_TELEMETRY_LINKS_SAVE_STATUS,
  SET_TELEMETRY_LINKS_CHANGE,
  SET_TELEMETRY_LINK
} from '../constants/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

// Telemetry Links
export const setTelemetryLinksErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setTelemetryLinksError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTelemetryLinksError(messageCode, false));
  }, 2500);
};

export const setTelemetryLinksError = (messageCode, status) => {
  return {
    type: TELEMETRY_LINKS_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

// export const getTelemetryLinksThunk = (modelId, widgetCode) => dispatch => {
//   if (modelId) {
//     dispatch(getTelemetryLinks(modelId, widgetCode));
//   }
// };

export const setTelemetryLinksLoadingStatus = isLoading => ({
  type: TELEMETRY_LINKS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getTelemetryLinks = (modelId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/model/componentposition/telemetrylink`,
    data: {
      ModelId: modelId
    },
    method: 'GET',
    onLoad: status => setTelemetryLinksLoadingStatus(status),
    onSuccess: retData => setAllTelemetryLinksListThunk(retData),
    onFailure: err => setTelemetryLinksErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllTelemetryLinksListThunk = ({ retData }) => dispatch => {
  let messageCode = retData && retData.message;
  let TelemetryLinkDetails = (retData && retData.TelemetryLink) || [];
  dispatch(setAllTelemetryLinksList(messageCode, TelemetryLinkDetails));
};

export const setAllTelemetryLinksList = (messageCode, TelemetryLinkDetails) => ({
  type: GET_ALL_TELEMETRY_LINKS,
  payload: {
    data: {
      TelemetryLinks: TelemetryLinkDetails,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

// export const describeTelemetryLinkThunk = (telemetryLinkId, widgetCode) => dispatch => {
//   dispatch(describeTelemetryLink(telemetryLinkId, widgetCode));
// };

export const describeTelemetryLink = (telemetryLinkId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/model/componentposition/telemetrylink`,
    data: {
      TelemetryLinkId: telemetryLinkId || undefined
    },
    method: 'GET',
    onLoad: status => setTelemetryLinksLoadingStatus(status),
    onSuccess: retData => setTelemetryLinkThunk(retData),
    onFailure: err => setTelemetryLinksErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setTelemetryLinkThunk = retData => dispatch => {
  const telemetryLink = retData.retData.TelemetryLink || {};
  let telemetryLinkDetails = telemetryLink.length > 0 ? telemetryLink[0] : [];
  dispatch(setComponentPart(telemetryLinkDetails));
};

export const setComponentPart = data => ({
  type: SET_TELEMETRY_LINK,
  payload: {
    selectedTelemetryLink: data,
    isOpSuccessful: false,
    showBanner: false
  }
});

// export const setTelemetryLinkChangeThunk = retData => dispatch => {
//   dispatch(setTelemetryLinkChange(retData));
// };

export const setTelemetryLinkChange = retData => ({
  type: SET_TELEMETRY_LINKS_CHANGE,
  payload: retData
});

// export const saveTelemetryLinkThunk = (TelemetryLinkDetails, widgetCode) => dispatch => {
//   let url = '/management/model/componentposition/telemetrylink/save';
//   let actionName = TelemetryLinkDetails.TelemetryLinkId === 0 ? CREATE_TELEMETRY_LINK : UPDATE_TELEMETRY_LINK;
//   let log = logEntry(TELEMETRY_LINKS_MANAGEMENT, actionName, TelemetryLinkDetails);
//   dispatch(postTelemetryLink(TelemetryLinkDetails, url, log, widgetCode));
// };

export const postTelemetryLink = (TelemetryLinkDetails, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/model/componentposition/telemetrylink/save`,
    method: 'POST',
    onLoad: status => setTelemetryLinksLoadingStatus(status),
    onSuccess: retData => setTelemetryLinkStatusThunk(retData, TelemetryLinkDetails),
    onFailure: err => setTelemetryLinksErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      TelemetryLink: TelemetryLinkDetails,
      log: log
    },
    widgetcode: widgetCode
  });

export const setTelemetryLinkStatusThunk = (retData, TelemetryLinkDetails) => dispatch => {
  let messageCode = retData && retData.message;
  dispatch(setTelemetryLinkStatus(messageCode, true));
  setTimeout(() => {
    if (TelemetryLinkDetails.PerformDelete) dispatch(setTelemetryLinkStatus('refresh', false));
    else dispatch(setTelemetryLinkStatus('hhhh', false));
  }, 2500);
};

export const setTelemetryLinkStatus = (messageCode, status) => ({
  type: UPDATE_TELEMETRY_LINKS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});
