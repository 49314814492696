import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAppWidgetsThunk } from '../../actions/appwidgets';
import Profile from './Profile';
import { getProfile, getAppWidgets, getSelectedProfile, getUserProfileWidget } from '../../selectors/index';
import { postProfileThunk, setSelectedProfileThunk, getSelectedProfileWidgetsThunk } from '../../actions/profiles';

const mapStateToProps = (state, props) => {
  return {
    profile: getProfile(state),
    selectedProfile: getSelectedProfile(state),
    widgetData: getAppWidgets(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSelectedProfile: (skey, widgetCode) => getSelectedProfileWidgetsThunk(skey, widgetCode),
      saveProfile: (profile, widgetCode) => postProfileThunk(profile, widgetCode),
      setSelectedProfile: profile => setSelectedProfileThunk(profile),
      getAppWidgets: widgetCode => fetchAppWidgetsThunk(widgetCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
