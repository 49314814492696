import React from 'react';
import s from './Calibration.module.scss';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie';
import { CALIBRATION_FINALIZED, CALIBRATION_UPLOADED } from '../../constants/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';

const CalibrationFinalize = ({
  calibrationManager,
  saveCalibration,
  unitSerialNumber,
  updateUnitShadow,
  userProfileWidgets,
  selectedUnit
}) => {
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const finalizeHandler = () => {
    let saveData = calibrationManager.SelectedCalibration;
    saveData.IsFinalized = true;
    saveData.Status = CALIBRATION_FINALIZED;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveCalibration(saveData, unitSerialNumber, false, 'CALIBRATION_FINALIZE');
  };

  const uploadHandler = () => {
    let saveData = calibrationManager.SelectedCalibration;
    saveData.IsUploaded = true;
    saveData.Status = CALIBRATION_UPLOADED;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    //saveCalibration(saveData, unitSerialNumber);
    let payLoadData = {
      uploadUnitCalibrations: true,
      modelId: selectedUnit.ModelId,
      modelVersionId: saveData.ModelVersionId,
      unitId: saveData.UnitId,
      unitSerialNumber: unitSerialNumber,
      updatedBy: saveData.UpdatedBy
    };
    updateUnitShadow(saveData, unitSerialNumber, payLoadData, true);
  };

  const isFinalizedDisabled = calibrationManager.SelectedCalibration.IsFinalized;
  const isUploadDisabled = !calibrationManager.SelectedCalibration.IsFinalized || calibrationManager.SelectedCalibration.IsUploaded;

  return (
    <div className={s.calibration}>
      <div className={s.contentWrapper}>
        {(IsWidgetAccessible(userProfileWidgets, 'CALIBRATION_FINALIZE') ||
          IsWidgetAccessible(userProfileWidgets, 'UPLOAD_UNIT_SHADOW')) && (
          <div className={s.calibrationFinalize}>
            <Row className={s.breakrow}>
              <Col sm={12}>
                <FormattedMessage id="calibration.finalizeNote" defaultMessage="Please finalize before uploading the calibration" />
              </Col>
            </Row>
            <Row className={s.breakrow}>
              <Col sm={12}>
                <Button
                  style={WidgetVisibility(userProfileWidgets, `CALIBRATION_FINALIZE`)}
                  className={s.finalizeButton + ' ' + (!isFinalizedDisabled ? '' : s.buttonDisable)}
                  onClick={finalizeHandler}
                  disabled={isFinalizedDisabled}
                  data-unittest="finalizeButton"
                >
                  <FormattedMessage id="calibration.finalize" defaultMessage="FINALIZE" />
                </Button>
                <Button
                  className={s.finalizeButton + ' ' + (!isUploadDisabled ? '' : s.buttonDisable)}
                  onClick={uploadHandler}
                  disabled={isUploadDisabled}
                  data-unittest="uploadButton"
                  style={WidgetVisibility(userProfileWidgets, `UPLOAD_UNIT_SHADOW`)}
                >
                  <FormattedMessage id="calibration.upload" defaultMessage="UPLOAD" />
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalibrationFinalize;
