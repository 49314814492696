import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { REFRESH_TIME } from '../../constants/index';
import { initialEventActionStatus } from '../../reducers/initialState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import s from './EventLog.module.scss';
import t from '../../styles/table.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import alert from '../../assets/alert.svg';
import warning from '../../assets/warning.svg';
import descendingIcon from '../../assets/arrow-up.png';
import ascendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { compareValues } from '../../utils';
import Banner from '../Banner/Banner';
import uuid from 'uuid';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const EventLog = ({
  dateFrom,
  dateTo,
  code,
  type,
  descOrder,
  events,
  fetchEvents,
  isLoading,
  limit,
  view,
  currentTimezone,
  eventActionStatus,
  fetchEventActionExistance,
  selectedDevice,
  flowSequences,
  intl,
  userProfileWidgets,
  alarms,
  warnings,
  infoEvents,
  updateEventInputs
}) => {
  useEffect(() => {
    fetchEvents(limit, view, dateFrom, dateTo, code, type);
    if (view === 'processFlow') {
      const interval = setInterval(() => {
        fetchEvents(limit, view, dateFrom, dateTo, code, type);
      }, REFRESH_TIME);
      return () => clearInterval(interval);
    }
  }, [fetchEvents, limit, view]);

  useEffect(() => {
    if (view === 'viewAll') {
      let errorEvents = events && events.filter(event => event.type === 2);
      errorEvents = errorEvents.map(event => {
        return {
          EventCode: event.code,
          Timestamp: event.timestamp,
          EventType: event.type
        };
      });
      if (errorEvents && errorEvents.length > 0) {
        fetchEventActionExistance(errorEvents);
      }
    }
  }, [view, fetchEventActionExistance, events]);

  const showLoading = (view === 'viewAll' && (isLoading || eventActionStatus.isLoading)) || (view === 'processFlow' && events.length === 0);
  const history = useHistory();
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'ALARMMANAGE');

  const onEventActionBtnClick = (code, type) => {
    let eventActionId =
      (eventActionStatus.Events.filter(event => event.EventCode.toString() === code.toString() && event.EventType === type).length > 0 &&
        eventActionStatus.Events.filter(event => event.EventCode.toString() === code.toString() && event.EventType === type)[0]
          .EventActionId) ||
      0;

    if (eventActionId !== 0) history.push(`/events/action/${selectedDevice.unitSerialNumber}/${eventActionId}?from=eventlog`);
  };

  const onSortOrder = () => {
    descOrder = descOrder === '1' ? '0' : '1';
    updateEventInputs && updateEventInputs(selectedDevice.unitSerialNumber, 'descOrder', descOrder);
  };

  let messageId = (eventActionStatus && eventActionStatus.messageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let sortIcon = descOrder === '1' ? descendingIcon : ascendingIcon;

  return (
    <div className={classNames(s.eventLog, { [s.viewAll]: view === 'viewAll' })}>
      {showLoading && <LoadingSpinner centeredLoading={view === 'viewAll'} />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={eventActionStatus.showBanner}
        status={eventActionStatus.isOpSuccessful}
        successText={messageText}
      />
      {view === 'viewAll' && events.length === 0 && <p className={s.noData}>No data currently available</p>}

      {events.length > 0 && (
        <div className={`${s.eventsList}`}>
          <Row className={s.eventHeader}>
            <Col>
              <span onClick={onSortOrder} className={view === 'viewAll' ? s.sortHeading : ''}>
                <FormattedMessage id="thresholdTable.timestamp" defaultMessage="Time" />
                {view === 'viewAll' ? <img className={s.sortIcon} src={sortIcon} alt="icon" /> : ''}
              </span>
            </Col>
            <Col>
              <FormattedMessage id="thresholdTable.event" defaultMessage="Event" />
            </Col>
            <Col>
              <FormattedMessage id="thresholdTable.message" defaultMessage="Message" />
            </Col>
            {view === 'viewAll' && (
              <Col>
                <FormattedMessage id="thresholdTable.eventInfo" defaultMessage="Event Info" />
              </Col>
            )}
            {view === 'viewAll' && <Col></Col>}
          </Row>

          {events.map(({ timestamp, type, code, message, alarm_type }, i) => (
            <Row className={s.eventContent} key={`${timestamp}-${i}`}>
              <Col>
                <span className={t.date}>
                  {moment(timestamp)
                    .tz(currentTimezone)
                    .format('DD-MMM-YYYY HH:mm:ss z')}
                </span>
              </Col>
              <Col>
                {/* {type === 0 &&
                  ((flowSequences &&
                    flowSequences.filter(x => x.SequenceCode && x.SequenceCode.toString() === code.toString()).length > 0 &&
                    flowSequences.filter(x => x.SequenceCode && x.SequenceCode.toString() === code.toString())[0].Description) ||
                    code.toString())} */}

                {type === 1 &&
                  code != null &&
                  ((warnings &&
                    warnings.filter(x => x.Code.toString() === code.toString()).length > 0 &&
                    warnings.filter(x => x.Code.toString() === code.toString())[0].Name) ||
                    code.toString())}

                {type === 2 &&
                  code != null &&
                  ((alarms &&
                    alarms.filter(x => x.Code.toString() === code.toString()).length > 0 &&
                    alarms.filter(x => x.Code.toString() === code.toString())[0].Name) ||
                    code.toString())}

                {type === 3 &&
                  code != null &&
                  ((flowSequences &&
                    flowSequences.filter(x => x.SequenceCode && x.SequenceCode.toString() === code.toString()).length > 0 &&
                    flowSequences.filter(x => x.SequenceCode && x.SequenceCode.toString() === code.toString())[0].Description) ||
                    code.toString())}

                {type === 4 &&
                  code != null &&
                  ((infoEvents &&
                    infoEvents.filter(x => x.Code.toString() === code.toString()).length > 0 &&
                    infoEvents.filter(x => x.Code.toString() === code.toString())[0].Name) ||
                    code.toString())}

                {(type === 1 || type === 2) && <img src={type === 1 ? warning : alert} alt="icon"></img>}
              </Col>
              <Col>{message ? message.toString() : ''}</Col>
              {view === 'viewAll' && <Col>{alarm_type ? alarm_type.toString() : ''}</Col>}
              {view === 'viewAll' && (
                <Col>
                  {type === 2 && isUpdateAllowed && (
                    <div className={s.evenlogButton}>
                      <Button
                        variant="outline-secondary"
                        noValidate
                        data-unittest="btnInsert"
                        onClick={() => onEventActionBtnClick(code, type)}
                      >
                        <FormattedMessage id="eventLog.update" defaultMessage="UPDATE" />
                        {/* {!eventActionStatus ||
                        !eventActionStatus.Events ||
                        eventActionStatus.Events.length === 0 ||
                        eventActionStatus.Events.filter(event => event.EventCode === code && event.EventType === type).length === 0 ||
                        eventActionStatus.Events.filter(event => event.EventCode === code && event.EventType === type)[0].EventStatus ===
                          ALARM_STATUS_NEW ? (
                          <FormattedMessage id="eventLog.create" defaultMessage="CREATE" />
                        ) : (
                          <FormattedMessage id="eventLog.update" defaultMessage="UPDATE" />
                        )} */}
                      </Button>
                    </div>
                  )}
                </Col>
              )}
            </Row>
          ))}
        </div>
      )}
    </div>
  );
};

EventLog.defaultProps = {
  isLoading: false,
  currentTimezone: moment.tz.guess(),
  events: [],
  eventActionStatus: {
    ...initialEventActionStatus.eventActionStatus
  },
  selectedDevice: { unitSerialNumber: '' },
  warnings: [],
  alarms: []
};

EventLog.propTypes = {
  code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  events: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  fetchEvents: PropTypes.func.isRequired,
  fetchEventActionExistance: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  dateFrom: PropTypes.number,
  dateTo: PropTypes.number,
  type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  view: PropTypes.string.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  flowSequences: PropTypes.array.isRequired,
  alarms: PropTypes.array.isRequired,
  warnings: PropTypes.array.isRequired,
  versions: PropTypes.array
};

export default injectIntl(EventLog);
