import React from 'react';
import s from './Organisation.module.scss';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { initialOrganisationManagerState } from '../../reducers/initialState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SearchBar from '../SearchBar/SearchBar';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const OrganisationUsers = ({
  organisationManager,
  parentOrganisationUsers,
  onUserSelectionChange,
  selectedUserIds,
  onUserSearch,
  initialText,
  disable
}) => {
  let activeUsers = [];

  const searchHandler = value => {
    onUserSearch(value);
  };

  if (parentOrganisationUsers) {
    //check if there is any organisation user which doesn't belong to parent organisation, add it to active users
    if (organisationManager.toFilterUsers) {
      let standaloneUsers = organisationManager.toFilterUsers.filter(o => !parentOrganisationUsers.some(i => i.UserId === o.UserId));
      parentOrganisationUsers = parentOrganisationUsers.concat(standaloneUsers);
    }
    activeUsers = parentOrganisationUsers
      .filter(p => p.IsActive && !p.IsDeleted)
      .map(user => {
        return {
          ...user,
          SKEY: user.UserId,
          label: `${user.FullName} - ${user.Email}`,
          value: user.UserId,
          isChecked: selectedUserIds && selectedUserIds.filter(userid => userid === user.UserId).length > 0
        };
      });
  }

  const onCheckboxSelectionChange = (e, data) => {
    if (!selectedUserIds) {
      selectedUserIds = [];
    }

    if (data.isChecked) {
      selectedUserIds.push(data.UserId);
    } else {
      selectedUserIds = selectedUserIds.filter(selUser => selUser !== data.UserId);
    }
    onUserSelectionChange(selectedUserIds);
  };

  return (
    <div className={s.organisationUsers} id="organisationUsers">
      {parentOrganisationUsers.length === 0 && <LoadingSpinner centeredLoading={false} />}
      <Row className={s.row}>
        <Col sm={12}>
          <SearchBar
            searchHandler={searchHandler}
            clearSearchInVisible={true}
            initialText={initialText}
            placeHolderTextId="organisationManagement.search"
          />
        </Col>
      </Row>
      <Row className={s.selectedUsersAndUnits}>
        <Col sm={12} className={s.selectedCols}>
          <Checkbox dataArray={activeUsers} onSelectionChange={onCheckboxSelectionChange} disable={disable} />
        </Col>
      </Row>

      <div></div>
    </div>
  );
};

OrganisationUsers.defaultProps = {
  parentOrganisationUsers: [],
  organisationManager: {
    ...initialOrganisationManagerState.organisationManager
  }
};

OrganisationUsers.propTypes = {
  onUserSelectionChange: PropTypes.func.isRequired
};

export default injectIntl(OrganisationUsers);
