import React from 'react';
import PropTypes from 'prop-types';
import bs from '../../styles/bootstrap-overrides.scss';
import s from './Dashboard.module.scss';
import DeviceList from '../DeviceList/DeviceListContainer';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';
import { WidgetVisibility } from '../../utils/widgetManager';
import DashboardTasks from '../DashboardTasks/DashboardTasksContainer';
import DashboardStatistics from '../DashboardStatistics/DashboardStatistics';

const Dashboard = ({ devices, userProfileWidgets, allowedUnits }) => {
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const name = Cookies.get(`name-${envName}`);
  let OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  let devicesObj = Object.values(devices);

  if (devicesObj && Array.isArray(devicesObj) && allowedUnits && allowedUnits.userProfileUnits) {
    devicesObj = devicesObj.filter(
      unit =>
        allowedUnits.userProfileUnits.filter(
          allowedUnit => allowedUnit.OrganisationId == OrganisationId && allowedUnit.UnitId == unit.UnitId
        ).length > 0
    );
  } else {
    devicesObj = [];
  }

  return (
    <div className={s.devices}>
      <div className={s.fullWidth}>
        <h1>
          <FormattedMessage id="dashboard.hello" defaultMessage="Hello" />, {name}
        </h1>
      </div>

      <div style={WidgetVisibility(userProfileWidgets, 'DASHBOARD_STATISTICS_VIEW')}>
        <DashboardStatistics devices={devicesObj} />
      </div>

      <div style={WidgetVisibility(userProfileWidgets, 'MYUNITSINDASHBOARD')}>
        <DeviceList devices={devicesObj} allowedUnits={allowedUnits} />
      </div>

      <div style={WidgetVisibility(userProfileWidgets, 'MYTASKSINDASHBOARD')}>
        <DashboardTasks devices={devicesObj} userProfileWidgets={userProfileWidgets} />
      </div>
    </div>
  );
};

Dashboard.defaultProps = {
  devices: {},
  allowedUnits: {
    userProfileUnits: []
  }
};

Dashboard.propTypes = {
  devices: PropTypes.object,
  userProfileWidgets: PropTypes.object,
  allowedUnits: PropTypes.object
};

export default Dashboard;
