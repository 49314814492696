import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SequenceVersionFileDetail from './SequenceVersionFileDetail';
import {
  getSequenceVersionFileManager,
  getSequenceVersion,
  getUserProfileWidget,
  getCurrentTimezone,
  getModelVersionManager,
  getFlowSequenceGlobalSettings
} from '../../selectors/index';
import {
  describeSequenceVersionFileThunk,
  describeSequenceVersionThunk,
  saveSequenceVersionFileThunk,
  setSequenceVersionFileChangeThunk
} from '../../actions/sequenceVersion';
import { describeModelVersionThunk } from '../../actions/modelVersion';

const mapStateToProps = state => {
  return {
    sequenceVersionFileManager: getSequenceVersionFileManager(state),
    sequenceVersionManager: getSequenceVersion(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state),
    modelVersionManager: getModelVersionManager(state),
    flowSequences: getFlowSequenceGlobalSettings(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeSequenceVersionFile: (sequenceVersionId, sequenceVersionFileId, widgetCode) =>
        describeSequenceVersionFileThunk(sequenceVersionId, sequenceVersionFileId, widgetCode),
      describeSequenceVersion: (sequenceVersionId, widgetCode) => describeSequenceVersionThunk(sequenceVersionId, widgetCode),
      saveSequenceVersionFile: (sequenceVersionFile, widgetCode) => saveSequenceVersionFileThunk(sequenceVersionFile, widgetCode),
      changeSequenceVersionFile: sequenceVersionFile => setSequenceVersionFileChangeThunk(sequenceVersionFile),
      describeModelVersion: (modelVersionId, widgetCode) => describeModelVersionThunk(modelVersionId, false, widgetCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SequenceVersionFileDetail);
