import Cookies from 'js-cookie';
import {
  USER_MANAGEMENT_ERROR,
  GET_ALL_USER_DETAILS,
  USER_UI_DETAILS,
  USER_IS_LOADING,
  UPDATE_USERPROFILE_SAVE_STATUS,
  SET_SELECTED_USER,
  SET_USER_CURRENT_PAGE,
  SET_USER_PAGE_FILTER,
  SET_USER_DETAILS,
  UPDATE_CHANGEPASSWORD_STATUS,
  USERDETAILS_CLEANUP
} from '../constants/index';
import { USER_MANAGEMENT, CREATE_USER, UPDATE_USER, UPDATE_USER_PASSWORD, USER_LOGOUT } from '../constants/logs';
import { apiAction, logEntry } from '../utils/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

//User Management
export const setUserErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setUserError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUserError(messageCode, false));
  }, 2500);
};

export const setUserError = (messageCode, status) => {
  return {
    type: USER_MANAGEMENT_ERROR,
    payload: {
      user: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getUsersThunk = (limit, filter, orgId, widgetCode) => dispatch => {
  dispatch(getUsers(limit, filter, orgId, widgetCode));
};

export const setLoadingStatus = isLoading => ({
  type: USER_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getUsers = (limit, filter, orgId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/management/user`,
    data: {
      Limit: limit,
      Filter: filter,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllUserListThunk(retData),
    onFailure: err => setUserErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllUserListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let users = (retData && retData.user && retData.user.users) || [];
  dispatch(setAllUserList(messageCode, users));
};

export const setAllUserList = (messageCode, users) => ({
  type: GET_ALL_USER_DETAILS,
  payload: {
    user: {
      users: users,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessfull: false
    }
  }
});

export const setSelectedUser = userId => ({
  type: SET_SELECTED_USER,
  payload: { userId }
});

export const setCurrentPage = currentPage => ({
  type: SET_USER_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_USER_PAGE_FILTER,
  payload: { filter }
});

export const setSelectedUserThunk = user => dispatch => {
  dispatch(setUser(user));
};

export const setUser = user => ({
  type: USER_UI_DETAILS,
  payload: { user, showBanner: false, isLoading: false }
});

export const setUserCleanupThunk = user => dispatch => {
  dispatch(setUserCleanup(user));
};

export const setUserCleanup = user => ({
  type: USERDETAILS_CLEANUP,
  payload: { user }
});

export const postUserThunk = (user, widgetCode, fromAccountSettings) => dispatch => {
  let url = '/management/user/save';
  let actionName = CREATE_USER;
  if (fromAccountSettings) {
    url = '/management/user/updatemyuser';
    actionName = UPDATE_USER;
  } else if (user && user.IsEdit) {
    url = '/management/user/update';
    actionName = UPDATE_USER;
  }
  let log = logEntry(USER_MANAGEMENT, actionName, user);
  dispatch(saveUser(user, url, log, widgetCode));
};

export const saveUser = (user, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}${url}`,
    method: 'POST',
    widgetcode: widgetCode,
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setUserStatusThunk(retData),
    onFailure: err => setUserErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      user,
      log
    }
  });

export const setUserStatusThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  dispatch(setUserStatus(messageCode || '', true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUserStatus(messageCode || '', false));
  }, 2500);
};

export const setUserStatus = (messageCode, status) => ({
  type: UPDATE_USERPROFILE_SAVE_STATUS,
  payload: {
    user: {
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const describeUserThunk = (userId, widgetCode, fromAccountPage) => dispatch => {
  let url = `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/management/user/describe`;
  if (fromAccountPage) {
    url = `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/management/user/describemyuser`;
  }
  dispatch(describeUser(userId, widgetCode, url));
};

export const describeUser = (userId, widgetCode, url) =>
  apiAction({
    url: url,
    data: {
      UserId: userId || undefined,
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0
    },
    widgetcode: widgetCode,
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setDescribeUserThunk(retData),
    onFailure: err => setUserErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`)
  });

export const setDescribeUserThunk = retData => dispatch => {
  dispatch(setDescribeUser(retData));
};

export const setDescribeUser = ({ retData }) => ({
  type: SET_USER_DETAILS,
  payload: retData
});

export const submitChangePassword = data => dispatch => {
  let url = '/management/user/updatepassword';
  let log = logEntry(USER_MANAGEMENT, UPDATE_USER_PASSWORD);
  dispatch(changepPassword(data, url, log));
};

export const changepPassword = (data, url, log) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setChangePasswordStatusThunk(retData),
    onFailure: err => setUserErrorThunk(err),
    data: {
      data,
      log
    }
  });

export const setChangePasswordStatusThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  dispatch(setChangePasswordStatus(messageCode || '', true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setChangePasswordStatus(messageCode || '', false));
  }, 2500);
};

export const setChangePasswordStatus = (messageCode, status) => ({
  type: UPDATE_CHANGEPASSWORD_STATUS,
  payload: {
    user: {
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});
