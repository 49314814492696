import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './ModelComponentPositionList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const ModelComponentPositionListTable = ({
  ModelComponentPositionData,
  RemoveModelComponentPosition,
  ChangeModelComponentPosition,
  UpdateAllowed,
  DeleteAllowed,
  intl
}) => {
  const labelNo = <FormattedMessage id="common.no" defaultMessage="No" />;
  const labelYes = <FormattedMessage id="common.yes" defaultMessage="Yes" />;
  return (
    <div className={s.contentWrapper}>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="modelComponentPositions.name" defaultMessage="Name" />
            </th>
            {/* <th className="d-none d-lg-table-cell">
              <FormattedMessage id="modelComponentPositions.description" defaultMessage="Description" />
            </th> */}
            <th>
              <FormattedMessage id="modelComponentPositions.componentType" defaultMessage="Component type" />
            </th>
            {/* <th>
              <FormattedMessage id="modelComponentPositions.state" defaultMessage="State" />
            </th>
            <th>
              <FormattedMessage id="modelComponentPositions.feedback" defaultMessage="Feedback" />
            </th> */}
            <th>
              <FormattedMessage id="modelComponentPositions.ccp" defaultMessage="CCP" />
            </th>
            <th>
              <FormattedMessage id="modelComponentPositions.ocp" defaultMessage="OCP" />
            </th>
            {/* <th className="d-none d-lg-table-cell">
              <FormattedMessage id="modelComponentPositions.unitOfMeasure" defaultMessage="Unit of measure" />
            </th> */}
            {/* <th className="d-none d-lg-table-cell">
              <FormattedMessage id="modelComponentPositions.active" defaultMessage="Active" />
            </th> */}
            {(UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {ModelComponentPositionData.map(n => {
            return (
              n.ParentModelComponentPositionId == null && (
                <Fragment key={n.ModelComponentPositionId}>
                  <tr data-unittest="modelComponentPositionData">
                    <td>{n.Name}</td>
                    {/* <td className="d-none d-lg-table-cell">{n.Description}</td> */}
                    <td>{n.ComponentType}</td>
                    {/* <td>{n.NeedState ? labelYes : labelNo}</td>
                    <td>{n.NeedFeedback ? labelYes : labelNo}</td> */}
                    <td>{n.IsCCP ? labelYes : labelNo}</td>
                    <td>{n.IsOCP ? labelYes : labelNo}</td>
                    {/* <td className="d-none d-lg-table-cell">{n.NeedUnitOfMeasure}</td> */}
                    {/* <td className="d-none d-lg-table-cell">{n.IsActive ? lblTrue : lblFalse}</td> */}
                    {(UpdateAllowed || DeleteAllowed) && (
                      <td className={s.btnCol}>
                        {UpdateAllowed && (
                          <Button
                            variant="outline-secondary"
                            className={s.changeButton}
                            href="#"
                            onClick={() => {
                              ChangeModelComponentPosition(n.ModelComponentPositionId, n.ParentModelComponentPositionId);
                            }}
                            data-unittest="changeData"
                          >
                            <FormattedMessage id="modelComponentPositions.change" defaultMessage="CHANGE" />
                          </Button>
                        )}
                        {DeleteAllowed && (
                          <Button
                            variant="outline-secondary"
                            className={s.removeButton}
                            href="#"
                            onClick={() => {
                              RemoveModelComponentPosition(n.ModelComponentPositionId);
                            }}
                            data-unittest="removeData"
                          >
                            <FormattedMessage id="modelComponentPositions.remove" defaultMessage="Remove" />
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                </Fragment>
              )
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ModelComponentPositionListTable.defaultProps = {
  ModelComponentPositionData: []
};

ModelComponentPositionListTable.propTypes = {
  ModelComponentPositionData: PropTypes.array.isRequired,
  RemoveModelComponentPosition: PropTypes.func.isRequired,
  ChangeModelComponentPosition: PropTypes.func.isRequired,
  UpdateAllowed: PropTypes.bool,
  DeleteAllowed: PropTypes.bool
};

export default injectIntl(ModelComponentPositionListTable);
