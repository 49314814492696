import Cookies from 'js-cookie';

import { EVENT_ACTION_EXISTANCE_IS_LOADING, EVENT_ACTION_EXISTANCE_ERROR, EVENT_ACTION_EXISTANCE_STATUS } from '../constants/index';

import { apiAction } from '../utils/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const fetchEventActionExistanceThunk = (data, widgetCode) => (dispatch, getState) => {
  const { unitSerialNumber } = getState().devices;
  const reqData = {
    Events: data,
    UnitSerialNumber: unitSerialNumber,
    UserId: Cookies.get(`userid-${envName}`) || 1
  };
  dispatch(fetchEventActionExistance(reqData, widgetCode));
};

export const fetchEventActionExistance = (data, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${data.UnitSerialNumber}/events/eventAction/exists`,
    method: 'POST',
    onSuccess: resp => setEventActionExistance(resp),
    onFailure: error => setEventActionExistanceErrorThunk(error),
    onLoad: e => setEventActionExistanceIsLoading(e),
    accessToken: Cookies.get(`access-${envName}`),
    data,
    widgetcode: widgetCode
  });

export const setEventActionExistanceErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setEventActionExistanceError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setEventActionExistanceError(messageCode, false));
  }, 3500);
};

export const setEventActionExistanceError = (messageCode, status) => ({
  type: EVENT_ACTION_EXISTANCE_ERROR,
  payload: {
    eventActionStatus: {
      isOpSuccessfull: false,
      showBanner: status,
      isLoading: false,
      messageCode: messageCode || ''
    }
  }
});

export const setEventActionExistance = data => ({
  type: EVENT_ACTION_EXISTANCE_STATUS,
  payload: { ...data, isLoading: false }
});

export const setEventActionExistanceIsLoading = data => ({
  type: EVENT_ACTION_EXISTANCE_IS_LOADING,
  payload: { isLoading: data }
});
