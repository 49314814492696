import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionServicePlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelVersionState } from '../../reducers/initialState';
import ModelVersionServicePlanListTable from '../ModelVersionServicePlan/ModelVersionServicePlanListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const ModelVersionServicePlansList = ({
  servicePlans,
  intl,
  ModelVersionId,
  ReadOnly,
  userProfileWidgets,
  checklistTemplate,
  getModelVersionsServicePlans,
  saveModelVersionServicePlan,
  describeModelVersionServicePlan,
  changeModelVersionServicePlan,
  setServicePlanShowScreenStatus,
  getChecklistTemplate,
  currentTimezone
}) => {
  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;
  let initialRecords = servicePlans.ServicePlansList.slice(0, initialLimit);

  useEffect(() => {
    getModelVersionsServicePlans(offset, limit, filter, ModelVersionId);
  }, [getModelVersionsServicePlans, offset, limit, filter, ModelVersionId]);

  const [currentEditId, setCurrentEditId] = useState(-1);
  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [servicePlanId, setServicePlanId] = useState(0);
  const [searchText, setSearchText] = useState('');

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const localSettingLength = localSettings.length;
  const servicePlanLength = servicePlans.ServicePlansList.length;
  const addNewRef = useRef(null);

  useEffect(() => {
    getChecklistTemplate('MODELVERSIONSERVICEPLANSADD');
  }, [getChecklistTemplate]);

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(servicePlans.ServicePlansList);
    }
    setShowAll(!showAll);
  };

  const disableModeHandler = status => {
    setServicePlanShowScreenStatus(status);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = servicePlans.ServicePlansList.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const setAddHandler = () => {
    setServicePlanShowScreenStatus(true);
    setCurrentEditId(0);
    window.scrollTo(0, addNewRef.current.offsetTop);
  };

  const setEditHandler = modelVersionServicePlanId => {
    setCurrentEditId(modelVersionServicePlanId);
    if (modelVersionServicePlanId === -1) {
      changeModelVersionServicePlan({
        SelectedServicePlan: {
          ...initialModelVersionState.modelVersionManager.selectedModelVersion.ServicePlans.SelectedServicePlan,
          ModelVersionId: ModelVersionId
        }
      });
      setServicePlanShowScreenStatus(false);
    } else {
      setServicePlanShowScreenStatus(true);
    }
  };

  const filterData = () => {
    let filteredList = servicePlans.ServicePlansList;
    if (isFilter) {
      filteredList = servicePlans.ServicePlansList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getSettings = () => {
    let servicePlans = [];
    if (showAll) {
      servicePlans = filterData();
    } else {
      if (isFilter) {
        servicePlans = localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        servicePlans = initialRecords;
      }
    }
    return servicePlans.sort(compareValues('Name'));
  };

  const removeServicePlanClick = servicePlanId => {
    setActive(true);
    setServicePlanId(servicePlanId);
  };
  const DeleteServicePlan = () => {
    let saveData = servicePlans.SelectedServicePlan;
    saveData.PerformDelete = true;
    saveData.ModelVersionId = ModelVersionId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.ModelVersionServicePlanId = servicePlanId;
    saveModelVersionServicePlan(saveData, 'MODELVERSIONSERVICEPLANSDELETE');
  };

  const handleClose = () => {
    setActive(false);
    setServicePlanId(0);
  };

  const handleConfirm = () => {
    DeleteServicePlan();
    handleClose();
  };

  let messageId = (servicePlans && servicePlans.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'modelVersion.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelVersion.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSIONSERVICEPLANSEDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSIONSERVICEPLANSDELETE');

  const dialogTitle = intl.formatMessage({
    id: 'modelVersion.confirmDeleteServicePlans',
    defaultMessage: 'Are you sure you want to delete the global setting?'
  });

  return (
    <div className={s.modelVersion}>
      {servicePlans.isLoading && <LoadingSpinner />}
      {servicePlans.showBanner && (
        <Banner
          failureText={messageText}
          showBanner={servicePlans.showBanner}
          status={servicePlans.isOpSuccessfull}
          successText={messageText}
        />
      )}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              disabled={servicePlans.showAddEditScreen}
              // initialText={filter}
              placeHolderTextId="modelVersion.search"
              data-unittest="searchServicePlans"
            />
          </Col>
          <Col sm={6} lg={8}>
            {!ReadOnly ? (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONSERVICEPLANSADD')}
                onClick={setAddHandler}
                disabled={servicePlans.showAddEditScreen}
                data-unittest="addServicePlanButton"
              >
                + <FormattedMessage id="modelVersion.addNew" defaultMessage="ADD NEW" />
              </Button>
            ) : (
              ''
            )}
          </Col>
        </Row>

        <div>
          <ModelVersionServicePlanListTable
            setEditHandler={setEditHandler}
            ModelVersionId={ModelVersionId}
            selectedModelVersionServicePlanId={currentEditId}
            saveModelVersionServicePlan={saveModelVersionServicePlan}
            modelVersionServicePlans={servicePlans}
            describeModelVersionServicePlan={describeModelVersionServicePlan}
            changeModelVersionServicePlan={changeModelVersionServicePlan}
            checklistTemplate={checklistTemplate}
            UpdateAllowed={isUpdateAllowed}
            DeleteAllowed={isDeleteAllowed}
            servicePlansData={getSettings()}
            disableMode={servicePlans.showAddEditScreen}
            disableModeHandler={disableModeHandler}
            addNewRef={addNewRef}
            ReadOnly={ReadOnly}
            removeServicePlan={removeServicePlanClick}
            currentTimezone={currentTimezone}
          />
        </div>
        {((!isFilter && servicePlanLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? servicePlanLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

ModelVersionServicePlansList.defaultProps = {
  ...initialModelVersionState
};

ModelVersionServicePlansList.propTypes = {
  checklistTemplate: PropTypes.object,
  userProfileWidgets: PropTypes.object.isRequired,
  getModelVersionsServicePlans: PropTypes.func.isRequired,
  saveModelVersionServicePlan: PropTypes.func.isRequired,
  describeModelVersionServicePlan: PropTypes.func.isRequired,
  changeModelVersionServicePlan: PropTypes.func.isRequired,
  setServicePlanShowScreenStatus: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionServicePlansList);
