import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './GeneralGlobalSettingsDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialGlobalSettingsState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Link, useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import GoBack from '../WSAControls/GoBack/GoBack';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { GLOBAL_SETTINGS_TYPE, FLOWSEQUENCE_TYPE, SERVICE_TYPE } from '../../constants';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import { getQuerystring } from '../../utils';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import { Fragment } from 'react';

const GeneralGlobalSettingsDetail = ({
  intl,
  userProfileWidgets,
  currentTimezone,
  globalSettings,
  describeGlobalSettings,
  saveGlobalSettings,
  changeGlobalSettings
}) => {
  const history = useHistory();
  const backLink = '/admin-controls/general-global-settings';
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  //Redirecting
  if (globalSettings.redirect) {
    history.push(backLink);
  }
  //cleanup code
  useEffect(() => {
    return () => {
      changeGlobalSettings({
        selectedGlobalSettings: {
          ...initialGlobalSettingsState.selectedGlobalSettings
        }
      });
    };
  }, [changeGlobalSettings]);

  let IsEdit = false;
  let { generalGlobalSettingId } = useParams();
  let IsDuplicate = getQuerystring('duplicate') === '1' || false;

  let type = globalSettings.selectedGlobalSettings.Type || GLOBAL_SETTINGS_TYPE;

  const [formError, setFormError] = useState({});

  generalGlobalSettingId = parseInt(generalGlobalSettingId);
  IsEdit = generalGlobalSettingId !== 0 && !IsDuplicate;

  //Checking permission and redirecting to unauth page if unauthorised
  if (IsEdit && !IsWidgetAccessible(userProfileWidgets, 'GENERALGLOBALSETTINGSUPDATE')) {
    history.push('/unauth');
  }
  if (IsDuplicate && !IsWidgetAccessible(userProfileWidgets, 'GENERALGLOBALSETTINGSCLONE')) {
    history.push('/unauth');
  }
  if (generalGlobalSettingId === 0 && !IsWidgetAccessible(userProfileWidgets, 'GENERALGLOBALSETTINGSADDNEW')) {
    history.push('/unauth');
  }

  let widgetCode = '';

  if (IsEdit) {
    widgetCode = 'GENERALGLOBALSETTINGSUPDATE';
  } else if (IsDuplicate) {
    widgetCode = 'GENERALGLOBALSETTINGSCLONE';
  } else {
    widgetCode = 'GENERALGLOBALSETTINGSADDNEW';
  }

  useEffect(() => {
    changeGlobalSettings({
      selectedGlobalSettings: {
        ...initialGlobalSettingsState.globalSettings.selectedGlobalSettings
      }
    });
    if (IsEdit || IsDuplicate) {
      describeGlobalSettings(generalGlobalSettingId, widgetCode);
    }
  }, [describeGlobalSettings, changeGlobalSettings, generalGlobalSettingId, IsEdit, IsDuplicate]);

  //cleanup code
  useEffect(() => {
    return () => {
      changeGlobalSettings({
        selectedGlobalSettings: {
          ...initialGlobalSettingsState.globalSettings.selectedGlobalSettings
        }
      });
    };
  }, [changeGlobalSettings]);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = globalSettings.selectedGlobalSettings;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    saveData.TranslationCode = `globalSettings.${saveData.Name.replace(/ /g, '')}`;
    if (!IsEdit) {
      saveData.GeneralGlobalSettingId = 0;
      saveData.Type = saveData.Type || GLOBAL_SETTINGS_TYPE;
    }

    if (saveData.Type === GLOBAL_SETTINGS_TYPE) {
      saveData.SequenceCode = null;
    } else if (saveData.Type === SERVICE_TYPE) {
      saveData.SequenceCode = null;
      saveData.DefaultValue = null;
      saveData.UnitOfMeasure = null;
      saveData.ShowCommand = false;
    } else {
      saveData.ShowCommand = saveData.ShowCommand ? saveData.ShowCommand : false;
      saveData.DefaultValue = null;
      saveData.UnitOfMeasure = null;
    }
    saveGlobalSettings(saveData, widgetCode);
  };

  const validateInput = () => {
    let formError = {};

    let isValid = true;

    if (
      !globalSettings ||
      !globalSettings.selectedGlobalSettings ||
      !globalSettings.selectedGlobalSettings.Name ||
      isRequired(globalSettings.selectedGlobalSettings.Name, 1)
    ) {
      formError.Name = intl.formatMessage({
        id: 'globalSettingsManagement.nameMandatory',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !globalSettings ||
      !globalSettings.selectedGlobalSettings ||
      !globalSettings.selectedGlobalSettings.Code ||
      isRequired(globalSettings.selectedGlobalSettings.Code, 1)
    ) {
      formError.Code = intl.formatMessage({
        id: 'globalSettingsManagement.codeMandatory',
        defaultMessage: 'Code is a mandatory field'
      });
      isValid = false;
    }
    if (type === GLOBAL_SETTINGS_TYPE && globalSettings && globalSettings.selectedGlobalSettings) {
      let defValue = globalSettings.selectedGlobalSettings.DefaultValue;
      let minValue = globalSettings.selectedGlobalSettings.MinimumValue;
      let maxValue = globalSettings.selectedGlobalSettings.MaximumValue;

      if (minValue !== '' && maxValue !== '') {
        if (maxValue < minValue) {
          formError.MaximumValue = intl.formatMessage({
            id: 'globalSettingsManagement.maxValuemustbeGrater',
            defaultMessage: 'Max value must be grater than Min value'
          });
          isValid = false;
        }
      }

      if (defValue !== '') {
        if ((minValue !== '' && defValue < minValue) || (maxValue !== '' && defValue > maxValue)) {
          formError.DefaultValue = intl.formatMessage({
            id: 'globalSettingsManagement.defaultValueWithinRange',
            defaultMessage: 'Default value must be within Min and Max range'
          });
          isValid = false;
        }
      }
    }

    if (
      type === FLOWSEQUENCE_TYPE &&
      (!globalSettings ||
        !globalSettings.selectedGlobalSettings ||
        !globalSettings.selectedGlobalSettings.SequenceCode ||
        isRequired(globalSettings.selectedGlobalSettings.SequenceCode, 1))
    ) {
      formError.SequenceCode = intl.formatMessage({
        id: 'globalSettingsManagement.sequenceMandatory',
        defaultMessage: 'Sequence code is a mandatory field'
      });
      isValid = false;
    }

    if (
      type === FLOWSEQUENCE_TYPE &&
      globalSettings &&
      globalSettings.selectedGlobalSettings &&
      globalSettings.selectedGlobalSettings.SequenceCode &&
      isNaN(globalSettings.selectedGlobalSettings.SequenceCode)
    ) {
      formError.SequenceCode = intl.formatMessage({
        id: 'globalSettingsManagement.sequenceNumeric',
        defaultMessage: 'Sequence code must be numeric value'
      });
      isValid = false;
    }

    setFormError(formError);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      globalSettings.selectedGlobalSettings = {
        ...globalSettings.selectedGlobalSettings,
        [e.target.name]: e.target.checked
      };
    } else {
      globalSettings.selectedGlobalSettings = {
        ...globalSettings.selectedGlobalSettings,
        [e.target.name]: e.target.value
      };
    }
    changeGlobalSettings(globalSettings);
  };

  const globalSettingsTypeOptions = () => {
    let lblGlobalSettingsType = intl.formatMessage({
      id: 'globalSettingsManagement.globalSetting',
      defaultMessage: 'Global Setting'
    });

    let lblFlowSequenceType = intl.formatMessage({
      id: 'globalSettingsManagement.flowSequence',
      defaultMessage: 'Flow Sequence'
    });

    let lblServiceType = intl.formatMessage({
      id: 'globalSettingsManagement.serviceType',
      defaultMessage: 'Service Type'
    });

    let typeOptions = [
      {
        label: lblGlobalSettingsType,
        value: GLOBAL_SETTINGS_TYPE
      },
      {
        label: lblFlowSequenceType,
        value: FLOWSEQUENCE_TYPE
      },
      {
        label: lblServiceType,
        value: SERVICE_TYPE
      }
    ];
    return [...typeOptions];
  };

  const onDropdownChange = e => {
    globalSettings.selectedGlobalSettings = {
      ...globalSettings.selectedGlobalSettings,
      Type: e.value
    };
    changeGlobalSettings(globalSettings);
  };

  let messageId = (globalSettings && globalSettings.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const globalSettingsName = (globalSettings.selectedGlobalSettings ? globalSettings.selectedGlobalSettings.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'globalSettingsManagement.edit', defaultMessage: 'Edit' }) + ' ' + globalSettingsName;
  } else if (IsDuplicate) {
    heading = intl.formatMessage({ id: 'globalSettingsManagement.duplicate', defaultMessage: 'Duplicate' });
  } else {
    heading = intl.formatMessage({ id: 'globalSettingsManagement.addNewGlobalSetting', defaultMessage: 'Add new global setting' });
  }
  return (
    <div className={s.globalSettings}>
      {globalSettings.isLoading && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={globalSettings.showBanner}
        status={globalSettings.isOpSuccessful}
        successText={messageText}
      />

      <AdminControlsContentTemplate selectedPage="globalSettings" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.globalSettingsHeader}>
            <GoBack returnUrl={backLink}>
              &lt; &nbsp;
              <FormattedMessage id="globalSettingsManagement.backToGlobalSettings" defaultMessage="BACK TO GLOBAL SETTINGS" />
            </GoBack>
            <Row>
              <Col>
                <h3 data-unittest="headingLabel">{heading}</h3>
              </Col>
            </Row>
          </div>
          <div className={s.globalSettingsContent}>
            <Form className={s.viewRow}>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formGlobalSettingsName">
                    <Form.Label>
                      <FormattedMessage id="globalSettingsManagement.name" defaultMessage="Name" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={globalSettings.selectedGlobalSettings.Name}
                      className={`${s.formControl} ${formError && formError.Name ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'globalSettingsManagement.name',
                        defaultMessage: 'Name'
                      })}
                    />
                    {formError && formError.Name && (
                      <p role="alert" className={s.error}>
                        {formError.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formGlobalSettingsType">
                    <Form.Label>
                      <FormattedMessage id="globalSettingsManagement.type" defaultMessage="Type" />
                    </Form.Label>
                    <Dropdown
                      id="formGlobalSettingsType"
                      dataArray={globalSettingsTypeOptions()}
                      controlData={{ placeholderText: 'Type', customClassName: '' }}
                      onDropdownChange={onDropdownChange}
                      data-unittest="formGlobalSettingsType"
                      selectedOption={globalSettingsTypeOptions().filter(option => option.value === type)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formGlobalSettingsCode">
                    <Form.Label>
                      <FormattedMessage id="globalSettingsManagement.code" defaultMessage="Code" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Code"
                      onChange={onChange}
                      value={globalSettings.selectedGlobalSettings.Code}
                      className={`${s.formControl} ${formError && formError.Code ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'globalSettingsManagement.code',
                        defaultMessage: 'Code'
                      })}
                    />
                    {formError && formError.Code && (
                      <p role="alert" className={s.error}>
                        {formError.Code}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formGlobalSettingsDescription">
                    <Form.Label>
                      <FormattedMessage id="globalSettingsManagement.description" defaultMessage="Description" />
                    </Form.Label>

                    <Form.Control
                      className={s.txtArea}
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'globalSettingsManagement.description',
                        defaultMessage: 'Description'
                      })}
                      value={globalSettings.selectedGlobalSettings.Description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {type === GLOBAL_SETTINGS_TYPE && (
                <Fragment>
                  <Row>
                    <Col xs={12} lg={2}>
                      <Form.Group controlId="formGlobalSettingsDefaultValue">
                        <Form.Label>
                          <FormattedMessage id="globalSettingsManagement.defaultValue" defaultMessage="Default value" />
                        </Form.Label>

                        <InputNumber
                          name="DefaultValue"
                          step=".1"
                          onInputChange={onChange}
                          value={globalSettings.selectedGlobalSettings.DefaultValue}
                          id="formGlobalSettingDefaultValue"
                          customClassName={`${formError && formError.DefaultValue ? s.formControlError : ''}`}
                        />
                        {formError && formError.DefaultValue && (
                          <p role="alert" className={`${s.error} d-block d-lg-none`}>
                            {formError.DefaultValue}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Group controlId="formGlobalSettingsMinValue">
                        <Form.Label>
                          <FormattedMessage id="globalSettingsManagement.minValue" defaultMessage="Min value" />
                        </Form.Label>
                        <InputNumber
                          name="MinimumValue"
                          step=".1"
                          onInputChange={onChange}
                          value={globalSettings.selectedGlobalSettings.MinimumValue}
                          id="formGlobalSettingMinimumValue"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={2}>
                      <Form.Group controlId="formGlobalSettingsMaxValue">
                        <Form.Label>
                          <FormattedMessage id="globalSettingsManagement.maxValue" defaultMessage="Max value" />
                        </Form.Label>
                        <InputNumber
                          name="MaximumValue"
                          step=".1"
                          onInputChange={onChange}
                          value={globalSettings.selectedGlobalSettings.MaximumValue}
                          id="formGlobalSettingMaximumValue"
                          customClassName={`${formError && formError.MaximumValue ? s.formControlError : ''}`}
                        />
                        {formError && formError.MaximumValue && (
                          <p role="alert" className={`${s.error} d-block d-lg-none `}>
                            {formError.MaximumValue}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="d-none d-lg-block">
                    <Col>
                      {formError && formError.DefaultValue && (
                        <p role="alert" className={s.error}>
                          {formError.DefaultValue}
                        </p>
                      )}
                      {formError && formError.MaximumValue && (
                        <p role="alert" className={s.error}>
                          {formError.MaximumValue}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Fragment>
              )}
              {type === GLOBAL_SETTINGS_TYPE && (
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group controlId="formGlobalSettingsUnitOfMeasure">
                      <Form.Label>
                        <FormattedMessage id="globalSettingsManagement.unitOfMeasure" defaultMessage="Unit of measure" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="UnitOfMeasure"
                        onChange={onChange}
                        value={globalSettings.selectedGlobalSettings.UnitOfMeasure}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'globalSettingsManagement.unitOfMeasure',
                          defaultMessage: 'Unit of measure'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {type === FLOWSEQUENCE_TYPE && (
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group controlId="formGlobalSettingsSequenceCode">
                      <Form.Label>
                        <FormattedMessage id="globalSettingsManagement.sequenceCode" defaultMessage="Sequence code" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="SequenceCode"
                        onChange={onChange}
                        value={globalSettings.selectedGlobalSettings.SequenceCode || ''}
                        className={`${s.formControl} ${formError && formError.SequenceCode ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'globalSettingsManagement.sequenceCode',
                          defaultMessage: 'Sequence code'
                        })}
                      />
                      {formError && formError.SequenceCode && (
                        <p role="alert" className={s.error}>
                          {formError.SequenceCode}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {type === FLOWSEQUENCE_TYPE && (
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group controlId="formIsPredefined">
                      <Checkbox
                        key={uuidv4()}
                        dataArray={[
                          {
                            SKEY: 'IsPredefined',
                            target: { type: 'checkbox' },
                            label: intl.formatMessage({
                              id: 'globalSettingsManagement.predefined',
                              defaultMessage: 'Predefined'
                            }),
                            isChecked: globalSettings.selectedGlobalSettings.IsPredefined
                          }
                        ]}
                        onSelectionChange={onChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formIsActive">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsActive',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'globalSettingsManagement.active',
                            defaultMessage: 'Active'
                          }),
                          isChecked: globalSettings.selectedGlobalSettings.IsActive
                        }
                      ]}
                      onSelectionChange={onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className={s.btnRow}>
                  <Button
                    variant="primary"
                    data-unittest="saveData"
                    className={classNames(s.margin5, s.btnSaveChanges)}
                    onClick={submitForm}
                    noValidate
                  >
                    <FormattedMessage id="globalSettingsManagement.save" defaultMessage="Save" />
                  </Button>
                  <Link to={backLink}>
                    <Button variant="outline-secondary" className={s.btnCancel}>
                      <FormattedMessage id="globalSettingsManagement.cancel" defaultMessage="Cancel" />
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

GeneralGlobalSettingsDetail.defaultProps = {
  globalSettings: {
    ...initialGlobalSettingsState.globalSettings
  }
};

GeneralGlobalSettingsDetail.propTypes = {
  saveGlobalSettings: PropTypes.func.isRequired,
  globalSettings: PropTypes.object.isRequired,
  describeGlobalSettings: PropTypes.func.isRequired,
  changeGlobalSettings: PropTypes.func.isRequired
};

export default injectIntl(GeneralGlobalSettingsDetail);
