import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { getUserProfileWidget, getDevices, getUserProfileUnit } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    userProfileWidgets: getUserProfileWidget(state),
    devices: getDevices(state),
    allowedUnits: getUserProfileUnit(state)
  };
};

export default connect(mapStateToProps, null)(Dashboard);
