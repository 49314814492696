import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './Pagination.module.scss';
import { injectIntl } from 'react-intl';

class Pagination extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentPage: null,
      pageCount: null
    };
  }

  updateCurrentPage = (dataLength, pageSize, startingPage) => {
    let pageCount = parseInt(dataLength / pageSize);
    if (dataLength % pageSize > 0) {
      pageCount++;
    }
    this.setState({
      currentPage: startingPage,
      pageCount: pageCount
    });
  };

  componentDidMount() {
    const { data, pageSize, startingPage } = this.props;
    this.updateCurrentPage(data.length, pageSize, startingPage);
  }

  componentDidUpdate(prevProps) {
    const { data, pageSize, startingPage } = this.props;
    const { data: prevData } = prevProps;

    if (data.length !== prevData.length) {
      this.updateCurrentPage(data.length, pageSize, startingPage);
    }
  }

  setCurrentPage = page => this.setState({ currentPage: page });

  generateLink = (targetPage, disabled = false, text = null) => {
    const { pageCount, currentPage } = this.state;
    if (!text && (targetPage <= 0 || targetPage > pageCount)) return null;
    return (
      <button
        disabled={disabled}
        className={classNames({ [s.active]: targetPage === currentPage, [s.numbers]: !text })}
        onClick={() => {
          this.setCurrentPage(targetPage);
          if (this.props.onPageChange) {
            this.props.onPageChange(targetPage);
          }
        }}
      >
        {text || targetPage}
      </button>
    );
  };

  paginationControls() {
    const { currentPage, pageCount } = this.state;
    const { data, pageSize, startingPage } = this.props;
    let intlNext = this.props.intl.formatMessage({ id: 'pagination.next', defaultMessage: '>' });
    let intlPrev = this.props.intl.formatMessage({ id: 'pagination.prev', defaultMessage: '<' });
    let intlshowing = this.props.intl.formatMessage({ id: 'pagination.showing', defaultMessage: 'Showing' });
    let intlof = this.props.intl.formatMessage({ id: 'pagination.of', defaultMessage: 'of' });

    let showingInfo = '';
    let showStartDataCount = 0;
    let showEndDataCount = 0;

    showStartDataCount = pageSize * (currentPage - 1) + 1;
    showEndDataCount = data.length > currentPage * pageSize ? currentPage * pageSize : data.length;

    if (data.length > 0 && showStartDataCount > 0 && showEndDataCount > 0) {
      showingInfo = intlshowing + ' ' + showStartDataCount + '-' + showEndDataCount + ' ' + intlof + ' ' + data.length;
    }
    return (
      <Fragment>
        <div className={s.showing}>
          <span className={s.info}> {showingInfo}</span>
        </div>
        <div className={s.controls}>
          {this.generateLink(currentPage - 1, currentPage === 1, intlPrev)}
          <div className={s.pageLinks}>
            {this.generateLink(currentPage - 2)}
            {this.generateLink(currentPage - 1)}
            {this.generateLink(currentPage)}
            {this.generateLink(currentPage + 1)}
            {this.generateLink(currentPage + 2)}
          </div>
          {this.generateLink(currentPage + 1, currentPage === pageCount, intlNext)}
        </div>
      </Fragment>
    );
  }

  paginatedData() {
    const { data, pageSize } = this.props;
    const { currentPage } = this.state;
    const upperLimit = currentPage * pageSize;
    return data.slice(upperLimit - pageSize, upperLimit);
  }

  render() {
    const { data } = this.props;
    const { currentPage } = this.state;
    return (
      <div className={s.pagination}>
        {React.cloneElement(this.props.children, { currentPage: currentPage, paginatedData: this.paginatedData() })}
        {data.length > 0 && this.paginationControls()}
      </div>
    );
  }
}

Pagination.propTypes = {
  data: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  startingPage: PropTypes.number.isRequired
};

Pagination.defaultProps = {
  pageSize: 20,
  startingPage: 1
};

export default injectIntl(Pagination);
