import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelComponentTypeList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelComponentTypeState } from '../../reducers/initialState';
import ModelComponentTypeListTable from './ModelComponentTypeListTable';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';

const ModelComponentTypeList = ({ modelComponentType, userProfileWidgets, intl, getModelComponentType, saveModelComponentType }) => {
  const history = useHistory();

  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;
  let initialRecords = modelComponentType.modelComponentTypeList.slice(0, initialLimit);
  const [modelComponentTypeOpen, setModelComponentTypeOpen] = useState(false);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    getModelComponentType(offset, limit, filter);
  }, [getModelComponentType, offset, limit, filter, modelComponentType.isOpSuccessful]);

  const [localModelComponentTypes, setLocalModelComponentTypes] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [modelComponentTypeId, setModelComponentTypeId] = useState(0);

  const [searchText, setSearchText] = useState('');

  const localModelComponentTypeLength = localModelComponentTypes.length;
  const globalSettingLength = modelComponentType.modelComponentTypeList.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalModelComponentTypes(modelComponentType.modelComponentTypeList);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = modelComponentType.modelComponentTypeList.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalModelComponentTypes(filteredList);
  };

  const filterData = () => {
    let filteredList = modelComponentType.modelComponentTypeList;
    if (isFilter) {
      filteredList = modelComponentType.modelComponentTypeList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getModelComponentTypeData = () => {
    let modelComponentType = [];
    if (showAll) {
      modelComponentType = filterData();
    } else {
      if (isFilter) {
        modelComponentType = localModelComponentTypeLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        modelComponentType = initialRecords;
      }
    }
    return modelComponentType.sort(compareValues('Name'));
  };

  const onRemoveModelComponentTypeClick = modelComponentTypeId => {
    setActive(true);
    setModelComponentTypeId(modelComponentTypeId);
  };

  const DeleteModelComponentType = () => {
    let saveData = modelComponentType.selectedModelComponentType;
    saveData.PerformDelete = true;
    saveData.ModelComponentTypeId = modelComponentTypeId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveModelComponentType(saveData, 'MODELCOMPONENTTYPE_DELETE');
  };

  const handleClose = () => {
    setActive(false);
    setModelComponentTypeId(0);
  };

  const handleConfirm = () => {
    DeleteModelComponentType();
    handleClose();
  };

  const onAddNewClick = () => {
    history.push('/admin-controls/model-component/model-component-type');
  };

  const onChangeModelComponentTypeClick = modelComponentTypeId => {
    history.push(`/admin-controls/model-component/model-component-type/${modelComponentTypeId}`);
  };

  let messageId = (modelComponentType && modelComponentType.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'modelComponentTypes.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelComponentTypes.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELCOMPONENTTYPE_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELCOMPONENTTYPE_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'modelComponentTypes.confirmDelete',
    defaultMessage: 'Are you sure you want to delete model component type?'
  });

  return (
    <div className={s.modelComponentType}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELCOMPONENTTYPE_LIST')}>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={modelComponentTypeOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setModelComponentTypeOpen(!modelComponentTypeOpen)}
                aria-controls="model-component-type-section"
                aria-expanded={modelComponentTypeOpen}
              ></img>
              <h4>
                <FormattedMessage id="modelComponentTypes.componentTypes" defaultMessage="Component types" />
              </h4>
              <span className={s.circle}>{modelComponentType.modelComponentTypeList.length}</span>
            </Row>
          </Col>
        </Row>
        <Collapse in={modelComponentTypeOpen}>
          <div className={s.innerComponent} id="model-component-type-section">
            <div className={s.component}>
              {modelComponentType.isLoading && <LoadingSpinner />}
              {modelComponentType.showBanner && (
                <Banner
                  failureText={messageText}
                  showBanner={modelComponentType.showBanner}
                  status={modelComponentType.isOpSuccessful}
                  successText={messageText}
                />
              )}
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
              <div className={s.contentWrapper}>
                <Row className={s.topRow}>
                  <Col sm={6} lg={4}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={false}
                      disabled={modelComponentType.showAddEditScreen}
                      // initialText={filter}
                      placeHolderTextId="modelComponentType.search"
                      data-unittest="searchModelComponentTypes"
                    />
                  </Col>
                  <Col sm={6} lg={8}>
                    <Button
                      className={s.addbutton}
                      style={WidgetVisibility(userProfileWidgets, 'MODELCOMPONENTTYPE_ADDNEW')}
                      onClick={onAddNewClick}
                      disabled={modelComponentType.showAddEditScreen}
                      data-unittest="addModelComponentTypeButton"
                    >
                      + <FormattedMessage id="modelComponentType.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Col>
                </Row>

                <div>
                  <ModelComponentTypeListTable
                    RemoveModelComponentType={onRemoveModelComponentTypeClick}
                    ModelComponentTypeData={getModelComponentTypeData()}
                    ChangeModelComponentType={onChangeModelComponentTypeClick}
                    UpdateAllowed={isUpdateAllowed}
                    DeleteAllowed={isDeleteAllowed}
                  />
                </div>
                {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localModelComponentTypeLength > initialLimit)) && (
                  <Row className={s.topRow}>
                    <Col>
                      <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                        {!showAll
                          ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localModelComponentTypeLength})`
                          : showLessText}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

ModelComponentTypeList.defaultProps = {
  ...initialModelComponentTypeState
};

ModelComponentTypeList.propTypes = {
  modelComponentType: PropTypes.object.isRequired,
  userProfileWidgets: PropTypes.object.isRequired,
  getModelComponentType: PropTypes.func.isRequired,
  saveModelComponentType: PropTypes.func.isRequired,
  setModelComponentTypeChange: PropTypes.func.isRequired
};

export default injectIntl(ModelComponentTypeList);
