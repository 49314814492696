import React from 'react';
import { Link } from 'react-router-dom';
import s from './NoRoute.module.scss';

const NoRoute = () => (
  <div className={s.noRoute}>
    <h1>Page not found</h1>
    <Link to={'/'}>Click here to return home</Link>
  </div>
);
export default NoRoute;
