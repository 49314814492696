import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChecklistTaskTemplate from './ChecklistTasksTemplates';
import { getTaskTemplateManager, getUserProfileWidget, getChecklistTemplate } from '../../selectors/index';
import { getTaskTemplateThunk } from '../../actions/taskTemplates';
import { getChecklistTemplateThunk } from '../../actions/checklistTemplates';

const mapStateToProps = state => {
  return {
    taskTemplateManager: getTaskTemplateManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    checklistTemplate: getChecklistTemplate(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTaskTemplates: () => getTaskTemplateThunk('TASKTEMPLATELIST'),
      getChecklistTemplate: () => getChecklistTemplateThunk('CHECKLISTTEMPLATELIST')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistTaskTemplate);
