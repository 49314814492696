import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './UnitTagOutHistory.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import UnitTagOutHistoryTable from './UnitTagOutHistoryTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import MaintenanceContentTemplate from '../Maintenance/MaintenanceContentTemplate';
import { getUnitTagHistory, getCurrentTimezone } from '../../selectors/index';
import moment from 'moment-timezone';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';

import {
  getUnitTagHistoryThunk,
  getUnitTagHistoryFilterThunk,
  setUnitTagHistoryFilteredDataThunk,
  setCurrentPage,
  setPageFilter
} from '../../actions/unitTagHistory';

export const getUnitTagOutStatus = intl => {
  let status = [];
  const all = intl.formatMessage({ id: 'maintenance.tagoutHistory.statusAll', defaultMessage: 'All Tags' });
  const tagouts = intl.formatMessage({ id: 'maintenance.tagoutHistory.statusTaggedOut', defaultMessage: 'Tag Out' });
  const tagins = intl.formatMessage({ id: 'maintenance.tagoutHistory.statusTaggedIn', defaultMessage: 'Tag In' });

  status.push({ text: all, value: -1 });
  status.push({ text: tagouts, value: 1 });
  status.push({ text: tagins, value: 0 });
  return status;
};

const STATUS = 'STATUS';
const UNIT = 'UNIT';
const TAGGEDUSER = 'TAGGEDUSER';
const TAGGED_OUT_FROM = 'TAGGED_OUT_FROM';
const TAGGED_IN_FROM = 'TAGGED_IN_FROM';

const DAY_TIME = 86399;

const UnitTagOutHistory = ({ intl }) => {
  const dispatch = useDispatch();
  const unitTagOutHistory = useSelector(state => getUnitTagHistory(state));
  const unitTagOutStatus = getUnitTagOutStatus(intl);
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  const [filterUnitId, setFilterUnitId] = useState(0);
  const [filterTagOutUser, setFilterTagOutUser] = useState(0);
  const [filterTagOutFrom, setFilterTagOutFrom] = useState(null);
  const [filterTagInFrom, setFilterTagInFrom] = useState(null);
  const [filterIsTaggedOut, setFilterIsTaggedOut] = useState(-1);
  const [asendingOrder, setAsendingOrder] = useState(false);

  let offset = unitTagOutHistory.offset || 0;
  let limit = unitTagOutHistory.limit || 1000;

  let filterRedUnitId = unitTagOutHistory.filterBy.filterUnitId || null;
  let filterRedTagOutUser = unitTagOutHistory.filterBy.filterTagOutUser || null;
  let filterRedTagOutFrom = unitTagOutHistory.filterBy.filterTagOutFrom || null;
  let filterRedTagInFrom = unitTagOutHistory.filterBy.filterTagInFrom || null;
  let filterRedIsTaggedOut = unitTagOutHistory.filterBy.filterIsTaggedOut;

  useEffect(() => {
    dispatch(getUnitTagHistoryFilterThunk('TAGOUT_TAB'));
  }, [getUnitTagHistoryFilterThunk]);

  useEffect(() => {
    let filterObj = {
      filterUnitId: filterRedUnitId,
      filterTagOutUser: filterRedTagOutUser,
      filterTagOutFrom: filterRedTagOutFrom,
      filterTagInFrom: filterRedTagInFrom,
      filterIsTaggedOut: filterRedIsTaggedOut === -1 ? null : filterRedIsTaggedOut,
      offset: offset,
      limit: limit
    };
    dispatch(getUnitTagHistoryThunk(filterObj, 'TAGOUT_TAB'));
  }, [
    getUnitTagHistoryThunk,
    offset,
    limit,
    filterRedIsTaggedOut,
    filterRedTagInFrom,
    filterRedTagOutFrom,
    filterRedTagOutUser,
    filterRedUnitId
  ]);

  let messageId = (unitTagOutHistory && unitTagOutHistory.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const placeHolderTextTaggedOutDate = intl.formatMessage({
    id: 'maintenance.tagoutHistory.tagoutDate',
    defaultMessage: 'Tagged Out Date'
  });
  const placeHolderTextTaggedInDate = intl.formatMessage({ id: 'maintenance.tagoutHistory.taginDate', defaultMessage: 'Tagged In Date' });

  const statusOptions = () => {
    let statusData = [];

    unitTagOutStatus.forEach(element => {
      statusData.push({
        label: element.text,
        value: element.value
      });
    });
    return statusData;
  };

  const unitOptions = () => {
    let unitData = [];

    unitData.push({
      label: <FormattedMessage id="maintenance.tagoutHistory.allUnits" defaultMessage="All Units" />,
      value: 0
    });

    unitTagOutHistory.filterData.Units.forEach(element => {
      unitData.push({
        label: element.Name,
        value: element.UnitId
      });
    });
    return unitData;
  };

  const taggedOutUserOptions = () => {
    let assigneeData = [];

    assigneeData.push({
      label: <FormattedMessage id="maintenance.tagoutHistory.allTaggedOutUsers" defaultMessage="All Tagged Out Users" />,
      value: 0
    });

    unitTagOutHistory.filterData.Users.forEach(element => {
      assigneeData.push({
        label: element.FullName,
        value: element.UserId
      });
    });
    return assigneeData;
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const onUnitChange = e => {
    setFilterUnitId(e.value);
    filteredResults(UNIT, e.value);
    setCurrentPage(1);
  };

  const onTagoutUserChange = e => {
    setFilterTagOutUser(e.value);
    filteredResults(TAGGEDUSER, e.value);
    setCurrentPage(1);
  };

  const onStatusChange = e => {
    setFilterIsTaggedOut(e.value);
    filteredResults(STATUS, e.value);
    setCurrentPage(1);
  };

  const onTagoutDateChange = value => {
    if (value !== null && value > 0) {
      setFilterTagOutFrom(value);
    } else setFilterTagOutFrom(null);

    filteredResults(TAGGED_OUT_FROM, value);
    setCurrentPage(1);
  };

  const onTagInDateChange = value => {
    if (value !== null && value > 0) {
      setFilterTagInFrom(value);
    } else setFilterTagInFrom(null);
    filteredResults(TAGGED_IN_FROM, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = unitTagOutHistory.unitTagHistoryList;

    let apifilterObj = {
      filterUnitId: null,
      filterTagOutUser: null,
      filterTagOutFrom: null,
      filterTagInFrom: null,
      filterIsTaggedOut: -1
    };

    if (filterType === STATUS) {
      if (value !== -1) {
        apifilterObj.filterIsTaggedOut = value;
        filteredList = filteredList.filter(function(item) {
          return item.IsTaggedOut === value;
        });
      }
    } else {
      if (filterIsTaggedOut !== -1) {
        apifilterObj.filterIsTaggedOut = filterIsTaggedOut;
        filteredList = filteredList.filter(function(item) {
          return item.IsTaggedOut === filterIsTaggedOut;
        });
      }
    }

    if (filterType === UNIT) {
      if (value !== 0) {
        apifilterObj.filterUnitId = value;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === value;
        });
      }
    } else {
      if (filterUnitId !== 0) {
        apifilterObj.filterUnitId = filterUnitId;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === filterUnitId;
        });
      }
    }

    if (filterType === TAGGEDUSER) {
      if (value !== 0) {
        apifilterObj.filterTagOutUser = value;
        filteredList = filteredList.filter(function(item) {
          return item.TagOutByUserId === value;
        });
      }
    } else {
      if (filterTagOutUser !== 0) {
        apifilterObj.filterTagOutUser = filterTagOutUser;
        filteredList = filteredList.filter(function(item) {
          return item.TagOutByUserId === filterTagOutUser;
        });
      }
    }

    if (filterType === TAGGED_OUT_FROM) {
      if (value !== null && value !== '') {
        const timestampValue = value / 1000;
        const timestampToValue = timestampValue + DAY_TIME;
        apifilterObj.filterTagOutFrom = timestampValue;
        filteredList = filteredList.filter(function(item) {
          return item.TagOutDateTime >= timestampValue && item.TagOutDateTime <= timestampToValue;
        });
      }
    } else {
      if (filterTagOutFrom !== null && filterTagOutFrom !== '') {
        const timestampValue = filterTagOutFrom / 1000;
        const timestampToValue = timestampValue + DAY_TIME;
        apifilterObj.filterTagOutFrom = timestampValue;
        filteredList = filteredList.filter(function(item) {
          return item.TagOutDateTime >= timestampValue && item.TagOutDateTime <= timestampToValue;
        });
      }
    }

    if (filterType === TAGGED_IN_FROM) {
      if (value !== null && value !== '') {
        const timestampValue = value / 1000;
        const timestampToValue = timestampValue + DAY_TIME;
        apifilterObj.filterTagInFrom = timestampValue;
        filteredList = filteredList.filter(function(item) {
          return item.TagInDateTime >= timestampValue && item.TagInDateTime <= timestampToValue;
        });
      }
    } else {
      if (filterTagInFrom !== null && filterTagOutFrom !== '') {
        const timestampValue = filterTagInFrom / 1000;
        const timestampToValue = timestampValue + DAY_TIME;
        apifilterObj.filterTagInFrom = timestampValue;
        filteredList = filteredList.filter(function(item) {
          return item.TagInDateTime >= timestampValue && item.TagInDateTime <= timestampToValue;
        });
      }
    }

    if (unitTagOutHistory.unitTagHistoryListCount > unitTagOutHistory.unitTagHistoryList.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setUnitTagHistoryFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let unitTagHistoryObj = Object.values(unitTagOutHistory.filteredResults);

  if (asendingOrder) {
    unitTagHistoryObj = unitTagHistoryObj.sort(compareValues('TagOutDateTime'));
  } else {
    unitTagHistoryObj = unitTagHistoryObj.sort(compareValues('TagOutDateTime', 'desc'));
  }

  return (
    <div className={s.unitTagoutHistory}>
      {unitTagOutHistory.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={unitTagOutHistory.showBanner}
        status={unitTagOutHistory.isOpSuccessful}
        successText={messageText}
      />
      <MaintenanceContentTemplate selectedPage="tagoutHistory">
        <div className={s.contentWrapper}>
          <div className={s.tagoutHistoryHeader}>
            <div className={s.subHeading}>
              <FormattedMessage id="maintenance.tagoutHistory" defaultMessage="Tag Out History" />
              {/* <span className={s.circle}>{unitTagOutHistory.unitTagHistoryListCount}</span> */}
            </div>
          </div>
          <Row>
            <Col sm={12}>
              <span className={s.textHeading}>
                {' '}
                <FormattedMessage id="maintenance.tagoutHistory.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpUnitId"
                dataArray={unitOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="maintenance.tagoutHistory.unit" defaultMessage="Unit" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onUnitChange(e)}
                selectedOption={unitOptions().filter(option => option.value === filterUnitId)}
                disabled={false}
              />
            </Col>

            <Col sm={6} lg={2}>
              <Dropdown
                id="drpTaggedOutUser"
                dataArray={taggedOutUserOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="maintenance.tagoutHistory.tagoutUser" defaultMessage="Tagged Out By" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onTagoutUserChange(e)}
                selectedOption={taggedOutUserOptions().filter(option => option.value === filterTagOutUser)}
                disabled={false}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpStatusId"
                dataArray={statusOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="maintenance.tagoutHistory.taggoutStatus" defaultMessage="Tag Status" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onStatusChange(e)}
                selectedOption={statusOptions().filter(option => option.value === filterIsTaggedOut)}
                disabled={false}
              />
            </Col>
            <Col sm={6} lg={2}>
              <DateTimePicker
                className={s.datetimeInput}
                onChange={m => onTagoutDateChange(m.valueOf())}
                timeFormat={null}
                dateFormat="DD-MMM-YYYY"
                checkValue={filterTagOutFrom}
                value={moment(filterTagOutFrom).tz(currentTimezone)}
                defaultValue={moment(filterTagOutFrom).tz(currentTimezone)}
                placeHolderText={placeHolderTextTaggedOutDate}
                closeOnSelect
                showClear={true}
                onClear={m => onTagoutDateChange(null)}
              />
            </Col>
            <Col sm={6} lg={2}>
              <DateTimePicker
                className={s.datetimeInput}
                onChange={m => onTagInDateChange(m.valueOf())}
                timeFormat={null}
                dateFormat="DD-MMM-YYYY"
                checkValue={filterTagInFrom}
                value={moment(filterTagInFrom).tz(currentTimezone)}
                defaultValue={moment(filterTagInFrom).tz(currentTimezone)}
                placeHolderText={placeHolderTextTaggedInDate}
                closeOnSelect
                showClear={true}
                onClear={m => onTagInDateChange(null)}
              />
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={unitTagHistoryObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={unitTagOutHistory.currentPage}
            >
              <UnitTagOutHistoryTable currentTimezone={currentTimezone} onSortOrder={onSortOrder} asendingOrder={asendingOrder} />
            </Pagination>
          </div>
        </div>
      </MaintenanceContentTemplate>
    </div>
  );
};

export default injectIntl(UnitTagOutHistory);
