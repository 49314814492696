import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './UnitConfiguration.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialUnitConfigurationState, initialUnitManagerState, initialModelVersionState } from '../../reducers/initialState';
import { Redirect } from 'react-router-dom';
import ModelVersionGlobalSettingsContainer from '../ModelVersionGlobalSettings/ModelVersionGlobalSettingsContainer';
import ModelVersionThresholdsContainer from '../ModelVersionThresholds/ModelVersionThresholdsContainer';
import UnitConfigurationPartsContainer from '../UnitConfigurationParts/UnitConfigurationPartsContainer';
import UnitSettingsList from '../UnitConfigurationUnitSettings/UnitSettingsList';
import Banner from '../Banner/Banner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import { WidgetVisibility } from '../../utils/widgetManager';
import UnitModel from './UnitModel';
import uuid from 'uuid';

const UnitConfiguration = ({
  unitManager,
  unitConfiguration,
  modelVersionManager,
  userProfileWidgets,
  getModelComponentPositions,
  saveUnitDetails,
  getSelectedUserProfileWidgets,
  getOrganisationRegionByOrgId,
  getUnitManagerAndEngineers,
  currentTimezone,
  intl
}) => {
  const ModelVersionId = unitManager.selectedUnit.ModelVersionId;
  const UnitId = unitManager.selectedUnit.UnitId;
  const ModelId = unitManager.selectedUnit.ModelId;
  const modelComponentPositions = unitManager.selectedUnit.unitComponentPositions.filter(x => !x.IsVirtual);
  const unitSettings = unitManager.unitSetting.unitSettings || [];

  const [unitModelOpen, setUnitModelOpen] = useState(true);
  const [globalSettingsOpen, setGlobalSettingsOpen] = useState(false);
  const [unitSettingsOpen, setUnitSettingsOpen] = useState(false);
  const [thresholdsOpen, setThresholdsOpen] = useState(false);
  const [partsOpen, setPartsOpen] = useState(false);

  let redirect = false;

  useEffect(() => {
    getModelComponentPositions(ModelId, false, null);
  }, [getModelComponentPositions, ModelId]);

  let messageId = (unitConfiguration && unitConfiguration.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <div className={s.unitConfiguration}>
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={unitConfiguration.showBanner}
        status={unitConfiguration.isOpSuccessful}
        successText={messageText}
      />
      {redirect ? <Redirect to="/unit" /> : ''}
      <ContentTemplate
        selectedPage="unitConfiguration"
        userProfileWidgets={userProfileWidgets}
        widgetCode={'UNIT_CONFIGURATION'}
        tagoutRequired={false}
      >
        <div className={s.contentWrapper}>
          <div className={s.modelversionContent}>
            <div className={`${s.titleRows}`}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={unitModelOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setUnitModelOpen(!unitModelOpen)}
                      aria-controls="unit-model-section"
                      aria-expanded={unitModelOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="unitConfiguration.unitModel" defaultMessage="Unit Model" />
                    </h4>
                  </Row>
                </Col>
              </Row>
              <Collapse in={unitModelOpen}>
                <div id="unit-model-section">
                  <UnitModel
                    getSelectedUserProfileWidgets={getSelectedUserProfileWidgets}
                    getOrganisationRegionByOrgId={getOrganisationRegionByOrgId}
                    getUnitManagerAndEngineers={getUnitManagerAndEngineers}
                    userProfileWidgets={userProfileWidgets}
                    unitManager={unitManager}
                    intl={intl}
                    currentTimezone={currentTimezone}
                    onSave={saveUnitDetails}
                  />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'UNIT_SETTINGS_LIST')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={unitSettingsOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setUnitSettingsOpen(!unitSettingsOpen)}
                      aria-controls="unit-settings-section"
                      aria-expanded={unitSettingsOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="unitsettings.unitsettings" defaultMessage="Unit Settings" />
                    </h4>
                    <span className={s.circle}>{unitSettings.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={unitSettingsOpen}>
                <div className={s.innerComponent} id="unit-settings-section">
                  <UnitSettingsList />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONGLOBALSETTINGS')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={globalSettingsOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setGlobalSettingsOpen(!globalSettingsOpen)}
                      aria-controls="global-settings-section"
                      aria-expanded={globalSettingsOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="modelVersion.globalSettings" defaultMessage="Global Settings" />
                    </h4>
                    <span className={s.circle}>{modelVersionManager.selectedModelVersion.GlobalSettings.GlobalSettingsList.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={globalSettingsOpen}>
                <div className={s.innerComponent} id="global-settings-section">
                  <ModelVersionGlobalSettingsContainer
                    ReadOnly={true}
                    ModelVersionId={ModelVersionId}
                    userProfileWidgets={userProfileWidgets}
                  />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONTHRESHOLDS')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={thresholdsOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setThresholdsOpen(!thresholdsOpen)}
                      aria-controls="thresholds-section"
                      aria-expanded={thresholdsOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="modelVersion.threasholds" defaultMessage="Thresholds" />
                    </h4>
                    <span className={s.circle}>{modelVersionManager.selectedModelVersion.Thresholds.ThresholdsList.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={thresholdsOpen}>
                <div className={s.innerComponent} id="thresholds-section">
                  <ModelVersionThresholdsContainer
                    ReadOnly={true}
                    ModelVersionId={ModelVersionId}
                    userProfileWidgets={userProfileWidgets}
                  />
                </div>
              </Collapse>
            </div>
            <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'UNIT_PARTS')}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={partsOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setPartsOpen(!partsOpen)}
                      aria-controls="parts-section"
                      aria-expanded={partsOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="componentParts.parts" defaultMessage="Parts" />
                    </h4>
                    <span className={s.circle}>
                      {unitConfiguration &&
                        unitConfiguration.componentParts &&
                        unitConfiguration.componentParts.componentPartsList &&
                        unitConfiguration.componentParts.componentPartsList.length}
                    </span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={partsOpen}>
                <div className={s.innerComponent} id="parts-section">
                  <UnitConfigurationPartsContainer
                    UnitId={UnitId}
                    userProfileWidgets={userProfileWidgets}
                    modelComponentPositions={modelComponentPositions}
                  ></UnitConfigurationPartsContainer>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </ContentTemplate>
    </div>
  );
};

UnitConfiguration.defaultProps = {
  unitConfiguration: {
    ...initialUnitConfigurationState.unitConfiguration
  },
  unitManager: {
    ...initialUnitManagerState.unitManager
  },
  modelVersionManager: {
    ...initialModelVersionState.modelVersionManager
  }
};

UnitConfiguration.propTypes = {
  unitConfiguration: PropTypes.object.isRequired,
  unitManager: PropTypes.object.isRequired,
  userProfileWidgets: PropTypes.object.isRequired,
  modelVersionManager: PropTypes.object.isRequired,
  getModelComponentPositions: PropTypes.func.isRequired
};

export default injectIntl(UnitConfiguration);
