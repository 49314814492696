import Cookies from 'js-cookie';
import { apiAction } from '../utils/index';

import {
  GET_ALL_ALARMS_DETAILS,
  ALARMS_IS_LOADING,
  ALARMS_ERROR,
  GET_ALL_ALARMS_FILTERS,
  SET_ALARMS_CURRENT_PAGE,
  SET_ALARMS_FILTERED_RESULTS,
  SET_ALARMS_PAGE_FILTER
} from '../constants/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setAlarmErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setAlarmError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setAlarmError(messageCode, false));
  }, 2500);
};

export const setAlarmError = (messageCode, status) => {
  return {
    type: ALARMS_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getAlarmsThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getAlarm(filterObj, widgetCode));
};

export const setLoadingStatus = isLoading => ({
  type: ALARMS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getAlarm = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/operation/alarms`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter,
      FilterEventCode: filterObj.filterEventCode,
      FilterSensor: filterObj.ilterSensor,
      FilterStatus: filterObj.filterStatus,
      FilterUnit: filterObj.filterUnit,
      FilterAssignee: filterObj.filterAssignee
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllAlarmListThunk(retData),
    onFailure: err => setAlarmErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllAlarmListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let eventActions = (retData && retData.eventActions) || [];
  dispatch(setAllAlarmList(messageCode, eventActions));
};

export const setAllAlarmList = (messageCode, eventActions) => ({
  type: GET_ALL_ALARMS_DETAILS,
  payload: {
    data: {
      alarmsList: eventActions,
      filteredResults: eventActions,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const getAlarmsFilterThunk = widgetCode => dispatch => {
  dispatch(getAlarmFilter(widgetCode));
};

export const getAlarmFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      fEventCodes: true,
      fSensor: true,
      fUnits: true,
      fAssignees: true,
      filterFor: 'alarms'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllAlarmFilterThunk(retData),
    onFailure: err => setAlarmErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllAlarmFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;

  dispatch(setAllAlarmFilter(messageCode, filterData, itemCount));
};

export const setAllAlarmFilter = (messageCode, eventActionFilter, eventActionCount) => ({
  type: GET_ALL_ALARMS_FILTERS,
  payload: {
    data: {
      filterData: eventActionFilter,
      alarmListCount: eventActionCount,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setAlarmFilteredDataThunk = retData => dispatch => {
  dispatch(setAlarmFilteredData(retData));
};

export const setAlarmFilteredData = retData => ({
  type: SET_ALARMS_FILTERED_RESULTS,
  payload: retData
});

export const setCurrentPage = currentPage => ({
  type: SET_ALARMS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_ALARMS_PAGE_FILTER,
  payload: { filter }
});
