import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ComponentPart from './ComponentPart';
import {
  getComponentParts,
  getOrganisationManager,
  getUserProfileUnit,
  getApprovedComponent,
  getAllModelComponentPositions,
  getUnitConfiguration,
  getModelVersionManager
} from '../../selectors/index';
import { describeComponentPartThunk, saveComponentPartThunk, setComponentPartChangeThunk } from '../../actions/componentParts';
import { getOrganisationsThunk } from '../../actions/organisationManager';
import { getApprovedComponentThunk } from '../../actions/approvedComponents';
import { getComponentPartsByUnitThunk } from '../../actions/unitConfiguration';
import { getModelComponentPositionsThunk } from '../../actions/modelVersion';
import { getSelectedUserProfileWidgetsThunk } from '../../actions/UserProfileWidgets';

const mapStateToProps = state => {
  return {
    componentParts: getComponentParts(state),
    organisationManager: getOrganisationManager(state),
    allowedUnits: getUserProfileUnit(state),
    approvedComponents: getApprovedComponent(state),
    allModelComponentPositions: getAllModelComponentPositions(state),
    unitConfiguration: getUnitConfiguration(state),
    modelVersionManager: getModelVersionManager(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeComponentPart: componentPartId => describeComponentPartThunk(componentPartId, 'PARTSEDIT'),
      saveComponentPart: (componentPart, widgetCode) => saveComponentPartThunk(componentPart, widgetCode),
      changeComponentPart: componentPart => setComponentPartChangeThunk(componentPart),
      getOrganisations: (offset, limit, filter, widgetCode) => getOrganisationsThunk(offset, limit, filter, widgetCode),
      getApprovedComponents: (filterObj, widgetCode) => getApprovedComponentThunk(filterObj, widgetCode),
      getComponentPartsByUnit: unitId => getComponentPartsByUnitThunk(unitId, 'PARTSEDIT'),
      getModelComponentPositions: (modelId, onlyVirtual, telemetryComponent, widgetCode) =>
        getModelComponentPositionsThunk(modelId, onlyVirtual, telemetryComponent, widgetCode),
      getSelectedUserProfileWidgets: (profileId, userName) => getSelectedUserProfileWidgetsThunk(profileId, userName, 'GENERALACCESS')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ComponentPart);
