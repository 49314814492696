import React from 'react';
import PropTypes from 'prop-types';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const AdminControls = ({ userProfileWidgets }) => (
  <div>
    {IsWidgetAccessible(userProfileWidgets, 'ORGANISATION_TAB') ? (
      <Redirect to="/admin-controls/organisation-list" />
    ) : IsWidgetAccessible(userProfileWidgets, 'USERS_TAB') ? (
      <Redirect to="/admin-controls/user-list" />
    ) : IsWidgetAccessible(userProfileWidgets, 'PROFILES_TAB') ? (
      <Redirect to="/admin-controls/profile-list" />
    ) : IsWidgetAccessible(userProfileWidgets, 'MODELS_TAB') ? (
      <Redirect to="/admin-controls/model-list" />
    ) : IsWidgetAccessible(userProfileWidgets, 'CALIBRATIONS_TAB') ? (
      <Redirect to="/admin-controls/calibration-equation-list" />
    ) : IsWidgetAccessible(userProfileWidgets, 'TASKSCHECKLISTS_TAB') ? (
      <Redirect to="/admin-controls/checklist-task-templates" />
    ) : IsWidgetAccessible(userProfileWidgets, 'GLOBALSETTINGS_TAB') ? (
      <Redirect to="/admin-controls/general-global-settings" />
    ) : IsWidgetAccessible(userProfileWidgets, 'EVENTCODES_TAB') ? (
      <Redirect to="/admin-controls/event-code-list" />
    ) : IsWidgetAccessible(userProfileWidgets, 'NOTIFICATIONS_TAB') ? (
      <Redirect to="/admin-controls/notifications" />
    ) : IsWidgetAccessible(userProfileWidgets, 'FAQ_TAB') ? (
      <Redirect to="/admin-controls/faqs" />
    ) : IsWidgetAccessible(userProfileWidgets, 'RELEASENOTELIST') ? (
      <Redirect to="/admin-controls/release-notes" />
    ) : (
      ''
    )}
    <span>
      <FormattedMessage
        id="AdminControls.NoAccess"
        defaultMessage="You do not have access to Admin Controls. Please contact your administrator."
      />
    </span>
  </div>
);

AdminControls.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired
};

export default AdminControls;
