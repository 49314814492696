import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './TelemetryPartsList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import TelemetryPartsListTable from './TelemetryPartsListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues, downloadCsvFile, flattenObject } from '../../utils/';
import MaintenanceContentTemplate from '../Maintenance/MaintenanceContentTemplate';
import moment from 'moment-timezone';
import { getUserProfileWidget, getTelemetryParts, getCurrentTimezone, getTelemetryTypes } from '../../selectors/index';
import { initialTelemetryPartsState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import { IsWidgetAccessible } from '../../utils/widgetManager';

import {
  getTelemetryPartsThunk,
  getTelemetryPartsFilterThunk,
  setTelemetryPartsFilteredDataThunk,
  setCurrentPage,
  setPageFilter,
  cleanUpThunk,
  getExportDataThunk,
  setExportCompleteThunk,
  setTelemetryPartsExportErrorThunk
} from '../../actions/telemetryParts';

const UNIT = 'UNIT';
const BRAND = 'BRAND';
const MODEL = 'MODEL';
const COMPONENT_TYPE = 'COMPONENT_TYPE';
const POSITION = 'POSITION';
const TELEMETRY_TYPE = 'TELEMETRY_TYPE';
const STATUS = 'STATUS';

const TelemetryPartsList = ({ intl }) => {
  const dispatch = useDispatch();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const telemetryParts = useSelector(state => getTelemetryParts(state));

  const telemetryPartsTypes = getTelemetryTypes();

  //clean up
  useEffect(() => {
    return () => {
      dispatch(cleanUpThunk({ ...initialTelemetryPartsState }));
    };
  }, []);

  const [filterUnitId, setFilterUnitId] = useState(0);
  const [filterBrand, setFilterBrand] = useState(0);
  const [filterModel, setFilterModel] = useState(0);
  const [filterComponentTypeId, setFilterComponentTypeId] = useState(0);
  const [filterComponentPosition, setFilterComponentPosition] = useState(0);
  const [filterTelemetryType, setFilterTelemetryType] = useState(-1);
  const [filterStatus, setFilterStatus] = useState(0);

  const [asendingOrder, setAsendingOrder] = useState(true);
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  let offset = telemetryParts.offset || 0;
  let limit = telemetryParts.limit || 1000;

  let filterLocalUnitId = telemetryParts.filterBy.filterUnitId || null;
  let filterLocalBrand = telemetryParts.filterBy.filterBrand || null;
  let filterLocalModel = telemetryParts.filterBy.filterModel || null;
  let filterLocalComponentTypeId = telemetryParts.filterBy.filterComponentTypeId || null;
  let filterLocalComponentPosition = telemetryParts.filterBy.filterComponentPosition || null;
  let filterLocalTelemetryType =
    telemetryParts.filterBy.filterTelemetryType || telemetryParts.filterBy.filterTelemetryType === 0
      ? telemetryParts.filterBy.filterTelemetryType
      : null;
  let filterLocalStatus = telemetryParts.filterBy.filterStatus || null;
  let exportComplete = telemetryParts.exportComplete || false;
  let isOpSuccessful = telemetryParts.isOpSuccessful || false;
  let exportedResults = telemetryParts.exportedResults || [];
  let disableControls = telemetryParts.isLoading || false;

  useEffect(() => {
    dispatch(getTelemetryPartsFilterThunk('TELEMETRY_TAB'));
  }, [dispatch, getTelemetryPartsFilterThunk]);

  useEffect(() => {
    let filterObj = {
      filterBrand: filterLocalBrand,
      filterModel: filterLocalModel,
      filterUnitId: filterLocalUnitId,
      filterComponentTypeId: filterLocalComponentTypeId,
      filterComponentPosition: filterLocalComponentPosition,
      filterTelemetryType: filterLocalTelemetryType,
      filterStatus: filterLocalStatus,
      offset: offset,
      limit: limit
    };
    dispatch(getTelemetryPartsThunk(filterObj, 'TELEMETRY_TAB'));
  }, [
    getTelemetryPartsThunk,
    offset,
    limit,
    filterLocalBrand,
    filterLocalModel,
    filterLocalUnitId,
    filterLocalComponentTypeId,
    filterLocalComponentPosition,
    filterLocalTelemetryType,
    filterLocalStatus
  ]);

  //export check
  useEffect(() => {
    if (exportComplete && isOpSuccessful) {
      computeExportData();
    }
  }, [setExportCompleteThunk, exportComplete, isOpSuccessful]);

  let messageId = (telemetryParts && telemetryParts.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const noDataText = intl.formatMessage({ id: 'maintenance.telemetryParts.noData', defaultMessage: 'No data is available' });
  const isExportAllowed = IsWidgetAccessible(userProfileWidgets, 'PARTS_TELEMETRY_EXPORT');

  const unitOptions = () => {
    let unitData = [];

    unitData.push({
      label: <FormattedMessage id="filters.allUnits" defaultMessage="All Units" />,
      value: 0
    });

    telemetryParts.filterData.Units.forEach(element => {
      unitData.push({
        label: element.Name,
        value: element.UnitId
      });
    });
    return unitData;
  };

  const brandOptions = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="filters.allBrands" defaultMessage="All Brands" />,
      value: 0
    });

    telemetryParts.filterData.Brands.forEach(element => {
      itemData.push({
        label: element.Brand,
        value: element.Brand
      });
    });
    return itemData;
  };

  const modelOptions = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="filters.allModels" defaultMessage="All Models" />,
      value: 0
    });

    telemetryParts.filterData.Models.forEach(element => {
      itemData.push({
        label: element.Model,
        value: element.Model
      });
    });
    return itemData;
  };

  const componentTypesOptions = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="filters.allComponentTypes" defaultMessage="All Component Types" />,
      value: 0
    });

    telemetryParts.filterData.ComponentTypes.forEach(element => {
      itemData.push({
        label: element.Name,
        value: element.ModelComponentTypeId
      });
    });
    return itemData;
  };

  const componentPositionsOptions = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="filters.allpositions" defaultMessage="All Positions" />,
      value: 0
    });
    const filteredData = telemetryParts.filterData.Positions.filter(x => !x.IsDerived);

    filteredData.forEach(element => {
      itemData.push({
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return itemData;
  };

  const telemetryTypesOptions = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="filters.allTelemetryTypes" defaultMessage="All Types" />,
      value: -1
    });

    telemetryPartsTypes.forEach(element => {
      itemData.push({
        label: element.text,
        value: element.value
      });
    });
    return itemData;
  };

  const statusOptions = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="filters.allstatus" defaultMessage="All Statuses" />,
      value: 0
    });
    itemData.push({ label: 'Active', value: 'Active' });
    itemData.push({ label: 'Past', value: 'Past' });
    return itemData;
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const onUnitChange = e => {
    setFilterUnitId(e.value);
    filteredResults(UNIT, e.value);
    setCurrentPage(1);
  };

  const onBrandChange = e => {
    setFilterBrand(e.value);
    filteredResults(BRAND, e.value);
    setCurrentPage(1);
  };

  const onModelChange = e => {
    setFilterModel(e.value);
    filteredResults(MODEL, e.value);
    setCurrentPage(1);
  };

  const onComponentTypeChange = e => {
    setFilterComponentTypeId(e.value);
    filteredResults(COMPONENT_TYPE, e.value);
    setCurrentPage(1);
  };

  const onPositionChange = e => {
    setFilterComponentPosition(e.value);
    filteredResults(POSITION, e.value);
    setCurrentPage(1);
  };

  const onTelemetryTypeChange = e => {
    setFilterTelemetryType(e.value);
    filteredResults(TELEMETRY_TYPE, e.value);
    setCurrentPage(1);
  };

  const onstatusChange = e => {
    setFilterStatus(e.value);
    filteredResults(STATUS, e.value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = telemetryParts.telemetryParts;

    let apifilterObj = {
      filterBrand: null,
      filterModel: null,
      filterUnitId: null,
      filterComponentTypeId: null,
      filterComponentPosition: null,
      filterTelemetryType: null,
      filterStatus: null
    };

    if (filterType === UNIT) {
      if (value !== 0) {
        apifilterObj.filterUnitId = value;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === value;
        });
      }
    } else {
      if (filterUnitId !== 0) {
        apifilterObj.filterUnitId = filterUnitId;
        filteredList = filteredList.filter(function(item) {
          return item.UnitId === filterUnitId;
        });
      }
    }

    if (filterType === BRAND) {
      if (value !== 0) {
        apifilterObj.filterBrand = value;
        filteredList = filteredList.filter(function(item) {
          return item.Brand === value;
        });
      }
    } else {
      if (filterBrand !== 0) {
        apifilterObj.filterBrand = filterBrand;
        filteredList = filteredList.filter(function(item) {
          return item.Brand === filterBrand;
        });
      }
    }

    if (filterType === MODEL) {
      if (value !== 0) {
        apifilterObj.filterModel = value;
        filteredList = filteredList.filter(function(item) {
          return item.Model === value;
        });
      }
    } else {
      if (filterModel !== 0) {
        apifilterObj.filterModel = filterModel;
        filteredList = filteredList.filter(function(item) {
          return item.Model === filterModel;
        });
      }
    }

    if (filterType === COMPONENT_TYPE) {
      if (value !== 0) {
        apifilterObj.filterComponentTypeId = value;
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentTypeId === value;
        });
      }
    } else {
      if (filterComponentTypeId !== 0) {
        apifilterObj.filterComponentTypeId = filterComponentTypeId;
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentTypeId === filterComponentTypeId;
        });
      }
    }

    if (filterType === POSITION) {
      if (value !== 0) {
        apifilterObj.filterComponentPosition = value;
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentPositionId === value;
        });
      }
    } else {
      if (filterComponentPosition !== 0) {
        apifilterObj.filterComponentPosition = filterComponentPosition;
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentPositionId === filterComponentPosition;
        });
      }
    }

    if (filterType === TELEMETRY_TYPE) {
      if (value !== -1) {
        apifilterObj.filterTelemetryType = value;
        filteredList = filteredList.filter(function(item) {
          return item.TelemetryType === value;
        });
      }
    } else {
      if (filterTelemetryType !== -1) {
        apifilterObj.filterTelemetryType = filterTelemetryType;
        filteredList = filteredList.filter(function(item) {
          return item.TelemetryType === filterTelemetryType;
        });
      }
    }

    if (filterType === STATUS) {
      if (value !== 0) {
        apifilterObj.filterStatus = value;
        filteredList = filteredList.filter(function(item) {
          return item.Status === value;
        });
      }
    } else {
      if (filterStatus !== 0) {
        apifilterObj.filterStatus = filterStatus;
        filteredList = filteredList.filter(function(item) {
          return item.Status === filterStatus;
        });
      }
    }

    if (telemetryParts.telemetryPartListCount > telemetryParts.telemetryParts.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setTelemetryPartsFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let telemetryPartsObj = Object.values(telemetryParts.filteredResults);

  if (asendingOrder) {
    telemetryPartsObj = telemetryPartsObj.sort(compareValues('Brand'));
  } else {
    telemetryPartsObj = telemetryPartsObj.sort(compareValues('Brand', 'desc'));
  }
  const getTypeName = val => {
    return telemetryPartsTypes.find(x => x.value === val).text;
  };
  //Export Code
  const exportReportHandler = () => {
    const requireApiFilter = telemetryParts.telemetryPartListCount > telemetryParts.telemetryParts.length ? true : false;

    let filterObj = {
      filterUnitId: requireApiFilter ? filterLocalUnitId : filterUnitId ? filterUnitId : null,
      filterBrand: requireApiFilter ? filterLocalBrand : filterBrand ? filterBrand : null,
      filterModel: requireApiFilter ? filterLocalModel : filterModel ? filterModel : null,
      filterComponentTypeId: requireApiFilter ? filterLocalComponentTypeId : filterComponentTypeId ? filterComponentTypeId : null,
      filterComponentPosition: requireApiFilter ? filterLocalComponentPosition : filterComponentPosition ? filterComponentPosition : null,
      filterTelemetryType: requireApiFilter
        ? filterLocalTelemetryType
        : filterTelemetryType || filterTelemetryType === 0
        ? filterTelemetryType
        : null,
      filterStatus: requireApiFilter ? filterLocalStatus : filterStatus ? filterStatus : null,
      offset: offset,
      limit: limit
    };

    dispatch(getExportDataThunk(filterObj, 'TELEMETRY_TAB'));
  };

  const computeExportData = useCallback(() => {
    const computePromise = new Promise((resolve, reject) => {
      try {
        let exportFormattedData = exportedResults.map(item => {
          return {
            ...item,
            TelemetryType: getTypeName(item.TelemetryType)
          };
        });
        let convertToCSV = jsonToCsv([{ NoData: noDataText }]);

        if (exportFormattedData.length > 0) {
          convertToCSV = jsonToCsv(exportFormattedData);
        }
        downloadCsvFile(convertToCSV, `telemetryParts_${moment().unix()}.csv`);
        resolve(dispatch(setExportCompleteThunk()));
      } catch (error) {
        reject(dispatch(setTelemetryPartsExportErrorThunk('maintenance.telemetryParts.unableToExport')));
      }
    });

    return computePromise;
  }, [dispatch, exportedResults]);

  return (
    <div className={s.telemetryParts}>
      <MaintenanceContentTemplate selectedPage="telemetry">
        <div className={s.contentWrapper}>
          {telemetryParts.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={telemetryParts.showBanner}
            status={telemetryParts.isOpSuccessful}
            successText={messageText}
          />

          <Row>
            <Col sm={6} lg={6}>
              <div className={s.telemetryPartsHeader}>
                <div className={s.subHeading}>
                  <FormattedMessage id="maintenance.telemetryParts" defaultMessage="Telemetry Parts" />
                  {/* <span className={s.circle}>{telemetryParts.telemetryPartListCount}</span> */}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={6}>
              {isExportAllowed && (
                <Button className={s.export} onClick={exportReportHandler} disabled={disableControls}>
                  <FormattedMessage id="maintenance.telemetryParts.export" defaultMessage="Export" />
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                {' '}
                <FormattedMessage id="maintenance.telemetryParts.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpBrandId"
                dataArray={brandOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="maintenance.telemetryParts.brand" defaultMessage="Brand" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onBrandChange(e)}
                selectedOption={brandOptions().filter(option => option.value === filterBrand)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpModelId"
                dataArray={modelOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="maintenance.telemetryParts.model" defaultMessage="Model" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onModelChange(e)}
                selectedOption={modelOptions().filter(option => option.value === filterModel)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpUnitId"
                dataArray={unitOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="maintenance.telemetryParts.unit" defaultMessage="Unit" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onUnitChange(e)}
                selectedOption={unitOptions().filter(option => option.value === filterUnitId)}
                disabled={disableControls}
              />
            </Col>

            <Col sm={6} lg={2}>
              <Dropdown
                id="drpComponentTypeId"
                dataArray={componentTypesOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="maintenance.telemetryParts.componentType" defaultMessage="Component Type" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onComponentTypeChange(e)}
                selectedOption={componentTypesOptions().filter(option => option.value === filterComponentTypeId)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpPositionId"
                dataArray={componentPositionsOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="maintenance.telemetryParts.position" defaultMessage="Position" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onPositionChange(e)}
                selectedOption={componentPositionsOptions().filter(option => option.value === filterComponentPosition)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={1}>
              <Dropdown
                id="drptelemetryTypeId"
                dataArray={telemetryTypesOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="maintenance.telemetryParts.type" defaultMessage="Type" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onTelemetryTypeChange(e)}
                selectedOption={telemetryTypesOptions().filter(option => option.value === filterTelemetryType)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={1}>
              <Dropdown
                id="drpStatus"
                dataArray={statusOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="maintenance.telemetryParts.status" defaultMessage="Status" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onstatusChange(e)}
                selectedOption={statusOptions().filter(option => option.value === filterStatus)}
                disabled={disableControls}
              />
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={telemetryPartsObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={telemetryParts.currentPage}
            >
              <TelemetryPartsListTable telemetryPartsTypes={telemetryPartsTypes} onSortOrder={onSortOrder} asendingOrder={asendingOrder} />
            </Pagination>
          </div>
        </div>
      </MaintenanceContentTemplate>
    </div>
  );
};

export default injectIntl(TelemetryPartsList);

const jsonToCsv = items => {
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = Object.keys(flattenObject(items[0]));

  let csv = items.map(row => {
    let flattenedRow = flattenObject(row);
    return header.map(fieldName => JSON.stringify(flattenedRow[fieldName], replacer)).join(',');
  });
  csv.unshift(header.join(','));
  return csv.join('\r\n');
};
