import {
  GET_ALL_MODEL_DETAILS,
  MODEL_IS_LOADING,
  UPDATE_MODEL_USER_SAVE_STATUS,
  MODEL_MANAGEMENT_ERROR,
  SET_MODEL_CURRENT_PAGE,
  SET_MODEL_PAGE_FILTER,
  SET_MODEL_DETAILS,
  SET_MODEL_DETAILS_DUPLICATE,
  SET_MODEL_CHANGE,
  SET_MODEL_FILTERED_RESULTS,
  MODEL_CLEANUP,
  GET_ALL_COMPONENT_POSITION_MODEL,
  COMPONENT_POSITION_MODEL_IS_LOADING,
  COMPONENT_POSITION_MODEL_MANAGEMENT_ERROR,
  UPDATE_COMPONENT_POSITION_MODEL_SAVE_STATUS,
  UPDATE_COMPONENT_POSITION_MODEL_SHOW_SCREEN,
  SET_COMPONENT_POSITION_MODEL_CHANGE,
  GET_ALL_MODELVERSION_MODEL,
  SET_DUPLICATE_MODEL,
  UPLOAD_UNIT_COMPONENT_STATUS
} from '../constants/index';
import { initialModelState } from './initialState';

const modelReducer = (state = initialModelState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_MODEL_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          ...data
        }
      };
    }

    case SET_MODEL_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          currentPage
        }
      };
    }

    case SET_MODEL_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          filter
        }
      };
    }

    case UPDATE_MODEL_USER_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          ...data
        }
      };
    }

    case MODEL_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          isLoading: isLoading
        }
      };
    }

    case MODEL_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          ...data
        }
      };
    }

    case SET_MODEL_DETAILS: {
      let { modelDetails } = payload;

      let modelData = modelDetails.length > 0 ? modelDetails[0] : state.modelManager.selectedModel;

      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          selectedModel: {
            ...state.modelManager.selectedModel,
            ...modelData
          }
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: true
      };
    }

    case SET_MODEL_CHANGE: {
      let { selectedModel } = payload;

      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          selectedModel: selectedModel
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: true
      };
    }

    case SET_MODEL_DETAILS_DUPLICATE: {
      let { modelDetails } = payload;

      let modelData = modelDetails.length > 0 ? modelDetails[0] : state.modelManager.selectedModel;
      modelData.ModelId = 0;
      modelData.ModelName = modelData.Name;
      modelData.UpdatedBy = '';
      modelData.GuidIdentifier = '';
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          selectedModel: {
            ...state.modelManager.selectedModel,
            ...modelData
          }
        },
        isLoading: false,
        showBanner: false,
        isOpSuccessful: true
      };
    }

    case SET_MODEL_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          ...filteredResults
        }
      };
    }

    case MODEL_CLEANUP: {
      const { data } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          ...data
        }
      };
    }

    // Component Positions

    case GET_ALL_COMPONENT_POSITION_MODEL: {
      const { data } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          selectedModel: {
            ...state.modelManager.selectedModel,
            ComponentPositionModel: {
              ...state.modelManager.selectedModel.ComponentPositionModel,
              ...data
            }
          }
        }
      };
    }

    case COMPONENT_POSITION_MODEL_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          selectedModel: {
            ...state.modelManager.selectedModel,
            ComponentPositionModel: {
              ...state.modelManager.selectedModel.ComponentPositionModel,
              isLoading: isLoading
            }
          }
        }
      };
    }

    case COMPONENT_POSITION_MODEL_MANAGEMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          selectedModel: {
            ...state.modelManager.selectedModel,
            ComponentPositionModel: {
              ...state.modelManager.selectedModel.ComponentPositionModel,
              ...data
            }
          }
        }
      };
    }

    case UPDATE_COMPONENT_POSITION_MODEL_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          selectedModel: {
            ...state.modelManager.selectedModel,
            ComponentPositionModel: {
              ...state.modelManager.selectedModel.ComponentPositionModel,
              ...data
            }
          }
        }
      };
    }

    case SET_COMPONENT_POSITION_MODEL_CHANGE: {
      let { selectedcomponentPositionModel } = payload;

      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          selectedModel: {
            ...state.modelManager.selectedModel,
            ComponentPositionModel: {
              ...state.modelManager.selectedModel.ComponentPositionModel,
              selectedcomponentPositionModel: {
                ...state.modelManager.selectedModel.ComponentPositionModel.selectedcomponentPositionModel,
                ...selectedcomponentPositionModel
              }
            }
          }
        }
      };
    }

    case UPDATE_COMPONENT_POSITION_MODEL_SHOW_SCREEN: {
      const { showAddEditScreen } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          selectedModel: {
            ...state.modelManager.selectedModel,
            ComponentPositionModel: {
              ...state.modelManager.selectedModel.ComponentPositionModel,
              showAddEditScreen: showAddEditScreen
            }
          }
        }
      };
    }

    case GET_ALL_MODELVERSION_MODEL: {
      const { data } = payload;

      data.ModelVersions.forEach(element => {
        element.RefModelVersionId = element.ModelVersionId;
        element.IsSelected = false;
        element.GlobalSettings = false;
        element.Thresholds = false;
      });

      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          isLoading: false,
          selectedModel: {
            ...state.modelManager.selectedModel,
            DuplicateModel: {
              ...state.modelManager.selectedModel.DuplicateModel,
              ...data
            }
          }
        }
      };
    }

    case SET_DUPLICATE_MODEL: {
      const { data } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          isLoading: false,
          selectedModel: {
            ...state.modelManager.selectedModel,
            ...data
          }
        }
      };
    }

    case UPLOAD_UNIT_COMPONENT_STATUS: {
      const { data } = payload;
      return {
        ...state,
        modelManager: {
          ...state.modelManager,
          selectedModel: {
            ...state.modelManager.selectedModel,
            ComponentPositionModel: {
              ...state.modelManager.selectedModel.ComponentPositionModel,
              ...data
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default modelReducer;
