import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  GET_ALL_MODEL_DETAILS,
  MODEL_IS_LOADING,
  UPDATE_MODEL_USER_SAVE_STATUS,
  MODEL_MANAGEMENT_ERROR,
  SET_MODEL_CURRENT_PAGE,
  SET_MODEL_PAGE_FILTER,
  SET_MODEL_DETAILS,
  SET_MODEL_DETAILS_DUPLICATE,
  SET_MODEL_CHANGE,
  SET_MODEL_FILTERED_RESULTS,
  MODEL_CLEANUP,
  GET_ALL_COMPONENT_POSITION_MODEL,
  GET_ALL_MODELVERSION_MODEL,
  COMPONENT_POSITION_MODEL_IS_LOADING,
  COMPONENT_POSITION_MODEL_MANAGEMENT_ERROR,
  UPDATE_COMPONENT_POSITION_MODEL_SAVE_STATUS,
  UPDATE_COMPONENT_POSITION_MODEL_SHOW_SCREEN,
  SET_COMPONENT_POSITION_MODEL_CHANGE,
  SET_DUPLICATE_MODEL,
  UPLOAD_UNIT_COMPONENT,
  UPLOAD_UNIT_COMPONENT_STATUS
} from '../constants/index';

import {
  MODEL_MANAGEMENT,
  UPDATE_MODEL,
  CREATE_MODEL,
  ADD_COMPONENT_POSITION_MODEL,
  DELETE_COMPONENT_POSITION_MODEL,
  UPLOAD_COMPONENT_TO_UNIT
} from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

//Model Management
export const setModelErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setModelError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelError(messageCode, false));
  }, 2500);
};

export const setModelError = (messageCode, status) => {
  return {
    type: MODEL_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getModelsThunk = (offset, limit, filter, widgetCode, organisationId) => dispatch => {
  dispatch(getModel(offset, limit, filter, widgetCode, organisationId));
};

export const setLoadingStatus = isLoading => ({
  type: MODEL_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getModel = (offset, limit, filter, widgetCode, organisationId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/models`,
    data: {
      Offset: offset,
      Limit: limit,
      Filter: filter,
      OrganisationId: organisationId ? organisationId : undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllModelListThunk(retData),
    onFailure: err => setModelErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllModelListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let models = (retData && retData.models) || [];
  dispatch(setAllModelList(messageCode, models));
};

export const setAllModelList = (messageCode, models) => ({
  type: GET_ALL_MODEL_DETAILS,
  payload: {
    data: {
      models: models,
      filteredResults: models,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_MODEL_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_MODEL_PAGE_FILTER,
  payload: { filter }
});

export const postModelThunk = (model, widgetCode) => dispatch => {
  let url = '/management/model/save';
  let actionName = model.ModelId === 0 ? CREATE_MODEL : UPDATE_MODEL;
  let log = logEntry(MODEL_MANAGEMENT, actionName, model);

  dispatch(saveModel(model, url, log, widgetCode));
};

export const saveModel = (model, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    widgetcode: widgetCode,
    onLoad: status => setLoadingStatus(status),
    onSuccess: message => setModelStatusThunk(message),
    onFailure: err => setModelErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      Model: model,
      log: log
    }
  });

export const setModelStatusThunk = message => dispatch => {
  dispatch(setModelStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelStatus('', false));
  }, 2500);
};

export const setModelStatus = (messageCode, status) => ({
  type: UPDATE_MODEL_USER_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const describeModelThunk = (modelId, isDuplicate, widgetCode) => dispatch => {
  dispatch(describeModel(modelId, isDuplicate, widgetCode));
};

export const describeModel = (modelId, isDuplicate, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/models`,
    data: {
      ModelId: modelId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => (isDuplicate ? setdescribeModelDuplicateThunk(retData) : setdescribeModelThunk(retData)),
    onFailure: err => setModelErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribeModelThunk = retData => dispatch => {
  dispatch(setDescribeModel(retData));
};

export const setDescribeModel = ({ retData }) => ({
  type: SET_MODEL_DETAILS,
  payload: retData
});

export const setdescribeModelDuplicateThunk = retData => dispatch => {
  dispatch(setDescribeModelDuplicate(retData));
};

export const setDescribeModelDuplicate = ({ retData }) => ({
  type: SET_MODEL_DETAILS_DUPLICATE,
  payload: retData
});

export const setModelChangeThunk = retData => dispatch => {
  dispatch(setModelChange(retData));
};

export const setModelChange = retData => ({
  type: SET_MODEL_CHANGE,
  payload: retData
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_MODEL_FILTERED_RESULTS,
  payload: retData
});

export const modelCleanUpThunk = model => dispatch => {
  dispatch(modelCleanUp(model));
};

export const modelCleanUp = model => ({
  type: MODEL_CLEANUP,
  payload: {
    data: model
  }
});

// Component Positions

export const setComponentPositionsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setComponentPositionsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setComponentPositionsError(messageCode, false));
  }, 2500);
};

export const setComponentPositionsError = (messageCode, status) => {
  return {
    type: COMPONENT_POSITION_MODEL_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getComponentPositionsThunk = (modelId, onlyVirtual, telemetryComponent, widgetCode) => dispatch => {
  if (modelId) {
    dispatch(getComponentPositions(modelId, onlyVirtual, telemetryComponent, widgetCode));
  }
};

export const setComponentPositionsLoadingStatus = isLoading => ({
  type: COMPONENT_POSITION_MODEL_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getComponentPositions = (modelId, onlyVirtual, telemetryComponent, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/model/componentposition`,
    data: {
      ModelId: modelId,
      OnlyVirtual: onlyVirtual,
      Telemetry: telemetryComponent
    },
    method: 'GET',
    onLoad: status => setComponentPositionsLoadingStatus(status),
    onSuccess: retData => setAllComponentPositionsListThunk(retData),
    onFailure: err => setComponentPositionsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllComponentPositionsListThunk = ({ retData }) => dispatch => {
  let messageCode = retData && retData.message;
  let componentPositionDetails = (retData && retData.componentPositionDetails) || [];
  dispatch(setAllComponentPositionsList(messageCode, componentPositionDetails));
};

export const setAllComponentPositionsList = (messageCode, componentPositionDetails) => ({
  type: GET_ALL_COMPONENT_POSITION_MODEL,
  payload: {
    data: {
      componentPositionModels: componentPositionDetails,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setComponentPositionChangeThunk = retData => dispatch => {
  dispatch(setComponentPositionChange(retData));
};

export const setComponentPositionChange = retData => ({
  type: SET_COMPONENT_POSITION_MODEL_CHANGE,
  payload: retData
});

export const saveComponentPositionThunk = (componentPositionDetails, widgetCode) => dispatch => {
  let url = '/management/model/componentposition/save';
  let actionName = ADD_COMPONENT_POSITION_MODEL;
  let log = logEntry(MODEL_MANAGEMENT, actionName, componentPositionDetails);
  dispatch(postComponentPosition(componentPositionDetails, url, log, widgetCode));
};

export const deleteComponentPositionThunk = (componentPositionDetails, widgetCode) => dispatch => {
  let url = '/management/model/componentposition/delete';
  let actionName = DELETE_COMPONENT_POSITION_MODEL;
  let log = logEntry(MODEL_MANAGEMENT, actionName, componentPositionDetails);
  dispatch(postComponentPosition(componentPositionDetails, url, log, widgetCode));
};

export const postComponentPosition = (componentPositionDetails, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setComponentPositionsLoadingStatus(status),
    onSuccess: retData => setComponentPositionStatusThunk(retData),
    onFailure: err => setComponentPositionsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      ComponentPosition: componentPositionDetails,
      log: log
    },
    widgetcode: widgetCode
  });

export const setComponentPositionStatusThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let componentPositionDetails = (retData && retData.retData.componentPositionDetails) || [];

  dispatch(setComponentPositionStatusAndData(messageCode, componentPositionDetails, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setComponentPositionStatus('hhhh', false));
  }, 2500);
};

export const setComponentPositionStatusAndData = (messageCode, data, status) => ({
  type: UPDATE_COMPONENT_POSITION_MODEL_SAVE_STATUS,
  payload: {
    data: {
      componentPositionModels: data,
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      showAddEditScreen: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setComponentPositionStatus = (messageCode, status) => ({
  type: UPDATE_COMPONENT_POSITION_MODEL_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setComponentPositionShowScreenStatusThunk = status => dispatch => {
  dispatch(seComponentPositionShowScreenStatus(status));
};

export const seComponentPositionShowScreenStatus = status => ({
  type: UPDATE_COMPONENT_POSITION_MODEL_SHOW_SCREEN,
  payload: { showAddEditScreen: status }
});

export const getModelVersionsThunk = (
  modelId,
  offset,
  limit,
  filter,
  widgetCode,
  organisationId,
  includeSeqenceList = false
) => dispatch => {
  dispatch(getModelVersion(modelId, offset, limit, filter, widgetCode, organisationId, includeSeqenceList));
};

export const getModelVersion = (modelId, offset, limit, filter, widgetCode, organisationId, includeSeqenceList = false) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion`,
    data: {
      ModelId: modelId,
      Offset: offset,
      Limit: limit,
      Filter: filter,
      OrganisationId: organisationId ? organisationId : undefined,
      IncludeSequenceList: includeSeqenceList || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllModelVersionListThunk(retData),
    onFailure: err => setModelErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllModelVersionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelVersions = (retData && retData.modelVersions) || [];
  dispatch(setAllModelVersionList(messageCode, modelVersions));
};

export const setAllModelVersionList = (messageCode, modelVersions) => ({
  type: GET_ALL_MODELVERSION_MODEL,
  payload: {
    data: {
      ModelVersions: modelVersions
    }
  }
});

export const setDuplicateChangeThunk = data => dispatch => {
  dispatch(setDuplicateChange(data));
};

export const setDuplicateChange = data => ({
  type: SET_DUPLICATE_MODEL,
  payload: {
    data: {
      DuplicateModel: data
    }
  }
});

export const uploadComponentToS3UpdateShadowThunk = (modelId, widgetCode) => dispatch => {
  let url = `/management/modelversion/updateunitshadow`;
  let actionName = UPLOAD_UNIT_COMPONENT;
  let log = logEntry(UPLOAD_COMPONENT_TO_UNIT, actionName, modelId);
  dispatch(uploadComponentToS3UpdateShadow(modelId, url, log, widgetCode));
};

export const uploadComponentToS3UpdateShadow = (modelId, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setUploadComponentToS3UpdateShadowStatusThunk(retData),
    onFailure: err => setComponentPositionsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      uploadUnitComponents: true,
      modelId: modelId,
      updatedBy: Cookies.get(`userid-${envName}`),
      log: log
    },
    widgetcode: widgetCode
  });

export const setUploadComponentToS3UpdateShadowStatusThunk = retData => dispatch => {
  let messageCode = retData && retData.message;

  dispatch(setUploadComponentToS3UpdateShadowStatus(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUploadComponentToS3UpdateShadowStatus('', false));
  }, 2500);
};

export const setUploadComponentToS3UpdateShadowStatus = (messageCode, status) => ({
  type: UPLOAD_UNIT_COMPONENT_STATUS,
  payload: {
    data: {
      isOpSuccessfull: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});
