import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './FAQCategory.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const FAQCategoryListTable = ({ FAQCategoryData, RemoveFAQCategory, ChangeFAQCategory, UpdateAllowed, DeleteAllowed, intl }) => {
  return (
    <div>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="common.description" defaultMessage="Description" />
            </th>
            {(UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {FAQCategoryData.map((n, index) => {
            return (
              <Fragment key={index}>
                <tr data-unittest="FAQCategoryData">
                  <td>{n.Name}</td>
                  <td>{n.Description}</td>
                  {(UpdateAllowed || DeleteAllowed) && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={() => {
                            ChangeFAQCategory(n.FAQCategoryId);
                          }}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                        </Button>
                      )}
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.removeButton}
                          href="#"
                          onClick={() => {
                            RemoveFAQCategory(n.FAQCategoryId);
                          }}
                          data-unittest="removeData"
                        >
                          <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

FAQCategoryListTable.defaultProps = {
  FAQCategoryData: []
};

FAQCategoryListTable.propTypes = {
  FAQCategoryData: PropTypes.array.isRequired,
  RemoveFAQCategory: PropTypes.func.isRequired,
  ChangeFAQCategory: PropTypes.func.isRequired,
  UpdateAllowed: PropTypes.bool,
  DeleteAllowed: PropTypes.bool
};

export default injectIntl(FAQCategoryListTable);
