import { connect } from 'react-redux';
import UnitReports from './UnitReports';
import {
  getUnitManager,
  getCurrentTimezone,
  getUserProfileWidget,
  getFlowSequenceGlobalSettings,
  getWarningEvents,
  getAlarmEvents,
  getInfoEvents
} from '../../selectors/index';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const ALARMS = 'ALARMS';
const WARNINGS = 'WARNINGS';
const EVENTLOG = 'EVENTLOG';
const TELEMETRY = 'TELEMETRY';
const INFO = 'INFO';
const SEQUENCE = 'SEQUENCE';
const mapStateToProps = (state, props) => {
  //Injecting Errors, Alarms, Eventlog
  let unitMgr = getUnitManager(state);

  let unitManager = { ...unitMgr };

  let userProfileWidgets = getUserProfileWidget(state);

  const reportTypes = [];

  let unitComponentPositions = unitManager.selectedUnit.unitComponentPositions;

  if (unitComponentPositions.filter(f => f.ModelComponentPositionId === EVENTLOG).length === 0) {
    // if (IsWidgetAccessible(userProfileWidgets, 'UNITREPORT_EVENTLOG')) {
    //   reportTypes.push({
    //     ModelComponentPositionId: EVENTLOG,
    //     Name: EVENTLOG,
    //     Description: 'Event Log',
    //     ShowDataPoints: false,
    //     DefaultChecked: false
    //   });
    // }
    if (IsWidgetAccessible(userProfileWidgets, 'UNITREPORT_WARNINGS')) {
      reportTypes.push({
        ModelComponentPositionId: WARNINGS,
        Name: WARNINGS,
        Description: 'Warnings',
        ShowDataPoints: false
      });
    }
    if (IsWidgetAccessible(userProfileWidgets, 'UNITREPORT_ALARMS')) {
      reportTypes.push({
        ModelComponentPositionId: ALARMS,
        Name: ALARMS,
        Description: 'Alarms',
        ShowDataPoints: false,
        DefaultChecked: false
      });
    }
    // if (IsWidgetAccessible(userProfileWidgets, 'UNITREPORT_SEQUENCE')) {
    reportTypes.push({
      ModelComponentPositionId: SEQUENCE,
      Name: SEQUENCE,
      Description: 'Sequence',
      ShowDataPoints: false,
      DefaultChecked: false
    });
    // }
    // if (IsWidgetAccessible(userProfileWidgets, 'UNITREPORT_INFO')) {
    reportTypes.push({
      ModelComponentPositionId: INFO,
      Name: INFO,
      Description: 'Info',
      ShowDataPoints: false,
      DefaultChecked: false
    });
    // }

    if (IsWidgetAccessible(userProfileWidgets, 'UNITREPORT_TELEMETRY')) {
      reportTypes.push({
        ModelComponentPositionId: TELEMETRY,
        Name: TELEMETRY,
        Description: 'Telemetry',
        ShowDataPoints: true,
        DefaultChecked: true
      });
    }
  }
  return {
    unitManager: unitManager,
    currentTimezone: getCurrentTimezone(state),
    //currentTimezone: 'Europe/London',
    userProfileWidgets: userProfileWidgets,
    flowSequences: getFlowSequenceGlobalSettings(state),
    alarms: getAlarmEvents(state),
    warnings: getWarningEvents(state),
    infoEvents: getInfoEvents(state),
    reportTypes: reportTypes
  };
};

export default connect(mapStateToProps, null)(UnitReports);
