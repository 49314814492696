import { initialLocaleState } from './initialState';
import * as localeActionTypes from '../constants/index';

export default (state = initialLocaleState, action = {}) => {
  switch (action.type) {
    case localeActionTypes.CHANGE_LOCALE_LANGUAGE_SUCCESS:
      return { ...state, locale: action.locale };

    default:
      return state;
  }
};
