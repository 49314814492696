import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  GET_ALL_SEQUENCEVERSION_DETAILS,
  SEQUENCEVERSION_IS_LOADING,
  SEQUENCEVERSION_MANAGEMENT_ERROR,
  SET_SEQUENCEVERSION_CURRENT_PAGE,
  SET_SEQUENCEVERSION_DETAILS,
  SET_SEQUENCEVERSION_CHANGE,
  SET_SEQUENCEVERSION_FILTERED_RESULTS,
  SEQUENCEVERSION_CLEANUP,
  UPDATE_SEQUENCEVERSION_SAVE_STATUS,
  SET_SEQUENCEVERSIONFILE_CHANGE,
  SET_SEQUENCEVERSIONFILE_DETAILS,
  UPDATE_SEQUENCEVERSIONFILE_SAVE_STATUS
} from '../constants/index';

import { SEQUENCEVERSION_MANAGEMENT, UPDATE_SEQUENCEVERSION, CREATE_SEQUENCEVERSION } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

//SequenceVersion Management
export const setSequenceVersionErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setSequenceVersionError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setSequenceVersionError(messageCode, false));
  }, 2500);
};

export const setSequenceVersionError = (messageCode, status) => {
  return {
    type: SEQUENCEVERSION_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getSequenceVersionThunk = (widgetCode, modelVersionId) => dispatch => {
  let organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  dispatch(getSequenceVersion(widgetCode, organisationId, modelVersionId));
};

export const setLoadingStatus = isLoading => ({
  type: SEQUENCEVERSION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getSequenceVersion = (widgetCode, organisationId, modelVersionId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/sequenceversion`,
    data: {
      OrganisationId: organisationId,
      ModelVersionId: modelVersionId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllSequenceVersionListThunk(retData),
    onFailure: err => setSequenceVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllSequenceVersionListThunk = data => dispatch => {
  let messageCode = data && data.message;
  let sequenceVersions = (data && data.retData && data.retData.SequenceVersions) || [];
  dispatch(setAllSequenceVersionList(messageCode, sequenceVersions));
};

export const setAllSequenceVersionList = (messageCode, sequenceVersions) => ({
  type: GET_ALL_SEQUENCEVERSION_DETAILS,
  payload: {
    data: {
      sequenceVersions: sequenceVersions,
      filteredResults: sequenceVersions,
      isLoading: false,
      duplicateSequence: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_SEQUENCEVERSION_CURRENT_PAGE,
  payload: { currentPage }
});

export const saveSequenceVersionThunk = (sequenceVersion, widgetCode) => dispatch => {
  let url = '/management/modelversion/sequenceversion/save';
  let actionName = sequenceVersion.SequenceVersionId === 0 ? CREATE_SEQUENCEVERSION : UPDATE_SEQUENCEVERSION;
  let log = logEntry(SEQUENCEVERSION_MANAGEMENT, actionName, sequenceVersion);

  dispatch(saveSequenceVersion(sequenceVersion, url, log, widgetCode));
};

export const saveSequenceVersion = (sequenceVersion, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    widgetcode: widgetCode,
    onLoad: status => setLoadingStatus(status),
    onSuccess: message => setSequenceVersionStatusThunk(message, sequenceVersion),
    onFailure: err => setSequenceVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      SequenceVersion: sequenceVersion,
      log: log
    }
  });

export const setSequenceVersionStatusThunk = (data, sequenceVersion) => dispatch => {
  dispatch(setSequenceVersionStatus(data.message, true));
  setTimeout(() => {
    //Hide the banner
    if (!data.SequenceVersionId) {
      dispatch(setSequenceVersionStatus('', false, sequenceVersion.IsCopy || false));
    } else {
      dispatch(setSequenceVersionInsertStatus(data.SequenceVersionId, false));
    }
  }, 2500);
};

export const setSequenceVersionInsertStatus = (sequenceVersionId, status) => ({
  type: UPDATE_SEQUENCEVERSION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: '',
      sequenceVersionId: sequenceVersionId
    }
  }
});

export const setSequenceVersionStatus = (messageCode, status, fromCopy = false) => ({
  type: UPDATE_SEQUENCEVERSION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: fromCopy ? false : true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const describeSequenceVersionThunk = (sequenceVersionId, widgetCode) => dispatch => {
  dispatch(describeSequenceVersion(sequenceVersionId, widgetCode));
};

export const describeSequenceVersion = (sequenceVersionId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/sequenceversion`,
    data: {
      SequenceVersionId: sequenceVersionId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setdescribeSequenceVersionThunk(retData),
    onFailure: err => setSequenceVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribeSequenceVersionThunk = retData => dispatch => {
  dispatch(setDescribeSequenceVersion(retData));
};

export const setDescribeSequenceVersion = ({ retData }) => ({
  type: SET_SEQUENCEVERSION_DETAILS,
  payload: retData
});

export const setSequenceVersionChangeThunk = retData => dispatch => {
  dispatch(setSequenceVersionChange(retData));
};

export const setSequenceVersionChange = retData => ({
  type: SET_SEQUENCEVERSION_CHANGE,
  payload: retData
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_SEQUENCEVERSION_FILTERED_RESULTS,
  payload: retData
});

export const sequenceVersionCleanUpThunk = sequenceVersion => dispatch => {
  dispatch(sequenceVersionCleanUp(sequenceVersion));
};

export const sequenceVersionCleanUp = sequenceVersion => ({
  type: SEQUENCEVERSION_CLEANUP,
  payload: {
    data: sequenceVersion
  }
});

//Sequence Version File Section
//************************* */

export const describeSequenceVersionFileThunk = (sequenceVersionId, sequenceVersionFileId, widgetCode) => dispatch => {
  dispatch(describeSequenceVersionFile(sequenceVersionId, sequenceVersionFileId, widgetCode));
};

export const describeSequenceVersionFile = (sequenceVersionId, sequenceVersionFileId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelversion/sequenceversion`,
    data: {
      SequenceVersionId: sequenceVersionId || undefined,
      SequenceVersionFileId: sequenceVersionFileId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setdescribeSequenceVersionFileThunk(retData),
    onFailure: err => setSequenceVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribeSequenceVersionFileThunk = retData => dispatch => {
  dispatch(setDescribeSequenceVersionFile(retData));
};

export const setDescribeSequenceVersionFile = ({ retData }) => ({
  type: SET_SEQUENCEVERSIONFILE_DETAILS,
  payload: retData
});

export const setSequenceVersionFileChangeThunk = retData => dispatch => {
  dispatch(setSequenceVersionFileChange(retData));
};

export const setSequenceVersionFileChange = retData => ({
  type: SET_SEQUENCEVERSIONFILE_CHANGE,
  payload: retData
});

export const saveSequenceVersionFileThunk = (sequenceVersion, widgetCode) => dispatch => {
  let url = '/management/modelversion/sequenceversion/save';
  let actionName = sequenceVersion.SequenceVersionId === 0 ? CREATE_SEQUENCEVERSION : UPDATE_SEQUENCEVERSION;
  let log = logEntry(SEQUENCEVERSION_MANAGEMENT, actionName, sequenceVersion);

  dispatch(saveSequenceVersionFile(sequenceVersion, url, log, widgetCode));
};

export const saveSequenceVersionFile = (sequenceVersion, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    widgetcode: widgetCode,
    onLoad: status => setLoadingStatus(status),
    onSuccess: message => setSequenceVersionFileStatusThunk(message),
    onFailure: err => setSequenceVersionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      SequenceVersion: sequenceVersion,
      log: log
    }
  });

export const setSequenceVersionFileStatusThunk = resp => dispatch => {
  dispatch(setSequenceVersionFileStatus(resp, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setSequenceVersionFileStatus('', false));
  }, 2500);
};

export const setSequenceVersionFileStatus = (messageCode, status) => ({
  type: UPDATE_SEQUENCEVERSIONFILE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});
