import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  DAILYSUMMART_DATA_ERROR,
  DAILYSUMMART_DATA_IS_LOADING,
  SET_DAILYSUMMART_DATA_CHANGE,
  START_DAILYSUMMART_DATA_EXPORT,
  SET_DAILYSUMMART_EXPORT_DATA,
  SET_COMPUTE_DAILYSUMMART_DATA_EXPORT
} from '../constants/index';

import { UNIT_MANAGEMENT, DAILY_SUMMARY_REPORT } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setDailySummaryErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setDailySummaryError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setDailySummaryError(messageCode, false));
  }, 2500);
};

export const setDailySummaryError = (messageCode, status) => {
  return {
    type: DAILYSUMMART_DATA_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || '',
        search: {
          exportedResults: [],
          exportComplete: false,
          exportCompute: false
        }
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: DAILYSUMMART_DATA_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const setDailySummaryChangeThunk = retData => dispatch => {
  dispatch(setDailySummaryChange(retData));
};

export const setDailySummaryChange = retData => ({
  type: SET_DAILYSUMMART_DATA_CHANGE,
  payload: retData
});

export const getExportDataThunk = (data, widgetCode) => dispatch => {
  let log = logEntry(UNIT_MANAGEMENT, DAILY_SUMMARY_REPORT, data);
  dispatch(startExport());
  dispatch(getExportData(data, widgetCode, log));
};

export const getExportData = (data, widgetCode, log) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/reports/unit/dailySummary`,
    data: {
      query: data,
      log: log
    },
    method: 'POST',
    // onLoad: status => startExport(status),
    onSuccess: retData => setExportDataThunk(retData),
    onFailure: err => setDailySummaryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const startExport = () => ({
  type: START_DAILYSUMMART_DATA_EXPORT
});

export const setExportCompleteThunk = status => dispatch => {
  dispatch(setExportComplete(status));
};

export const setExportComplete = status => ({
  type: SET_COMPUTE_DAILYSUMMART_DATA_EXPORT,
  payload: { status: status }
});

export const setExportDataThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let dailySummaries = (retData && retData.dailySummaries) || [];
  dispatch(setExportData(messageCode, dailySummaries));
};

export const setExportData = (messageCode, dailySummaries) => ({
  type: SET_DAILYSUMMART_EXPORT_DATA,
  payload: {
    data: {
      search: {
        exportedResults: dailySummaries.summaryData,
        exportComplete: true
      },
      displayMessageCode: messageCode,
      isOpSuccessful: true
    }
  }
});

export const setDailySummaryExportErrorThunk = err => dispatch => {
  dispatch(setDailySummaryError(err, true));
  setTimeout(() => {
    dispatch(setDailySummaryError(err, false));
  }, 2500);
};
