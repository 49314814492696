import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import s from './UnitGlobalSettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelVersionState } from '../../reducers/initialState';
import UnitGlobalSettingListTable from './UnitGlobalSettingsListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const UnitGlobalSettingsList = ({
  unitGlobalSettings,
  intl,
  ModelVersionId,
  ReadOnly,
  userProfileWidgets,
  getUnitGlobalSettings,
  saveUnitGlobalSetting,
  describeUnitGlobalSetting,
  changeUnitGlobalSetting,
  setUnitGlobalSettingShowScreenStatus,
  currentTimezone
}) => {
  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;
  let initialRecords = unitGlobalSettings.UnitGlobalSettingsList.slice(0, initialLimit);

  useEffect(() => {
    getUnitGlobalSettings(offset, limit, filter, ModelVersionId);
  }, [getUnitGlobalSettings, offset, limit, filter, ModelVersionId]);

  const [currentEditId, setCurrentEditId] = useState(-1);
  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [delUnitGlobalSettingId, setUnitGlobalSettingId] = useState(0);
  const [searchText, setSearchText] = useState('');

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const localSettingLength = localSettings.length;
  const unitGlobalSettingLength = unitGlobalSettings.UnitGlobalSettingsList.length;
  const addNewRef = useRef(null);

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(unitGlobalSettings.UnitGlobalSettingsList);
    }
    setShowAll(!showAll);
  };

  const disableModeHandler = status => {
    setUnitGlobalSettingShowScreenStatus(status);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = unitGlobalSettings.UnitGlobalSettingsList.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const setAddHandler = () => {
    setUnitGlobalSettingShowScreenStatus(true);
    setCurrentEditId(0);
    window.scrollTo(0, addNewRef.current.offsetTop);
  };

  const setEditHandler = unitGlobalSettingId => {
    setCurrentEditId(unitGlobalSettingId);
    if (unitGlobalSettingId === -1) {
      changeUnitGlobalSetting({
        SelectedUnitGlobalSetting: {
          ...initialModelVersionState.modelVersionManager.selectedModelVersion.UnitGlobalSettings.SelectedUnitGlobalSetting,
          ModelVersionId: ModelVersionId
        }
      });
      setUnitGlobalSettingShowScreenStatus(false);
    } else {
      setUnitGlobalSettingShowScreenStatus(true);
    }
  };

  const filterData = () => {
    let filteredList = unitGlobalSettings.UnitGlobalSettingsList;
    if (isFilter) {
      filteredList = unitGlobalSettings.UnitGlobalSettingsList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getSettings = () => {
    let unitGlobalSettings = [];
    if (showAll) {
      unitGlobalSettings = filterData();
    } else {
      if (isFilter) {
        unitGlobalSettings = localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        unitGlobalSettings = initialRecords;
      }
    }
    return unitGlobalSettings.sort(compareValues('Name'));
  };

  const removeUnitGlobalSettingClick = unitGlobalSettingId => {
    setActive(true);
    setUnitGlobalSettingId(unitGlobalSettingId);
  };
  const DeleteUnitGlobalSetting = () => {
    let saveData = unitGlobalSettings.SelectedUnitGlobalSetting;
    saveData.MarkAsDeleted = true;
    saveData.ModelVersionId = ModelVersionId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.UnitGlobalSettingId = delUnitGlobalSettingId;
    saveUnitGlobalSetting(saveData, 'UNITGLOBALSETTINGSDELETE');
  };

  const handleClose = () => {
    setActive(false);
    setUnitGlobalSettingId(0);
  };

  const handleConfirm = () => {
    DeleteUnitGlobalSetting();
    handleClose();
  };

  let messageId = (unitGlobalSettings && unitGlobalSettings.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'modelVersion.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelVersion.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'UNITGLOBALSETTINGSEDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'UNITGLOBALSETTINGSDELETE');

  const dialogTitle = intl.formatMessage({
    id: 'modelVersion.confirmDeleteUnitGlobalSettings',
    defaultMessage: 'Are you sure you want to delete the unit setting?'
  });

  return (
    <div className={s.unitGlobalSettings}>
      {unitGlobalSettings.isLoading && <LoadingSpinner />}
      {unitGlobalSettings.showBanner && (
        <Banner
          failureText={messageText}
          showBanner={unitGlobalSettings.showBanner}
          status={unitGlobalSettings.isOpSuccessfull}
          successText={messageText}
        />
      )}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              disabled={unitGlobalSettings.showAddEditScreen}
              // initialText={filter}
              placeHolderTextId="modelVersion.search"
              data-unittest="searchUnitGlobalSettings"
            />
          </Col>
          <Col sm={6} lg={8}>
            {!ReadOnly ? (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'UNITGLOBALSETTINGSADD')}
                onClick={setAddHandler}
                disabled={unitGlobalSettings.showAddEditScreen}
                data-unittest="addUnitGlobalSettingButton"
              >
                + <FormattedMessage id="modelVersion.addNew" defaultMessage="ADD NEW" />
              </Button>
            ) : (
              ''
            )}
          </Col>
        </Row>

        <div>
          <UnitGlobalSettingListTable
            setEditHandler={setEditHandler}
            ModelVersionId={ModelVersionId}
            selectedUnitGlobalSettingId={currentEditId}
            saveUnitGlobalSetting={saveUnitGlobalSetting}
            unitGlobalSettings={unitGlobalSettings}
            describeUnitGlobalSetting={describeUnitGlobalSetting}
            changeUnitGlobalSetting={changeUnitGlobalSetting}
            UpdateAllowed={isUpdateAllowed}
            DeleteAllowed={isDeleteAllowed}
            unitGlobalSettingsData={getSettings()}
            disableMode={unitGlobalSettings.showAddEditScreen}
            disableModeHandler={disableModeHandler}
            addNewRef={addNewRef}
            ReadOnly={ReadOnly}
            removeUnitGlobalSetting={removeUnitGlobalSettingClick}
            currentTimezone={currentTimezone}
          />
        </div>
        {((!isFilter && unitGlobalSettingLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? unitGlobalSettingLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

UnitGlobalSettingsList.defaultProps = {
  ...initialModelVersionState
};

UnitGlobalSettingsList.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired,
  getUnitGlobalSettings: PropTypes.func.isRequired,
  saveUnitGlobalSetting: PropTypes.func.isRequired,
  describeUnitGlobalSetting: PropTypes.func.isRequired,
  changeUnitGlobalSetting: PropTypes.func.isRequired,
  setUnitGlobalSettingShowScreenStatus: PropTypes.func.isRequired
};

export default injectIntl(UnitGlobalSettingsList);
