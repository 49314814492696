import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModelFirmwareVersionList from './ModelFirmwareVersionList';
import { getModelFirmwareVersionManager, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import { getModelFirmwareVersionsThunk, setCurrentPage, setPageFilter } from '../../actions/modelFirmwareVersion';

const mapStateToProps = state => {
  return {
    modelFirmwareVersionManager: getModelFirmwareVersionManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getModelFirmwareVersions: (modelId, offset, limit, filter) =>
        getModelFirmwareVersionsThunk(modelId, offset, limit, filter, 'MODELFIRMWAREVERSIONLIST'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelFirmwareVersionList);
