import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import s from './MapView.module.scss';
import bs from '../../styles/bootstrap-overrides.scss';
import DeviceTile from '../DeviceTile/DeviceTile';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import droplet from '../../assets/droplet-icon.svg';
import pendingAlarm from '../../assets/alarm-outline-small.svg';
import dropletClusterMarker from '../../assets/droplet-with-number.png';
import warning from '../../assets/warning-icon.svg';
import error from '../../assets/error-icon.svg';
import fullScreen from '../../assets/fullscreen-icon.svg';
import closeButton from '../../assets/close-button-icon.svg';
import SearchBar from '../SearchBar/SearchBar';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Map from '../Map/Map';
import Markers from '../Map/Markers';
import MapInfoWindow from '../Map/MapInfoWindow';
import Button from 'react-bootstrap/Button';
import { ALARM_STATUS_NEW, ALARM_STATUS_PENDING } from '../../constants';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

class MapView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapLoaded: false,
      markerClustererLoaded: false,
      markerDrawn: false,
      showMapOverlay: false,
      unitSerialNumber: null,
      location: '',
      isTaggedOut: 0,
      tagOutByUser: '',
      SchedulesCount: 0,
      deviceSerialNumber: '',
      unitName: '',
      country: '',
      position: {},
      searchQuery: {
        keywords: '',
        type: 0
      },
      isFullScreen: false
    };

    this.mapOverlay = {};

    this.dropDownData = [
      {
        label: 'Show:all',
        value: '0',
        isDisabled: false
      },
      {
        label: 'Warnings',
        value: '1',
        isDisabled: false
      },
      {
        label: 'New Alarms',
        value: '2',
        isDisabled: false
      },
      {
        label: 'Pending Alarms',
        value: '3',
        isDisabled: false
      },
      {
        label: 'Offline',
        value: '4',
        isDisabled: false
      }
    ];

    this.defaultLocation = { lat: -37.840935, lng: 144.946457 };
  }

  googleMapRef = React.createRef();

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  onMapClick = map => {
    this.removeMapOverlay(map);
  };

  onMapLoad = map => {
    this.setState({ mapLoaded: true });
  };

  onMarkerClusterLoad = markerCluster => {
    this.markerCluster = markerCluster;
    this.setState({ markerClustererLoaded: true });
  };

  handleTileClick = unitSerialNumber => {
    if (this.props.handleClick) {
      this.props.handleClick(unitSerialNumber);
    }
  };

  onMarkerClick = marker => {
    if (marker && marker.device && marker.device.UnitId) {
      this.setState({
        ...this.state,
        showMapOverlay: true,
        location: marker.device.InstallationCity,
        country: marker.device.InstallationCountry,
        isTaggedOut: marker.device.IsTaggedOut,
        position: marker.position,
        tagOutByUser: marker.device.TagOutByUser,
        unitName: marker.device.Name,
        unitSerialNumber: marker.device.UnitSerialNumber,
        deviceSerialNumber: marker.device.DeviceSerialNumber,
        SchedulesCount: marker.device.SchedulesCount
      });
    }
  };

  onMarkerReDrawn = newMarkerObj => {
    //Code to execute on marker redrawn
    if (!this.state.markerDrawn) {
      this.setState({ markerDrawn: true });
    }
  };

  onMarkerDrawn = markerObj => {
    if (!this.state.markerDrawn) {
      this.setState({ markerDrawn: true });
    }
    this.removeMapOverlay();
  };

  onMarkerRemoved = markerObj => {
    this.removeMapOverlay();
  };

  removeMapOverlay = map => {
    this.setState({
      ...this.state,
      showMapOverlay: false,
      location: '',
      country: '',
      isTaggedOut: 0,
      tagOutByUser: '',
      SchedulesCount: 0,
      position: {},
      unitName: '',
      unitSerialNumber: null,
      deviceSerialNumber: ''
    });

    if (this.mapOverlay && this.mapOverlay.overlay && this.mapOverlay.overlay.setMap) {
      this.mapOverlay.overlay.setMap(null);
    }
  };

  getFormattedUnits = () => {
    let devicesObj = Object.values(this.props.devices);
    let formattedUnits = devicesObj.map(device => {
      //Unit Address
      let address = `${device.InstallationStreetAddress || ''}, ${device.InstallationCity || ''}, ${device.InstallationRegion ||
        ''},${device.InstallationPostCode || ''} ${device.InstallationCountry || ''} `;

      device.address = address;

      //Unit  Current Status
      let deviceSensorData = this.props.sensorData.filter(sensor => sensor.unitserialnumber === device.UnitSerialNumber);
      const timestamp = deviceSensorData && deviceSensorData.map(item => item.timestamp);
      const deviceOnline = timestamp && timestamp[0] && moment(timestamp[0]).isBetween(moment().subtract(1, 'minute'), moment());
      device.Online = deviceOnline;

      //position
      if (device.CommissionGpsLatitude && device.CommissionGpsLongitude) {
        device.position = { lat: device.CommissionGpsLatitude, lng: device.CommissionGpsLongitude };
      }

      //Unit EventType and Icon
      let filteredEvents =
        this.props.eventData.length > 0 && this.props.eventData.filter(event => event.unitSerialNumber === device.UnitSerialNumber);
      const warnings =
        filteredEvents && filteredEvents.length > 0 && filteredEvents[0].events && filteredEvents[0].events.filter(item => item.type === 1);

      const newAlarms =
        this.props.alarmsData &&
        this.props.alarmsData.filter(event => {
          return event.EventStatus === ALARM_STATUS_NEW && event.UnitNumber === device.UnitNumber;
        });

      const pendingAlarms =
        this.props.alarmsData &&
        this.props.alarmsData.filter(event => {
          return event.EventStatus === ALARM_STATUS_PENDING && event.UnitNumber === device.UnitNumber;
        });

      let warningCount = (warnings && warnings.length) || 0;
      let newAlarmsCount = (newAlarms && newAlarms.length) || 0;
      let pendingAlarmsCount = (pendingAlarms && pendingAlarms.length) || 0;

      let icon = newAlarmsCount > 0 ? error : pendingAlarmsCount > 0 ? pendingAlarm : warningCount > 0 ? warning : droplet;
      device.icon = icon;
      //device.eventType = warningCount > 0 ? '1' : newAlarmsCount > 0 ? '2' : pendingAlarmsCount > 0 ? '3' : '0';
      device.newAlarm = newAlarmsCount > 0 ? true : false;
      device.pendingAlarm = pendingAlarmsCount > 0 ? true : false;
      device.warning = warningCount > 0 ? true : false;

      return device;
    });
    return formattedUnits;
  };

  onSearchKeywordChange = keyword => {
    this.removeMapOverlay();

    this.setState({
      searchQuery: {
        ...this.state.searchQuery,
        keywords: keyword
      }
    });
  };

  onSearchTypeChange = type => {
    this.removeMapOverlay();

    this.setState({
      searchQuery: {
        ...this.state.searchQuery,
        type: type.value
      }
    });
  };

  performSearch = units => {
    if (this.state.searchQuery.keywords) {
      units = units.filter(unit => unit.address.toLowerCase().indexOf(this.state.searchQuery.keywords.toLowerCase()) >= 0);
    }
    let type = this.state.searchQuery.type && this.state.searchQuery.type * 1;
    if (type && type <= 3) {
      switch (type) {
        case 1:
          units = units.filter(unit => unit.warning);
          break;
        case 2:
          units = units.filter(unit => unit.newAlarm);
          break;
        case 3:
          units = units.filter(unit => unit.pendingAlarm);
          break;
        default:
          break;
      }
    }

    if (type === 4) {
      units = units.filter(unit => !unit.Online);
    }

    return units;
  };

  setOverlay = overlay => {
    this.mapOverlay = overlay;
  };

  onFullscreenButtonClick = id => {
    this.setState({ isFullScreen: true });

    let element = document.getElementById('mapViewContainer');
    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
    if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    }
    if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    }
  };

  onFullScreenCloseButtonClick = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  onFullScreenExit = () => {
    this.setState({ isFullScreen: false });
  };

  render() {
    let formattedUnits = this.getFormattedUnits();
    formattedUnits = this.performSearch(formattedUnits);

    return (
      <div className={s.mapContainer} id="mapViewContainer">
        {!this.state.markerDrawn && <LoadingSpinner centeredLoading={false} />}
        <Row className={this.state.isFullScreen ? `d-md-flex ${s.fullScreenSearch}` : 'd-none d-md-flex'}>
          <div className={s.searchBarContainer}>
            <SearchBar
              searchHandler={this.onSearchKeywordChange}
              clearSearchInVisible={true}
              placeHolderTextId="unitlistmap.enterUnitAddress"
            />
          </div>
          <Col xs={12} md={4} lg={3} xl={2} className={s.mapDropdown}>
            {this.state.isFullScreen ? (
              <Row>
                <Col xs={6} className={'d-md-none'}>
                  <img
                    src={closeButton}
                    onClick={this.onFullScreenCloseButtonClick}
                    alt="close"
                    style={{ height: '30px', marginLeft: '10px' }}
                  />
                </Col>
                <Col xs={6} className={`d-md-none ${s.mapSearchDropdown}`}>
                  <Dropdown
                    dataArray={this.dropDownData}
                    controlData={{ placeholderText: 'Show: All', customClassName: s.dropdownClass }}
                    onDropdownChange={this.onSearchTypeChange}
                  />
                </Col>
              </Row>
            ) : (
              <Dropdown
                dataArray={this.dropDownData}
                controlData={{ placeholderText: 'Show: All', customClassName: '' }}
                onDropdownChange={this.onSearchTypeChange}
              />
            )}
          </Col>
        </Row>

        <Map
          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          mapContainerCssStyle={s.mapContainerStyle}
          onMapClick={this.onMapClick}
          onMapLoad={this.onMapLoad}
          onMarkerClusterLoad={this.onMarkerClusterLoad}
          fullScreenIcon={fullScreen}
          onFullScreenButtonClick={this.onFullscreenButtonClick}
          onFullScreenExit={this.onFullScreenExit}
          mapContainerStyleFullScreen={s.mapContainerStyleFullScreen}
        >
          <MapInfoWindow
            handleClick={unitSerialNumber => {
              this.handleTileClick(unitSerialNumber);
            }}
            position={this.state.position}
            onOverlayDrawn={this.setOverlay}
            hideOverlay={!this.state.showMapOverlay}
          >
            <ul
              className={s.deviceWrapper}
              unitserialnumber={this.state.unitSerialNumber || ''}
              location={this.state.location}
              country={this.state.country}
            >
              <DeviceTile
                location={this.state.location}
                country={this.state.country}
                isTaggedOut={this.state.isTaggedOut}
                isSchedulesCount={this.state.SchedulesCount > 0 ? true : false}
                unitName={this.state.unitName}
                unitSerialNumber={this.state.unitSerialNumber || ''}
                deviceSerialNumber={this.state.deviceSerialNumber}
                handleClick={this.handleTileClick}
                deviceSensorData={this.props.sensorData.filter(sensor => sensor.unitserialnumber === this.state.unitSerialNumber)}
                deviceEventData={this.props.eventData.filter(event => event.unitSerialNumber === this.state.unitSerialNumber)}
                deviceAlarmsData={this.props.alarmsData.filter(x => x.UnitSerialNumber === this.state.unitSerialNumber)}
                flowSequences={this.props.flowSequences}
                tagOutMessage={
                  // this.props.intl.formatMessage({ id: 'maintenance.tagout.isTaggedoutBy', defaultMessage: 'Tagged out by ' }) +
                  this.state.tagOutByUser
                }
                scheduleMessage="aaa"
              />
            </ul>
          </MapInfoWindow>

          {this.state.mapLoaded && this.state.markerClustererLoaded && (
            <Markers
              onClick={this.onMarkerClick}
              onMarkerDrawn={this.onMarkerDrawn}
              onMarkerReDrawn={this.onMarkerReDrawn}
              onMarkerRemoved={this.onMarkerRemoved}
              devices={formattedUnits}
              markerCluster={this.markerCluster}
              markerClusterIcon={dropletClusterMarker}
              defaultLocation={this.defaultLocation}
            />
          )}
        </Map>
        {!this.state.isFullScreen && (
          <div className={`container-fluid ${s.seeAllUnits}`}>
            <Row className={s.alignCenter}>
              <Col md={5}>
                <hr />
              </Col>
              <Col md={2} xs={12}>
                <Link to={'/unit'}>
                  <Button>
                    <FormattedMessage id="mapview.seeallunits" defaultMessage="SEE ALL UNITS" />
                  </Button>
                </Link>
              </Col>
              <Col md={5}>
                <hr />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

MapView.defaultProps = {
  devices: {},
  allowedUnits: {
    userProfileUnits: []
  },
  flowSequences: []
};

MapView.propTypes = {
  devices: PropTypes.array,
  handleClick: PropTypes.func.isRequired,
  sensorData: PropTypes.array,
  eventData: PropTypes.array,
  flowSequences: PropTypes.array.isRequired
};

export default injectIntl(MapView);
