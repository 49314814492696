import { GET_ALL_WIDGET_DETAILS, WIDGET_IS_SAVING, UPDATE_WIDGET_SAVE_STATUS, WIDGET_IS_LOADING } from '../constants/index';
import { initialAppWidgetState } from './initialState';

const appWidgetReducer = (state = initialAppWidgetState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_WIDGET_DETAILS: {
      const { Widgets } = payload;
      return {
        ...state,
        appWidgets: Widgets
      };
    }

    case WIDGET_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        appWidgets: {
          ...state.appWidgets,
          isLoading: isLoading
        }
      };
    }

    case UPDATE_WIDGET_SAVE_STATUS: {
      const { isOpSuccessfull, showBanner, isLoading } = payload;
      return {
        ...state,
        appWidgets: {
          ...state.appWidgets,
          isLoading: isLoading,
          isOpSuccessfull: isOpSuccessfull,
          showBanner: showBanner
        }
      };
    }

    case WIDGET_IS_SAVING: {
      const { isLoading } = payload;
      return {
        ...state,
        appWidgets: {
          ...state.appWidgets,
          isLoading: isLoading
        }
      };
    }

    default:
      return state;
  }
};

export default appWidgetReducer;
