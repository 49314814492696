import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionThresholds.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelVersionState } from '../../reducers/initialState';
import ModelVersionThresholdsListTable from './ModelVersionThresholdsListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import Form from 'react-bootstrap/Form';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';

const FLOW_SEQUENCE = 'FLOW_SEQUENCE';
const SENSOR = 'SENSOR';

const ModelVersionThresholdsList = ({
  getModelVersionsThresholds,
  ModelVersionId,
  ReadOnly,
  saveModelVersionThreshold,
  describeModelVersionThreshold,
  changeModelVersionThreshold,
  setThresholdShowScreenStatus,
  setFilteredThesholdList,
  flowSequences,
  modelComponentPositions,
  thresholds,
  intl,
  userProfileWidgets
}) => {
  let filter = thresholds.filter || '';
  let offset = thresholds.offset || 0;
  let limit = thresholds.limit || 1000;
  let initialLimit = 6;
  let initialRecords = thresholds.ThresholdsList.slice(0, initialLimit);

  //const { ModelVersionId } = useParams();

  const [currentEditId, setCurrentEditId] = useState(-1);
  const [currentFlowSequenceFilter, setCurrentFlowSequenceFilter] = useState(0);
  const [currentSensorFilter, setSurrentSensorFilter] = useState(0);

  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [thresholdId, setThresholdId] = useState(0);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const localSettingLength = localSettings.length;
  const thresholdLength = thresholds.ThresholdsList.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(thresholds.ThresholdsList);
    }
    setShowAll(!showAll);
  };

  const disableModeHandler = status => {
    setThresholdShowScreenStatus(status);
  };
  const searchHandler = (type, value) => {
    let filteredList = thresholds.ThresholdsList;
    setIsFilter(false);

    if (type === FLOW_SEQUENCE) {
      if (value > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.GeneralGlobalSettingId === value;
        });
        setIsFilter(true);
      }
      if (currentSensorFilter > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentPositionId === currentSensorFilter;
        });
        setIsFilter(true);
      }
    }

    if (type === SENSOR) {
      if (value > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.ModelComponentPositionId === value;
        });
        setIsFilter(true);
      }
      if (currentFlowSequenceFilter > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.GeneralGlobalSettingId === currentFlowSequenceFilter;
        });
        setIsFilter(true);
      }
    }
    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const filterData = () => {
    let filteredList = thresholds.ThresholdsList;
    if (currentSensorFilter > 0) {
      filteredList = filteredList.filter(function(item) {
        return item.ModelComponentPositionId === currentSensorFilter;
      });
    }

    if (currentFlowSequenceFilter > 0) {
      filteredList = filteredList.filter(function(item) {
        return item.GeneralGlobalSettingId === currentFlowSequenceFilter;
      });
    }

    return filteredList;
  };

  const getSettings = () => {
    if (showAll) {
      return filterData();
    } else {
      if (isFilter) {
        return localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        return initialRecords;
      }
    }
  };

  useEffect(() => {
    getModelVersionsThresholds(offset, limit, filter, ModelVersionId);
  }, [getModelVersionsThresholds, offset, limit, filter, ModelVersionId]);

  const setAddHandler = () => {
    changeModelVersionThreshold({
      SelectedThreshold: {
        ...initialModelVersionState.modelVersionManager.selectedModelVersion.Thresholds.SelectedThreshold,
        ModelVersionId: ModelVersionId
      }
    });
    setCurrentEditId(0);
    setThresholdShowScreenStatus(true);
  };

  const setEditHandler = modelVersionThresholdId => {
    setCurrentEditId(modelVersionThresholdId);
    if (modelVersionThresholdId === -1) {
      changeModelVersionThreshold({
        SelectedThreshold: {
          ...initialModelVersionState.modelVersionManager.selectedModelVersion.Thresholds.SelectedThreshold,
          ModelVersionId: ModelVersionId
        }
      });
      setThresholdShowScreenStatus(false);
    } else {
      setThresholdShowScreenStatus(true);
    }
  };

  const flowSequenceOptions = () => {
    let flowSequenceData = [];
    const initData = {
      label: <FormattedMessage id="modelVersion.allFlowSequence" defaultMessage="All flow sequences" />,
      value: 0
    };
    flowSequenceData.push(initData);

    flowSequences.forEach(element => {
      flowSequenceData.push({
        label: element.Name,
        value: element.GeneralGlobalSettingId
      });
    });

    return flowSequenceData.sort(compareValues('label'));
  };

  const modelComponentsOptions = () => {
    let modelComponentsData = [];
    const initData = {
      label: <FormattedMessage id="modelVersion.allSensor" defaultMessage="All sensors" />,
      value: 0
    };
    modelComponentsData.push(initData);

    const sensorComponents = modelComponentPositions.filter(item => item.NeedFeedback === true);
    sensorComponents.forEach(element => {
      modelComponentsData.push({
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });

    return modelComponentsData.sort(compareValues('label'));
  };

  const onDropdownFilterFlowSequenceChange = e => {
    setCurrentFlowSequenceFilter(e.value);
    searchHandler(FLOW_SEQUENCE, e.value);
  };

  const onDropdownFilterSensorChange = e => {
    setSurrentSensorFilter(e.value);
    searchHandler(SENSOR, e.value);
  };

  const removeThresholdClick = thresholdId => {
    setActive(true);
    setThresholdId(thresholdId);
  };
  const DeleteThreshold = () => {
    let saveData = thresholds.SelectedThreshold;
    saveData.PerformDelete = true;
    saveData.ModelVersionId = ModelVersionId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.ModelVersionThresholdId = thresholdId;
    saveModelVersionThreshold(saveData, 'MODELVERSIONTHRESHOLDSDELETE');
  };

  const handleClose = () => {
    setActive(false);
    setThresholdId(0);
  };

  const handleConfirm = () => {
    DeleteThreshold();
    handleClose();
  };

  let messageId = (thresholds && thresholds.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'modelVersion.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelVersion.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSIONTHRESHOLDSEDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELVERSIONTHRESHOLDSDELETE');
  const dialogTitle = intl.formatMessage({
    id: 'modelVersion.confirmDeleteThreshold',
    defaultMessage: 'Are you sure you want to delete the threshold?'
  });

  return (
    <div className={s.modelVersion}>
      {thresholds.isLoading && <LoadingSpinner />}
      {thresholds.showBanner && (
        <Banner failureText={messageText} showBanner={thresholds.showBanner} status={thresholds.isOpSuccessful} successText={messageText} />
      )}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <div className={s.contentWrapper}>
        <Row className={s.topAndSideOnlyRow}>
          <Col sm={12}>
            <Form.Label>
              <FormattedMessage id="modelVersion.filterBy" defaultMessage="Filter By" />
            </Form.Label>
          </Col>
        </Row>
        <Row className={s.sideRow}>
          <Col sm={6} lg={4}>
            <Row>
              <Col sm={6}>
                <Dropdown
                  id="filterFlowSequence"
                  dataArray={flowSequenceOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="modelVersion.allFlowSequence" defaultMessage="All flow sequences" />,
                    customClassName: ''
                  }}
                  disabled={thresholds.showAddEditScreen}
                  onDropdownChange={onDropdownFilterFlowSequenceChange}
                  selectedOption={flowSequenceOptions().filter(option => option.value === currentFlowSequenceFilter)}
                  data-unittest="filterFlowSequence"
                />
              </Col>
              <Col sm={6}>
                <Dropdown
                  id="filtersensor"
                  dataArray={modelComponentsOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="modelVersion.allSensor" defaultMessage="All sensors" />,
                    customClassName: ''
                  }}
                  disabled={thresholds.showAddEditScreen}
                  onDropdownChange={e => onDropdownFilterSensorChange(e)}
                  selectedOption={modelComponentsOptions().filter(option => option.value === currentSensorFilter)}
                  data-unittest="filtersensor"
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6} lg={8}>
            {!ReadOnly ? (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'MODELVERSIONTHRESHOLDSADD')}
                onClick={setAddHandler}
                disabled={thresholds.showAddEditScreen}
                data-unittest="addThresholdButton"
              >
                + <FormattedMessage id="modelVersion.addNew" defaultMessage="ADD NEW" />
              </Button>
            ) : (
              ''
            )}
          </Col>
        </Row>

        <div>
          <ModelVersionThresholdsListTable
            setEditHandler={setEditHandler}
            selectedModelVersionId={currentEditId}
            saveModelVersionThreshold={saveModelVersionThreshold}
            modelVersionThreshold={thresholds}
            describeModelVersionThreshold={describeModelVersionThreshold}
            changeModelVersionThreshold={changeModelVersionThreshold}
            flowSequences={flowSequences}
            modelComponentPositions={modelComponentPositions}
            thresholdsData={getSettings()}
            UpdateAllowed={isUpdateAllowed}
            DeleteAllowed={isDeleteAllowed}
            disableMode={thresholds.showAddEditScreen}
            disableModeHandler={disableModeHandler}
            removeThreshold={removeThresholdClick}
            ReadOnly={ReadOnly}
          />
        </div>

        {((!isFilter && thresholdLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? thresholdLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

ModelVersionThresholdsList.defaultProps = {
  ...initialModelVersionState
};

ModelVersionThresholdsList.propTypes = {
  describeModelVersionThreshold: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionThresholdsList);
