import React from 'react';
import Slider from 'react-rangeslider';
import './RangeSlider.css';

//** docs **/
/* 
    https://github.com/whoisandy/react-rangeslider
*/

const RangeSlider = props => {
  return (
    <div className="slider custom-labels">
      <Slider
        min={props.min}
        max={props.max}
        value={props.value}
        labels={props.labels}
        format={props.format}
        handleLabel={props.handleLabel}
        onChange={props.onChange}
        orientation={props.orientation}
      />
    </div>
  );
};

export default RangeSlider;
