import { initialApprovedComponentState } from './initialState';

import {
  APPROVEDCOMPONENT_ERROR,
  GET_ALL_APPROVEDCOMPONENT_DETAILS,
  APPROVEDCOMPONENT_IS_LOADING,
  SET_APPROVEDCOMPONENT_CURRENT_PAGE,
  SET_APPROVEDCOMPONENT_PAGE_FILTER,
  SET_APPROVEDCOMPONENT_CHANGE,
  SET_APPROVEDCOMPONENT,
  UPDATE_APPROVEDCOMPONENT_SAVE_STATUS,
  APPROVEDCOMPONENT_CLEANUP,
  SET_APPROVEDCOMPONENT_FILTERED_RESULTS,
  SHOW_APPROVEDCOMPONENT_SUB_COMPONENT_ADD_EDIT_SCREEN,
  SET_APPROVED_SUB_COMPONENT_LIST,
  SET_APPROVED_SUB_COMPONENT_CHANGE
} from '../constants/index';

const approvedComponentReducer = (state = initialApprovedComponentState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_APPROVEDCOMPONENT_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          ...data
        }
      };
    }

    case SET_APPROVEDCOMPONENT_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          currentPage
        }
      };
    }

    case SET_APPROVEDCOMPONENT_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          filter
        }
      };
    }

    case UPDATE_APPROVEDCOMPONENT_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          ...data
        }
      };
    }

    case APPROVEDCOMPONENT_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          isLoading: isLoading
        }
      };
    }

    case APPROVEDCOMPONENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          ...data
        }
      };
    }

    case SET_APPROVEDCOMPONENT: {
      let {
        calibrationEquations,
        modelComponentTypes,
        modelSubComponentTypes,
        selectedApprovedComponent,
        isOpSuccessful,
        showBanner
      } = payload;

      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          calibrationEquations: calibrationEquations,
          modelComponentTypes: modelComponentTypes,
          modelSubComponentTypes: modelSubComponentTypes,
          selectedApprovedComponent: {
            ...state.approvedComponent.selectedApprovedComponent,
            ...selectedApprovedComponent
          },
          isOpSuccessful: isOpSuccessful,
          showBanner: showBanner
        }
      };
    }

    case SHOW_APPROVEDCOMPONENT_SUB_COMPONENT_ADD_EDIT_SCREEN: {
      let { showAddEditScreen } = payload;

      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          selectedApprovedComponent: {
            ...state.approvedComponent.selectedApprovedComponent,
            showAddEditScreen: showAddEditScreen
          }
        }
      };
    }

    case SET_APPROVEDCOMPONENT_CHANGE: {
      let { selectedApprovedComponent, isOpSuccessful } = payload;

      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          selectedApprovedComponent: {
            ...state.approvedComponent.selectedApprovedComponent,
            ...selectedApprovedComponent
          },
          isOpSuccessful: isOpSuccessful || false
        }
      };
    }

    case SET_APPROVED_SUB_COMPONENT_CHANGE: {
      let { selectedSubApprovedComponent } = payload;

      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          selectedApprovedComponent: {
            ...state.approvedComponent.selectedApprovedComponent,
            SelectedApprovedSubComponent: {
              ...state.approvedComponent.selectedApprovedComponent.SelectedApprovedSubComponent,
              ...selectedSubApprovedComponent
            }
          }
        }
      };
    }
    case SET_APPROVED_SUB_COMPONENT_LIST: {
      let { ApprovedSubComponentsData } = payload;

      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          selectedApprovedComponent: {
            ...state.approvedComponent.selectedApprovedComponent,
            ApprovedSubComponentsData: ApprovedSubComponentsData
          }
        }
      };
    }

    case SET_APPROVEDCOMPONENT_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          ...filteredResults
        }
      };
    }

    case APPROVEDCOMPONENT_CLEANUP: {
      let { approvedComponent } = payload;
      return {
        ...state,
        approvedComponent: {
          ...state.approvedComponent,
          ...approvedComponent
        }
      };
    }

    default:
      return state;
  }
};

export default approvedComponentReducer;
