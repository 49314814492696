import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  RELEASENOTES_ERROR,
  GET_ALL_RELEASENOTES_DETAILS,
  RELEASENOTES_IS_LOADING,
  SET_RELEASENOTES_CURRENT_PAGE,
  SET_RELEASENOTES_PAGE_FILTER,
  SET_RELEASENOTES_CHANGE,
  SET_RELEASENOTES_CLEANUP,
  SET_RELEASENOTE,
  SET_RELEASENOTES_VIEW,
  UPDATE_RELEASENOTES_SAVE_STATUS,
  SET_RELEASENOTES_FILTERED_RESULTS
} from '../constants/index';

import { RELEASENOTES_MANAGEMENT, CREATE_RELEASENOTES, UPDATE_RELEASENOTES } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setReleaseNoteErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setReleaseNoteError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setReleaseNoteError(messageCode, false));
  }, 2500);
};

export const setReleaseNoteError = (messageCode, status) => {
  return {
    type: RELEASENOTES_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        isRedirect: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: RELEASENOTES_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getReleaseNoteListThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getReleaseNoteList(filterObj, widgetCode));
};

export const getReleaseNoteList = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/releasenotes`,
    method: 'GET',
    data: {
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllReleaseNoteListThunk(retData),
    onFailure: err => setReleaseNoteErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllReleaseNoteListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData && retData.retData.data) || [];
  dispatch(setAllReleaseNoteList(messageCode, data));
};

export const setAllReleaseNoteList = (messageCode, data) => ({
  type: GET_ALL_RELEASENOTES_DETAILS,
  payload: {
    data: {
      releaseNotes: data,
      filteredResults: data,
      isLoading: false,
      displayMessageCode: messageCode,
      isRedirect: false,
      showBanner: false
    }
  }
});

export const getReleaseNoteViewThunk = widgetCode => dispatch => {
  dispatch(getReleaseNoteView(widgetCode));
};

export const getReleaseNoteView = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/releasenotes`,
    method: 'GET',
    data: {
      IsView: true
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllReleaseNoteViewThunk(retData),
    onFailure: err => setReleaseNoteErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllReleaseNoteViewThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData && retData.retData.data) || [];
  dispatch(setAllReleaseNoteView(messageCode, data));
};

export const setAllReleaseNoteView = (messageCode, data) => ({
  type: SET_RELEASENOTES_VIEW,
  payload: {
    data: data
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_RELEASENOTES_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_RELEASENOTES_PAGE_FILTER,
  payload: { filter }
});

export const describeReleaseNoteThunk = (releaseNoteId, widgetCode) => dispatch => {
  dispatch(describeReleaseNote(releaseNoteId, widgetCode));
};

export const describeReleaseNote = (releaseNoteId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/releasenotes`,
    data: {
      ReleaseNoteId: releaseNoteId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setReleaseNoteThunk(retData),
    onFailure: err => setReleaseNoteErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setReleaseNoteThunk = retData => dispatch => {
  let data = (retData && retData.retData && retData.retData.data) || [];
  dispatch(setReleaseNote(data));
};

export const setReleaseNote = data => ({
  type: SET_RELEASENOTE,
  payload: {
    data: data
  }
});

export const saveReleaseNoteThunk = (data, widgetCode) => dispatch => {
  let url = '/management/releasenotes/save';
  let actionName = data.ReleaseNoteId === 0 ? CREATE_RELEASENOTES : UPDATE_RELEASENOTES;
  let log = logEntry(RELEASENOTES_MANAGEMENT, actionName, data);
  dispatch(saveReleaseNote(data, url, log, widgetCode));
};

export const saveReleaseNote = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setReleaseNoteStatusThunk(retData),
    onFailure: err => setReleaseNoteErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setReleaseNoteStatusThunk = message => dispatch => {
  dispatch(setReleaseNoteStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setReleaseNoteStatus(message, false));
  }, 2500);
};

export const setReleaseNoteStatus = (messageCode, status) => ({
  type: UPDATE_RELEASENOTES_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: !status,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setReleaseNoteChangeThunk = retData => dispatch => {
  dispatch(setReleaseNoteChange(retData));
};

export const setReleaseNoteChange = retData => ({
  type: SET_RELEASENOTES_CHANGE,
  payload: retData
});

export const setReleaseNoteCleanUpThunk = selectedReleaseNote => dispatch => {
  dispatch({ type: SET_RELEASENOTES_CLEANUP, payload: selectedReleaseNote });
};

export const setReleaseNoteFilteredDataThunk = retData => dispatch => {
  dispatch(setReleaseNoteFilteredData(retData));
};

export const setReleaseNoteFilteredData = retData => ({
  type: SET_RELEASENOTES_FILTERED_RESULTS,
  payload: retData
});

export const setDeleteReleaseNoteListThunk = data => dispatch => {
  let releaseNoteData = (data && data.releaseNoteData) || [];
  let filteredResultData = (data && data.filteredResultData) || [];
  dispatch(setDeleteReleaseNoteList(releaseNoteData, filteredResultData));
};

export const setDeleteReleaseNoteList = (releaseNoteData, filteredResultData) => ({
  type: GET_ALL_RELEASENOTES_DETAILS,
  payload: {
    data: {
      releaseNotes: releaseNoteData,
      filteredResults: filteredResultData,
      isLoading: false,
      isRedirect: false,
      showBanner: false
    }
  }
});
