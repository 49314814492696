import React from 'react';
import PropTypes from 'prop-types';
import s from './UnitServiceList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { useSelector } from 'react-redux';
import { getUserProfileWidget } from '../../selectors/index';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const UnitServiceListTable = ({ paginatedData, onSortOrder, asendingOrder, currentTimezone }) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'UNIT_SERVICE_VIEW');
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="maintenance.unitService.unit" defaultMessage="Unit" />
            </th>
            <th>
              <FormattedMessage id="maintenance.unitService.checklist" defaultMessage="Checklist" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="maintenance.unitService.serviceDate" defaultMessage="Service Date" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="unitServiceDetail.serviceStatus" defaultMessage="Service Status" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="maintenance.unitService.servicedBy" defaultMessage="Serviced By" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.UnitServiceId}>
                <td>{n.UnitName || ''}</td>
                <td>{n.ChecklistName || ''}</td>
                <td>
                  {n.ServiceDateTime
                    ? moment
                        .unix(n.ServiceDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                </td>
                <td className="d-none d-md-table-cell">{n.UnitServiceStatus || ''}</td>
                <td className="d-none d-md-table-cell">{n.UserName || ''}</td>
                <td>
                  {isViewAllowed ? (
                    <Link to={`/maintenance/service-view/${n.ChecklistId}/${n.UnitServiceId}`}>
                      <Button variant="outline-secondary" className={s.changeButton}>
                        <FormattedMessage id="maintenance.unitService.view" defaultMessage="VIEW" />
                      </Button>
                    </Link>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

UnitServiceListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

UnitServiceListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(UnitServiceListTable);
