import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import s from './LoadingSpinner.module.scss';

const LoadingSpinner = ({ className, centeredLoading = true }) => {
  const centeredLoadingClass = centeredLoading ? s.loadingContainer : '';

  return (
    <div className={centeredLoadingClass}>
      <Spinner animation="border" className={[s.spinner, className].join(' ')} />
    </div>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string
};

export default LoadingSpinner;
