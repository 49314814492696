import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionServicePlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelVersionState } from '../../reducers/initialState';
import uuidv4 from 'uuid/v4';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import Cookies from 'js-cookie';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const ModelVersionServicePlan = ({
  ModelVersionServicePlanId,
  ModelVersionId,
  saveModelVersionServicePlan,
  modelVersionServicePlan,
  changeModelVersionServicePlan,
  cancelHandler,
  checklistTemplate,
  currentTimezone,
  setEditHandler,
  intl
}) => {
  let IsEdit = false;
  const [localformErrors, setFormErrors] = useState({});
  const [commandId, setCommandId] = useState(0);
  const [active, setActive] = useState(false);
  const [showApplySchedule, setShowApplySchedule] = useState(true);
  const [updatedDateTime, setUpdatedDateTime] = useState(null);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  if (ModelVersionServicePlanId) {
    IsEdit = true;
  }

  // Update redux store
  const setLocalModelVersionServicePlan = currentState => {
    changeModelVersionServicePlan(currentState);
  };

  let widgetCode = IsEdit ? 'MODELVERSIONSERVICEPLANSEDIT' : 'MODELVERSIONSERVICEPLANSADD';

  const checklistTemplatesOptions = () => {
    let checklistTemplatesData = [];
    checklistTemplate.ChecklistTemplates.forEach(element => {
      if (
        modelVersionServicePlan &&
        modelVersionServicePlan.ServicePlansList.filter(x => x.ChecklistTemplateId === element.ChecklistTemplateId).length === 1
      ) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }
      checklistTemplatesData.push({
        ...element,
        label: element.Name,
        value: element.ChecklistTemplateId
      });
    });

    let sortedData = checklistTemplatesData.sort(compareValues('label'));
    return [{ value: 0, label: 'None' }, ...sortedData];
  };

  const disablePastDates = current =>
    current.isAfter(
      moment()
        .tz(currentTimezone)
        .subtract(1, 'day')
    );

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = modelVersionServicePlan.SelectedServicePlan;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.ModelVersionServicePlanId = 0;
      saveData.ModelVersionId = ModelVersionId;
      saveData.GuidIdentifier = uuidv4();
    }

    saveModelVersionServicePlan(saveData, widgetCode, false);
  };

  let selServicePlanObj = null;
  if (modelVersionServicePlan.SelectedServicePlan.ChecklistTemplateId) {
    selServicePlanObj = checklistTemplate.ChecklistTemplates.find(
      x => x.ChecklistTemplateId === modelVersionServicePlan.SelectedServicePlan.ChecklistTemplateId
    );
  }

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;
    if (
      !modelVersionServicePlan ||
      !modelVersionServicePlan.SelectedServicePlan ||
      isRequired(modelVersionServicePlan.SelectedServicePlan.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'modelVersion.servicePlanNameRequired',
        defaultMessage: 'Service Name is required'
      });
      isValid = false;
    }

    if (
      !modelVersionServicePlan ||
      !modelVersionServicePlan.SelectedServicePlan ||
      isRequired(modelVersionServicePlan.SelectedServicePlan.Description, 1)
    ) {
      formErrors.Description = intl.formatMessage({
        id: 'modelVersion.servicePlanDesriptionRequired',
        defaultMessage: 'Service description is required'
      });
      isValid = false;
    }

    if (
      !modelVersionServicePlan ||
      !modelVersionServicePlan.SelectedServicePlan ||
      !modelVersionServicePlan.SelectedServicePlan.ChecklistTemplateId ||
      isRequired(modelVersionServicePlan.SelectedServicePlan.ChecklistTemplateId, 1)
    ) {
      formErrors.ChecklistTemplateId = intl.formatMessage({
        id: 'modelVersion.servicePlanCheckListTemplateRequired',
        defaultMessage: 'Checklist template is required'
      });
      isValid = false;
    }

    if (
      !modelVersionServicePlan ||
      !modelVersionServicePlan.SelectedServicePlan ||
      isRequired(modelVersionServicePlan.SelectedServicePlan.ScheduleByPeriod, 1)
    ) {
      formErrors.ScheduleByPeriod = intl.formatMessage({
        id: 'modelVersion.servicePlanSchedulePeriodRequired',
        defaultMessage: 'Schedule Period'
      });
      isValid = false;
    } else {
      if (
        modelVersionServicePlan.SelectedServicePlan &&
        modelVersionServicePlan.SelectedServicePlan.ScheduleByPeriod !== undefined &&
        modelVersionServicePlan.SelectedServicePlan.ScheduleByPeriod !== '' &&
        modelVersionServicePlan.SelectedServicePlan.ScheduleByPeriod < 1
      ) {
        formErrors.ScheduleByPeriod = intl.formatMessage({
          id: 'modelVersion.servicePlanSchedulePeriodValidate',
          defaultMessage: 'Schedule Period has to be more than 0'
        });
        isValid = false;
      }
    }

    if (
      !modelVersionServicePlan ||
      !modelVersionServicePlan.SelectedServicePlan ||
      !modelVersionServicePlan.SelectedServicePlan.StartDateTime ||
      isRequired(modelVersionServicePlan.SelectedServicePlan.StartDateTime, 1)
    ) {
      let respData = validateDate();
      if (!respData.validDate) {
        if (respData.convertedDate) {
          formErrors.StartDateTime = intl.formatMessage({
            id: 'modelVersion.servicePlanStartDateShouldBeGreaterThanOrEqualToTodaysDate',
            defaultMessage: "Start date must be greater than or equal to today's date"
          });
        } else {
          formErrors.StartDateTime = intl.formatMessage({
            id: 'modelVersion.servicePlanStartDateRequired',
            defaultMessage: 'Valid start date is required'
          });
        }

        isValid = false;
      } else {
        modelVersionServicePlan.SelectedServicePlan.StartDateTime = respData.convertedDate.unix();
      }
    }
    setFormErrors(formErrors);
    return isValid;
  };

  //clean up
  useEffect(() => {
    return () => {
      setEditHandler(-1);
    };
  }, []);

  //on control value change
  const onChange = e => {
    setShowApplySchedule(false);
    setLocalModelVersionServicePlan({
      ...modelVersionServicePlan,
      SelectedServicePlan: { ...modelVersionServicePlan.SelectedServicePlan, [e.target.name]: e.target.value }
    });
  };

  const onDropdownChange = e => {
    setShowApplySchedule(false);
    setLocalModelVersionServicePlan({
      SelectedServicePlan: { ...modelVersionServicePlan.SelectedServicePlan, ChecklistTemplateId: e.value }
    });
  };

  const onDateChange = newVal => {
    //Keeping this value in global variable to process it during  saving if newVal is string.
    setUpdatedDateTime(newVal);
    let dateTime = null;
    if (newVal && newVal.unix) {
      dateTime = newVal.unix();
    }
    setShowApplySchedule(false);
    setLocalModelVersionServicePlan({
      SelectedServicePlan: { ...modelVersionServicePlan.SelectedServicePlan, StartDateTime: dateTime }
    });
  };

  const validateDate = () => {
    //Converting datetime to unix time format if updatedDateTime != null and  modelVersionServicePlan.SelectedServicePlan.StartDateTime is null
    let respData = {
      convertedDate: null,
      validDate: false
    };
    if (!modelVersionServicePlan.SelectedServicePlan.StartDateTime && updatedDateTime != null) {
      let convertedDate = moment(updatedDateTime);
      if (convertedDate.isValid() && convertedDate.unix() && convertedDate.unix() > 0) {
        if (
          moment()
            .startOf('day')
            .unix() > convertedDate.unix()
        ) {
          respData.convertedDate = convertedDate;
          return respData;
        }
        setLocalModelVersionServicePlan({
          SelectedServicePlan: { ...modelVersionServicePlan.SelectedServicePlan, StartDateTime: convertedDate.unix() }
        });
        respData.convertedDate = convertedDate;
        respData.validDate = true;
        return respData;
      }
    }
    return respData;
  };

  let ddlClass = localformErrors && localformErrors.ChecklistTemplateId ? s.ddlError : '';

  const dialogTitle = intl.formatMessage({
    id: 'modelVersionServicePlan.confirmApplySchedule',
    defaultMessage: 'Do you wish to create the service schedules to all units for this version from the starting date?'
  });

  const handleClose = () => setActive(false);

  const handleCommand = commandId => {
    setActive(true);
    setCommandId(commandId);
  };
  const handleConfirm = () => {
    if (!validateInput()) {
      return;
    }
    handleClose();

    let saveData = modelVersionServicePlan.SelectedServicePlan;
    saveData.IsEdit = IsEdit;
    saveData.ApplyServiceSchedules = true;
    saveData.OrganisationId = Cookies.get(`selectedorganisationid-${envName}`);
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    saveModelVersionServicePlan(saveData, widgetCode, true);
  };

  return (
    <div className={s.modelversion}>
      <div className={s.modelversionContent}>
        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
        <Form>
          <div className={s.topRow}>
            <Row>
              <Col lg={4}>
                <Form.Group controlId="formServicePlanName">
                  <Form.Label>
                    <FormattedMessage id="modelVersionServicePlan.name" defaultMessage="Service Name" />
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="Name"
                    onChange={onChange}
                    value={modelVersionServicePlan.SelectedServicePlan.Name}
                    className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                  />
                  {localformErrors && localformErrors.Name && (
                    <p role="alert" className={s.error}>
                      {localformErrors.Name}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="formServicePlanDesc">
                  <Form.Label>
                    <FormattedMessage id="modelVersionServicePlan.description" defaultMessage="Description" />
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="Description"
                    onChange={onChange}
                    value={modelVersionServicePlan.SelectedServicePlan.Description}
                    className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                  />
                  {localformErrors && localformErrors.Description && (
                    <p role="alert" className={s.error}>
                      {localformErrors.Description}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="formServicePlanCheckList">
                  <Form.Label>
                    <FormattedMessage id="modelVersionServicePlan.checklistName" defaultMessage="Checklist Template" />
                  </Form.Label>
                  <Dropdown
                    id="formServicePlanName"
                    dataArray={checklistTemplatesOptions()}
                    controlData={{ placeholderText: 'Select Template', customClassName: ddlClass }}
                    onDropdownChange={onDropdownChange}
                    selectedOption={checklistTemplatesOptions().filter(
                      option => option.value === modelVersionServicePlan.SelectedServicePlan.ChecklistTemplateId
                    )}
                    disabled={IsEdit}
                    data-unittest="formServicePlan"
                  />
                  {localformErrors && localformErrors.ChecklistTemplateId && (
                    <p role="alert" className={s.error}>
                      {localformErrors.ChecklistTemplateId}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="formServicePlanScheduleByPeriod">
                  <Form.Label>
                    <FormattedMessage id="modelVersionServicePlan.scheduleByPeriod" defaultMessage="Schedule Period " /> (
                    {modelVersionServicePlan.SelectedServicePlan.ScheduleBy})
                  </Form.Label>
                  <InputNumber
                    name="ScheduleByPeriod"
                    step="1"
                    onInputChange={onChange}
                    value={modelVersionServicePlan.SelectedServicePlan.ScheduleByPeriod}
                    id="formServicePlanValue"
                    customClassName={`${localformErrors && localformErrors.ScheduleByPeriod ? s.formControlError : ''}`}
                  />
                  {localformErrors && localformErrors.ScheduleByPeriod && (
                    <p role="alert" className={s.error}>
                      {localformErrors.ScheduleByPeriod}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} lg={3}>
                <Form.Group controlId="formDueDate" className={s.customDtPicker}>
                  <Form.Label>
                    <FormattedMessage id="modelVersionServicePlan.startDateTime" defaultMessage="Start Date" />
                  </Form.Label>

                  <DateTime
                    isValidDate={disablePastDates}
                    onChange={m => onDateChange(m)}
                    timeFormat="HH:mm:ss"
                    dateFormat="DD-MMM-YYYY"
                    value={
                      modelVersionServicePlan.SelectedServicePlan.StartDateTime
                        ? moment.unix(modelVersionServicePlan.SelectedServicePlan.StartDateTime).tz(currentTimezone)
                        : null
                    }
                    closeOnSelect={true}
                  />
                  {localformErrors && localformErrors.StartDateTime && (
                    <p role="alert" className={s.error}>
                      {localformErrors.StartDateTime}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={ModelVersionServicePlanId ? 8 : 4} xs={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="modelVersionManagement.saveModelVersionThreshold" defaultMessage="SAVE" />
                </Button>
                <Button
                  variant="outline-secondary"
                  className={classNames(s.btnCancel)}
                  onClick={cancelHandler}
                  noValidate
                  data-unittest="saveCancel"
                >
                  <FormattedMessage id="modelVersionManagement.cancelModelVersionThreshold" defaultMessage="CANCEL" />
                </Button>
                {IsEdit && showApplySchedule && (
                  <Button
                    variant="primary"
                    className={classNames(s.margin5, s.btnApplyChanges)}
                    onClick={() => handleCommand()}
                    noValidate
                    data-unittest="saveData"
                  >
                    <FormattedMessage id="modelVersionServicePlan.applyScheduleModelVersionThreshold" defaultMessage="APPLY SCHEDULES" />
                  </Button>
                )}
              </Col>
              <Col lg={ModelVersionServicePlanId ? 7 : 5}></Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

ModelVersionServicePlan.defaultProps = {
  modelVersionServicePlan: {
    ...initialModelVersionState.modelVersionManager.selectedModelVersion.ServicePlans
  }
};

ModelVersionServicePlan.propTypes = {
  saveModelVersionServicePlan: PropTypes.func.isRequired,
  changeModelVersionServicePlan: PropTypes.func.isRequired,
  modelVersionServicePlan: PropTypes.object.isRequired,
  checklistTemplate: PropTypes.object,
  cancelHandler: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionServicePlan);
