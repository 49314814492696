import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import CommandButton from '../CommandButton/CommandButton';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import s from './CommandPanel.module.scss';
import bs from '../../styles/bootstrap-overrides.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { WidgetVisibility } from '../../utils/widgetManager';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { COMMAND_MODE_MANUAL, COMMAND_MODE_AUTO } from '../../constants/index';

const CommandPanel = ({ commandButtonStatus, sendCommand, intl, userProfileWidgets, flowSequences, isTaggedOut }) => {
  const [active, setActive] = useState(false);
  const [commandId, setCommandId] = useState(0);
  const [commandType, setCommandType] = useState(COMMAND_MODE_MANUAL);

  const handleClose = () => setActive(false);

  const handleCommand = (commandId, cType) => {
    setActive(true);
    setCommandId(commandId);
    setCommandType(cType);
  };

  const handleConfirm = () => {
    sendCommand(commandId, commandType);
    handleClose();
  };

  const commandButtons = flowSequences && flowSequences.filter(x => x.ShowCommand);

  const dialogTitle = intl.formatMessage({
    id: 'command.areyousureyouwanttoactionthecommand',
    defaultMessage: 'Are you sure you want to action the command?'
  });

  const commandStatus = commandButtonStatus || isTaggedOut === 1 ? true : false;

  return (
    <Fragment>
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <div className={s.commandPanel}>
        <h4>
          <FormattedMessage id="commandPanel.iotCommands" defaultMessage="IoT Commands" />
        </h4>

        <Row className={s.startStopButton}>
          {commandButtons &&
            commandButtons.map(button =>
              button.Name.toUpperCase() === 'START' || button.Name.toUpperCase() === 'STOP' ? (
                <Col
                  md={6}
                  xs={6}
                  className={s.commandbutton}
                  key={`commandPanel-${button.SequenceCode}`}
                  style={WidgetVisibility(userProfileWidgets, `IOTALPHA2COMMANDBUTTON_${button.SequenceCode}`)}
                >
                  <CommandButton
                    key={`command-${button.SequenceCode}`}
                    disabled={commandStatus}
                    sendCommand={handleCommand}
                    command={button.SequenceCode}
                    cType={COMMAND_MODE_MANUAL}
                    name={button.Name.toUpperCase()}
                    title={button.Description || button.Name.toUpperCase()}
                  />
                </Col>
              ) : (
                ''
              )
            )}
        </Row>

        <Row className={s.buttonHolder}>
          {commandButtons &&
            commandButtons.map(button =>
              button.Name.toUpperCase() !== 'START' && button.Name.toUpperCase() !== 'STOP' ? (
                <Col
                  className={s.commandbutton}
                  md={6}
                  xs={6}
                  key={`commandPanel-${button.SequenceCode}`}
                  style={WidgetVisibility(userProfileWidgets, `IOTALPHA2COMMANDBUTTON_${button.SequenceCode}`)}
                >
                  <CommandButton
                    key={`command-${button.SequenceCode}`}
                    disabled={commandStatus}
                    sendCommand={handleCommand}
                    command={button.SequenceCode}
                    name={button.Name.toUpperCase()}
                    cType={button.Name == 'AUTO' ? COMMAND_MODE_AUTO : COMMAND_MODE_MANUAL}
                    title={button.Description || button.Name.toUpperCase()}
                  />
                </Col>
              ) : (
                ''
              )
            )}
        </Row>
      </div>
    </Fragment>
  );
};

CommandPanel.defaultProps = {
  userProfileWidgets: {}
};

CommandPanel.propTypes = {
  commandButtonStatus: PropTypes.bool.isRequired,
  sendCommand: PropTypes.func.isRequired,
  flowSequences: PropTypes.array.isRequired,
  isTaggedOut: PropTypes.number.isRequired,
  userProfileWidgets: PropTypes.object.isRequired
};

export default injectIntl(CommandPanel);
