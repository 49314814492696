import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UnitConfiguration from './UnitConfiguration';
import {
  getUnitConfiguration,
  getUserProfileWidget,
  getUnitManager,
  getModelVersionManager,
  getCurrentTimezone
} from '../../selectors/index';
import { getModelComponentPositionsThunk } from '../../actions/modelVersion';
import { saveUnitDetailsThunk, getOrganisationRegionThunk, getUnitManagerAndEngineersThunk } from '../../actions/unitManager';
import { getSelectedUserProfileWidgetsThunk } from '../../actions/UserProfileWidgets';

const mapStateToProps = state => {
  return {
    modelVersionManager: getModelVersionManager(state),
    unitConfiguration: getUnitConfiguration(state),
    userProfileWidgets: getUserProfileWidget(state),
    unitManager: getUnitManager(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveUnitDetails: unitData => saveUnitDetailsThunk(unitData, 'EDIT_UNIT_INFO'),
      getModelComponentPositions: (modelId, onlyVirtual, telemetryComponent) =>
        getModelComponentPositionsThunk(modelId, onlyVirtual, telemetryComponent, 'UNIT_CONFIGURATION'),
      getSelectedUserProfileWidgets: (profileId, userName) => getSelectedUserProfileWidgetsThunk(profileId, userName, 'GENERALACCESS'),
      getOrganisationRegionByOrgId: regionId => getOrganisationRegionThunk('UNIT_CONFIGURATION', regionId),
      getUnitManagerAndEngineers: orgId => getUnitManagerAndEngineersThunk('UNIT_CONFIGURATION', orgId)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UnitConfiguration);
