import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import s from './Alarms.module.scss';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import { setSelectedUnit } from '../../actions/devices';
import { push } from 'connected-react-router';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import { ALARM_STATUS_PENDING, ALARM_STATUS_RESOLVED } from '../../constants/index';

const Alarms = ({ alarms, updateAllowed, currentTimezone }) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [asendingOrder, setAsendingOrder] = useState(false);

  const searchHandler = value => {
    setSearchFilter(value);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const filteredResults = () => {
    let filteredList = alarms || [];
    if (searchFilter !== '') {
      filteredList = filteredList.filter(function(item) {
        return item.EventTitle.toLowerCase().includes(searchFilter.toLowerCase());
      });
    }

    return filteredList;
  };

  let alarmsObj = Object.values(filteredResults());

  if (asendingOrder) {
    alarmsObj = alarmsObj.sort(compareValues('EventDateTime'));
  } else {
    alarmsObj = alarmsObj.sort(compareValues('EventDateTime', 'desc'));
  }

  return (
    <div>
      <div className={s.alarms}>
        <div className={s.contentWrapper}>
          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="alarms.alarmSearchName"
              />
            </Col>
            <Col sm={6} lg={8}></Col>
          </Row>

          <div>
            <AlarmsListTable
              alarms={alarmsObj}
              UpdateAllowed={updateAllowed}
              currentTimezone={currentTimezone}
              onSortOrder={onSortOrder}
              asendingOrder={asendingOrder}
              setSelectedUnit={setSelectedUnit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const AlarmsListTable = ({ alarms, UpdateAllowed, onSortOrder, asendingOrder, currentTimezone }) => {
  const dispatch = useDispatch();
  const onAlarmSelection = obj => {
    if (UpdateAllowed) {
      dispatch(setSelectedUnit(obj.UnitSerialNumber));
      dispatch(push(`/events/action/${obj.UnitSerialNumber}/${obj.EventActionId}`));
    }
  };

  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="alarms.dateReceived" defaultMessage="Date Received" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="alarms.eventCode" defaultMessage="Event Code" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="alarms.alarmName" defaultMessage="Name" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="alarms.status" defaultMessage="Status" />
            </th>
          </tr>
        </thead>
        <tbody>
          {alarms.map(n => {
            return (
              <tr
                key={n.EventActionId}
                data-unittest="alarmData"
                onClick={onAlarmSelection.bind(this, n)}
                className={
                  n.EventStatus === ALARM_STATUS_RESOLVED ? s.resolved : n.EventStatus === ALARM_STATUS_PENDING ? s.pending : s.new
                }
              >
                <td className="d-none d-md-table-cell">
                  {n.EventDateTime
                    ? moment(parseInt(n.EventDateTime))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                </td>
                <td>{n.EventCode || ''}</td>
                <td>{n.EventTitle || ''}</td>
                <td className={'d-none d-md-table-cell ' + s.status}>
                  <span
                    className={`${s.dot} ${
                      n.EventStatus === ALARM_STATUS_RESOLVED
                        ? s.dotResovled
                        : n.EventStatus === ALARM_STATUS_PENDING
                        ? s.dotPending
                        : s.dotNew
                    }`}
                  ></span>{' '}
                  {n.EventStatus || ''}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default injectIntl(Alarms);
