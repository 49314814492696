import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelFirmwareVersion.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { initialModelFirmwareVersionState } from '../../reducers/initialState';
import ModelUnitsTable from './ModelUnitsTable';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from '../Pagination/Pagination';

import { compareValues } from '../../utils';
import Cookies from 'js-cookie';

const ModelUnitsList = ({
  setModelUnitsCurrentPage,
  setModelUnitsPageFilter,
  selectedModelFirmwareVersion,
  getModelUnitsFilteredData,
  ModelId,
  widgetCode,
  intl,
  userProfileWidgets,
  currentTimezone
}) => {
  let filter = selectedModelFirmwareVersion.filter || '';
  let offset = selectedModelFirmwareVersion.offset || 0;
  let limit = selectedModelFirmwareVersion.limit || 1000;

  const ModelFirmwareVersionId = selectedModelFirmwareVersion.ModelFirmwareVersionId;
  const ModelComponentPositionId = selectedModelFirmwareVersion.ModelComponentPositionId;
  const [isFilter, setIsFilter] = useState(false);

  const [searchText, setSearchText] = useState('');

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    setModelUnitsCurrentPage(1);
  };

  const filterData = () => {
    let filteredList = selectedModelFirmwareVersion.ModelUnits;
    if (isFilter) {
      filteredList = selectedModelFirmwareVersion.ModelUnits.filter(function(item) {
        return item.UnitName.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getTableData = () => {
    let tableData = [];
    tableData = filterData();
    return tableData.sort(compareValues('Name'));
  };

  const onPageChangeHandler = currentPage => {
    setModelUnitsCurrentPage(currentPage);
  };

  useEffect(() => {
    if (ModelId && ModelComponentPositionId) {
      getModelUnitsFilteredData(ModelId, ModelFirmwareVersionId, ModelComponentPositionId, offset, limit, filter, widgetCode);
    }
  }, [getModelUnitsFilteredData, ModelId, ModelFirmwareVersionId, ModelComponentPositionId, offset, limit, filter, widgetCode]);

  let messageId = (selectedModelFirmwareVersion && selectedModelFirmwareVersion.displayMessageCode) || 'none';

  return (
    <div className={s.modelfirmwareversionContent}>
      <div className={`${s.titleRows}`}>
        <h3 data-unittest="headingLabel">{intl.formatMessage({ id: 'modelFirmwareVersion.modelUnits', defaultMessage: 'Model Units' })}</h3>
      </div>
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              initialText={filter}
              placeHolderTextId="modelFirmwareVersion.modelUnits"
            />
          </Col>
          <Col sm={6} lg={8}></Col>
        </Row>

        <div className="">
          <Pagination
            data={getTableData()}
            pageSize={DEFAULT_PAGE_SIZE}
            onPageChange={onPageChangeHandler}
            startingPage={selectedModelFirmwareVersion.currentPage}
          >
            <ModelUnitsTable FirmwareVersionData={getTableData()} currentTimezone={currentTimezone} />
          </Pagination>
          <div className={s.bottomRow}></div>
        </div>
      </div>
    </div>
  );
};

ModelUnitsList.defaultProps = {
  ...initialModelFirmwareVersionState
};

ModelUnitsList.propTypes = {
  getModelUnitsFilteredData: PropTypes.func.isRequired,
  setModelUnitsPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(ModelUnitsList);
