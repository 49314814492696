import React from 'react';
import PropTypes from 'prop-types';

import { geocode } from '../../utils';
import MapContext from './MapContext';

class Markers extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.markers = [];
    this.unitsCurrentlyBeingAddedToMap = [];
    this.clusterStyles = [
      {
        textColor: 'white',
        url: this.props.markerClusterIcon,
        height: 28,
        width: 25
      },
      {
        textColor: 'white',
        url: this.props.markerClusterIcon,
        height: 28,
        width: 25
      },
      {
        textColor: 'white',
        url: this.props.markerClusterIcon,
        height: 28,
        width: 25
      },
      {
        textColor: 'white',
        url: this.props.markerClusterIcon,
        height: 28,
        width: 25
      },
      {
        textColor: 'white',
        url: this.props.markerClusterIcon,
        height: 28,
        width: 25
      },
      {
        textColor: 'white',
        url: this.props.markerClusterIcon,
        height: 28,
        width: 25
      }
    ];
    this.mcOptions = {
      gridSize: 30,
      styles: this.clusterStyles,
      maxZoom: 20
    };

    this.markerCluster = null;
  }

  async componentDidMount() {
    this.markerCluster = new this.props.markerCluster(this.map, null, this.mcOptions);
  }

  async componentDidUpdate() {
    this.removeExtraMarkers();

    for (let device of this.props.devices) {
      if (!this.isUnitCurrentlyBeingAddedToMap(device.UnitNumber)) {
        this.unitsCurrentlyBeingAddedToMap.push(device.UnitNumber);

        let markerObj = this.getMarkerByUnitNumber(device.UnitNumber);
        if (markerObj) {
          if (markerObj.icon && markerObj.icon.url && device.icon && markerObj.icon.url !== device.icon) {
            this.markerCluster.removeMarkers(this.markers);
            this.markerCluster.clearMarkers();
            await this.reDraw(markerObj, device);
            this.markerCluster.addMarkers(this.markers);
          }
        } else {
          await this.draw(device);
          this.markerCluster.removeMarkers(this.markers);
          this.markerCluster.clearMarkers();

          this.markerCluster.addMarkers(this.markers);
        }

        this.removeUnitCurrentlyBeingAddedToMap(device.UnitNumber);
      }
    }

    // if(this.markerCluster && this.markerCluster.removeMarkers){
    //   this.markerCluster.removeMarkers();
    // }
  }

  clearAllMarkers = () => {
    if (this.markers && this.markers.length > 0) {
      this.markers.forEach(function(markerObj) {
        if (markerObj) {
          markerObj.setMap(null);
          markerObj = null;
        }
      });
    }
    this.markers = [];
  };

  setupComponent = map => {
    this.map = map;
  };

  //creating marker
  createMarker = async device => {
    try {
      let latLng = { ...device.position };

      if (!latLng.lng) {
        try {
          let results = await geocode(device.address);
          latLng = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
        } catch (err) {
          latLng = { lat: -37.840935, lng: 144.946457 }; //Melbourn Lat and Lng
          if (this.props.defaultLocation && this.props.defaultLocation.lng && this.props.defaultLocation.lat) {
            latLng = this.props.defaultLocation;
          }
          console.log('geographic location missing ' + JSON.stringify(this.props));
        }
      }

      //To position cutom markers on zooming
      let iconImage = new window.google.maps.MarkerImage(
        device.icon,
        null,
        new window.google.maps.Point(0, 0),
        new window.google.maps.Point(14, 14)
      );

      let marker = new window.google.maps.Marker({
        device: device,
        icon: iconImage,
        //map: this.map,
        position: latLng
      });

      if (this.props.onClick) {
        window.google.maps.event.addListener(marker, 'click', () => {
          this.props.onClick(marker);
        });
      }

      if (this.props.onDblclick) {
        window.google.maps.event.addListener(marker, 'dblclick', () => {
          this.props.onDblclick(marker);
        });
      }

      if (this.props.onMouseup) {
        window.google.maps.event.addListener(marker, 'mouseup', () => {
          this.props.onMouseup(marker);
        });
      }

      if (this.props.onMousedown) {
        window.google.maps.event.addListener(marker, 'mousedown', () => {
          this.props.onMousedown(marker);
        });
      }

      if (this.props.onMouseover) {
        window.google.maps.event.addListener(marker, 'mouseover', () => {
          this.props.onMouseover(marker);
        });
      }

      if (this.props.onMouseout) {
        window.google.maps.event.addListener(marker, 'mouseout', () => {
          this.props.onMouseout(marker);
        });
      }

      return marker;
    } catch (err) {
      console.log('geographic location missing ' + JSON.stringify(this.props));
      return null;
    }
  };

  draw = async device => {
    let markerObj = await this.createMarker(device);
    if (!markerObj) return;

    this.markers.push(markerObj);

    if (!this.boundary) {
      this.boundary = new window.google.maps.LatLngBounds();
    }
    this.boundary.extend(markerObj.position);
    this.map.fitBounds(this.boundary);

    this.adjustMapZooming();

    if (this.props.onMarkerDrawn) {
      this.props.onMarkerDrawn(markerObj);
    }
  };

  reDraw = async (marker, device) => {
    this.removeMarkerFromMap(marker);
    this.removeMarker(marker);

    let newMarker = await this.createMarker(device);

    if (!newMarker) return;

    this.replaceMarker(newMarker);

    if (this.props.onMarkerReDrawn) {
      this.props.onMarkerReDrawn(newMarker);
    }
  };

  isUnitCurrentlyBeingAddedToMap = unitNumber => {
    if (this.unitsCurrentlyBeingAddedToMap.filter(unitNo => unitNo === unitNumber).length > 0) {
      return true;
    }
    return false;
  };

  removeUnitCurrentlyBeingAddedToMap = unitNumber => {
    this.unitsCurrentlyBeingAddedToMap = this.unitsCurrentlyBeingAddedToMap.filter(unitNo => unitNo !== unitNumber);
  };

  removeMarkerFromMap = async markerObj => {
    if (!markerObj || !markerObj.setMap) return;
    markerObj.setMap(null);
  };

  adjustMapZooming = () => {
    //Adjusting Zoom
    var zoom = this.map.getZoom();
    this.map.setZoom(zoom > 12 ? 12 : zoom);
  };

  //Remove markers that are no longer part of device list
  removeExtraMarkers = () => {
    let delMarkers = this.markers.filter(
      markerObj => this.props.devices.filter(device => device.UnitNumber === markerObj.device.UnitNumber).length === 0
    );

    //Removing marker from map

    delMarkers.map(objMarker => {
      objMarker.setMap(null);
      this.markerCluster.removeMarker(objMarker);
      return objMarker;
    });

    let filteredMarkers = this.markers.filter(
      markerObj => this.props.devices.filter(device => device.UnitNumber === markerObj.device.UnitNumber).length > 0
    );
    this.markers = filteredMarkers;

    //Resetting  boundary if marker is deleted from map
    if (delMarkers.length > 0) {
      this.boundary = this.boundary = new window.google.maps.LatLngBounds();
      this.markers.map(marker => {
        this.boundary.extend(marker.position);
        if (this.props.onMarkerRemoved) {
          this.props.onMarkerRemoved(marker);
        }
        return marker;
      });
      if (this.boundary.getCenter().lat() === 0) {
        if (this.props.defaultLocation && this.props.defaultLocation.lng && this.props.defaultLocation.lat) {
          this.boundary.extend(this.props.defaultLocation);
        }
      }
      this.map.fitBounds(this.boundary);
      this.adjustMapZooming();
    }
  };

  getMarkerByUnitNumber = unitNumber => {
    let markers = this.markers.filter(markerObj => markerObj.device.UnitNumber === unitNumber);
    if (markers && markers.length > 0) {
      return markers[0];
    }
    return null;
  };

  replaceMarker = newMarker => {
    if (!newMarker || !newMarker.device) return;

    this.markers = this.markers.filter(marker => marker.device.UnitNumber !== newMarker.device.UnitNumber);
    this.markers.push(newMarker);
  };

  removeMarker = markerObj => {
    if (!markerObj || !markerObj.device) return;

    this.markers = this.markers.filter(marker => marker.device.UnitNumber !== markerObj.device.UnitNumber);
  };

  render() {
    return (
      <MapContext.Consumer>
        {map => {
          this.setupComponent(map);
        }}
      </MapContext.Consumer>
    );
  }
}

Markers.defaultProps = {
  devices: []
};

Markers.propTypes = {
  devices: PropTypes.array,
  onClick: PropTypes.func,
  onDblclick: PropTypes.func,
  onMouseup: PropTypes.func,
  onMousedown: PropTypes.func,
  onMouseover: PropTypes.func,
  onMouseout: PropTypes.func,
  onMarkerDrawn: PropTypes.func,
  onMarkerReDrawn: PropTypes.func
};

Markers.context = MapContext;
export default Markers;
