import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './FAQuestion.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const FAQuestionListTable = ({
  FAQuestionData,
  RemoveFAQuestion,
  ChangeFAQuestion,
  UpdateAllowed,
  DeleteAllowed,
  faqCategorySpecificQuestions,
  intl
}) => {
  return (
    <div>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            {!faqCategorySpecificQuestions && (
              <th>
                <FormattedMessage id="faQuestions.faqCategory" defaultMessage="FAQ Category" />
              </th>
            )}
            <th>
              <FormattedMessage id="faQuestions.question" defaultMessage="Question" />
            </th>
            <th>
              <FormattedMessage id="faQuestions.answer" defaultMessage="Answer" />
            </th>
            {(UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {FAQuestionData.map(n => {
            return (
              <Fragment key={n.FAQuestionId}>
                <tr data-unittest="faqCategoryQuestionData">
                  {!faqCategorySpecificQuestions && <td>{n.CategoryName}</td>}
                  <td>{n.Question}</td>
                  <td>{n.Answer}</td>
                  {(UpdateAllowed || DeleteAllowed) && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={() => {
                            ChangeFAQuestion(n.FAQuestionId);
                          }}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                        </Button>
                      )}
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.removeButton}
                          href="#"
                          onClick={() => {
                            RemoveFAQuestion(n.FAQuestionId);
                          }}
                          data-unittest="removeData"
                        >
                          <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

FAQuestionListTable.defaultProps = {
  FAQuestionData: []
};

FAQuestionListTable.propTypes = {
  FAQuestionData: PropTypes.array.isRequired,
  RemoveFAQuestion: PropTypes.func.isRequired,
  ChangeFAQuestion: PropTypes.func.isRequired,
  UpdateAllowed: PropTypes.bool,
  DeleteAllowed: PropTypes.bool
};

export default injectIntl(FAQuestionListTable);
