import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './MFAuthentication.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getIsLoading, getUserState } from '../../selectors/index';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import logoBlack from '../../assets/logo-black.svg';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import alert from '../../assets/alert.svg';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Cookies from 'js-cookie';
import { push } from 'connected-react-router';
import { submitSMSVerificationThunk } from '../../actions/user';
import VerificationCodeInput from '../WSAControls/VerificationCodeInput/VerificationCodeInput';
import { setUserDetails } from '../../actions/user';
import { initialUserState } from '../../reducers/initialState';
const MFAuthentication = ({ intl }) => {
  const dispatch = useDispatch();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let email = Cookies.get(`username-${envName}`) || '';
  let session = Cookies.get(`session-${envName}`) || '';

  useEffect(() => {
    return () => {
      dispatch(setUserDetails(initialUserState));
    };
  }, []);

  if (!email || !session) {
    dispatch(push('/login'));
  }

  const isLoading = useSelector(state => getIsLoading(state));
  const mFA = useSelector(state => getUserState(state));

  const apiMessage = mFA.loginError ? mFA.loginErrorMessage : null;
  let messageText = '';
  if (apiMessage) {
    messageText = intl.formatMessage({ id: apiMessage, defaultMessage: apiMessage });
  }

  let content = <CodeVerification isLoading={isLoading} messageText={messageText} email={email} session={session} />;

  return (
    <div className={s.wrapper}>
      <img src={logoBlack} alt="icon" className={classNames(s.hideDesktop, s.logo)} />
      <div className={s.contactSection}>
        <span>
          <FormattedMessage id="general.needHelp" defaultMessage="Need help?" />
        </span>
        <Button variant="outline-secondary">
          <FormattedMessage id="general.contactUsNow" defaultMessage="CONTACT US NOW" />
        </Button>
      </div>

      <div className={s.mFAuthentication}>
        <h1>
          {' '}
          <FormattedMessage id="mfa.logIn" defaultMessage="Log in" />
        </h1>
        {isLoading && <LoadingSpinner />}
        {content}
      </div>
    </div>
  );
};

const CodeVerification = ({ email, session, isLoading, messageText }) => {
  const [form, setForm] = useState({ verificationCode: '', userName: email });
  const [formErrors, setFormErrors] = useState({ verificationCode: false });
  const dispatch = useDispatch();

  const handleInput = e => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const verificationCodeError = form.verificationCode.toString().length !== 6;
    setFormErrors({
      verificationCode: verificationCodeError
    });

    if (!verificationCodeError) {
      dispatch(
        submitSMSVerificationThunk({ IsMFAVerification: true, username: email, session: session, verificationcode: form.verificationCode })
      );
    }
  };

  const handleCodeInput = value => {
    setForm({
      ...form,
      verificationCode: value
    });
  };

  const resendCodeHandler = () => {
    setForm({ verificationCode: '' });
    setFormErrors({ verificationCode: false, userName: false, session: false });
    dispatch(push('/login'));
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={10}>
            <div className={s.verificationInfo}>
              <FormattedMessage
                id="mfa.verificationSentEnterCode"
                defaultMessage="A verification code has been sent to your mobile. Please enter the verification code below."
              />
            </div>
          </Col>
        </Row>
        {messageText && (
          <Alert variant="light" className={s.errorContainer}>
            <img src={alert} alt="icon" /> <div>{messageText}</div>
          </Alert>
        )}
        <Row>
          <Col lg={10} sm={12}>
            <div className={s.verificationContainer}>
              <div className={s.verificationCode}>
                <FormattedMessage id="mFA.enterCode" defaultMessage="Enter Code" />
              </div>
              <VerificationCodeInput type="number" fields={6} value={form.verificationCode} onChange={handleCodeInput} />

              {formErrors.verificationCode && (
                <p role="alert" className={s.error}>
                  <FormattedMessage id="mFa.codeRequirement" defaultMessage="Invalid verification code" />
                </p>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={5} sm={12}>
            <Button disabled={isLoading} type="submit" className={s.mFAuthenticationButton}>
              {' '}
              <FormattedMessage id="mFa.confirm" defaultMessage="CONFIRM" />{' '}
            </Button>
          </Col>
          <Col lg={5} md={12}>
            <a onClick={resendCodeHandler} className={s.resendLink}>
              <span>
                <FormattedMessage id="mFA.resendCode" defaultMessage="RESEND CODE" />
              </span>
            </a>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default injectIntl(MFAuthentication);
