import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './TabNavigation.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const Tab = ({ selectedPage, pageComponent, url, title }) => (
  <Fragment>
    <Link className={classNames({ [s.active]: selectedPage === pageComponent })} to={url}>
      {title}
      {selectedPage === pageComponent && <span />}
    </Link>
  </Fragment>
);

const TabNavigation = ({ selectedPage, unitSerialNumber, userProfileWidgets }) => (
  <ul className={s.tabNav}>
    <li>
      <Tab
        url={`/unit/process-flow/${unitSerialNumber}`}
        title={<FormattedMessage id="tabNavigation.processFlow" defaultMessage="Process flow" />}
        selectedPage={selectedPage}
        pageComponent="processFlow"
      />
    </li>

    <li>
      <Tab
        url={`/events/${unitSerialNumber}`}
        title={<FormattedMessage id="tabNavigation.eventLog" defaultMessage="Event Log" />}
        selectedPage={selectedPage}
        pageComponent="eventLog"
      />
    </li>
    {IsWidgetAccessible(userProfileWidgets, 'UNIT_CONFIGURATION') && (
      <li>
        <Tab
          url={`/unit/unit-configuration/${unitSerialNumber}`}
          title={<FormattedMessage id="tabNavigation.unitConfiguration" defaultMessage="Unit Configuration" />}
          selectedPage={selectedPage}
          pageComponent="unitConfiguration"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'UNIT_CALIBRATION') && (
      <li>
        <Tab
          url={`/unit/calibrations/${unitSerialNumber}`}
          title={<FormattedMessage id="tabNavigation.calibration" defaultMessage="Calibration" />}
          selectedPage={selectedPage}
          pageComponent="calibration"
        />
      </li>
    )}

    {IsWidgetAccessible(userProfileWidgets, 'DATATRENDANALYSIS') && (
      <li>
        <Tab
          url={`/unit/data-trend-analysis/${unitSerialNumber}`}
          title={<FormattedMessage id="tabNavigation.dataTrendAnalysis" defaultMessage="Data trend analysis" />}
          selectedPage={selectedPage}
          pageComponent="dataTrend"
        />
      </li>
    )}

    {IsWidgetAccessible(userProfileWidgets, 'UNITREPORT') && (
      <li>
        <Tab
          url={`/unit/reports/${unitSerialNumber}`}
          title={<FormattedMessage id="tabNavigation.reports" defaultMessage="Reports" />}
          selectedPage={selectedPage}
          pageComponent="unitreports"
        />
      </li>
    )}

    {IsWidgetAccessible(userProfileWidgets, 'UNIT_SCHEDULE') && (
      <li>
        <Tab
          url={`/unit/schedule/${unitSerialNumber}`}
          title={<FormattedMessage id="tabNavigation.schedule" defaultMessage="Schedule" />}
          selectedPage={selectedPage}
          pageComponent="unitSchedule"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'UNIT_UNIT_SERVICE_TAB') && (
      <li>
        <Tab
          url={`/unit/service/${unitSerialNumber}`}
          title={<FormattedMessage id="tabNavigation.unitService" defaultMessage="Unit Service" />}
          selectedPage={selectedPage}
          pageComponent="unitService"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'DAILY_SUMMARY_DATA') && (
      <li>
        <Tab
          url={`/unit/summary/${unitSerialNumber}`}
          title={<FormattedMessage id="tabNavigation.summaryData" defaultMessage="Summary Data" />}
          selectedPage={selectedPage}
          pageComponent="dailySummary"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'UNIT_SETTINGS') && (
      <li>
        <Tab
          url={`/unit/unit-settings/${unitSerialNumber}`}
          title={<FormattedMessage id="tabNavigation.unitSettings" defaultMessage="Unit Settings" />}
          selectedPage={selectedPage}
          pageComponent="unitSettings"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'UNITSTATICDATALIST') && (
      <li>
        <Tab
          url={`/unit/static-data/${unitSerialNumber}`}
          title={<FormattedMessage id="tabNavigation.staticData" defaultMessage="Static Data" />}
          selectedPage={selectedPage}
          pageComponent="staticData"
        />
      </li>
    )}
  </ul>
);

TabNavigation.propTypes = {
  unitSerialNumber: PropTypes.string,
  selectedPage: PropTypes.string.isRequired
};

export default TabNavigation;
