import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  FAQ_ERROR,
  GET_ALL_FAQ_DETAILS,
  FAQ_IS_LOADING,
  SET_FAQ_CURRENT_PAGE,
  SET_FAQ_PAGE_FILTER,
  SET_FAQ_CHANGE,
  SET_FAQ,
  SET_FAQS,
  SET_FAQ_CLEANUP,
  UPDATE_FAQ_SAVE_STATUS
} from '../constants/index';

import { FAQ_MANAGEMENT, CREATE_FAQ, UPDATE_FAQ } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setFAQuestionErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setFAQuestionError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setFAQuestionError(messageCode, false));
  }, 2500);
};

export const setFAQuestionError = (messageCode, status) => {
  return {
    type: FAQ_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: FAQ_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getFAQuestionThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getFAQuestion(widgetCode, orgId));
};

export const getFAQuestion = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/faqs/questions`,
    method: 'GET',
    data: {
      OrgId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFAQuestionListThunk(retData),
    onFailure: err => setFAQuestionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFAQuestionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData && retData.retData.data) || [];
  let faqCategories = (retData && retData.retData && retData.retData.faqCategories) || [];
  dispatch(setAllFAQuestionList(messageCode, data, faqCategories));
};

export const setAllFAQuestionList = (messageCode, data, faqCategories) => ({
  type: GET_ALL_FAQ_DETAILS,
  payload: {
    data: {
      faQuestions: data,
      faqCategories: faqCategories,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_FAQ_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_FAQ_PAGE_FILTER,
  payload: { filter }
});

export const describeFAQuestionThunk = (faQuestionId, widgetCode, faqCategoryId = null) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeFAQuestion(faQuestionId, widgetCode, orgId, faqCategoryId));
};

export const describeFAQuestion = (faQuestionId, widgetCode, orgId, faqCategoryId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/faqs/questions`,
    data: {
      FAQuestionId: faQuestionId,
      OrgId: orgId,
      FAQCategoryId: faqCategoryId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setFAQuestionThunk(retData, faqCategoryId),
    onFailure: err => setFAQuestionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setFAQuestionThunk = (retData, faqCategoryId) => dispatch => {
  let data = (retData && retData.retData && retData.retData.data) || [];
  let faqCategories = (retData && retData.retData && retData.retData.faqCategories) || [];
  if (faqCategoryId) {
    dispatch(setFAQuestions(data, faqCategories));
  } else {
    dispatch(setFAQuestion(data, faqCategories));
  }
};

export const setFAQuestion = (data, faqCategories) => ({
  type: SET_FAQ,
  payload: {
    data: data,
    faqCategories: faqCategories
  }
});

export const setFAQuestions = (data, faqCategories) => ({
  type: SET_FAQS,
  payload: {
    data: data,
    faqCategories: faqCategories
  }
});

export const saveFAQuestionThunk = (data, widgetCode) => dispatch => {
  let url = '/management/faqs/questions/save';
  let actionName = data.FAQuestionId === 0 ? CREATE_FAQ : UPDATE_FAQ;
  let log = logEntry(FAQ_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveFAQuestion(data, url, log, widgetCode));
};

export const saveFAQuestion = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setFAQuestionStatusThunk(retData),
    onFailure: err => setFAQuestionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setFAQuestionStatusThunk = message => dispatch => {
  dispatch(setFAQuestionStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setFAQuestionStatus(message, false));
  }, 2500);
};

export const setFAQuestionStatus = (messageCode, status) => ({
  type: UPDATE_FAQ_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: true,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setFAQuestionChangeThunk = retData => dispatch => {
  dispatch(setFAQuestionChange(retData));
};

export const setFAQuestionChange = retData => ({
  type: SET_FAQ_CHANGE,
  payload: retData
});

export const setFAQuestionCleanUpThunk = selectedFAQuestion => dispatch => {
  dispatch({ type: SET_FAQ_CLEANUP, payload: selectedFAQuestion });
};
