import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  CALIBRATION_EQUATION_ERROR,
  GET_ALL_CALIBRATION_EQUATION_DETAILS,
  CALIBRATION_EQUATION_IS_LOADING,
  SET_CALIBRATION_EQUATION_CURRENT_PAGE,
  SET_CALIBRATION_EQUATION_PAGE_FILTER,
  SET_CALIBRATION_EQUATION,
  SET_CALIBRATION_EQUATION_CHANGE,
  SET_CALIBRATION_EQUATION_CLEANUP,
  UPDATE_CALIBRATION_EQUATION_SAVE_STATUS,
  SET_CALIBRATION_EQUATION_CONSTANT_CHANGE,
  SET_CALIBRATION_EQUATION_CONSTANT_LIST_CHANGE,
  SHOW_CALIBRATION_EQUATION_CONSTANT_ADD_EDIT_SCREEN
} from '../constants/index';

import { CALIBRATION_EQUATION_MANAGEMENT, CREATE_CALIBRATION_EQUATION, UPDATE_CALIBRATION_EQUATION } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setCalibrationEquationErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setCalibrationEquationError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setCalibrationEquationError(messageCode, false));
  }, 2500);
};

export const setCalibrationEquationCustomErrorThunk = (messageCode, status) => dispatch => {
  dispatch(setCalibrationEquationError(messageCode, true));
  setTimeout(() => {
    dispatch(setCalibrationEquationError('', false));
  }, 5000);
};

export const setCalibrationEquationError = (messageCode, status) => {
  return {
    type: CALIBRATION_EQUATION_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: CALIBRATION_EQUATION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getCalibrationEquationThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getCalibrationEquation(filterObj, widgetCode));
};

export const getCalibrationEquation = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/calibrationequations`,
    data: {
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllCalibrationEquationListThunk(retData),
    onFailure: err => setCalibrationEquationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllCalibrationEquationListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let calibrationEquation = (retData && retData.calibrationEquation) || [];
  dispatch(setAllCalibrationEquationList(messageCode, calibrationEquation));
};

export const setAllCalibrationEquationList = (messageCode, calibrationEquation) => ({
  type: GET_ALL_CALIBRATION_EQUATION_DETAILS,
  payload: {
    data: {
      CalibrationEquationList: calibrationEquation,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_CALIBRATION_EQUATION_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_CALIBRATION_EQUATION_PAGE_FILTER,
  payload: { filter }
});

export const describeCalibrationEquationThunk = (CalibrationEquationId, widgetCode) => dispatch => {
  dispatch(describeCalibrationEquation(CalibrationEquationId, widgetCode));
};

export const describeCalibrationEquation = (CalibrationEquationId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/calibrationequations`,
    data: {
      CalibrationEquationId: CalibrationEquationId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCalibrationEquationThunk(retData),
    onFailure: err => setCalibrationEquationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setCalibrationEquationThunk = ({ retData }) => dispatch => {
  let data = (retData.calibrationEquation && retData.calibrationEquation[0]) || null;
  let describeCalibration = {};
  if (data) {
    describeCalibration = {
      CalibrationEquationId: data.CalibrationEquationId,
      Name: data.Name,
      Description: data.Description,
      Formula: data.Formula,
      GuidIdentifier: data.GuidIdentifier,
      IsActive: data.IsActive,
      EquationConstants: {
        EquationConstantList: data.CalibrationConstants,
        SelectedEquationConstant: {
          CalibrationEquationConstantId: 0,
          CalibrationEquationId: 0,
          Name: '',
          Description: '',
          GuidIdentifier: '',
          isChanged: 0,
          IsActive: true,
          OrderNumber: 0
        },
        showAddEditScreen: false
      }
    };
  }

  dispatch(setCalibrationEquation(describeCalibration));
};

export const setCalibrationEquation = data => ({
  type: SET_CALIBRATION_EQUATION,
  payload: data
});

export const saveCalibrationEquationThunk = (CalibrationEquation, widgetCode) => dispatch => {
  let url = '/management/calibrationequations/save';
  let actionName = CalibrationEquation.CalibrationEquationId === 0 ? CREATE_CALIBRATION_EQUATION : UPDATE_CALIBRATION_EQUATION;
  let log = logEntry(CALIBRATION_EQUATION_MANAGEMENT, actionName, CalibrationEquation);
  dispatch(saveCalibrationEquation(CalibrationEquation, url, log, widgetCode));
};

export const saveCalibrationEquation = (CalibrationEquation, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCalibrationEquationStatusThunk(retData),
    onFailure: err => setCalibrationEquationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      CalibrationEquation: CalibrationEquation,
      log: log
    },
    widgetcode: widgetCode
  });

export const setCalibrationEquationStatusThunk = retData => dispatch => {
  let messageCode = retData;
  dispatch(setCalibrationEquationStatus(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setCalibrationEquationStatus('', false));
  }, 2500);
};

export const setCalibrationEquationStatus = (messageCode, status) => ({
  type: UPDATE_CALIBRATION_EQUATION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setCalibrationEquationChangeThunk = retData => dispatch => {
  dispatch(setCalibrationEquationChange(retData));
};

export const setCalibrationEquationChange = retData => ({
  type: SET_CALIBRATION_EQUATION_CHANGE,
  payload: retData
});

export const setCalibrationEquationCleanUpThunk = () => dispatch => {
  dispatch(setCalibrationEquationCleanUp());
};

export const setCalibrationEquationCleanUp = () => ({
  type: SET_CALIBRATION_EQUATION_CLEANUP,
  payload: {}
});

export const setCalibrationEquationConstantThunk = retData => dispatch => {
  dispatch(setCalibrationEquationConstantChange(retData));
};

export const setCalibrationEquationConstantChange = retData => ({
  type: SET_CALIBRATION_EQUATION_CONSTANT_CHANGE,
  payload: retData
});

export const showAddEditSreenThunk = status => dispatch => {
  dispatch(showAddEditSreen(status));
};

export const showAddEditSreen = status => ({
  type: SHOW_CALIBRATION_EQUATION_CONSTANT_ADD_EDIT_SCREEN,
  payload: { showAddEditScreen: status }
});

export const setCalibrationEquationConstantListThunk = retData => dispatch => {
  dispatch(setCalibrationEquationConstantList(retData));
};

export const setCalibrationEquationConstantList = retData => ({
  type: SET_CALIBRATION_EQUATION_CONSTANT_LIST_CHANGE,
  payload: retData
});
