import Cookies from 'js-cookie';
import { EVENT_IS_LOADING, EVENTS_ERROR, SET_EVENTS, UPDATE_EVENT_INPUT } from '../constants/index';
import { apiAction } from '../utils/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const fetchEventsThunk = (limit, view, from, to, code, type, widgetCode, descOrder) => (dispatch, getState) => {
  const { unitSerialNumber } = getState().devices;
  const data = {
    unitSerialNumber,
    limit,
    ...(from && { from }),
    ...(to && { to }),
    ...(type && { type }),
    ...(code && { code }),
    ...(descOrder && { descOrder })
  };
  dispatch(fetchEvents(data, view, widgetCode));
};

export const processEvents = ({ Items, LastEvaluatedKey }, { unitSerialNumber, limit }) => dispatch => {
  dispatch(setEvents(Items, LastEvaluatedKey, unitSerialNumber, limit));
};

export const setEvents = (Items, LastEvaluatedKey, unitSerialNumber, limit) => ({
  type: SET_EVENTS,
  payload: { unitSerialNumber, Items, LastEvaluatedKey, limit }
});

export const setEventsError = (unitSerialNumber, view) => ({
  type: EVENTS_ERROR,
  payload: { unitSerialNumber, view }
});

export const fetchEvents = (data, view, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${data.unitSerialNumber}/events`,
    method: 'GET',
    onSuccess: processEvents,
    onFailure: () => setEventsError(data.unitSerialNumber, view),
    onLoad: e => setIsLoading(e, data.unitSerialNumber, view),
    accessToken: Cookies.get(`access-${envName}`),
    data,
    widgetcode: widgetCode
  });

export const updateEventInputs = (unitSerialNumber, field, value) => ({
  type: UPDATE_EVENT_INPUT,
  payload: { unitSerialNumber, field, value }
});

export const setIsLoading = (status, unitSerialNumber, view) => ({
  type: EVENT_IS_LOADING,
  payload: { status, unitSerialNumber, view }
});
