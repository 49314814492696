import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import configureStore, { history, loadState, saveState, filterOfflineChartData } from './configureStore';
import throttle from 'lodash.throttle';

import './styles/reset.css';
import './styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const persistedState = loadState();

const store = configureStore(persistedState);

store.subscribe(
  throttle(() => {
    const { charts, devices } = store.getState();
    if (Object.keys(charts).length !== 0) {
      saveState({
        charts: filterOfflineChartData(charts),
        devices
      });
    }
  }, 2000)
);

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
);
