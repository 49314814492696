import { initialChecklistState } from './initialState';

import {
  CHECKLIST_ERROR,
  GET_ALL_CHECKLIST_DETAILS,
  CHECKLIST_IS_LOADING,
  SET_CHECKLIST_CURRENT_PAGE,
  SET_CHECKLIST_PAGE_FILTER,
  SET_CHECKLIST_CHANGE,
  SET_CHECKLIST,
  UPDATE_CHECKLIST_SAVE_STATUS,
  SET_CHECKLIST_FILTERED_RESULTS,
  GET_ALL_CHECKLIST_FILTERS
} from '../constants/index';

const checklistReducer = (state = initialChecklistState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_CHECKLIST_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        checklist: {
          ...state.checklist,
          ...data
        }
      };
    }

    case SET_CHECKLIST_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        checklist: {
          ...state.checklist,
          currentPage
        }
      };
    }

    case SET_CHECKLIST_PAGE_FILTER: {
      const { filterBy } = payload;
      return {
        ...state,
        checklist: {
          ...state.checklist,
          filterBy
        }
      };
    }

    case UPDATE_CHECKLIST_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        checklist: {
          ...state.checklist,
          ...data
        }
      };
    }

    case CHECKLIST_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        checklist: {
          ...state.checklist,
          isLoading: isLoading
        }
      };
    }

    case CHECKLIST_ERROR: {
      const { data } = payload;
      return {
        ...state,
        checklist: {
          ...state.checklist,
          ...data
        }
      };
    }

    case SET_CHECKLIST: {
      let { Tasks, SelectedChecklist } = payload;

      return {
        ...state,
        checklist: {
          ...state.checklist,
          isOpSuccessful: false,
          showBanner: false,
          Tasks: Tasks,
          SelectedChecklist: {
            ...state.checklist.SelectedChecklist,
            ...SelectedChecklist
          }
        }
      };
    }

    case SET_CHECKLIST_CHANGE: {
      let { SelectedChecklist } = payload;

      return {
        ...state,
        checklist: {
          ...state.checklist,
          isOpSuccessful: false,
          showBanner: false,
          SelectedChecklist: {
            ...state.checklist.SelectedChecklist,
            ...SelectedChecklist
          }
        }
      };
    }

    case SET_CHECKLIST_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        checklist: {
          ...state.checklist,
          ...filteredResults
        }
      };
    }

    case GET_ALL_CHECKLIST_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        checklist: {
          ...state.checklist,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default checklistReducer;
