import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import s from './UnitSettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialUnitManagerState } from '../../reducers/initialState';
import { useSelector, useDispatch } from 'react-redux';
import UnitSettingsListTable from './UnitSettingsListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { getUnitSettings, getSelectedUnit } from '../../selectors/index';

import { getUnitSettingsThunk, setUnitSettingsChangeThunk, postUnitSettingsThunk } from '../../actions/unitManager';

const regex = /^[a-zA-Z0-9 ]+$/;

const UnitSettingsList = ({ intl }) => {
  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;
  const dispatch = useDispatch();

  const unit = useSelector(state => getSelectedUnit(state));
  const unitSetting = useSelector(state => getUnitSettings(state));

  const userProfileWidgets = useSelector(state => state.userProfileWidgets);
  let initialRecords = unitSetting.unitSettings.length > 0 ? unitSetting.unitSettings.slice(0, initialLimit) : 0;
  const unitId = unit.UnitId || null;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    if (unitId) {
      dispatch(getUnitSettingsThunk(unitId, 'UNIT_SETTINGS_LIST'));
    }
  }, [getUnitSettingsThunk, dispatch, unitId]);
  const [localformErrors, setFormErrors] = useState({});
  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [searchText, setSearchText] = useState('');

  const localSettingLength = localSettings.length;
  const unitSettingLength = unitSetting.unitSettings.length;
  const addNewRef = useRef(null);

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(unitSetting.unitSettings);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = unitSetting.unitSettings.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const filterData = () => {
    let filteredList = unitSetting.unitSettings;
    if (isFilter) {
      filteredList = unitSetting.unitSettings.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getSettings = () => {
    let unitSettings = [];
    if (showAll) {
      unitSettings = filterData();
    } else {
      if (isFilter) {
        unitSettings = localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        unitSettings = initialRecords;
      }
    }
    return unitSettings.length > 0 ? unitSettings.sort(compareValues('Name')) : [];
  };

  const changeUnitSetting = data => {
    if (data && regex.test(data.value)) {
      dispatch(setUnitSettingsChangeThunk(data));
    }
  };

  const handleCommand = commandId => {
    setActive(true);
  };
  const handleClose = () => {
    setActive(false);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;
    let validateData = unitSetting.unitSettings || [];
    validateData.forEach(element => {
      let validateResult = isValidValue(element);
      if (!validateResult.status) {
        formErrors['uval_' + element.UnitSettingId] = validateResult.errMessage;
        isValid = false;
      }
    });
    setFormErrors(formErrors);

    return isValid;
  };

  const isValidValue = keydata => {
    let result = {
      status: true,
      errMessage: ''
    };

    if (!regex.test(keydata.Value)) {
      result.errMessage = intl.formatMessage({
        id: 'unitsettings.invalidValue',
        defaultMessage: 'Invalid value'
      });
      result.status = false;
      return result;
    }
    if (
      keydata.MinimumValue &&
      Number(keydata.MinimumValue) != 'NaN' &&
      Number(keydata.Value) != 'NaN' &&
      Number(keydata.Value) < Number(keydata.MinimumValue)
    ) {
      result.errMessage = intl.formatMessage({
        id: 'unitsettings.invalidMinRange',
        defaultMessage: 'Value below minimum range'
      });
      result.status = false;
      return result;
    }
    if (
      keydata.MaximumValue &&
      Number(keydata.MaximumValue) != 'NaN' &&
      Number(keydata.Value) != 'NaN' &&
      Number(keydata.Value) > Number(keydata.MaximumValue)
    ) {
      result.errMessage = intl.formatMessage({
        id: 'unitsettings.invalidMaxRange',
        defaultMessage: 'Value above maximum range'
      });
      result.status = false;
      return result;
    }
    return result;
  };

  const handleConfirm = () => {
    handleClose();
    if (!validateInput()) {
      return;
    }
    let saveData = {};
    saveData.UnitId = unitId;
    saveData.UnitSettings = unitSetting.unitSettings;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(postUnitSettingsThunk(saveData, 'UNIT_SETTINGS_UPDATE'));
  };

  let messageId = (unitSetting && unitSetting.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'UNIT_SETTINGS_UPDATE');

  const dialogTitle = intl.formatMessage({
    id: 'unitsettings.saveAllConfirmation',
    defaultMessage: 'Do you wish to update all your changes?'
  });

  return (
    <div className={s.unitSettings}>
      {unitSetting.isLoading && <LoadingSpinner />}
      {unitSetting.showBanner && (
        <Banner
          failureText={messageText}
          showBanner={unitSetting.showBanner}
          status={unitSetting.isOpSuccessful}
          successText={messageText}
        />
      )}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              disabled={false}
              // initialText={filter}
              placeHolderTextId="unitsettings.search"
              data-unittest="searchUnitSettings"
            />
          </Col>
          <Col sm={6} lg={8}>
            {isUpdateAllowed ? (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'UNIT_SETTINGS_UPDATE')}
                onClick={() => handleCommand()}
                data-unittest="updateUnitSettingsButton"
              >
                <FormattedMessage id="common.update" defaultMessage="UPDATE" />
              </Button>
            ) : (
              ''
            )}
          </Col>
        </Row>

        <div>
          <UnitSettingsListTable
            changeUnitSetting={changeUnitSetting}
            UpdateAllowed={isUpdateAllowed}
            unitSettingsData={getSettings()}
            localformErrors={localformErrors}
          />
        </div>
        {((!isFilter && unitSettingLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? unitSettingLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

UnitSettingsList.defaultProps = {
  ...initialUnitManagerState
};

UnitSettingsList.propTypes = {};

export default injectIntl(UnitSettingsList);
