import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  MODELCOMPONENTPOSITION_ERROR,
  GET_ALL_MODELCOMPONENTPOSITION_DETAILS,
  MODELCOMPONENTPOSITION_IS_LOADING,
  SET_MODELCOMPONENTPOSITION_CURRENT_PAGE,
  SET_MODELCOMPONENTPOSITION_PAGE_FILTER,
  SET_MODELCOMPONENTPOSITION_CHANGE,
  SET_MODELCOMPONENTPOSITION,
  UPDATE_MODELCOMPONENTPOSITION_SAVE_STATUS,
  MODELCOMPONENTPOSITION_CLEANUP,
  SET_MODELCOMPONENTPOSITION_FILTERED_RESULTS
} from '../constants/index';

import {
  MODELCOMPONENTPOSITION_MANAGEMENT,
  CREATE_MODELCOMPONENTPOSITION,
  UPDATE_MODELCOMPONENTPOSITION,
  DELETE_MODELCOMPONENTPOSITION
} from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setModelComponentPositionErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setModelComponentPositionError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelComponentPositionError(messageCode, false));
  }, 2500);
};

export const setModelComponentPositionError = (messageCode, status) => {
  return {
    type: MODELCOMPONENTPOSITION_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: MODELCOMPONENTPOSITION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getModelComponentPositionThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getModelComponentPosition(filterObj, widgetCode));
};

export const getModelComponentPosition = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelComponentPositions`,
    data: {
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllModelComponentPositionListThunk(retData),
    onFailure: err => setModelComponentPositionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllModelComponentPositionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelComponentPositions = (retData && retData.modelComponentPositions) || [];
  dispatch(setAllModelComponentPositionList(messageCode, modelComponentPositions));
};

export const setAllModelComponentPositionList = (messageCode, modelComponentPositions) => ({
  type: GET_ALL_MODELCOMPONENTPOSITION_DETAILS,
  payload: {
    data: {
      modelComponentPositions: modelComponentPositions,
      filteredResults: modelComponentPositions,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setModelComponentPositionFilteredDataThunk = retData => dispatch => {
  dispatch(setModelComponentPositionFilteredData(retData));
};

export const setModelComponentPositionFilteredData = retData => ({
  type: SET_MODELCOMPONENTPOSITION_FILTERED_RESULTS,
  payload: retData
});

export const setCurrentPage = currentPage => ({
  type: SET_MODELCOMPONENTPOSITION_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_MODELCOMPONENTPOSITION_PAGE_FILTER,
  payload: { filter }
});

export const describeModelComponentPositionThunk = (
  ModelComponentPositionId,
  widgetCode,
  parentModelComponentPositionId = null
) => dispatch => {
  dispatch(describeModelComponentPosition(ModelComponentPositionId, widgetCode, parentModelComponentPositionId));
};

export const describeModelComponentPosition = (ModelComponentPositionId, widgetCode, parentModelComponentPositionId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelComponentPositions`,
    data: {
      ModelComponentPositionId: ModelComponentPositionId || undefined,
      ParentModelComponentPositionId: parentModelComponentPositionId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setModelComponentPositionThunk(retData, parentModelComponentPositionId),
    onFailure: err => setModelComponentPositionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setModelComponentPositionThunk = ({ retData }, parentModelComponentPositionId) => dispatch => {
  dispatch(setModelComponentPosition(retData, parentModelComponentPositionId));
};

export const setModelComponentPosition = ({ modelComponentPositions }, parentModelComponentPositionId) => {
  let parentModelComponentPositionName = {};
  let componentName = {};
  let componentType = {};
  if (parentModelComponentPositionId) {
    let parentModelCompPos = modelComponentPositions.parentModelComponentPositions.find(
      item => item.ModelComponentPositionId == parentModelComponentPositionId
    );
    if (parentModelCompPos) {
      parentModelComponentPositionName = {
        ParentModelComponentPositionName: parentModelCompPos.Name ?? ''
      };
      componentName = {
        Name: `${parentModelCompPos.Name}_`
      };
      componentType = {
        ModelComponentTypeId: parentModelCompPos.ModelComponentTypeId ?? 0
      };
    }
  }

  return {
    type: SET_MODELCOMPONENTPOSITION,
    payload: {
      selectedModelComponentPosition: {
        ...componentName,
        ...parentModelComponentPositionName,
        ...componentType,
        ...modelComponentPositions.selectedModelComponentPositions,
        ParentModelComponentPositionId: parentModelComponentPositionId
      },
      modelComponentTypes: modelComponentPositions.modelComponentTypes,
      parentModelComponentPositions: modelComponentPositions.parentModelComponentPositions,
      isOpSuccessful: false,
      showBanner: false,
      isRedirect: false
    }
  };
};

export const saveModelComponentPositionThunk = (modelComponentPosition, widgetCode) => dispatch => {
  let url = '/management/modelComponentPositions/save';
  let actionName =
    modelComponentPosition.ModelComponentPositionId === 0
      ? CREATE_MODELCOMPONENTPOSITION
      : modelComponentPosition.PerformDelete
      ? DELETE_MODELCOMPONENTPOSITION
      : UPDATE_MODELCOMPONENTPOSITION;

  let log = logEntry(MODELCOMPONENTPOSITION_MANAGEMENT, actionName, modelComponentPosition);
  dispatch(saveModelComponentPosition(modelComponentPosition, url, log, widgetCode));
};

export const saveModelComponentPosition = (modelComponentPosition, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setModelComponentPositionStatusThunk(retData, modelComponentPosition),
    onFailure: err => setModelComponentPositionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      ModelComponentPosition: modelComponentPosition,
      log: log
    },
    widgetcode: widgetCode
  });

export const setModelComponentPositionStatusThunk = (message, modelComponentPosition) => dispatch => {
  let isSubComponent = modelComponentPosition.ParentModelComponentPositionId != null;
  dispatch(setModelComponentPositionStatus(message, true, true, isSubComponent));
  setTimeout(() => {
    //Hide the banner
    dispatch(setModelComponentPositionStatus('', false, modelComponentPosition.PerformDelete ? true : false, isSubComponent));
  }, 2500);
};

export const setModelComponentPositionStatus = (messageCode, status, isOpSuccessful, isSubComponent = false) => ({
  type: UPDATE_MODELCOMPONENTPOSITION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: isOpSuccessful,
      showBanner: status,
      isLoading: false,
      isRedirect: isSubComponent && !status && !isOpSuccessful,
      isParentRedirect: !isSubComponent && !status && !isOpSuccessful,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setModelComponentPositionChangeThunk = retData => dispatch => {
  dispatch(setModelComponentPositionChange(retData));
};

export const setModelComponentPositionChange = retData => ({
  type: SET_MODELCOMPONENTPOSITION_CHANGE,
  payload: retData
});

export const resetModelComponentThunk = retData => dispatch => {
  dispatch(resetModelComponent(retData));
};

export const resetModelComponent = retData => ({
  type: MODELCOMPONENTPOSITION_CLEANUP,
  payload: retData
});
