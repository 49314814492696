import React from 'react';
import PropTypes from 'prop-types';
import s from './CalibrationList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const CalibrationListTable = ({ paginatedData, unitId, unitSerialNumber, currentPage, userProfileWidgets, currentTimezone }) => {
  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'CALIBRATION_VIEW');

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="calibration.sensor" defaultMessage="Sensor" />
            </th>
            <th>
              <FormattedMessage id="calibration.calibrationComponent" defaultMessage="Calibration Component" />
            </th>
            <th>
              <FormattedMessage id="calibration.status" defaultMessage="Status" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="calibration.calibrationDate" defaultMessage="Calibration Date" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="calibration.assignee" defaultMessage="Assignee" />
            </th>
            <th>
              <FormattedMessage id="calibration.calibrationRequired" defaultMessage="Calibration Required" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.CalibrationId} data-unittest="calibrationData">
                <td>{n.ComponentPartName || ''}</td>
                <td>{n.ComponentPartName + '_' + n.CalibrationComponentName || ''}</td>
                <td>{n.Status || ''}</td>
                <td className="d-none d-md-table-cell">
                  {n.CalibrationDateTime
                    ? moment
                        .unix(n.CalibrationDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm')
                    : '-'}
                </td>

                <td className="d-none d-md-table-cell">{n.FullName || ''}</td>
                <td>
                  <span className={`${s.dot} ${n.CalibrationRequired ? s.dotRed : s.dotGreen}`}></span>{' '}
                  {n.CalibrationRequired ? (
                    <FormattedMessage id="calibration.yes" defaultMessage="Yes" />
                  ) : (
                    <FormattedMessage id="calibration.no" defaultMessage="No" />
                  )}
                </td>
                <td>
                  {isViewAllowed ? (
                    <Link to={`/unit/calibrations/${unitSerialNumber}/view/${unitId}/${n.CalibrationId}`}>
                      <Button variant="outline-secondary" className={s.changeButton}>
                        <FormattedMessage id="calibration.view" defaultMessage="VIEW" />
                      </Button>
                    </Link>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

CalibrationListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

CalibrationListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(CalibrationListTable);
