import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './AccountSettingsTabs.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const Tab = ({ selectedPage, pageComponent, url, title }) => (
  <Fragment>
    <Link className={classNames({ [s.active]: selectedPage === pageComponent })} to={url}>
      {title}
      {selectedPage === pageComponent && <span />}
    </Link>
  </Fragment>
);

const AdminControlsTabs = ({ selectedPage }) => (
  <ul className={s.tabNav}>
    <li>
      <Tab
        url={`/account-settings`}
        title={<FormattedMessage id="accountSettingsTab.profile" defaultMessage="Profile" />}
        selectedPage={selectedPage}
        pageComponent="profile"
      />
    </li>
    <li>
      <Tab
        url={`/account-settings/settings`}
        title={<FormattedMessage id="accountSettingsTab.settings" defaultMessage="Settings" />}
        selectedPage={selectedPage}
        pageComponent="settings"
      />
    </li>
  </ul>
);

AdminControlsTabs.propTypes = {
  selectedPage: PropTypes.string.isRequired
};

export default AdminControlsTabs;
