import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialEventAction, initialUserManagerState } from '../../reducers/initialState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import EventActionRHS from './EventActionRHS';
import Activity from '../WSAControls/Activity/Activity';
import EventActionTask from './EventActionTask';
import EventActionTrendAnalysis from './EventActionTrendAnalysis';
import EventActionTelemetry from './EventActionTelemetry';
import s from './EventAction.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import alert from '../../assets/alert.svg';
import { compareValues } from '../../utils';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import Cookies from 'js-cookie';
import Banner from '../Banner/Banner';
import uuid from 'uuid';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { TASK_STATUS_ACTIVE } from '../../constants/index';
import { useHistory } from 'react-router-dom';
import { getQuerystring } from '../../utils';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import Alarms from './Alarms';

import {
  EVENT_ACTION_ACTIVITY_TYPE_ASSIGN,
  EVENT_ACTION_ACTIVITY_TYPE_UPDATED_STATUS,
  EVENT_ACTION_ACTIVITY_TYPE_LINKALARM,
  EVENT_ACTION_ACTIVITY_TYPE_UNLINKALARM,
  ALARM_STATUS_NEW,
  ALARM_STATUS_RESOLVED,
  EVENT_ACTION_ACTIVITY_TYPE_COMMENT
} from '../../constants';
import greentick from '../../assets/greentick.svg';
import GoBack from '../WSAControls/GoBack/GoBack';

const EventAction = ({
  currentTimezone,
  eventAction,
  fetchEventAction,
  userProfileWidgets,
  getUsers,
  userManager,
  onActivitychange,
  postEventActivitiy,
  eventActionCleanUp,
  intl
}) => {
  let { eventActionId } = useParams();
  let from = getQuerystring('from');
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  const history = useHistory();
  let EventAction = eventAction.EventAction;
  let TelemetryDownloads = eventAction.TelemetryDownloads;
  let LinkedAlarms = eventAction.LinkedAlarms;
  let EventActivities = eventAction.EventActionActivities || [];
  let newActivity = eventAction.NewActivity || {};

  let isNewAlarm = EventAction.EventStatus === ALARM_STATUS_NEW;
  let isResolved = EventAction.EventStatus === ALARM_STATUS_RESOLVED;
  let isEventActionCompleted = EventAction.EventStatus === ALARM_STATUS_RESOLVED;
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'ALARMMANAGE');

  const isTrendAnalysisAllowed = IsWidgetAccessible(userProfileWidgets, 'ALARMMANAGE_CHART');
  const isTelemetryDownloadAllowed = IsWidgetAccessible(userProfileWidgets, 'ALARMMANAGE_TELEMETRY');

  const isViewAllowed = true;
  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchStatusType, setSearchStatusType] = useState('');
  const [sortOrder, setSortOrder] = useState(false);

  const [trendAnalysisOpen, setTrendAnalysisOpen] = useState(false);
  const [telemetryOpen, setTelemetryOpen] = useState(false);
  const [linkedAlarmOpen, setLinkedAlarmOpen] = useState(false);
  const [linkedTaskOpen, setLinkedTaskOpen] = useState(false);

  useEffect(() => {
    return () => {
      eventActionCleanUp(initialEventAction.eventAction);
    };
  }, [eventActionCleanUp]);

  useEffect(() => {
    fetchEventAction(eventActionId);
  }, [fetchEventAction, eventActionId]);

  useEffect(() => {
    getUsers(orgId);
  }, [getUsers, orgId]);

  const onSaveClick = () => {
    postEventActivitiy();
  };

  const onResolvedBtnClick = () => {
    newActivity.ActivityType = EVENT_ACTION_ACTIVITY_TYPE_UPDATED_STATUS.statusType;
    newActivity.EventStatus = ALARM_STATUS_RESOLVED;

    onActivitychange(newActivity);
    postEventActivitiy();
  };

  const onAlarmLink = parentAlarm => {
    newActivity.ActivityType = EVENT_ACTION_ACTIVITY_TYPE_LINKALARM.statusType;
    newActivity.LinkEventActionId = parentAlarm.value;
    newActivity.LinkEventActionStatus = parentAlarm.status;

    onActivitychange(newActivity);
    postEventActivitiy();
  };

  const onAlarmUnLink = linkEventActionId => {
    newActivity.ActivityType = EVENT_ACTION_ACTIVITY_TYPE_UNLINKALARM.statusType;
    newActivity.LinkEventActionId = linkEventActionId;

    onActivitychange(newActivity);
    postEventActivitiy();
  };

  const onUserDdlChange = e => {
    newActivity.ActivityType = EVENT_ACTION_ACTIVITY_TYPE_ASSIGN.statusType;
    newActivity.AssignedUserId = e.value;

    onActivitychange(newActivity);
    postEventActivitiy();
  };

  const getMessage = activity => {
    let message = activity.CreatedByName;

    switch (activity.ActivityType) {
      case EVENT_ACTION_ACTIVITY_TYPE_UPDATED_STATUS.statusType: {
        const translatedMsg = intl.formatMessage({
          id: EVENT_ACTION_ACTIVITY_TYPE_UPDATED_STATUS.messageTranslationCode,
          defaultMessage: 'changed the status to '
        });
        message += ` ${translatedMsg} '${activity.EventStatus}' `;
        break;
      }

      case EVENT_ACTION_ACTIVITY_TYPE_COMMENT.statusType: {
        const translatedMsg = intl.formatMessage({
          id: EVENT_ACTION_ACTIVITY_TYPE_COMMENT.messageTranslationCode,
          defaultMessage: 'added a comment'
        });
        message += ` ${translatedMsg} `;
        break;
      }

      case EVENT_ACTION_ACTIVITY_TYPE_ASSIGN.statusType: {
        const translatedMsg = intl.formatMessage({
          id: EVENT_ACTION_ACTIVITY_TYPE_ASSIGN.messageTranslationCode,
          defaultMessage: 'changed the assignee to'
        });
        message += ` ${translatedMsg} ${activity.AssignedName} `;
        break;
      }

      case EVENT_ACTION_ACTIVITY_TYPE_LINKALARM.statusType: {
        const translatedMsg = intl.formatMessage({
          id: EVENT_ACTION_ACTIVITY_TYPE_LINKALARM.messageTranslationCode,
          defaultMessage: 'linked Alarm'
        });
        message += ` ${translatedMsg} `;
        break;
      }

      case EVENT_ACTION_ACTIVITY_TYPE_UNLINKALARM.statusType: {
        const translatedMsg = intl.formatMessage({
          id: EVENT_ACTION_ACTIVITY_TYPE_UNLINKALARM.messageTranslationCode,
          defaultMessage: 'unlinked Alarm'
        });
        message += ` ${translatedMsg} `;
        break;
      }

      default: {
        message += '';
      }
    }
    return message;
  };

  const getEventActivities = () => {
    return EventActivities.sort((a, b) => (a.EventActionActivityId < b.EventActionActivityId ? 1 : -1)).map(activity => {
      return {
        Id: activity.EventActionActivityId,
        Title: getMessage(activity),
        ActivityDate: activity.CreatedDateTime,
        Description: activity.Description
      };
    });
  };

  const onActivityModified = event => {
    let { value } = event.target;
    newActivity.Description = value;
    newActivity.ActivityType = EVENT_ACTION_ACTIVITY_TYPE_COMMENT.statusType;
    onActivitychange(newActivity);
  };

  const onCancelClick = () => {
    newActivity.Description = '';
    newActivity.ActivityType = '';
    onActivitychange(newActivity);
  };

  const onTaskSearch = keyWords => {
    setSearchKeywords(keyWords);
  };

  const onTaskStatusSearch = value => {
    setSearchStatusType(value);
  };

  const onSortOrder = () => {
    setSortOrder(!sortOrder);
  };

  const addNewbtnClick = () => {
    history.push(`/tasks-and-checklists/task?eventActionId=${eventActionId}&prevPath=${history.location.pathname}`);
  };

  const viewBtnClick = url => {
    history.push(`${url}?eventActionId=${eventActionId}`);
  };

  const getSelectedTasks = () => {
    let filteredData = eventAction.Tasks.filter(task => task.Name.toLowerCase().indexOf(searchKeywords.toLowerCase()) >= 0);
    if (searchStatusType !== '' && searchStatusType !== 'ALL') {
      filteredData = filteredData.filter(
        task => task.Status === searchStatusType || (!task.Status && searchStatusType === TASK_STATUS_ACTIVE)
      );
    }
    if (sortOrder) {
      filteredData = filteredData.sort(compareValues('DueDateTime'));
    } else {
      filteredData = filteredData.sort(compareValues('DueDateTime', 'desc'));
    }
    return filteredData;
  };

  const isTaskViewAllowed = IsWidgetAccessible(userProfileWidgets, 'TASKVIEW');
  const isNewTaskAllowed = IsWidgetAccessible(userProfileWidgets, 'TASKADDNEW');

  let messageId = (eventAction && eventAction.messageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });
  const headingText = intl.formatMessage({ id: 'eventAction.activity', defaultMessage: 'Activity' });
  return (
    <ContentTemplate
      selectedPage="processFlow"
      userProfileWidgets={userProfileWidgets}
      isNewAlarm={isNewAlarm}
      widgetCode={'ALARMMANAGE'}
      tagoutRequired={false}
    >
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={eventAction.showBanner}
        status={eventAction.isOpSuccessful}
        successText={messageText}
      />

      <div className={s.eventAction}>
        {(eventAction.isLoading || userManager.isLoading) && <LoadingSpinner />}
        <div>
          <GoBack>
            &lt; &nbsp;
            {from && from === 'eventlog' ? (
              <FormattedMessage id="eventAction.backToEventLog" defaultMessage="BACK TO EVENT LOG" />
            ) : from && from === 'taskDetail' ? (
              <FormattedMessage id="tasksManagement.backtoTasks" defaultMessage="BACK TO TASKS" />
            ) : (
              <FormattedMessage id="eventAction.backToalarms" defaultMessage="BACK TO ALARMS" />
            )}
          </GoBack>
        </div>

        <div className={`${s.eventActionContainer} ${s.borderRed}`}>
          <Row className={s.rowContents}>
            <Col xs={12} lg={9} className={s.colContents}>
              <div className={s.lHSSection}>
                <div>
                  <img src={alert} alt="icon" />
                  <span className={s.alarmMessage}>
                    <FormattedMessage id="eventAction.alarm" defaultMessage="Alarm" />
                  </span>
                  {isResolved && (
                    <span>
                      <img src={greentick} alt="icon" className={s.greenTick} />
                      <span className={s.alarmMessage}>
                        <FormattedMessage id="eventAction.resolved" defaultMessage="Resolved" />
                      </span>
                    </span>
                  )}
                </div>
                <div>
                  <div id="unitTestTitle" className={s.eventActionHeader}>
                    {eventAction.EventAction.EventTitle}
                  </div>
                  <div className={s.eventActionContents}>
                    <p id="unitTestDescription">{EventAction.EventDescription.split('\n\n\n\n')[0]}</p>
                    <p id="unitTestDescription1">
                      {EventAction.EventDescription.split('\n\n\n\n').length > 1 ? EventAction.EventDescription.split('\n\n\n\n')[1] : ''}
                    </p>
                  </div>
                </div>
                <div>
                  <Activity
                    description={newActivity.Description || ''}
                    onActivityModified={onActivityModified}
                    onSaveClick={onSaveClick}
                    onCancelClick={onCancelClick}
                    currentTimezone={currentTimezone}
                    activities={getEventActivities()}
                    isUpdateAllowed={isUpdateAllowed}
                    isViewAllowed={isViewAllowed}
                    headingText={headingText}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} lg={3} className={s.rHSSection}>
              <div>
                <EventActionRHS
                  isUpdateAllowed={isUpdateAllowed}
                  eventAction={EventAction}
                  users={userManager.users}
                  onResolvedBtnClick={onResolvedBtnClick}
                  onUserDdlChange={onUserDdlChange}
                  tasks={eventAction.Tasks}
                  onAlarmLink={onAlarmLink}
                  onAlarmUnLink={onAlarmUnLink}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className={s.collapsableSection}>
          <div className={`${s.titleRows}`}>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <img
                    src={linkedTaskOpen ? collapseDown : collapseUp}
                    alt="Collapse section"
                    onClick={() => setLinkedTaskOpen(!linkedTaskOpen)}
                    aria-controls="task-section"
                    aria-expanded={linkedTaskOpen}
                  ></img>
                  <h4>
                    <FormattedMessage id="checklistTasks.tasks" defaultMessage="Tasks" />
                  </h4>
                  <span className={s.circle}>{getSelectedTasks().length}</span>
                </Row>
              </Col>
            </Row>
            <Collapse in={linkedTaskOpen}>
              <div id="task-section">
                <EventActionTask
                  onTaskSearch={onTaskSearch}
                  onTaskStatusSearch={onTaskStatusSearch}
                  selectedTasks={getSelectedTasks() || []}
                  isEventActionCompleted={isEventActionCompleted}
                  currentTimezone={currentTimezone}
                  isView={isTaskViewAllowed}
                  isNewTask={isNewTaskAllowed}
                  sortOrder={sortOrder}
                  onSortOrder={onSortOrder}
                  addNewBtnClick={addNewbtnClick}
                  viewBtnClick={viewBtnClick}
                  eventAction={EventAction}
                />
              </div>
            </Collapse>
          </div>

          {EventAction.LinkStatus === 1 && (
            <div className={`${s.titleRows}`}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={linkedAlarmOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setLinkedAlarmOpen(!linkedAlarmOpen)}
                      aria-controls="alarm-section"
                      aria-expanded={linkedAlarmOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="eventAction.linkedAlarms" defaultMessage="Linked Alarms" />
                    </h4>
                    <span className={s.circle}>{LinkedAlarms.length}</span>
                  </Row>
                </Col>
              </Row>
              <Collapse in={linkedAlarmOpen}>
                <div id="alarm-section">
                  <Alarms updateAllowed={isUpdateAllowed} currentTimezone={currentTimezone} alarms={LinkedAlarms} />
                </div>
              </Collapse>
            </div>
          )}

          {isTrendAnalysisAllowed && (
            <div className={`${s.titleRows}`}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={trendAnalysisOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setTrendAnalysisOpen(!trendAnalysisOpen)}
                      aria-controls="trendanalysis-section"
                      aria-expanded={trendAnalysisOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="eventAction.datatrendAnalysis" defaultMessage="Data trend analysis" />
                    </h4>
                  </Row>
                </Col>
              </Row>
              <Collapse in={trendAnalysisOpen}>
                <div id="trendanalysis-section">
                  <EventActionTrendAnalysis
                    eventAction={EventAction}
                    currentTimezone={currentTimezone}
                    userProfileWidgets={userProfileWidgets}
                    isOpen={trendAnalysisOpen}
                  />
                </div>
              </Collapse>
            </div>
          )}

          {isTelemetryDownloadAllowed && (
            <div className={`${s.titleRows}`}>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <img
                      src={telemetryOpen ? collapseDown : collapseUp}
                      alt="Collapse section"
                      onClick={() => setTelemetryOpen(!telemetryOpen)}
                      aria-controls="telemetry-section"
                      aria-expanded={telemetryOpen}
                    ></img>
                    <h4>
                      <FormattedMessage id="eventAction.telemetry" defaultMessage="Telemetry" />
                    </h4>
                  </Row>
                </Col>
              </Row>
              <Collapse in={telemetryOpen}>
                <div id="telemetry-section">
                  {telemetryOpen && (
                    <EventActionTelemetry
                      eventAction={EventAction}
                      telemetryDownloads={TelemetryDownloads}
                      currentTimezone={currentTimezone}
                    />
                  )}
                </div>
              </Collapse>
            </div>
          )}
        </div>
      </div>
    </ContentTemplate>
  );
};

EventAction.defaultProps = {
  currentTimezone: moment.tz.guess(),
  eventAction: {
    ...initialEventAction.eventAction
  },
  userProfileWidgets: [],
  userManager: {
    ...initialUserManagerState.userManager
  }
};

EventAction.propTypes = {
  currentTimezone: PropTypes.string.isRequired,
  userProfileWidgets: PropTypes.object.isRequired,
  userManager: PropTypes.object.isRequired
};

export default injectIntl(EventAction);
