import React from 'react';
import s from './TelemetryPartsList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';

const TelemetryPartsListTable = ({ telemetryPartsTypes, paginatedData, onSortOrder, asendingOrder }) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  const getTypeName = val => {
    return telemetryPartsTypes.find(x => x.value === val).text;
  };

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="maintenance.telemetryParts.brand" defaultMessage="Brand" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="maintenance.telemetryParts.model" defaultMessage="Model" />
            </th>
            <th>
              <FormattedMessage id="maintenance.telemetryParts.unit" defaultMessage="Unit" />
            </th>

            <th>
              <FormattedMessage id="maintenance.telemetryParts.componentType" defaultMessage="Component Type" />
            </th>
            <th>
              <FormattedMessage id="maintenance.telemetryParts.position" defaultMessage="Position" />
            </th>
            <th>
              <FormattedMessage id="maintenance.telemetryParts.type" defaultMessage="Type" />
            </th>
            <th>
              <FormattedMessage id="maintenance.telemetryParts.value" defaultMessage="Value" />
            </th>
            <th>
              <FormattedMessage id="maintenance.telemetryParts.status" defaultMessage="Status" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.RefId}>
                <td>{n.Brand || ''}</td>
                <td>{n.Model || ''}</td>
                <td>{n.UnitName || ''}</td>
                <td>{n.ModelComponentType || ''}</td>
                <td>{n.Position || ''}</td>
                <td>{getTypeName(n.TelemetryType) || ''}</td>
                <td>{n.Value || ''}</td>
                <td>
                  <span className={`${s.dot} ${n.Status === 'Active' ? s.green : s.blue}`}></span> {n.Status || ''}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

TelemetryPartsListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(TelemetryPartsListTable);
