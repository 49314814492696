import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './ProfileList.module.scss';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';

const ProfileList = ({ getProfileInfo, profile, getAppWidgets, userProfileWidgets }) => {
  //getAppWidgets();

  useEffect(() => {
    getProfileInfo();
    getAppWidgets();
  }, [getProfileInfo, getAppWidgets]);

  const profilesObj = (profile.profiles && Object.values(profile.profiles)) || [];

  return (
    <div className={s.profile}>
      {/* <div className={s.fullWidth}>
        <div className={s.fullWidthWrapper}>
          <h1>
            <FormattedMessage id="settings.profileManagement" defaultMessage="Profile Management" />
          </h1>
        </div>
      </div> */}
      <AdminControlsContentTemplate selectedPage="manageProfiles" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.profileHeader}>
            <div className={s.subHeading}>
              <FormattedMessage id="profile.profiles" defaultMessage="Profiles" />
            </div>
            <Link to="/admin-controls/profile">
              <Button className={s.addbutton}>
                + <FormattedMessage id="profile.addNewProfile" defaultMessage="ADD NEW PROFILE" />
              </Button>
            </Link>
          </div>
          <div className="">
            {profile.isLoading && <LoadingSpinner />}
            <Table variant className={s.table}>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="profile.profileName" defaultMessage="Profile Name" />
                  </th>
                  <th className="d-none d-md-table-cell">
                    <FormattedMessage id="profile.profileDescription" defaultMessage="Profile Profile Description" />
                  </th>
                  <th className="d-none d-md-table-cell">
                    <FormattedMessage id="profile.widgetsCount" defaultMessage="Widgets" />
                  </th>
                  <th className="d-none d-md-table-cell">
                    <FormattedMessage id="profile.userCount" defaultMessage="Users" />
                  </th>
                  <th>
                    <FormattedMessage id="profile.profileActive" defaultMessage="Active" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {profilesObj.map(n => {
                  return (
                    <tr key={n.ProfileId} data-unittest="profilesData">
                      <td>{n.Name}</td>
                      <td className="d-none d-md-table-cell">{n.Description}</td>
                      <td className="d-none d-md-table-cell">{n.NumberOfWidgets}</td>
                      <td className="d-none d-md-table-cell">{n.NumberOfUsers}</td>
                      <td>{n.IsActive ? 'True' : 'False'}</td>
                      <td>
                        <Link className={s.selectLink} to={`/admin-controls/profile/${n.ProfileId}`}>
                          {n.IsDefault ? (
                            <FormattedMessage id="profile.View" defaultMessage="VIEW" />
                          ) : (
                            <FormattedMessage id="profile.Edit" defaultMessage="EDIT" />
                          )}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ProfileList.defaultProps = {
  profile: { profiles: [] }
};

ProfileList.propTypes = {
  getProfileInfo: PropTypes.func.isRequired,
  userProfileWidgets: PropTypes.object.isRequired
};

export default ProfileList;
