import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UnitList from './UnitList';

import { fetchDevicesStatus, getDeviceAlarmsThunk, setSelectedUnit } from '../../actions/devices';
import { getUnitsThunk, setCurrentPage, setPageFilter, getUnitsFilterThunk, setUnitsFilteredDataThunk } from '../../actions/unitManager';

import {
  getDevicesStatus,
  getUnitManager,
  getDevicesAlarms,
  getFlowSequenceGlobalSettings,
  getUserProfileWidget,
  getCurrentTimezone,
  getUnit
} from '../../selectors/index';

const mapStateToProps = state => {
  return {
    devicesStatus: getDevicesStatus(state),
    deviceAlarms: getDevicesAlarms(state),
    flowSequences: getFlowSequenceGlobalSettings(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state),
    unitManager: getUnitManager(state),
    unitAdd: getUnit(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUnitsFilter: () => getUnitsFilterThunk('NAV_UNITS'),
      getUnits: (limit, filter, orgId, profId) => getUnitsThunk(limit, filter, orgId, 'NAV_UNITS', profId),
      getDevicesStatus: (unitSerialNumbers, from, to) => fetchDevicesStatus(unitSerialNumbers, from, to, 'NAV_UNITS'),
      getDeviceAlarms: (unitSerialNumbers, status) => getDeviceAlarmsThunk(unitSerialNumbers, status, 'NAV_UNITS'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter),
      setFilterData: filteredData => setUnitsFilteredDataThunk(filteredData),
      setSelectedUnit: unitSerialNumber => setSelectedUnit(unitSerialNumber)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UnitList);
