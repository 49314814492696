import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProfileSettings from './ProfileSettings';
import { describeUserThunk, postUserThunk, submitChangePassword, setUserCleanupThunk } from '../../actions/userManager';
import { getUserManager } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    userManager: getUserManager(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeUser: userName => describeUserThunk(userName, 'GENERALACCESS', true),
      saveUser: user => postUserThunk(user, 'GENERALACCESS', true),
      submitPassword: item => submitChangePassword(item),
      setUserCleanup: user => setUserCleanupThunk(user)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
